import http from "./api";

const create = (data) => {
  return http.post("AnnouncementBanner", data);
};
const find = (params) => {
  return http.get("AnnouncementBanner/search?", { params });
};
const remove = (id) => {
  return http.delete(`AnnouncementBanner?id=${id}`);
};
const update = (data) => {
  return http.put(`AnnouncementBanner`, data);
};

const AnnouncementService = {
  create,
  find,
  remove,
  update,
};

export default AnnouncementService;
