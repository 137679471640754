import React, { useState, useRef } from "react";
import Slider from "react-slick";
import photo1 from "../../assets/landingPage/program.png";
import photo2 from "../../assets/landingPage/agent.png";
import photo3 from "../../assets/landingPage/institution.png";
import photo4 from "../../assets/landingPage/partner.png";
import photo5 from "../../assets/landingPage/internship.png";
import TokenService from "../../Services/TokenService";

const Cards = [
  {
    id: 1,
    title: "Program Management",
    content: "Choose your program that fits you through our AI.",
    photo: photo1,
  },
  {
    id: 2,
    title: "Partner",
    content: "Help you choose the right program giving the right guidance.",
    photo: photo2,
  },
  {
    id: 3,
    title: "Institution",
    content: "Choose the institute that best suits your needs and goals.",
    photo: photo3,
  },
  // {
  //   id: 4,
  //   title: "Partner",
  //   content: "We are a partner with the best agencies to provide the best.",
  //   photo: photo4,
  // },
  {
    id: 4,
    title: "Internship & Scholarship",
    content: "Scholarship guidance for the institure that you choose.",
    photo: photo5,
  },
];
const AuthCards = [
  {
    id: 1,
    title: "Program Management",
    content: "Choose your program that fits you through our AI.",
    photo: photo1,
  },
  {
    id: 2,
    title: "Agent",
    content: "Help you choose the right program giving the right guidance.",
    photo: photo2,
  },
  {
    id: 3,
    title: "Institution",
    content: "Choose the institute that best suits your needs and goals.",
    photo: photo3,
  },
  {
    id: 4,
    title: "Partner",
    content: "We are a partner with the best agencies to provide the best.",
    photo: photo4,
  },
  {
    id: 5,
    title: "Internship & Scholarship",
    content: "Scholarship guidance for the institure that you choose.",
    photo: photo5,
  },
];

export default function Section2() {
  const token = TokenService.getLocalAccessToken();
  const isAuth = Boolean(token);
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);

  const sliderSettings = {
    className: "center",
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    afterChange: (current) => setCurrentSlide(current),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const prevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const cards = isAuth ? AuthCards : Cards;

  return (
    <div className="container">
      <div className="col-12 text-center my-5 sections-title font-bold">
        <span className="text-dark0">Cherish your dream of</span>{" "}
        <span className="text-dark1">studying abroad</span>
      </div>

      <Slider ref={sliderRef} {...sliderSettings}>
        {cards.map((card) => (
          <div key={card.id} className="mb-5">
            <div className="position-relative mx-2">
              <img src={card.photo} className={`w-100`} alt={card.photo} />
              <div className="position-absolute bottom-0 start-0 p-3 info-width end-0">
                <div className="bg-white p-3 rounded-4 inner-width">
                  <div>
                    <div className="font-bold text1-5 text-dark0">
                      {card.title}
                    </div>
                    <p className="text-dark2 text1-3">{card.content}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>

      <div className="position-relative mt-5 pt-4">
        <div className="position-absolute bottom-0 start-50 translate-middle">
          <button
            className="btn bg-light0 rounded-circle me-1 arrow-btn"
            onClick={prevSlide}
          >
            <i className="bi bi-arrow-left fs-1 text-dark1 d-flex justify-content-center align-items-center"></i>
          </button>
          <button
            className="btn bg-light0 rounded-circle ms-1 arrow-btn"
            onClick={nextSlide}
          >
            <i className="bi bi-arrow-right fs-1 text-dark1 d-flex justify-content-center align-items-center"></i>
          </button>
        </div>
      </div>
    </div>
  );
}
