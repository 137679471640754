import React, { useCallback, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import CollegeDataService from "../../Services/CollegesService";

export default function LogoView({ data }) {
  const [imageURL, setImageURL] = useState(null);

  const getCollegeLogo = useCallback(async () => {
    try {
      if (data.logo) {
        const response = await CollegeDataService.downloadCollegeLogo(data.id);
        setImageURL(response?.data?.Url);
      }
    } catch (error) {}
  }, [data.id, data.logo]);

  useEffect(() => {
    getCollegeLogo();
  }, [data, getCollegeLogo]);

  return (
    <>
      {imageURL ? (
        <span
          className="d-flex justify-content-center align-items-center p-4"
          style={{ width: "100%", height: "14rem" }}
        >
          <Card.Img
            variant="top"
            src={imageURL}
            alt={data.name}
            style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
          />
        </span>
      ) : (
        <span
          className="d-flex justify-content-center align-items-center p-4"
          style={{ height: "14rem" }}
        >
          Logo not found
        </span>
      )}
    </>
  );
}
