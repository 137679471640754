import React, { useState, useEffect } from "react";

import { toast } from "react-hot-toast";

import PageTitle from "../../../components/PageTitle";
import ListIntakes from "./ListIntakes";
import SpinnerGrow from "../../Components/SpinnerGrow";

import SearchBar from "../../Components/SearchBar";
import SelectItemsPerPage from "../../Components/SelectItemsPerPage";

import AdvancePagination from "../../Components/Pagination";
import SpinnerBorder from "../../Components/SpinnerBorder";
import ProgramService from "../../Services/ProgramSearchService";

export default function MainIntakes() {
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  const pageTitle = "Intakes";

  const initializeData = [
    {
      collegeId: "",
      name: "",
      author: "",
      campus: "",
      duration: "",
      credential: "",
      tuitionFee: 0,
      academicRequirement: "",
      applicationProcessingTime: "",
      pgWorkPermit: "",
      websiteUrl: "",
      statusId: 1,
      ieltsRequirement: "",
      pteRequirement: "",
      toeflRequirement: "",
      duolingoRequirement: "",
      gmat: "",
      gre: "",
      sat: "",
      areasofInterest: "",
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [itemsLength, setItemsLength] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const [collegeData, setCollegeData] = useState([]);
  const [selectedCollege, setSelectedCollege] = useState();

  const handleCollegeSelect = (e) => {
    e.preventDefault();
    const { value } = e.target;
    if (value === "All") {
      setSelectedCollege();
    } else {
      setSelectedCollege(value);
    }
  };

  const getCollegeData = async () => {
    try {
      const response = (await ProgramService.getCountryColleges("")).data;
      setCollegeData(response);
    } catch (error) {
      toast.error((error.response && error.response.data && error.response.data.message) || error.message || error.to());
    }
  };

  const [programData, setProgramData] = useState(initializeData);

  const getPrograms = async () => {
    setUpdating(true);
    try {
      await getCollegeData();
      const data = {
        search: searchQuery,
        currentPage,
        pageSize,
        collegeId: selectedCollege,
      };
      const response = await ProgramService.find(data);
      const responseData = response.data.response;

      setProgramData(responseData);
      setLoading(false);
      setItemsLength(responseData[0]?.totalItems);
      setUpdating(false);
    } catch (error) {
      setUpdating(false);
    }
  };

  useEffect(() => {    
    getPrograms();
  }, [searchQuery, currentPage, pageSize, pageTitle, selectedCollege]);

  const handleSearchInput = (e) => {
    e.preventDefault();
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const clearSearchQuery = () => {
    setSearchQuery("");
  };

  const handlePageSizeChange = (event) => {
    event.preventDefault();
    setPageSize(parseInt(event.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if (loading) return <SpinnerGrow />;

  return (
    <>
    <PageTitle title={pageTitle} />
      <div className="text-center fs-1 text-uppercase col-12">{pageTitle}</div>
      <div className="px-5">
        <div className=" d-flex justify-content-between">
          <div className="">
            <SearchBar
              searchQuery={searchQuery}
              handleSearchInput={handleSearchInput}
              clearSearchQuery={clearSearchQuery}
              searchTitle={`Search Program`}
            />
          </div>
          <div className="d-flex justify-content-center">
            <div className="">
              <select
                className="form-select rounded-4 fs-5 py-2"
                aria-label="college select"
                name="collegeId"
                value={selectedCollege}
                onChange={handleCollegeSelect}
              >
                <option value={"All"}>All Colleges</option>
                {collegeData.map((cd) => (
                  <option value={cd.id} key={cd.id}>
                    {cd.collegeName}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-4 ms-2">
              <SelectItemsPerPage handlePageSizeChange={handlePageSizeChange} pageSize={pageSize} />
            </div>
          </div>
        </div>

        {updating ? (
          <SpinnerBorder />
        ) : programData.length === 0 ? (
          <div className="mt-5 text-center fs-1">No Data Found</div>
        ) : (
          <div className="col-12 p-4">
            <ListIntakes
              dataList={programData}
              collegeData={collegeData}
              getData={getPrograms}
              pageTitle={pageTitle}
            />
            <AdvancePagination currentPage={currentPage} handlePageChange={handlePageChange} totalItems={itemsLength} pageSize={pageSize} />
          </div>
        )}
      </div>
    </>
  );
}
