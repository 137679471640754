import React, { useCallback, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import pdfIcon from "../../assets/icons/applications-1.svg";
import FeedbackService from "../../CMS/Services/FeedbackService";
import { toast } from "react-toastify";
import RequestService from "../../Services/RequestService";

function RequestEdit({ modalShow, id, setModalShow, type }) {
  const [loading, setLoading] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [validated, setValidated] = useState(false);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      setValidated(true);

      if (form.checkValidity() === false) {
        event.stopPropagation();
        return;
      }

      try {
        setLoading(true);
        const payload = {
          remarks: remarks,
          type: type ? type : "Student",
          referenceId: id,
        };

        const response = await RequestService.create(payload);

        if (response.data.success === true) {
          toast.success(response.data.message);

          setValidated(false);
        } else if (
          response.data.success === false &&
          response.data.response === false
        ) {
          toast.error(response.data.message);
        } else if (
          response.data.success === false &&
          response.data.validationErrors.length > 0
        ) {
          response.data.validationErrors.map((err) => toast.error(err));
        } else {
          toast.error("Something Went Wrong");
        }
      } catch (error) {
        console.log(error);

        toast.error("An error occurred");
      } finally {
        setLoading(false);
        setModalShow(false);
      }
    },
    [id, remarks, setModalShow]
  );

  const handleInputChange = useCallback((event) => {
    const { value } = event.target;
    setRemarks(value);
  }, []);
  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      centered
      aria-labelledby="contained-modal-title-vcenter"
      size="md"
      className="custom-modal"
    >
      <Modal.Header
        className="d-flex justify-content-between align-items-center py-3 px-4 bg-dark1 "
        style={{
          borderRadius: "15px 15px 0px 0px",
        }}
      >
        <Modal.Title
          id="contained-modal-title-vcenter "
          className="d-flex align-items-center text1-8 text-white"
        >
          Request Edit
        </Modal.Title>
        <i
          className="bi bi-x-circle text-white text2"
          style={{ cursor: "pointer" }}
          onClick={() => setModalShow(false)}
        ></i>
      </Modal.Header>

      <Modal.Body className="pt-3">
        <form
          className="d-flex flex-column  needs-validation px-4"
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <div className="px-4 my-2 mt-3 text-dark text1-3">
            {" "}
            Please justify the requested edits in the remarks section
          </div>
          <div className="form-group px-3">
            <label className="ms-2 text1-4 mb-2 pt-1">
              Remarks <span text-danger>*</span>
            </label>
            <textarea
              style={{
                resize: "none",
              }}
              className="form-control rounded-4 border text1-3 ps-4 py-4 "
              rows="6"
              placeholder="Enter Remarks"
              required
              name="remarks"
              value={remarks}
              onChange={handleInputChange}
            />
          </div>
          <div className="my-4 mt-5 d-flex justify-content-center w-100">
            <button
              type="submit"
              className="btn btn-dark-blue rounded-pill py-3 text1-5"
              disabled={loading}
              style={{
                width: "80%",
              }}
            >
              <span> Submit</span>
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default RequestEdit;
