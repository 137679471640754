import React from "react";

export default function AddImage({ fileInputRef, setBannerData, bannerData }) {
  const handleCollegeLogoInput = async (event) => {
    const { name, type, files, value } = event.target;
    const file = files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBannerData((p) => ({
          ...p,
          image: {
            logo: reader.result.split(",")[1],
            logoName: file.name,
            type: file.type,
          },
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      {bannerData?.image?.logo ? (
        <div className=" d-flex justify-content-center mt-2 position-relative">
          <img
            src={`data:${bannerData.image.type};base64,${bannerData.image.logo}`}
            alt="Uploaded"
            className=""
            style={{ maxWidth: "100%", maxHeight: "180px" }}
          />
          <button
            className="btn btn-link btn-cross"
            style={{
              position: "absolute",
              right: "19px",
              top: "-13px",
            }}
            onClick={() => {
              setBannerData((prevInfo) => ({
                ...prevInfo,
                image: {
                  logo: "",
                  logoName: "",
                  type: "",
                },
              }));
            }}
          >
            <i className="bi bi-x-circle-fill text-danger text2"></i>
          </button>
        </div>
      ) : (
        <>
          <div className="text1-4 text-dark1 d-flex align-items-center justify-content-center mt-1">
            <i className="bi bi-cloud-plus text-dark1 text2-5 d-flex align-items-center justify-content-center me-2"></i>
            <label
              htmlFor={`importInput_${bannerData.name}`}
              style={{ cursor: "pointer" }}
            >
              Upload Image
            </label>

            <input
              id={`importInput_${bannerData.name}`}
              type="file"
              className="d-none"
              ref={fileInputRef}
              accept="image/jpeg,image/jpg,image/png"
              onChange={handleCollegeLogoInput}
            />
          </div>
        </>
      )}
    </div>
  );
}
