import React, { useCallback, useEffect, useState } from "react";
import UserPanel from "../UserPanel";
import PaymentDataService from "../../Services/PaymentService";
import { toast } from "react-toastify";
import Pagination from "../../components/UI/Pagination";
import SelectItemsPerPage from "../../components/UI/SelectItemsPerPage";
import FormatDOB from "../../components/UI/FormatDOB";
import PageTitle from "../../components/PageTitle";
import RolesService from "../../Services/RolesService";
import TokenService from "../../Services/TokenService";
import AgentService from "../../Services/AgentsService";
import FormatIntkeDate from "../../components/UI/FormatIntkeDate";
import FormatDate from "../../components/UI/FormatDate";
import ApplicationDataService from "../../Services/ApplicationService";
import { Link } from "react-router-dom";
import sortIcon from "../../assets/icons/sort.svg";

const paymentOptions = [
  { label: "Card", value: "card" },
  { label: "Wallet", value: "wallet" },
  { label: "N/A", value: "N/A" },
];

export default function PaymentList() {
  const { SuperAdmin, AgencyManager, Accounts } = RolesService;
  const getRole = TokenService.getUserRole();
  const compnayId = TokenService.getCompnayId();

  const [agentData, setAgentData] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState();

  const handleAgentSelect = useCallback((e) => {
    e.preventDefault();
    const { value } = e.target;
    setSelectedAgent(value);
  }, []);
  const [paymentMode, setPaymentMode] = useState("");

  const handleModeChange = useCallback((event) => {
    setPaymentMode(event.target.value);
    setCurrentPage(1);
  }, []);

  const getAgentData = useCallback(async () => {
    const data = {
      currentPage: 1,
      pageSize: 1000 * 1000,
    };
    try {
      const response = (await AgentService.getAllCompaniesandBranchHead(data))
        .data.response;

      setAgentData(response);
    } catch (error) {
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.to()
      );
    }
  }, []);

  const [studentApplicationData, setStudentApplicationData] = useState([]);

  const getStudentApplicationDataData = useCallback(async () => {
    const data = {
      currentPage: 1,
      pageSize: 10000 * 10000,
    };
    try {
      if (
        getRole === SuperAdmin ||
        getRole === AgencyManager ||
        getRole === Accounts
      ) {
        const response = (
          await ApplicationDataService.getAllStudentApplications_forAllCompaniesAndStudents(
            data
          )
        ).data.response;
        setStudentApplicationData(response);
      } else {
        const response = (await ApplicationDataService.find(data)).data
          .response;
        setStudentApplicationData(response);
      }
    } catch (error) {
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString()
      );
    }
  }, [Accounts, AgencyManager, SuperAdmin, getRole]);

  useEffect(() => {
    getAgentData();
    getStudentApplicationDataData();
  }, []);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  const [paymentData, setPaymentData] = useState();

  const findPaymentData = useCallback(async () => {
    var data;
    if (selectedAgent === "All") {
      data = {
        paymentStatus: "",
        totalPayableAmount: "",
        currentPage: currentPage,
        pageSize: itemsPerPage,
        mode: paymentMode,
      };
    } else {
      data = {
        companyId:
          getRole === SuperAdmin ||
          getRole === AgencyManager ||
          getRole === Accounts
            ? selectedAgent
            : compnayId, // role check and add company ID
        paymentStatus: "",
        totalPayableAmount: "",
        currentPage: currentPage,
        pageSize: itemsPerPage,
        mode: paymentMode,
      };
    }

    if (
      getRole === SuperAdmin ||
      getRole === AgencyManager ||
      getRole === Accounts
    ) {
      await PaymentDataService.getAllPaymentList(data).then((response) => {
        setPaymentData(response.data);
        setTotalItems(response.data[0]?.totalItems);
      });
    } else {
      await PaymentDataService.getPaymentListByCompanyId(data).then(
        (response) => {
          setTotalItems(response.data[0]?.totalItems);
          setPaymentData(response.data);
        }
      );
    }
  }, [
    Accounts,
    AgencyManager,
    SuperAdmin,
    compnayId,
    currentPage,
    getRole,
    itemsPerPage,
    paymentMode,
    selectedAgent,
  ]);

  const handlePageSizeChange = useCallback((event) => {
    event.preventDefault();
    setItemsPerPage(parseInt(event.target.value));
    setCurrentPage(1);
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    findPaymentData();
  }, [findPaymentData]);

  const floatValue = (value) => {
    return value.toFixed(2);
  };
  return (
    <>
      <PageTitle title="Payment" />
      <div className="p-2 mt-2">
        <div className="row align-items-center">
          <div className="col">
            <UserPanel title={"Payments"} />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div>
              <div className="row align-items-baseline justify-content-end mt-3 mx-2 mb-2">
                <div className="col d-flex gap-3">
                  {getRole === SuperAdmin || getRole === Accounts ? (
                    <div
                      style={{
                        width: "45%",
                      }}
                    >
                      <div className="form-floating">
                        <select
                          className="form-select rounded-4 text1-3 py-2"
                          aria-label="lead sort"
                          defaultValue={"All"}
                          onChange={handleAgentSelect}
                          id="floatingSelect"
                        >
                          <option value={"All"}>All</option>
                          {agentData.map((ad) => (
                            <option value={ad.id} key={ad.id}>
                              {ad.name}
                            </option>
                          ))}
                        </select>
                        <label for="floatingSelect">Filter List by Agent</label>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="w-25">
                    <div className="form-floating">
                      <select
                        className="form-select rounded-4 text1-3 py-2"
                        aria-label="payment-mode"
                        defaultValue={"All"}
                        onChange={handleModeChange}
                        id="floatingSelect"
                      >
                        <option value={""}>All</option>
                        {paymentOptions.map((ad) => (
                          <option value={ad.value} key={ad.value}>
                            {ad.label}
                          </option>
                        ))}
                      </select>
                      <label for="floatingSelect">Payment Mode</label>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="d-flex align-items-center justify-content-end text-dark1 column-gap-3">
                    <SelectItemsPerPage
                      handlePageSizeChange={handlePageSizeChange}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3 mb-4">
                {totalItems > 0 && (
                  <Pagination
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    dataLength={totalItems}
                    itemsPerPage={itemsPerPage}
                  />
                )}
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive rounded-4 text-center">
                    <table className="table custom-table">
                      <thead className="bg-dark1 text-white">
                        <tr className="py-3 text1-3">
                          <th scope="col">Transaction ID</th>
                          <th scope="col">Balance</th>
                          <th scope="col">Agent Name</th>
                          <th scope="col">Student Name</th>
                          <th scope="col">Application ID</th>
                          <th scope="col">Course & Intake</th>
                          <th scope="col">Application Fee</th>
                          <th scope="col">Promo Code Amount</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Type</th>
                          <th scope="col">Date</th>
                          <th scope="col">Remarks</th>
                        </tr>
                      </thead>
                      <tbody className="text1-4">
                        {paymentData?.map((transaction) => (
                          <tr key={transaction.id}>
                            <td>
                              {transaction.transactionId === "" ||
                              transaction.transactionId === null
                                ? transaction.id
                                : transaction.transactionId}
                            </td>
                            <td>n/a</td>
                            <td>
                              {
                                studentApplicationData.find(
                                  (ad) =>
                                    ad.id === transaction.studentApplicationId
                                )?.agentName
                              }
                            </td>
                            <td>
                              <Link
                                to={
                                  "/user/updateStudent/" + transaction.studentId
                                }
                                className="text-decoration-none font-bold text1-4"
                              >
                                {studentApplicationData.find(
                                  (ad) =>
                                    ad.id === transaction.studentApplicationId
                                )?.studentFirstName + " "}
                                {
                                  studentApplicationData.find(
                                    (ad) =>
                                      ad.id === transaction.studentApplicationId
                                  )?.studentLastName
                                }
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={
                                  "/user/viewApplication/" +
                                  transaction.studentApplicationId
                                }
                                className="text-decoration-none font-bold text1-4"
                              >
                                <FormatDate
                                  date={
                                    studentApplicationData.find(
                                      (ad) =>
                                        ad.id ===
                                        transaction.studentApplicationId
                                    )?.createdDate
                                  }
                                />
                                {
                                  studentApplicationData.find(
                                    (ad) =>
                                      ad.id === transaction.studentApplicationId
                                  )?.idNumber
                                }
                              </Link>
                            </td>
                            <td>
                              {
                                studentApplicationData.find(
                                  (ad) =>
                                    ad.id === transaction.studentApplicationId
                                )?.collegeProgramName
                              }
                              <br />
                              <FormatIntkeDate
                                date={
                                  studentApplicationData.find(
                                    (ad) =>
                                      ad.id === transaction.studentApplicationId
                                  )?.intake
                                }
                              />
                            </td>
                            <td>
                              {transaction.currency}{" "}
                              {floatValue(transaction.applicationFee)}
                            </td>
                            <td>{transaction.promoCodeAmount}</td>
                            <td>
                              {transaction.currency}{" "}
                              {transaction.currency !== "INR" && (
                                <>
                                  {floatValue(transaction.totalPayableAmount)}{" "}
                                  <br /> or <br /> INR{" "}
                                </>
                              )}
                              {floatValue(
                                transaction.totalPayableAmount *
                                  transaction.currencyConversionRate
                              )}
                            </td>
                            <td>
                              {transaction.mode ? transaction.mode : "N/A"}
                            </td>
                            <td>
                              <FormatDOB date={transaction.createdDate} />
                            </td>
                            <td>{transaction.remarks}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  {totalItems > 0 && (
                    <Pagination
                      currentPage={currentPage}
                      handlePageChange={handlePageChange}
                      dataLength={totalItems}
                      itemsPerPage={itemsPerPage}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
