import React from "react";
import { Spinner, Button } from "react-bootstrap";
import "./SpinnerGrow.css";

export default function SpinnerGrow() {
  return (
    <div className="fullscreen-backdrop">
      <div className="fullscreen-content">
        <Button variant="primary" disabled className="rounded-pill px-4 py-3">
          <div className="d-flex fs-2">
            <div>
              <Spinner animation="grow" variant="light" />
            </div>
            <div className="ms-3">Please Wait...</div>
          </div>
        </Button>
      </div>
    </div>
  );
}
