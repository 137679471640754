import React from "react";
import loanCorner from "../../assets/marketplace/loan-corner.png";
import gicCorner from "../../assets/marketplace/card-corner.png";
import loanMain from "../../assets/marketplace/loanMain.png";
import { useNavigate } from "react-router-dom";
import RolesService from "../../Services/RolesService";
import TokenService from "../../Services/TokenService";

export default function LoanCard({ setShowModal }) {
  const navigate = useNavigate();
  const { BranchHead } = RolesService;
  const getRole = TokenService.getUserRole();
  return (
    <div className="gic-card blue-bgImage">
      <img
        src={loanCorner}
        alt="loan"
        className="position-absolute top-0 start-0"
      />
      <img src={loanMain} className="position-absolute boyImage" alt="dots" />
      <p className="text2 text-dark1 my-0 position-absolute loan-text">
        Earn upto 50% Commission{" "}
      </p>
      <div className="px-5 w-100 d-flex  gap-4 position-absolute bottom-buttons">
        <button
          onClick={() => navigate("/user/marketplace/details")}
          className="bg-white text1-3 rounded-pill py-2 px-3 border-0 text-dark1"
        >
          View Details
        </button>
        {getRole === BranchHead ? (
          <button
            onClick={() => setShowModal(true)}
            className="bg-white text1-3 rounded-pill py-2 px-3 border-0 text-dark1 "
          >
            Apply Now
          </button>
        ) : null}
      </div>
    </div>
  );
}
