import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";

const SideBarButton = ({
  text,
  navTo,
  icon,
  linkClass,
  onClick,
  handleSidebarToggle,
}) => {
  const name = useMemo(() => {
    return text.split(" ").map((value, index) => {
      if (index === 0) {
        return value;
      } else {
        return (
          <React.Fragment key={index}>
            <br />
            {value}
          </React.Fragment>
        );
      }
    });
  }, [text]);

  return (
    <>
      <li className={`${handleSidebarToggle ? "mb-1" : "mb-2"}`}>
        <NavLink
          to={navTo}
          className={`py-3 d-flex align-items-center text-decoration-none ${linkClass} ${
            handleSidebarToggle ? "rounded-start-pill" : "rounded-start-pill"
          }`}
          onClick={onClick}
        >
          <div className="text-center px-1">
            <i
              className={`mx-3 text1-8 d-flex justify-content-center align-items-center text-orange ${icon}`}
            ></i>
            {handleSidebarToggle ? (
              <span className=" text-center px-1 ">{name}</span>
            ) : (
              ""
            )}
          </div>

          {handleSidebarToggle ? "" : <span>{text}</span>}
        </NavLink>
      </li>
    </>
  );
};

export default SideBarButton;
