import { useCallback, useEffect, useMemo, useState } from "react";
import Modal from "react-bootstrap/Modal";

import TokenService from "../../Services/TokenService";
import AppStripePayment from "./AppStripePayment";
import AgentsDataService from "../../Services/AgentsService";
import { toast } from "react-toastify";
import ExchangeRate from "../../Services/ExchangeRate";
import PaymentService from "../../Services/PaymentService";
import platformLogo from "../../assets/logo_dark.png";

function WalletPay({ findWalletData, retrieveDashbaord }) {
  const [show, setShow] = useState(false);
  const [payShow, setPayShow] = useState(false);
  const [clientSecret, setClientSecret] = useState("");

  const getUser = TokenService.getUser();

  const initialWalletData = useMemo(() => {
    return {
      amount: 0,
      remarks: "",
      companyId: getUser.companyId,
      type: "",
    };
  }, [getUser.companyId]);

  const [newWalletData, setNewWalletData] = useState(initialWalletData);

  const handleInputChange = useCallback(
    (e) => {
      e.preventDefault();
      const { name, value } = e.target;
      setNewWalletData({ ...newWalletData, [name]: value });
    },
    [newWalletData]
  );

  const handleClose = useCallback(() => {
    setShow(false);
    setPayShow(false);
    setNewWalletData(initialWalletData);
    setClientSecret("");
  }, [initialWalletData]);

  const stripePayStart = useCallback(async () => {
    setPayShow(true);
    try {
      const agentResponse = await AgentsDataService.get(getUser.companyId);
      // Create the payData object
      const payData = {
        name: getUser.firstName + " " + getUser.lastName,
        description: agentResponse.data.name,
        line1: agentResponse.data.address,
        postalCode: agentResponse.data.postalCode,
        city: agentResponse.data.city,
        state: agentResponse.data.state,
        country: agentResponse.data.country,
        amount: Number(newWalletData?.amount),
      };

      // Check for incomplete fields
      const incompleteFields = Object.keys(payData).filter(
        (key) => !payData[key]
      );

      if (incompleteFields.length > 0) {
        // There are incomplete fields, show an error toast
        toast.error(`Please update: ${incompleteFields.join(", ")}`);
        // You can handle this error further as needed.
      } else {
        // All fields are complete, proceed with the payment
        const paymentResponse = await PaymentService.stripepay(payData);
        setClientSecret(paymentResponse.data.clientSecret);
      }
    } catch (error) {
      toast.error(error);
    }
  }, [
    getUser.companyId,
    getUser.firstName,
    getUser.lastName,
    newWalletData?.amount,
  ]);

  return (
    <>
      <div
        className="d-flex align-items-center rounded-pill btn-light-blue-outline p-3 h6 text-decoration-none text1-6"
        onClick={() => setShow(true)}
      >
        <div
          className="bg-dark1 d-flex justify-content-center align-items-center rounded-circle me-2 text3"
          style={{ width: "32px", height: "32px" }}
        >
          <i className="bi bi-plus text-white d-flex justify-content-center align-items-center"></i>
        </div>
        Add Money
      </div>
      {show ? (
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
          size="md"
          className="custom-modal"
        >
          <Modal.Body className="p-2">
            <i
              className="bi bi-x-circle-fill text2 p-3 text-danger position-absolute end-0 top-0"
              style={{ cursor: "pointer" }}
              onClick={handleClose}
            ></i>
            <div className="d-flex flex-column align-items-center pt-5 gap-5">
              <img src={platformLogo} alt="platformLogo" height="80px" />
              <p className="text2 text-dark1 fw-bold">Add Money to Wallet</p>
            </div>
            {!payShow && (
              <form
                className="needs-validation px-5 pb-5 "
                onSubmit={stripePayStart}
              >
                <div className="row g-3 p-5">
                  <div>
                    <p className="text-dark1 text1-4">Amount</p>
                    <div className="col-12 form-floating">
                      <input
                        type="number"
                        className="form-control rounded-pill border"
                        placeholder="Amount"
                        required
                        id="amount"
                        name="amount"
                        value={newWalletData.amount}
                        onChange={handleInputChange}
                        min={1}
                      />
                      <label
                        htmlFor="floatingInput"
                        className="ms-4 text-light-gray"
                      >
                        Enter Amount<span className="text-danger">*</span>
                      </label>
                    </div>
                  </div>
                  <div>
                    <p className="text-dark1 text1-4">Remarks</p>
                    <div className="col-12 form-floating">
                      <input
                        type="text"
                        className="form-control rounded-pill border"
                        placeholder="Remarks"
                        required
                        id="remarks"
                        name="remarks"
                        value={newWalletData.remarks}
                        onChange={handleInputChange}
                      />
                      <label
                        htmlFor="floatingInput"
                        className="ms-4 text-light-gray"
                      >
                        Enter Remarks<span className="text-danger">*</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center gap-4">
                  <button
                    type="button"
                    className="btn btn-light-blue-outline rounded-pill py-2 me-2 px-5 text1-3"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="gradiantButton rounded-pill py-2 px-5 text1-3"
                  >
                    Submit
                  </button>
                </div>
              </form>
            )}
            {payShow && newWalletData.amount > 0 && (
              <AppStripePayment
                clientSecret={clientSecret}
                PaymentService={PaymentService}
                newWalletData={newWalletData}
                setNewWalletData={setNewWalletData}
                handleClose={handleClose}
                findWalletData={findWalletData}
                retrieveDashbaord={retrieveDashbaord}
              />
            )}
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  );
}

export default WalletPay;
