import http from "./api";

const get = (id) => {
  return http.get(`CollegeProgram?CollegeProgramId=${id}`);
};
const remove = (id) => {
  return http.delete(`CollegeProgram?id=${id}`);
};
const create = (data) => {
  return http.post("CollegeProgram", data);
};
const update = (data) => {
  return http.put(`CollegeProgram`, data);
};
const find = (params) => {
  return http.get("CollegeProgram/search?", { params });
};
const upload = (data) => {
  return http.post(`CsvFile/uploadandReadCsvFile`, data);
};
const CollegeProgramDataService = {
  get,
  remove,
  create,
  update,
  find,
  upload,
};

export default CollegeProgramDataService;
