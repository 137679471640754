import http from "./api";

const get = (id) => {
  return http.get(`Intake?IntakeId=${id}`);
};
const create = (data) => {
  return http.post("Intake", data);
};
const update = (data) => {
  return http.put(`Intake`, data);
};
const changeIntakeStatusId = (data) => {
  return http.put(`Intake/changeIntakeStatusId`, data);
};
const find = (params) => {
  return http.get("Intake/search?", { params });
};
const getListByCollegeIdAndCollegeProgramId = (data) => {
  return http.get(`Intake/getListByCollegeIdAndCollegeProgramId?collegeId=${data.collegeId}&collegeProgramId=${data.collegeProgramId}`);
};
const IntakeService = {
  get,
  create,
  update,
  changeIntakeStatusId,
  find,
  getListByCollegeIdAndCollegeProgramId,
};

export default IntakeService;
