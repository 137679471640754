import React, { useState, useEffect } from "react";
import UserPanel from "../UserPanel";
import Pagination from "../../components/UI/Pagination";
//import SearchBar from "../../components/UI/SearchBar";
import SelectItemsPerPage from "../../components/UI/SelectItemsPerPage";

import UserEdit from "./UserEdit";
import UserDataService from "../../Services/UserService";

import { Modal, Table } from "react-bootstrap";
import UserAdd from "./UserAdd";

import TokenService from "../../Services/TokenService";
import RoleService from "../../Services/RolesService";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/UI/ConfirmationModal";
import PageTitle from "../../components/PageTitle";
import EpLoader from "../../components/UI/EpLoader";

export default function UsersList() {
  const pageTitle = "User";
  const getRole = TokenService.getUserRole();
  const { SuperAdmin } = RoleService;

  const [loading, setLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const [usersList, setUsersList] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [itemsLength, setItemsLength] = useState(0);

  const getRequestParams = (currentPage, itemsPerPage) => {
    let params = {};
    if (currentPage) {
      params["currentPage"] = currentPage;
    }

    if (itemsPerPage) {
      params["pageSize"] = itemsPerPage;
    }

    return params;
  };

  const retrieveUsers = async () => {
    try {
      const params = getRequestParams(currentPage, itemsPerPage);
      const response = await UserDataService.find(params);

      const Users = response.data;
      setLoading(false);
      setUsersList(Users);
      setItemsLength(response.data[0]?.totalItems);
    } catch (error) {
      toast.error(
        (error.response && error.response.data) ||
        error.message ||
        error.toString()
      );
    }
  };

  const handlePageSizeChange = (event) => {
    event.preventDefault();
    setItemsPerPage(parseInt(event.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [itemToConfirm, setItemToConfirm] = useState(null);

  const handleDelete = async (e, data) => {
    e.preventDefault();
    setShowConfirmation(true);
    setItemToConfirm(data);
  };

  const handleConfirmation = async () => {
    setLoading(true);
    setShowConfirmation(false);
    try {
      const response = await UserDataService.remove(itemToConfirm.email);
      if (response.data === true) {
        setLoading(false);
        toast.info("Delete Successfully");
        retrieveUsers();
      } else {
        toast.info("Not Deleted");
      }
    } catch (e) {
      toast.error(
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString()
      );
    }
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const openModal = (user) => {
    setSelectedUser(user);
    setModalShow(true);
  };

  const closeModal = () => {
    setSelectedUser(null);
    setModalShow(false);
  };
  const [roles, setRoles] = useState([]);
  const getUserRoles = () => {
    UserDataService.getRoles().then((response) => {
      setRoles(response.data);
    });
  };
  useEffect(() => {
    getUserRoles();
  }, []);
  useEffect(() => {
    retrieveUsers();
  }, [currentPage, itemsPerPage]);

  if (loading) {
    return <EpLoader />;
  }

  return (
    <>
      <PageTitle title={pageTitle} />
      <div className="p-2 mt-2">
        <UserPanel title={"User"} />

        <div className="">
          <div className="row align-items-baseline mt-3 mx-2 mb-1">
            {/* <div className="col d-flex align-items-center column-gap-3">
              <SearchBar
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                searchTitle={"Search Users"}
              />
            </div> */}
            <div className="col">
              <div className="d-flex align-items-center justify-content-end text-dark1 column-gap-3">
                <UserAdd
                  retrieveUsers={retrieveUsers}
                  roles={roles}
                  RoleService={RoleService}
                  getRole={getRole}
                />
                <SelectItemsPerPage
                  handlePageSizeChange={handlePageSizeChange}
                  itemsPerPage={itemsPerPage}
                />
              </div>
            </div>
          </div>

          <div className="mt-3 mb-4">
            {itemsLength > 0 && (
              <Pagination
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                dataLength={itemsLength}
                itemsPerPage={itemsPerPage}
              />
            )}
          </div>

          <div className="row">
            <div className="col-12">
              <div className="table-responsive rounded-4 text-center">
                <Table className="table custom-table">
                  <thead className="bg-dark1 text-white">
                    <tr className="py-3 text1-3">
                      <th scope="col text-danger">Full Name</th>
                      <th scope="col">Username / Email</th>
                      <th scope="col">Contact</th>
                      <th scope="col">Role</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody className="text1-4">
                    {usersList?.map((user, index) => {
                      if (user.role === SuperAdmin || user.role === getRole) {
                        return null;
                      }
                      return (
                        <tr className="py-3" key={index}>
                          <td>
                            {user.firstName} {user.lastName}
                          </td>
                          <th>
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => openModal(user)}
                            >
                              {user.email}
                            </div>
                          </th>
                          <td>{user.phoneNumber}</td>
                          <td>{user.role}</td>
                          <td>
                            {user.statusId ? "Active" : "Inactive"}
                            {/* <div className="d-flex justify-content-center">
                              <div className="form-check form-switch">
                                <label className="form-check-label text1-2">{user.statusID ? "Active" : "Inactive"}</label>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="statusID"
                                  name="statusID"
                                  checked={user.statusId}
                                />
                              </div>
                            </div> */}
                          </td>
                          <td className="d-flex justify-content-center align-items-center">
                            {/* <button className="btn p-0 m-0" value={user.id}>
                        <i className="bi bi-lock-fill p-0 m-0 text2 text-warning"></i>
                        <i className="bi bi-unlock-fill p-0 m-0 text2 text-success"></i>
                      </button> */}

                            <div
                              onClick={(event) => {
                                if (user.statusId === 0)
                                  handleDelete(event, user);
                              }}
                              style={user.statusId ? {} : { cursor: "pointer" }}
                            >
                              <i
                                className={`text2-2 h1 ${user.statusId
                                  ? "bi bi-lock text-light-gray"
                                  : "bi bi-trash3 text-normal-red"
                                  }`}
                                style={{ minWidth: "24px", height: "24px" }}
                              ></i>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {itemsLength > 0 && (
                  <Pagination
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    dataLength={itemsLength}
                    itemsPerPage={itemsPerPage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {selectedUser && (
        <Modal
          show={modalShow}
          onHide={closeModal}
          centered
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header className="bg-dark1 text-white d-flex justify-content-between align-items-center py-2">
            <Modal.Title
              id="contained-modal-title-vcenter"
              className="d-flex align-items-center"
            >
              Update User details of {selectedUser.firstName}{" "}
              {selectedUser.lastName}
            </Modal.Title>
            <i
              className="bi bi-x-circle text2"
              style={{ cursor: "pointer" }}
              onClick={() => setModalShow(false)}
            ></i>
          </Modal.Header>
          <Modal.Body>
            <UserEdit
              user={selectedUser}
              roles={roles}
              retrieveUsers={retrieveUsers}
              setModalShow={setModalShow}
              RoleService={RoleService}
              getRole={getRole}
            />
          </Modal.Body>
        </Modal>
      )}
      <ConfirmationModal
        show={showConfirmation}
        onHide={handleCloseConfirmation}
        onConfirm={handleConfirmation}
        message={`Are you sure you want to delete ${itemToConfirm?.email}?`}
        title="Confirm Deletion"
      />
    </>
  );
}
