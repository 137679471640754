import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import PromoCodeDataService from "../../Services/PromoCode";
import platformLogo from "../../assets/logo_dark.png";


export default function PromoCode({ setSelectedPromoCode, currentApplication }) {
  const [modalShow, setModalShow] = useState(false);
  const [isPromoCodeOk, setIsPromoCodeOk] = useState(false);
  const [message, setMessage] = useState("");
  const [promoCode, setPromoCode] = useState("");

  const [collegePromoCode, setCollegePromoCode] = useState([]);

  const getCollegePromoCode = () => {
    try {
      PromoCodeDataService.getListByCollegeId(currentApplication.collegeId).then((response) => {
        if (response.data.success === true) {
          setCollegePromoCode(response.data.response);
        } else if (response.data.validationErrors) {
          response.data.validationErrors.map((verr) => toast.error(verr));
        } else {
          toast.error("Something Went Wrong");
        }
      });
    } catch (error) {
      toast.error(error.toString());
    }
  };
  useEffect(() => {
    getCollegePromoCode();
  }, [currentApplication]);

  const handlePromoCodeCheck = (e) => {
    e.preventDefault();
    const promoCodeExists = collegePromoCode.some((promo) => promoCode.toLowerCase() === promo.name.toLowerCase());

    if (collegePromoCode.length > 0 && promoCodeExists) {
      setIsPromoCodeOk(true);

      // Assuming you want to set the first matched promo code's value
      const matchedPromoCode = collegePromoCode.find((promo) => promoCode.toLowerCase() === promo.name.toLowerCase());
      if (matchedPromoCode) {
        setSelectedPromoCode(matchedPromoCode);
      }

      setTimeout(() => {
        setModalShow(false);
      }, 1000);
    } else {
      setMessage("Invalid Promo Code");
    }
  };

  const handleRemovePromoCode = () => {
    setIsPromoCodeOk(false);
    setSelectedPromoCode("");
    setPromoCode("");
    setMessage("");
  };

  return (
    <>
      {isPromoCodeOk ? (
        <a
          className=" link-danger link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover text1-8"
          style={{ cursor: "pointer" }}
          onClick={handleRemovePromoCode}
        >
          Remove Promo Code
        </a>
      ) : collegePromoCode.length === 0 ? (
        <a className=" link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover text1-6" disabled>
          Sorry, No Code avialable for you
        </a>
      ) : (
        <a
          className=" link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover text1-8"
          style={{ cursor: "pointer" }}
          onClick={() => setModalShow(true)}
        >
          Apply Promo Code
        </a>
      )}

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="md"
        className="custom-modal"
        centered
      >
        <Modal.Body>
          <i className="bi bi-x-circle-fill text2 p-3 text-danger position-absolute end-0 top-0" style={{ cursor: "pointer" }}
            onClick={() => setModalShow(false)}></i>
          <div className='d-flex flex-column align-items-center pt-5 gap-5'>

            <img src={platformLogo} alt='platformLogo' height='80px' />
            <p className='text2 text-dark1 fw-bold'>
              {collegePromoCode && collegePromoCode.length + " Promo Code avialable"}
            </p>
          </div>
          {isPromoCodeOk ? (
            <div className="text-center text-normal-green">
              <i className="bi bi-patch-check text6"></i>
              <div className="text1-4">Promo Code Accepted</div>
            </div>
          ) : (
            <form className="needs-validation" onSubmit={handlePromoCodeCheck}>
              {/* <span className="text1-3">
                For testing purpose use "
                {collegePromoCode.map((p, index) => (
                  <span key={index}>
                    {index > 0 && " OR "}
                    {p.name}
                  </span>
                ))}
                "
              </span> */}
              <div className="fs-4 px-5 text-dark1">Promo code</div>
              <div className="row g-3 p-3 text-center">
                <div className="col-12 form-floating px-3">
                  <input
                    type="text"
                    className="form-control rounded-pill border"
                    placeholder="Promo Code"
                    required
                    id="code"
                    name="code"
                    value={promoCode}
                    onChange={(e) => {
                      setPromoCode(e.target.value);
                    }}
                  />
                  <label htmlFor="floatingInput" className="ms-4 text-light-gray ">
                    Enter Promo Code
                  </label>
                </div>

                <div className="col-12 px-5 py-4 text1-4 m-0">
                  <span className="text-normal-red">{message}</span>
                </div>

                <div className="col-12 px-3">
                  <button
                    className="border-dark1 rounded-pill py-2 px-5 text1-3 me-4"
                    onClick={() => setModalShow(false)}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="gradiantButton rounded-pill py-2 px-5 text1-3 me-2">
                    Apply
                  </button>
                </div>
              </div>
            </form>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
