import React from "react";
import TokenService from "../Services/TokenService";

import Navbar from "../pages/Header/Navbar";
import RolesService from "../Services/RolesService";

export default function Header({ isScroll }) {
  const token = TokenService.getLocalAccessToken();
  const getRole = TokenService.getUserRole();
  return (
    <>
      <div>
        <Navbar
          isAuth={Boolean(token)}
          RolesService={RolesService}
          getRole={getRole}
          isScroll={isScroll}
        />
      </div>
    </>
  );
}
