import TokenService from "../../Services/TokenService";
import SectionsTitle from "../Components/SectionsTitle";

export default function Main({ setModalShow }) {
  const token = TokenService.getLocalAccessToken();
  const isAuth = Boolean(token);
  return (
    <div className=" m-4">
      <SectionsTitle title={"How it works"} />

      <div className="bg-dark1 rounded-4 mt-5 text-white">
        <div className="row  justify-content-between text-center py-2">
          <div className="col-md aos-init aos-animate" data-aos="zoom-out">
            <div className="step-count" style={{ fontSize: "96px" }}>
              01
            </div>
            <div style={{ fontSize: "26px" }}>Fill up a form</div>
            <div style={{ fontSize: "16px" }}>
              Submit required details and documents.
            </div>
          </div>
          <div className="col-md aos-init aos-animate" data-aos="zoom-out">
            <div className="step-count" style={{ fontSize: "96px" }}>
              02
            </div>
            <div style={{ fontSize: "26px" }}>Verification</div>
            <div style={{ fontSize: "16px" }}>Get documents verified.</div>
          </div>
          <div className="col-md aos-init aos-animate" data-aos="zoom-out">
            <div className="step-count" style={{ fontSize: "96px" }}>
              03
            </div>
            <div style={{ fontSize: "26px" }}>Welcome aboard!</div>
            <div style={{ fontSize: "16px" }}>
              Yay! You are an authorised {isAuth ? "agent" : "partner"}.
            </div>
          </div>
        </div>

        <div className="row justify-content-center text-center py-5">
          <div className="col-12 mb-4 ">
            <button
              className="btn btn-light rounded-pill px-5 py-3 text2"
              onClick={() => setModalShow(true)}
            >
              Become {isAuth ? "an agent" : "a partner"}
            </button>
          </div>
          <div className="col-12 text2">
            <span style={{ cursor: "pointer" }}>
              What happens after becoming {isAuth ? "an agent" : "a partner"}?
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
