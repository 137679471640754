import React, { useState, useRef } from "react";
import ApplicationNoteService from "../../Services/ApplicationNoteService";
import { toast } from "react-toastify";
import FileSizeService from "../../Services/FileSizeService";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
export default function SendNote({
  currentApplication,
  retrieveNotes,
  user,
  replyNote,
  setReplyNote,
}) {
  const fileInputRef = useRef(null);
  const { MinSize, MaxSize, MinSizeName, MaxSizeName } = FileSizeService;
  const inilizieData = { note: "" };

  const [saving, setSaving] = useState(false);
  const [newNote, setNewNote] = useState(inilizieData);
  const [files, setFiles] = useState([]);

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setNewNote({ ...newNote, [name]: value });

    if (e.target.files) {
      const selectedFiles = Array.from(e.target.files);

      const validFiles = selectedFiles.filter(
        (file) => file.size >= MinSize && file.size <= MaxSize
      );

      if (validFiles.length < selectedFiles.length) {
        selectedFiles.forEach((file) => {
          if (file.size < MinSize) {
            toast.error(`File size should be greater than ${MinSizeName}`);
          } else if (file.size > MaxSize) {
            toast.error(`File size should be less than ${MaxSizeName}`);
          }
        });
        return;
      }

      if (files.length + validFiles.length > 8) {
        toast.error("You can only upload up to 8 files.");
        return;
      }

      validFiles.forEach((file) => {
        const timestamp = new Date().getTime();
        const randomNumber = Math.floor(Math.random() * 10000);
        const parts = file.name.split(".");
        const extension = parts.slice(-1)[0];
        const fileNameWithExtension = `${user.role}_${timestamp}_${randomNumber}.${extension}`;

        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (e) => {
          const base64String = btoa(e.target.result);
          setFiles((prevFiles) => [
            ...prevFiles,
            {
              fileName: fileNameWithExtension,
              fileType: file.type,
              base64String,
            },
          ]);
        };
      });
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);

    const validFiles = droppedFiles.filter(
      (file) => file.size >= MinSize && file.size <= MaxSize
    );

    if (validFiles.length < droppedFiles.length) {
      droppedFiles.forEach((file) => {
        if (file.size < MinSize) {
          toast.error(`File size should be greater than ${MinSizeName}`);
        } else if (file.size > MaxSize) {
          toast.error(`File size should be less than ${MaxSizeName}`);
        }
      });
      return;
    }

    if (files.length + validFiles.length > 8) {
      toast.error("You can only upload up to 8 files.");
      return;
    }

    validFiles.forEach((file) => {
      const timestamp = new Date().getTime();
      const randomNumber = Math.floor(Math.random() * 10000);
      const parts = file.name.split(".");
      const extension = parts.slice(-1)[0];
      const fileNameWithExtension = `${user.role}_${timestamp}_${randomNumber}.${extension}`;

      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = (e) => {
        const base64String = btoa(e.target.result);
        setFiles((prevFiles) => [
          ...prevFiles,
          {
            fileName: fileNameWithExtension,
            fileType: file.type,
            base64String,
          },
        ]);
      };
    });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const saveNote = async () => {
    setSaving(true);
    const filesPayload = files.reduce((acc, file) => {
      acc[file.fileName] = file.base64String;
      return acc;
    }, {});

    const data = {
      studentApplicationId: currentApplication.id,
      note: newNote.note,
      noteType: 0,
      statusId: 0,
      files: filesPayload,
      userFirstName: user.firstName,
      userLastName: user.lastName,
      userRole: user.role,
      reply: replyNote.id,
    };

    try {
      const response = await ApplicationNoteService.create(data);
      if (response.data.success) {
        retrieveNotes();
        toast.success(response.data.message);
        setNewNote(inilizieData);
        setFiles([]);
        setReplyNote({
          message: "",
          id: "",
        });
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        setSaving(false);
      } else if (response.data.validationErrors.length > 0) {
        response.data.validationErrors.forEach((verr) => toast.error(verr));
        setSaving(false);
      } else {
        setSaving(false);
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      setSaving(false);
      toast.error(error.message);
    }
  };

  return (
    <>
      {currentApplication.epAssigneeUserId ===
      "00000000-0000-0000-0000-000000000000" ? (
        <div className="text-center text2 p-4">
          No Admission Officer is selected. To send note Admission Officer is
          required
        </div>
      ) : (
        <div className="row">
          <div className="col-12 px-5 position-relative">
            <div
              className="position-relative note-input"
              onDrop={handleDrop}
              onDragOver={handleDragOver}
            >
              <div className="p-3 bg-light-blue d-flex flex-column gap-3 rounded-4">
                {replyNote.id && (
                  <div className="w-100 rounded-4 border px-4 py-3 d-flex justify-content-between align-items-center">
                    <span className="text1-3 opacity-75">
                      {replyNote.message}
                    </span>
                    <i
                      className="bi bi-x text1-8 p-0"
                      style={{ cursor: "pointer" }}
                      onClick={() => setReplyNote({ message: "", id: "" })}
                    ></i>
                  </div>
                )}
                <textarea
                  className="form-control rounded-4 border text1-4 ps-4"
                  id="note"
                  placeholder="Enter New Note"
                  required
                  style={{
                    height: "160px",
                    paddingRight: "100px",
                    resize: "none",
                  }}
                  name="note"
                  value={newNote.note}
                  onChange={handleInputChange}
                  disabled={saving}
                ></textarea>
              </div>
              <div className="d-flex flex-wrap justify-content-end position-absolute bottom-0 end-0 me-5 mt-2">
                {files.map((file, index) => (
                  <div
                    key={index}
                    className="d-flex mb-4 flex-column justify-content-center align-items-center position-relative"
                  >
                    {file.fileType.startsWith("application/") ? (
                      <>
                        <button
                          className="btn rounded-4 btn-success d-flex align-items-center justify-content-center text1-4 me-3"
                          type="button"
                          style={{ width: "35px", height: "35px" }}
                        >
                          <i className="bi bi-file-earmark-text text2-5 mt-1"></i>
                        </button>
                        <div className="note-file">{file.fileName}</div>
                      </>
                    ) : (
                      <img
                        src={`data:${file.fileType};base64,${file.base64String}`}
                        alt="Uploaded"
                        className="mb-2 me-2 rounded"
                        style={{ width: "40px", height: "40px" }}
                      />
                    )}
                    <button
                      className="btn btn-link btn-cross position-absolute note-cross"
                      onClick={() =>
                        setFiles(files.filter((_, i) => i !== index))
                      }
                    >
                      <i className="bi bi-x-circle-fill text-danger text1-3"></i>
                    </button>
                  </div>
                ))}
                {files.length < 8 && (
                  <div
                    onClick={handleUploadButtonClick}
                    style={saving ? { cursor: "wait" } : { cursor: "pointer" }}
                  >
                    <input
                      ref={fileInputRef}
                      className="form-control rounded-pill d-none"
                      type="file"
                      id="file"
                      name="file"
                      accept="image/jpeg, image/jpg, image/png, application/pdf"
                      multiple
                      onChange={handleInputChange}
                      disabled={saving}
                    />
                    <i className="bi bi-paperclip text2-5 text-dark1"></i>
                  </div>
                )}

                {saving ? (
                  <span className="spinner-border"></span>
                ) : (
                  <i
                    className="bi bi-send text2-5 text-dark1 ms-3 mb-2"
                    style={{ cursor: "pointer" }}
                    onClick={saveNote}
                  ></i>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
