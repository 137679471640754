import React, { useState } from "react";
import MainSearch from "../../components/UI/MainSearch";
import { useNavigate } from "react-router-dom";
import { usePlaceholder } from "../../shared/hooks/usePlaceholder";

export default function SearchProgramToAdd({ studentData }) {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(["Canada"]);

  const handleHomeProgramSearch = () => {
    navigate("/user/program", {
      state: { searchQuery, selectedCountry, studentData },
    });
  };

  const [isInputClicked, setIsInputClicked] = useState(false);
  const { placeholder } = usePlaceholder({
    stopChangingText: isInputClicked,
  });
  return (
    <div>
      <div className="border rounded-4 bg-light-blue1 text1-5 p-3 mb-4">
        <div className="d-flex align-items-center bg-stripped1 border border-top-0 rounded-4 p-3 font-bold  mb-3">
          Search a program/institute to add
        </div>
        <div className="row justify-content-center">
          <div className="col-6">
            <MainSearch
              setSearchQuery={setSearchQuery}
              searchQuery={searchQuery}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              handleHomeProgramSearch={handleHomeProgramSearch}
              placeholder={placeholder}
              isInputClicked={isInputClicked}
              setIsInputClicked={setIsInputClicked}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
