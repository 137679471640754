import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import RolesService from "../../Services/RolesService";
import TokenService from "../../Services/TokenService";
import PageTitle from "../../components/PageTitle";
import { ComponentLoader } from "../../components/UI/Loader";
import Pagination from "../../components/UI/Pagination";
import UserPanel from "../UserPanel";
import { AgentsDropdown } from "./AgentsDropdown";
import { ApplicationsDropdown } from "./ApplicationsDropdown";
import "./Refund.css";
import RefundDialog from "./RefundDialog";
import { useRefund } from "./useRefund";

function RefundList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { Accounts } = RolesService;
  const getRole = TokenService.getUserRole();
  const [refundDialog, setRefundDialog] = useState(false);
  const {
    dropdownData,
    inputData,
    setInputData,
    handleChange,
    setSelected,
    formatDate,
    loading,
    ledger,
    getRefundLedger,
    handlePageChange,
  } = useRefund();

  return (
    <>
      {refundDialog ? (
        <RefundDialog
          modalShow={refundDialog}
          setModalShow={setRefundDialog}
          formatDate={formatDate}
          dropdownData={dropdownData}
          getRefundLedger={getRefundLedger}
          ledger={ledger}
        />
      ) : null}

      <PageTitle title="Refunds" />
      <div className="p-2 mt-2">
        <div className="row align-items-center">
          <div className="col">
            <UserPanel title={"Transaction History"} />
          </div>
        </div>
      </div>
      <>
        <div className="row justify-content-between mt-3 mx-2 mb-2">
          <div className="col d-flex align-items-center column-gap-3">
            <div className="form-floating text-white position-relative">
              <AgentsDropdown
                list={dropdownData.agents}
                value={inputData.agents}
                inputClassnames={`align-self-end select-container text1-4 bg-dark1 text-white mt-3`}
                handleInputChange={(e) =>
                  handleChange(e.target.value, "agents")
                }
                handleItemClick={(item) => {
                  if (!item) handleChange("", "agents");
                  setInputData((p) => ({
                    ...p,
                    agents: item
                      ? `${item.firstName} ${item.lastName}`
                      : "All Agents",
                  }));
                  setSelected((p) => ({
                    ...p,
                    agents: item ? item.id : "",
                  }));
                  searchParams.set("page", "1");
                  setSearchParams(searchParams);
                }}
              />
              <label className="text-white text1-2" htmlFor="floatingInput">
                Agent
              </label>
            </div>
            <div className="form-floating text-white position-relative">
              <ApplicationsDropdown
                inputClassnames="align-self-end select-container text1-4 bg-dark1 text-white mt-3 "
                value={inputData.applicationIds}
                list={dropdownData.applicationIds}
                handleInputChange={(e) =>
                  handleChange(e.target.value, "applicationIds")
                }
                handleItemClick={(item) => {
                  if (!item) handleChange("", "applicationIds");
                  const id = formatDate(item?.createdDate || "");
                  setInputData((p) => ({
                    ...p,
                    applicationIds: item
                      ? `${id}-${item.idNumber}`
                      : "All Application IDs",
                  }));
                  setSelected((p) => ({
                    ...p,
                    applicationIds: item ? item.id : "",
                  }));
                  searchParams.set("page", 1);
                  setSearchParams(searchParams);
                }}
              />
              <label className="text-white text1-2" htmlFor="floatingInput">
                Application ID
              </label>
            </div>
          </div>
          <div className="col d-flex justify-content-end ">
            {getRole === Accounts && (
              <button
                className="btn btn-dark-blue rounded-pill py-2 px-5 text1-4"
                onClick={() => setRefundDialog(true)}
              >
                <span>Send Money</span>
              </button>
            )}
          </div>
        </div>
        <div className="mt-3 mb-4">
          {ledger?.refundLedgers?.length > 0 ? (
            <Pagination
              currentPage={searchParams.get("page") ?? 1}
              handlePageChange={handlePageChange}
              dataLength={ledger?.refundLedgers[0]?.totalItems}
              itemsPerPage={searchParams.get("limit") ?? 10}
            />
          ) : null}
        </div>
        <div className="row">
          <div className="col-12">
            <div className="table-responsive rounded-4">
              <table className="table custom-table">
                <thead className="bg-dark1 text-white text-center">
                  <tr className="py-1 text1-3">
                    <th scope="col">Agent Name</th>
                    <th scope="col">Application ID</th>
                    <th scope="col">Amount</th>
                    <th scope="col"> Transaction Date</th>
                  </tr>
                </thead>
                {loading ? (
                  <td colSpan={4}>
                    <ComponentLoader />
                  </td>
                ) : (
                  <tbody className="text1-4">
                    {ledger?.refundLedgers?.map((entry) => (
                      <tr>
                        <td className="text-center">{entry?.receiverName}</td>
                        <td className="text-center">
                          {`${formatDate(entry.studentApplicationCreated)}-${
                            entry.studentApplicationIdNumber
                          }`}
                        </td>
                        <td className="text-center">
                          ${entry?.amount?.toFixed(2)}
                        </td>
                        <td className="text-center">
                          {entry?.created?.slice(0, 10)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
        {ledger?.refundLedgers?.length > 0 ? (
          <Pagination
            currentPage={searchParams.get("page") ?? 1}
            handlePageChange={handlePageChange}
            dataLength={ledger?.refundLedgers[0]?.totalItems}
            itemsPerPage={searchParams.get("limit") ?? 10}
          />
        ) : null}
      </>
    </>
  );
}

export default RefundList;
