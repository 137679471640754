import React from "react";

export default function SideBarMenuButton({ index, section, setActiveSection, activeSection }) {
  return (
    <h2 className="accordion-header">
      <button
        className="accordion-button fw-bold fs-4"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={`#collapse${index}`}
        aria-expanded={index === activeSection}
        aria-controls={`collapse${index}`}

        //onClick={() => setActiveSection(index)}
      >
        {section.webPage}
      </button>
    </h2>
  );
}
