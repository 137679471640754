import http from "./api";

const get = (id) => {
  return http.get(`Wallet?WalletId=${id}`);
};
const remove = (id) => {
  return http.delete(`Wallet/${id}`);
};
const create = (data) => {
  return http.post("Wallet", data);
};
const update = (data) => {
  return http.put(`Wallet`, data);
};
const find = (data) => {
  return http.post("Wallet/search?", data);
};
const getAllWalletList = (data) => {
  return http.post("Wallet/getAllWalletList?", data);
};
const WalletDataService = {
  get,
  remove,
  create,
  update,
  find,
  getAllWalletList,
};

export default WalletDataService;
