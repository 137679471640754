import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import NestedSideBarButton from "./Components/NestedSideBarButton";
import SideBarMenuButton from "./Components/SideBarMenuButton";
import logoTextDark from "./Assets/logo_text_dark.png";
const Sidebar = () => {
  const location = useLocation();
  const pathname = location.pathname;

  const [activeSection, setActiveSection] = useState(0);
  const [activeLink, setActiveLink] = useState(null);

  const [sideMenuList, setSideMenuList] = useState([]);

  // Load activeLink from localStorage on component mount
  useEffect(() => {
    const storedActiveLink = localStorage.getItem("activeLink");
    if (storedActiveLink !== null) {
      setActiveLink(storedActiveLink);
    }
  }, []);

  // Update localStorage when activeLink changes
  useEffect(() => {
    if (activeLink !== null) {
      localStorage.setItem("activeLink", activeLink);
    }
  }, [activeLink, pathname]);

  useEffect(() => {
    // Initialize sideMenuList

    setSideMenuList([
      {
        webPage: "EP Management",
        links: [
          { linkName: "Colleges", linkURL: "common-api/colleges" },
          { linkName: "Programs", linkURL: "common-api/programs" },
          { linkName: "Intakes", linkURL: "common-api/intakes" },
          {
            linkName: "Promo Codes",
            linkURL: "common-api/promo-code",
          },
          {
            linkName: "Announcements",
            linkURL: "common-api/announcements",
          },
          // { linkName: "Vouchers", linkURL: "common-api/vouchers" },
          // { linkName: "Wallet", linkURL: "common-api/wallet" },
        ],
      },
      {
        webPage: "Main EP",
        links: [
          { linkName: "Blogs", linkURL: "main-ep/blogs" },
          //  { linkName: "Student Review", linkURL: "main-ep/student-review" },
        ],
      },
      // {
      //   webPage: "Students",
      //   links: [
      //     {
      //       linkName: "Institutions",
      //       linkURL: "student-web-pages/institution",
      //     },
      //     {
      //       linkName: "Featured Scholarship",
      //       linkURL: "student-web-pages/featured-scholarship",
      //     },
      //     {
      //       linkName: "Featured Program",
      //       linkURL: "student-web-pages/featured-program",
      //     },
      //     {
      //       linkName: "Student Success Stories",
      //       linkURL: "student-web-pages/student-success-stories",
      //     },

      //     { linkName: "FAQ", linkURL: "student-web-pages/faq" },
      //   ],
      // },

      // {
      //   webPage: "Instituions",
      //   links: [
      //     { linkName: "carousel", linkURL: "institution/landing-page-carousel" },
      //     { linkName: "testimonial", linkURL: "institution/testimonial" },
      //     { linkName: "work-feature", linkURL: "institution/work-feature" },
      //   ],
      // },
    ]);
  }, []);

  return (
    <>
      <div className="py-2" style={{ minHeight: "calc(100vh - 2rem)" }}>
        <div className="mx-auto  d-flex justify-content-center align-items-center mb-4 ">
          <img
            src={logoTextDark}
            alt={logoTextDark}
            style={{ width: "128px" }}
          />
        </div>
        <div className="accordion accordion-flush" id="accordionExample">
          {sideMenuList.map((section, index) => (
            <div
              className={`accordion-item bg-body-tertiary ${
                index === activeSection ? "active" : ""
              }`}
              key={index}
            >
              <SideBarMenuButton
                index={index}
                section={section}
                setActiveSection={setActiveSection}
                activeSection={activeSection}
              />

              <div
                id={`collapse${index}`}
                className={`accordion-collapse collapse ${
                  index === activeSection ? "show" : ""
                }`}
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  {section.links.map((link, linkIndex) => (
                    <NestedSideBarButton
                      key={linkIndex}
                      title={link.linkName}
                      navTo={link.linkURL}
                      activeLink={activeLink}
                      setActiveLink={setActiveLink}
                    />
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
