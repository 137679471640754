import React from "react";
import FormatIntkeDate from "../../../components/UI/FormatIntkeDate";

const durationNumberOptions = Array.from({ length: 12 }, (_, index) => index + 1);

export default function AdvanceFilterMain({
  filterTabList,
  activeIndex,
  handleActive,
  handleCheckboxClick,
  checkedOptions,
  advanceDataList,
  handleDurationAndFee,
  durationAndFee,
  setDurationAndFee,
  appliedDurationAndFee,
  formatSessionIntakeDate
}) {
  return (
    <>
      <div className="d-flex align-items-start">
        <div
          className="nav flex-column nav-pills me-1 text-dark border-end gap-2"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
          style={{ height: "480px" }}
        >
          {filterTabList.map((filterTab, index) => (
            <div
              key={index}
              className={` ${activeIndex === index ? "bg-light-blue1" : ""} pe-2 py-2 d-flex align-items-center text1-4`}
              id={`v-pills-${filterTab.navTo}-tab`}
              data-bs-toggle="pill"
              data-bs-target={`#v-pills-${filterTab.navTo}`}
              type="button"
              role="tab"
              aria-controls={`v-pills-${filterTab.navTo}`}
              aria-selected={activeIndex === index ? "true" : "false"}
              onClick={() => handleActive(index)}
              style={{ minWidth: "130px" }}
            >
              {activeIndex === index ? <div className="bg-dark1 ps-1 py-4"></div> : <div className="me-2 py-4"></div>}
              {activeIndex === index ? (
                <span className="ms-2">
                  <strong>{filterTab.tabTitle}</strong>
                </span>
              ) : (
                <span>{filterTab.tabTitle}</span>
              )}
            </div>
          ))}

          <div
            key={77}
            className={` ${activeIndex === 77 ? "bg-light-blue1" : ""} pe-2 d-flex align-items-center text1-4`}
            id={`v-pills-${"DurationFee"}-tab`}
            data-bs-toggle="pill"
            data-bs-target={`#v-pills-${"DurationFee"}`}
            type="button"
            role="tab"
            aria-controls={`v-pills-${"DurationFee"}`}
            aria-selected={activeIndex === 77 ? "true" : "false"}
            onClick={() => handleActive(77)}
            style={{ minWidth: "130px" }}
          >
            {activeIndex === 77 ? <div className="bg-dark1 ps-1 me-1 py-4"></div> : <div className="me-2 py-4"></div>}
            {activeIndex === 77 ? (
              <span className="ms-2">
                <strong>{"Duration & Fee"}</strong>
              </span>
            ) : (
              <span>{"Duration & Fee"}</span>
            )}
          </div>
        </div>
        <div className="tab-content px-3" id="v-pills-tabContent" style={{ height: "480px" }}>
          {filterTabList?.map((filterTab, index) => (
            <div
              key={index}
              className={`tab-pane fade ${activeIndex === index ? "show active" : ""} mb`}
              id={`v-pills-${filterTab.navTo}`}
              role="tabpanel"
              aria-labelledby={`v-pills-${filterTab.navTo}-tab`}
              tabIndex="0"
              style={{ minWidth: "300px" }}
            >
              <div className="overflow-y-scroll pe-4" style={{ maxHeight: "450px" }}>
                {filterTab.optionsList?.map((option, optionIndex) => (
                  <ul className="m-0 p-0 text1-4" key={optionIndex}>
                    <li
                      className="border-bottom py-3 d-flex justify-content-between align-items-center blue-checkbox"
                      onClick={() => handleCheckboxClick(filterTab.tabTitle, option)}
                      //onClick={() => handleCheckboxClick(index, optionIndex)}
                      style={{ cursor: "pointer" }}
                    >
                      <span className="pe-2">
                        {filterTab.tabTitle === "Areas of Interest" ? (
                          option?.replace(/\//g, ",")
                        ) : filterTab.tabTitle === "Intake" ? (
                          formatSessionIntakeDate(option)
                        ) : (
                          option
                        )}
                      </span>

                      <input
                        className="form-check-input"
                        style={{ cursor: "pointer" }}
                        type="checkbox"
                        value=""
                        id={`${filterTab.id}-option-${index}`}
                        checked={checkedOptions[filterTab.tabTitle]?.includes(option) || false}
                        readOnly
                      //onChange={() => handleCheckboxClick(index, optionIndex)}
                      />
                    </li>
                  </ul>
                ))}
              </div>
            </div>
          ))}

          <div
            key={77}
            className={`tab-pane fade ${activeIndex === 77 ? "show active" : ""} mb-4`}
            id={`v-pills-${"DurationFee"}`}
            role="tabpanel"
            aria-labelledby={`v-pills-${"DurationFee"}-tab`}
            tabIndex="0"
            style={{ minHeight: "480px" }}
          >
            <div>
              <div className="mt-3 text1-4 mx-3">
                <div className="fw-bold pt-3">Select Duration</div>

                <div className="row">
                  <div className="col-6">
                    <select
                      className="form-select rounded-pill border py-2 text1-4"
                      aria-label="Select Year"
                      required
                      name="durationNumber"
                      value={durationAndFee.durationNumber === 0 ? "" : durationAndFee.durationNumber}
                      onChange={handleDurationAndFee}
                    >
                      <option value={""} disabled>
                        {durationAndFee.durationNumber === appliedDurationAndFee.durationNumber
                          ? appliedDurationAndFee.durationNumber
                          : "Number"}
                      </option>
                      {durationNumberOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* <Counter durationAndFee={durationAndFee} setDurationAndFee={setDurationAndFee} /> */}
                  <div className="col-6">
                    <select
                      className="form-select rounded-pill border py-2 text1-4"
                      aria-label="Select Year"
                      required
                      name="durationPeriod"
                      value={durationAndFee.durationPeriod}
                      onChange={handleDurationAndFee}
                    >
                      <option value="" disabled>
                        Period
                      </option>
                      <option value="Year">Year</option>
                      <option value="Month">Month</option>
                    </select>
                  </div>

                  <div className="col-12 pt-3">
                    <div className="fw-bold">
                      Application Fee: {durationAndFee.applicationFee === -1.0 ? "All" : durationAndFee.applicationFee}
                    </div>
                    <input
                      type="range"
                      className="w-100"
                      id="applicationFee"
                      name="applicationFee"
                      min={0} // Set the minimum value 0
                      max={advanceDataList.highestCollegeApplicationFee ? advanceDataList.highestCollegeApplicationFee : 0} // Set highies Fee Value
                      value={durationAndFee.applicationFee}
                      onChange={handleDurationAndFee}
                    />
                    <div className="d-flex justify-content-between">
                      <div>0</div>
                      <div>{advanceDataList.highestCollegeApplicationFee ? advanceDataList.highestCollegeApplicationFee / 2 : 0}</div>
                      <div>{advanceDataList.highestCollegeApplicationFee ? advanceDataList.highestCollegeApplicationFee : 0}</div>
                    </div>
                  </div>
                  <div className="col-12 pt-3">
                    <div className="fw-bold">
                      Tution Fee: {parseFloat(durationAndFee.tuitionFee) === -1.0 ? "All" : durationAndFee.tuitionFee}
                    </div>
                    <input
                      type="range"
                      className="w-100"
                      id="tuitionFee"
                      name="tuitionFee"
                      min={0} // Set the minimum value 0
                      max={advanceDataList.highestProgramTuitionFee ? Math.ceil(advanceDataList.highestProgramTuitionFee) : 0} // Set highies Fee Value
                      value={durationAndFee.tuitionFee}
                      onChange={handleDurationAndFee}
                    />
                    <div className="d-flex justify-content-between">
                      <div>0</div>
                      <div>{advanceDataList.highestProgramTuitionFee ? Math.ceil(advanceDataList.highestProgramTuitionFee) / 2 : 0}</div>
                      <div>{advanceDataList.highestProgramTuitionFee ? Math.ceil(advanceDataList.highestProgramTuitionFee) : 0}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
