import React from "react";

const SearchBar = ({
  searchQuery,
  handleSearchInput,
  clearSearchQuery,
  searchTitle,
  paddingY = "py-2",
}) => {
  return (
    <div className="position-relative mb-2">
      <input
        type="text"
        className={`form-control rounded-4 border ${
          paddingY ? paddingY : "py-2"
        } mx-2 fs-5`}
        placeholder={searchTitle}
        style={{ width: "280px" }}
        value={searchQuery}
        onChange={handleSearchInput}
        required
      />

      <div className="top-50 end-0 position-absolute translate-middle-y me-3">
        <button
          className="btn btn-outline-dark rounded-circle"
          style={{ width: "24px", height: "24px" }}
          onClick={clearSearchQuery}
        >
          <span className="d-flex justify-content-center align-items-center">
            X
          </span>
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
