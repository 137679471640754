import React from "react";
import become_agent from "../../assets/landingPage/become_agent.png";
import become_partner from "../../assets/landingPage/become_partner.png";
import try_institution from "../../assets/landingPage/try_institution.png";
import TokenService from "../../Services/TokenService";
export default function Section4() {
  const token = TokenService.getLocalAccessToken();
  const isAuth = Boolean(token);
  return (
    <>
      <div className="container-fluid my-md-5 pb-md-5">
        <div className="row justify-content-center gap-sm-0">
          <div className="col-12 text-center my-5 sections-title font-bold">
            <span className="text-dark0">Try it </span>
            <span className="text-dark1">today</span>
          </div>

          <div
            className={isAuth ? "col-10 col-sm-3 m-5" : "col-10 col-sm-3 m-5"}
          >
            <div className="position-relative">
              <img
                src={become_agent}
                className="img-fluid mb-2"
                alt="become_agent"
              />
              <div className="position-absolute top-0 start-50 translate-middle">
                <div className="bg-white rounded-pill px-4 py-3 text1-4">
                  <strong>{isAuth ? "Agents" : "Partners"}</strong>
                </div>
              </div>
            </div>
            <div className="m-2">
              <p className="text-dark2 text1-2">
                {isAuth ? "Agents" : "Partners"} help students choose the right
                university and courses/programs. Agents also assist students in
                filling applications and scholarships.
              </p>
            </div>
            <div className="text-center">
              <button className="btn bg-dark1 text-white rounded-pill mt-2 p-3 text1-2">
                Become {isAuth ? "an Agent" : "a Partner"}
              </button>
            </div>
          </div>
          {isAuth && (
            <div className="col-10 col-sm-3 m-5">
              <div className="position-relative">
                <img
                  src={become_partner}
                  className="img-fluid mb-2"
                  alt="become_partner"
                />
                <div className="position-absolute top-0 start-50 translate-middle">
                  <div className="bg-white rounded-pill px-4 py-3 text1-4">
                    <strong>Partner</strong>
                  </div>
                </div>
              </div>
              <div className="m-2">
                <p className="text-dark2 text1-2">
                  Partners help in increasing the complete network of students
                  and institutions in order give students the best university
                  choices.
                </p>
              </div>
              <div className="text-center">
                <button className="btn bg-dark1 text-white rounded-pill mt-2 p-3 text1-2">
                  Become an partner
                </button>
              </div>
            </div>
          )}

          <div
            className={isAuth ? "col-10 col-sm-3 m-5" : "col-10 col-sm-3 m-5"}
          >
            <div className="position-relative">
              <img
                src={try_institution}
                className="img-fluid mb-2"
                alt="try_institution"
              />
              <div className="position-absolute top-0 start-50 translate-middle">
                <div className="bg-white rounded-pill px-4 py-3 text1-4">
                  <strong>Institutes</strong>
                </div>
              </div>
            </div>
            <div className="m-2">
              <p className="text-dark2 text1-2">
                Institutions are the focus point for students. Connecting
                students and institution is important. Institutes associate with
                us in order get eligible students to enroll.
              </p>
            </div>
            <div className="text-center">
              <button className="btn bg-dark1 text-white rounded-pill mt-2 p-3 text1-2">
                Get started
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
