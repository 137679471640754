import React, { useState, useEffect } from "react";

import { toast } from "react-hot-toast";
import PageTitle from "../../../components/PageTitle";
import ListDiscount from "./ListPromoCode";
import SpinnerGrow from "../../Components/SpinnerGrow";

import CollegeDataService from "../../Services/CollegesService";
import PromoCodeDataService from "../../Services/PromoCode";
import SearchBar from "../../Components/SearchBar";
import SelectItemsPerPage from "../../Components/SelectItemsPerPage";
import AddPromoCode from "./AddPromoCode";
import AdvancePagination from "../../Components/Pagination";
import ConfirmationModal from "../../Components/ConfirmationModal";
import SpinnerBorder from "../../Components/SpinnerBorder";
import ProgramSearchService from "../../../Services/ProgramSearchService";

export default function MainPromoCode() {
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  const pageTitle = "Promo Code";

  const initializeData = [
    {
      collegeId: "",
      companyId: "",
      name: "",
      value: 0,
      activeDate: "",
      expiryDate: "",
      statusId: 0,
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [itemsLength, setItemsLength] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const [collegeData, setCollegeData] = useState([]);
  const getCollegeData = async () => {
    try {
      const response = (await ProgramSearchService.getCountryColleges("")).data;
      setCollegeData(response);
    } catch (error) {
      toast.error((error.response && error.response.data && error.response.data.message) || error.message || error.toString());
    }
  };

  const [promoData, setPromoData] = useState(initializeData);

  const getPromoCode = async () => {
    setUpdating(true);
    try {
      await getCollegeData();
      const data = {
        search: searchQuery,
        currentPage,
        pageSize,
      };
      const response = await PromoCodeDataService.find(data);
      const responseData = response.data;
      setPromoData(responseData);
      setLoading(false);
      setItemsLength(responseData[0]?.totalItems);
      setUpdating(false);
    } catch (error) {
      toast.error(error);
      setUpdating(false);
    }
  };

  useEffect(() => {
    getPromoCode();
  }, [searchQuery, currentPage, pageSize, pageTitle]);

  const handleSearchInput = (e) => {
    e.preventDefault();
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const clearSearchQuery = () => {
    setSearchQuery("");
  };

  const handlePageSizeChange = (event) => {
    event.preventDefault();
    setPageSize(parseInt(event.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [itemToConfirm, setItemToConfirm] = useState(null);

  const handleDelete = (e, data) => {
    e.preventDefault();
    setItemToConfirm(data);
    setShowConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    setShowConfirmation(false);
    if (itemToConfirm) {
      try {
        await toast.promise(PromoCodeDataService.remove(itemToConfirm.id), {
          loading: "Deleting...",
          success: <b>{itemToConfirm.name} deleted successfully!</b>,
          error: <b>Could not delete {itemToConfirm.name}</b>,
        });
        setItemToConfirm(null);
        getPromoCode(); // updates the data after deletion
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
    setItemToConfirm(null);
  };

  if (loading) return <SpinnerGrow />;

  return (
    <>
      <PageTitle title={pageTitle} />
      <div className="row px-5">
        <div className="text-center fs-1 text-uppercase col-12">{pageTitle}</div>
        <div className="col-6">
          <AddPromoCode collegeData={collegeData} getData={getPromoCode} pageTitle={pageTitle} />
        </div>
        <div className="col-6 d-flex justify-content-end">
          <SearchBar
            searchQuery={searchQuery}
            handleSearchInput={handleSearchInput}
            clearSearchQuery={clearSearchQuery}
            searchTitle={`Search ${pageTitle}`}
          />
          <SelectItemsPerPage handlePageSizeChange={handlePageSizeChange} />
        </div>
        {updating ? (
          <SpinnerBorder />
        ) : promoData.length === 0 ? (
          <div className="mt-5 text-center fs-1">No Data Found</div>
        ) : (
          <div className="col-12 p-4">
            <ListDiscount
              dataList={promoData}
              handleDelete={handleDelete}
              collegeData={collegeData}
              getData={getPromoCode}
              pageTitle={pageTitle}
            />
            <AdvancePagination currentPage={currentPage} handlePageChange={handlePageChange} totalItems={itemsLength} pageSize={pageSize} />
            <ConfirmationModal
              show={showConfirmation}
              onHide={handleCloseConfirmation}
              onConfirm={handleConfirmDelete}
              message={itemToConfirm ? `Are you sure want to delete ${itemToConfirm.name}?` : ""}
              title={itemToConfirm?.name}
            />
          </div>
        )}
      </div>
    </>
  );
}
