import React, { useEffect, useState } from "react";
import Header from "../layout/Header";
import Section8 from "../pages/Main/Section8";
import Section1 from "./Section1/Main";
import Section2 from "./Section2/Main";
import Section3 from "./Section3/Main";
import Section4 from "./Section4/Main";
import Section5 from "./Section5/Main";
import LoginModal from "../layout/LoginModal";
import PageTitle from "../components/PageTitle";
export default function AppInstitution() {
  const [modalShow, setModalShow] = useState(false);
  const pageTitle = "Institution";

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <PageTitle title={pageTitle} />
      <Header />
      <Section1 setModalShow={setModalShow} />
      <div className="container">
        <div className="my-5">
          <Section2 />
        </div>
        <div className=" pt-sm-5" style={{ marginBottom: "100px" }}>
          <Section3 />
        </div>
        <div className="" style={{ marginBottom: "100px" }}>
          <Section4 setModalShow={setModalShow} />
        </div>
        <div className="" style={{ marginBottom: "100px" }}>
          <Section5 />
        </div>
      </div>
      <div>
        <Section8 />
      </div>
      {modalShow ? <LoginModal modalShow setModalShow={setModalShow} /> : null}
    </>
  );
}
