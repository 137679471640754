import React, { useState } from "react";
import "./WorkFeatures.css";
import work1 from "../../assets/institution/work1.jpg";
import work2 from "../../assets/institution/work2.jpg";
import work3 from "../../assets/institution/work3.jpg";
import work4 from "../../assets/institution/work4.jpg";
import { Accordion } from "react-bootstrap";

const workFeaturesData = [
  {
    title: "Diverse Students",
    icon: "archive", // Icon for the first section
    description: [
      "Diversify recruitment by targeting students of diverse potential.",
      "Attract right students from all backgrounds.",
      "Get access to thousands of student applications.",
    ],
    image: work1,
  },
  {
    title: "Association",
    icon: "person-bounding-box", // Icon for the second section
    description: [
      "Association of partners with similar interests.",
      "Collaborate and team work.",
      "Increase the partner and institute network.",
    ],
    image: work2,
  },
  {
    title: "Transparency",
    icon: "sun", // Icon for the third section
    description: [
      "Transparency verification of student documents and information",
      "Communication with absolute transparency and in all formats.",
    ],
    image: work3,
  },
  {
    title: "Help Students",
    icon: "bookshelf", // Icon for the fourth section
    description: ["Diverse campuses.", "Recognise all eligible students."],
    image: work4,
  },
];

export default function Section2() {
  const [activeKey, setActiveKey] = useState(null);

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };
  return (
    <div className="">
      <section className="features my-5">
        <div className="d-none d-sm-flex bg-secondary bg-opacity-10  justify-content-center align-items-center rounded-5 py-5">
          <div className="w-100 px-5">
            <ul className="nav nav-tabs px-5 d-flex" role="tablist">
              {workFeaturesData.map((item, index) => (
                <li
                  key={index}
                  className="col-12 col-md-6 col-lg-3 mb-1 d-flex justify-content-center align-items-center"
                  role="presentation"
                >
                  <a
                    className={`nav-link border-0 rounded-4 d-flex justify-content-center align-items-center px-3 ${
                      index === 0 ? "active show" : ""
                    }`}
                    data-bs-toggle="tab"
                    href={`#tab-${index + 1}`}
                    aria-selected={index === 0 ? "true" : "false"}
                    role="tab"
                  >
                    <i className={`text3 pt-3 me-3 bi bi-${item.icon}`}></i>

                    {/* Icon is now taken from the data array */}
                    <h4 className="text2">{item.title}</h4>
                  </a>
                </li>
              ))}
            </ul>

            <div
              className="tab-content aos-init aos-animate mt-5 px-5"
              data-aos="fade-up"
            >
              {workFeaturesData.map((item, index) => (
                <div
                  key={index}
                  className={`tab-pane ${index === 0 ? "active show" : ""}`}
                  id={`tab-${index + 1}`}
                  role="tabpanel"
                >
                  <div className="row px-5">
                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 ps-5">
                      {item.description.map((line, idx) => (
                        <div key={idx} className="check-double-icon-item mb-4">
                          <i className="bi bi-check2-all text-dark1 me-2 text2"></i>
                          <span className="text1-6">{line}</span>
                        </div>
                      ))}
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2 text-center">
                      <img
                        src={item.image}
                        alt=""
                        className="img-fluid rounded-4 shadow"
                        style={{ width: "420px" }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="d-block d-sm-none">
          <Accordion activeKey={activeKey} onSelect={handleToggle}>
            {workFeaturesData.map((item, index) => (
              <Accordion.Item
                className="border-0"
                key={index}
                eventKey={index.toString()}
              >
                <Accordion.Header
                  className="custom-accordion-header"
                  onClick={() => handleToggle(index.toString())}
                >
                  <div className="d-flex gap-4 align-items-center">
                    <div
                      className={`border-0 bg-dark1 rounded-4 d-flex justify-content-center align-items-center p-3 `}
                      data-bs-toggle="tab"
                      role="tab"
                    >
                      <i className={`text2 text-white bi bi-${item.icon}`}></i>
                    </div>
                    <span className="text1-8">{item.title}</span>
                  </div>

                  <span className="ms-auto">
                    <i
                      className={
                        activeKey === index.toString()
                          ? "bi bi-dash-lg text2"
                          : "bi bi-plus-lg text2"
                      }
                    />
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row px-5 ">
                    <div className="col-lg-6 order-2 order-lg-1 mt-4  ps-5">
                      {item.description.map((line, idx) => (
                        <div key={idx} className="check-double-icon-item mb-4">
                          <i className="bi bi-check2-all text-dark1 me-2 text2"></i>
                          <span className="text1-6">{line}</span>
                        </div>
                      ))}
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2 text-center ps-4">
                      <img
                        src={item.image}
                        alt=""
                        className="img-fluid rounded-4 shadow "
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </section>
    </div>
  );
}
