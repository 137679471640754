import React, { useState, useEffect, useCallback } from "react";

const captchaCharacters = "ABCDFGHKLMPQRSTUVXZ975421";
const captchaStringLength = 6;

const Captcha = ({ captchaToAll }) => {
  const [genCaptcha, setGenCaptcha] = useState("");

  const generateString = useCallback(() => {
    let result = "";
    const charactersLength = captchaCharacters.length;
    for (let i = 0; i < captchaStringLength; i++) {
      result += captchaCharacters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }, []);

  useEffect(() => {
    try {
      const captcha = generateString(); // Function called here and save in captcha variable
      if (captcha) {
        setGenCaptcha(captcha);
      }
    } catch (err) {
      console.error(err);
    }
  }, [generateString]);

  useEffect(() => {
    try {
      captchaToAll(genCaptcha);
    } catch (err) {
      console.error(err);
    }
  }, [genCaptcha, captchaToAll]);

  return (
    <div>
      <div className="d-flex">
        <div className="mx-md-2 mx-1 text-center" style={{ minWidth: "90px" }}>
          <div className="text2 m-auto text-dark user-select-none">
            <em>
              <del>{genCaptcha}</del>
            </em>
          </div>
        </div>
        <div className="m-auto" onClick={() => setGenCaptcha(generateString())} style={{ minWidth: "20px" }}>
          <div
            className="btn btn-light-blue-outline rounded-circle d-flex justify-content-center align-items-center"
            style={{ width: "24px", height: "24px" }}
          >
            <i className="bi bi-arrow-clockwise text2 d-flex justify-content-center align-items-center"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Captcha;
