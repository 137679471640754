import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import headerBG from "../../assets/headerBG.png";
import Captcha from "../../components/Captcha";
import AuthService from "../../Services/AuthService";
export default function ForgotPassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const firstName = searchParams.get("FirstName");
  const lastName = searchParams.get("LastName");

  const [isLoading, setLoading] = useState(false);
  const [viewCreatePassword, setViewCreatePassword] = useState(false);
  const handleViewCreatePassword = () => {
    setViewCreatePassword(!viewCreatePassword);
  };

  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const handleViewConfirmPassword = () => {
    setViewConfirmPassword(!viewConfirmPassword);
  };

  const [captchaValue, setCaptchaValue] = useState("");

  const captchaToLogin = (captchadata) => {
    setCaptchaValue(captchadata);
  };

  const [iscaptcha, setIsCaptcha] = useState("");
  const onChangeCaptcha = (e) => {
    const iscaptcha = e.target.value;
    setIsCaptcha(iscaptcha);
  };

  const inilizeData = {
    createPassword: "",
    confirmPassword: "",
  };

  const [changePasswordData, setChangePasswordData] = useState(inilizeData);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setChangePasswordData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    var data = {
      emailAddress: email,
      token: token,
      password: changePasswordData.createPassword,
      confirmPassword: changePasswordData.confirmPassword,
    };
    if (iscaptcha === captchaValue) {
      if (changePasswordData.createPassword === changePasswordData.confirmPassword) {
        try {
          setLoading(true);
          const response = await toast.promise(AuthService.resetPassword(data), {
            loading: "Updating...",
            success: <span className="fs-5"> Password successfully!</span>,
            error: <span className="fs-5">Could not Change Password</span>,
          });

          if (response.data.success === true) {
            setTimeout(() => {
              navigate("/");
            }, 2000);
          } else if (response.data.validationErrors) {
            response.data.validationErrors.map((verr) => toast.error(verr));
          }
        } catch (error) {
          toast.error(error.message, {
            style: {
              border: "1px solid #ff3f6d",
              padding: "6px 12px",
              color: "#000",
              fontSize: "12px",
            },
            iconTheme: {
              primary: "#ff3f6d",
              secondary: "#fff",
            },
          });
        } finally {
          setLoading(false);
        }
      } else {
        toast.error("Confirm password does not macthed", {
          id: "confirmPassword",
          style: {
            border: "1px solid #ff3f6d",
            padding: "6px 12px",
            color: "#000",
            fontSize: "12px",
          },
          iconTheme: {
            primary: "#ff3f6d",
            secondary: "#fff",
          },
        });
      }
    } else {
      toast.error("The captcha does not match", {
        id: "captchaNotMatched",
        style: {
          border: "1px solid #ff3f6d",
          padding: "6px 12px",
          color: "#000",
          fontSize: "12px",
        },
        iconTheme: {
          primary: "#ff3f6d",
          secondary: "#fff",
        },
      });
    }
  };

  return (
    <div>
      <div>
        <Toaster reverseOrder={false} />
      </div>
      <div className="position-relative z-n1">
        <div className="position-absolute">
          <img src={headerBG} className={`img-fluid w-100 h-25 opacity-25`} alt="headerBG" />
        </div>
      </div>
      <div className="container pt-5">
        <div className="row mt-5 z-1">
          <div className="col-12 fs-1 fw-bold text-center">
            Welcome <span>{firstName + " " + lastName}</span> to Education Planner
          </div>

          <div className="col-md-6 mx-auto bg-secondary bg-opacity-25 shadow rounded-5 p-5">
            <form className="row g-3 needs-validation px-5" onSubmit={handleResetPassword}>
              <div className="col-12 fs-2 text-center">Enter new password</div>
              <div className="col-12 form-floating px-3">
                <input
                  type="email"
                  className="form-control rounded-pill border ps-4 pb-1"
                  placeholder="Email*"
                  required
                  value={email}
                  disabled
                />
                <label htmlFor="floatingInput" className="ms-4 pt-2">
                  Email address<span className="text-danger">*</span>
                </label>
              </div>
              <div className="col-12 form-floating px-3">
                <input
                  type={`${viewCreatePassword ? "text" : "password"}`}
                  className="form-control rounded-pill border ps-4 pb-1"
                  placeholder="*"
                  required
                  name="createPassword"
                  value={changePasswordData.createPassword}
                  onChange={handleInputChange}
                  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.@$!%*?&])[A-Za-z\d.@$!%*?&]{8,}$"
                  autoComplete="off"
                  disabled={isLoading}
                />
                <div className="position-absolute top-50 end-0 translate-middle-y me-5 pe-2" onClick={handleViewCreatePassword}>
                  <i className={`bi ${viewCreatePassword ? "bi-eye-slash" : "bi-eye"} d-flex align-items-center text-dark1 text2`}></i>
                </div>
                <label htmlFor="floatingInput" className="ms-4 pt-2">
                  Create Password<span className="text-danger">*</span>
                </label>
              </div>
              <div className="col-12 form-floating px-3">
                <input
                  type={`${viewConfirmPassword ? "text" : "password"}`}
                  className="form-control rounded-pill border ps-4 pb-1"
                  placeholder="*"
                  required
                  name="confirmPassword"
                  value={changePasswordData.confirmPassword}
                  onChange={handleInputChange}
                  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.@$!%*?&])[A-Za-z\d.@$!%*?&]{8,}$"
                  autoComplete="off"
                  disabled={isLoading}
                />
                <div className="position-absolute top-50 end-0 translate-middle-y me-5 pe-2" onClick={handleViewConfirmPassword}>
                  <i className={`bi ${viewConfirmPassword ? "bi-eye-slash" : "bi-eye"} d-flex align-items-center text-dark1 text2`}></i>
                </div>
                <label htmlFor="floatingInput" className="ms-4 pt-2">
                  Confirm Password<span className="text-danger">*</span>
                </label>
              </div>
              <div className="col-12 px-3">
                <div className="d-flex align-items-center form-floating">
                  <input
                    type="text"
                    className="form-control rounded-pill border ps-4 pb-1"
                    placeholder="Captcha*"
                    onChange={onChangeCaptcha}
                    minLength={6}
                    maxLength={6}
                    required
                    autoComplete="off"
                  />
                  <label htmlFor="floatingInput" className="ms-3 pt-2">
                    Captcha<span className="text-danger">*</span>
                  </label>
                  <div className="" style={{ minWidth: "120px" }}>
                    <Captcha captchaToAll={captchaToLogin} />
                  </div>
                </div>
              </div>

              <button type="submit" className="col-6 mx-auto btn btn-dark-blue rounded-pill py-2 px-5 text1-3">
                <span>{isLoading ? "Please Wait..." : "Change Password"}</span>
                {isLoading && <span className="spinner-border"></span>}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
