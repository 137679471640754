import http from "./api";

const find = (params) => {
  return http.get("Auth/getAllPagedUsers?", { params });
};
const getAllCompany = () => {
  return http.get(`Company/getAllCompanyNames`);
};

const AgentUserService = {
  find,
  getAllCompany,
};

export default AgentUserService;
