import React, { useEffect, useState } from "react";
import { useLocation, Navigate, useNavigate, Outlet } from "react-router-dom";
import logoTextLight from "../assets/logo_text_light.png";
import logoLight from "../assets/logo_light.png";
import TokenService from "../Services/TokenService";
import SideMenuButton from "../components/SideMenuButton";
import SideMenu from "./SideBarMenu";

const RequireAuth = ({ allowedRoles }) => {
  const getRole = TokenService.getUserRole();

  const getUser = TokenService.getUser();

  const location = useLocation();
  const navigate = useNavigate();

  const [sidebarActive, setSidebarActive] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setSidebarActive(true);
    }, 2000);
  }, []);

  useEffect(() => {
    // Check if the user has the required role
    if (getUser && !allowedRoles.includes(getRole)) {
      navigate("/unauthorized", { replace: true });
    }
    // eslint-disable-next-line
  }, [getUser, allowedRoles, getRole]);

  return getUser ? (
    <div className={`bg-dark1 d-flex ${sidebarActive ? "active" : ""}`}>
      <nav id="sidebar" className={`d-print-none position-relative ${sidebarActive ? "active" : ""}`}>
        <div className="text-center my-4">
          <a href="/" className="">
            <img
              src={sidebarActive ? logoLight : logoTextLight}
              width={sidebarActive ? 80 : 240}
              className="img-fluid px-4 pt-4"
              alt="logo"
            />
          </a>
        </div>
        <ul className="list-unstyled my-5">
          <SideMenu btnToggle={sidebarActive} EnterCMSMode={() => navigate("/cms")} />
        </ul>
        <div className="text-white text-center text1-2 mb-3 position-absolute bottom-0 start-50 translate-middle-x">Version 2.0</div>
      </nav>

      <div id="content" className="pe-4 py-4">
        <div>
          <div className="rounded-5 bg-white">
            <div className="d-print-none position-relative">
              <SideMenuButton handleSidebarToggle={() => setSidebarActive(!sidebarActive)} sidebarActive={sidebarActive} />
            </div>
            <div className="px-5 py-3 main-scroll">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default RequireAuth;
