import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import "./App.css";

import StripeKeys from "../../Services/StripeKeys";

//import PaymentService from "../../Services/PaymentService";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
// const stripePromise = loadStripe(
//   "pk_live_51N2rmqSEeXJSCHtnENFf26ZBTGddA5RdCCit1cIzk3Shzl6jbr7k7Dq89fhh2rFq4c0dJkv0ekRgxp2Bos4MYDth00fL5JGTxe"
//   //"pk_test_51NNiyMSAwxQB49PC83FWjlk863AbgJxNdHADA70gAUUI18YlghmYEe4YZA7exxYSCBmtLmMfwxABjJRI5wxp4oGi00OLn4PX1Y"
// );
const stripePromise = loadStripe(StripeKeys.PublishableStripeKey);

export default function AppStripePayment({
  studentCurrency,
  collegeCurrency,
  clientSecret,
  exchangeData,
  currentApplication,
  setShow,
  PaymentService,
  getPaymentDetails,
  selectedPromoCode,
  collegeDetailsApplicationFee,
  setPaymentDoneShow,
}) {
  const appearance = {
    theme: "stripe",
    variables: {
      colorPrimary: "#3f59ff",
      colorBackground: "#ffffff",
      colorText: "#161616",
      colorDanger: "#ff3f6d",
      spacingUnit: "3px",
      borderRadius: "1.6rem",
      marginLeft: "2rem",
    },
    // labels: "floating",
    // marginRight:"20px"
  };
  const options = {
    clientSecret,
    appearance,
  };
  return (
    <div>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            studentCurrency={studentCurrency}
            collegeCurrency={collegeCurrency}
            exchangeData={exchangeData}
            currentApplication={currentApplication}
            PaymentService={PaymentService}
            setShow={setShow}
            getPaymentDetails={getPaymentDetails}
            selectedPromoCode={selectedPromoCode}
            collegeDetailsApplicationFee={collegeDetailsApplicationFee}
            setPaymentDoneShow={setPaymentDoneShow}
          />
        </Elements>
      )}
    </div>
  );
}
