import React, { useState } from "react";
import PageTitle from "../../components/PageTitle";
import UserPanel from "../UserPanel";
import icici from "../../assets/images/icici.svg";
import cibc from "../../assets/images/cibc.svg";
import arrow from "../../assets/icons/gradientArrow.svg";
import "./MarketPlace.css";
import { useNavigate } from "react-router-dom";
import StudentConfirmationModal from "./StudentConfirmationModal";
import RolesService from "../../Services/RolesService";
import TokenService from "../../Services/TokenService";
import GICCard from "./ICICICard";
import ICICICard from "./ICICICard";
import CIBCCard from "./CIBCCard";
import FlywireCard from "./FlywireCard";
import LoanCard from "./LoanCard";

const pageTitle = "MarketPlace";

export default function GicList() {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <StudentConfirmationModal show={showModal} setShowModal={setShowModal} />
      <PageTitle title={pageTitle} />
      <div className="p-2 mt-2">
        <div className="row align-items-center">
          <div className="col">
            <UserPanel title={pageTitle} />
          </div>
        </div>
        <div className="row g-5 p-3 ">
          <div className="col-lg-6 col-12">
            <ICICICard setShowModal={setShowModal} />
          </div>
          <div className="col-lg-6 col-12">
            <CIBCCard setShowModal={setShowModal} />
          </div>
          <div className="col-lg-6 col-12">
            <LoanCard setShowModal={setShowModal} />
          </div>
          <div className="col-lg-6 col-12">
            <FlywireCard />
          </div>
        </div>
      </div>
    </>
  );
}
