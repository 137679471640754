import React from "react";
import sortIcon from "../../assets/icons/sort.svg";
export default function SelectOption({ handleOptionChange, options }) {
  return (
    <>
      <div className="text1-4 text-dark1">
        <img src={sortIcon} alt="sortIcon" className="img-fluid" />
      </div>
      <div className="text1-4 text-dark1">
        <select className="form-select rounded-4 text1-3 py-2" aria-label="lead sort" defaultValue={"All"} onChange={handleOptionChange}>
          <option value={""}>All</option>
          {options?.map((option, index) => (
            <option value={option} key={index}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}
