import http from "./api";

const getAgentProfileData = (userId) => {
    return http.get(`Agent/getByUserId?userId=${userId}`);
};

const postAgentProfileData = (body) => {
    return http.post("Agent", body);
}
const updateAgentProfileData = (body) => {
    return http.put("Agent", body);
}

const uploadDocuments = (body) => {
    return http.post("Agent/uploadAgentDocuments", body);
}

const getDocuments = (userId) => {
    return http.get(`Agent/getAgentDocuments?userId=${userId}`,);
}


const AgentProfileService = {
    getAgentProfileData,
    postAgentProfileData,
    updateAgentProfileData,
    uploadDocuments,
    getDocuments
};

export default AgentProfileService;
