import React, { useState } from "react";
import SubmitButton from "../../components/Form/SubmitButton";
import Captcha from "../../components/Captcha";
import AuthService from "../../Services/AuthService";
import { toast } from "react-toastify";
export default function ForgetPassword({ showLogin, setModalShow }) {
  const [loading, setLoading] = useState(false);

  // const [viewCurrentPassword, setViewCurrentPassword] = useState(false);
  // const handleViewCurrentPassword = () => {
  //   setViewCurrentPassword(!viewCurrentPassword);
  // };
  // const [viewCreatePassword, setViewCreatePassword] = useState(false);
  // const handleViewCreatePassword = () => {
  //   setViewCreatePassword(!viewCreatePassword);
  // };

  // const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  // const handleViewConfirmPassword = () => {
  //   setViewConfirmPassword(!viewConfirmPassword);
  // };

  const [captchaValue, setCaptchaValue] = useState("");

  const captchaToLogin = (captchadata) => {
    setCaptchaValue(captchadata);
  };

  const [iscaptcha, setIsCaptcha] = useState("");
  const onChangeCaptcha = (e) => {
    const iscaptcha = e.target.value;
    setIsCaptcha(iscaptcha);
  };

  const inilizeData = [
    {
      emailAddress: "",
      // currentPassword: "",
      // createPassword: "",
      // confirmPassword: "",
    },
  ];
  const [forgetPasswordData, setForgetPasswordData] = useState(inilizeData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForgetPasswordData({ ...forgetPasswordData, [name]: value });
  };

  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();

    if (iscaptcha === captchaValue) {
      setLoading(true);
      var data = {
        emailAddress: forgetPasswordData.emailAddress,
        // currentPassword: forgetPasswordData.currentPassword,
        // confirmPassword: forgetPasswordData.confirmPassword,
      };

      try {
        const response = await AuthService.forgetPassword(data);

        if (response.data.success === true) {
          setForgetPasswordData(inilizeData);
          setModalShow(false);
          toast.success(response.data.message);
        } else if (response.data.validationErrors) {
          response.data.validationErrors.map((verr) => toast.error(verr));
        } else {
          toast.error("Something Went Wrong");
        }
      } catch (e) {
        if (e.response && e.response.status === 401) {
          toast.error(e.response.data);
        } else {
          toast.error(e.message);
        }
      } finally {
        setLoading(false);
      }
    } else {
      toast.error("The captcha does not match");
    }
  };

  return (
    <>
      <form
        className="row g-3 was-validated px-5"
        onSubmit={handleForgotPasswordSubmit}
      >
        <div className="col-12 form-floating px-3">
          <input
            type="email"
            className="form-control rounded-pill border ps-4 pb-1"
            placeholder="Email Address*"
            required
            name="emailAddress"
            value={forgetPasswordData.emailAddress}
            onChange={handleInputChange}
          />
          <label htmlFor="floatingInput" className="ms-4 pt-2">
            Email Address<span className="text-danger">*</span>
          </label>
        </div>
        {/* <div className="col-12 form-floating px-3 position-relative">
          <input
            type={`${viewCurrentPassword ? "text" : "password"}`}
            className="form-control rounded-pill border ps-4 pb-1"
            placeholder="*"
            required
            name="currentPassword"
            value={forgetPasswordData.currentPassword}
            onChange={handleInputChange}
            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.@$!%*?&])[A-Za-z\d.@$!%*?&]{8,}$"
          />
          <div className="position-absolute top-50 end-0 translate-middle-y me-5 pe-2" onClick={handleViewCurrentPassword}>
            <i className={`bi ${viewCurrentPassword ? "bi-eye-slash" : "bi-eye"} d-flex align-items-center text-dark1 text2`}></i>
          </div>
          <label htmlFor="floatingInput" className="ms-4 pt-2">
            Current Password<span className="text-danger">*</span>
          </label>
        </div>

        <div className="col-12 form-floating px-3 position-relative">
          <input
            type={`${viewCreatePassword ? "text" : "password"}`}
            className="form-control rounded-pill border ps-4 pb-1"
            placeholder="*"
            required
            name="createPassword"
            value={forgetPasswordData.createPassword}
            onChange={handleInputChange}
            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.@$!%*?&])[A-Za-z\d.@$!%*?&]{8,}$"
          />
          <div className="position-absolute top-50 end-0 translate-middle-y me-5 pe-2" onClick={handleViewCreatePassword}>
            <i className={`bi ${viewCreatePassword ? "bi-eye-slash" : "bi-eye"} d-flex align-items-center text-dark1 text2`}></i>
          </div>
          <label htmlFor="floatingInput" className="ms-4 pt-2">
            Create Password<span className="text-danger">*</span>
          </label>
        </div>

        <div className="col-12 form-floating px-3 position-relative">
          <input
            type={`${viewConfirmPassword ? "text" : "password"}`}
            className="form-control rounded-pill border ps-4 pb-1"
            placeholder="*"
            required
            name="confirmPassword"
            value={forgetPasswordData.confirmPassword}
            onChange={handleInputChange}
            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.@$!%*?&])[A-Za-z\d.@$!%*?&]{8,}$"
          />
          <div className="position-absolute top-50 end-0 translate-middle-y me-5 pe-2" onClick={handleViewConfirmPassword}>
            <i className={`bi ${viewConfirmPassword ? "bi-eye-slash" : "bi-eye"} d-flex align-items-center text-dark1 text2`}></i>
          </div>
          <label htmlFor="floatingInput" className="ms-4 pt-2">
            Confirm Password<span className="text-danger">*</span>
          </label>
        </div> */}
        <div className="col-12 px-3">
          <div className="d-flex align-items-center form-floating">
            <input
              type="text"
              className="form-control rounded-pill border ps-4 pb-1"
              placeholder="Captcha*"
              onChange={onChangeCaptcha}
              minLength={6}
              maxLength={6}
              required
            />
            <label htmlFor="floatingInput" className="ms-4 pt-2">
              Captcha<span className="text-danger">*</span>
            </label>
            <div className="" style={{ minWidth: "120px" }}>
              <Captcha captchaToAll={captchaToLogin} />
            </div>
          </div>
        </div>
        <div className="col-10 mx-auto">
          <SubmitButton title={"Change Password"} loading={loading} />
        </div>

        <div className="col-12 text-center my-3 d-flex justify-content-center align-items-center">
          <p className="text1-4 font-bold me-2">Existing User?</p>
          <p
            className="text1-6 font-bold text-dark1"
            style={{ cursor: "pointer" }}
            onClick={showLogin}
          >
            Login
          </p>
        </div>
      </form>
    </>
  );
}
