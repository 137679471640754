export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatIntakeDate(datetime) {
  const dateObj = new Date(datetime);
  const options = { year: "numeric", month: "short" };
  return dateObj.toLocaleDateString(undefined, options);
}

export function formatIndianDateAndTime(dateTime) {
  if (!dateTime) return ``;
  const date = new Date(dateTime.toString());
  const indianTime = new Date(date.getTime() + (5 * 60 + 30) * 60000);
  const formattedDate = indianTime.toLocaleDateString("en-GB");
  const formattedTime = indianTime.toLocaleTimeString("en-GB");

  return `${formattedDate}, ${formattedTime}`;
}

export function formatDateAndTime(dateTime) {
  if (!dateTime) return ``;
  const date = new Date(dateTime.toString())?.toLocaleDateString("en-GB");
  const time = new Date(dateTime);
  const indianTime = new Date(time.getTime() + (5 * 60 + 30) * 60000);

  const formattedIndianTime = indianTime.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
    timeZone: "Asia/Kolkata",
  });
  return `${date}, ${formattedIndianTime}`;
}

export const floatValue = (value) => {
  return value.toFixed(2);
};

export const debounce = (callBack, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      callBack(...args);
    }, delay);
  };
};
