import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import UserPanel from "../UserPanel";
import AddPersonalInformation from "./AddPersonalInformation";
import AddEducation from "./AddEducation";
import personalInformationIcon from "../../assets/icons/personal.svg";
import educationIcon from "../../assets/icons/education.svg";
import docuemntsIcon from "../../assets/icons/documents.svg";
import applicationIcon from "../../assets/icons/applications-1.svg";
import ApplicationForm from "./ApplicationForm";
import AddDocuments from "./AddDocuments";
import Tabs from "../../components/UI/Tabs";
import StudentDataService from "../../Services/StudentService";
import EducationDataService from "../../Services/StudentEducationService";
import SearchResult from "../Search/Internal/SearchResult";
import { toast } from "react-toastify";
import RolesService from "../../Services/RolesService";
import TokenService from "../../Services/TokenService";
import EnglishProficiencyTestDataService from "../../Services/StudentEPTestService";

const staticFields = 2;

export default function StudentUpdate() {
  const { AdmissionOfficer, TeamLeader } = RolesService;
  const getRole = TokenService.getUserRole();

  const { id } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [documentList, setDocumentList] = useState([]);

  const [activeTabNext, setActiveTabNext] = useState("personalInformation");

  const [studentData, setStudentData] = useState(inilizeData);
  const [phoneError, setPhoneError] = useState("");
  // const [EPInfo, setEPInfo] = useState([]);
  const getStudent = async () => {
    try {
      const response = (await StudentDataService.get(id)).data;
      setStudentData(response);
      setIsLoaded(true);
    } catch (e) {
      toast.error(
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString()
      );
    } finally {
      setIsLoaded(true);
    }
  };
  // const getEPData = async () => {
  //   try {
  //     const response = (await EnglishProficiencyTestDataService.get(id)).data;
  //     setEPInfo(response);
  //     setIsLoaded(true);
  //   } catch (e) {
  //     toast.error(
  //       (e.response && e.response.data && e.response.data.message) ||
  //         e.message ||
  //         e.toString()
  //     );
  //   } finally {
  //     setIsLoaded(true);
  //   }
  // };
  const handleStudentInputChange = (event) => {
    const { name, value } = event.target;
    setStudentData((prevStudent) => ({
      ...prevStudent,
      [name]: value,
    }));
  };

  const saveStudentData = (e) => {
    if (!studentData.contactNumber) {
      setPhoneError("Please enter contact number");
      return;
    }
    e.preventDefault();
    setIsSaving(true);

    var data = {
      id: studentData.id,
      salutation: studentData.salutation,
      firstName: studentData.firstName,
      middleName: studentData.middleName,
      lastName: studentData.lastName,
      dateofBirth: studentData.dateofBirth,
      nationality: studentData.nationality,
      currentStatus: studentData.currentStatus,
      gender: studentData.gender,
      maritalStatus: studentData.maritalStatus,
      contactNumber: studentData.contactNumber,
      email: studentData.email,
      address: studentData.address,
      country: studentData.country,
      state: studentData.state,
      district: studentData.district,
      city: studentData.city,
      postalCode: studentData.postalCode,
      permanentAddress: studentData.permanentAddress,
      permanentCountry: studentData.permanentCountry,
      permanentState: studentData.permanentState,
      permanentDistrict: studentData.permanentDistrict,
      permanentCity: studentData.permanentCity,
      permanentPostalCode: studentData.permanentPostalCode,
      passportNumber: studentData.passportNumber,
      passportExpiryDate: studentData.passportExpiryDate,
      previousRefusal: studentData.previousRefusal,
      emergencyContactName: studentData.emergencyContactName,
      emergencyContactNumber: studentData.emergencyContactNumber,
      emergencyEmail: studentData.emergencyEmail,
      relation: studentData.relation,
      statusId: 1,
      organizationNameAndAddress: studentData.organizationNameAndAddress,
      jobProfile: studentData.jobProfile,
      position: studentData.position,
      modeOfSalary: studentData.modeOfSalary,
      currentlyWorking: studentData.currentlyWorking,
      workingFrom: studentData.workingFrom,
      workingUpto: studentData.workingUpto,
      gapJustification: studentData.gapJustification,
    };

    StudentDataService.update(data)
      .then((response) => {
        if (response.data.success === true) {
          toast.success(response.data.message);
          getStudent();
        } else if (response.data.validationErrors.length > 0) {
          const vErrs = response.data.validationErrors.map((verr) => verr);
          const errorMessage = vErrs.join(", "); // Join the errors into a single string

          toast.error(errorMessage); // Show the combined error message
        } else {
          toast.error("Something Went Wrong");
        }
        setIsSaving(false);
      })
      .catch((e) => {
        toast.error(e);
      });
  };

  const initliEducationArry = [
    {
      studentId: id,
      institute: "",
      field: "",
      boardUniv: "",
      passingYear: "",
      startDate: "",
      endDate: "",
      address: "",
      percentage: "",
      gradingSystem: "",
      level: "10th",
      author: "",
      statusId: 1,
    },
    {
      studentId: id,
      institute: "",
      field: "",
      boardUniv: "",
      passingYear: "",
      startDate: "",
      endDate: "",
      address: "",
      percentage: "",
      gradingSystem: "",
      level: "12th",
      author: "",
      statusId: 1,
    },
    {
      studentId: id,
      institute: "",
      field: "",
      boardUniv: "",
      passingYear: "",
      startDate: "",
      endDate: "",
      address: "",
      percentage: "",
      gradingSystem: "",
      level: "Graduation",
      author: "",
      statusId: 1,
    },
    {
      studentId: id,
      institute: "",
      field: "",
      boardUniv: "",
      passingYear: "",
      startDate: "",
      endDate: "",
      address: "",
      percentage: "",
      gradingSystem: "",
      level: "Post Graduation",
      author: "",
      statusId: 1,
    },
  ];
  const [EducationData, setEducationData] = useState(initliEducationArry);
  const [currentEducation, setCurrentEducation] = useState([]);

  const getEducation = async () => {
    try {
      const response = await EducationDataService.findAndGetByStudentID(id);
      setCurrentEducation(response.data.response);

      if (response.data.response.length !== 0) {
        const edu = response.data.response;
        // Create a new array with the updated data
        const updatedEducationData = EducationData.map((e) => {
          const exist = edu.find((x) => x.level === e.level);
          return exist ? exist : e;
        });
        setEducationData(updatedEducationData);
      }

      setIsLoaded(true);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message || error.toString());
      }
    }
  };

  useEffect(() => {
    getStudent();
    getEducation();
  }, []);

  const [allDraftApplication, setAllDraftApplication] = useState(false);

  useEffect(() => {
    const checkAppStatus = () => {
      if (studentData.length === 0) {
        setAllDraftApplication(true);
      } else if (studentData && studentData?.applications?.length === 0) {
        setAllDraftApplication(true);
      } else if (
        studentData &&
        studentData.applications &&
        studentData.applications.length > 0
      ) {
        const allApplicationsDraft = studentData.applications.every(
          (application) => application.applicationStatus === "Draft"
        );
        setAllDraftApplication(allApplicationsDraft);
      }
    };
    checkAppStatus();
  }, [studentData]);

  const updateState = (index) => (e) => {
    const newArray = EducationData.map((item, i) => {
      if (index === i) {
        return { ...item, [e.target.name]: e.target.value };
      } else {
        return item;
      }
    });
    setEducationData(newArray);
  };

  const updateStateAfterDelete = (index) => {
    const newArray = EducationData.map((item, i) => {
      if (index === i) {
        return initliEducationArry[i];
      } else {
        return item;
      }
    });

    setEducationData(newArray);
  };

  const saveEducationData = async (e) => {
    e.preventDefault();
    let toSave = [];
    let messages = [];

    for (const record of EducationData) {
      let data = {
        id: record.id,
        studentId: id,
        institute: record.institute,
        field: record.field,
        boardUniv: record.boardUniv,
        passingYear: record.passingYear,
        percentage: record.percentage,
        gradingSystem: record.gradingSystem,
        startDate: record.startDate,
        endDate: record.endDate,
        address: record.address,
        level: record.level,
        author: record.author,
        statusId: 1,
      };

      toSave.push(data);
    }

    for (const record of toSave) {
      try {
        if (record.id === undefined) {
          await EducationDataService.create(record);
          messages.push(
            `Success: ${record.level} record created successfully.`
          );
        } else {
          await EducationDataService.update(record);
          messages.push(
            `Success: ${record.level} record updated successfully.`
          );
        }
        getStudent();
      } catch (e) {
        messages.push(
          `Failed: ${record.level} - ${e.response.status === 400 ? "record was empty" : e.message
          }`
        );
      }
    }

    if (messages.length > 0) {
      //alert(messages.join("\n"));
      getEducation();
    }
  };

  const [isStudentSelected, setIsStudentSelected] = useState(false);
  const showProgramList = () => {
    setIsStudentSelected(true);
  };
  if (isStudentSelected) {
    return <SearchResult selectedStudent={studentData} />;
  }

  const tabs = [
    {
      id: "personalInformation",
      label: "Personal Information",
      icon: personalInformationIcon,
      content: (
        <AddPersonalInformation
          handleInputChange={handleStudentInputChange}
          studentData={studentData}
          saveStudentData={saveStudentData}
          isSaving={isSaving}
          setStudentData={setStudentData}
          setActiveTabNext={setActiveTabNext}
          allDraftApplication={allDraftApplication}
          phoneError={phoneError}
          setPhoneError={setPhoneError}
        />
      ),
    },
    {
      id: "education",
      label: "Education",
      icon: educationIcon,
      content: (
        <AddEducation
          updateState={updateState}
          EducationData={EducationData}
          studentData={studentData}
          setStudentData={setStudentData}
          saveStudentData={saveStudentData}
          handleInputChange={handleStudentInputChange}
          saveEducationData={saveEducationData}
          currentEducation={currentEducation}
          getEducation={getEducation}
          updateStateAfterDelete={updateStateAfterDelete}
          setActiveTabNext={setActiveTabNext}
          allDraftApplication={allDraftApplication}

        />
      ),
    },
    {
      id: "documents",
      label: "Documents",
      icon: docuemntsIcon,
      content: (
        <AddDocuments
          currentEducation={currentEducation}
          studentData={studentData}
          setActiveTabNext={setActiveTabNext}
          allDraftApplication={allDraftApplication}
          documentList={documentList}
          setDocumentList={setDocumentList}
          isNextDisabled={
            documentList.length < currentEducation.length + staticFields
          }
        />
      ),
    },
    {
      id: "application",
      label: "Application",
      icon: applicationIcon,
      content: (
        <ApplicationForm
          studentData={studentData}
          showProgramList={showProgramList}
          allDraftApplication={allDraftApplication}
        // isNextDisabled={mandatoryDocuments.length === 0}
        />
      ),
    },
  ];
  const filteredTabs = tabs.filter(
    (tab) =>
      !(
        (getRole === AdmissionOfficer || getRole === TeamLeader) &&
        tab.id === "application"
      )
  );

  return (
    <>
      <div className="p-2 mt-2">
        <UserPanel title={"Update Student"} />

        <Tabs
          tabs={filteredTabs}
          isAnimatedBorder={false}
          isLoaded={isLoaded}
          activeTabNext={activeTabNext}
          setActiveTabNext={setActiveTabNext}
          isApplicationTabDisabled={
            documentList.length < currentEducation.length + staticFields
          }
        />
      </div>
    </>
  );
}

const inilizeData = [
  {
    salutation: "",
    firstName: "",
    middleName: "",
    lastName: "",
    dateofBirth: "",
    nationality: "",
    currentStatus: "",
    gender: "",
    maritalStatus: "",
    contactNumber: "",
    email: "",
    address: "",
    country: "",
    state: "",
    district: "",
    city: "",
    postalCode: "",
    permanentAddress: "",
    permanentCountry: "",
    permanentState: "",
    permanentDistrict: "",
    permanentCity: "",
    permanentPostalCode: "",
    passportNumber: "",
    passportExpiryDate: "",
    previousRefusal: "",
    emergencyContactName: "",
    emergencyContactNumber: "",
    emergencyEmail: "",
    relation: "",
    statusId: 0,
    companyId: '',
    organizationNameAndAddress: "",
    jobProfile: "",
    position: "",
    modeOfSalary: null,
    currentlyWorking: false,
    workingFrom: "",
    workingUpto: "",
    gapJustification: "",
    isEditable: false,
  },
];
