import http from "./api";

const contact = (data) => {
    return http.post("ContactUs", data);
};
const ContactUsService = {
    contact,
};

export default ContactUsService;
