import http from "./api";

const get = (id) => {
  return http.get(`CollegeProgram?CollegeProgramId=${id}`);
};
const remove = (id) => {
  return http.delete(`CollegeProgram?id=${id}`);
};
const create = (data) => {
  return http.post("CollegeProgram", data);
};
const update = (data) => {
  return http.put(`CollegeProgram`, data);
};
const find = (params) => {
  return http.get("CollegeProgram/search?", { params });
};
const advanceFilterList = () => {
  return http.get("AdvanceSearch/advanceSearchFiltersList");
};
const getCountryColleges = (data) => {
  return http.get(`AdvanceSearch/getCountryColleges?country=${data}`);
};
const getCollegeCampus = (data) => {
  return http.get(`AdvanceSearch/getCollegeCampus?collegeId=${data}`);
};
const getIntakeList = (data) => {
  return http.get(`AdvanceSearch/getIntakeList?collegeId=${data.collegeId}&campus=${data.campus}`);
};
const getCollegeProgramsList = (data) => {
  return http.get(`AdvanceSearch/getCollegeProgramsList?collegeId=${data.collegeId}&campus=${data.campus}&intake=${data.intake}`);
};

const ProgramSearchService = {
  get,
  remove,
  create,
  update,
  find,
  advanceFilterList,
  getCountryColleges,
  getCollegeCampus,
  getIntakeList,
  getCollegeProgramsList,
};

export default ProgramSearchService;
