import React from "react";
import {
  formatDateAndTime,
  formatIndianDateAndTime,
} from "../../shared/helpers";
import ToolTip from "../../components/UI/ToolTip";
import TokenService from "../../Services/TokenService";
import RolesService from "../../Services/RolesService";

export default function NotesList({
  notesData,
  user,
  handleDownload,
  viewing,
  setReplyNote,
}) {
  const getRole = TokenService.getUserRole();

  const { BranchHead, AdmissionOfficer, Counsellor } = RolesService;
  const { firstName, lastName, role } = user;
  const isCurrentUserNote = (note) => {
    if (
      (getRole === BranchHead || getRole === Counsellor) &&
      (note.userRole === BranchHead || note.userRole === Counsellor)
    ) {
      return true;
    } else if (
      getRole === AdmissionOfficer &&
      note.userRole === AdmissionOfficer
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="row text1-6 gap-0 row-gap-3 px-5">
      <div className="d-flex flex-column gap-3 ">
        {notesData?.map((note, index) => {
          const filenames = note?.fileName?.split(",");
          return (
            <div
              className={`p-3 bg-light-blue d-flex flex-column gap-2 rounded-4 shadow ${
                isCurrentUserNote(note) ? "bg-light-green" : "bg-light-yellow"
              } ${
                isCurrentUserNote(note) ? " align-self-end" : "align-self-start"
              }`}
            >
              {note?.reply?.message ? (
                <div className="w-100 rounded-4 border  px-4 py-3 d-flex justify-content-between align-items-center ">
                  <span className=" text1-3 opacity-75 ">
                    {note.reply.message}
                  </span>
                </div>
              ) : null}
              <div
                className={`d-flex flex-column rounded-4 p-1 mb-2 `}
                key={index}
              >
                <div className="col-12 ">
                  <div className="p-2 text1-4">
                    <div className="d-flex justify-content-end w-100">
                      <ToolTip
                        tooltipText="Reply"
                        position="top"
                        className="reply-tooltip"
                      >
                        <i
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            setReplyNote({
                              id: note.id,
                              message: note.note,
                            })
                          }
                          class="bi bi-reply"
                        ></i>
                      </ToolTip>
                    </div>

                    <div className="text1-5">{note.note}</div>
                    {!note.fileName ? (
                      ""
                    ) : (
                      <div className="d-flex gap-1 align-items-center">
                        {filenames?.map((file, index) =>
                          viewing === file ? (
                            <div key={index}>
                              Please Wait..{" "}
                              <span className="spinner-border"></span>
                            </div>
                          ) : (
                            <div
                              key={index}
                              className="text1-3 flex align-items-center"
                              onClick={(e) => handleDownload(e, note, file)}
                              style={{ cursor: "pointer" }}
                            >
                              View File{" "}
                              <i className="bi bi-paperclip text1-6 text-dark1"></i>
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={`col-12 ${
                    isCurrentUserNote(note) ? "" : "d-flex justify-content-end"
                  }`}
                >
                  <div
                    className={`d-flex align-items-center blockquote-footer m-0 pb-0 ${
                      isCurrentUserNote(note) ? "ps-2" : "pe-2"
                    }`}
                  >
                    <div className="text1-3 me-1">
                      {note.userFirstName} {note.userLastName}
                    </div>
                    <div className="text1-1 font-monospace">
                      ({note.userRole})
                    </div>
                  </div>
                </div>
                <div className="col-12 ">
                  <div className="pt-3 ps-2 d-flex align-items-center justify-content-end gap-2">
                    <div className="text1-1 opacity-50">
                      {formatIndianDateAndTime(note?.createdDate)}
                    </div>
                    {(getRole === BranchHead && note.statusId === 1) ||
                    (getRole === AdmissionOfficer && note.statusId === 2) ||
                    (getRole === Counsellor && note.statusId === 1) ? (
                      <i class="bi bi-check-all text-tick-blue text1-8"></i>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
