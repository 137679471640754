import { useCallback, useEffect, useMemo } from "react";
import { Button, OverlayTrigger } from "react-bootstrap";
import globeBlueIcon from "../../../assets/icons/globe-blue.svg";
import graduationCapIcon from "../../../assets/icons/graduation-cap.svg";
import shareIcon from "../../../assets/icons/share.svg";
import companyLogo from "../../../assets/logo_dark.png";
import ShareButton from "../../../components/ShareButton";
import { StyledTooltip } from "../../../shared/components/StyledTooltip";
import { floatValue, formatIntakeDate } from "../../../shared/helpers";
import DeleteProgram from "../../Programs/DeleteProgram";
import GenerateApplication from "../../Students/GenerateApplication";
import "./ProgramCard.css";

const ProgramValues = ({ field, value }) => {
  const result = useMemo(() => {
    if (value) {
      if (field === "pteRequirement") {
        return value?.split(";")[0];
      }
      return value;
    }
    return "None";
  }, [field, value]);
  return result;
};

export function ProgramCard(props) {
  // const getCollegeLogo = useCallback(
  //   async (cID) => {
  //     try {
  //       setUpdating(true);
  //       const response = await CollegeDataService.downloadCollegeLogo(cID);
  //       console.log({ response: response?.data.collegeLogoUrl });
  //       setImageURL(response?.data?.response?.collegeLogoUrl);
  //     } catch (error) {
  //       toast.error(error);
  //     }
  //     setUpdating(false);
  //   },
  //   [label, setImageURL]
  // );
  const {
    uniqueCIDs,
    program,
    openEnquireModal,
    handleSwitchClick,
    selectedPrograms,
    index,
    showDeleteIcon,
    findProgram,
    showStartApplicationButton,
    showGenerateApplicationButton,
    selectedStudent,
    showStudentList,
    showShareIcon = true,
    showEnquireButton = true,
  } = props;

  const modifiedProgram = useMemo(() => {
    if (!program) return;

    let availableIntake = "";

    program.intakeList
      ?.filter((intake) => intake.statusId === 3)
      ?.sort((a, b) => new Date(a.date) - new Date(b.date))
      ?.map(
        (intake) => (availableIntake += `${formatIntakeDate(intake.date)}, `)
      );

    return {
      ...program,
      campus: program?.campus?.reverse()?.join(", "),
      intakeList: availableIntake.slice(0, -2),
      ieltsRequirement: program.ieltsRequirement.split(";")[0],
    };
  }, [program]);
  return (
    <div className="position-relative program-card-wrapper">
      <div className="card-header-row d-flex justify-content-between align-items-start">
        <OverlayTrigger
          placement="top"
          overlay={StyledTooltip({
            text: program.collegeProgramName,
          })}
        >
          <a
            href={program.courseUrl}
            className="text-decoration-none card-heading"
            target="_blank"
            rel="noreferrer"
          >
            {program.collegeProgramName}
          </a>
        </OverlayTrigger>
        <div className="d-flex gap-4">
          <ShareButton
            url={`${
              window.location.origin
            }/programs/${program.collegeName.replaceAll(
              " ",
              "-"
            )}/${program.collegeProgramName.replaceAll(
              " ",
              "-"
            )}/${program.campus[0].replaceAll(" ", "-")}/overview`}
            title={program.name}
            customIconClasses="btn border-0"
            customIcon={
              <img
                src={shareIcon}
                alt="share"
                role="button"
                width={20}
                height={20}
              />
            }
          />

          {Boolean(showDeleteIcon && findProgram) && (
            <DeleteProgram program={program} findProgram={findProgram} />
          )}
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-sm-12 col-md-3 image-column">
          <a href={program.website} target="_blank" rel="noreferrer">
            <img
              src={
                uniqueCIDs.find((obj) => {
                  return obj.collegeId === program.collegeId;
                }).imgUrl || companyLogo
              }
              className="college-logo"
              alt="college logo"
            />
          </a>
        </div>
        <div className="col-sm-12 col-md-9 ps-5 ps-md-0">
          <div className="d-flex flex-column align-items-start gap-2 mb-3 card-subheading-container">
            <a
              href={program.website}
              className="text-decoration-none card-heading"
              target="_blank"
              rel="noreferrer"
            >
              <div className="d-flex flex-row align-items-center w-100">
                <img
                  src={graduationCapIcon}
                  alt="graduation-cap-icon"
                  width={20}
                  height={20}
                  className="m-2"
                />
                <p className="m-0 card-subheading">
                  {program.collegeName} - {modifiedProgram.campus}
                </p>
              </div>
            </a>
          </div>
          <div className="d-flex flex-column flex-sm-row">
            <ul className="program-list">
              {Object.keys(PROGRAM_DETAILS_TO_MAP)
                .slice(0, 5)
                .map((key) => (
                  <li className={key === "campus" ? "campus-list" : ""}>
                    <span className="list-heading">
                      {PROGRAM_DETAILS_TO_MAP[key]} -{" "}
                    </span>
                    {key === "campus" ? (
                      <OverlayTrigger
                        placement="top"
                        overlay={StyledTooltip({
                          text: modifiedProgram?.[key],
                        })}
                      >
                        <span className="list-content list-content-campus">
                          {modifiedProgram?.[key]}
                        </span>
                      </OverlayTrigger>
                    ) : (
                      <span className="list-content">
                        {PRICE_FIELDS?.includes(key)
                          ? `${modifiedProgram.currency} ${floatValue(
                              modifiedProgram?.[key] ?? 0
                            )}`
                          : modifiedProgram?.[key] || "None"}
                      </span>
                    )}
                  </li>
                ))}
            </ul>
            <ul className="program-list">
              {Object.keys(PROGRAM_DETAILS_TO_MAP)
                .slice(5)
                .map((key) => (
                  <li>
                    <span className="list-heading">
                      {PROGRAM_DETAILS_TO_MAP[key]} -{" "}
                    </span>
                    {key === "campus" ? (
                      <OverlayTrigger
                        placement="top"
                        overlay={StyledTooltip({
                          text: modifiedProgram?.[key],
                        })}
                      >
                        <span className="list-content list-content-campus">
                          <ProgramValues field={key} value={key} />
                        </span>
                      </OverlayTrigger>
                    ) : (
                      <span className="list-content">
                        {PRICE_FIELDS?.includes(key) ? (
                          `CAD ${floatValue(modifiedProgram?.[key] ?? 0)}`
                        ) : (
                          <ProgramValues
                            field={key}
                            value={modifiedProgram?.[key]}
                          />
                        )}
                      </span>
                    )}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column flex-sm-row cta-program-bottom-row justify-content-between">
        <div
          className="compare-wrapper d-flex flex-row  align-items-center"
          style={
            selectedPrograms.includes(index)
              ? {
                  minWidth: "193px",
                }
              : {
                  minWidth: "145px",
                }
          }
        >
          <div className="form-check form-switch form-check program-checkbox mb-3 mb-sm-0">
            <input
              className="form-check-input text1-5 d-flex "
              type="checkbox"
              role="switch"
              id={`flexSwitchCheckDefault${index}`}
              checked={selectedPrograms.includes(index)}
              onClick={(e) => {
                handleSwitchClick(e, index);
              }}
            />
            <label
              className={`form-check-label text1-3 mt-1`}
              htmlFor={`flexSwitchCheckDefault${index}`}
            >
              {selectedPrograms.includes(index)
                ? "Remove from Compare"
                : "Add to Compare"}
            </label>
          </div>
        </div>
        <div className="card-buttons-container d-flex">
          <Button
            variant="primary"
            onClick={() => {
              window.open(
                `${
                  window.location.origin
                }/programs/${program.collegeName.replaceAll(
                  " ",
                  "-"
                )}/${program.collegeProgramName.replaceAll(
                  " ",
                  "-"
                )}/${program.campus[0].replaceAll(" ", "-")}/overview`,
                "_blank",
                "noreferrer"
              );
            }}
          >
            View Details
          </Button>
          {showEnquireButton && (
            <Button
              variant="primary"
              onClick={() => openEnquireModal?.(program)}
            >
              Enquire Now
            </Button>
          )}
          {showStartApplicationButton && (
            <Button
              variant="primary"
              onClick={() => showStudentList(program)}
              disabled={
                program.intakeList?.length === 0 ||
                program.intakeList.every((intake) => intake.statusId !== 3)
              }
              className={
                program.intakeList?.length === 0 ||
                (program.intakeList.every((intake) => intake.statusId !== 3) &&
                  `bg-secondary opacity-50 border-0`)
              }
              style={{ minWidth: "max-content" }}
            >
              Start Application
            </Button>
          )}
          {showGenerateApplicationButton && (
            <GenerateApplication
              studentData={selectedStudent}
              programData={program}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const PROGRAM_DETAILS_TO_MAP = {
  country: "Country",
  tuitionFee: "Tuition Fees",
  applicationFee: "Application Fees",
  credential: "Credential",
  duration: "Duration",
  province: "Province",
  ieltsRequirement: "IELTS",
  pteRequirement: "PTE",
  intakeList: "Open Intake",
};

const PRICE_FIELDS = ["applicationFee", "tuitionFee"];
