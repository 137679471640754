import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { toast } from "react-hot-toast";
import { Modal, Button, Form, Row, Col } from "react-bootstrap/";
import IntakeService from "../../Services/IntakeService";

export default function AddIntake({ getData, data }) {
  const pageTitle = "Intake";

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDateChange = (date, index) => {
    const newProgramsData = [...ProgramsData];
    newProgramsData[index].date = date;
    setProgramsData(newProgramsData);
  };

  const [ProgramsData, setProgramsData] = useState([
    {
      collegeId: data.collegeId,
      collegeProgramId: data.collegeProgramId,
      date: new Date(),
      statusId: 0,
      collegeCampusId: data.campus[0]?.id,
    },
  ]);

  const [validated, setValidated] = useState(false);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const newProgramsData = [...ProgramsData];
    newProgramsData[index][name] = value;
    setProgramsData(newProgramsData);
  };

  const handleAddNew = () => {
    setProgramsData([
      ...ProgramsData,
      {
        collegeId: data.collegeId,
        collegeProgramId: data.collegeProgramId,
        date: new Date(),
        statusId: 0,
        collegeCampusId: data.campus[0]?.id,
      },
    ]);
  };

  const handleRemove = (index) => {
    const newProgramsData = [...ProgramsData];
    newProgramsData.splice(index, 1);
    setProgramsData(newProgramsData);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);

    if (!form.checkValidity()) {
      event.stopPropagation();
      return;
    }

    try {
      // Display loading message
      const loadingMessage = toast.loading("Saving...");
      const response = await IntakeService.create(ProgramsData);

      if (response.data.success === true) {
        getData();
        const errorMessage = response.data.response
          .map((verr) => verr.message)
          .join(", ");
        toast.success(errorMessage, { id: loadingMessage });
        setShow(false);
      } else if (response.data.success === false) {
        const errorMessage = response.data.response
          .map((verr) => verr.message)
          .join(", ");

        toast.error(errorMessage, { id: loadingMessage });
      } else {
        toast.error("Something Went Wrong", { id: loadingMessage });
      }
    } catch (error) {
      toast.error("An error occurred");
    }
  };

  return (
    <>
      <Button
        variant="outline-success"
        onClick={handleShow}
        className="w-100 rounded-pill"
      >
        Add {pageTitle}
      </Button>

      <Modal show={show} onHide={handleClose} className="mt-5" size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add New {pageTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            className="px-3 fs-5"
          >
            {ProgramsData.map((program, index) => (
              <Row key={index} xs={1} className="g-3 justify-content-between">
                <Col style={{ flex: 1 }}>
                  <Form.Label className="w-100">
                    Select Campus
                    <Form.Select
                      className="fs-5"
                      aria-label="select campus"
                      value={program.collegeCampusId}
                      name="collegeCampusId"
                      onChange={(e) => handleInputChange(e, index)}
                    >
                      {data.campus.map((campus) => (
                        <option value={campus.id} key={campus.id}>
                          {campus.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Label>
                </Col>
                <Col md={3}>
                  <Form.Label className="w-100">
                    Enter Date
                    <div>
                      <DatePicker
                        className="fs-5"
                        selected={program.date}
                        onChange={(date) => handleDateChange(date, index)}
                        showMonthYearPicker
                        dateFormat="MM/yyyy"
                        required
                        wrapperClassName="update-intake-calender"
                      />
                    </div>
                  </Form.Label>
                </Col>
                <Col md={3}>
                  <Form.Label className="w-100">
                    Select Status
                    <Form.Select
                      className="fs-5"
                      aria-label="select Status"
                      value={program.statusId}
                      name="statusId"
                      onChange={(e) => handleInputChange(e, index)}
                    >
                      <option value={0}>Inactive</option>
                      <option value={2}>Yet to Open</option>
                      <option value={3}>Open</option>
                      <option value={4}>Waitlist</option>
                    </Form.Select>
                  </Form.Label>
                </Col>
                <Col
                  md={1}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Button
                    variant="danger"
                    disabled={ProgramsData?.length === 1}
                    onClick={() => handleRemove(index)}
                  >
                    Remove
                  </Button>
                </Col>
              </Row>
            ))}
            <Col md={12}>
              <Button variant="primary" onClick={handleAddNew}>
                Add New
              </Button>
              <Modal.Footer className="mt-4">
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button type="submit" variant="success">
                  Save
                </Button>
              </Modal.Footer>
            </Col>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
