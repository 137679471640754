import React from "react";
export default function ErrorPage() {
  return (
    <>
      <div className="text-center mt-5">
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <a href="/">Home</a>
      </div>
    </>
  );
}
