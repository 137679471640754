import http from "./api";

const get = (id) => {
  return http.get(`ProgramEnquiry?ProgramEnquiryId=${id}`);
};
const remove = (id) => {
  return http.delete(`ProgramEnquiry/?id=${id}`);
};
const create = (data) => {
  return http.post("ProgramEnquiry", data);
};
const update = (data) => {
  return http.put(`ProgramEnquiry`, data);
};
const find = (data) => {
  return http.post("ProgramEnquiry/search?", data);
};

const ProgramEnquiryService = {
  get,
  remove,
  create,
  update,
  find,
};

export default ProgramEnquiryService;
