import React, { useState } from "react";
import enquireWhiteIcon from "../../../assets/icons/enquire_white.svg";
import Enquire from "../Enquire";
import companyLogo from "../../../assets/logo_dark.png";
import { Modal } from "react-bootstrap";
import LoginModal from "../../../layout/LoginModal";
import FormatIntkeDate from "../../../components/UI/FormatIntkeDate";
import { Link } from "react-router-dom";
import ShareButton from "../../../components/ShareButton";
import { ProgramCard } from "./ProgramCard";

export default function ResultCard({
  handleSwitchClick,
  handleCollapseToggle,
  selectedPrograms,
  collapseStatus,
  searchResults,
  selectedProgramsLength,
  uniqueCIDs,
}) {
  const [loginModalShow, setLoginModalShow] = useState(false);
  const [enquireModalShow, setEnquireModalShow] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState(null);

  const openEnquireModal = (program) => {
    setSelectedProgram(program);
    setEnquireModalShow(true);
  };

  const closeModal = () => {
    setSelectedProgram(null);
    setEnquireModalShow(false);
  };

  const handleLogin = (e, program) => {
    e.preventDefault();
    setLoginModalShow(true);
  };
  const currentUrl = window.location.href;

  return (
    <>
      {searchResults?.length === 0 ? (
        <div className="col text-center text1-5">No program found for you</div>
      ) : null}
      {searchResults?.length > 0 && (
        <ul className="program-list-wrapper">
          {searchResults.map((program, index) => (
            <li
              className="resultCard aos-init aos-animate"
              key={index}
              data-aos="fade-up"
              data-aos-offset="10"
            >
              <ProgramCard
                program={program}
                uniqueCIDs={uniqueCIDs}
                openEnquireModal={openEnquireModal}
                selectedPrograms={selectedPrograms}
                handleSwitchClick={handleSwitchClick}
                handleLogin={handleLogin}
                index={index}
              />
              {/* <div>
                <div className="row resultCardBG rounded-5 p-2 border mb-4 shadow">
                  <div className="col-2 bg-white rounded-5 d-flex justify-content-center align-items-center">
                    <img
                      src={
                        uniqueCIDs.find((obj) => {
                          return obj.collegeId === program.collegeId;
                        }).imgUrl
                          ? uniqueCIDs.find((obj) => {
                              return obj.collegeId === program.collegeId;
                            }).imgUrl
                          : companyLogo
                      }
                      alt="ProgramLogo"
                      className={`img-fluid resultCardContent rounded-4`}
                      style={{ height: "100px" }}
                    />
                  </div>
                  <div className="col-10">
                    <div className="">
                      <div className="row border-bottom my-2">
                        <div className="col-7">
                          <div className="text1-7 font-bold">
                            {program.collegeProgramName}
                          </div>

                          <div className="d-flex font-bold">
                            <div className="text-dark1 text1-5 text-capitalize me-4">
                              <i className="bi bi-buildings text-orange text1-3"></i>{" "}
                              {program.collegeName}
                            </div>
                            <div className="text-dark1 text1-5 text-capitalize">
                              <i className="bi bi-flag text-orange text1-3"></i>
                              {program.country}
                            </div>
                          </div>
                          <div className="d-flex font-bold">
                            <div className="me-4">
                              {program.campus && (
                                <span className="text-dark1 text1-3">
                                  <i className="bi bi-geo text-orange "></i>{" "}
                                  {program.campus}
                                </span>
                              )}
                            </div>
                            <div>
                              {program.website && (
                                <a
                                  href={program.website}
                                  style={{ cursor: "pointer" }}
                                  className={`text-dark1 text1-3 text-decoration-none`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i className="bi bi-globe text-orange "></i>
                                  Visit website
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-5">
                          <div className="d-flex me-3 justify-content-end align-items-center">
                            <div className="resultCardContent">
                              <button
                                type="button"
                                className="btn btn-dark-blue rounded-pill p-3 text1-2"
                                onClick={(e) => handleLogin(e, program)}
                              >
                                Login to Select Program
                              </button>
                            </div>
                            <div
                              className="d-flex justify-content-end ms-3"
                              onClick={() => openEnquireModal(program)}
                            >
                              <button
                                className="btn bg-orange rounded-circle"
                                style={{ width: "48px", height: "48px" }}
                              >
                                <img
                                  src={enquireWhiteIcon}
                                  alt="enquire_white"
                                  className="img-fluid d-flex justify-content-center align-items-center"
                                />
                              </button>
                            </div>
                            <ShareButton
                              url={currentUrl}
                              title={program.collegeProgramName}
                            />
                            <div className="" style={{ width: "200px" }}>
                              <div className="form-check form-switch form-check-reverse">
                                <label
                                  className={`form-check-label text1-3`}
                                  htmlFor={`flexSwitchCheckDefault${index}`}
                                >
                                  {selectedPrograms.includes(index)
                                    ? "Remove from Compare"
                                    : "Add to Compare"}
                                </label>
                                <input
                                  className="form-check-input text1-5 d-flex justify-content-end"
                                  type="checkbox"
                                  role="switch"
                                  id={`flexSwitchCheckDefault${index}`}
                                  checked={selectedPrograms.includes(index)}
                                  onChange={(e) => handleSwitchClick(e, index)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row me-2">
                      <div className="col-3">
                        {program.credential && (
                          <div className="text-dark2 text1-2">
                            Credential:{" "}
                            <span className="text1-3 text-dark font-bold">
                              {program.credential}
                            </span>
                          </div>
                        )}
                        {program.duration && (
                          <div className="text-dark2 text1-2">
                            Duration:{" "}
                            <span className="text1-3 text-dark font-bold">
                              {program.duration}
                            </span>
                          </div>
                        )}
                        <div className="text-dark2 text1-2">
                          Application Fee:{" "}
                          <span className="text1-3 text-dark font-bold">
                            ${program.applicationFee}
                          </span>
                        </div>
                      </div>
                      <div className="col-3">
                        {program.ieltsRequirement && (
                          <div className="text-dark2 text1-2">
                            {program.ieltsRequirement.includes("Overall") ? (
                              program.ieltsRequirement.match(
                                /Overall (\d+\.\d+|\d+)/
                              ) ? (
                                <div>
                                  IELTS:{" "}
                                  <span className="text1-3 text-dark font-bold">
                                    {
                                      program.ieltsRequirement.match(
                                        /Overall (\d+\.\d+|\d+)/
                                      )[0]
                                    }
                                  </span>
                                </div>
                              ) : (
                                ""
                              )
                            ) : (
                              <div>
                                IELTS:{" "}
                                <span className="text1-3 text-dark font-bold">
                                  {program.ieltsRequirement}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                        {program.pteRequirement && (
                          <div className="text-dark2 text1-2">
                            {program.pteRequirement.includes("Overall") ? (
                              program.pteRequirement.match(
                                /Overall (\d+\.\d+|\d+)/
                              ) ? (
                                <div>
                                  PTE:{" "}
                                  <span className="text1-3 text-dark font-bold">
                                    {
                                      program.pteRequirement.match(
                                        /Overall (\d+\.\d+|\d+)/
                                      )[0]
                                    }
                                  </span>
                                </div>
                              ) : (
                                ""
                              )
                            ) : (
                              <div>
                                PTE:{" "}
                                <span className="text1-3 text-dark font-bold">
                                  {program.pteRequirement}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                        {program.tuitionFee && (
                          <div className="text-dark2 text1-2">
                            Tuition Fee:{" "}
                            <span className="text1-3 text-dark font-bold">
                              {program.country === "Canada" && "CAD "}
                              {program.country === "UK" && "GBP "}
                              {program.tuitionFee}
                            </span>
                          </div>
                        )}
                      </div>

                      <div className="col-5">
                        <div className="text-dark2 text1-2">
                          {program.intakeList.length > 0 &&
                            (program.intakeList.some(
                              (intake) => intake.statusId === 3
                            ) ||
                              program.intakeList.some(
                                (intake) => intake.statusId === 2
                              )) &&
                            "Intakes"}
                        </div>
                        <div className="text1-3 ">
                          {program.intakeList.length > 0 &&
                            program.intakeList.some(
                              (intake) => intake.statusId === 3
                            ) && (
                              <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="bg-normal-green rounded-4 text-white text1-1 px-2 me-1 ">
                                  <div className="pt-1">Open</div>
                                </div>
                                {program.intakeList
                                  .filter((intake) => intake.statusId === 3) // active + open
                                  .sort(
                                    (a, b) =>
                                      new Date(a.date) - new Date(b.date)
                                  ) // Sort by date
                                  .map((intake, index, array) => (
                                    <div key={intake.date} className="fw-bold">
                                      <FormatIntkeDate date={intake.date} />
                                      {index < array.length - 1 && <>,&nbsp;</>}
                                    </div>
                                  ))}

                                <br />
                              </div>
                            )}
                          {program.intakeList.length > 0 &&
                            program.intakeList.some(
                              (intake) => intake.statusId === 2
                            ) && (
                              <div className="d-flex justify-content-start align-items-center">
                                <div className="bg-normal-red rounded-4 text-white text1-1 px-2 me-1 ">
                                  <div className="pt-1">Close</div>
                                </div>
                                {program.intakeList
                                  .filter((intake) => intake.statusId === 2) // active + close
                                  .sort(
                                    (a, b) =>
                                      new Date(a.date) - new Date(b.date)
                                  ) // Sort by date
                                  .map((intake, index, array) => (
                                    <div key={intake.date} className="fw-bold">
                                      <FormatIntkeDate date={intake.date} />
                                      {index < array.length - 1 && <>,&nbsp;</>}
                                    </div>
                                  ))}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-1">
                        <div className="d-flex align-items-center viewMore ">
                          <Link
                            to={`/program/${program.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-decoration-none"
                          >
                            View Program Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </li>
          ))}
        </ul>
      )}
      {loginModalShow ? (
        <LoginModal modalShow setModalShow={setLoginModalShow} />
      ) : null}
      {selectedProgram && (
        <Modal
          show={enquireModalShow}
          onHide={closeModal}
          centered
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header className="bg-dark1 text-white d-flex justify-content-between align-items-center py-2">
            <Modal.Title
              id="contained-modal-title-vcenter"
              className="d-flex align-items-center"
            >
              Please fill the form below to enquire
            </Modal.Title>
            <i
              className="bi bi-x-circle text2"
              style={{ cursor: "pointer" }}
              onClick={() => setEnquireModalShow(false)}
            ></i>
          </Modal.Header>
          <Modal.Body>
            <Enquire
              program={selectedProgram}
              setModalShow={setEnquireModalShow}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
