const setMinSize = 10240;
//const setMaxSize = 5242880;//5MB
const setMaxSize = 4096000; //4MB
const formatSize = (bytes) => {
  if (bytes < 1024) {
    return bytes + "B";
  } else if (bytes < 1024 * 1024) {
    return (bytes / 1024).toFixed(2) + "KB";
  } else {
    return (bytes / (1024 * 1024)).toFixed(2) + "MB";
  }
};

const FileSizeService = {
  MinSize: setMinSize,
  MaxSize: setMaxSize,
  MinSizeName: formatSize(setMinSize),
  MaxSizeName: formatSize(setMaxSize),
};

export default FileSizeService;
