import React, { useCallback, useEffect, useState } from "react";
import UserPanel from "../UserPanel";
import { useParams } from "react-router-dom";
import GicServices from "../../Services/GicServices";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import InvoiceModal from "./InvoiceModal";
import UploadGicDOcument from "./uploadGicDOcument";
import TokenService from "../../Services/TokenService";
import RolesService from "../../Services/RolesService";
import EpLoader from "../../components/UI/EpLoader";

const pageTitle = "View Details";
export default function ViewForm() {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [isUpdating, setIsUpdating] = useState(false);
  const [InvoiceModalOpen, setInvoiceModalOpen] = useState(false);
  const [applicantId, setApplicantId] = useState("");
  const [studentDetails, setStudentDetails] = useState({
    name: "",
    email: "",
    studentId: "",
    firstName: "",
    lastName: "",
    bank: "",
    passport: "",
    contactNumber: "",
    gicReferenceNumber: "",
  });
  const getRole = TokenService.getUserRole();
  const { BranchHead, Accounts } = RolesService;

  const getGicDetails = useCallback(async () => {
    try {
      setLoading(true);
      const params = {
        currentPage: 1,
        pageSize: 1,
        applicantId: id,
      };
      const response = await GicServices.getGicDetails(params);

      if (response.data.success === true) {
        const students = response.data.response;
        setStudentDetails(students[0]);
      } else if (response.data.validationErrors.length > 0) {
        response.data.validationErrors.map((verr) => toast.error(verr));
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString()
      );
    } finally {
      setLoading(false);
    }
  }, [id]);

  const updateStatus = useCallback(
    async (applicantStatus, id) => {
      try {
        if (applicantStatus === "Commission Paid") {
          setApplicantId(id);
          setInvoiceModalOpen(true);
          return;
        }
        setIsUpdating(true);
        const body = {
          id,
          applicantStatus,
        };
        const response = await GicServices.updateStatus(body);
        if (response.data.success === true) {
          toast.success(response.data.message);
          await getGicDetails();
        } else if (response.data.validationErrors) {
          response.data.validationErrors.forEach((verr) => toast.error(verr));
        } else {
          toast.error("Something Went Wrong");
        }
      } catch (error) {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
        );
      } finally {
        setIsUpdating(false);
      }
    },
    [getGicDetails]
  );

  useEffect(() => {
    getGicDetails();
  }, [getGicDetails]);
  if (loading) {
    return <EpLoader />;
  }

  return (
    <div className="py-2 px-4 mt-2">
      <div className="row align-items-center">
        <div className="col">
          <UserPanel title={pageTitle} />
        </div>
        <div className="shadow form-container px-0 mt-5">
          <div className=" d-flex justify-content-center col text2-2 bg-dark1 text-white py-4 form-header">
            GIC Form Details
          </div>
          <form className="needs-validation px-5 ms-0  ms-md-5">
            <div className="row gy-5 ms-3 gx-4 py-5 px-md-5 px-2 d-flex">
              <div className="col-6">
                <p className=" text-dark1 text1-4 ps-4 ">First Name</p>
                <div className="w-100 w-md-75 form-floating px-3 ">
                  <input
                    type="text"
                    className="form-control rounded-pill border"
                    placeholder="First name*"
                    required
                    id="firstName"
                    value={studentDetails.firstName}
                    disabled
                    name="firstName"
                    autoComplete="off"
                  />
                  <label
                    htmlFor="floatingInput"
                    className="ms-4 text-light-gray "
                  >
                    First Name
                  </label>
                </div>
              </div>
              <div className="col-6">
                <p className=" text-dark1 text1-4 ps-4 ">Last Name</p>
                <div className=" w-100 w-md-75 form-floating px-3">
                  <input
                    type="text"
                    className="form-control rounded-pill border"
                    placeholder="Last name"
                    id="lastName"
                    value={studentDetails.lastName ?? "N/A"}
                    disabled
                    name="lastName"
                    autoComplete="off"
                  />
                  <label
                    htmlFor="floatingInput"
                    className="ms-4 text-light-gray"
                  >
                    Last Name
                  </label>
                </div>
              </div>
              <div className="col-6">
                <p className="ps-4 text-dark1 text1-4 ">Email</p>
                <div className="w-100 w-md-75 form-floating px-3">
                  <input
                    type="email"
                    className="form-control rounded-pill border"
                    placeholder="Country of Citizenship"
                    required
                    id="email"
                    value={studentDetails.email}
                    disabled
                    name="email"
                    autoComplete="off"
                  />
                  <label
                    htmlFor="floatingInput"
                    className="ms-4 text-light-gray"
                  >
                    Email
                  </label>
                </div>
              </div>
              <div className="col-6">
                <p className="text-dark1 text1-4 ps-4 ">Bank</p>
                <div className="w-100 w-md-75 form-floating px-3">
                  <input
                    type="text"
                    className="form-control rounded-pill border"
                    placeholder="Choose Bank"
                    required
                    id="email"
                    value={studentDetails.bank}
                    disabled
                    name="bank"
                    autoComplete="off"
                  />
                  <label
                    htmlFor="floatingInput"
                    className="ms-4 text-light-gray"
                  >
                    Bank
                  </label>
                </div>
              </div>
              <div className="col-6">
                <p className="text-dark1 text1-4 ps-4 ">Passport Number</p>
                <div className="w-100 w-md-75 form-floating px-3">
                  <input
                    type="text"
                    className="form-control rounded-pill border"
                    placeholder="Passport*"
                    required
                    id="passport"
                    name="passport"
                    pattern="^[A-Z]{1}[0-9]{7}$"
                    value={
                      studentDetails.passport ? studentDetails.passport : "N/A"
                    }
                    disabled
                    autoComplete="off"
                  />

                  <label htmlFor="floatingInput" className="ms-4 pt-2">
                    Passport
                  </label>
                </div>
              </div>
              <div className="col-6">
                <p className="ps-4 text-dark1 text1-4 ">Contact Number</p>
                <div className="w-100 w-md-75 form-floating px-3">
                  <input
                    type="text"
                    className="form-control rounded-pill border"
                    placeholder="Contact Number"
                    required
                    id="contactNumber"
                    name="contactNumber"
                    value={
                      studentDetails.contactNumber
                        ? studentDetails.contactNumber
                        : "N/A"
                    }
                    disabled
                    autoComplete="off"
                  />
                  <label
                    htmlFor="floatingInput"
                    className="ms-4 text-light-gray"
                  >
                    Contact
                  </label>
                </div>
              </div>
              <div className="col-6">
                <p className="ps-4 text-dark1 text1-4 ">GIC Reference Number</p>
                <div className="w-100 w-md-75 form-floating px-3">
                  <input
                    type="text"
                    className="form-control rounded-pill border"
                    placeholder="GIC Reference Number"
                    required
                    id="gicReferenceNumber"
                    disabled
                    value={
                      studentDetails.gicReferenceNumber
                        ? studentDetails.gicReferenceNumber
                        : "N/A"
                    }
                    name="gicReferenceNumber"
                    autoComplete="off"
                  />
                  <label
                    htmlFor="floatingInput"
                    className="ms-4 text-light-gray"
                  >
                    GIC Reference
                  </label>
                </div>
              </div>
              <div className="col-6">
                <p className="text-dark1 text1-4 ps-4 ">Status</p>
                <div className="w-100 w-md-75 form-floating px-3">
                  {getRole === Accounts ? (
                    <select
                      className="form-select rounded-pill border"
                      aria-label="status"
                      required
                      id="status"
                      name="applicationStatus"
                      value={studentDetails.applicationStatus}
                      onChange={(e) => {
                        updateStatus(e?.target?.value, id);
                      }}
                      autoComplete="off"
                    >
                      {isUpdating ? (
                        <option className="" value="">
                          Please Wait...
                        </option>
                      ) : (
                        <>
                          <option value="In Progress">In Progress</option>
                          <option value="Account Opened">Account Opened</option>
                          <option value="Account Funded">Account Funded</option>
                          <option value="Commission Paid">
                            Commission Paid
                          </option>
                        </>
                      )}
                    </select>
                  ) : (
                    <>
                      <input
                        type="text"
                        className="form-control rounded-pill border"
                        placeholder="Status"
                        required
                        id="status"
                        disabled
                        value={studentDetails.applicationStatus}
                        name="applicationStatus"
                        autoComplete="off"
                      />
                      <label
                        htmlFor="floatingInput"
                        className="ms-4 text-light-gray"
                      >
                        Status
                      </label>
                    </>
                  )}
                </div>
              </div>
              {(studentDetails.gicCertificateFilePath &&
                getRole === BranchHead) ||
              getRole === Accounts ? (
                <div className="col-6">
                  <p className="text-dark1 text1-4 ps-4 ">GIC Certificate</p>
                  <div className="w-100 w-md-75 px-3 ">
                    <UploadGicDOcument
                      id={id}
                      type="GICCertificate"
                      label="GIC Certificate"
                      filePath={studentDetails.gicCertificateFilePath}
                      getData={getGicDetails}
                    />
                  </div>
                </div>
              ) : null}

              {(studentDetails.instructionSheetFilePath &&
                getRole === BranchHead) ||
              getRole === Accounts ? (
                <div className="col-6">
                  <p className="text-dark1 text1-4 ps-4 ">Instruction Sheet</p>
                  <div className="w-100 w-md-75 px-3">
                    <UploadGicDOcument
                      id={id}
                      type="InstructionSheet"
                      label="Instruction Sheet"
                      filePath={studentDetails.instructionSheetFilePath}
                      getData={getGicDetails}
                    />
                  </div>
                </div>
              ) : null}
              {(studentDetails.invoiceFilePath && getRole === BranchHead) ||
              (studentDetails.invoiceFilePath &&
                getRole === Accounts &&
                studentDetails.applicationStatus === "Commission Paid") ? (
                <div className="col-6">
                  <p className="text-dark1 text1-4 ps-4 ">Invoice</p>
                  <div className="w-100 w-md-75 px-3">
                    <UploadGicDOcument
                      id={id}
                      type="Invoice"
                      label="Invoice"
                      filePath={studentDetails.invoiceFilePath}
                      getData={getGicDetails}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </form>
        </div>
      </div>
      <InvoiceModal
        showModal={InvoiceModalOpen}
        handleCloseModal={() => setInvoiceModalOpen(false)}
        applicantId={applicantId}
        getGicDetails={getGicDetails}
      />
    </div>
  );
}
