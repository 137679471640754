import http from "./api";

const get = (id) => {
  return http.get(`PromoCode?PromoCodeId=${id}`);
};
const remove = (id) => {
  return http.delete(`PromoCode?id=${id}`);
};
const create = (data) => {
  return http.post("PromoCode", data);
};
const update = (data) => {
  return http.put(`PromoCode`, data);
};
const getListByCollegeId = (id) => {
  return http.get(`PromoCode/getListByCollegeId?collegeId=${id}`);
};

const find = (data) => {
  return http.post("PromoCode/search?", data);
};

const PromoCodeDataService = {
  get,
  remove,
  create,
  update,
  getListByCollegeId,
  find,
};

export default PromoCodeDataService;
