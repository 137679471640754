import React, { useCallback } from "react";
import AuthService from "../Services/AuthService";

const Logout = () => {
  const handleLogout = useCallback(() => {
    AuthService.logout();
    // navigate("/");
    window.location.href = "/";
  }, []);
  return (
    <>
      {/* <SessionTimeOut onLogout={handleLogout} /> */}
      {/* <NavLink
        to="/"
        className="btn btn-dark-outline-red rounded-2 py-2 text1-4 dropdown-item"
        onClick={handleLogout}
      >
        <span>Logout</span>
      </NavLink> */}
      <div
        className="btn btn-dark-outline-red rounded-2 py-2 text1-4 dropdown-item"
        onClick={handleLogout}
      >
        <span>Logout</span>
      </div>
    </>
  );
};

export default Logout;
