import React from "react";
import lightLogo from "../../assets/logo_text_light.png";
import globeMap from "../../assets/images/globeMap.png";

import FloatingWhatsAppButton from "../../components/UI/FloatingWhatsAppButton";
import GoDaddySSLSeal from "../../layout/GoDaddySSLSeal";
import TokenService from "../../Services/TokenService";
export default function AppFooter() {
  const token = TokenService.getLocalAccessToken();
  const isAuth = Boolean(token);

  return (
    <>
      <div
        className={`mt-5 bg-dark1 py-5 text-white position-relative d-print-none`}
      >
        <div className="position-absolute top-0 start-0 opacity-25 ms-1 mt-1 z-1">
          <img src={globeMap} alt={globeMap} style={{ maxWidth: "300px" }} />
        </div>

        <div className="container py-5 position-relative z-2">
          <div className="row">
            <div className="col-md-4 text1-4">
              <div>
                <img
                  src={lightLogo}
                  alt="lightLogo"
                  className="img-fluid mb-4"
                  width={"200px"}
                />
              </div>
              {/* <div className="my-2 lh-lg">
                Education Planners enriched with the experience of over 11 years in the international education domain is a meticulously
                structured organization...
              </div> */}
              <div className="d-flex align-items-center mt-5">
                <div>Connect with us:</div>
                <a href="https://www.facebook.com/eduplanner.io"
                  target="_blank" rel="noreferrer"
                  className="mx-2 socialIcon text-white text-decoration-none"
                  style={{ cursor: "pointer" }}>
                  <i className="bi bi-facebook"></i>
                </a>
                <a href="https://www.instagram.com/educationplanner.io/"
                  target="_blank" rel="noreferrer"
                  className="mx-2 socialIcon text-white text-decoration-none"
                  style={{ cursor: "pointer" }}>
                  <i className="bi bi-instagram"></i>
                </a>
                <a
                  href="https://api.whatsapp.com/send?phone=919914100042&text=Hi"
                  target="_blank"
                  rel="noreferrer"
                  className="mx-2 socialIcon text-white text-decoration-none"
                  style={{ cursor: "pointer" }}
                >
                  <i className="bi bi-whatsapp"></i>
                </a>
              </div>
            </div>
            <div className="col-md-2">
              <div className="font-bold text1-6 mb-4">Register</div>
              {/* <div className=" mb-3">
                <a
                  href="/#/agent"
                  className="link-light link-underline-opacity-0 text1-4"
                >
                  {isAuth ? "Agent" : "Partner"}
                </a>
              </div> */}

              <div className=" mb-3">
                <a
                  href="/#/agent"
                  className="link-light link-underline-opacity-0 text1-4"
                >
                  Partner
                </a>
              </div>

              <div className=" mb-3">
                <a
                  href="/#/institutions"
                  className="link-light link-underline-opacity-0 text1-4"
                >
                  Institution
                </a>
              </div>
            </div>
            <div className="col-md-2">
              <div className="font-bold text1-6 mb-4">Other Links</div>
              <div className=" mb-3">
                <a
                  href="/#/about-us"
                  className="link-light link-underline-opacity-0 text1-4"
                >
                  About Us
                </a>
              </div>
              {/* <div className=" mb-3">
                <a
                  href="/"
                  className="link-light link-underline-opacity-0 text1-4"
                >
                  How It Works
                </a>
              </div> */}
              <div className=" mb-3">
                <a
                  href="/#/contact-us"
                  className="link-light link-underline-opacity-0 text1-4"
                >
                  Contact Us
                </a>
              </div>
              <div className=" mb-3">
                <a
                  href="/#/blogs"
                  className="link-light link-underline-opacity-0 text1-4"
                >
                  Blog
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="font-bold text1-6 mb-4">Reach Us</div>
              {/* <div className="d-flex">
                <div className="me-2">
                  <i className="bi bi-geo-alt text-orange fs-4"></i>
                </div>
                <div className="mb-3">
                  <a href="geo:12.9716,77.5946?q=Punjab, India" className="link-light link-underline-opacity-0 text1-5">
                    Punjab, India
                  </a>
                </div>
              </div> */}

              <div className="d-flex my-2">
                <div className="me-2">
                  <i className="bi bi-telephone text-orange fs-4"></i>
                </div>
                <div className="mb-3">
                  <a
                    href="tel:+919914100042"
                    className="link-light link-underline-opacity-0 text1-5"
                  >
                    +91 99141 00042
                  </a>
                </div>
              </div>

              <div className="d-flex">
                <div className="me-2">
                  <i className="bi bi-envelope text-orange fs-4"></i>
                </div>
                <div className="mb-3">
                  <a
                    href="mailto:partnerships@educationplanner.io"
                    className="link-light link-underline-opacity-0 text1-5"
                  >
                    partnerships@educationplanner.io
                  </a>
                </div>
              </div>
            </div>
          </div>
          <p className="pt-4 text-center">Website owned & managed by <br /> Learning Highway Institute & Consultancy Services</p>

        </div>
        <div className="position-absolute bottom-0 end-0 opacity-25 me-5 mb-1">
          <img src={globeMap} alt={globeMap} style={{ maxWidth: "320px" }} />
        </div>
      </div>
      {/* <Footer /> */}
      <GoDaddySSLSeal />
      <FloatingWhatsAppButton />
    </>
  );
}
