import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginImg from "../../assets/images/loginImg.png";
import SubmitButton from "../../components/Form/SubmitButton";
import AuthService from "../../Services/AuthService";
import Captcha from "../../components/Captcha";
import TokenService from "../../Services/TokenService";
import RoleService from "../../Services/RolesService";
import { toast } from "react-toastify";
export default function Login({ showSignUp, forgetPassword, captchaResponse }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [viewPassword, setViewPassword] = useState(false);
  //
  const { SuperAdmin, BranchHead } = RoleService;
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await AuthService.login(email, password);
      await TokenService.getLocalAccessToken();
      await TokenService.getUserRole();
      const token = TokenService.getLocalAccessToken();
      const getRole = TokenService.getUserRole();
      if (token) {
        if (getRole === SuperAdmin || getRole === BranchHead) {
          navigate("/user/dashboard");
          localStorage.setItem("activeItem", "/user/dashboard");
        } else {
          navigate("/user");
        }
      }
    } catch (error) {
      toast.error(error.toString());
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleViewPassword = () => {
    setViewPassword(!viewPassword);
  };

  const [captchaValue, setCaptchaValue] = useState("");

  const captchaToLogin = (captchadata) => {
    setCaptchaValue(captchadata);
  };

  const [iscaptcha, setIsCaptcha] = useState("");
  const onChangeCaptcha = (e) => {
    const iscaptcha = e.target.value;
    setIsCaptcha(iscaptcha);
  };
  return (
    <>
      <div className="row g-3 px-5 mt-5">
        <form className="row gap-5 needs-validation px-5" onSubmit={handleLogin}>
          <div>
            <p className='text-dark1 text1-4'>Email Address</p>
            <div className="col-12 form-floating ">
              <input
                type="email"
                className="form-control rounded-pill pt-3 border ps-4 pb-1"
                placeholder="Email*"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div
                className="position-absolute top-50 end-0 translate-middle-y me-4">
                <i class="bi bi-person text-light-gray text2"></i>
              </div>
              <label htmlFor="floatingInput" className="ms-4 pt-2">
                Enter Email Address<span className="text-danger">*</span>
              </label>
            </div>
          </div>
          <div>
            <p className='text-dark1 text1-4'> Password</p>
            <div className="col-12 form-floating ">
              <input
                type={viewPassword ? "text" : "password"}
                className="form-control rounded-pill border ps-4 pb-1"
                placeholder="Password*"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div
                className="position-absolute top-50 end-0 translate-middle-y me-4"
                onClick={handleViewPassword}
              >
                <i
                  className={`bi ${viewPassword ? "bi-eye-slash" : "bi-eye"
                    } d-flex align-items-center text-light-gray text2`}
                ></i>
              </div>
              <label htmlFor="floatingInput" className="ms-4 pt-2">
                Enter Password<span className="text-danger">*</span>
              </label>
            </div>
          </div>
          {/* <div className="col-12 px-3">
            <div className="d-flex align-items-center form-floating">
              <input
                type="text"
                className="form-control rounded-pill border ps-4 pb-1"
                placeholder="Captcha*"
                onChange={onChangeCaptcha}
                minLength={6}
                maxLength={6}
                required 
              />
              <label htmlFor="floatingInput" className="ms-4 pt-2">
                Captcha<span className="text-danger">*</span>
              </label>
              <div className="" style={{ minWidth: "120px" }}>
                <Captcha captchaToAll={captchaToLogin} />
              </div>
            </div>
          </div> */}

          {process.env.REACT_APP_ENVIRONMENT === "local" ? (
            <SubmitButton title={"Login"} loading={loading} />
          ) : (
            ""
          )}

          {email &&
            password &&
            captchaResponse?.success === true &&
            captchaResponse?.score > 0.2 ? (
            <div className={`col-10 mx-auto`}>
              <SubmitButton title={"Login"} loading={loading} />
            </div>
          ) : (
            <div className={`col-10 mx-auto`}>
              <button
                type="button"
                className="gradiantButton rounded-pill py-3 font-bold fs-4 w-100 d-flex justify-content-center align-items-center"
                disabled
              >
                Login
              </button>
            </div>
          )}
          <div className="col-12 text-center">
            <p
              className="text-light-gray link-underline link-underline-opacity-0 text-link mb-0"
              onClick={forgetPassword}
              style={{ cursor: "pointer" }}
            >
              Forget your Password?
            </p>
            <div className="d-flex align-items-center my-3">
              <hr className="w-50">
              </hr>
              <p className="p-3 mb-0 text-light-gray">or</p>
              <hr className="w-50">
              </hr>
            </div>
            <div className="d-flex justify-content-center mb-3">
              <p className="text1-4 text-light-gray font-bold me-2">New User?</p>
              <p
                className="text1-6 font-bold text-dark1"
                style={{ cursor: "pointer" }}
                onClick={showSignUp}
              >
                Sign Up
              </p>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
