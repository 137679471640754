import React, { useState, useEffect } from "react";
import SpinnerGrow from "../../Components/SpinnerGrow";
import logoTextDark from "../../Assets/logo_text_dark.png";
import AddBlog from "./AddBlog";
import ListBlog from "./ListBlog";
import { toast } from "react-hot-toast";
import BlogDataService from "../../Services/BlogService";
import SearchBar from "../../Components/SearchBar";
import SelectItemsPerPage from "../../Components/SelectItemsPerPage";
import AdvancePagination from "../../Components/Pagination";
import ConfirmationModal from "../../Components/ConfirmationModal";
import SpinnerBorder from "../../Components/SpinnerBorder";

export default function MainBlog() {
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const pageTitle = "Blogs";
  const initializeData = [
    {
      title: "",
      description: "",
      image: "",
      link: "",
      date: "",
      statusId: 0,
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [itemsLength, setItemsLength] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusId, setStatusId] = useState();

  const [BlogData, setBlogData] = useState(initializeData);
  const getBlogData = async () => {
    setUpdating(true);
    try {
      let data;
      if (statusId !== "All") {
        data = {
          search: searchQuery,
          currentPage,
          pageSize,
          statusId,
        };
      } else {
        data = {
          search: searchQuery,
          currentPage,
          pageSize,
        };
      }
      const response = await BlogDataService.find(data);
      const responseData = response.data;
      setBlogData(responseData);
      setLoading(false);
      setItemsLength(responseData[0]?.totalItems);
      setUpdating(false);
    } catch (error) {
      console.error(error);
      setUpdating(false);
    }
  };

  useEffect(() => {
    document.title = pageTitle;
  }, []);
  useEffect(() => {
    getBlogData();
  }, [searchQuery, currentPage, pageSize, pageTitle, statusId]);

  const handleSearchInput = (e) => {
    e.preventDefault();
    console.log(e.target.value);
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const clearSearchQuery = () => {
    setSearchQuery("");
  };

  const handlePageSizeChange = (event) => {
    event.preventDefault();
    setPageSize(parseInt(event.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [itemToConfirm, setItemToConfirm] = useState(null);

  const handleDelete = (e, data) => {
    e.preventDefault();
    setItemToConfirm(data);
    setShowConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    setShowConfirmation(false);
    if (itemToConfirm) {
      try {
        await toast.promise(BlogDataService.remove(itemToConfirm.id), {
          loading: "Deleting...",
          success: <b> {itemToConfirm.title} deleted successfully!</b>,
          error: <b>Could not delete {itemToConfirm.title}</b>,
        });
        setItemToConfirm(null);
        getBlogData(); // updates the data after deletion
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
    setItemToConfirm(null);
  };

  if (loading) return <SpinnerGrow />;

  return (
    <>
      <div className="row px-5">
        <div className="text-center fs-1 text-uppercase col-12">
          {pageTitle}
        </div>
        <div className="col-6">
          <AddBlog pageTitle={pageTitle} getData={getBlogData} />
        </div>
        <div className="col-6 d-flex justify-content-end">
          <select
            className="form-select rounded-4 fs-5 py-2"
            aria-label="college select"
            name="collegeId"
            value={statusId}
            onChange={(e) => setStatusId(e.target.value)}
            style={{ width: "100px" }}
          >
            <option value={"All"}>All</option>
            <option value={0}>Inactive</option>
            <option value={1}>Active</option>
          </select>
          <SearchBar
            searchQuery={searchQuery}
            handleSearchInput={handleSearchInput}
            clearSearchQuery={clearSearchQuery}
            searchTitle={`Search ${pageTitle}`}
          />
          <SelectItemsPerPage handlePageSizeChange={handlePageSizeChange} />
        </div>
        {updating ? (
          <SpinnerBorder />
        ) : BlogData.length === 0 ? (
          <div className="mt-5 text-center fs-1">No Data Found</div>
        ) : (
          <div className="col-12 p-4">
            <ListBlog
              dataList={BlogData}
              handleDelete={handleDelete}
              getData={getBlogData}
              pageTitle={pageTitle}
            />
            <AdvancePagination
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              totalItems={itemsLength}
              pageSize={pageSize}
            />
            <ConfirmationModal
              show={showConfirmation}
              onHide={handleCloseConfirmation}
              onConfirm={handleConfirmDelete}
              message={
                itemToConfirm
                  ? `Are you sure want to delete ${itemToConfirm.title}?`
                  : ""
              }
              title={itemToConfirm?.title}
            />
          </div>
        )}
      </div>
    </>
  );
}
