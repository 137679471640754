import React from "react";
import CountUp from "../../components/CountUp";
import countriesIcon from "../../assets/icons/countries.svg";
import customersIcon from "../../assets/icons/customers.svg";
import satisfactionIcon from "../../assets/icons/satisfaction.svg";
import bg_gradient from "../../assets/landingPage/bg_gradient.svg";
import heroImg from "../../assets/landingPage/hero_img.png";
import linesIcon from "../../assets/landingPage/lines.svg";

const Numbers = () => {
  return (
    <div className="bg-dark1 py-5">
      <div className="my-xs-3">
        <div className="d-flex justify-content-around">
          <div className="">
            <div className="d-flex flex-column flex-sm-row align-items-center">
              <div className="text-white text-center ms-3">
                <div className="static-count-data">
                  <strong>
                    <CountUp
                      start={0}
                      end={1000}
                      duration={2000}
                      suffix={"+"}
                    />
                  </strong>
                </div>
                <div className="static-count-title">Agents</div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="d-flex flex-column flex-sm-row align-items-center">
              <div className="text-white text-center ms-3">
                <div className="static-count-data">
                  <strong>
                    <CountUp
                      start={0}
                      end={1000}
                      duration={2000}
                      suffix={"+"}
                    />
                  </strong>
                </div>
                <div className="static-count-title">Partners</div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="d-flex flex-column flex-sm-row align-items-center">
              <div className="text-white text-center ms-3">
                <div className="static-count-data">
                  <strong>
                    <CountUp
                      start={0}
                      end={1000}
                      duration={2000}
                      suffix={"+"}
                    />
                  </strong>
                </div>
                <div className="static-count-title">Institutes</div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="d-flex flex-column flex-sm-row align-items-center">
              <div className="text-white text-center ms-3">
                <div className="static-count-data">
                  <strong>
                    <CountUp
                      start={0}
                      end={1000}
                      duration={2000}
                      suffix={"+"}
                    />
                  </strong>
                </div>
                <div className="static-count-title">Happy Clients</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Numbers;
