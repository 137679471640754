import React from "react";

export default function Checkbox({
  id,
  label,
  value,
  required,
  onChange,
  isChecked,
  customCSS,
  allDraftApplication,
  disabled,
}) {
  const handleCheckboxChange = () => {
    onChange(id, !isChecked);
  };

  return (
    <div
      className={`form-check text1-4 mx-3 ${
        customCSS ? "orange-checkbox" : "blue-checkbox"
      }`}
    >
      <input
        className="form-check-input"
        type="checkbox"
        //value={value}
        id={id}
        required={required}
        onChange={handleCheckboxChange}
        checked={isChecked}
        disabled={disabled}
      />
      <label className="form-check-label pt-1" htmlFor={id}>
        {label}
      </label>
    </div>
  );
}
