import React, { useCallback, useEffect, useState } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import ProgramEnquiryService from "../../Services/ProgramEnquiryService";
import PhoneInput from "react-phone-input-2";
import "./Enquire.css";
import { useSearchParams } from "react-router-dom";
import apiURLandKEYS from "../../Services/apiURLandKEYS";

export default function Enquire({ program, setModalShow }) {
  const [programEnquiry, setProgramEnquiry] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    country: "",
    query: "",
    statusId: 1,
    state: "",
  });
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [locationAuthToken, setLocationAuthToken] = useState(null);
  const [loading, setLoading] = useState({
    states: false,
    countries: false,
    submitting: false,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;
    setProgramEnquiry((p) => ({ ...p, [name]: value }));
  }, []);

  const saveProgramEnquiry = useCallback(async (payload) => {
    return await ProgramEnquiryService.create(payload);
  }, []);

  const fetchLeadSquaredApi = useCallback(async (leadSquaredPayload) => {
    return await fetch(
      `https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=${apiURLandKEYS.leadSquaredAccessKey}&secretKey=${apiURLandKEYS.leadSquaredSecretKey}`,
      {
        method: "POST",
        body: JSON.stringify(leadSquaredPayload),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }, []);

  const handleFormSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        setLoading((p) => ({ ...p, submitting: true }));
        const serverPayload = {
          collegeProgramId: program.id || program.collegeProgramId,
          firstName: programEnquiry.firstName,
          lastName: programEnquiry.lastName,
          phoneNumber: programEnquiry.phoneNumber,
          email: programEnquiry.email,
          countryofResidence: programEnquiry.country,
          query: programEnquiry.query,
          statusId: 1,
        };
        const leadSquaredPayload = [
          {
            Attribute: "FirstName",
            Value: programEnquiry.firstName,
          },
          {
            Attribute: "LastName",
            Value: programEnquiry.lastName,
          },
          {
            Attribute: "EmailAddress",
            Value: programEnquiry.email,
          },
          {
            Attribute: "mx_State",
            Value: programEnquiry.state,
          },
          {
            Attribute: "Phone",
            Value: programEnquiry.phoneNumber,
          },
          {
            Attribute: "mx_Country",
            Value: programEnquiry.country,
          },
          {
            //source - user kahan se aaya
            Attribute: "Source",
            Value:
              searchParams.get("utm_source") ??
              document.referrer ??
              window.location.origin,
          },
          {
            Attribute: "SourceCampaign",
            Value: searchParams.get("utm_campaign") ?? "enquiry",
          },
          {
            // medium - banner, newsletter
            Attribute: "SourceMedium",
            Value: searchParams.get("utm_medium") ?? "web app",
          },
          {
            Attribute: "Program",
            Value: program.collegeProgramName,
          },
        ];
        const [saveProgramEnquiryResponse, leadSquaredResponse] =
          await Promise.all([
            saveProgramEnquiry(serverPayload),
            fetchLeadSquaredApi(leadSquaredPayload),
          ]);
        const leadSquaredJsonedResponse = await leadSquaredResponse.json();
        if (
          leadSquaredJsonedResponse.Status === "Success" &&
          saveProgramEnquiryResponse.status === 200
        ) {
          toast.success("We will reach you shortly!");
          setModalShow(false);
        } else {
          toast("Something went wrong");
        }
      } catch (err) {
        toast.error("Something went wrong");
      } finally {
        setLoading((p) => ({ ...p, submitting: false }));
      }
    },
    [
      program,
      programEnquiry,
      searchParams,
      saveProgramEnquiry,
      fetchLeadSquaredApi,
      setModalShow,
    ]
  );

  useEffect(() => {
    async function getLocationAuthToken() {
      try {
        const authTokenResponse = await fetch(
          `https://www.universal-tutorial.com/api/getaccesstoken`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "api-token": apiURLandKEYS.getAccessTokenForLocations,
              "user-email": "mandeepin@msn.com",
            },
          }
        );
        const jsonedResponse = await authTokenResponse.json();
        setLocationAuthToken(jsonedResponse.auth_token);
      } catch (err) {
        toast.error("Something went wrong...");
      }
    }
    getLocationAuthToken();
  }, []);

  useEffect(() => {
    if (!locationAuthToken) {
      return;
    }
    async function getCountries() {
      try {
        setLoading((p) => ({ ...p, countries: true }));
        const countriesResponse = await fetch(
          `https://www.universal-tutorial.com/api/countries/`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${locationAuthToken}`,
              Accept: "application/json",
            },
          }
        );
        const jsonedResponse = await countriesResponse.json();
        setCountries(jsonedResponse);
      } catch (err) {
        toast.error("Something went wrong while fetching countries...");
      } finally {
        setLoading((p) => ({ ...p, countries: false }));
      }
    }
    getCountries();
  }, [locationAuthToken]);

  useEffect(() => {
    if (!programEnquiry.country || !locationAuthToken) {
      return;
    }
    async function getStates() {
      try {
        setLoading((p) => ({ ...p, states: true }));
        const states = await fetch(
          `https://www.universal-tutorial.com/api/states/${programEnquiry.country}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${locationAuthToken}`,
              Accept: "application/json",
            },
          }
        );
        const jsonedResponse = await states.json();
        setStates(jsonedResponse);
      } catch (err) {
        toast.error("Something went wrong while fetching states...");
      } finally {
        setLoading((p) => ({ ...p, states: false }));
      }
    }
    getStates();
  }, [programEnquiry.country, locationAuthToken]);
  const handlePhoneNumberChange = useCallback(
    (value, data, event, formattedValue) => {
      setProgramEnquiry((p) => ({ ...p, phoneNumber: formattedValue }));
    },
    []
  );

  return (
    <>
      <form
        className="need-validation enquiry-form w-100"
        onSubmit={handleFormSubmit}
      >
        <div className=" bg-white m-0 p-0">
          <div className="row g-3 p-4">
            <div className="col-12 text-center font-bold text1-6 col-2">
              Program Name:{" "}
              <span className="text-orange">
                {program.name || program.collegeProgramName}
              </span>
            </div>
            <div className="col-6 form-floating px-3">
              <input
                type="text"
                className="form-control rounded-pill border"
                placeholder="First name*"
                required
                name="firstName"
                value={programEnquiry.firstName}
                onChange={handleInputChange}
              />
              <label htmlFor="floatingInput" className="ms-4">
                First Name<span className="text-danger">*</span>
              </label>
            </div>
            <div className="col-6 form-floating px-3">
              <input
                type="text"
                className="form-control rounded-pill border"
                placeholder="Last name*"
                required
                name="lastName"
                value={programEnquiry.lastName}
                onChange={handleInputChange}
              />
              <label htmlFor="floatingInput" className="ms-4">
                Last Name<span className="text-danger">*</span>
              </label>
            </div>
            <div className="col-6 form-floating px-3">
              <PhoneInput
                country={"in"}
                value={programEnquiry.phoneNumber}
                onChange={handlePhoneNumberChange}
                placeholder="Enter your no."
                countryCodeEditable={false}
                inputProps={{
                  name: "phoneNumber",
                  required: true,
                }}
                containerStyle={{
                  width: "100%",
                }}
                inputStyle={{
                  width: "100%",
                  borderRadius: "25px",
                  border: "1px solid #dee2e6",
                }}
                dropdownStyle={{
                  zIndex: 1000,
                  background: "white",
                }}
              />
            </div>
            <div className="col-6 form-floating px-3">
              <input
                type="email"
                className="form-control rounded-pill border"
                placeholder="Email*"
                required
                name="email"
                value={programEnquiry.email}
                onChange={handleInputChange}
              />
              <label htmlFor="floatingInput" className="ms-4">
                Email address<span className="text-danger">*</span>
              </label>
            </div>
            <div className="col-6 form-floating px-3">
              <FloatingLabel
                controlId="floatingSelect"
                label="Select Country"
                className="w-100"
              >
                <Form.Select
                  aria-label="Floating label select country"
                  defaultValue={programEnquiry.country}
                  name="country"
                  onChange={handleInputChange}
                  required
                  className="rounded-pill"
                >
                  <option value="">Select Country</option>
                  {countries?.map((country) => (
                    <option key={country} value={country.country_name}>
                      {country.country_name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please provide Country
                </Form.Control.Feedback>
              </FloatingLabel>
            </div>
            <div className="col-6 form-floating px-3 align-items-center d-flex align-items-center flex-row">
              {loading.states ? (
                <Form.Text className="ms-4 mt-0">Loading States...</Form.Text>
              ) : (
                <FloatingLabel
                  controlId="floatingSelect"
                  label="Select State"
                  className="w-100"
                >
                  <Form.Select
                    aria-label="Floating label select state"
                    defaultValue={programEnquiry.state}
                    name="state"
                    onChange={handleInputChange}
                    required
                    className="rounded-pill"
                  >
                    <option value="">Select State</option>
                    {states?.map((state) => (
                      <option key={state} value={state.state_name}>
                        {state.state_name}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please provide State
                  </Form.Control.Feedback>
                </FloatingLabel>
              )}
            </div>
            <div className="col-12 form-floating px-3">
              <textarea
                className="form-control rounded-4 border"
                id="floatingTextarea"
                placeholder="Query"
                required
                style={{ height: "120px" }}
                name="query"
                value={programEnquiry.query}
                onChange={handleInputChange}
              ></textarea>
              <label
                htmlFor="floatingTextarea"
                className="ms-4 text-light-gray"
              >
                Query<span className="text-danger">*</span>
              </label>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light-blue-outline rounded-pill py-3 me-2 px-5 fs-5"
            onClick={() => setModalShow(false)}
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={loading.submitting}
            className="btn bg-dark1 text-white rounded-pill py-3 px-5 fs-5"
          >
            {loading.submitting ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
    </>
  );
}
