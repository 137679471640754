import React from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import EditPromoCode from "./EditPromoCode";
import FormatDOB from "../../../components/UI/FormatDOB";

export default function ListPromoCode({ dataList, handleDelete, collegeData, getData, pageTitle }) {
  return (
    <>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Value</th>
            <th>College Name</th>
            <th>Active Date</th>
            <th>Expiry Date</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {dataList.map((data, idx) => (
            <tr key={idx}>
              <td>{data.name}</td>
              <td>{data.value}</td>
              <td>{collegeData.find((college) => college.id === data.collegeId)?.collegeName || "N/A"}</td>
              <td>
                <FormatDOB date={data.activeDate} />
              </td>
              <td>
                <FormatDOB date={data.expiryDate} />
              </td>
              <td>{data.statusId === 0 ? "Inactive" : "Active"}</td>
              <td className="d-flex">
                <EditPromoCode data={data} collegeData={collegeData} pageTitle={pageTitle} getData={getData} />
                <Button variant="outline-danger" className="w-100 rounded-pill ms-1" onClick={(e) => handleDelete(e, data)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
