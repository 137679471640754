import { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { toast } from "react-hot-toast";
import ProgramsDataService from "../../Services/ProgramSearchService";
import ProgramSearchService from "../../Services/ProgramSearchService";

export default function EditPrograms({
  pageTitle,
  collegeData,
  getData,
  data,
}) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [ProgramsData, setProgramsData] = useState({
    ...data,
    id: data.collegeProgramId,
  });

  const [campuses, setCampuses] = useState(
    data?.campus.map((campus) => campus.name)
  );
  const [showDropdown, setShowDropdown] = useState(false);
  const [collegeCampuses, setCollegeCampuses] = useState([]);

  const [validated, setValidated] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProgramsData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    setValidated(true);

    if (!form.checkValidity()) {
      event.stopPropagation();
      return;
    }

    try {
      // Display loading message
      const loadingMessage = toast.loading("Updating...");
      const response = await ProgramsDataService.update({
        ...ProgramsData,
        campusList: campuses,
      });

      if (response.data.success === true) {
        getData();
        toast.success(response.data.message, { id: loadingMessage });
        setShow(false);
      } else if (response.data.validationErrors.length > 0) {
        const errorMessage = response.data.validationErrors
          .map((verr) => verr)
          .join(", ");
        toast.error(errorMessage, { id: loadingMessage });
      } else {
        toast.error("Something Went Wrong", { id: loadingMessage });
      }
    } catch (error) {
      toast.error("An error occurred");
    }
  };

  const handleMultipleSelectCampus = useCallback(
    (campusName) => {
      const findCampusId = campuses.find((camp) => camp === campusName);
      if (!findCampusId) {
        setCampuses((p) => [...p, campusName]);
      } else {
        const newCampuses = campuses.filter((camp) => camp !== campusName);
        setCampuses(newCampuses);
      }
    },
    [campuses]
  );

  const getCollegeCampuses = useCallback(async () => {
    const response = await ProgramSearchService.getCollegeCampus(
      data.collegeId
    );
    setCollegeCampuses(response.data);
  }, [data.collegeId]);

  useEffect(() => {
    getCollegeCampuses();
  }, [getCollegeCampuses]);

  return (
    <>
      <Button
        variant="outline-warning"
        onClick={handleShow}
        className="w-100 rounded-pill"
      >
        Edit
      </Button>

      <Modal show={show} onHide={handleClose} className="mt-5" size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit {pageTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            className="px-3"
          >
            <Row xs={2} md={3} className="g-3">
              <FloatingLabel controlId="floatingInput" label="name">
                <Form.Control
                  type="text"
                  placeholder="name"
                  required
                  name="name"
                  value={ProgramsData.name}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide name
                </Form.Control.Feedback>
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="Meta Title">
                <Form.Control
                  type="text"
                  placeholder="Meta Title"
                  required
                  name="metaTitle"
                  value={ProgramsData.metaTitle}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide Meta Title
                </Form.Control.Feedback>
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="Program Code">
                <Form.Control
                  type="text"
                  placeholder="Program Code"
                  required
                  name="programCode"
                  value={ProgramsData.programCode}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide Program Code
                </Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel controlId="floatingInput" label="campus">
                <Form.Control
                  type="text"
                  placeholder="Campus"
                  value={campuses?.join(", ")}
                  onClick={() => setShowDropdown((p) => !p)}
                  style={{ cursor: "pointer" }}
                />
                {showDropdown ? (
                  <ul
                    class="list-group bg-light ps-0 z-3 position-absolute shadow bg-white rounded border-dark"
                    style={{ width: "96%" }}
                  >
                    {collegeCampuses?.map((campus) => (
                      <li
                        class={`list-group-item campus-item text1-3 ${
                          campuses.includes(campus.campus) ? "bg-light" : ""
                        }`}
                        border
                        onClick={() =>
                          handleMultipleSelectCampus(campus.campus)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {campuses.includes(campus.campus) ? (
                          <i class="bi bi-check-circle-fill me-2"></i>
                        ) : (
                          <i class="bi bi-circle me-2"></i>
                        )}
                        {campus.campus}
                      </li>
                    ))}
                  </ul>
                ) : null}

                <Form.Control.Feedback type="invalid">
                  Please provide campus
                </Form.Control.Feedback>
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="duration">
                <Form.Control
                  type="text"
                  placeholder="duration"
                  required
                  name="duration"
                  value={ProgramsData.duration}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide duration
                </Form.Control.Feedback>
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="credential">
                <Form.Control
                  type="text"
                  placeholder="credential"
                  required
                  name="credential"
                  value={ProgramsData.credential}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide credential
                </Form.Control.Feedback>
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="tuitionFee">
                <Form.Control
                  type="number"
                  placeholder="tuitionFee"
                  required
                  name="tuitionFee"
                  value={ProgramsData.tuitionFee}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide tuitionFee
                </Form.Control.Feedback>
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingInput"
                label="academicRequirement"
              >
                <Form.Control
                  type="text"
                  placeholder="academicRequirement"
                  required
                  name="academicRequirement"
                  value={ProgramsData.academicRequirement}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide academicRequirement
                </Form.Control.Feedback>
              </FloatingLabel>
              {/* <FloatingLabel controlId="floatingInput" label="applicationProcessingTime">
                <Form.Control
                  type="text"
                  placeholder="applicationProcessingTime"
                  required
                  name="applicationProcessingTime"
                  value={ProgramsData.applicationProcessingTime}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">Please provide applicationProcessingTime</Form.Control.Feedback>
              </FloatingLabel> */}
              <FloatingLabel controlId="floatingInput" label="pgWorkPermit">
                <Form.Select
                  aria-label="select pgWorkPermit"
                  value={ProgramsData.pgWorkPermit}
                  name="pgWorkPermit"
                  onChange={handleInputChange}
                >
                  <option value={"Yes"}>Yes</option>
                  <option value={"No"}>No</option>
                  <option value={"N/A"}>N/A</option>
                </Form.Select>

                <Form.Control.Feedback type="invalid">
                  Please provide pgWorkPermit
                </Form.Control.Feedback>
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="ieltsRequirement">
                <Form.Control
                  type="text"
                  placeholder="ieltsRequirement"
                  name="ieltsRequirement"
                  value={ProgramsData.ieltsRequirement}
                  onChange={handleInputChange}
                />
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="pteRequirement">
                <Form.Control
                  type="text"
                  placeholder="pteRequirement"
                  name="pteRequirement"
                  value={ProgramsData.pteRequirement}
                  onChange={handleInputChange}
                />
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="toeflRequirement">
                <Form.Control
                  type="text"
                  placeholder="toeflRequirement"
                  name="toeflRequirement"
                  value={ProgramsData.toeflRequirement}
                  onChange={handleInputChange}
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingInput"
                label="duolingoRequirement"
              >
                <Form.Control
                  type="text"
                  placeholder="duolingoRequirement"
                  name="duolingoRequirement"
                  value={ProgramsData.duolingoRequirement}
                  onChange={handleInputChange}
                />
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="gmat">
                <Form.Control
                  type="text"
                  placeholder="gmat"
                  name="gmat"
                  value={ProgramsData.gmat}
                  onChange={handleInputChange}
                />
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="gre">
                <Form.Control
                  type="text"
                  placeholder="gre"
                  name="gre"
                  value={ProgramsData.gre}
                  onChange={handleInputChange}
                />
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="sat">
                <Form.Control
                  type="text"
                  placeholder="sat"
                  name="sat"
                  value={ProgramsData.sat}
                  onChange={handleInputChange}
                />
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="areasofInterest">
                <Form.Control
                  type="text"
                  placeholder="areasofInterest"
                  required
                  name="areasofInterest"
                  value={ProgramsData.areasofInterest}
                  onChange={handleInputChange}
                />
              </FloatingLabel>

              <FloatingLabel
                controlId="floatingInput"
                label="Specific Requirement"
              >
                <Form.Control
                  as="textarea"
                  className="text1-3"
                  placeholder="Specific Requirement"
                  name="specificRequirement1"
                  value={ProgramsData.specificRequirement1}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide Program Specific Requirement
                </Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel
                controlId="floatingInput"
                label="Other Specific Requirement"
              >
                <Form.Control
                  as="textarea"
                  className="text1-3"
                  placeholder="Other Specific Requirement"
                  name="specificRequirement2"
                  value={ProgramsData.specificRequirement2}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide Program Other Specific Requirement
                </Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel controlId="floatingInput" label="courseUrl">
                <Form.Control
                  as="textarea"
                  className="text1-3"
                  placeholder="courseUrl"
                  name="courseUrl"
                  value={ProgramsData.courseUrl}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide courseUrl
                </Form.Control.Feedback>
              </FloatingLabel>
              <Col md={6}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Meta Description"
                  className="w-100"
                >
                  <Form.Control
                    as="textarea"
                    aria-label="Meta Description"
                    required
                    name="metaDescription"
                    value={ProgramsData.metaDescription}
                    onChange={handleInputChange}
                    style={{ height: "200px", width: "100px" }}
                    className="fs-5 w-100"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide Meta description
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={6}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Program Details"
                  className="w-100"
                >
                  <Form.Control
                    as="textarea"
                    aria-label="Program Details"
                    required
                    name="programDetails"
                    value={ProgramsData.programDetails}
                    onChange={handleInputChange}
                    style={{ height: "200px", width: "100%" }}
                    className="fs-5 w-100"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide Program Details
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>

              <FloatingLabel controlId="floatingSelect" label="Select Status">
                <Form.Select
                  aria-label="select Status"
                  value={ProgramsData.statusId}
                  name="statusId"
                  onChange={handleInputChange}
                >
                  <option value={0}>Inactive</option>
                  <option value={1}>Active</option>
                </Form.Select>
              </FloatingLabel>
              <Col md={12}>
                <Modal.Footer className="mt-4">
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button type="submit" variant="success">
                    Update
                  </Button>
                </Modal.Footer>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
