import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PromoCodeDataService from "../../Services/PromoCode";

export default function EditPromoCode({ pageTitle, collegeData, getData, data }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [promoCodeData, setPromoCodeData] = useState(data);
  const [validated, setValidated] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPromoCodeData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    setValidated(true);

    if (!form.checkValidity()) {
      event.stopPropagation();
      return;
    }

    try {
      // Display loading message
      const loadingMessage = toast.loading("Updating...");

      const response = await PromoCodeDataService.update(promoCodeData);

      if (response.data.success === true) {
        getData();
        toast.success(response.data.message, { id: loadingMessage });
        setShow(false);
      } else if (response.data.validationErrors.length > 0) {
        const errorMessage = response.data.validationErrors.map((verr) => verr).join(", ");
        toast.error(errorMessage, { id: loadingMessage });
      } else {
        toast.error("Something Went Wrong", { id: loadingMessage });
      }
    } catch (error) {
      toast.error("An error occurred");
    }
  };


  return (
    <>
      <Button variant="outline-warning" onClick={handleShow} className="w-100 rounded-pill">
        Edit
      </Button>

      <Modal show={show} onHide={handleClose} className="mt-5" size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit {pageTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit} className="px-3">
            <Row xs={1} md={2} className="g-3">
              <Col md={12}>
                <FloatingLabel controlId="floatingSelect" label="Select College">
                  <Form.Select
                    aria-label="select college"
                    defaultValue={promoCodeData.collegeId}
                    name="collegeId"
                    onChange={handleInputChange}
                  >
                    <option>Select College</option>
                    {collegeData.map((cd) => (
                      <option value={cd.id} key={cd.id}>
                        {cd.collegeName}
                      </option>
                    ))}
                  </Form.Select>
                </FloatingLabel>
              </Col>

              <FloatingLabel controlId="floatingInput" label="name">
                <Form.Control type="text" placeholder="name" required name="name" value={promoCodeData.name} onChange={handleInputChange} />
                <Form.Text className="text-muted">This promo name/code should be unqiue everytime</Form.Text>
                <Form.Control.Feedback type="invalid">Please provide name</Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel controlId="floatingInput" label="value">
                <Form.Control
                  type="number"
                  placeholder="Value"
                  required
                  name="value"
                  value={promoCodeData.value}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">Please provide value</Form.Control.Feedback>
                <Form.Text className="text-muted">Value will be calculated as percentage</Form.Text>
              </FloatingLabel>

              <FloatingLabel controlId="floatingInput" label="activeDate">
                <Form.Control
                  type="datetime-local"
                  placeholder="activeDate"
                  required
                  name="activeDate"
                  value={promoCodeData.activeDate}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">Please provide activeDate</Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel controlId="floatingInput" label="expiryDate">
                <Form.Control
                  type="datetime-local"
                  placeholder="expiryDate"
                  required
                  name="expiryDate"
                  value={promoCodeData.expiryDate}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">Please provide expiryDate</Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel controlId="floatingSelect" label="Select Status">
                <Form.Select aria-label="select Status" value={promoCodeData.statusId} name="statusId" onChange={handleInputChange}>
                  <option value={0}>Inactive</option>
                  <option value={1}>Active</option>
                </Form.Select>
              </FloatingLabel>

              <Col md={12}>
                <Modal.Footer className="mt-4">
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button type="submit" variant="success">
                    Update
                  </Button>
                </Modal.Footer>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
