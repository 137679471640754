import { ListGroup } from "react-bootstrap";
import FormatDate from "../../components/UI/FormatDate";
import { useComponentVisible } from "../../shared/hooks/useComponentVisible";
import "./Refund.css";

export function ApplicationsDropdown({
  handleInputChange,
  value,
  list,
  handleItemClick,
  inputClassnames,
  disabled,
}) {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible();

  return (
    <>
      <input
        value={value}
        ref={disabled ? null : ref}
        onClick={() => setIsComponentVisible(!isComponentVisible)}
        placeholder="Select Application ID"
        className={inputClassnames}
        onChange={handleInputChange}
      />

      {isComponentVisible & !disabled ? (
        <ListGroup className="dropdown-options-container w-100 bg-white ">
          <ListGroup.Item
            className="text1-4 list-item"
            onClick={() => handleItemClick()}
          >
            Search all applications
          </ListGroup.Item>
          {list.length ? (
            list.map((item) => (
              <ListGroup.Item
                className="text1-4 list-item"
                key={item.id}
                onClick={(e) => {
                  handleItemClick(item);
                }}
              >
                <FormatDate date={item.createdDate} idNo={item.idNumber} />
              </ListGroup.Item>
            ))
          ) : (
            <ListGroup.Item>No application ids found</ListGroup.Item>
          )}
        </ListGroup>
      ) : null}
    </>
  );
}
