import React, { useEffect, useMemo, useState } from "react";
import PhoneNumberInput from "../../components/PhoneNumberInput";
import WelcomeBanner from "./WelcomeBanner";
import CountryList from "../../components/UI/CountriesList";
import DatePicker from "react-datepicker";
import countriesData from "../../Services/CountriesList.json";
import { toast } from "react-toastify";
import GoogleAddressAutoComplete from "../../components/GoogleAddressAutoComplete";
import { OverlayTrigger, Popover } from "react-bootstrap";

const postalPopover = (
  <Popover id="popover-basic">
    <Popover.Body>
      <div className="text1-3 pb-0">Write N/A if no postal code</div>
    </Popover.Body>
  </Popover>
);

export default function AddPersonalInformation({
  handleInputChange,
  studentData,
  setStudentData,
  saveStudentData,
  isSaving,
  setActiveTabNext,
  allDraftApplication,
  newStudent,
  phoneError,
  setPhoneError,
}) {
  const [sameAsMailing, setSameAsMailing] = useState(false);

  const handleTabChange = (e) => {
    e.preventDefault();
    setActiveTabNext("education");
  };

  useEffect(() => {
    // Update DOB when studentData changes
    setDOB(studentData.dateofBirth || null);
    setPassportExpiryDate(
      studentData.passportExpiryDate === "0001-01-01T00:00:00"
        ? null
        : studentData.passportExpiryDate
    );

    // Update studentData.contactNumber when studentData changes
    handlePhoneNumberChange(studentData.contactNumber || "");
  }, [studentData]);

  function convertUTCToLocalDate(date) {
    if (!date) {
      return date;
    }

    const localOffset = new Date().getTimezoneOffset(); // Get local timezone offset in milliseconds
    const utcDate = new Date(date);
    const localDate = new Date(utcDate.getTime() - localOffset);

    return localDate;
  }

  const [DOB, setDOB] = useState(studentData.dateofBirth || null);
  const handleDOB = (value) => {
    const dateString = value;
    const date = new Date(dateString);
    const utcDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000
    );
    const formattedDate = utcDate.toISOString();
    setDOB(formattedDate);
    studentData.dateofBirth = formattedDate;
  };
  const [passportExpiryDate, setPassportExpiryDate] = useState(
    studentData.passportExpiryDate === "0001-01-01T00:00:00"
      ? null
      : studentData.passportExpiryDate
  );

  const handlePassportExpiryDate = (value) => {
    const dateString = value;
    const date = new Date(dateString);
    const utcDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000
    );
    const formattedDate = utcDate.toISOString();
    setPassportExpiryDate(formattedDate);
    studentData.passportExpiryDate = formattedDate;
  };

  const handleSameAsMailingClick = (e) => {
    const checked = e.target.checked;

    // Check if all relevant fields are not empty
    const isFieldsNotEmpty =
      studentData.address &&
      studentData.country &&
      studentData.city &&
      studentData.state &&
      studentData.postalCode;

    if (checked && isFieldsNotEmpty) {
      // All fields are not empty, so it's safe to set sameAsMailing to true
      setSameAsMailing(checked);

      // Copy values to permanent fields
      studentData.permanentAddress = studentData.address;
      studentData.permanentCountry = studentData.country;
      studentData.permanentDistrict = studentData.district;
      studentData.permanentCity = studentData.city;
      studentData.permanentState = studentData.state;
      studentData.permanentPostalCode = studentData.postalCode;
    } else if (checked && !isFieldsNotEmpty) {
      // Fields are empty, show an alert or take appropriate action
      setSameAsMailing(false);
      toast.warn(
        "Please fill in all relevant fields before checking Same as Mailing."
      );
    } else {
      // If unchecked, clear permanent fields
      setSameAsMailing(checked);
      studentData.permanentAddress = "";
      studentData.permanentCountry = "";
      studentData.permanentDistrict = "";
      studentData.permanentCity = "";
      studentData.permanentState = "";
      studentData.permanentPostalCode = "";
    }
  };

  const handlePhoneNumberChange = (value) => {
    // setStudentData((prevData) => ({
    //   ...prevData,
    //   contactNumber: value,
    // }));

    studentData.contactNumber = value;
  };

  const handleCountryChange = (e) => {
    const { value } = e.target;
    if (value) {
      setStudentData((prevData) => ({
        ...prevData,
        country: value,
      }));
    }
  };
  const handlePermanentCountryChange = (value) => {
    if (value) {
      studentData.permanentCountry = value;
    }
  };

  const [selectedPlace, setSelectedPlace] = useState({
    address: studentData.address,
    country: studentData.country,
    district: studentData.district,
    city: studentData.city,
    state: studentData.state,
    postalCode: studentData.postalCode,
  });

  useEffect(() => {
    // Extract required information from the response
    const { address_components, formatted_address } = selectedPlace;

    // Extract specific components from address_components
    const street_numberObj = address_components?.find((component) =>
      component.types.includes("street_number")
    );
    const routeObj = address_components?.find((component) =>
      component.types.includes("route")
    );
    const neighborhoodObj = address_components?.find((component) =>
      component.types.includes("neighborhood")
    );
    const postalCodeObj = address_components?.find((component) =>
      component.types.includes("postal_code")
    );
    const localityObj = address_components?.find((component) =>
      component.types.includes("locality")
    );
    const sublocalityObj = address_components?.find((component) =>
      component.types.includes("sublocality")
    );
    const adminArea3Obj = address_components?.find((component) =>
      component.types.includes("administrative_area_level_3")
    );
    const adminArea1Obj = address_components?.find((component) =>
      component.types.includes("administrative_area_level_1")
    );
    const countryObj = address_components?.find((component) =>
      component.types.includes("country")
    );

    const addressParts = [
      street_numberObj?.long_name,
      routeObj?.long_name,
      neighborhoodObj?.long_name,
      sublocalityObj?.long_name,
    ];

    setStudentData((prevData) => ({
      ...prevData,
      // Update studentData fields based on the extracted information
      address:
        addressParts.filter((part) => part).join(", ") || studentData.address,
      country: countryObj ? countryObj.long_name : studentData.country,
      district: `${
        adminArea3Obj?.long_name !== localityObj?.long_name
          ? adminArea3Obj?.long_name
          : studentData.district !== null && studentData.district !== undefined
          ? studentData.district
          : ""
      }`,

      city: localityObj ? localityObj.long_name : studentData.city,
      state: adminArea1Obj ? adminArea1Obj.long_name : studentData.state,
      postalCode: postalCodeObj
        ? postalCodeObj.long_name
        : studentData.postalCode,
    }));
  }, [selectedPlace]);

  const disabled = useMemo(() => {
    if (allDraftApplication || allDraftApplication === undefined) {
      return false;
    } else {
      if (!studentData.isEditable) {
        return true;
      }
      return false;
    }
  }, [allDraftApplication, studentData.isEditable]);

  return (
    <>
      <WelcomeBanner data={studentData} show={disabled} />

      <form className="needs-validation" onSubmit={saveStudentData}>
        {/* Basic Details Starts */}
        <div className="border rounded-4 bg-light-blue1 text1-5 p-3 mb-4">
          <div className="d-flex align-items-center bg-stripped1 border border-top-0 rounded-4 p-3 font-bold  mb-3">
            Basic Details (Mandatory)
            <span className="text-danger">*</span>
          </div>
          <div className="row align-items-center">
            <div className="col-12">
              <div className="row g-3">
                <div className="col-4 form-floating px-3">
                  <select
                    className="form-select rounded-pill border shadow"
                    aria-label="Select Salutation"
                    required
                    id="salutation"
                    value={studentData.salutation}
                    onChange={handleInputChange}
                    name="salutation"
                    autoComplete="off"
                    disabled={disabled}
                  >
                    <option value="">Select Salutation</option>
                    <option value="Mr">Mr</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Miss">Miss</option>
                  </select>
                  <label htmlFor="floatingInput" className="ms-4">
                    Salutation<span className="text-danger">*</span>
                  </label>
                </div>

                <div className="col-4 form-floating px-3">
                  <input
                    type="text"
                    className="form-control rounded-pill border shadow"
                    placeholder="First name*"
                    required
                    id="firstName"
                    value={studentData.firstName}
                    onChange={handleInputChange}
                    name="firstName"
                    pattern="^[a-zA-Z ]*$"
                    title={"Only Alphabets allowed"}
                    autoComplete="off"
                    disabled={allDraftApplication === false}
                  />
                  <label htmlFor="floatingInput" className="ms-4 ">
                    First Name<span className="text-danger">*</span>
                  </label>
                </div>

                <div className="col-4 form-floating px-3">
                  <input
                    type="text"
                    className="form-control rounded-pill border shadow"
                    placeholder="Middle name"
                    id="middleName"
                    value={studentData.middleName ? studentData.middleName : ""}
                    onChange={handleInputChange}
                    name="middleName"
                    pattern="^[a-zA-Z ]*$"
                    title={"Only Alphabets allowed"}
                    autoComplete="off"
                    disabled={allDraftApplication === false}
                  />
                  <label htmlFor="floatingInput" className="ms-4">
                    Middle Name
                  </label>
                </div>

                <div className="col-4 form-floating px-3">
                  <input
                    type="text"
                    className="form-control rounded-pill border shadow"
                    placeholder="Last name"
                    required
                    id="lastName"
                    value={studentData.lastName}
                    onChange={handleInputChange}
                    name="lastName"
                    pattern="^[a-zA-Z. ]*$"
                    title={"Only Alphabets and full stop allowed"}
                    autoComplete="off"
                    disabled={allDraftApplication === false}
                  />
                  <label htmlFor="floatingInput" className="ms-4">
                    Last Name<span className="text-danger">*</span>
                  </label>
                </div>
                <div className="col-4 form-floating px-3 customDatePickerWidth z-3">
                  {/* <input
                    type="date"
                    className="form-control rounded-pill border shadow"
                    placeholder="DD-MM-YYYY"
                    required
                    id="dateofBirth"
                    //value={formatDate(studentData.dateofBirth)}
                    value={studentData.length === 0 ? studentData.dateofBirth : formatDate(studentData.dateofBirth)}
                    onChange={handleInputChange}
                    max="2020-12-31"
                    min="1950-01-01"
                    name="dateofBirth"
                  /> */}
                  <DatePicker
                    className="form-control rounded-pill border shadow text1-4 px-4 py-2"
                    selected={convertUTCToLocalDate(DOB)}
                    onChange={(date) => handleDOB(date)}
                    dateFormat="dd-MM-yyyy"
                    maxDate={new Date()}
                    minDate={new Date(1950, 0, 1)}
                    name="dateofBirth"
                    placeholderText="Date of Birth"
                    required
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    autoComplete="off"
                    disabled={disabled}
                  />

                  {/* <label htmlFor="floatingInput" className="ms-4">
                    Date of Birth<span className="text-danger">*</span>
                  </label> */}
                </div>
                <div className="col-4 form-floating px-3">
                  <input
                    type="text"
                    className="form-control rounded-pill border shadow"
                    placeholder="Nationality"
                    required
                    id="nationality"
                    value={studentData.nationality}
                    onChange={handleInputChange}
                    name="nationality"
                    pattern="^[a-zA-Z.]*$"
                    title={"Only Alphabets and full stop allowed"}
                    autoComplete="off"
                    disabled={disabled}
                  />
                  <label htmlFor="floatingInput" className="ms-4">
                    Nationality<span className="text-danger">*</span>
                  </label>
                </div>

                <div className="col-4 form-floating px-3">
                  <select
                    className="form-select rounded-pill border shadow"
                    aria-label="Current status (If Applicable)"
                    required
                    id="currentStatus"
                    value={studentData.currentStatus}
                    onChange={handleInputChange}
                    name="currentStatus"
                    disabled={disabled}
                  >
                    <option value="">Current status (If Applicable)</option>
                    <option value="No Current Status in Canada">
                      No Current Status in Canada
                    </option>
                    <option value="PR">PR</option>
                    <option value="Citizenship">Cititizenship</option>
                    <option value="Work Permit">Work Permit</option>
                  </select>
                  <label htmlFor="floatingInput" className="ms-4">
                    Current status (If Applicable)
                    <span className="text-danger">*</span>
                  </label>
                </div>

                <div className="col-4 form-floating px-3">
                  <select
                    className="form-select rounded-pill border shadow"
                    aria-label="Select Gender"
                    required
                    id="gender"
                    value={studentData.gender}
                    onChange={handleInputChange}
                    name="gender"
                    disabled={disabled}
                  >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                  <label htmlFor="floatingInput" className="ms-4">
                    Gender<span className="text-danger">*</span>
                  </label>
                </div>
                <div className="col-4 form-floating px-3">
                  <select
                    className="form-select rounded-pill border shadow"
                    aria-label="Select Marital status"
                    required
                    id="maritalStatus"
                    value={studentData.maritalStatus}
                    onChange={handleInputChange}
                    name="maritalStatus"
                    disabled={disabled}
                  >
                    <option value="">Select Marital status</option>
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                    <option value="Divorced">Divorced</option>
                  </select>
                  <label htmlFor="floatingInput" className="ms-4">
                    Marital status<span className="text-danger">*</span>
                  </label>
                </div>
                {allDraftApplication === true ||
                allDraftApplication === undefined ? (
                  <div className="col-4 px-3">
                    <PhoneNumberInput
                      customizedCSS={"shadow"}
                      value={studentData.contactNumber}
                      onChange={handlePhoneNumberChange}
                      error={phoneError}
                      setError={setPhoneError}
                    />
                  </div>
                ) : (
                  <div className="col-4 form-floating px-3">
                    <input
                      type="text"
                      className="form-control rounded-pill border shadow"
                      placeholder="contactNumber"
                      required
                      id="contactNumber"
                      value={studentData.contactNumber}
                      name="contactNumber"
                      autoComplete="off"
                      disabled={allDraftApplication === false}
                    />
                    <label htmlFor="floatingInput" className="ms-4">
                      Contact Number<span className="text-danger">*</span>
                    </label>
                  </div>
                )}

                <div className="col-4 form-floating px-3">
                  <input
                    type="email"
                    pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                    className="form-control rounded-pill border shadow"
                    placeholder="Email"
                    required
                    id="email"
                    title="Please enter valid email address"
                    value={studentData.email}
                    onChange={handleInputChange}
                    name="email"
                    autoComplete="off"
                    disabled={allDraftApplication === false}
                  />
                  <label htmlFor="floatingInput" className="ms-4">
                    Email<span className="text-danger">*</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Basic Details End */}

        {/* Mailing Address Starts */}
        <div className="border rounded-4 bg-light-blue1 text1-5 p-3 mb-4">
          <div className="d-flex align-items-center bg-stripped1 border border-top-0 rounded-4 p-3  mb-3">
            <span className="font-bold">Mailing Address</span>
            <span className="text-danger">*</span>
            <div className={`col-4 form-floating px-3 ${disabled && "d-none"}`}>
              <GoogleAddressAutoComplete setSelectedPlace={setSelectedPlace} />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-12">
              <div className="row g-3">
                <div className="col-4 form-floating px-3">
                  <textarea
                    className="form-control rounded-4 border shadow h-100 text1-4"
                    placeholder="Mailing Address"
                    required
                    id="address"
                    value={studentData.address}
                    onChange={handleInputChange}
                    name="address"
                    pattern="^[a-zA-Z0-9 ,-./#&]*$"
                    title={"Only Alphabets, digits and , - . / # & allowed"}
                    disabled={disabled}
                  />
                  <label htmlFor="floatingTextarea" className="ms-4">
                    Apartment, unit, suite, or floor
                    <span className="text-danger">*</span>
                  </label>
                </div>

                <div className="col-8 mb-3">
                  <div className="row g-3">
                    {/* 
                    <div className="col-4 form-floating px-3">
                      <input
                        className="form-control rounded-pill border shadow"
                        placeholder="Apartment, unit, suite, or floor"
                        required
                        id="address"
                        value={studentData.address}
                        onChange={handleInputChange}
                        name="address"
                        pattern="^[a-zA-Z0-9 ,-./#&]*$"
                        title={"Only Alphabets, digits and , - . / # & allowed"}
                        disabled={allDraftApplication === false || !studentData.isEditable}
                      />
                      <label htmlFor="floatingTextarea" className="ms-4">
                        Apartment, unit, suite, or floor<span className="text-danger">*</span>
                      </label>
                    </div> */}

                    <div className="col-4 form-floating px-3">
                      <select
                        className={`form-select rounded-pill border shadow`}
                        aria-label="Country"
                        value={studentData.country}
                        onChange={handleCountryChange}
                        // onChange={(value) => {
                        //   setStudentData((prevData) => ({
                        //     ...prevData,
                        //     country: value,
                        //   }));
                        // }}
                        name="country"
                        disabled={disabled}
                        required
                      >
                        <option value="">Country</option>
                        {countriesData.map((country) => (
                          <option key={country.code} value={country.name}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                      <label htmlFor="floatingInput" className="ms-4">
                        Country<span className="text-danger">*</span>
                      </label>
                      {/* <CountryList
                        customCSS={true}
                        value={studentData.country}
                        onChange={(value) => {
                          setStudentData((prevData) => ({
                            ...prevData,
                            country: value,
                          }));
                        }}
                        disabled={allDraftApplication === false || !studentData.isEditable}
                      /> */}
                      {/*
                      <label htmlFor="floatingInput" className="ms-4">
                        Country<span className="text-danger">*</span>
                      </label> */}
                    </div>

                    <div className="col-4 form-floating px-3">
                      <input
                        type="text"
                        className="form-control rounded-pill border shadow"
                        placeholder="District"
                        id="district"
                        value={studentData.district}
                        onChange={handleInputChange}
                        name="district"
                        pattern="^[a-zA-Z ]*$"
                        title={"Only Alphabets and white space allowed"}
                        autoComplete="off"
                        disabled={disabled}
                      />
                      <label htmlFor="floatingInput" className="ms-4">
                        District
                      </label>
                    </div>
                    <div className="col-4 form-floating px-3">
                      <input
                        type="text"
                        className="form-control rounded-pill border shadow"
                        placeholder="City"
                        required
                        id="city"
                        value={studentData.city}
                        onChange={handleInputChange}
                        name="city"
                        pattern="^[a-zA-Z ]*$"
                        autoComplete="off"
                        disabled={disabled}
                      />
                      <label htmlFor="floatingInput" className="ms-4">
                        City<span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="col-4 form-floating px-3">
                      <input
                        type="text"
                        className="form-control rounded-pill border shadow"
                        placeholder="State"
                        required
                        id="state"
                        value={studentData.state}
                        onChange={handleInputChange}
                        name="state"
                        pattern="^[a-zA-Z ]*$"
                        autoComplete="off"
                        disabled={disabled}
                      />
                      <label htmlFor="floatingInput" className="ms-4">
                        State<span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="col-4 form-floating px-3">
                      <OverlayTrigger
                        placement="bottom"
                        overlay={postalPopover}
                      >
                        <input
                          type="text"
                          className="form-control rounded-pill border shadow"
                          placeholder="Postal Code"
                          required
                          id="postalCode"
                          value={studentData.postalCode}
                          onChange={handleInputChange}
                          name="postalCode"
                          autoComplete="off"
                          disabled={disabled}
                        />
                      </OverlayTrigger>
                      <label htmlFor="floatingInput" className="ms-4">
                        Postal Code<span className="text-danger">*</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Mailing Address End */}

        {/* Permanent Address Starts */}
        <div className="border rounded-4 bg-light-blue1 text1-5 p-3 mb-4">
          <div className="d-flex align-items-center bg-stripped1 border border-top-0 rounded-4 p-3 font-bold mb-3">
            Permanent Address
            <span className="text-danger">*</span>
            <div className="form-check blue-checkbox ms-5">
              <input
                type="checkbox"
                className="form-check-input"
                id="validationFormCheck1"
                onClick={handleSameAsMailingClick}
                disabled={disabled}
                defaultChecked={sameAsMailing}
              />
              <label
                className="form-check-label"
                htmlFor="validationFormCheck1"
              >
                Same as Mailing address
              </label>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-12">
              <div className="row g-3">
                <div className="col-4 form-floating px-3">
                  <textarea
                    className="form-control rounded-4 border shadow h-100 text1-4"
                    id="floatingTextarea"
                    placeholder="Mailing Address"
                    required
                    value={
                      sameAsMailing
                        ? studentData.address
                        : studentData.permanentAddress
                    }
                    onChange={handleInputChange}
                    name="permanentAddress"
                    disabled={sameAsMailing || disabled}
                  ></textarea>
                  <label htmlFor="floatingTextarea" className="ms-4">
                    Apartment, unit, suite, or floor
                    <span className="text-danger">*</span>
                  </label>
                </div>

                <div className="col-8 mb-3">
                  <div className="row g-3">
                    <div className="col-4 form-floating px-3">
                      {sameAsMailing ? (
                        <>
                          <select
                            required
                            className={`form-select rounded-pill border shadow`}
                            aria-label="permanentCountry"
                            value={studentData.country}
                            onChange={handleInputChange}
                            name="permanentCountry"
                            disabled={sameAsMailing || disabled}
                          >
                            <option value={studentData.country}>
                              {studentData.country}
                            </option>
                          </select>
                          <label htmlFor="floatingInput" className="ms-4">
                            Country<span className="text-danger">*</span>
                          </label>
                        </>
                      ) : (
                        <CountryList
                          customCSS={true}
                          value={studentData.permanentCountry}
                          onChange={handlePermanentCountryChange}
                          disabled={disabled}
                        />
                      )}
                    </div>

                    <div className="col-4 form-floating px-3">
                      <input
                        type="text"
                        className="form-control rounded-pill border shadow"
                        placeholder="District"
                        id="permanentDistrict"
                        value={
                          sameAsMailing
                            ? studentData.district
                            : studentData.permanentDistrict
                        }
                        onChange={handleInputChange}
                        name="permanentDistrict"
                        disabled={sameAsMailing || disabled}
                        autoComplete="off"
                      />
                      <label htmlFor="floatingInput" className="ms-4">
                        District
                      </label>
                    </div>
                    <div className="col-4 form-floating px-3">
                      <input
                        type="text"
                        className="form-control rounded-pill border shadow"
                        placeholder="City"
                        required
                        id="permanentCity"
                        value={
                          sameAsMailing
                            ? studentData.city
                            : studentData.permanentCity
                        }
                        onChange={handleInputChange}
                        name="permanentCity"
                        disabled={sameAsMailing || disabled}
                        autoComplete="off"
                      />
                      <label htmlFor="floatingInput" className="ms-4">
                        City<span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="col-4 form-floating px-3">
                      <input
                        type="text"
                        className="form-control rounded-pill border shadow"
                        placeholder="State"
                        required
                        id="permanentState"
                        value={
                          sameAsMailing
                            ? studentData.state
                            : studentData.permanentState
                        }
                        onChange={handleInputChange}
                        name="permanentState"
                        disabled={sameAsMailing || disabled}
                        autoComplete="off"
                      />
                      <label htmlFor="floatingInput" className="ms-4">
                        State<span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="col-4 form-floating px-3">
                      <OverlayTrigger
                        placement="bottom"
                        overlay={postalPopover}
                      >
                        <input
                          type="text"
                          className="form-control rounded-pill border shadow"
                          placeholder="Postal Code"
                          required
                          id="permanentPostalCode"
                          value={
                            sameAsMailing
                              ? studentData.postalCode
                              : studentData.permanentPostalCode
                          }
                          onChange={handleInputChange}
                          name="permanentPostalCode"
                          disabled={sameAsMailing || disabled}
                          autoComplete="off"
                        />
                      </OverlayTrigger>
                      <label htmlFor="floatingInput" className="ms-4">
                        Postal Code<span className="text-danger">*</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Permanent Address End */}

        {/* Passport Details Starts */}
        <div className="border rounded-4 bg-light-blue1 text1-5 p-3 mb-4">
          <div className="d-flex align-items-center bg-stripped1 border border-top-0 rounded-4 p-3 font-bold  mb-3">
            Passport Details
            <span className="text-danger">*</span>
          </div>
          <div className="row align-items-center">
            <div className="col-12">
              <div className="row g-3">
                <div className="col-4 form-floating px-3">
                  <input
                    type="text"
                    className="form-control rounded-pill border shadow"
                    placeholder="Passport Number"
                    required
                    id="passportNumber"
                    value={studentData.passportNumber}
                    onChange={handleInputChange}
                    name="passportNumber"
                    pattern="^[A-Z]{1}[0-9]{7}$"
                    autoComplete="off"
                    disabled={allDraftApplication === false}
                  />
                  <label htmlFor="floatingInput" className="ms-4">
                    Passport Number<span className="text-danger">*</span>
                  </label>
                </div>
                <div className="col-4 form-floating px-3 customDatePickerWidth z-3">
                  <DatePicker
                    className="form-control rounded-pill border shadow text1-4 px-4 pt-2 pb-3"
                    selected={convertUTCToLocalDate(passportExpiryDate)}
                    onChange={(date) => handlePassportExpiryDate(date)}
                    dateFormat="dd-MM-yyyy"
                    name="passportExpiryDate"
                    placeholderText="Date of Passport Expiry"
                    required
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    autoComplete="off"
                    minDate={new Date(2023, 0, 1)}
                    disabled={disabled}
                  />

                  {/* <input
                    type="date"
                    className="form-control rounded-pill border shadow"
                    placeholder="DD-MM-YYYY"
                    required
                    id="passportExpiryDate"
                    //value={formatDate(studentData.passportExpiryDate)}
                    value={studentData.length === 0 ? studentData.passportExpiryDate : formatDate(studentData.passportExpiryDate)}
                    onChange={handleInputChange}
                    name="passportExpiryDate"
                    min={minDate}
                  />
                  <label htmlFor="floatingInput" className="ms-4">
                    Date of Passport Expire
                    <span className="text-danger">*</span>
                  </label> */}
                </div>

                <div className="col-4 form-floating px-3">
                  <select
                    className="form-select rounded-pill border shadow"
                    aria-label="Select Any Previous refusal?"
                    required
                    id="previousRefusal"
                    value={studentData.previousRefusal}
                    onChange={handleInputChange}
                    name="previousRefusal"
                    disabled={disabled}
                  >
                    <option value="">Select Any Previous refusal?</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                  <label htmlFor="floatingInput" className="ms-4">
                    Any Previous refusal?<span className="text-danger">*</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Passport Details End */}

        {/* Emergency Contact Starts */}
        <div className="border rounded-4 bg-light-blue1 text1-5 p-3 mb-4">
          <div className="d-flex align-items-center bg-stripped1 border border-top-0 rounded-4 p-3 font-bold  mb-3">
            Emergency Contact
            <span className="text-danger">*</span>
          </div>
          <div className="row align-items-center">
            <div className="col-12">
              <div className="row g-3">
                <div className="col-4 form-floating px-3">
                  <input
                    type="text"
                    className="form-control rounded-pill border shadow"
                    placeholder="Emergency Name"
                    required
                    id="emergencyContactName"
                    value={studentData.emergencyContactName}
                    onChange={handleInputChange}
                    name="emergencyContactName"
                    autoComplete="off"
                    disabled={disabled}
                  />
                  <label htmlFor="floatingInput" className="ms-4">
                    Emergency Name<span className="text-danger">*</span>
                  </label>
                </div>
                <div className="col-4 form-floating px-3">
                  <input
                    type="text"
                    className="form-control rounded-pill border shadow"
                    placeholder="Emergency Contact"
                    required
                    id="emergencyContactNumber"
                    value={studentData.emergencyContactNumber}
                    onChange={handleInputChange}
                    name="emergencyContactNumber"
                    autoComplete="off"
                    disabled={disabled}
                  />
                  <label htmlFor="floatingInput" className="ms-4">
                    Emergency Contact<span className="text-danger">*</span>
                  </label>
                </div>
                <div className="col-4 form-floating px-3">
                  <input
                    type="email"
                    pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                    className="form-control rounded-pill border shadow"
                    placeholder="Emergency Email"
                    required
                    id="emergencyEmail"
                    value={studentData.emergencyEmail}
                    onChange={handleInputChange}
                    name="emergencyEmail"
                    autoComplete="off"
                    disabled={disabled}
                  />
                  <label htmlFor="floatingInput" className="ms-4">
                    Emergency Email<span className="text-danger">*</span>
                  </label>
                </div>
                <div className="col-4 form-floating px-3">
                  <input
                    type="text"
                    className="form-control rounded-pill border shadow"
                    placeholder="Relation"
                    required
                    id="relation"
                    value={studentData.relation}
                    onChange={handleInputChange}
                    name="relation"
                    autoComplete="off"
                    disabled={disabled}
                  />
                  <label htmlFor="floatingInput" className="ms-4">
                    Relation<span className="text-danger">*</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Emergency Contact End */}
        <div className="d-flex justify-content-end my-2 mt-5">
          {allDraftApplication === true ||
          allDraftApplication === undefined ||
          studentData.isEditable ? (
            <button
              type="submit"
              className="btn btn-light-blue-outline rounded-pill py-3 me-2 col-2 text1-4"
              disabled={isSaving}
            >
              <span>{isSaving ? "Please Wait..." : "Save"}</span>
              {isSaving && <span className="spinner-border"></span>}
            </button>
          ) : (
            ""
          )}
          {newStudent ? (
            ""
          ) : (
            <button
              type="button"
              className="btn btn-light-blue-outline rounded-pill py-3 me-2 col-2 text1-4"
              onClick={handleTabChange}
            >
              Next
            </button>
          )}
        </div>
      </form>
    </>
  );
}
