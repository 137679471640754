import React, { useEffect } from "react";

const GoDaddySSLSeal = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.type = "text/javascript";
    script.src = "https://seal.godaddy.com/getSeal?sealID=9utgx4TbpxdaqxHfALzxplO0jablWfb2id3w2Aq54ZMaJ53Cl2cawZKnMZ7v";
    document.getElementById("siteseal").appendChild(script);
  }, []);

  return (
    <div className="position-relative d-print-none">
      <div className="position-absolute bottom-0 end-0">
        <div className="d-flex justify-content-end align-items-center me-3 mb-3">
          <span id="siteseal"></span>
        </div>
      </div>
    </div>
  );
};

export default GoDaddySSLSeal;
