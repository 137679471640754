import http from "./api";

const create = (data) => {
  return http.post("Feedback/sendFeedback", data);
};

const FeedbackService = {
  create,
};

export default FeedbackService;
