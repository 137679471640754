import React, { useCallback, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import platformLogo from "../../assets/logo_dark.png";
import GicServices from "../../Services/GicServices";
import { toast } from "react-toastify";

const InvoiceModal = ({
  showModal,
  handleCloseModal,
  applicantId,
  getGicDetails,
}) => {
  const [invoiceFile, setInvoiceFile] = useState();
  const [loading, setLoading] = useState(false);
  const handleInputChange = (event) => {
    const { type, files } = event.target;
    if (type === "file") {
      const file = files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setInvoiceFile({
            fileName: file.name,
            content: reader.result.split(",")[1],
            mimeType: file.type,
          });
        };
        reader.readAsDataURL(file);
      }
    } else {
      setInvoiceFile({});
    }
  };

  const handleUpdateStatus = useCallback(async () => {
    try {
      const body = {
        id: applicantId,
        applicantStatus: "Commission Paid",
      };
      const response = await GicServices.updateStatus(body);
      if (response.data.success === true) {
        toast.success(response.data.message);
        await getGicDetails();
      } else if (response.data.validationErrors) {
        response.data.validationErrors.forEach((verr) => toast.error(verr));
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString()
      );
    }
  }, [applicantId, getGicDetails]);

  const handleSaveInvoice = useCallback(async () => {
    setLoading(true);
    try {
      const body = {
        applicantId,
        documentType: "Invoice",
        fileName: invoiceFile?.fileName,
        file: invoiceFile?.content,
      };
      const response = await GicServices.UploadInvoice(body);
      if (response.data.success === true) {
        await handleUpdateStatus();
        handleCloseModal();
        setInvoiceFile({});
      } else if (response.data.validationErrors) {
        response.data.validationErrors.forEach((verr) => toast.error(verr));
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString()
      );
    }
    setLoading(false);
  }, [
    applicantId,
    handleCloseModal,
    handleUpdateStatus,
    invoiceFile?.content,
    invoiceFile?.fileName,
  ]);
  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      centered
      size="md"
      className="custom-modal"
    >
      <Modal.Body>
        <i
          className="bi bi-x-circle-fill text2 p-3 text-danger position-absolute end-0 top-0"
          style={{ cursor: "pointer" }}
          onClick={handleCloseModal}
        ></i>
        <div className="d-flex flex-column align-items-center pt-5 gap-5">
          <img src={platformLogo} alt="platformLogo" height="80px" />
        </div>
        <div className="p-5">
          <p className="text-dark1 text1-8 text-center">Invoice</p>
          {!invoiceFile?.fileName ? (
            <div className="border rounded-pill p-2">
              <label
                htmlFor="invoiceUpload"
                className="d-flex justify-content-between align-items-center"
              >
                <p className="mb-0">Upload Invoice</p>
                <i class="bi bi-cloud-arrow-up-fill text2 text-dark1"></i>
              </label>
              <input
                type="file"
                id="invoiceUpload"
                onChange={handleInputChange}
                className="d-none"
              />
            </div>
          ) : (
            <div className="d-flex justify-content-evenly align-items-center">
              <div className="d-flex flex-column align-items-center justify-content-center gap-3">
                <i class="bi bi-file-pdf-fill text2-5 btn rounded-4 btn-success "></i>
                <p className="mb-0"> {invoiceFile?.fileName}</p>
                <button
                  onClick={handleSaveInvoice}
                  className="btn bg-dark1 text-white rounded-pill text1-5 px-5 mt-4"
                >
                  {loading ? <Spinner /> : "Save"}
                </button>
              </div>

              <i
                className="bi bi-x-circle-fill text1-5 p-3 text-danger position-absolute"
                style={{
                  cursor: "pointer",
                  left: "51%",
                  bottom: "41%",
                }}
                onClick={() => setInvoiceFile({})}
              ></i>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default InvoiceModal;
