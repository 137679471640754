import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import PageTitle from "../../../components/PageTitle";
import SpinnerGrow from "../../Components/SpinnerGrow";
import SearchBar from "../../Components/SearchBar";
import SelectItemsPerPage from "../../Components/SelectItemsPerPage";
import AddPrograms from "./AddPrograms";
import AdvancePagination from "../../Components/Pagination";
import ConfirmationModal from "../../Components/ConfirmationModal";
import SpinnerBorder from "../../Components/SpinnerBorder";
import ProgramService from "../../Services/ProgramSearchService";
import ListPrograms from "./ListPrograms";

export default function MainPrograms() {
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  const pageTitle = "Programs";

  const initializeData = [
    {
      collegeId: "",
      name: "",
      author: "",
      campus: "",
      duration: "",
      credential: "",
      tuitionFee: 0,
      academicRequirement: "",
      applicationProcessingTime: "",
      pgWorkPermit: "",
      websiteUrl: "",
      statusId: 1,
      ieltsRequirement: "",
      pteRequirement: "",
      toeflRequirement: "",
      duolingoRequirement: "",
      gmat: "",
      gre: "",
      sat: "",
      areasofInterest: "",
      specificRequirement1: "",
      specificRequirement2: "",
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [itemsLength, setItemsLength] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const [collegeData, setCollegeData] = useState([]);
  const [selectedCollege, setSelectedCollege] = useState();

  const handleCollegeSelect = (e) => {
    e.preventDefault();
    const { value } = e.target;
    if (value === "All") {
      setSelectedCollege();
    } else {
      setSelectedCollege(value);
    }
  };

  const getCollegeData = async () => {
    try {
      const response = (await ProgramService.getCountryColleges("")).data;
      setCollegeData(response);
    } catch (error) {
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message
      );
    }
  };

  const [programData, setProgramData] = useState(initializeData);

  const getPrograms = async () => {
    setUpdating(true);
    try {
      await getCollegeData();
      const data = {
        search: searchQuery,
        currentPage,
        pageSize,
        collegeId: selectedCollege,
      };
      const response = await ProgramService.find(data);
      const responseData = response.data.response;
      setItemsLength(responseData[0]?.totalItems);
      setProgramData(responseData);
    } catch (error) {
      toast.error(error);
    } finally {
      setUpdating(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPrograms();
  }, [currentPage, pageSize, pageTitle, selectedCollege]);

  const handleSearchInput = (e) => {
    e.preventDefault();
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const clearSearchQuery = () => {
    setSearchQuery("");
  };

  const handlePageSizeChange = (event) => {
    event.preventDefault();
    setPageSize(parseInt(event.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [itemToConfirm, setItemToConfirm] = useState(null);

  const handleDelete = (e, data) => {
    e.preventDefault();
    setItemToConfirm(data);
    setShowConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    setShowConfirmation(false);
    if (itemToConfirm) {
      try {
        await toast.promise(
          ProgramService.remove(itemToConfirm.collegeProgramId),
          {
            loading: "Deleting...",
            success: <b>{itemToConfirm.name} deleted successfully!</b>,
            error: <b>Could not delete {itemToConfirm.name}</b>,
          }
        );
        setItemToConfirm(null);
        getPrograms(); // updates the data after deletion
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
    setItemToConfirm(null);
  };

  if (loading) return <SpinnerGrow />;

  return (
    <>
      <PageTitle title={pageTitle} />
      <div className="row px-5">
        <div className="text-center fs-1 text-uppercase col-12">
          {pageTitle}
        </div>
        <div className="col-6">
          <AddPrograms
            collegeData={collegeData}
            getData={getPrograms}
            pageTitle={pageTitle}
          />
        </div>
        <div className="col-5 d-flex justify-content-end">
          <div className="position-relative mb-2">
            <input
              type="text"
              className="form-control rounded-4 border py-2 mx-2 fs-5"
              placeholder={`Search ${pageTitle}`}
              style={{ width: "280px" }}
              value={searchQuery}
              onChange={handleSearchInput}
              required
            />

            <div className="top-50 end-0 position-absolute translate-middle-y me-3">
              <button
                className="btn btn-outline-dark rounded-circle"
                style={{ width: "24px", height: "24px" }}
                onClick={getPrograms}
              >
                <span className="d-flex justify-content-center align-items-center">
                  <i className="bi bi-search fs-5"></i>
                </span>
              </button>
            </div>
          </div>

          <div className="w-100">
            <select
              className="form-select rounded-4 fs-5 py-2"
              aria-label="college select"
              name="collegeId"
              value={selectedCollege}
              onChange={handleCollegeSelect}
            >
              <option value={"All"}>All Colleges</option>
              {collegeData.map((cd) => (
                <option value={cd.id} key={cd.id}>
                  {cd.collegeName}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-1">
          <SelectItemsPerPage
            handlePageSizeChange={handlePageSizeChange}
            pageSize={pageSize}
          />
        </div>
        {updating ? (
          <SpinnerBorder />
        ) : programData.length === 0 ? (
          <div className="mt-5 text-center fs-1">No Data Found</div>
        ) : (
          <div className="col-12 p-4">
            <ListPrograms
              dataList={programData}
              handleDelete={handleDelete}
              collegeData={collegeData}
              getData={getPrograms}
              pageTitle={pageTitle}
            />
            <AdvancePagination
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              totalItems={itemsLength}
              pageSize={pageSize}
            />
            <ConfirmationModal
              show={showConfirmation}
              onHide={handleCloseConfirmation}
              onConfirm={handleConfirmDelete}
              message={
                itemToConfirm
                  ? `Are you sure want to delete ${itemToConfirm.name}?`
                  : ""
              }
              title={itemToConfirm?.name}
            />
          </div>
        )}
      </div>
    </>
  );
}
