import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const PlaceholderPhoneInput = ({ placeholder, customCSS, value, onChange, disabled }) => {
    const [phoneNo, setPhoneNo] = useState('');

    useEffect(() => {
        if (value) {
            setPhoneNo(value);
        }
    }, [value]);

    return (
        <div>
            <PhoneInput
                inputClass={`w-100 rounded-pill shadow ${customCSS}`}
                inputProps={{
                    name: 'phoneNumber',
                    required: true,
                    placeholder: placeholder ? placeholder : 'Telephone',
                }}
                disabled={disabled}
                value={phoneNo}
                onChange={(value) => {
                    setPhoneNo(value);
                    onChange(value);
                }}
            />
        </div>
    );
}

export default PlaceholderPhoneInput;
