import React, { useState } from "react";
import CollegeProgramService from "../../Services/CollegeProgramService";
import TokenService from "../../Services/TokenService";
import RolesService from "../../Services/RolesService";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/UI/ConfirmationModal";
export default function DeleteProgram({ program, findProgram }) {
  const getRoles = TokenService.getUserRole();
  const { SuperAdmin, ProgramEditor } = RolesService;

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [itemToConfirm, setItemToConfirm] = useState(null);
  const handleDelete = async (e, data) => {
    e.preventDefault();
    setShowConfirmation(true);
    setItemToConfirm(data);
  };

  const handleConfirmation = async () => {
    setShowConfirmation(false);
    try {
      const response = await CollegeProgramService.remove(itemToConfirm.id);
      if (response.data === true) {
        toast.info("Delete Successfully");
        findProgram();
      } else {
        toast.info("Not Deleted");
      }
    } catch (e) {
      toast.error(
        (e.response && e.response.data && e.response.data.message) ||
          e.message ||
          e.toString()
      );
    }
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  return (
    <>
      {getRoles === SuperAdmin || ProgramEditor ? (
        <button
          className="btn btn-dark-red rounded-circle"
          style={{ width: "38px", height: "38px" }}
          onClick={(e) => handleDelete(e, program)}
        >
          <i
            className="bi bi-trash3 d-flex justify-content-center align-items-center"
            style={{
              fontSize: "1.5rem",
            }}
          ></i>
        </button>
      ) : (
        ""
      )}
      <ConfirmationModal
        show={showConfirmation}
        onHide={handleCloseConfirmation}
        onConfirm={handleConfirmation}
        message={`Are you sure you want to delete ${program.collegeProgramName} of ${program.collegeName}?`}
        title="Confirm Deletion"
      />
    </>
  );
}
