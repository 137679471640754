import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FormatIntkeDate from "../../components/UI/FormatIntkeDate";
import ApplicationDataService from "../../Services/ApplicationService";
import platformLogo from "../../assets/logo_dark.png";
import EducationDataService from "../../Services/StudentEducationService";
import StudentDocumentService from "../../Services/StudentDocumentService";

const staticFields = 2;

function IntakeSelectionModal({
  show,
  onHide,
  programData,
  setSelectedIntake,
  selectedIntake,
  handleSelectIntake,
  processing,
  handleCampusChange,
  selectedCampus,
  id,
}) {
  const filteredIntakes = useMemo(() => {
    return programData?.intakeList
      ?.filter((intake) => intake.collegeCampusName === selectedCampus)
      ?.filter((intake) => intake.statusId === 3);
  }, [selectedCampus]);

  console.log({ processing, selectedIntake, selectedCampus });
  return (
    <Modal show={show} onHide={onHide} size="md" className="custom-modal">
      <Modal.Body>
        <i
          className="bi bi-x-circle-fill text2 p-3 text-danger position-absolute end-0 top-0"
          style={{ cursor: "pointer" }}
          onClick={onHide}
        ></i>
        <div className="d-flex flex-column align-items-center pt-5 gap-5">
          <img src={platformLogo} alt="platformLogo" height="80px" />
          <p className="text2 text-dark1 fw-bold">
            {programData?.collegeProgramName}
          </p>
        </div>
        <div className="px-5 m-5">
          <div className="mx-4 fs-4 text-dark1">Select Campus</div>
          <Form.Select
            aria-label="Floating label select campus"
            name="campus"
            value={selectedCampus}
            onChange={handleCampusChange}
            required
            className="mb-4 w-100 m-auto mt-2 p-4 rounded-pill"
          >
            <option disabled>Select Campus</option>
            {programData.campus.map((campus) => (
              <option key={campus} value={campus}>
                {campus}
              </option>
            ))}
          </Form.Select>
          {filteredIntakes?.length === 0 ? (
            <div className="text-center fs-4 mt-3 fw-bold">
              No Open Intakes in this campus
            </div>
          ) : (
            <>
              <div className="mx-4 fs-4 text-dark1">Select Intake</div>
              <Form.Select
                aria-label="Floating label select intake"
                name="intake"
                value={selectedIntake}
                onChange={(e) => setSelectedIntake(e?.target?.value)}
                required
                className="mb-4 w-100 m-auto mt-2 p-4 rounded-pill"
              >
                <option className="p-5" value="" disabled>
                  Select Intake
                </option>
                {filteredIntakes
                  ?.sort((a, b) => new Date(a.date) - new Date(b.date))
                  ?.map((intake) => (
                    <option key={intake.date} value={intake.date}>
                      <FormatIntkeDate date={intake.date} />
                    </option>
                  ))}
              </Form.Select>

              <div className="text-center text-light-gray fs-4 my-4">
                Selecting one intake is compulsory
              </div>
            </>
          )}
        </div>
        <div className="d-flex justify-content-center gap-5 mb-5">
          <button
            variant="secondary"
            onClick={onHide}
            className="rounded-pill text1-4  px-5 pt-2 border-dark1"
            disabled={processing}
          >
            Cancel
          </button>
          <button
            variant="success"
            onClick={handleSelectIntake}
            className="rounded-pill text1-4 px-5 pt-2 gradiantButton"
            disabled={!selectedIntake || processing}
          >
            Select & Pay
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default function GenerateApplication({ studentData, programData }) {
  const { id } = studentData;
  const navigate = useNavigate();

  const [showIntakeSelect, setShowIntakeSelect] = useState(false);
  const [selectedIntake, setSelectedIntake] = useState("");
  const [processing, setProcessing] = useState(false);

  const [applicationData] = useState(programData);
  const [applications, setApplications] = useState([]);

  const [selectedCampus, setSelectedCampus] = useState(programData.campus[0]);

  const [currentEducation, setCurrentEducation] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const getEducation = useCallback(async () => {
    try {
      const response = await EducationDataService.findAndGetByStudentID(id);
      setCurrentEducation(response.data.response);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message || error.toString());
      }
    }
  }, [id]);
  const getDocument = useCallback(() => {
    StudentDocumentService.findAndGetByStudentID(id)
      .then((response) => {
        setDocumentList(response.data.response);
        //setIsLoaded(true);
      })
      .catch((e) => {
        toast.error(
          (e.response && e.response.data && e.response.data.message) ||
            e.message ||
            e.toString()
        );
      });
  }, [id]);
  useEffect(() => {
    getDocument();
  }, [getDocument]);
  useEffect(() => {
    getEducation();
  }, [getEducation]);

  const handleCampusChange = useCallback((e) => {
    setSelectedCampus(e.target.value);
  }, []);

  const getAllApplication = async () => {
    try {
      const response =
        await ApplicationDataService.getStudentApplicationByStudentId(id);
      setApplications(response.data.response);
    } catch (error) {
      //toast.error("Error fetching applications");
    }
  };

  useEffect(() => {
    getAllApplication();
  }, [studentData]);

  const saveApplicationData = async () => {
    const isDuplicate = applications.some(
      (application) => application.collegeProgramId === applicationData.id
    );
    if (documentList.length < currentEducation.length + staticFields) {
      toast.error(`Please upload mandatory documents`);
      return;
    } else if (isDuplicate) {
      toast.error(
        `You have already applied ${applicationData.collegeProgramName}`
      );
      return;
    } else if (studentData.passportNumber === null) {
      toast.error("Passport Number is must to start an application");
    } else if (applicationData.statusId === 2) {
      toast.error(`${applicationData.collegeProgramName} is Closed`);
    } else {
      setProcessing(true);
      const data = {
        statusId: 1,
        studentId: id,
        collegeId: applicationData.collegeId,
        collegeProgramId: applicationData.id,
        country: applicationData.country,
        campus: selectedCampus,
        intake: selectedIntake,
        remarks: "Application Created",
        applicationStatus: "Draft",
      };

      try {
        const response = await ApplicationDataService.create(data);

        toast.success(response.data.message);
        navigate("/user/viewApplication/" + response.data.response);
        setProcessing(false);
      } catch (error) {
        setProcessing(false);
        toast.error("Error saving application");
      }
    }
  };

  const handleSelection = () => {
    setShowIntakeSelect(true);
  };

  const handleCancelIntakeSelect = () => {
    setShowIntakeSelect(false);
    setSelectedIntake(""); // Clear the selected intake when the modal is closed
  };

  return (
    <>
      {showIntakeSelect ? (
        <IntakeSelectionModal
          show={showIntakeSelect}
          onHide={handleCancelIntakeSelect}
          programData={programData}
          setSelectedIntake={setSelectedIntake}
          handleSelectIntake={saveApplicationData}
          selectedIntake={selectedIntake}
          processing={processing}
          handleCampusChange={handleCampusChange}
          selectedCampus={selectedCampus}
          id={id}
        />
      ) : (
        <button
          className={`${
            programData.intakeList.filter((intake) => intake.statusId === 3)
              .length === 0
              ? "d-none"
              : "btn-light-green-outline"
          }  rounded-pill px-2 py-3 text1-4`}
          onClick={handleSelection}
          disabled={
            programData.intakeList.filter((intake) => intake.statusId === 3)
              .length === 0
          } // Disable button if no intakes with statusId === 3
          style={{ width: "140px" }}
        >
          Start Application
        </button>
      )}
    </>
  );
}
