import React, { useCallback, useEffect, useState } from "react";
import Header from "../../layout/Header";
import PageTitle from "../../components/PageTitle";
import Section8 from "../../pages/Main/Section8";
import PhoneNumberInput from "../../components/PhoneNumberInput";
import { toast } from "react-toastify";
import ContactUsService from "../../Services/ContactUsService";
import { Modal, Spinner } from "react-bootstrap";
import platformLogo from "../../assets/logo_dark.png";
import { set } from "date-fns";

const pageTitle = "Contact Us";
const Contact = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [resetPhone, setResetPhone] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    emailId: "",
    enquiry: "",
  });

  const handlePhoneNumberChange = useCallback((value) => {
    setFormData((prev) => ({
      ...prev,
      phoneNumber: value,
    }));
  }, []);
  console.log({ resetPhone });

  const handleSubmitContactus = useCallback(
    async (e) => {
      try {
        setIsLoading(true);
        e.preventDefault();
        if (!formData.phoneNumber) {
          toast.error("Phone Number is required");
          return;
        }
        const response = await ContactUsService.contact(formData);
        if (response.data.success === true) {
          setFormData({
            name: "",
            phoneNumber: "",
            emailId: "",
            enquiry: "",
          });
          setResetPhone(true);
          setShow(true);
        } else if (response.data.validationErrors) {
          response.data.validationErrors.forEach((verr) => toast.error(verr));
        } else {
          toast.error("Something Went Wrong");
        }
      } catch (error) {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
        );
      } finally {
        setIsLoading(false);
      }
    },
    [formData]
  );

  return (
    <>
      <PageTitle title={pageTitle} />
      <Header />
      <div className="about-header" style={{ paddingTop: "300px" }}>
        <div className="bg-dark1 p-5 position-relative">
          <div className="d-flex flex-column gap-3  ms-sm-5 p-5">
            <p className="text-white text5">Contact us</p>
            {/* <div className='d-flex  gap-5'>
                            <i class="bi bi-geo-alt text-white text2"></i>
                            <p className='text-white text1-4 mb-0'>ha-8 remotestate noida sector 101 up <br /> lorem epsium djak sd sjd sdke </p>
                        </div> */}
            {/* <div className='d-flex  gap-5'>
                            <i class="bi bi-telephone text-white text2"></i>
                            <p className='text-white text1-4 mb-0 mt-2'>080 -25043257</p>
                        </div> */}
            <div className="d-flex  gap-5">
              <i class="bi bi-envelope text-white text2"></i>
              <p className="text-white text1-4 mb-0 mt-2">
                Partnerships@educationplanner.io
              </p>
            </div>
          </div>
          <div
            className="d-none d-lg-block bg-light-blue position-absolute p-5 rounded-4"
            style={{ top: "-40%", right: "20%", width: "600px" }}
          >
            <div className="d-flex flex-column align-items-center gap-3">
              <p className="text2">Drop us a message</p>
              <form
                onSubmit={handleSubmitContactus}
                className="d-flex flex-column align-items-center gap-5 w-100 px-5"
              >
                <div className="col-12 form-floating">
                  <input
                    type="text"
                    className="form-control rounded-pill pt-3 border ps-4 pb-1"
                    placeholder="Name*"
                    required
                    value={formData.name}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        name: e?.target?.value,
                      }))
                    }
                  />
                  <label htmlFor="floatingInput" className="ms-4 pt-2">
                    Name<span className="text-danger">*</span>
                  </label>
                </div>

                <div className="col-12 form-floating">
                  <input
                    type="email"
                    className="form-control rounded-pill pt-3 border ps-4 pb-1"
                    placeholder="Email*"
                    required
                    value={formData.emailId}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        emailId: e?.target?.value,
                      }))
                    }
                  />
                  <label htmlFor="floatingInput" className="ms-4 pt-2">
                    Email Id<span className="text-danger">*</span>
                  </label>
                </div>
                <div className="w-100">
                  <PhoneNumberInput
                    customCSS="rounded"
                    customizedCSS={"rounded"}
                    value={formData.phoneNumber}
                    onChange={handlePhoneNumberChange}
                    resetPhoneNumber={resetPhone}
                  />
                </div>

                <div class="form-floating col-12 ">
                  <textarea
                    htmlFor="floatingInput"
                    className="form-control rounded-4  text1-4"
                    placeholder="Type your enquiry here..."
                    style={{ height: "100px" }}
                    value={formData.enquiry}
                    required
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        enquiry: e?.target?.value,
                      }))
                    }
                  />
                  <label id="teLbl" htmlFor="floatingInput">
                    Type your enquiry here...
                    <span className="text-danger">*</span>
                  </label>
                </div>

                {isLoading ? (
                  <Spinner />
                ) : (
                  <button
                    type="submit"
                    className="d-flex justify-content-center align-items-center text-center p-2 mt-3 border-0 rounded text-white text1-5 w-25 bg-dark1"
                  >
                    Submit
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>

        <div className="d-block d-lg-none bg-light-blue py-5 mx-3 my-5 m-md-5 rounded-4">
          <div className="d-flex flex-column align-items-center gap-3">
            <p className="text2">Drop us a message</p>
            <form
              onSubmit={handleSubmitContactus}
              className="d-flex flex-column align-items-center gap-5 w-100 px-5"
            >
              <div className="col-12 form-floating">
                <input
                  type="text"
                  className="form-control rounded-pill pt-3 border ps-4 pb-1"
                  placeholder="Name*"
                  required
                  value={formData.name}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      name: e?.target?.value,
                    }))
                  }
                />
                <label htmlFor="floatingInput" className="ms-4 pt-2">
                  Name<span className="text-danger">*</span>
                </label>
              </div>

              <div className="col-12 form-floating">
                <input
                  type="email"
                  className="form-control rounded-pill pt-3 border ps-4 pb-1"
                  placeholder="Email*"
                  required
                  value={formData.emailId}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      emailId: e?.target?.value,
                    }))
                  }
                />
                <label htmlFor="floatingInput" className="ms-4 pt-2">
                  Email Id<span className="text-danger">*</span>
                </label>
              </div>
              <div className="w-100">
                <PhoneNumberInput
                  customCSS="rounded"
                  customizedCSS={"rounded"}
                  value={formData.phoneNumber}
                  onChange={handlePhoneNumberChange}
                  resetPhoneNumber={resetPhone}
                />
              </div>

              <div class="form-floating col-12 ">
                <textarea
                  htmlFor="floatingInput"
                  className="form-control rounded-4 pt-5 text1-4"
                  placeholder="Type your enquiry here..."
                  style={{ height: "100px" }}
                  required
                  value={formData.enquiry}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      enquiry: e?.target?.value,
                    }))
                  }
                />
                <label htmlFor="floatingInput">Type your enquiry here...</label>
              </div>

              <button
                type="submit"
                className="d-flex justify-content-center align-items-center text-center p-2 mt-3 border-0 rounded text-white text1-5 w-25 bg-dark1"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
        <div style={{ paddingTop: "150px" }}>
          <Section8 />
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        size="md"
        className="custom-modal"
      >
        <Modal.Body>
          <i
            className="bi bi-x-circle-fill text2 p-3 text-danger position-absolute end-0 top-0"
            style={{ cursor: "pointer" }}
            onClick={() => setShow(false)}
          ></i>
          <div className="d-flex flex-column justify-content-center align-items-center pt-5 gap-5 mb-3">
            <img src={platformLogo} alt="platformLogo" height="80px" />
            <div className="flex flex-column gap-3 mt-2  mb-3 px-4">
              <p className="text2-5 fw-bold text-dark1 text-center ">
                Thank you for contacting us
              </p>
              <p className="text1-6 text-center">
                Our team is going through your query.
                <br /> We will contact you shortly.
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Contact;
