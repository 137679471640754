import React from "react";
import flywireCorner from "../../assets/marketplace/flywire-corner.png";
import flywireLogo from "../../assets/marketplace/flywire-logo.png";

export default function FlywireCard() {
  return (
    <div className="gic-card blue-bgImage">
      <img
        src={flywireCorner}
        className="position-absolute flywire-corner"
        alt="dots"
      />
      <div className="d-flex flex-column gap-4  w-50 mt-5 pt-2 px-5">
        <div
          style={{
            width: "max-content",
          }}
          className="shadow py-4 w-ma px-5 rounded-4 d-flex justify-content-center align-items-center"
        >
          <img src={flywireLogo} alt="logo" className="flywire-logo" />
        </div>
        <div className="d-flex flex-column gap-3 mt-1">
          <p className="my-0 text1-5 text-dark1">Tuition payments Made Easy </p>
          <p className="my-0 text1-6 text-dark1 fw-bold">
            Earn 0.4% Commission{" "}
          </p>
          <button
            style={{
              width: "max-content",
            }}
            className="bg-dark1  pt-2 pb-1 px-4 rounded-pill border-0 text-white text1-4"
          >
            Pay Now <i class="bi bi-arrow-right "></i>
          </button>
        </div>
      </div>
    </div>
  );
}
