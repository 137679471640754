import http from "./api";

const get = (id) => {
  return http.get(`EnglishProficiencyTest?EnglishProficiencyTestId=${id}`);
};
const remove = (id) => {
  return http.delete(`EnglishProficiencyTest?id=${id}`);
};
const create = (data) => {
  return http.post("EnglishProficiencyTest", data);
};
const update = (data) => {
  return http.put(`EnglishProficiencyTest`, data);
};
const find = (data) => {
  return http.post(`EnglishProficiencyTest/search`, data);
};


const EnglishProficiencyTestDataService = {
  get,
  remove,
  create,
  update,
  find,
};

export default EnglishProficiencyTestDataService;
