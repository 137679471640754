import React from "react";

export default function UploadLogo({ fileInputRef, data, setData, label }) {
  const handleCollegeLogoInput = async (event) => {
    const { name, type, files, value } = event.target;
    const file = files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setData((p) => ({
          ...p,
          [label]: reader.result.split(",")[1],
          [`${label}Name`]: file.name,
          [`${label}type`]: file.type,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      {data[label] ? (
        <div className=" d-flex  mt-2 position-relative">
          <img
            src={`data:${data[`${label}Name`]};base64,${data[label]}`}
            alt="Uploaded"
            className=""
            style={{ maxWidth: "90%", maxHeight: "130px" }}
          />
          <button
            className="btn btn-link btn-cross"
            style={{
              position: "absolute",
              right: "35px",
              top: "-13px",
            }}
            onClick={() => {
              setData((p) => ({
                ...p,
                [label]: "",
                [`${label}Name`]: "",
                [`${label}type`]: "",
              }));
            }}
          >
            <i className="bi bi-x-circle-fill text-danger text2"></i>
          </button>
        </div>
      ) : (
        <>
          <div className="text1-4 text-dark1 d-flex align-items-center ms-2 mt-2">
            <label
              htmlFor={`importInput_${label}`}
              style={{ cursor: "pointer" }}
            >
              <div className="d-flex align-items-center">
                <i className="bi bi-cloud-plus text-dark1 text1-6 d-flex align-items-center justify-content-center me-2"></i>
                <span className="text1-3">Upload</span>
              </div>
            </label>

            <input
              id={`importInput_${label}`}
              type="file"
              className="d-none"
              ref={fileInputRef}
              accept="image/jpeg,image/jpg,image/png"
              onChange={handleCollegeLogoInput}
            />
          </div>
        </>
      )}
    </div>
  );
}
