import React, { forwardRef } from "react";

export const DocumentsEPAssignee = forwardRef(
  (
    {
      documentList,
      documentData,
      handleStudentDocumentsInputChange,
      saveStudentDocumentsData,
      handleDeleteDocument,
      handleDownload,
      RoleService,
      getRole,
      isSaving,
      allDraftApplication,
    },
    ref
  ) => {
    const { AdmissionOfficer } = RoleService;
    return (
      <>
        <div className="table-responsive rounded-4">
          <table className="table custom-table">
            <tbody className="text1-4">
              {getRole === AdmissionOfficer ? (
                <tr className="py-3 text-center">
                  <td>
                    <form
                      id="upload-docs-form"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <div className="col-7">
                        <input
                          className="form-control rounded-pill text1-3"
                          type="text"
                          id="title"
                          name="title"
                          placeholder="Add New Document"
                          value={documentData.title}
                          onChange={handleStudentDocumentsInputChange}
                          required
                          disabled={isSaving}
                        />
                      </div>
                      <div className="col-4 px-3">
                        <input
                          className="form-control rounded-pill text1-3"
                          type="file"
                          ref={ref}
                          id="upload"
                          name="upload"
                          onChange={handleStudentDocumentsInputChange}
                          accept="application/pdf"
                          required
                          disabled={isSaving}
                        />
                      </div>
                      <div className="col-1">
                        <button
                          className="btn rounded-4 btn-dark-blue d-flex align-items-center justify-content-center text1-4"
                          type="submit"
                          //style={{ minWidth: "140px" }}
                          onClick={saveStudentDocumentsData}
                          disabled={
                            isSaving ||
                            documentData.title === undefined ||
                            documentData.title === ""
                          }
                        >
                          <span>
                            {documentData.title !== undefined && isSaving ? (
                              ""
                            ) : (
                              <i className="bi bi-upload text2"></i>
                            )}
                          </span>
                          {documentData.title !== undefined && isSaving && (
                            <span className="spinner-border"></span>
                          )}
                        </button>
                      </div>
                    </form>
                  </td>
                </tr>
              ) : null}

              {documentList.length > 0
                ? documentList.map((dl, index) => {
                    if (dl.roleName === AdmissionOfficer) {
                      return (
                        <tr className="py-3 text-center" key={index}>
                          <td className="d-flex align-items-center justify-content-between">
                            <div className="text1-6 font-bold ms-5">
                              {dl.title}
                            </div>
                            <div className="d-flex align-items-center justify-content-center me-5">
                              <button
                                className="btn rounded-4 btn-success d-flex align-items-center justify-content-center text1-4 me-3"
                                type="button"
                                //value={dl.id}
                                onClick={(e) => handleDownload(e, dl)}
                                //disabled={viewing}
                              >
                                {/* {viewing ? (
                                      <>
                                        <span className="spinner-border"></span>
                                      </>
                                    ) : (
                                      <i className="bi bi-file-earmark-text text2"></i>
                                    )} */}
                                <i className="bi bi-file-earmark-text text2"></i>
                              </button>
                              <button
                                className={`btn rounded-4 d-flex align-items-center justify-content-center text1-4 ${
                                  getRole !== AdmissionOfficer &&
                                  allDraftApplication === false
                                    ? "btn-secondary"
                                    : "btn-danger"
                                }`}
                                type="button"
                                // style={{ minWidth: "140px" }}
                                value={dl.id}
                                onClick={handleDeleteDocument}
                                disabled={
                                  getRole !== AdmissionOfficer &&
                                  allDraftApplication === false
                                }
                              >
                                <i className="bi bi-trash3 text2"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    } else {
                      return null;
                    }
                  })
                : ""}
            </tbody>
          </table>
        </div>
      </>
    );
  }
);

export default DocumentsEPAssignee;
