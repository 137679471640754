import React, { useState } from "react";
import { Fade, Modal } from "react-bootstrap";
import addIcon from "../../assets/icons/add_role.svg";
import PhoneNumberInput from "../../components/PhoneNumberInput";
import UserDataService from "../../Services/UserService";
import TokenService from "../../Services/TokenService";
import { toast } from "react-toastify";
export default function UserAdd({ retrieveUsers, roles, RoleService, getRole }) {
  const { SuperAdmin, Reception, Counsellor, BranchHead } = RoleService;
  const getCompnayId = TokenService.getCompnayId();
  const [saving, setSaving] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const initialUserData = {
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    phoneNumber: "",
    companyId: getCompnayId,
    roleName: "",
    password: "",
    confirmPassword: "",
    statusId: 1,
  };
  const [userData, setUserData] = useState(initialUserData);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
  };

  const handlePhoneNumberChange = (value) => {
    if (value) {
      userData.phoneNumber = value;
    }
  };
  const handlePhoneNumberConfirmedChange = (value) => {
    if (value) {
      userData.phoneNumberConfirmed = value;
    }
  };
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);

  const [resetPhoneNumber, setResetPhoneNumber] = useState(false);

  const resetForm = () => {
    document.getElementById("form-reset")?.reset();
    setResetPhoneNumber(true);
    setUserData(initialUserData);
    setViewPassword(false);
    setViewConfirmPassword(false);
  };
  const saveUserData = async (e) => {
    e.preventDefault();
    const data = { ...userData };
    setSaving(true);

    try {
      const response = await UserDataService.create(data);

      if (response.data.success) {
        resetForm();
        retrieveUsers();
        toast.success("User added successfully");
        setModalShow(false);
      } else if (response.data.validationErrors) {
        response.data.validationErrors.map((verr) => toast.error(verr));
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <button
        className="btn bg-dark1 d-flex justify-content-center align-items-center rounded-pill text-white text1-4 py-2 px-4"
        onClick={() => setModalShow(true)}
      >
        <img src={addIcon} alt="addUser" className="img-fluid me-1 mb-1" style={{ width: "16px", height: "16px" }} /> Add New User
      </button>

      <Modal show={modalShow} onHide={() => setModalShow(false)} centered size="lg" aria-labelledby="contained-modal-title-vcenter">
        <Modal.Header className="bg-dark1 text-white d-flex justify-content-between align-items-center py-2">
          <Modal.Title id="contained-modal-title-vcenter" className="d-flex align-items-center">
            Add New User
          </Modal.Title>
          <i className="bi bi-x-circle text2" style={{ cursor: "pointer" }} onClick={() => setModalShow(false)}></i>
        </Modal.Header>
        <Modal.Body>
          <form id="form-reset" className="needs-validation" onSubmit={saveUserData}>
            <div className="row g-3 p-3">
              <div className="col-6 form-floating px-3">
                <input
                  type="text"
                  className="form-control rounded-pill border"
                  placeholder="First name*"
                  required
                  id="firstName"
                  value={userData.firstName}
                  onChange={handleInputChange}
                  name="firstName"
                  autoComplete="off"
                />
                <label htmlFor="floatingInput" className="ms-4 text-light-gray ">
                  First Name<span className="text-danger">*</span>
                </label>
              </div>
              <div className="col-6 form-floating px-3">
                <input
                  type="text"
                  className="form-control rounded-pill border"
                  placeholder="Last name"
                  required
                  id="lastName"
                  value={userData.lastName}
                  onChange={handleInputChange}
                  name="lastName"
                  autoComplete="off"
                />
                <label htmlFor="floatingInput" className="ms-4 text-light-gray">
                  Last Name<span className="text-danger">*</span>
                </label>
              </div>

              <div className="col-6 form-floating px-3">
                <input
                  type="email"
                  className="form-control rounded-pill border"
                  placeholder="Country of Citizenship"
                  required
                  id="email"
                  value={userData.email}
                  onChange={handleInputChange}
                  name="email"
                  autoComplete="off"
                />
                <label htmlFor="floatingInput" className="ms-4 text-light-gray">
                  Email
                  <span className="text-danger">*</span>
                </label>
              </div>
              <div className="col-6 form-floating px-3">
                <input
                  type="email"
                  className="form-control rounded-pill border"
                  placeholder="Country of Citizenship"
                  required
                  id="confirmEmail"
                  value={userData.confirmEmail}
                  onChange={handleInputChange}
                  name="confirmEmail"
                  autoComplete="off"
                />
                <label htmlFor="floatingInput" className="ms-4 text-light-gray">
                  Confirm Email
                  <span className="text-danger">*</span>
                </label>
              </div>

              <div className="col-6 form-floating px-3">
                <input
                  type={viewPassword ? "text" : "password"}
                  className="form-control rounded-pill border"
                  placeholder="Password*"
                  required
                  id="password"
                  name="password"
                  value={userData.password}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
                <div className="position-absolute top-50 end-0 translate-middle-y me-5 pe-2" onClick={() => setViewPassword(!viewPassword)}>
                  <i className={`bi ${viewPassword ? "bi-eye-slash" : "bi-eye"} d-flex align-items-center text-dark1 text2`}></i>
                </div>
                <label htmlFor="floatingInput" className="ms-4 pt-2">
                  Password<span className="text-danger">*</span>
                </label>
              </div>

              <div className="col-6 form-floating px-3">
                <input
                  type={viewConfirmPassword ? "text" : "password"}
                  className="form-control rounded-pill border"
                  placeholder="Confirm Password"
                  required
                  id="confirmPassword"
                  value={userData.confirmPassword}
                  onChange={handleInputChange}
                  name="confirmPassword"
                  autoComplete="off"
                />
                <div
                  className="position-absolute top-50 end-0 translate-middle-y me-5 pe-2"
                  onClick={() => setViewConfirmPassword(!viewConfirmPassword)}
                >
                  <i className={`bi ${viewConfirmPassword ? "bi-eye-slash" : "bi-eye"} d-flex align-items-center text-dark1 text2`}></i>
                </div>
                <label htmlFor="floatingInput" className="ms-4 text-light-gray">
                  Confirm Password<span className="text-danger">*</span>
                </label>
              </div>
              <div className="col-6 form-floating px-3">
                <PhoneNumberInput
                  customCSS={""}
                  value={userData.phoneNumber}
                  onChange={handlePhoneNumberChange}
                  resetPhoneNumber={resetPhoneNumber}
                />
              </div>
              <div className="col-6 form-floating px-3">
                <PhoneNumberInput
                  customCSS={""}
                  value={userData.phoneNumberConfirmed}
                  onChange={handlePhoneNumberConfirmedChange}
                  resetPhoneNumber={resetPhoneNumber}
                />
              </div>
              <div className="col-6 form-floating px-3">
                <select
                  className="form-select rounded-pill border"
                  aria-label="Services of Interest"
                  required
                  id="roleName"
                  value={userData.roleName}
                  onChange={handleInputChange}
                  name="roleName"
                  autoComplete="off"
                >
                  <option value="">Select Role</option>
                  {roles.map((role) => {
                    if (getRole === SuperAdmin) {
                      return (
                        <option key={role.id} value={role.name}>
                          {role.name}
                        </option>
                      );
                    } else if (getRole === BranchHead) {
                      if (role.name === Counsellor || role.name === Reception) {
                        return (
                          <option key={role.id} value={role.name}>
                            {role.name}
                          </option>
                        );
                      }
                    } else {
                      <option>Your are not Authorized</option>;
                    }
                  })}
                </select>
                <label htmlFor="floatingInput" className="ms-4 text-light-gray">
                  Role <span className="text-danger">*</span>
                </label>
              </div>
              <div className="col-6 form-floating px-3">
                <select
                  className="form-select rounded-pill border"
                  aria-label="Services of Interest"
                  required
                  id="statusId"
                  value={userData.statusId}
                  onChange={handleInputChange}
                  name="statusId"
                >
                  <option value="">Select Status</option>
                  <option value={1}>Active</option>
                  <option value={0}>Inactive</option>
                </select>
                <label htmlFor="floatingInput" className="ms-4 text-light-gray">
                  Status <span className="text-danger">*</span>
                </label>
              </div>
            </div>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-light-blue-outline rounded-pill py-3 me-2 px-5 text1-3"
                onClick={() => setModalShow(false)}
                disabled={saving}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-dark-blue rounded-pill py-3 px-5 text1-3 d-flex justify-content-center align-items-center"
                disabled={saving}
              >
                <span>{saving ? "Please Wait..." : "Submit"}</span>
                {saving && <span className="spinner-border"></span>}
              </button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
