import http from "./api";

const create = (data) => {
  return http.post("Document/upload", data);
};

const PdfService = {
  create,
};

export default PdfService;
