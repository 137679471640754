import { create } from "zustand";

const useStudentStore = create((set) => ({
  studentDetails: {
    name: "",
    email: "",
    studentId: "",
    firstName: "",
    lastName: "",
    bank: "",
    passport: "",
    contactNumber: "",
    gicReferenceNumber: "",
  },
  setStudentDetails: (details) =>
    set((state) => ({
      studentDetails: { ...state.studentDetails, ...details },
    })),
}));

export default useStudentStore;
