import axios from "axios";
import TokenService from "./TokenService";
import apiURLandKEYS from "./apiURLandKEYS";

const api = axios.create({
  baseURL: apiURLandKEYS.url,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      TokenService.removeUser();
      window.location.href = "/";
    }

    return Promise.reject(error);
  }
);
export default api;
