import React, { useState } from "react";
import PhoneNumberInput from "../../components/PhoneNumberInput";
import Tooltip from "../../components/UI/ToolTip";

export default function SignUp1({ showLogin, showSignup2, registerData, handleInputChange, captchaResponse }) {
  const [viewPassword, setViewPassword] = useState(false);
  const handleViewPassword = () => {
    setViewPassword(!viewPassword);
  };
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const handleViewConfirmPassword = () => {
    setViewConfirmPassword(!viewConfirmPassword);
  };
  const handlePhoneNumberChange = (value) => {
    if (value) {
      registerData.phoneNumber = value;
    }
  };
  return (
    <>
      <form className="row g-3 needs-validation px-5" onSubmit={showSignup2}>
        <div className="col-6 form-floating ps-3">
          <input
            type="text"
            className="form-control rounded-pill border ps-4 pb-1"
            placeholder="First name*"
            required
            name="firstName"
            value={registerData.firstName}
            onChange={handleInputChange}
            pattern="^[a-zA-Z .]*$"
          />
          <label htmlFor="floatingInput" className="ms-4 pt-2">
            First Name<span className="text-danger">*</span>
          </label>
        </div>
        <div className="col-6 form-floating pe-3">
          <input
            type="text"
            className="form-control rounded-pill border ps-4 pb-1"
            placeholder="Full name*"
            required
            name="lastName"
            value={registerData.lastName}
            onChange={handleInputChange}
            pattern="^[a-zA-Z .]*$"
          />
          <label htmlFor="floatingInput" className="ms-4 pt-2">
            Last Name<span className="text-danger">*</span>
          </label>
        </div>

        <div className="col-12 form-floating px-3">
          <input
            type="email"
            className="form-control rounded-pill border ps-4 pb-1"
            placeholder="Email*"
            required
            name="email"
            value={registerData.email}
            onChange={handleInputChange}
          />
          <label htmlFor="floatingInput" className="ms-4 pt-2">
            Email address<span className="text-danger">*</span>
          </label>
        </div>
        <div className="col-12 form-floating px-3">
          <input
            type="email"
            className="form-control rounded-pill border ps-4 pb-1"
            placeholder="Email*"
            required
            name="confirmEmail"
            value={registerData.confirmEmail}
            onChange={handleInputChange}
          />
          <label htmlFor="floatingInput" className="ms-4 pt-2">
            Confirm Email address<span className="text-danger">*</span>
          </label>
        </div>
        <Tooltip
          tooltipText={
            "Your password must be 8-20 characters long, contain letters, special characters and numbers, and must not contain spaces or emoji."
          }
          position="left"
        >
          <div className="col-12 form-floating px-2 position-relative">
            <input
              type={`${viewPassword ? "text" : "password"}`}
              className="form-control rounded-pill border ps-4 pb-1"
              placeholder="*"
              required
              name="password"
              value={registerData.password}
              onChange={handleInputChange}
              pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.@$!%*?&])[A-Za-z\d.@$!%*?&]{8,}$"
              title="At least one of Uppercase, Lowercase, Digit and Symbol"
            />
            <div className="position-absolute top-50 end-0 translate-middle-y me-5 pe-2" onClick={handleViewPassword}>
              <i className={`bi ${viewPassword ? "bi-eye-slash" : "bi-eye"} d-flex align-items-center text-dark1 text2`}></i>
            </div>
            <label htmlFor="floatingInput" className="ms-4 pt-2">
              Create Password<span className="text-danger">*</span>
            </label>
          </div>
        </Tooltip>
        <Tooltip tooltipText={"Confirm password must same as create password"} position="left">
          <div className="col-12 form-floating px-2 position-relative">
            <input
              type={`${viewConfirmPassword ? "text" : "password"}`}
              className="form-control rounded-pill border ps-4 pb-1"
              placeholder="*"
              required
              name="confirmPassword"
              value={registerData.confirmPassword}
              onChange={handleInputChange}
              pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.@$!%*?&])[A-Za-z\d.@$!%*?&]{8,}$"
              title="At least one of Uppercase, Lowercase, Digit and Symbol"
            />
            <div className="position-absolute top-50 end-0 translate-middle-y me-5 pe-2" onClick={handleViewConfirmPassword}>
              <i className={`bi ${viewConfirmPassword ? "bi-eye-slash" : "bi-eye"} d-flex align-items-center text-dark1 text2`}></i>
            </div>
            <label htmlFor="floatingInput" className="ms-4 pt-2">
              Confirm Password<span className="text-danger">*</span>
            </label>
          </div>
        </Tooltip>
        <div className="col-12 px-3">
          <PhoneNumberInput customizedCSS={"shadow"} value={registerData.phoneNumber} onChange={handlePhoneNumberChange} />
        </div>
        <div className="col-12 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border ps-4 pb-1"
            placeholder="Website*"
            name="website"
            value={registerData.website}
            onChange={handleInputChange}
          />
          <label htmlFor="floatingInput" className="ms-4 pt-2">
            Website
          </label>
        </div>

        <button
          type="submit"
          className={`gradiantButton rounded-pill py-3 mx-auto col-10 font-bold text1-6`}
          disabled={captchaResponse === false}
        >
          Next
        </button>

        <div className="col-12 text-center my-3 d-flex justify-content-center align-items-center">
          <p className="text1-4 font-bold me-2">Existing User?</p>
          <p className="text1-6 font-bold text-dark1" style={{ cursor: "pointer" }} onClick={showLogin}>
            Login
          </p>
        </div>
      </form>
    </>
  );
}
