import React, { useState } from "react";

const SearchBar = ({
  searchQuery,
  setSearchQuery,
  searchTitle,
  setCurrentPage,
  className,
}) => {
  const [getQuery, setQuery] = useState(searchQuery);

  return (
    <form
      className={`position-relative mb-2 ${className}`}
      onSubmit={(e) => {
        e.preventDefault();
        setSearchQuery(getQuery);
        if (setCurrentPage) {
          setCurrentPage(1);
        }
      }}
    >
      <input
        type="text"
        className={`form-control rounded-pill border py-3 mx-2 text1-3 ${className}`}
        placeholder={searchTitle}
        style={{ width: "280px" }}
        value={getQuery}
        onChange={(e) => setQuery(e.target.value)}
      />

      <div className="top-50 end-0 position-absolute top-50 end-0 translate-middle-y me-3">
        <button
          className="btn btn-dark-blue rounded-circle"
          style={{ width: "32px", height: "32px" }}
          type="submit"
        >
          <span className="d-flex justify-content-center align-items-center text1-7">
            <i
              className={`bi bi-search justify-content-center align-items-center`}
            ></i>
          </span>
        </button>
      </div>
    </form>
  );
};

export default SearchBar;
