export default function FormatDOB({ date }) {
  const formatDate = (datetime) => {
    const dateObj = new Date(datetime);
    const day = String(dateObj.getDate()).padStart(2, "0");
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const year = String(dateObj.getFullYear());
    return `${day}-${month}-${year}`;
  };

  return <>{formatDate(date)}</>;
}
