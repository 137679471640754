const getUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};
const setUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};
const removeUser = () => {
  localStorage.removeItem("user");
  localStorage.clear();
  sessionStorage.clear();
};
const getLocalAccessToken = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.token;
};
const getCompnayId = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.companyId;
};
const getUserRole = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.role;
};
const TokenService = {
  getUser,
  setUser,
  removeUser,
  getLocalAccessToken,
  getCompnayId,
  getUserRole,
};
export default TokenService;
