import http from "./api";

const find = (data) => {
  return http.post("AdvanceSearch/advanceSearch", data);
};
const get = (id) => {
  return http.get(`CollegeProgram?CollegeProgramId=${id}`);
};
const advanceFilterList = () => {
  return http.get("AdvanceSearch/advanceSearchFiltersList");
};
const getCountryColleges = (data) => {
  return http.get(`AdvanceSearch/getCountryColleges?country=${data}`);
};
const getCollegeCampus = (data) => {
  return http.get(`AdvanceSearch/getCollegeCampus?collegeId=${data}`);
};
const getIntakeList = (collegeId, campusId) => {
  return http.get(
    `AdvanceSearch/getIntakeList?collegeId=${collegeId}&collegeCampusId=${campusId}`
  );
};
const getCollegeProgramsList = (data) => {
  return http.get(
    `AdvanceSearch/getCollegeProgramsList?collegeId=${data.collegeId}&collegeCampusId=${data.collegeCampusId}&intake=${data.intake}`
  );
};
const getAllCollegesAndProgramsList = () => {
  return http.get("AdvanceSearch/getAllCollegesAndProgramsList");
};
const ProgramSearchService = {
  find,
  get,
  advanceFilterList,
  getCountryColleges,
  getCollegeCampus,
  getIntakeList,
  getCollegeProgramsList,
  getAllCollegesAndProgramsList,
};

export default ProgramSearchService;
