import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import EpLoader from "../../components/UI/EpLoader";

export default function AgentTabs({
    tabs,
    customCss,
    isAnimatedBorder,
    isLoaded,
    activeTabNext,
    setActiveTabNext,
    isTabJumpDisabled,
}) {
    const [isLoading, setIsLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(tabs[0].id);
    const [showLoader, setShowLoader] = useState(true);
    const [isClicked, setIsClicked] = useState(false);

    const handleTabClick = (tabId) => {
        setActiveTabNext(tabId);
    };

    // Use useEffect to update the activeTab state when activeTabNext prop changes
    useEffect(() => {
        if (activeTabNext === undefined) {
            setActiveTab(tabs[0].id);
        } else {
            setActiveTab(activeTabNext);
        }
    }, [activeTabNext]);

    useEffect(() => {
        setShowLoader(true); // Show the loader when switching tabs
        setIsClicked(false);
        setTimeout(() => {
            setIsClicked(true);
            setIsLoading(false);
            setShowLoader(false); // Hide the loader once the content is loaded
        }, 500);
    }, [activeTab]);

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <ul
                        className={`nav nav-pills nav-fill my-3 `}
                        id="pills-tab"
                        role="tablist"
                    >
                        {tabs.map((tab, index) => (
                            <li
                                className={`nav-item border-end ${customCss ? "rounded-pill bg-light-blue" : "AddStudentNavLink"
                                    } ${index === 0 ? "border-start" : ""}`}
                                role="presentation"
                                key={tab.id}
                                style={{ width: "200px" }}
                            >
                                <button
                                    className={`nav-link text1-6 ${customCss && activeTab === tab.id
                                        ? "rounded-pill bg-dark1"
                                        : "AddStudentNavLink"
                                        } ${activeTab === tab.id
                                            ? "active rounded-4 text-white"
                                            : "text-dark"
                                        } `}
                                    id={`pills-${tab.id}-tab`}
                                    // data-bs-toggle="pill"
                                    // data-bs-target={`#pills-${tab.id}`}
                                    type="button"
                                    role="tab"
                                    aria-controls={`pills-${tab.id}`}
                                    aria-selected={activeTab === tab.id}
                                    onClick={() => {
                                        if (isTabJumpDisabled) {
                                            toast.error("Please fill out the details and then move to next");
                                        } else {
                                            handleTabClick(tab.id);
                                        }
                                    }}
                                >
                                    {tab.icon ? (
                                        <span className="me-1">
                                            <img
                                                src={tab.icon}
                                                alt={tab.label}
                                                className="img-fluid"
                                                style={{ maxWidth: "24px" }}
                                            />
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    <span
                                        className={`${customCss && activeTab !== tab.id ? "text-dark1" : ""
                                            }`}
                                    >
                                        {tab.label}
                                    </span>
                                </button>
                            </li>
                        ))}
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        {tabs.map((tab) => (
                            <div
                                className={`tab-pane fade borderContainer ${isClicked && isAnimatedBorder ? "onLoad" : ""
                                    } ${activeTab === tab.id ? "show active" : ""}`}
                                id={`pills-${tab.id}`}
                                role="tabpanel"
                                aria-labelledby={`pills-${tab.id}-tab`}
                                tabIndex="0"
                                key={tab.id}
                            >
                                {showLoader || isLoading || isLoaded === false ? (
                                    <EpLoader />
                                ) : (
                                    <>{tab.content}</>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}
