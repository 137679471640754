import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import UserPanel from "../UserPanel";
import ApplicationsCarousel from "./ApplicationsCarousel";
import Pagination from "../../components/UI/Pagination";
import SearchBar from "../../components/UI/SearchBar";
import SelectItemsPerPage from "../../components/UI/SelectItemsPerPage";
//import SelectOption from "../../components/UI/SelectOption";
import StudentDataService from "../../Services/StudentService";
import FormatDate from "../../components/UI/FormatDate";
import GenerateApplication from "./GenerateApplication";
import SearchResult from "../Search/Internal/SearchResult";
import { toast } from "react-toastify";
import TokenService from "../../Services/TokenService";
import RolesService from "../../Services/RolesService";
import PageTitle from "../../components/PageTitle";
import FormatIntkeDate from "../../components/UI/FormatIntkeDate";
import EpLoader from "../../components/UI/EpLoader";
export default function Students({ selectedProgram, setIsAppSelected }) {
  const pageTitle = "Students";
  const getRole = TokenService.getUserRole();
  const { Counsellor, BranchHead } = RolesService;

  const [studentsList, setStudentList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const getRequestParams = (searchQuery, currentPage, itemsPerPage) => {
    let params = {};
    if (searchQuery) {
      params["search"] = searchQuery;
    }
    if (currentPage) {
      params["currentPage"] = currentPage;
    }

    if (itemsPerPage) {
      params["pageSize"] = itemsPerPage;
    }

    return params;
  };

  const retrieveStudents = useCallback(async () => {
    try {
      const params = getRequestParams(searchQuery, currentPage, itemsPerPage);
      const response = await StudentDataService.find(params);
      const students = response.data.response;

      setStudentList(students);
      setTotalItems(students[0]?.totalItems);
      setIsLoading(false);
    } catch (error) {
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      );
    }
  }, [searchQuery, currentPage, itemsPerPage]);

  useEffect(() => {
    retrieveStudents();
  }, [retrieveStudents]);

  const handlePageSizeChange = (event) => {
    event.preventDefault();
    setItemsPerPage(parseInt(event.target.value));
    setCurrentPage(1);
  };

  //const [studentApproach, setStudentApproach] = useState("All");
  // const handleStudnetApproachChange = (event) => {
  //   setStudentApproach(event.target.value);
  //   setCurrentPage(1);
  // };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isStudentSelected, setIsStudentSelected] = useState(false);
  const showProgramList = (student) => {
    setSelectedStudent(student);
    setIsStudentSelected(true);
  };
  if (isStudentSelected) {
    return (
      <SearchResult
        selectedStudent={selectedStudent}
        setSelectedStudent={setSelectedStudent}
        setIsStudentSelected={setIsStudentSelected}
      />
    );
  }
  if (isLoading) {
    return <EpLoader />;
  }

  return (
    <>
      <PageTitle title={pageTitle} />
      <div className="p-2 mt-2 position-relative">
        <div className="position-absolute top-0 start-50 translate-middle-x mt-3">
          <div className="text-center text1-4">
            {selectedProgram === undefined ? (
              ""
            ) : (
              <>
                <div>
                  Program:
                  <span className="font-bold text1-6">
                    {selectedProgram.collegeProgramName}{" "}
                  </span>
                </div>
                <div>
                  College:
                  <span className="font-bold text1-6">
                    {selectedProgram.collegeName}{" "}
                  </span>
                  Intake:
                  <span className="font-bold text1-6">
                    {selectedProgram.intakeList
                      .filter((intake) => intake.statusId === 3)
                      .sort((a, b) => new Date(a.date) - new Date(b.date)) // Sort by date
                      .map((intake, index, array) => (
                        <span key={intake.date} className="fw-bold">
                          <FormatIntkeDate date={intake.date} />
                          {index < array.length - 1 && <>,&nbsp;</>}
                        </span>
                      ))}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <div
                    className="btn-dark-red text-4 rounded-pill py-2 col-4"
                    onClick={() => {
                      setIsAppSelected(false);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Re-Select Program
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <UserPanel
          title={
            selectedProgram === undefined
              ? "Student Management"
              : "Select a Student"
          }
        />

        <div>
          <div
            className={`row align-items-baseline mx-2 mb-1 mt-${selectedProgram === undefined ? "3" : "5"
              }`}
          >
            <div className="col d-flex align-items-center column-gap-3">
              <SearchBar
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                setCurrentPage={setCurrentPage}
                searchTitle={"Search Student"}
              />
            </div>

            <div className="col">
              <div className="d-flex align-items-center justify-content-end text-dark1 column-gap-3">
                {/* <SelectOption handleOptionChange={handleStudnetApproachChange} options={["lead", "direct"]} /> */}
                <SelectItemsPerPage
                  handlePageSizeChange={handlePageSizeChange}
                />
              </div>
            </div>
          </div>

          <div className="mt-3 mb-4">
            {totalItems > 0 && (
              <Pagination
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                dataLength={totalItems}
                itemsPerPage={itemsPerPage}
              />
            )}
          </div>

          <div className="row">
            <div className="col-12">
              <div className="table-responsive rounded-4 text-center">
                <table className="table custom-table">
                  <thead className="bg-dark1 text-white">
                    <tr className="py-3 text1-3">
                      <th scope="col">Student ID</th>
                      <th scope="col">Name</th>
                      <th scope="col">Contact Details </th>
                      <th scope="col">Passport No.</th>
                      {!(getRole === Counsellor || getRole === BranchHead) && (
                        <th scope="col">Agent</th>
                      )}
                      <th scope="col">Agent User</th>
                      <th scope="col">
                        {selectedProgram === undefined
                          ? "Applications"
                          : "Start Applications"}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text1-4">
                    {studentsList.map((student, index) => (
                      <tr className="py-3" key={index}>
                        <td>
                          {selectedProgram === undefined ? (
                            <Link
                              to={"/user/updateStudent/" + student.id}
                              className="text-decoration-none font-bold text1-4"
                            >
                              <FormatDate
                                date={student.createdDate}
                                idNo={student.idNumber}
                              />
                            </Link>
                          ) : (
                            <>
                              <FormatDate
                                date={student.createdDate}
                                idNo={student.idNumber}
                              />
                            </>
                          )}
                        </td>
                        <td>
                          {student.salutation} {student.firstName}
                        </td>
                        <td>
                          <div>{student.email}</div>
                          <div>{student.contactNumber}</div>
                        </td>
                        <td>{student.passportNumber}</td>
                        {!(
                          getRole === Counsellor || getRole === BranchHead
                        ) && <td>{student.companyName}</td>}
                        <td className="text-center">
                          {student.userFirstName} {student.userLastName}
                        </td>

                        <td>
                          {selectedProgram === undefined ? (
                            <ApplicationsCarousel
                              currentStudentData={student}
                              showProgramList={showProgramList}
                              RolesService={RolesService}
                              getRole={getRole}
                            />
                          ) : (
                            <GenerateApplication
                              studentData={student}
                              programData={selectedProgram}
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {totalItems > 0 && (
                  <Pagination
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    dataLength={totalItems}
                    itemsPerPage={itemsPerPage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
