import React, { useCallback, useState } from "react";
import tv from "../../assets/icons/formtv.svg";
import globe from "../../assets/icons/formglobe.svg";
import PhoneNumberInput from "../../components/PhoneNumberInput";
import { counsellingService } from "../../Services/CounsellingFormService";
import { toast } from "react-toastify";
import ThankyouModal from "./ThankyouModal";
import Tooltip from "../../components/UI/ToolTip";
import { OverlayTrigger } from "react-bootstrap";
import { StyledTooltip } from "../../shared/components/StyledTooltip";

const CounsellingForm = () => {
  const [showThanksModal, setShowThanksModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    emailId: "",
    city: "",
  });
  const [resetPhone, setResetPhone] = useState(false);

  const handlePhoneNumberChange = useCallback((value) => {
    setFormData((prev) => ({
      ...prev,
      phoneNumber: value,
    }));
  }, []);

  const handleSubmitClick = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        if (!formData.phoneNumber) {
          toast.error("Phone Number is required");
          return;
        }
        const response = await counsellingService.sendDataToWhatsApp(formData);
        if (response.data.success === true) {
          setFormData({
            name: "",
            phoneNumber: "",
            emailId: "",
            city: "",
          });
          setResetPhone(true);
          setShowThanksModal(true);
        } else if (response.data.validationErrors) {
          response.data.validationErrors.forEach((verr) => toast.error(verr));
        } else {
          toast.error("Something Went Wrong");
        }
      } catch (error) {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
        );
      } finally {
        // window.location.reload()
      }
    },
    [formData]
  );

  return (
    <div className="border border-grey w-md-50" style={{ borderRadius: "8px" }}>
      <p
        className="bg-dark1 text-white text-center mb-0 p-3"
        style={{ borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}
      >
        INTERNATIONAL DEGREE NOW IN YOUR BUDGET
      </p>
      <div className="p-2 p-md-5 d-flex flex-column gap-3">
        <div>
          <p>Enter Your Details</p>
        </div>
        <div className="d-flex gap-4 d-md-none">
          <div className="d-flex align-items-center gap-2">
            <img src={tv} alt="tv" />
            <strong className="mb-0 text1-3">250+ Courses</strong>
          </div>
          <div className="d-flex align-items-center gap-2">
            <img src={globe} alt="globe" />
            <strong className="mb-0 text1-3">5 Countries</strong>
          </div>
        </div>
        <form
          className="d-flex flex-column  gap-3 w-100"
          onSubmit={handleSubmitClick}
        >
          <div className="col-12 form-floating">
            <input
              type="text"
              className="form-control rounded-pill "
              placeholder="Name*"
              required
              value={formData.name}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  name: e?.target?.value,
                }))
              }
            />
            <label htmlFor="floatingInput" className="ms-2 pt-2">
              Name<span className="text-danger">*</span>
            </label>
          </div>
          <Tooltip
            className="w-100 bg-light-blue text-black  border"
            tooltipText="Whatsapp number only"
            position="bottom"
          >
            <PhoneNumberInput
              customCSS="rounded"
              value={formData.phoneNumber}
              onChange={handlePhoneNumberChange}
              resetPhoneNumber={resetPhone}
            />
          </Tooltip>
          <div className="col-12 form-floating">
            <input
              type="email"
              className="form-control rounded-pill"
              placeholder="Email*"
              required
              value={formData.emailId}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  emailId: e?.target?.value,
                }))
              }
            />
            <label htmlFor="floatingInput" className="ms-2 pt-2">
              Email<span className="text-danger">*</span>
            </label>
          </div>
          <div className="col-12 form-floating">
            <input
              type="text"
              className="form-control rounded-pill"
              placeholder="City*"
              required
              value={formData.city}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  city: e?.target?.value,
                }))
              }
            />
            <label htmlFor="floatingInput" className="ms-2 pt-2">
              City<span className="text-danger">*</span>
            </label>
          </div>
          <button
            type="submit"
            className="d-flex align-items-center border-0 rounded justify-content-center bg-danger mx-2 mx-md-0 p-2 my-3 text-white"
          >
            <p className="mb-0 text1"> Click now for free counselling</p>
          </button>
        </form>
        <ThankyouModal
          showModal={showThanksModal}
          handleCloseModal={() => setShowThanksModal(false)}
        />
      </div>
    </div>
  );
};

export default CounsellingForm;
