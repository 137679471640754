import React from "react";
import { useNavigate } from "react-router-dom";

export default function AssosiateContents({ setModalShow }) {
  const navigate = useNavigate()
  return (
    <>
      <div className="text4 fw-bold">Associate with us</div>
      <div className="text1-8 pb-5">We want to establish a local presence for you and help students join your institute.</div>
      <button className="btn rounded-pill bg-dark1 text-white px-5 py-3 text2" onClick={() => navigate('/contact-us')}>Get Started</button>
    </>
  );
}
