import React from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import EditBlog from "./EditBlog";

export default function ListBlog({ dataList, handleDelete, getData }) {
  const formatDate = (datetime) => {
    const dateObj = new Date(datetime);
    const day = String(dateObj.getDate()).padStart(2, "0");
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const year = String(dateObj.getFullYear());
    return `${day}-${month}-${year}`;
  };
  return (
    <>
      <div>
        <Row xs={1} md={3} className="g-3">
          {dataList.map((data, idx) => (
            <Col key={idx}>
              <Card className="p-2">
                <Card.Img
                  variant="top"
                  src={data.image}
                  className="p-4 border-bottom"
                  style={{ height: "200px" }}
                />
                <Card.Body>
                  <Card.Title className="fw-bold">{data.title}</Card.Title>
                  <Card.Text>
                    Created on: {formatDate(data.createdDate)}{" "}
                  </Card.Text>
                  <Card.Text>
                    Updated on: {formatDate(data.modifiedDate)}{" "}
                  </Card.Text>
                </Card.Body>
                <Row className="mx-4">
                  <Col>
                    <Button
                      variant={`outline-${
                        data.statusId === 1 ? "success" : "secondary"
                      }`}
                      className="w-100 rounded-pill fs-5"
                    >
                      {data.statusId === 1 ? "Active" : "Inactive"}
                    </Button>
                  </Col>
                  <Col>
                    <EditBlog data={data} getData={getData} />
                  </Col>
                  <Col>
                    <Button
                      variant="outline-danger"
                      className="w-100 rounded-pill fs-5"
                      onClick={(e) => handleDelete(e, data)}
                    >
                      Delete
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
}
