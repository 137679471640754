import React from "react";
import Captcha from "../../components/Captcha";
import SubmitButton from "../../components/Form/SubmitButton";
import CountryList from "../../components/UI/CountriesList";
export default function SignUp2({
  showLogin,
  registerData,
  handleInputChange,
  handleRegister,
  loading,
  captchaResponse,
}) {
  const handleCountryChange = (value) => {
    if (value) {
      handleInputChange({ target: { name: "country", value: value } });
    }
  };

  // const captchaToLogin = (captchadata) => {
  //   setCaptchaValue(captchadata);
  // };

  // const onChangeCaptcha = (e) => {
  //   const iscaptcha = e.target.value;
  //   setIsCaptcha(iscaptcha);
  // };
  return (
    <>
      <form className="row g-3 needs-validation px-3" onSubmit={handleRegister}>
        <div className="col-12">
          <div className="form-floating px-3">
            <CountryList
              customCSS={false}
              value={registerData.country}
              onChange={handleCountryChange}
              required={true}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-floating px-3">
            <input
              type="text"
              className="form-control rounded-pill border ps-4 pb-1"
              placeholder="State*"
              required
              name="state"
              value={registerData.state}
              onChange={handleInputChange}
              pattern="^[a-zA-Z .]*$"
              autoComplete="off"
            />
            <label htmlFor="floatingInput" className="ms-4 pt-2">
              {registerData.country === "Canada" ? "Province" : "State"}
              <span className="text-danger">*</span>
            </label>
          </div>
        </div>
        <div
          className={`col-6 ${registerData.country === "Canada" ? "d-none" : ""
            }`}
        >
          <div className="form-floating px-3">
            <input
              type="text"
              className={`form-control rounded-pill border me-2 pb-1`}
              placeholder="District*"
              required
              name="district"
              value={registerData.district}
              onChange={handleInputChange}
              pattern="^[a-zA-Z .]*$"
              autoComplete="off"
              disabled={registerData.country === "Canada"}
            />
            <label htmlFor="floatingInput" className="ms-4 pt-2">
              District
              <span className="text-danger">*</span>
            </label>
          </div>
        </div>
        <div className="col-6">
          <div className="form-floating px-3">
            <input
              type="text"
              className="form-control rounded-pill border ps-4 pb-1"
              placeholder="City*"
              required
              name="city"
              value={registerData.city}
              onChange={handleInputChange}
              pattern="^[a-zA-Z .]*$"
              autoComplete="off"
            />
            <label htmlFor="floatingInput" className="ms-4 pt-2">
              City<span className="text-danger">*</span>
            </label>
          </div>
        </div>
        <div className="col-6">
          <div className="form-floating px-3">
            <input
              type="text"
              className="form-control rounded-pill border me-2 pb-1"
              placeholder="Postal Code*"
              required
              name="postalCode"
              value={registerData.postalCode}
              onChange={handleInputChange}
              minLength={6}
              pattern="^[a-zA-Z .0-9]*$"
              autoComplete="off"
            />
            <label htmlFor="floatingInput" className="ms-4 pt-2">
              Postal Code<span className="text-danger">*</span>
            </label>
          </div>
        </div>
        <div className="col-12">
          <div className="form-floating px-3">
            <input
              type="text"
              className="form-control rounded-pill border ps-4 pb-1"
              placeholder="Address*"
              required
              name="address"
              value={registerData.address}
              onChange={handleInputChange}
              autoComplete="off"
            />
            <label htmlFor="floatingInput" className="ms-4 pt-2">
              Address<span className="text-danger">*</span>
            </label>
          </div>
        </div>
        <div className="col-12">
          <div className="form-floating px-3">
            <input
              type="text"
              className="form-control rounded-pill border ps-4 pb-1"
              placeholder="Agency name*"
              required
              name="name"
              value={registerData.name}
              onChange={handleInputChange}
              pattern="^[a-zA-Z .-_&0-9]*$"
              autoComplete="off"
            />
            <label htmlFor="floatingInput" className="ms-4 pt-2">
              Agency name<span className="text-danger">*</span>
            </label>
          </div>
        </div>
        {/* <div className="col-12 px-4">
          <div className="d-flex align-items-center form-floating">
            <input
              type="text"
              className="form-control rounded-pill border ps-4 pb-1"
              placeholder="Captcha*"
              onChange={onChangeCaptcha}
              minLength={6}
              maxLength={6}
              required
              autoComplete="off"
            />
            <label htmlFor="floatingInput" className="ms-4 pt-2">
              Captcha<span className="text-danger">*</span>
            </label>
            <div className="" style={{ minWidth: "120px" }}>
              <Captcha captchaToAll={captchaToLogin} />
            </div>
          </div>
        </div> */}
        <div className="col-12">
          <div className="d-flex justify-content-center">
            <div className="form-check text1-3">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="invalidCheck"
                required
              />
              <label className="form-check-label" htmlFor="invalidCheck">
                Agree to terms and conditions
              </label>
              <div className="invalid-feedback">
                You must agree before submitting.
              </div>
            </div>
          </div>
        </div>
        {process.env.REACT_APP_ENVIRONMENT === "local" ? (
          <SubmitButton title={"Submit"} loading={loading} />
        ) : (
          ""
        )}
        {captchaResponse?.success === true && captchaResponse?.score > 0.2 ? (
          <div className={`col-10 mx-auto`}>
            <SubmitButton title={"Submit"} loading={loading} />
          </div>
        ) : (
          <div className={`col-10 mx-auto`}>
            <button
              type="button"
              className="gradiantButton rounded-pill py-3 font-bold fs-4 w-100 d-flex justify-content-center align-items-center"
              disabled
            >
              Submit
            </button>
          </div>
        )}

        {/* Existing user link */}
        <div className="col-12 text-center my-3 d-flex justify-content-center align-items-center">
          <p className="text1-4 font-bold me-2">Existing User?</p>
          <p
            className="text1-6 font-bold text-dark1 "
            style={{ cursor: "pointer" }}
            onClick={showLogin}
          >
            Login
          </p>
        </div>
      </form>
    </>
  );
}
