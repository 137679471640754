import React from "react";
import SectionsTitle from "../../pagesAgent/Components/SectionsTitle";
import filterIcon from "../../assets/icons/course.svg";

const WhyChooseUs = () => {
  return (
    <div className="my-5 py-5">
      <SectionsTitle title={"Why Choose us"} />
      <div className="row" data-aos="zoom-out">
        <div className="col-12 col-sm-6 p-5">
          <div className="d-flex gap-5">
            <img src={filterIcon} alt="" />{" "}
            <div className="d-flex flex-column justify-content-end">
              <p className="text1-7 fw-bold">Mold career path</p>
              <p>We mold carrer paths as student's individual profiles</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 p-5">
          <div className="d-flex gap-5">
            <img src={filterIcon} alt="" />{" "}
            <div className="d-flex flex-column justify-content-end">
              <p className="text1-7 fw-bold">Facilitate admission process</p>
              <p>
                Facilitate the admission process in global universities with
                utmost transparency
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 p-5">
          <div className="d-flex gap-5">
            <img src={filterIcon} alt="" />
            <div className="d-flex flex-column justify-content-end">
              <p className="text1-7 fw-bold">
                Accurate information on universities
              </p>
              <p>
                Provide accurate information about the world's leading
                educational institutions
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 p-5">
          <div className="d-flex gap-5">
            <img src={filterIcon} alt="" />{" "}
            <div className="d-flex flex-column justify-content-end">
              <p className="text1-7 fw-bold">Assist students</p>
              <p>
                Assist students in the application, course selection, interview,
                as well as visa and travel
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
