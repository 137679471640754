import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import pdfUrl from "../../assets/privacy_policy.pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

export default function Privacy() {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  return (
    <div className="d-flex justify-content-center">
      <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(Array(numPages).keys())?.map((x) => (
          <Page width={900} pageNumber={x + 1} key={x} />
        ))}
      </Document>
    </div>
  );
}
