import React, { useCallback, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import { toast } from "react-toastify";
import AuthService from "../Services/AuthService";
import apiURLandKEYS from "../Services/apiURLandKEYS";
import ForgetPassword from "../pages/Header/ForgetPassword";
import platformLogo from "../assets/logo_dark.png";
import Login from "../pages/Header/Login";
import SignUp1 from "../pages/Header/SignUp1";
import SignUp2 from "../pages/Header/SignUp2";
export default function LoginModal({ modalShow, setModalShow }) {
  const [captchaResponse, setCaptchaResponse] = useState(null);
  const resetReCaptchaCalled = useRef(false);
  const checkVerification = useCallback(async (token) => {
    try {
      const response = await AuthService.reCaptchaVerify(token);
      setCaptchaResponse(response.data);
      return;
    } catch (error) {
      toast.error(error);
    }
  }, []);
  const resetReCaptcha = useCallback(
    (token) => {
      if (!resetReCaptchaCalled.current) {
        checkVerification(token);
        resetReCaptchaCalled.current = true;
      }
    },
    [checkVerification]
  );
  const [submit, setSubmit] = useState(false);
  const [showSignUp1, setShowSignUp1] = useState(false);
  const [showSignUp2, setShowSignUp2] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  const [showForgetPassword, setShowForgetPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const handleLoginView = () => {
    setShowLogin(true);
    setShowSignUp1(false);
    setShowSignUp2(false);
  };
  const handleSignupView = () => {
    setShowLogin(false);
    setShowSignUp1(true);
    setShowSignUp2(false);
  };

  const handleForgetPasswordView = () => {
    setShowLogin(false);
    setShowSignUp1(false);
    setShowSignUp2(false);
    setShowForgetPassword(true);
  };

  const [registerData, setRegisterData] = useState("");

  function isRegisterDataValid(registerData) {
    // Define an array of properties to exclude from validation
    const excludeProps = ["website", "statusId", "logo"];

    // Check if any of the other properties in registerData is empty or null
    for (const key in registerData) {
      if (
        !excludeProps.includes(key) &&
        (registerData[key] === null ||
          registerData[key] === undefined ||
          registerData[key] === "")
      ) {
        return false; // If any non-excluded property is empty or null, return false
      }
    }
    return true; // If all non-excluded properties are valid, return true
  }

  const isValid = isRegisterDataValid(registerData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRegisterData({ ...registerData, [name]: value });
  };
  const handleRegister = (e) => {
    e.preventDefault();

    if (registerData.password === registerData.confirmPassword) {
      if (isValid) {
        setLoading(true);
        var data = {
          name: registerData.name,
          logo: registerData.logo,
          address: registerData.address,
          district:
            registerData.country === "Canada" ? "NA" : registerData.district,
          city: registerData.city,
          state: registerData.state,
          country: registerData.country,
          statusId: 0,
          website: registerData.website,
          postalCode: registerData.postalCode,
          companyAdmin: {
            firstName: registerData.firstName,
            lastName: registerData.lastName,
            email: registerData.email,
            confirmEmail: registerData.confirmEmail,
            companyId: registerData.companyId,
            password: registerData.password,
            confirmPassword: registerData.confirmPassword,
            phoneNumber: registerData.phoneNumber,
            phoneNumberConfirmed: registerData.phoneNumber,
          },
        };

        AuthService.register(data)
          .then((response) => {
            if (response.data.success === true) {
              setMessage(response.data.message);
              setRegisterData("");
              setShowLogin(true);
              setShowSignUp1(false);
              setShowSignUp2(false);
              setShowForgetPassword(false);
              setLoading(false);
              setSubmit(true);
            } else if (response.data.validationErrors) {
              response.data.validationErrors.map((verr) => toast.error(verr));
              setLoading(false);
            } else {
              toast.error("Something Went Wrong");
              setLoading(false);
            }
          })
          .catch((e) => {
            toast.error(e.message);
            setLoading(false);
          });
      } else {
        toast.warn("Fill required fields");
      }
    } else {
      toast.error("Confirm password does not macthed");
    }
  };

  const handleSubmitedClose = () => {
    setSubmit(false);
    setModalShow(false);
  };

  return (
    <>
      <Modal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        size="md"
        className="custom-modal"
      >
        <Modal.Body className="">
          <div className="">
            <div className="d-flex justify-content-end me-3 mt-2">
              <i
                className="bi bi-x-circle-fill text2 p-3 text-danger position-absolute end-0 top-0"
                style={{ cursor: "pointer" }}
                onClick={() => setModalShow(false)}
              ></i>
            </div>
            <div className="d-flex flex-column align-items-center p-5 gap-5">
              <img src={platformLogo} alt="platformLogo" height="80px" />
            </div>
            <div>
              <div className="">
                <div className="">
                  <div className="text-center text-dark1 text2-5 font-bold">
                    {submit ? (
                      <div className="text2-2">{message}</div>
                    ) : showLogin ? (
                      "Login to your account"
                    ) : showSignUp1 || showSignUp2 ? (
                      "Sign Up"
                    ) : showForgetPassword ? (
                      "Forget Password"
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                {submit ? (
                  ""
                ) : showSignUp2 ? (
                  <div className="d-flex align-items-center text1-6">
                    <div
                      className="bg-white text-orange border d-flex p-2 justify-content-center align-items-center rounded-circle"
                      style={{
                        width: "34px",
                        height: "34px",
                        cursor: "pointer",
                      }}
                      onClick={handleSignupView}
                    >
                      1
                    </div>
                    <div className="border-top" style={{ width: "53px" }}></div>
                    <div
                      className="bg-orange text-white border d-flex p-2 justify-content-center align-items-center rounded-circle"
                      style={{ width: "34px", height: "34px" }}
                    >
                      2
                    </div>
                  </div>
                ) : showSignUp1 ? (
                  <div className="d-flex align-items-center text1-6">
                    <div
                      className="bg-orange text-white border d-flex p-2 justify-content-center align-items-center rounded-circle"
                      style={{ width: "34px", height: "34px" }}
                    >
                      1
                    </div>
                    <div className="border-top" style={{ width: "53px" }}></div>
                    <div
                      className="bg-white text-orange border d-flex p-2 justify-content-center align-items-center rounded-circle"
                      style={{ width: "34px", height: "34px" }}
                    >
                      2
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="mt-2 d-flex justify-content-center">
                {submit ? (
                  <>
                    <div className="text-center px-5">
                      {/* <i className="bi bi-envelope-check text6 text-normal-green"></i> */}
                      {/* <div className="text2-5">{registerData.email}</div> */}

                      <div className="text-center text1-5 my-3">
                        Our team is reviewing your company details and will get
                        in touch with you shortly. Please keep a check on your
                        mail.
                      </div>
                      <div className="d-flex justify-content-center my-4">
                        <button
                          type="button"
                          className=" gradiantButton text-white rounded-pill py-2 px-5 my-2 text1-4  gradientButton"
                          onClick={handleSubmitedClose}
                        >
                          Okay
                        </button>
                      </div>
                    </div>
                  </>
                ) : showSignUp2 ? (
                  <SignUp2
                    showLogin={handleLoginView}
                    showSignup={handleSignupView}
                    handleInputChange={handleInputChange}
                    registerData={registerData}
                    handleRegister={handleRegister}
                    loading={loading}
                    captchaResponse={captchaResponse}
                  />
                ) : showSignUp1 ? (
                  <SignUp1
                    showLogin={handleLoginView}
                    showSignup2={setShowSignUp2}
                    handleInputChange={handleInputChange}
                    registerData={registerData}
                    captchaResponse={captchaResponse}
                  />
                ) : showLogin ? (
                  <>
                    <Login
                      showSignUp={handleSignupView}
                      forgetPassword={handleForgetPasswordView}
                      captchaResponse={captchaResponse}
                    />
                  </>
                ) : showForgetPassword ? (
                  <ForgetPassword
                    showLogin={handleLoginView}
                    setModalShow={setModalShow}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            <GoogleReCaptcha onVerify={resetReCaptcha} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
