import React, { useState } from "react";
import "./WorkFeatures.css";
import work1 from "../../assets/agents/grey-marble-column-details-building.jpg";
import work2 from "../../assets/agents/business-proposal-purchase-hands-holding-money.jpg";
import work3 from "../../assets/agents/man-giving-business-presentation-using-high-technology-digital-pen.jpg";
import work4 from "../../assets/agents/instructor-helping-students-computer-class2.jpg";
import TokenService from "../../Services/TokenService";
import { Accordion } from "react-bootstrap";

const AuthWorkFeaturesData = [
  {
    title: "Assess",
    icon: "clipboard-data", // Icon for the first section
    description: [
      "Get access to Hundreds of Global Universities.",
      "Access exclusive programs and services.",
      "Connect your students to our partner",
      "universities and institutions.",
    ],
    image: work1,
  },
  {
    title: "Commission",
    icon: "hand-index-thumb", // Icon for the second section
    description: [
      "Agents will surely receive the highest commission.",
      "Recruiters will have quick and easy access to their commissions.",
      "Recruiting a large volume of students for a single institution will reward them with additional bonuses.",
    ],
    image: work2,
  },
  {
    title: "Growth",
    icon: "bar-chart-line", // Icon for the third section
    description: ["Growing student network.", "Allows mutual growth."],
    image: work3,
  },
  {
    title: "Help Students",
    icon: "chat-right-quote", // Icon for the fourth section
    description: [
      "Render unparalleled experience to students and their parents. ",
      "Choosing the right program as per the academic qualification of the students.",
      "Get information about scholarships and internships.",
    ],
    image: work4,
  },
];
const WorkFeaturesData = [
  {
    title: "Assess",
    icon: "clipboard-data", // Icon for the first section
    description: [
      "Get access to Hundreds of Global Universities.",
      "Access exclusive programs and services.",
      "Connect your students to our partner",
      "universities and institutions.",
    ],
    image: work1,
  },
  {
    title: "Commission",
    icon: "hand-index-thumb", // Icon for the second section
    description: [
      "Partners will surely receive the highest commission.",
      "Recruiters will have quick and easy access to their commissions.",
      "Recruiting a large volume of students for a single institution will reward them with additional bonuses.",
    ],
    image: work2,
  },
  {
    title: "Growth",
    icon: "bar-chart-line", // Icon for the third section
    description: ["Growing student network.", "Allows mutual growth."],
    image: work3,
  },
  {
    title: "Help Students",
    icon: "chat-right-quote", // Icon for the fourth section
    description: [
      "Render unparalleled experience to students and their parents. ",
      "Choosing the right program as per the academic qualification of the students.",
      "Get information about scholarships and internships.",
    ],
    image: work4,
  },
];
export default function Section2() {
  const token = TokenService.getLocalAccessToken();
  const isAuth = Boolean(token);
  const workFeaturesData = isAuth ? AuthWorkFeaturesData : WorkFeaturesData;
  const [activeKey, setActiveKey] = useState(null);

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  return (
    <div className="">
      <section className="features my-3 my-sm-5">
        <div className="d-none d-sm-flex bg-secondary bg-opacity-10  justify-content-center align-items-center rounded-5 py-5">
          <div className="w-100 px-5">
            <ul
              className="nav nav-tabs px-5 d-flex flex-column flex-md-row align-items-center"
              role="tablist"
            >
              {workFeaturesData.map((item, index) => (
                <li
                  key={index}
                  className="col-3 mb-1 d-flex justify-content-center align-items-center"
                  role="presentation"
                >
                  <a
                    className={`nav-link border-0 rounded-4 d-flex justify-content-center align-items-center px-3 ${
                      index === 0 ? "active show" : ""
                    }`}
                    data-bs-toggle="tab"
                    href={`#tab-${index + 1}`}
                    aria-selected={index === 0 ? "true" : "false"}
                    role="tab"
                  >
                    <i className={`text3 pt-3 me-3 bi bi-${item.icon}`}></i>

                    {/* Icon is now taken from the data array */}
                    <h4 className="text2">{item.title}</h4>
                  </a>
                </li>
              ))}
            </ul>

            <div
              className="tab-content aos-init aos-animate mt-5 px-5"
              data-aos="fade-up"
            >
              {workFeaturesData.map((item, index) => (
                <div
                  key={index}
                  className={`tab-pane ${index === 0 ? "active show" : ""}`}
                  id={`tab-${index + 1}`}
                  role="tabpanel"
                >
                  <div className="row px-md-5">
                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 ps-5">
                      {item.description.map((line, idx) => (
                        <div key={idx} className="check-double-icon-item mb-4">
                          <i className="bi bi-check2-all text-dark1 me-2 text2"></i>
                          <span className="text1-6">{line}</span>
                        </div>
                      ))}
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2 text-center">
                      <img
                        src={item.image}
                        alt=""
                        className="img-fluid rounded-4 shadow"
                        style={{ width: "420px" }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="d-block d-sm-none">
          <Accordion activeKey={activeKey} onSelect={handleToggle}>
            {workFeaturesData.map((item, index) => (
              <Accordion.Item
                className="border-0"
                key={index}
                eventKey={index.toString()}
              >
                <Accordion.Header
                  className="custom-accordion-header"
                  onClick={() => handleToggle(index.toString())}
                >
                  <div className="d-flex gap-4 align-items-center">
                    <div
                      className={`border-0 bg-dark1 rounded-4 d-flex justify-content-center align-items-center p-3 `}
                      data-bs-toggle="tab"
                      role="tab"
                    >
                      <i className={`text2 text-white bi bi-${item.icon}`}></i>
                    </div>
                    <span className="text1-8">{item.title}</span>
                  </div>

                  <span className="ms-auto">
                    <i
                      className={
                        activeKey === index.toString()
                          ? "bi bi-dash-lg text2"
                          : "bi bi-plus-lg text2"
                      }
                    />
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row px-5 ">
                    <div className="col-lg-6 order-2 order-lg-1 mt-4  ps-5">
                      {item.description.map((line, idx) => (
                        <div key={idx} className="check-double-icon-item mb-4">
                          <i className="bi bi-check2-all text-dark1 me-2 text2"></i>
                          <span className="text1-6">{line}</span>
                        </div>
                      ))}
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2 text-center ps-4">
                      <img
                        src={item.image}
                        alt=""
                        className="img-fluid rounded-4 shadow "
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </section>
    </div>
  );
}
