import React from "react";
import AssosiateContents from "./AssosiateContents";
import Carousel from "./Carousel";
export default function Section1({ setModalShow }) {
  return (
    <>
      <div className="container pt-5">
        <div
          className="row justify-content-between align-items-center"
          style={{ minHeight: "80vh", paddingTop: "120px" }}
        >
          <div className="col-12 col-md-5">
            <AssosiateContents setModalShow={setModalShow} />
          </div>
          <div className="col-12 col-md-6 pt-5">
            <Carousel />
          </div>
        </div>
      </div>
    </>
  );
}
