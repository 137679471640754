import http from "./api";

const get = (id) => {
  return http.get(`Student?StudentId=${id}`);
};
const remove = (id) => {
  return http.delete(`Student/${id}`);
};
const create = (data) => {
  return http.post("Student", data);
};
const update = (data) => {
  return http.put(`Student`, data);
};
const find = (params) => {
  return http.get("Student/search?", { params });
};

const StudentDataService = {
  get,
  remove,
  create,
  update,
  find,
};

export default StudentDataService;
