import React from "react";
import { Modal } from "react-bootstrap";
import platformLogo from "../../assets/logo_dark.png";


const ConfirmationModal = ({ show, onHide, onConfirm, message, title }) => {
  return (
    <Modal show={show}
      onHide={onHide}
      size="md"
      className="custom-modal">
      <Modal.Body className="fs-4">
        <i
          className="bi bi-x-circle-fill text2 p-3 text-danger position-absolute end-0 top-0"
          style={{ cursor: "pointer" }}
          onClick={onHide}
        ></i>
        <div className="d-flex flex-column align-items-center pt-5 gap-5">
          <img src={platformLogo} alt="platformLogo" height="80px" />
          <p className="text2 text-dark1 fw-bold">{title}</p>
        </div>
        <p className="text-center text-light-gray fw-bold">
          {message}
        </p>
      </Modal.Body>
      <div className="d-flex justify-content-center gap-4 mb-5">
        <button onClick={onHide} className="btn border-dark1 rounded-pill py-2 px-5 text1-3">
          Cancel
        </button>
        <button onClick={onConfirm} className="gradiantButton border-0 rounded-pill py-2 px-5 text1-3">
          Confirm
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
