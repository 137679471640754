import React from "react";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

export default function SpinnerBorder() {
  return (
    <div className="d-flex justify-content-center align-items-center fs-2 mt-5">
      <div>
        <Spinner animation="border" variant="primary" />
      </div>
      <div className="ms-3">Please Wait...</div>
    </div>
  );
}
