import { toast } from "react-toastify";
import TokenService from "./TokenService";
import api from "./api";

const register = (data) => {
  return api.post("Registration/companyRegistration", data);
};

const login = async (email, password) => {
  try {
    const response = await api.post("Auth/authenticate", {
      email,
      password,
    });

    if (response.data.success == false) {
      toast.error(response.data.message);
    } else if (response.data.response.token) {
      TokenService.setUser(response.data.response);
    }
    return response.data;
  } catch (error) {
    throw new Error("Invalid username or password");
  }
};

const logout = () => {
  TokenService.removeUser();
};
const changepassword = (data) => {
  return api.post("Auth/changepassword", data);
};
const forgetPassword = (data) => {
  return api.post("Auth/forgetPassword", data);
};
const resetPassword = (data) => {
  return api.post("Auth/resetPassword", data);
};
const reCaptchaVerify = (token) => {
  return api.post(`Auth/reCaptchaVerify?token=${token}`);
};

const AuthService = {
  register,
  login,
  logout,
  changepassword,
  forgetPassword,
  resetPassword,
  reCaptchaVerify,
};
export default AuthService;
