import React, { useState, useEffect } from "react";
import countriesData from "../../Services/CountriesList.json";

const CountryList = ({ customCSS, value: initialValue, onChange, disabled, required }) => {
  const [country, setCountry] = useState(initialValue);

  useEffect(() => {
    if (onChange) {
      onChange(country);
    }
  }, [country]);

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setCountry(newValue);
  };

  return (
    <>
      <select
        className={`form-select rounded-pill border ${customCSS ? "shadow" : ""}`}
        aria-label="Country"
        value={country}
        onChange={handleInputChange}
        name="country"
        disabled={disabled}
        required={required}
      >
        <option value="">Country</option>
        {countriesData.map((country) => (
          <option key={country.code} value={country.name}>
            {country.name}
          </option>
        ))}
      </select>
      <label htmlFor="floatingInput" className="ms-4">
        Country<span className="text-danger">*</span>
      </label>
    </>
  );
};

export default CountryList;
