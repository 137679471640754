import React, { useCallback, useEffect, useState } from "react";
import GicServices from "../../Services/GicServices";
import { toast } from "react-toastify";
import TokenService from "../../Services/TokenService";
import RolesService from "../../Services/RolesService";
import { Spinner } from "react-bootstrap";
import "./MarketPlace.css";

export default function UploadGicDOcument({
  id,
  type,
  label,
  filePath,
  getData,
}) {
  const getRole = TokenService.getUserRole();
  const [fileLink, setFileLink] = useState("");
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const { BranchHead, Accounts } = RolesService;

  useEffect(() => {
    const file = filePath?.split("/");
    if (file?.[2]) {
      setFileName(file[2]);
    }
  }, [filePath]);

  const handleInputChange = (event) => {
    setLoading(true);
    const { type, files } = event.target;
    if (type === "file") {
      const file = files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          handleSaveInvoice({
            name: file.name,
            file: reader.result.split(",")[1],
          });
          setFileName(file.name);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleSaveInvoice = useCallback(
    async ({ name, file }) => {
      try {
        const body = {
          applicantId: id,
          documentType: type,
          fileName: name,
          file,
        };
        const response = await GicServices.UploadInvoice(body);
        if (response.data.success === true) {
          getData();
          toast.success(`${label} Uploaded Successfully`);
        } else if (response.data.validationErrors) {
          response.data.validationErrors.forEach((verr) => toast.error(verr));
        } else {
          toast.error("Something Went Wrong");
        }
      } catch (error) {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
        );
      }
      setLoading(false);
    },
    [getData, id, label, type]
  );
  const downloadDocument = useCallback(async () => {
    try {
      const params = {
        applicantId: id,
        documentType: type,
      };
      const response = await GicServices.DownloadDocument(params);
      setFileLink(response?.data?.response?.fileLink);
    } catch (error) {
      toast.error(error);
    }
  }, [id, type]);

  useEffect(() => {
    if (id && type) {
      downloadDocument();
    }
  }, [BranchHead, downloadDocument, getRole, id, type]);

  return (
    <>
      {getRole === Accounts ? (
        <div className="border rounded-pill p-2 d-flex align-items-center">
          {loading ? (
            <div className="w-100 d-flex justify-content-center">
              {" "}
              <Spinner />
            </div>
          ) : (
            <>
              <label
                htmlFor={`invoiceUpload${label}`}
                className="d-flex flex-grow-1 justify-content-between align-items-center px-1 overflow-hidden"
              >
                <p className="mb-0 file-name">
                  {fileName ? fileName : `Upload ${label}`}
                </p>
                <i
                  style={{
                    cursor: "pointer",
                  }}
                  class={`${
                    fileName
                      ? "bi bi-arrow-counterclockwise"
                      : "bi bi-cloud-arrow-up-fill"
                  } text2 text-dark1`}
                ></i>
              </label>
              <input
                type="file"
                id={`invoiceUpload${label}`}
                onChange={handleInputChange}
                className="d-none"
              />
              {fileName ? (
                <a
                  href={fileLink}
                  target="_blank"
                  download=""
                  rel="noopener noreferrer"
                >
                  <i
                    style={{
                      cursor: "pointer",
                    }}
                    class="bi bi-file-earmark-text-fill text1-6 mx-2 text-dark1"
                  ></i>
                </a>
              ) : null}
            </>
          )}
        </div>
      ) : null}

      {getRole === BranchHead ? (
        <div className="border rounded-pill p-2">
          <label
            htmlFor="invoiceUpload"
            className="d-flex justify-content-between align-items-center px-1"
          >
            <p className="mb-0">Download {label}</p>

            <a
              href={fileLink}
              target="_blank"
              download=""
              rel="noopener noreferrer"
            >
              <i class="bi bi-download text1-8 text-dark1"></i>
            </a>
          </label>
        </div>
      ) : null}
    </>
  );
}
