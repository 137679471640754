import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import sortIcon from "../../assets/icons/sort.svg";
import UserPanel from "../UserPanel";
import Pagination from "../../components/UI/Pagination";
import SearchBar from "../../components/UI/SearchBar";
import SelectItemsPerPage from "../../components/UI/SelectItemsPerPage";
import SelectOption from "../../components/UI/SelectOption";
import ApplicationDataService from "../../Services/ApplicationService";
import FormatDate from "../../components/UI/FormatDate";
import TokenService from "../../Services/TokenService";
import RolesService from "../../Services/RolesService";
import UserDataService from "../../Services/UserService";
import { toast } from "react-toastify";
import ApplicationStatusList from "../../Services/ApplicationStatusList";
import Tooltip from "../../components/UI/ToolTip";
import ApplicationStatusButton from "./ApplicationStatusButton";
import PageTitle from "../../components/PageTitle";
import FormatIntkeDate from "../../components/UI/FormatIntkeDate";
import ConfirmationModal from "../../components/UI/ConfirmationModal";
import EpLoader from "../../components/UI/EpLoader";
import DetailModal from "./DetailModal";
import AttachProffModal from "./AttachProffModal";

const ApplicationstatusValues = Object.values(ApplicationStatusList);
const filteredOptions = ApplicationstatusValues.filter(
  (option) => option !== "Draft"
);

const STUDENT_EXAMS = [
  {
    name: "All",
    value: "all",
  },
  {
    name: "IELTS",
    value: "IELTS",
  },
  { name: "TOEFL", value: "TOEFL" },
  { name: "PTE", value: "PTE" },
  { name: "Duolingo", value: "Duolingo" },
];

export default function Applications() {
  const navValue = useLocation();
  const getRole = TokenService.getUserRole();
  const {
    AdmissionOfficer,
    AgencyManager,
    Counsellor,
    BranchHead,
    TeamLeader,
    SuperAdmin,
  } = RolesService;

  const [EPAssigneeUsers, setEPAssigneeUsers] = useState();
  const retrieveEPAssigneeUsers = async () => {
    try {
      const response = await UserDataService.getEPAssignee();
      setEPAssigneeUsers(response.data);
    } catch (error) {
      toast.error(error);
    }
  };

  const [isUpdating, setUpdating] = useState(false);
  const handleEPAssigneeUserChange = async (aID, epID) => {
    try {
      setUpdating(true);
      const data = {
        studentApplicationId: aID,
        epAssigneeUserId: epID,
      };

      const response =
        await ApplicationDataService.assignStudentApplicationToEPAssignee(data);
      toast.success(response.data.message);
      retrieveApplications();
      setUpdating(false);
    } catch (error) {
      toast.error(error);
    }
  };

  const [applicationsList, setApplicationsList] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const [applicationStatusName, setApplicationStatusName] = useState(
    navValue.state?.newLabelName ?? ""
  );

  const [selectedExam, setSelectedExam] = useState("all");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [applicationIdtoDelete, setApplicationIdtoDelete] = useState("");

  const [openProffModal, setOpenProffModal] = useState(false);
  const [proofData, setProffData] = useState(false);

  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [modalData, setModalData] = useState();
  const [modalType, setModalType] = useState("");

  const getRequestParams = (
    searchQuery,
    currentPage,
    itemsPerPage,
    applicationStatusName
  ) => {
    setIsLoading(true);
    let params = {};

    if (searchQuery) {
      params["search"] = searchQuery;
    }
    if (currentPage) {
      params["currentPage"] = currentPage;
    }

    if (itemsPerPage) {
      params["pageSize"] = itemsPerPage;
    }
    if (applicationStatusName) {
      params["applicationStatus"] = applicationStatusName;
    }
    if (getRole === SuperAdmin && selectedExam !== "all") {
      params["englishProficiencyTest"] = selectedExam;
    }

    return params;
  };

  const retrieveApplications = async () => {
    try {
      const params = getRequestParams(
        searchQuery,
        currentPage,
        itemsPerPage,
        applicationStatusName
      );
      let response;
      if (getRole === AgencyManager || getRole === SuperAdmin) {
        response =
          await ApplicationDataService.getAllStudentApplications_forAllCompaniesAndStudents(
            params
          );
      } else if (getRole === TeamLeader) {
        response =
          await ApplicationDataService.getStudentApplicationByTeamLeaderUserId(
            params
          );
      } else if (getRole === AdmissionOfficer) {
        response =
          await ApplicationDataService.getStudentApplicationByEPAssigneeUserId(
            params
          );
      } else {
        response = await ApplicationDataService.find(params);
      }

      if (response.data.success === true) {
        setTotalItems(response.data.response[0]?.totalItems);
        setApplicationsList(response.data.response);
        //toast.success(response.data.message);
      } else if (response.data.validationErrors.length > 0) {
        response.data.validationErrors.map((verr) => toast.error(verr));
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString()
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageSizeChange = (event) => {
    event.preventDefault();
    setItemsPerPage(parseInt(event.target.value));
    setCurrentPage(1);
  };

  const handleStatusChange = (event) => {
    setApplicationStatusName(event.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleExamChange = useCallback((e) => {
    setSelectedExam(e.target.value);
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    retrieveApplications();
  }, [
    searchQuery,
    applicationStatusName,
    currentPage,
    itemsPerPage,
    selectedExam,
  ]);

  useEffect(() => {
    retrieveEPAssigneeUsers();
  }, []);

  const handleApplicationStatusChange = async (application, status) => {
    try {
      setUpdating(true);
      if (status === "Offer Letter Applied") {
        setProffData(application);
        setOpenProffModal(true);
        return;
      }

      const data = {
        id: application?.id,
        applicationStatus: status,
        remarks: "",
      };

      const response = await ApplicationDataService.updateStatus(data);

      if (response.data.success === true) {
        toast.success(response.data.message);
        await retrieveApplications();
      } else if (response.data.validationErrors) {
        response.data.validationErrors.forEach((verr) => toast.error(verr));
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString()
      );
    } finally {
      setUpdating(false);
    }
  };

  const handleDeleteDraftApplication = useCallback(async () => {
    try {
      const response = await ApplicationDataService.deleteDraftApplication(
        applicationIdtoDelete
      );
      if (response.data.success === true) {
        toast.success(response.data.message);
        await retrieveApplications();
      } else if (response.data.validationErrors) {
        response.data.validationErrors.forEach((verr) => toast.error(verr));
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString()
      );
    } finally {
      setOpenDeleteModal(false);
    }
  }, [applicationIdtoDelete]);

  if (isLoading) {
    return <EpLoader />;
  }

  return (
    <>
      <PageTitle title="Applications" />
      <div className="p-2 mt-2">
        <div className="row align-items-center">
          <div className="col">
            <UserPanel title={"Applications"} />
          </div>
        </div>

        <>
          <div className="row align-items-baseline mt-3 mx-2 mb-1">
            <div className="col d-flex align-items-center column-gap-3">
              <SearchBar
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                searchTitle={"Search Applications"}
              />
              {getRole === SuperAdmin ? (
                <div className="d-flex align-items-center justify-content-end text-dark1 column-gap-3 w-50">
                  <div className="text1-4 text-dark1">
                    <img src={sortIcon} alt="sortIcon" className="img-fluid" />
                  </div>
                  <div className="text1-4 text-dark1 w-100 position-relative">
                    <label className="position-absolute text1-1 form-label">
                      English Proficiency Exam
                    </label>
                    <select
                      className="form-select rounded-4 text1-3 py-2"
                      value={selectedExam}
                      aria-label="proficiency test"
                      onChange={handleExamChange}
                    >
                      {STUDENT_EXAMS?.map((option, index) => (
                        <option value={option.value} key={index}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="col">
              <div className="d-flex align-items-center justify-content-end text-dark1 column-gap-3">
                <div className="text1-4 text-dark1">
                  <img src={sortIcon} alt="sortIcon" className="img-fluid" />
                </div>
                <div className="text1-4 text-dark1 position-relative">
                  <label className="position-absolute text1-1 form-label">
                    Application Status
                  </label>
                  <select
                    className="form-select rounded-4 text1-3 py-2"
                    value={applicationStatusName}
                    aria-label="lead sort"
                    onChange={handleStatusChange}
                  >
                    <option value={""}>All</option>
                    {ApplicationstatusValues?.map((option, index) => (
                      <option value={option} key={index}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <SelectItemsPerPage
                  handlePageSizeChange={handlePageSizeChange}
                  itemsPerPage={itemsPerPage}
                />
              </div>
            </div>
          </div>

          <div className="mt-3 mb-4">
            {totalItems > 0 && (
              <Pagination
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                dataLength={totalItems}
                itemsPerPage={itemsPerPage}
              />
            )}
          </div>

          <div className="row">
            <div className="col-12">
              <div className="table-responsive rounded-4">
                <table className="table custom-table">
                  <thead className="bg-dark1 text-white text-center">
                    <tr className="py-1 text1-3">
                      <th scope="col">App ID</th>
                      <th scope="col">Name</th>
                      <th scope="col"> Campus/ Country</th>
                      <th scope="col">College Name/ Program/ Intake</th>
                      {!(getRole === Counsellor || getRole === BranchHead) && (
                        <th scope="col">Agent</th>
                      )}

                      <th scope="col">Agent User</th>
                      {getRole !== AdmissionOfficer && (
                        <th scope="col">Admission Officer</th>
                      )}
                      {getRole === AgencyManager ||
                      getRole === AdmissionOfficer ? (
                        <th scope="col">Team Leader</th>
                      ) : (
                        ""
                      )}
                      <th scope="col">Status</th>
                      {getRole === AgencyManager ? (
                        ""
                      ) : (
                        <th scope="col" className="pe-5">
                          Notes
                        </th>
                      )}
                      {getRole === BranchHead || getRole === Counsellor ? (
                        <th scope="col" className="pe-5">
                          Action
                        </th>
                      ) : (
                        ""
                      )}
                    </tr>
                  </thead>
                  <tbody className="text1-4 text-center">
                    {applicationsList &&
                      applicationsList
                        // .filter(
                        //   (application) =>
                        //     !(
                        //       (getRole === AgencyManager && application.applicationStatus === "Draft") ||
                        //       (getRole === AdmissionOfficer && application.applicationStatus === "Draft") ||
                        //       (getRole === TeamLeader && application.applicationStatus === "Draft")
                        //     )
                        // )
                        .map((application, index) => (
                          <tr className="" key={index}>
                            <td className="text-center">
                              <Link
                                to={"/user/viewApplication/" + application.id}
                                className="text-decoration-none font-bold text1-4"
                              >
                                <FormatDate
                                  date={application.createdDate}
                                  idNo={application.idNumber}
                                />
                              </Link>
                            </td>
                            {getRole === AdmissionOfficer ||
                            getRole === TeamLeader ||
                            getRole === SuperAdmin ? (
                              <td className="text-center">
                                <Link
                                  to={
                                    "/user/updateStudent/" +
                                    application.studentId
                                  }
                                  className="text-decoration-none font-bold text1-4"
                                >
                                  {application.studentFirstName}{" "}
                                  {application.studentLastName}
                                </Link>
                              </td>
                            ) : (
                              <td>
                                {application.studentFirstName}{" "}
                                {application.studentLastName}
                              </td>
                            )}

                            <td>
                              <div>{application.campus}</div>
                              <div>{application.country}</div>
                            </td>
                            <td>
                              <div>{application.collegeName}</div>
                              <div>{application.collegeProgramName}</div>
                              <div>
                                <FormatIntkeDate date={application.intake} />
                              </div>
                            </td>
                            {!(
                              getRole === Counsellor || getRole === BranchHead
                            ) && (
                              <td className="text-center">
                                {application.agentName}
                              </td>
                            )}

                            <td className="text-center">
                              <div
                                className="text-dark1"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setOpenDetailsModal(true);
                                  setModalType("agent");
                                  setModalData(application);
                                }}
                              >
                                {application.userFirstName}{" "}
                                {application.userLastName}
                              </div>
                            </td>

                            {getRole !== AdmissionOfficer && (
                              <td>
                                <div className="d-flex justify-content-center align-items-center">
                                  {getRole === AgencyManager ? (
                                    <select
                                      className="form-select py-2 rounded-pill text1-4 ps-3"
                                      aria-label="EPAssigne select"
                                      style={{ width: "14rem" }}
                                      value={application.epAssigneeUserId}
                                      onChange={(e) =>
                                        handleEPAssigneeUserChange(
                                          application.id,
                                          e.target.value
                                        )
                                      }
                                      disabled={isUpdating}
                                    >
                                      {application.epAssigneeUserId ===
                                        "00000000-0000-0000-0000-000000000000" && (
                                        <option
                                          value="00000000-0000-0000-0000-000000000000"
                                          disabled
                                        >
                                          No Admission Officer Selected
                                        </option>
                                      )}
                                      {EPAssigneeUsers?.map((epa, index) => (
                                        <option value={epa.id} key={index}>
                                          {epa.firstName} {epa.lastName}
                                        </option>
                                      ))}
                                    </select>
                                  ) : (
                                    EPAssigneeUsers?.map((epa, index) => {
                                      if (
                                        epa.id === application.epAssigneeUserId
                                      ) {
                                        return (
                                          <div
                                            className="text-dark1"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              setOpenDetailsModal(true);
                                              setModalType("admission officer");
                                              setModalData(epa);
                                            }}
                                          >
                                            {epa.firstName} {epa.lastName}
                                          </div>
                                        );
                                      } else {
                                        <span>
                                          NO Admission Officer selected
                                        </span>;
                                      }
                                    })
                                  )}
                                </div>
                              </td>
                            )}
                            {getRole === AgencyManager ||
                            getRole === AdmissionOfficer ? (
                              <td scope="col">
                                {application.teamLeaderFirstName}{" "}
                                {application.teamLeaderLastName}
                              </td>
                            ) : (
                              ""
                            )}

                            <td>
                              <div className="d-flex justify-content-center align-items-center">
                                {getRole === AdmissionOfficer ? (
                                  <select
                                    className={`form-select py-2 rounded-pill text1-4 ps-4`}
                                    aria-label="Status Select"
                                    style={{ minWidth: "14rem" }}
                                    name="applicationStatus"
                                    value={application.applicationStatus}
                                    onChange={(e) => {
                                      handleApplicationStatusChange(
                                        application,
                                        e.target.value
                                      );
                                    }}
                                  >
                                    <>
                                      {isUpdating ? (
                                        <option>Please Wait...</option>
                                      ) : (
                                        filteredOptions.map((option, index) => (
                                          <option value={option} key={index}>
                                            {option}
                                          </option>
                                        ))
                                      )}
                                    </>
                                  </select>
                                ) : (
                                  <ApplicationStatusButton
                                    AppStatus={application.applicationStatus}
                                    ApplicationStatusList={
                                      ApplicationStatusList
                                    }
                                  />
                                )}
                              </div>
                            </td>

                            {getRole === AgencyManager ? (
                              ""
                            ) : (
                              <td className="">
                                {getRole === BranchHead ? (
                                  <Link
                                    to={"/user/viewNotes/" + application.id}
                                    className="text-decoration-none font-bold text1-4 position-relative"
                                  >
                                    {application.unreadNotes > 0 && (
                                      <div
                                        className="d-flex justify-content-center align-items-center position-absolute mt-2 translate-middle badge rounded-pill bg-dark1 text1"
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                          right: "50%",
                                        }}
                                      >
                                        {application.unreadNotes}
                                      </div>
                                    )}
                                    <div>
                                      <i
                                        class="bi bi-journal-text text-dark2"
                                        style={{
                                          fontSize: "30px",
                                          marginLeft: "-20px",
                                        }}
                                      ></i>
                                    </div>
                                  </Link>
                                ) : (
                                  <Link
                                    to={"/user/viewNotes/" + application.id}
                                    className="text-decoration-none font-bold text1-4 position-relative"
                                  >
                                    View Notes
                                  </Link>
                                )}
                              </td>
                            )}
                            {getRole === BranchHead ||
                            getRole === Counsellor ? (
                              <td>
                                <button
                                  className={`btn ${
                                    application.applicationStatus === "Draft"
                                      ? "btn-dark-red"
                                      : ""
                                  } rounded-circle`}
                                  style={{ width: "38px", height: "38px" }}
                                  disabled={
                                    application.applicationStatus !== "Draft"
                                  }
                                  onClick={() => {
                                    setOpenDeleteModal(true);
                                    setApplicationIdtoDelete(application.id);
                                  }}
                                >
                                  <i className="bi bi-trash3 text2 d-flex justify-content-center align-items-center"></i>
                                </button>
                              </td>
                            ) : (
                              ""
                            )}
                          </tr>
                        ))}
                  </tbody>
                </table>
                {totalItems > 0 && (
                  <Pagination
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    dataLength={totalItems}
                    itemsPerPage={itemsPerPage}
                  />
                )}
                <ConfirmationModal
                  show={openDeleteModal}
                  onHide={() => setOpenDeleteModal(false)}
                  title="Delete application?"
                  message="Are you sure you want to delete this draft application?"
                  onConfirm={handleDeleteDraftApplication}
                />
                {openDetailsModal && (
                  <DetailModal
                    show={openDetailsModal}
                    onHide={() => setOpenDetailsModal(false)}
                    modalData={modalData}
                    modalType={modalType}
                  />
                )}
                <AttachProffModal
                  show={openProffModal}
                  onHide={() => setOpenProffModal(false)}
                  proofData={proofData}
                  retrieveApplications={retrieveApplications}
                />
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
}
