import React from "react";
import { Routes, Route } from "react-router-dom";
import AppCMS from "./AppCMS";
import { Toaster } from "react-hot-toast";
import MainBlog from "./MainEp/Blogs/MainBlog";
import MainColleges from "./CommonAPI/Colleges/MainColleges";
import MainPrograms from "./CommonAPI/Programs/MainPrograms";
import MainIntakes from "./CommonAPI/Intakes/MainIntakes";
import MainPromoCode from "./CommonAPI/PromoCode/MainPromoCode";
import Announcements from "./CommonAPI/Announcements/Announcements";

function RoutesCMS() {
  return (
    <div>
      <Routes>
        <Route path="/cms" element={<AppCMS />}>
          <Route path="common-api/colleges" element={<MainColleges />} />
          <Route path="common-api/programs" element={<MainPrograms />} />
          <Route path="common-api/intakes" element={<MainIntakes />} />

          <Route path="common-api/promo-code" element={<MainPromoCode />} />

          <Route path="main-ep/blogs" element={<MainBlog />} />
          <Route path="common-api/announcements" element={<Announcements />} />
        </Route>
      </Routes>
      <div>
        <Toaster reverseOrder={false} containerClassName="fs-4" />
      </div>
    </div>
  );
}

export default RoutesCMS;
