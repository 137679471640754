import React from "react";
import bg_gradient from "../../assets/landingPage/bg_gradient.svg";
import courseIcon from "../../assets/icons/course.svg";
import applicationIcon from "../../assets/icons/application.svg";
import internshipIcon from "../../assets/icons/internship.svg";
export default function Section3() {
  return (
    <>
      <div className="position-relative">
        <img
          src={bg_gradient}
          alt="leftBG"
          className="position-absolute img-fluid z-n1"
        />
      </div>
      <div className="container mb-5">
        <div className="row gap-2 gap-md-0">
          <div className="col-12 text-center my-5 sections-title font-bold">
            <span className="text-dark0">Ways we can </span>
            <span className="text-dark1">benefit you</span>
          </div>
          <div className="col-12 col-md-4">
            <div className="card p-3 rounded-4 h-100">
              <div className="card-body">
                <img
                  src={courseIcon}
                  className="img-fluid mb-2"
                  alt="iconComputer"
                />
                <div className="card-title text-dark0 section3-card-title">
                  <strong>Course & University Selection</strong>
                </div>
                <p className="card-text text-dark2 text1-3">
                  With so many options available students may be overwhelmed and
                  find difficulty in choosing the best course. Our educational
                  consultants help students pick the best course that ideally
                  matches their qualifications and desires.
                </p>
                <a
                  className="text-dark1 link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 position-absolute bottom-0 mb-3 text1-3"
                  href="/"
                >
                  {/* <strong>Learn More</strong> */}
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card p-3 rounded-4 h-100">
              <div className="card-body">
                <img
                  src={internshipIcon}
                  className="img-fluid mb-2"
                  alt="iconComputer"
                />
                <div className="card-title text-dark0 section3-card-title">
                  <strong>Internship & Scholarship Assistance</strong>
                </div>
                <p className="card-text text-dark2 text1-3">
                  Receive a hefty scholarship from the universities that are
                  offering them based on your eligibility.
                </p>
                <a
                  className="text-dark1 link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 position-absolute bottom-0 mb-3 text1-3"
                  href="/"
                >
                  {/* <strong>Learn More</strong> */}
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card p-3 rounded-4 h-100">
              <div className="card-body">
                <img
                  src={applicationIcon}
                  className="img-fluid mb-2"
                  alt="iconComputer"
                />
                <div className="card-title text-dark0 section3-card-title">
                  <strong>Institute Application Process</strong>
                </div>
                <p className="card-text text-dark2 text1-3">
                  We do not just help students choose the best program but we
                  also guide them through the application process making sure
                  that their application form is correctly and aptly filled with
                  all the essential documents attached.
                </p>
                <a
                  className="text-dark1 link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 position-absolute bottom-0 mb-3 text1-3"
                  href="/"
                >
                  {/* <strong>Learn More</strong> */}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
