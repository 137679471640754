import React from "react";
import compareIcon from "../../assets/icons/compare.svg";
export default function CompareButton({ selectedProgramsLength, onClick }) {
  return (
    <>
      <div
        className="text1-4 text-dark1 position-relative "
        style={{ cursor: "pointer" }}
        disabled={selectedProgramsLength < 2 || selectedProgramsLength > 0}
        onClick={onClick}
      >
        <img src={compareIcon} alt="compareIcon" className="img-fluid" />
        Compare
        {selectedProgramsLength > 0 ? (
          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success text1-1">
            {selectedProgramsLength}
            <span className="visually-hidden">selected program length</span>
          </span>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
