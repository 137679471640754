import React, { forwardRef } from "react";
import ToolTip from "../../components/UI/ToolTip";

const DocumentsAgent = forwardRef(
  (
    {
      documentList,
      documentData,
      handleStudentDocumentsInputChange,
      saveStudentDocumentsData,
      handleDeleteDocument,
      handleDownload,
      getRole,
      RoleService,
      isSaving,
      allDraftApplication,
      currentApplication,
    },
    ref
  ) => {
    const { Counsellor, AdmissionOfficer, BranchHead, SuperAdmin } =
      RoleService;
    return (
      <>
        <div>
          <div className="table-responsive rounded-4">
            <table className="table custom-table">
              <tbody className="text1-4">
                {currentApplication.epAssigneeUserId ===
                "00000000-0000-0000-0000-000000000000" ? (
                  <div className="text-center text2 p-4">
                    No Admission Officer is selected. To upload document
                    Admission Officer is required
                  </div>
                ) : getRole === BranchHead ||
                  getRole === Counsellor ||
                  getRole === SuperAdmin ? (
                  <tr className="py-3 text-center">
                    <td>
                      <form className="row w-100" id="upload-docs-form">
                        <div className="col-7">
                          <input
                            className="form-control rounded-pill text1-3"
                            type="text"
                            id="title"
                            name="title"
                            placeholder="Add New Document"
                            value={documentData.title}
                            onChange={handleStudentDocumentsInputChange}
                            required
                            disabled={isSaving}
                          />
                        </div>
                        <div className="col-4">
                          <ToolTip tooltipText="pdf file only" position="left">
                            <input
                              className="form-control rounded-pill text1-3"
                              ref={ref}
                              type="file"
                              id="upload"
                              name="upload"
                              onChange={handleStudentDocumentsInputChange}
                              accept="application/pdf"
                              required
                              disabled={isSaving}
                            />
                          </ToolTip>
                        </div>
                        <div className="col-1">
                          <button
                            className="btn rounded-4 btn-dark-blue d-flex align-items-center justify-content-center text1-4"
                            type="submit"
                            //style={{ minWidth: "140px" }}
                            onClick={saveStudentDocumentsData}
                            disabled={
                              isSaving ||
                              documentData.title === undefined ||
                              documentData.title === ""
                            }
                          >
                            <span>
                              {documentData.title !== undefined && isSaving ? (
                                ""
                              ) : (
                                <i className="bi bi-upload text2"></i>
                              )}
                            </span>
                            {documentData.title !== undefined && isSaving && (
                              <span className="spinner-border"></span>
                            )}
                          </button>
                        </div>
                      </form>
                    </td>
                  </tr>
                ) : null}

                <>
                  {documentList.length > 0
                    ? documentList.map((dl, index) => {
                        if (dl.roleName !== AdmissionOfficer) {
                          return (
                            <tr className="py-3 text-center" key={index}>
                              <td className="d-flex align-items-center justify-content-between">
                                <div className="text1-6 font-bold ms-5">
                                  {dl.title}
                                </div>
                                <div className="d-flex align-items-center justify-content-center me-5">
                                  <button
                                    className="btn rounded-4 btn-success d-flex align-items-center justify-content-center text1-4 me-3"
                                    type="button"
                                    //value={dl.id}
                                    onClick={(e) => handleDownload(e, dl)}
                                    //disabled={viewing}
                                  >
                                    {/* {viewing ? (
                                    <>
                                      <span className="spinner-border"></span>
                                    </>
                                  ) : (
                                    <i className="bi bi-file-earmark-text text2"></i>
                                  )} */}
                                    <i className="bi bi-file-earmark-text text2"></i>
                                  </button>
                                  <button
                                    className={`btn rounded-4 d-flex align-items-center justify-content-center text1-4 ${
                                      allDraftApplication === false
                                        ? "btn-secondary"
                                        : "btn-danger"
                                    }`}
                                    type="button"
                                    // style={{ minWidth: "140px" }}
                                    value={dl.id}
                                    onClick={handleDeleteDocument}
                                    disabled={allDraftApplication === false}
                                  >
                                    <i className="bi bi-trash3 text2"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        } else {
                          return null;
                        }
                      })
                    : ""}
                </>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
);

export default DocumentsAgent;
