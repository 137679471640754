import { useCallback, useRef, useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-hot-toast";
import addIcon from "../../../assets/icons/add.svg";
import UploadLogo from "./UploadLogo";

const countries = [
  "Australia",
  "Canada",
  "India",
  "Ireland",
  "New Zealand",
  "UK",
  "USA",
];

export default function AddColleges({
  pageTitle,
  getData,
  initializeData,
  dataService,
}) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [validated, setValidated] = useState(false);
  const logoRef1 = useRef(null);
  const logoRef2 = useRef(null);
  const [CollegesData, setCollegesData] = useState(initializeData);
  const [IsSaving, setIsSaving] = useState(false);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setCollegesData((prevData) => ({ ...prevData, [name]: value }));
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);

    if (form.checkValidity() === false) {
      event.stopPropagation();
      return; // Exit the function if the form is invalid
    }

    setIsSaving(true);
    try {
      const loadingMessage = toast.loading("Creating...");

      const payload = {
        name: CollegesData.name,
        country: CollegesData.country,
        insertAuthor: CollegesData.insertAuthor,
        province: CollegesData.province,
        website: CollegesData.website,
        refundUrl: CollegesData.refundUrl,
        applicationFee: CollegesData.applicationFee,
        logo: CollegesData.logo,
        detailPageLogoName: CollegesData.detailPageLogoName,
        detailPageLogo: CollegesData.detailPageLogo,
        logoName: CollegesData.logoName,
        qsRanking: CollegesData.qsRanking,
        webometricScore: CollegesData.webometricScore,
        statusId: CollegesData.statusId,
        directIndirect: CollegesData.directIndirect,
        score: CollegesData.score,
        regionsRestricted: CollegesData.regionsRestricted
          .filter((el) => Boolean(el.region) && Boolean(el.regionName))
          .map((el) => {
            const { region, regionName } = el;
            return {
              [region]: regionName,
            };
          }),
      };

      const response = await dataService.create(payload);

      if (response.data.success === true) {
        getData();
        toast.success(response.data.message, { id: loadingMessage });
        setCollegesData(initializeData);
        setValidated(false);
        setShow(false);
        setIsSaving(false);
      } else if (
        response.data.success === false &&
        response.data.response === false
      ) {
        toast.error(response.data.message, { id: loadingMessage });
        setIsSaving(false);
      } else if (
        response.data.success === false &&
        response.data.validationErrors.length > 0
      ) {
        response.data.validationErrors.map((verr) =>
          toast.error(verr, { id: loadingMessage })
        );
        setIsSaving(false);
      } else {
        setIsSaving(false);
        toast.error("Something Went Wrong", { id: loadingMessage });
      }
    } catch (error) {
      console.log(error);
      setIsSaving(false);
      toast.error("An error occurred");
    }
  };

  const handleregionsRestricted = useCallback(
    (e, index) => {
      const { name, value } = e.target;
      if (name === "region") {
        const newregionsRestricted = [...CollegesData.regionsRestricted];
        newregionsRestricted[index].region = value;
        setCollegesData((prev) => ({
          ...prev,
          regionsRestricted: newregionsRestricted,
        }));
      } else if (name === "regionName") {
        const newregionsRestricted = [...CollegesData.regionsRestricted];
        newregionsRestricted[index].regionName = value;
        setCollegesData((prev) => ({
          ...prev,
          regionsRestricted: newregionsRestricted,
        }));
      }
    },
    [CollegesData.regionsRestricted]
  );

  const handleAddRegion = useCallback(() => {
    setCollegesData((prev) => ({
      ...prev,
      regionsRestricted: [
        ...CollegesData.regionsRestricted,
        { region: "", regionName: "" },
      ],
    }));
  }, [CollegesData.regionsRestricted]);

  const handleDeleteRegion = useCallback(
    (index) => {
      const newRegions = CollegesData.regionsRestricted.filter(
        (e, idx) => idx !== index
      );
      setCollegesData((prev) => ({
        ...prev,
        regionsRestricted: newRegions,
      }));
    },
    [CollegesData.regionsRestricted]
  );

  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
        className="py-2 px-4 fs-5 rounded-pill"
      >
        Add New {pageTitle}
      </Button>

      <Modal show={show} onHide={handleClose} className="mt-5" size="lg">
        <Modal.Header closeButton={!IsSaving}>
          <Modal.Title>Add {pageTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            className="px-3"
          >
            <Row xs={12} md={12} className="g-3">
              <FloatingLabel controlId="floatingInput" label="Name">
                <Form.Control
                  type="text"
                  placeholder="name"
                  name="name"
                  required
                  value={CollegesData.name}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide Name
                </Form.Control.Feedback>
              </FloatingLabel>
              <Col xs={6} md={4}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Application Fee"
                >
                  <Form.Control
                    type="number"
                    placeholder="applicationFee"
                    name="applicationFee"
                    required
                    value={CollegesData.applicationFee}
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide Application Fee
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col xs={6} md={4}>
                <FloatingLabel controlId="floatingInput" label="qsRanking">
                  <Form.Control
                    type="number"
                    placeholder="qsRanking"
                    name="qsRanking"
                    required
                    value={CollegesData.qsRanking}
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide QS Ranking
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col xs={6} md={4}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="webometricScore"
                >
                  <Form.Control
                    type="number"
                    placeholder="webometricScore"
                    name="webometricScore"
                    required
                    value={CollegesData.webometricScore}
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide Webometric Score
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col xs={6} md={4}>
                <FloatingLabel
                  controlId="floatingSelect"
                  label="Select Country"
                >
                  <Form.Select
                    aria-label="Floating label select country"
                    defaultValue={CollegesData.country}
                    name="country"
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Country</option>
                    {countries.map((country) => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please provide Country
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col xs={6} md={4}>
                <FloatingLabel controlId="floatingInput" label="Province">
                  <Form.Control
                    type="text"
                    placeholder="province"
                    name="province"
                    required
                    value={CollegesData.province}
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide Province
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>

              <Col xs={6} md={6}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Direct Indirect"
                >
                  <Form.Select
                    aria-label="Select Direct Indirect"
                    value={CollegesData.directIndirect}
                    name="directIndirect"
                    onChange={handleInputChange}
                    required
                  >
                    <option value={""} disabled>
                      Please select Direct Indirect
                    </option>
                    <option value={"Indirect"}>Indirect</option>
                    <option value={"Direct"}>Direct</option>
                  </Form.Select>

                  <Form.Control.Feedback type="invalid">
                    Please provide Direct Indirect
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>

              <Col xs={6} md={"auto"}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Score"
                  className="mb-1"
                >
                  <Form.Control
                    type="number"
                    placeholder="College Score"
                    name="score"
                    required
                    value={CollegesData.score}
                    onChange={handleInputChange}
                    min={1}
                    max={100}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide valid college score
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Row className="mt-3 mb-1">
                <Col xs={6} md={6}>
                  <div className="d-flex flex-column  justify-content-center">
                    <p className="text1-3 text-light-gray mb-0">Card Logo</p>
                    <UploadLogo
                      label={"logo"}
                      data={CollegesData}
                      setData={setCollegesData}
                      fileInputRef={logoRef1}
                    />
                  </div>
                </Col>
                <Col xs={6} md={6}>
                  <div className="d-flex flex-column  justify-content-center">
                    <p className="text1-3 text-light-gray mb-0">
                      Detail Page Logo
                    </p>
                    <UploadLogo
                      label={"detailPageLogo"}
                      data={CollegesData}
                      setData={setCollegesData}
                      fileInputRef={logoRef2}
                    />
                  </div>
                </Col>
              </Row>
              {CollegesData?.regionsRestricted?.map((region, index) => {
                return (
                  <Row className="mt-3">
                    <Col xs={6} md={5}>
                      <FloatingLabel
                        controlId="floatingSelect"
                        label="Excluded Application Region (optional)"
                      >
                        <Form.Select
                          aria-label="Select Application Region"
                          name="region"
                          onChange={(e) => handleregionsRestricted(e, index)}
                          value={region.region}
                        >
                          <option value="" disabled>
                            Select Application Region
                          </option>
                          <option value={"country"}>Country</option>
                          <option value={"state"}>State</option>
                          {/* <option value={"area"}>Area</option> */}
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    <Col xs={6} md={6}>
                      <FloatingLabel
                        controlId="floatingInput"
                        label={"Country/State Name"}
                      >
                        <Form.Control
                          type="text"
                          placeholder={"Country/State Name"}
                          name="regionName"
                          value={region.regionName}
                          onChange={(e) => handleregionsRestricted(e, index)}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col
                      className="d-flex align-items-center add-region-container gap-2"
                      xs={6}
                      md={1}
                    >
                      <img
                        src={addIcon}
                        className="h-50"
                        onClick={handleAddRegion}
                        alt="add-row"
                      />
                      {CollegesData.regionsRestricted.length > 1 ? (
                        <img
                          src={addIcon}
                          className="h-50 rotate-add"
                          onClick={() => handleDeleteRegion(index)}
                          alt="delete-row"
                        />
                      ) : null}
                    </Col>
                  </Row>
                );
              })}

              <FloatingLabel controlId="floatingInput" label="refundUrl">
                <Form.Control
                  as="textarea"
                  aria-label="refundUrl"
                  required
                  name="refundUrl"
                  value={CollegesData.refundUrl}
                  style={{ height: "100px" }}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide refund URL
                </Form.Control.Feedback>
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="Website">
                <Form.Control
                  as="textarea"
                  aria-label="website"
                  required
                  name="website"
                  value={CollegesData.website}
                  style={{ height: "100px" }}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide Website
                </Form.Control.Feedback>
              </FloatingLabel>
              <Modal.Footer className="mt-4">
                <Button
                  variant="secondary"
                  onClick={handleClose}
                  disabled={IsSaving}
                >
                  Close
                </Button>
                <Button type="submit" variant="success" disabled={IsSaving}>
                  save
                </Button>
              </Modal.Footer>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
