import React, { useEffect, useState } from "react";
import FormatDate from "../../components/UI/FormatDate";
import FormatDOB from "../../components/UI/FormatDOB";
import requestIcon from "../../assets/icons/requestIcon.svg";
import "./Welcome.css";
import RequestEdit from "./RequestEdit";
import TokenService from "../../Services/TokenService";
import RolesService from "../../Services/RolesService";
import { OverlayTrigger } from "react-bootstrap";
import { StyledTooltip } from "../../shared/components/StyledTooltip";
//import StudentDataService from "../../Services/StudentService";
export default function WelcomeBanner({ data, studentId, show }) {
  //const id = studentId;

  const [isData, setIsData] = useState(false);
  const [editRequest, setEditRequest] = useState(false);
  // const [currentStudent, setCurrentStudent] = useState([]);
  // const getStudent = (id) => {
  //   StudentDataService.get(id)
  //     .then((response) => {
  //       setCurrentStudent(response.data);
  //     })
  //     .catch((e) => {
  //       toast.error((e.response && e.response.data && e.response.data.message) || e.message || e.toString());
  //     });
  // };
  // useEffect(() => {
  //   if (id) getStudent(id);
  // }, [id]);
  const getRole = TokenService.getUserRole();
  const { BranchHead } = RolesService;

  useEffect(() => {
    if (data && data.id !== null) {
      setIsData(true);
    } else {
      setIsData(false);
    }
  }, [data]);

  const hasAllValues =
    data &&
    data.salutation !== "" &&
    data.firstName !== "" &&
    data.lastName !== "" &&
    data.dateofBirth !== "" &&
    data.nationality !== "" &&
    data.currentStatus !== "" &&
    data.gender !== "" &&
    data.maritalStatus !== "" &&
    data.contactNumber !== "" &&
    data.email !== "" &&
    data.address !== "" &&
    data.country !== "" &&
    data.state !== "" &&
    data.city !== "" &&
    data.postalCode !== "" &&
    data.permanentAddress !== "" &&
    data.permanentCountry !== "" &&
    data.permanentState !== "" &&
    data.permanentCity !== "" &&
    data.permanentPostalCode !== "" &&
    data.passportNumber !== "" &&
    data.passportExpiryDate !== "" &&
    data.previousRefusal !== "" &&
    data.emergencyContactName !== "" &&
    data.emergencyContactNumber !== "" &&
    data.emergencyEmail !== "" &&
    data.relation !== "";

  const minHeight = isData && hasAllValues ? "160px" : "60px";
  return (
    <>
      <RequestEdit
        modalShow={editRequest}
        id={data.id}
        setModalShow={setEditRequest}
      />
      <div
        className="bg-dark1 text-white rounded-5 mb-4 p-3"
        style={{ minHeight: minHeight }}
      >
        <div className="row text1-4 py-4 px-5">
          <div className="col-9 text2-5 mb-1 font-bold">
            Welcome{" "}
            {isData && hasAllValues && (
              <>
                {data.salutation ? data.salutation + "." : ""}

                {data.firstName && data.lastName ? (
                  <span className="text-decoration-underline text-uppercase">
                    {data.firstName + " " + data.lastName}
                  </span>
                ) : (
                  ""
                )}
              </>
            )}{" "}
            to Education Planner
          </div>
          {show && getRole === BranchHead && (
            <button
              className="col edit-button btn btn-sm d-flex justify-content-end rounded-circle border-0"
              onClick={() => setEditRequest(true)}
              style={{ width: "40px", height: "40px", outline: "none" }}
            >
              <OverlayTrigger
                placement="top"
                overlay={StyledTooltip({
                  text: "Request Edit",
                })}
              >
                <img
                  src={requestIcon}
                  alt="request"
                  className="img-fluid "
                  style={{ width: "40px", height: "40px" }}
                />
              </OverlayTrigger>
            </button>
          )}

          {isData && hasAllValues && (
            <div className="row">
              <div className="col text1-4">
                <div className="font-bold">Student ID</div>
                <div>
                  {data.createdDate && (
                    <FormatDate date={data.createdDate} idNo={data.idNumber} />
                  )}
                </div>
              </div>
              <div className="col text1-4">
                <div className="font-bold">DOB</div>
                <div>
                  {data.dateofBirth && <FormatDOB date={data.dateofBirth} />}
                </div>
              </div>
              <div className="col text1-4">
                <div className="font-bold">Passport No</div>
                <div>{data.passportNumber}</div>
              </div>
              <div className="col text1-4">
                <div className="font-bold">Mobile</div>
                <div>{data.contactNumber}</div>
              </div>
              <div className="col text1-4">
                <div className="font-bold">Email</div>
                <div>{data.email}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
