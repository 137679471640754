import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
// Componenets
import PageTitle from "../../../components/PageTitle";
import ConfirmationModal from "../../Components/ConfirmationModal";
import AdvancePagination from "../../Components/Pagination";
import SearchBar from "../../Components/SearchBar";
import SelectItemsPerPage from "../../Components/SelectItemsPerPage";
import SpinnerBorder from "../../Components/SpinnerBorder";
import SpinnerGrow from "../../Components/SpinnerGrow";
// Page Functions
import AddColleges from "./AddColleges";
import ListColleges from "./ListColleges";
// Services
import CollegesService from "../../Services/CollegesService";

export default function MainColleges() {
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [itemsLength, setItemsLength] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const [CollegesData, setCollegesData] = useState([initializeData]);

  const getCollegesData = async () => {
    setUpdating(true);
    try {
      const data = {
        search: searchQuery,
        currentPage,
        pageSize,
      };
      const response = await CollegesService.find(data);
      const responseData = response.data.response;
      const modifiedResponseArray = responseData?.map((college) => {
        return {
          ...college,
          regionsRestricted: college?.regionsRestricted?.map((region) => {
            return {
              region: Object.keys(region)?.[0],
              regionName: region?.[Object.keys(region)?.[0]],
            };
          }),
        };
      });
      setCollegesData(modifiedResponseArray);
      setLoading(false);
      setItemsLength(responseData[0]?.totalItems);
    } catch (error) {
      toast.error(error);
    } finally {
      setUpdating(false);
    }
  };

  useEffect(() => {
    getCollegesData();
  }, [searchQuery, currentPage, pageSize, pageTitle]);

  const handleSearchInput = (e) => {
    e.preventDefault();

    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const clearSearchQuery = () => {
    setSearchQuery("");
  };

  const handlePageSizeChange = (event) => {
    event.preventDefault();
    setPageSize(parseInt(event.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [itemToConfirm, setItemToConfirm] = useState(null);

  const handleDelete = (e, data) => {
    e.preventDefault();
    setItemToConfirm(data);
    setShowConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    setShowConfirmation(false);
    if (itemToConfirm) {
      try {
        await toast.promise(CollegesService.remove(itemToConfirm.id), {
          loading: "Deleting...",
          success: <b> {itemToConfirm.name} deleted successfully!</b>,
          error: <b>Could not delete {itemToConfirm.name}</b>,
        });
        setItemToConfirm(null);
        getCollegesData(); // updates the data after deletion
      } catch (error) {
        toast.error(error);
      }
    }
  };
  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
    setItemToConfirm(null);
  };

  if (loading) return <SpinnerGrow />;

  return (
    <>
      <PageTitle title={pageTitle} />
      <div className="row px-5">
        <div className="text-center fs-1 text-uppercase col-12">
          {pageTitle}
        </div>
        <div className="col-6">
          <AddColleges
            pageTitle={pageTitle}
            initializeData={initializeData}
            getData={getCollegesData}
            dataService={CollegesService}
          />
        </div>
        <div className="col-6 d-flex justify-content-end">
          <SearchBar
            searchQuery={searchQuery}
            handleSearchInput={handleSearchInput}
            clearSearchQuery={clearSearchQuery}
            searchTitle={`Search ${pageTitle}`}
          />
          <SelectItemsPerPage handlePageSizeChange={handlePageSizeChange} />
        </div>
        {updating ? (
          <SpinnerBorder />
        ) : CollegesData.length === 0 ? (
          <div className="mt-5 text-center fs-1">No Data Found</div>
        ) : (
          <div className="col-12 p-4">
            <ListColleges
              dataList={CollegesData}
              handleDelete={handleDelete}
              getData={getCollegesData}
              pageTitle={pageTitle}
              dataService={CollegesService}
            />
            <AdvancePagination
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              totalItems={itemsLength}
              pageSize={pageSize}
            />
            <ConfirmationModal
              show={showConfirmation}
              onHide={handleCloseConfirmation}
              onConfirm={handleConfirmDelete}
              message={
                itemToConfirm
                  ? `All Programs will be removed which are associated with "${itemToConfirm.name}". Are you sure want to delete "${itemToConfirm.name}" college?`
                  : ""
              }
              title={itemToConfirm?.name}
            />
          </div>
        )}
      </div>
    </>
  );
}

const pageTitle = "Colleges";

const initializeData = {
  name: "",
  country: "",
  insertAuthor: "",
  province: "",
  website: "",
  refundUrl: "",
  applicationFee: "",
  logo: "",
  logoName: "",
  qsRanking: "",
  webometricScore: "",
  statusId: 0,
  score: null,
  directIndirect: "",
  regionsRestricted: [
    {
      region: "",
      regionName: "",
    },
  ],
};
