import React, { useCallback, useEffect, useRef, useState } from "react";
import UserPanel from "../UserPanel";
import useStudentStore from "../../stores/StudentStore";
import "./MarketPlace.css";
import PhoneNumberInput from "../../components/PhoneNumberInput";
import MarketPlaceService from "../../Services/MarketPlaceService";
import { toast } from "react-toastify";
import { Modal, Spinner } from "react-bootstrap";
import platformLogo from "../../assets/logo_dark.png";
import { useLocation, useNavigate } from "react-router-dom";

const pageTitle = "Apply Now";
export default function ApplyForm() {
  const navValue = useLocation();
  const isExisting = navValue.state?.existing;
  const { studentDetails, setStudentDetails } = useStudentStore();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (studentDetails.name && !isExisting) {
      const name = studentDetails.name.split(" ");
      setStudentDetails({
        firstName: name[0],
        lastName: name[1],
      });
    }
  }, [isExisting, setStudentDetails, studentDetails.name]);

  const [resetPhoneNumber, setResetPhoneNumber] = useState(false);
  const [show, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handlePhoneNumberChange = (value) => {
    if (value) {
      studentDetails.contactNumber = value;
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setStudentDetails({ [name]: value });
  };

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        setLoading(true);

        const { name, ...details } = studentDetails;
        const response = await MarketPlaceService.create(details);
        if (response.data.success === true) {
          setStudentDetails({
            name: "",
            email: "",
            studentId: "",
            firstName: "",
            lastName: "",
            bank: "",
            passport: "",
            contactNumber: "",
            gicReferenceNumber: "",
          });
          studentDetails.contactNumber = "";
          setResetPhoneNumber(true);
          setShowModal(true);
        } else if (response.data.validationErrors) {
          response.data.validationErrors.forEach((verr) => toast.error(verr));
        } else {
          toast.error("Something Went Wrong");
        }
      } catch (error) {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
        );
      } finally {
        setLoading(false);
      }
    },
    [setStudentDetails, studentDetails]
  );

  return (
    <>
      <div className="py-2 px-4 mt-2">
        <div className="row align-items-center">
          <div className="col">
            <UserPanel title={pageTitle} />
          </div>
          <div className="shadow form-container px-0 mt-5">
            <div className=" d-flex justify-content-center col text2-2 bg-dark1 text-white py-4 form-header">
              GIC Form Details
            </div>
            <form className="needs-validation px-5 " onSubmit={handleSubmit}>
              <div className="row gy-5  gx-3 py-5 px-5 d-flex justify-content-center">
                <div className="col-5">
                  <p className=" text-dark1 text1-4 ps-4 ">First Name</p>
                  <div className=" w-75 form-floating px-3 ">
                    <input
                      type="text"
                      className="form-control rounded-pill border"
                      placeholder="First name*"
                      required
                      id="firstName"
                      value={studentDetails.firstName}
                      onChange={handleInputChange}
                      name="firstName"
                      autoComplete="off"
                      disabled={isExisting}
                    />
                    <label
                      htmlFor="floatingInput"
                      className="ms-4 text-light-gray "
                    >
                      First Name<span className="text-danger">*</span>
                    </label>
                  </div>
                </div>
                <div className="col-5">
                  <p className=" text-dark1 text1-4 ps-4 ">Last Name</p>
                  <div className=" w-75 form-floating px-3">
                    <input
                      type="text"
                      className="form-control rounded-pill border"
                      placeholder="Last name"
                      id="lastName"
                      value={studentDetails.lastName}
                      onChange={handleInputChange}
                      name="lastName"
                      autoComplete="off"
                      disabled={isExisting}
                    />
                    <label
                      htmlFor="floatingInput"
                      className="ms-4 text-light-gray"
                    >
                      Last Name
                    </label>
                  </div>
                </div>
                <div className="col-5">
                  <p className="ps-4 text-dark1 text1-4 ">Email</p>
                  <div className="w-75 form-floating px-3">
                    <input
                      type="email"
                      className="form-control rounded-pill border"
                      placeholder="Country of Citizenship"
                      required
                      id="email"
                      value={studentDetails.email}
                      onChange={handleInputChange}
                      name="email"
                      autoComplete="off"
                      disabled={isExisting && studentDetails.email}
                    />
                    <label
                      htmlFor="floatingInput"
                      className="ms-4 text-light-gray"
                    >
                      Email
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                </div>
                <div className="col-5">
                  <p className="text-dark1 text1-4 ps-4 ">Bank</p>
                  <div className="w-75 form-floating px-3">
                    <select
                      className="form-select rounded-pill border"
                      aria-label="bank"
                      required
                      id="bank"
                      value={studentDetails.bank}
                      onChange={handleInputChange}
                      name="bank"
                      autoComplete="off"
                    >
                      <option value="">Choose Bank</option>
                      <option value="ICICI">ICICI</option>
                      <option value="CIBC">CIBC</option>
                    </select>
                    <label
                      htmlFor="floatingInput"
                      className="ms-4 text-light-gray"
                    >
                      Bank <span className="text-danger">*</span>
                    </label>
                  </div>
                </div>
                <div className="col-5">
                  <p className="text-dark1 text1-4 ps-4 ">Passport Number</p>
                  <div className="w-75 form-floating px-3">
                    <input
                      type="text"
                      className="form-control rounded-pill border"
                      placeholder="Passport"
                      id="passport"
                      name="passport"
                      disabled={isExisting && studentDetails.passport}
                      pattern="^[A-Z]{1}[0-9]{7}$"
                      value={studentDetails.passport}
                      onChange={handleInputChange}
                      autoComplete="off"
                    />

                    <label htmlFor="floatingInput" className="ms-4 pt-2">
                      Passport
                    </label>
                  </div>
                </div>
                <div className="col-5">
                  <p className="ps-4 text-dark1 text1-4 ">Contact Number</p>
                  <div className="w-75 form-floating px-3">
                    {!studentDetails.contactNumber ? (
                      <PhoneNumberInput
                        customCSS={""}
                        value={studentDetails.contactNumber}
                        onChange={handlePhoneNumberChange}
                        resetPhoneNumber={resetPhoneNumber}
                      />
                    ) : (
                      <input
                        type="text"
                        className="form-control rounded-pill border"
                        placeholder="Contact"
                        value={studentDetails.contactNumber}
                        disabled={isExisting && studentDetails.contactNumber}
                        name="contactNumber"
                      />
                    )}
                  </div>
                </div>
                <div className="col-5">
                  <p className="ps-4 text-dark1 text1-4 ">
                    GIC Reference Number
                  </p>
                  <div className="w-75 form-floating px-3">
                    <input
                      type="text"
                      className="form-control rounded-pill border"
                      placeholder="GIC Reference Number"
                      id="gicReferenceNumber"
                      value={studentDetails.gicReferenceNumber}
                      onChange={handleInputChange}
                      name="gicReferenceNumber"
                      autoComplete="off"
                    />
                    <label
                      htmlFor="floatingInput"
                      className="ms-4 text-light-gray"
                    >
                      GIC Reference Number
                    </label>
                  </div>
                </div>
                <div className="col-5"></div>
              </div>
              <div className="d-flex justify-content-end gap-4 mt-3 mb-5 me-5">
                <button
                  type="submit"
                  className="btn text-white bg-dark1 rounded-pill py-3 px-5 text1-6"
                >
                  {loading ? <Spinner /> : <span>Submit</span>}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => setShowModal(false)}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        size="sm"
        className="custom-modal"
      >
        <Modal.Body>
          <div className="d-flex flex-column justify-content-center align-items-center pt-5 gap-5 mb-3">
            <img src={platformLogo} alt="platformLogo" height="80px" />
            <p className="text1-6 text-dark1 text-center mb-0 ">
              Your Form has been submitted
            </p>
            <button
              onClick={() => {
                navigate("/user/marketplace/details");
                setShowModal(false);
              }}
              className="btn rounded-pill bg-dark1 py-3 text-white px-5 border-0"
            >
              Okay
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
