import React from "react";

export default function SubmitButton({ title, handleSubmitClick, loading }) {
  return (
    <>
      <button
        type="submit"
        className="gradiantButton rounded-pill py-3 font-bold text1-6 w-100 d-flex justify-content-center align-items-center"
        onClick={handleSubmitClick}
        disabled={loading}
      >
        <span>{loading ? "Please Wait..." : title}</span>
        {loading && <span className="spinner-border"></span>}
      </button>
    </>
  );
}
