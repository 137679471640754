import http from "./api";

const getGicDetails = (params) => {
  return http.get("GIC/search", { params });
};

const updateStatus = (body) => {
  return http.put("GIC/ChangeApplicantStatus", body);
};

const UploadInvoice = (body) => {
  return http.post("GIC/Upload", body);
};
const DownloadDocument = (params) => {
  return http.get("GIC/GetApplicantDocumentById", { params });
};

const GicServices = {
  getGicDetails,
  updateStatus,
  UploadInvoice,
  DownloadDocument,
};

export default GicServices;
