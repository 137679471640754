import React from "react";
import SectionsTitle from "../../pagesAgent/Components/SectionsTitle";
import agent from "../../assets/landingPage/agent.png";

const WhatWeDo = () => {
  return (
    <div className="py-5 my-5">
      <SectionsTitle title={"What we do"} />
      <div className="d-flex flex-column flex-lg-row justify-content-between gap-4 mt-5">
        <div className="d-flex justify-content-center" data-aos="zoom-in">
          <img src={agent} height="400px" alt="agent" />
        </div>
        <div className="d-flex justify-content-center" data-aos="zoom-out">
          <img src={agent} height="400px" alt="agent" />
        </div>
        <div className="d-flex justify-content-center" data-aos="zoom-in">
          <img src={agent} height="400px" alt="agent" />
        </div>
        <div className="d-flex justify-content-center" data-aos="zoom-out">
          <img src={agent} height="400px" alt="agent" />
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
