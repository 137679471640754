import React from "react";
import FormatDate from "../../components/UI/FormatDate";
import FormatIntkeDate from "../../components/UI/FormatIntkeDate";
export default function ProgramBanner({
  currentApplication,
  imageURL,
  companyLogo,
  programDetails,
  collegeDetails,
}) {
  return (
    <>
      <div className="bg-light-blue1 rounded-5 p-3 border shadow-sm">
        <div className="col text2">
          <span className="ms-5 font-bold">Application ID: </span>
          <FormatDate
            date={currentApplication.createdDate}
            idNo={currentApplication.idNumber}
            customCSS={false}
          />
        </div>
        <div className="d-flex justify-content-start align-items-center">
          <img
            src={imageURL ? imageURL : companyLogo}
            alt="CollegeLogo"
            className="img-fluid rounded-5 bg-white shadow p-3"
            style={{
              minWidth: "110px",
              maxWidth: "320px",
              height: "120px",
            }}
          />

          <div className="ms-4">
            <div className="text1-6">
              Program Name:{" "}
              <span className="text2 font-bold">{programDetails.name}</span>
            </div>
            <div className="text1-6">
              College Name:{" "}
              <span className="font-bold">{collegeDetails.name}</span>
            </div>
            <div className="text1-6">
              Campus:{" "}
              <span className="font-bold">{currentApplication?.campus} </span>
            </div>
            <div className="text1-6">
              Intake Status:{" "}
              <span className="font-bold">
                <FormatIntkeDate date={currentApplication.intake} />
              </span>
            </div>
            <div className="text1-6">
              Application Status:{" "}
              <span className="font-bold">
                {currentApplication.applicationStatus}
              </span>
            </div>
            {/* <div className="">
<button className="btn btn-outline-danger text1-6 rounded-pill">Remove</button>
</div> */}
          </div>
        </div>
      </div>
    </>
  );
}
