import http from "./api";

const get = (id) => {
  return http.get(`Payment?PaymentId=${id}`);
};

const create = (data) => {
  return http.post("Payment", data);
};

const find = (data) => {
  return http.post("Payment/search", data);
};
const getPaymentListByCompanyId = (data) => {
  return http.post("Payment/getPaymentListByCompanyId", data);
};

const getAllPaymentList = (data) => {
  return http.post("Payment/getAllPaymentList", data);
};
const zeroClgApplicationFee = (data) => {
  return http.post("Payment/zeroClgApplicationFee", data);
};

const stripepay = (data) => {
  return http.post("Payment/stripepay", data);
};

const PaymentService = {
  get,
  create,
  find,
  zeroClgApplicationFee,
  stripepay,
  getPaymentListByCompanyId,
  getAllPaymentList,
};

export default PaymentService;
