import React from "react";
import { Modal } from "react-bootstrap";
import { AgentsDropdown } from "./AgentsDropdown";
import { ApplicationsDropdown } from "./ApplicationsDropdown";
import "./Refund.css";
import { useRefundDialog } from "./useRefundDialog";

function RefundDialog({
  modalShow,
  setModalShow,
  formatDate,
  getRefundLedger,
  ledger,
}) {
  const {
    inputData,
    setInputData,
    handleInputDataChange,
    loading,
    handleIssueRefund,
    refundInfo,
    setRefundInfo,
    dropdownData,
  } = useRefundDialog({ modalShow, setModalShow, getRefundLedger });

  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      centered
      aria-labelledby="contained-modal-title-vcenter"
      size="md"
      className="custom-modal"
    >
      <Modal.Header
        className="d-flex bg-dark1  justify-content-between align-items-center py-3 px-4 "
        style={{
          borderRadius: "15px 15px 0px 0px",
        }}
      >
        <Modal.Title
          id="contained-modal-title-vcenter "
          className="d-flex align-items-center text1-8 text-white"
        >
          Refund
        </Modal.Title>
        <i
          className="bi bi-x-circle text-white text2"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setModalShow(false);
          }}
        ></i>
      </Modal.Header>
      <Modal.Body className="">
        <p className="text-center text1-4 my-2">
          Wallet Balance: ${ledger?.walletBalance}
        </p>
        <form
          className="g-4 needs-validation "
          noValidate
          onSubmit={(e) => handleIssueRefund(e)}
        >
          <div className="row py-3 pb-4 g-4 px-5 ">
            <div className="col-12 form-group px-3 text-white">
              <div className="text-white position-relative">
                <AgentsDropdown
                  list={dropdownData.agents}
                  value={inputData.agent}
                  handleInputChange={(e) =>
                    handleInputDataChange("agent", e.target.value)
                  }
                  handleItemClick={(item) => {
                    if (!item) handleInputDataChange("agent", "");
                    setInputData((p) => ({
                      ...p,
                      agent: item
                        ? `${item.firstName} ${item.lastName}`
                        : "All agents",
                    }));
                    setRefundInfo((prev) => ({
                      ...prev,
                      agentId: item ? item.id : "",
                      agentCompanyId: item ? item.companyId : "",
                    }));
                  }}
                  inputClassnames={
                    "select-form text1-3 bg-dark1 text-white w-100  ps-4 py-4 rounded-pill"
                  }
                />
              </div>
            </div>
            <div className="col-12 form-group px-3">
              <div className="text-white position-relative">
                <ApplicationsDropdown
                  inputClassnames={`select-form text1-3 ${
                    refundInfo.agentId === "" ? "bg-dark2" : "bg-dark1"
                  } text-white w-100  ps-4 py-4 rounded-pill`}
                  value={inputData.application}
                  list={dropdownData.applications}
                  handleInputChange={(e) =>
                    handleInputDataChange("application", e.target.value)
                  }
                  disabled={!refundInfo.agentId}
                  handleItemClick={(item) => {
                    if (!item) handleInputDataChange("application", "");
                    const id = formatDate(item?.createdDate || "");
                    setInputData((p) => ({
                      ...p,
                      application: item
                        ? `${id}-${item.idNumber}`
                        : "All applications",
                    }));

                    setRefundInfo((prev) => ({
                      ...prev,
                      applicationId: item ? item.id : "",
                    }));
                  }}
                />
              </div>
            </div>
            <div className="col-12 form-group px-3">
              <input
                type="text"
                className="form-control rounded-pill border ps-4 py-4 text1-3"
                placeholder="Enter Amount"
                required
                name="amount"
                onChange={(e) => {
                  setRefundInfo((p) => ({
                    ...p,
                    amount: e.target.value,
                  }));
                }}
                value={refundInfo.amount}
              />
            </div>
          </div>

          <Modal.Footer className="d-flex justify-content-center py-4">
            <button
              type="submit"
              className="btn btn-dark-blue rounded-pill py-3 px-5 text1-5"
              disabled={
                loading ||
                !refundInfo.agentId ||
                !refundInfo.agentCompanyId ||
                !refundInfo.applicationId ||
                !refundInfo.amount
              }
            >
              <span>Send Money</span>
            </button>
          </Modal.Footer>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default RefundDialog;
