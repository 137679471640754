import React from "react";
import { ListGroup } from "react-bootstrap";
import { useComponentVisible } from "../../shared/hooks/useComponentVisible";
import "./Refund.css";

export function AgentsDropdown({
  list,
  value,
  handleInputChange,
  handleItemClick,
  inputClassnames,
}) {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible();
  return (
    <>
      <input
        value={value}
        placeholder="Select Agent"
        className={inputClassnames}
        ref={ref}
        onClick={() => setIsComponentVisible(!isComponentVisible)}
        onChange={handleInputChange}
      />

      {isComponentVisible ? (
        <ListGroup className="dropdown-options-container w-100 bg-white">
          <ListGroup.Item
            className="text1-4 list-item"
            onClick={() => handleItemClick()}
          >
            Search All agents
          </ListGroup.Item>
          {list.length ? (
            <>
              {list.map((item) => (
                <ListGroup.Item
                  className="text1-4 list-item"
                  key={item.id}
                  onClick={() => {
                    handleItemClick(item);
                    setIsComponentVisible(!isComponentVisible);
                  }}
                >
                  {item?.firstName} {item?.lastName}
                </ListGroup.Item>
              ))}
            </>
          ) : (
            <ListGroup.Item>No agents found</ListGroup.Item>
          )}
        </ListGroup>
      ) : null}
    </>
  );
}
