import http from "./api";


const getIntakes = (id) => {
    return http.get(`Intake/getIntakesByCollegeId?id=${id}`);
};

const updateIntakes = (body) => {
    return http.put(`Intake`, body);
}

const ManageIntakeServices = {
    getIntakes,
    updateIntakes
};

export default ManageIntakeServices;
