import http from "./api";

const getAll = () => {
  return http.get(`Dashboard/dashboard`);
};
const dashboardByCompanyId = () => {
  return http.get(`Dashboard/dashboardByCompanyId`);
};
const getAllVisaStatus = () => {
  return http.get("Dashboard/allVisaStatus");
};
const getVisaStatusByCompanyId = () => {
  return http.get("Dashboard/visaStatusByCompanyId");
};
const getAnnouncements = () => {
  return http.get("AnnouncementBanner/getAll");
};
const getApplicationCount = (params) => {
  return http.get("StudentApplication/getStudentApplicationCountByFilter", {
    params,
  });
};

const DashboardService = {
  getAll,
  dashboardByCompanyId,
  getAllVisaStatus,
  getVisaStatusByCompanyId,
  getApplicationCount,
  getAnnouncements,
};

export default DashboardService;
