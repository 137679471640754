import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Modal, Spinner } from "react-bootstrap";
import pdfIcon from "../../assets/icons/applications-1.svg";
import FeedbackService from "../../CMS/Services/FeedbackService";
import { toast } from "react-toastify";
import "./Feedback.css";

function Feedback({ modalShow, setModalShow }) {
  const [loading, setLoading] = useState(false);
  const [feedbackInfo, setFeedbackInfo] = useState({
    title: "",
    message: "",
    attachments: [],
  });
  const [validated, setValidated] = useState(false);

  const fileInputRef = useRef(null);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);

    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }

    try {
      const currentDate = new Date().toISOString();
      const payload = {
        ...feedbackInfo,
        submissionDate: currentDate,
        attachments: [
          {
            fileName: feedbackInfo?.attachments[0].fileName,
            content: feedbackInfo?.attachments[0].content,
          },
        ],
      };

      const response = await FeedbackService.create(payload);

      if (response.data.success === true) {
        toast.success(response.data.message);

        setValidated(false);
      } else if (
        response.data.success === false &&
        response.data.response === false
      ) {
        toast.error(response.data.message);
      } else if (
        response.data.success === false &&
        response.data.validationErrors.length > 0
      ) {
        response.data.validationErrors.map((err) => toast.error(err));
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      console.log(error);

      toast.error("An error occurred");
    }
    setModalShow(false);
  };

  const handlePaste = useCallback((event) => {
    const items = (event.clipboardData || event.originalEvent.clipboardData)
      ?.items;

    if (items && items.length > 0) {
      const latestItem = items[items.length - 1];

      if (
        latestItem.type.startsWith("image") ||
        latestItem.type === "application/pdf"
      ) {
        const blob = latestItem.getAsFile();
        const file = new File([blob], blob.name, {
          type: blob.type,
        });
        if (file) {
          setLoading(true);
          const reader = new FileReader();
          reader.onloadend = () => {
            setFeedbackInfo((prevInfo) => ({
              ...prevInfo,
              attachments: [
                {
                  fileName: file.name,
                  content: reader.result.split(",")[1],
                  mimeType: file.type,
                },
              ],
            }));
            setLoading(false);
          };
          reader.readAsDataURL(file);
        }

        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      }
    }
  }, []);

  const handleInputChange = (event) => {
    const { name, type, files, value } = event.target;

    if (type === "file") {
      const file = files[0];
      if (file) {
        setLoading(true);
        const reader = new FileReader();
        reader.onloadend = () => {
          setFeedbackInfo((prevInfo) => ({
            ...prevInfo,
            attachments: [
              {
                fileName: file.name,
                content: reader.result.split(",")[1],
                mimeType: file.type,
              },
            ],
          }));
          setLoading(false);
        };
        reader.readAsDataURL(file);
      }
    } else {
      setFeedbackInfo((prevInfo) => ({
        ...prevInfo,
        [name]: value,
      }));
    }
  };
  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      centered
      aria-labelledby="contained-modal-title-vcenter"
      size="md"
      className="custom-modal"
      onPaste={(event) => handlePaste(event)}
    >
      <Modal.Header
        className="d-flex justify-content-between align-items-center py-3 px-4 bg-dark1 text-white "
        style={{
          borderRadius: "15px 15px 0px 0px",
        }}
      >
        <Modal.Title
          id="contained-modal-title-vcenter "
          className="d-flex align-items-center text1-8"
        >
          Feedback
        </Modal.Title>
        <i
          className="bi bi-x-circle text-white text2"
          style={{ cursor: "pointer" }}
          onClick={() => setModalShow(false)}
        ></i>
      </Modal.Header>
      <Modal.Body className="">
        <form
          className="row g-4 needs-validation px-5"
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <div className="col-12 form-group px-3">
            <label className="ms-3 mb-2 text1-5 pt-2">Enter Title</label>
            <input
              type="text"
              className="form-control rounded-4 border ps-4 py-4 text1-3"
              placeholder="Enter Title"
              required
              name="title"
              onChange={handleInputChange}
              value={feedbackInfo.title}
            />
          </div>
          <div className="col-12 form-group px-3 ">
            <label className="ms-3 text1-5 mb-2 pt-2">
              Can you tell us more?
            </label>
            <textarea
              style={{
                resize: "none",
              }}
              className="form-control rounded-4 border text1-3 ps-4 py-4 "
              rows="3"
              placeholder="Share your feedback here"
              required
              name="message"
              value={feedbackInfo.message}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-12 form-group px-3 ">
            {feedbackInfo?.attachments.length > 0 ? (
              <div className=" d-flex justify-content-center ms-4 mt-2 position-relative">
                {loading ? (
                  <Spinner />
                ) : feedbackInfo.attachments[0].mimeType.startsWith(
                    "image/"
                  ) ? (
                  <>
                    <img
                      src={`data:${feedbackInfo.attachments[0].mimeType};base64,${feedbackInfo.attachments[0].content}`}
                      alt="Uploaded"
                      className=""
                      style={{ maxWidth: "100%", maxHeight: "180px" }}
                    />
                    <button
                      className="btn btn-link btn-cross position-absolute cross top-5 right-5"
                      onClick={() => {
                        setFeedbackInfo((prevInfo) => ({
                          ...prevInfo,
                          attachments: [],
                        }));
                      }}
                    >
                      <i className="bi bi-x-circle-fill text-danger text2"></i>
                    </button>
                  </>
                ) : (
                  feedbackInfo?.attachments[0].mimeType.startsWith(
                    "application/"
                  ) && (
                    <>
                      <div className="d-flex flex-column justify-content-center align-items-center position-relative">
                        <button
                          className="btn rounded-4 btn-success d-flex align-items-center justify-content-center text1-4 me-3"
                          type="button"
                          style={{
                            width: "56px",
                            height: "58px",
                          }}
                        >
                          <i className="bi bi-file-earmark-text text3"></i>
                        </button>

                        <div> {feedbackInfo?.attachments[0].fileName}</div>
                      </div>
                      <button
                        className="btn btn-link btn-cross position-absolute file-cross"
                        onClick={() => {
                          setFeedbackInfo((prevInfo) => ({
                            ...prevInfo,
                            attachments: [],
                          }));
                        }}
                      >
                        <i className="bi bi-x-circle-fill text-danger text2"></i>
                      </button>
                    </>
                  )
                )}
              </div>
            ) : (
              <div className="d-flex justify-content-center ms-1 mt-2 align-items-center ">
                <div
                  className="dotted-box rounded-4 d-flex justify-content-center align-items-center"
                  tabIndex="0"
                  contentEditable="false"
                >
                  <label
                    className="text1-5 mb-3 text-dark1"
                    htmlFor="importInput"
                    style={{ cursor: "pointer" }}
                  >
                    Attach Image/Pdf
                  </label>
                  <label
                    className="text1-5 mb-3"
                    htmlFor="importInput"
                    style={{ cursor: "pointer" }}
                  >
                    <i className="bi bi-paperclip text2-5 text-blue"></i>
                  </label>

                  <input
                    id="importInput"
                    type="file"
                    required
                    accept="image/jpeg, image/jpg, image/png, application/pdf"
                    className="d-none"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            )}
          </div>

          <Modal.Footer className="d-flex justify-content-center py-4">
            <button
              type="submit"
              className="btn btn-dark-blue rounded-pill py-3 px-5 text1-5"
              disabled={loading}
            >
              <span> Send Feedback</span>
            </button>
          </Modal.Footer>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default Feedback;
