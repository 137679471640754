import http from "./api";

const getStudentListByUserId = (params) => {
  return http.get("Student/GetStudentListByUserId?", { params });
};
const create = (data) => {
  return http.post("GIC", data);
};
const MarketPlaceService = {
  getStudentListByUserId,
  create,
};

export default MarketPlaceService;
