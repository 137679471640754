import React, { useCallback } from 'react'
import { Modal } from 'react-bootstrap'
import platformLogo from "../../assets/logo_dark.png"
import { useNavigate } from 'react-router-dom'

const CompleteProfile = ({ showModal, handleCloseModal }) => {
    const navigate = useNavigate()

    const handleOkClick = useCallback(() => {
        handleCloseModal()
        navigate('/user/profile')
    }, [handleCloseModal, navigate])

    return (
        <Modal
            show={showModal}
            onHide={handleCloseModal}
            centered
            size='md'
            className="custom-modal">

            <Modal.Body>
                <i className="bi bi-x-circle-fill text2 p-3 text-danger position-absolute end-0 top-0" style={{ cursor: "pointer" }}
                    onClick={handleCloseModal}></i>
                <div className='d-flex flex-column align-items-center pt-5 gap-5'>

                    <img src={platformLogo} alt='platformLogo' height='80px' />
                    <p className='text2 text-dark1 fw-bold'>Welcome </p>
                </div>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <p className='text-center text1-5 fw-bold'>Please complete your profile</p>
                    <div className='d-flex gap-4 m-3'>

                        <button className='rounded-pill text1-4 px-5 pt-2 gradiantButton'
                            onClick={handleOkClick} >OK</button>
                    </div>
                </div>
            </Modal.Body>

        </Modal>
    )
}

export default CompleteProfile
