import { useState } from "react";

import { toast } from "react-hot-toast";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PromoCodeDataService from "../../Services/PromoCode";

export default function AddPromoCode({ pageTitle, collegeData, getData }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const initializeData = {
    collegeId: "",
    companyId: "",
    name: "",
    value: "",
    activeDate: "",
    expiryDate: "",
    statusId: 0,
  };

  const [PromoCodeData, setPromoCodeData] = useState(initializeData);

  const [validated, setValidated] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPromoCodeData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    setValidated(true);

    if (form.checkValidity() === false) {
      event.stopPropagation();
      return; // Exit the function if the form is invalid
    }

    var newData = {
      collegeId: PromoCodeData.collegeId,
      name: PromoCodeData.name,
      value: PromoCodeData.value,
      activeDate: PromoCodeData.activeDate,
      expiryDate: PromoCodeData.expiryDate,
      statusId: PromoCodeData.statusId,
    };

    try {
      // Display loading message
      const loadingMessage = toast.loading("Creating...");

      const response = await PromoCodeDataService.create(newData);

      if (response.data.success === true) {
        getData();
        toast.success(response.data.message, { id: loadingMessage });
        setPromoCodeData(initializeData);
        setValidated(false);
        setShow(false);
      } else if (response.data.success === false && response.data.validationErrors.length > 0) {
        response.data.validationErrors.map((verr) => toast.error(verr, { id: loadingMessage }));
      } else {
        toast.error("Something Went Wrong", { id: loadingMessage });
      }
    } catch (error) {
      toast.error("An error occurred");
    }
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow} className="py-2 px-4 fs-5 rounded-pill">
        Add New {pageTitle}
      </Button>

      <Modal show={show} onHide={handleClose} className="mt-5" size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add {pageTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit} className="px-3">
            <Row xs={1} md={2} className="g-3">
              <Col md={12}>
                <FloatingLabel controlId="floatingSelect" label="Select College">
                  <Form.Select
                    aria-label="select college"
                    defaultValue={PromoCodeData.collegeId}
                    name="collegeId"
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select College</option>
                    {collegeData.map((cd) => (
                      <option value={cd.id} key={cd.id}>
                        {cd.collegeName}
                      </option>
                    ))}
                  </Form.Select>
                </FloatingLabel>
              </Col>

              <FloatingLabel controlId="floatingInput" label="name">
                <Form.Control type="text" placeholder="name" required name="name" value={PromoCodeData.name} onChange={handleInputChange} />
                <Form.Text className="text-muted">This promo name/code should be unqiue everytime</Form.Text>
                <Form.Control.Feedback type="invalid">Please provide name</Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel controlId="floatingInput" label="value">
                <Form.Control
                  type="number"
                  placeholder="Value"
                  required
                  name="value"
                  value={PromoCodeData.value}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">Please provide value</Form.Control.Feedback>
                <Form.Text className="text-muted">Value will be calculated as percentage</Form.Text>
              </FloatingLabel>

              <FloatingLabel controlId="floatingInput" label="activeDate">
                <Form.Control
                  type="datetime-local"
                  placeholder="activeDate"
                  required
                  name="activeDate"
                  value={PromoCodeData.activeDate}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">Please provide activeDate</Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel controlId="floatingInput" label="expiryDate">
                <Form.Control
                  type="datetime-local"
                  placeholder="expiryDate"
                  required
                  name="expiryDate"
                  value={PromoCodeData.expiryDate}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">Please provide expiryDate</Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel controlId="floatingSelect" label="Select Status">
                <Form.Select
                  aria-label="select status"
                  value={PromoCodeData.statusId}
                  name="statusId"
                  onChange={handleInputChange}
                  required
                >
                  <option value={1}>Active</option>
                  <option value={0}>Inactive</option>
                </Form.Select>
              </FloatingLabel>

              <Col md={12}>
                <Modal.Footer className="mt-4">
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button type="submit" variant="success">
                    save
                  </Button>
                </Modal.Footer>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
