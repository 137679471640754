import React from "react";
import Slider from "react-slick";
import agentHero1 from "../../assets/agents/agentHero1.png";
import agentHero2 from "../../assets/agents/agentHero2.png";
import agentHero3 from "../../assets/agents/agentHero3.png";

const settings = {
  dots: false,
  fade: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 1500,
  autoplay: true,
  autoplaySpeed: 3000,
};

const cards = [
  {
    photo: agentHero1,
  },
  {
    photo: agentHero2,
  },
  {
    photo: agentHero3,
  },
];

export default function Carousel() {

  return (
    <Slider {...settings}>
      {cards.map((card, index) => {
        return <img key={index} src={card.photo} className={`img-fluid rounded-5 pt-5`} alt={card.photo} />;
      })}
    </Slider>
  );
}
