import http from "./api";

const getRoles = () => {
  return http.get(`Auth/roles`);
};
const getEPAssignee = () => {
  return http.get(`Auth/getEPAssigneeList`);
};
const getTeamLeader = () => {
  return http.get(`Auth/getTeamLeaderList`);
};
const get = (id) => {
  return http.get(`Auth?UserId=${id}`);
};
const remove = (email) => {
  return http.delete(`Auth/?email=${email}`);
};
const create = (data) => {
  return http.post("Auth", data);
};
const update = (data) => {
  return http.put(`Auth`, data);
};
const find = (params) => {
  return http.get("Auth?", { params });
};

const UserDataService = {
  getRoles,
  getEPAssignee,
  getTeamLeader,
  get,
  remove,
  create,
  update,
  find,
};

export default UserDataService;
