import React from "react";

const Pagination = ({
  currentPage,
  handlePageChange,
  dataLength,
  itemsPerPage,
}) => {
  const displayPages = 3;
  const totalPages = Math.ceil(dataLength / itemsPerPage);

  const generatePageButtons = () => {
    const buttons = [];
    const halfDisplayPages = Math.floor(displayPages / 2);

    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= +currentPage - halfDisplayPages &&
          i <= +currentPage + halfDisplayPages)
      ) {
        buttons.push(
          <li key={i} className={`page-item me-1`}>
            <button
              className={`btn border rounded-circle pagination-button ${
                +currentPage === i ? "btn-dark-blue" : "btn-dark-white"
              }`}
              style={{ width: "36px", height: "36px" }}
              onClick={() => handlePageChange(i)}
            >
              <span className="d-flex justify-content-center align-items-center text1-5">
                {i}
              </span>
            </button>
          </li>
        );
      } else if (
        i === +currentPage - halfDisplayPages - 1 ||
        i === +currentPage + halfDisplayPages + 1
      ) {
        // Add an ellipsis button
        buttons.push(
          <li key={i} className={`page-item me-1 disabled`}>
            <button
              className="btn border rounded-circle pagination-button"
              style={{ width: "36px", height: "36px" }}
            >
              <span className="d-flex justify-content-center align-items-center">
                ...
              </span>
            </button>
          </li>
        );
      }
    }
    return buttons;
  };

  return (
    <nav aria-label="Page navigation">
      <ul className="pagination justify-content-end">
        <li className="page-item me-1">
          <button
            className="btn btn-dark-white border rounded-circle m-0 p-0 pagination-button"
            style={{ width: "36px", height: "36px" }}
            onClick={() => handlePageChange(+currentPage - 1)}
            disabled={+currentPage === 1}
          >
            <span className="d-flex justify-content-center align-items-center text2">
              &laquo;
            </span>
          </button>
        </li>
        {generatePageButtons()}
        <li className="page-item me-1">
          <button
            className="btn btn-dark-white border rounded-circle m-0 p-0 pagination-button"
            style={{ width: "36px", height: "36px" }}
            onClick={() => handlePageChange(+currentPage + 1)}
            disabled={+currentPage === totalPages}
          >
            <span className="d-flex justify-content-center align-items-center text2">
              &raquo;
            </span>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
