import React, { useCallback, useEffect, useState } from "react";
import SendNote from "./SendNote";
import ApplicationNoteService from "../../Services/ApplicationNoteService";
import FormatDate from "../../components/UI/FormatDate";
import NotesList from "./NotesList";
import TokenService from "../../Services/TokenService";
import RoleService from "../../Services/RolesService";
import { toast } from "react-toastify";
import ProgramBanner from "../Applications/ProgramBanner";
import EpLoader from "../../components/UI/EpLoader";

export default function NotesBar({
  currentApplication,
  programDetails,
  imageURL,
  companyLogo,
  collegeDetails,
}) {
  const getRole = TokenService.getUserRole();
  const { Counsellor, AdmissionOfficer, BranchHead } = RoleService;
  const user = TokenService.getUser();

  const [notes, setNotes] = useState();
  const [replyNote, setReplyNote] = useState({
    id: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(true);

  const retrieveNotes = useCallback(() => {
    ApplicationNoteService.get(currentApplication.id)
      .then((response) => {
        setNotes(response.data.response);
        setIsLoading(false);
      })
      .catch((e) => {
        toast.error(e);
      });
  }, [currentApplication.id]);

  const markNotesAsRead = useCallback(() => {
    try {
      const payload = {
        referenceId: currentApplication.id,
        notificationType: "ApplicationNoteCreate",
      };
      ApplicationNoteService.markAsReadByReferenceId(payload);
    } catch (err) {}
  }, [currentApplication.id]);

  const markAsRead = useCallback(() => {
    try {
      const payload = {
        studentApplicationId: currentApplication.id,
      };
      ApplicationNoteService.markNotesAsRead(payload);
    } catch (err) {}
  }, [currentApplication.id]);

  useEffect(() => {
    retrieveNotes();
  }, [retrieveNotes]);
  useEffect(() => {
    markNotesAsRead();
  }, [markNotesAsRead]);
  useEffect(() => {
    markAsRead();
  }, [markAsRead]);

  const [viewing, setViewing] = useState(null);
  const handleOpenInNewTab = useCallback(async (e, note, file) => {
    e.preventDefault();

    const id = note.id;
    setViewing(file);

    const params = {
      Id: id,
      fileName: file,
    };

    try {
      ApplicationNoteService.download(params).then((e) => {
        const noteUrl = e.data?.Url;
        if (noteUrl) {
          const newTab = window.open(noteUrl, "_blank");
          setViewing(null);
          if (!newTab) {
            toast.error("Failed to open the file in a new tab.");
          }
        } else {
          toast.error("Note not found!");
        }
      });
    } catch (error) {
      toast.error("Error opening PDF in a new tab:", error);
      setViewing(null);
    }
  }, []);

  // const handleDownload = async (e, note) => {
  //   e.preventDefault();
  //   const id = note.id;
  //   const fileName = note.fileName;
  //   try {
  //     ApplicationNoteService.download(id).then(async (e) => {
  //       const blob = await e.data;
  //       const url = window.URL.createObjectURL(blob);
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", fileName);
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     });
  //   } catch (error) {
  //     console.error("Error downloading PDF:", error);
  //   }
  // };

  if (isLoading) {
    return <EpLoader />;
  }
  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="">
            <ProgramBanner
              currentApplication={currentApplication}
              imageURL={imageURL}
              companyLogo={companyLogo}
              programDetails={programDetails}
              collegeDetails={collegeDetails}
            />
          </div>
        </div>
        <div className="col-12 px-5 my-3">
          <NotesList
            notesData={notes}
            user={user}
            handleDownload={handleOpenInNewTab}
            viewing={viewing}
            setReplyNote={setReplyNote}
          />
        </div>
        {getRole === BranchHead ||
        getRole === Counsellor ||
        getRole === AdmissionOfficer ? (
          <div className={`col-12`}>
            <SendNote
              currentApplication={currentApplication}
              retrieveNotes={retrieveNotes}
              user={user}
              replyNote={replyNote}
              setReplyNote={setReplyNote}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
