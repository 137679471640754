const RolesService = {
  SuperAdmin: "Super Admin",
  Reception: "Reception",
  Student: "Student",
  Counsellor: "Counsellor",
  AdmissionOfficer: "Admission Officer",
  TeamLeader: "Team Leader",
  BranchHead: "Branch Head",
  ProgramEditor: "Program Editor",
  AgencyManager: "Agency Manager",
  Accounts: "Accounts",
};

export default RolesService;
