import React from "react";
import { Link } from "react-router-dom";

export default function NestedSideBarButton({ title, navTo, activeLink, setActiveLink }) {
  return (
    <Link to={navTo} className="nav-link w-100 mb-2" style={{ cursor: "pointer" }} onClick={() => setActiveLink(navTo)}>
      <button className={`btn btn-outline-primary rounded-2 w-100 text-start p-2 fs-5 ${activeLink === navTo ? "active" : ""}`}>
        {title}
      </button>
    </Link>
  );
}
