import React, { useRef } from "react";

const CopyToClipboardButton = ({ textToCopy }) => {
  const textAreaRef = useRef(null);

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
    } catch (err) {
      console.error("Unable to copy to clipboard: ", err);
    }
  };

  return (
    <div className="me-1">
      <textarea
        ref={textAreaRef}
        value={textToCopy}
        style={{ position: "absolute", left: "-9999px" }} // Hide the textarea off-screen
        readOnly
      />
      <button onClick={handleCopyClick} className="bg-dark btn btn-dark rounded-circle" style={{ width: "36px", height: "36px" }}>
        <i className="bi bi-clipboard-check fs-3 text-light d-flex justify-content-center align-items-center"></i>
      </button>
    </div>
  );
};

export default CopyToClipboardButton;
