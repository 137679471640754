import React from "react";
import { useNavigate } from "react-router-dom";
import { Chart as ChartJs, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJs.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

export default function BarChart({ visaStatus }) {
  const navigate = useNavigate();

  const { received, rejected, deferrals, underReview, approved } = visaStatus;

  const data = {
    labels: ["Received", "Rejected", "Deferrals", "Under Review", "Approved"],
    datasets: [
      {
        data: [received, rejected, deferrals, underReview, approved],
        backgroundColor: "#FC7753",
        maxBarThickness: 14,
        borderRadius: 4,
      },
    ],
  };

  const handleBarClick = (event, chartElements) => {
    if (chartElements && chartElements.length > 0) {
      const clickedBar = chartElements[0];
      const labelName = data.labels[clickedBar.index];
      let newLabelName;
      switch (labelName) {
        case "Received":
          newLabelName = "Offer Received";
          break;
        case "Rejected":
          newLabelName = "Offer Refused";
          break;
        case "Deferrals":
          newLabelName = "Deferral Under Process";
          break;
        case "Under Review":
          newLabelName = "Under Review";
          break;
        case "Approved":
          newLabelName = "Visa Approved";
          break;
      }
      navigate("/user/applications", { state: { newLabelName } });
    }
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "#fff",
        titleColor: "#000",
        bodyColor: "#000",
      },
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        border: { dash: [6, 6] },
        grid: {
          color: "#DCDCDC",
        },
        ticks: {
          color: "#5A5A72",
          font: {
            size: 16,
          },
        },
        beginAtZero: true,
      },
      x: {
        ticks: {
          color: "#5A5A72",
          font: {
            size: 16,
          },
        },
        grid: {
          lineWidth: 0,
        },
      },
    },
    onClick: handleBarClick, // Attach the click event handler
  };

  return (
    <>
      <div className="graph-width">
        <Bar data={data} options={options} width={700} height={300}></Bar>
      </div>
    </>
  );
}
