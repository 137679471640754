import React from "react";

export default function SelectItemsPerPage({ handlePageSizeChange, pageSize }) {
  const options = [5, 10, 25, 50, 100]; // Define options here

  return (
    <>
      <div className="">
        <select
          className="form-select rounded-4 fs-5 py-2"
          aria-label="Items Per Page"
          defaultValue={pageSize}
          onChange={handlePageSizeChange}
        >
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}
