import React from "react";
export default function SectionsTitle({ title }) {
  return (
    <>
      <div className="aos-init aos-animate" data-aos="zoom-out">
        <div className="d-flex align-items-center">
          <div className="text5 d-flex">
            <div className="bg-normal-yellow col-md-6 col-sm-12 mx-2" style={{ height: "64px", width: "12px" }}></div>
            {title}
          </div>
        </div>
      </div>
    </>
  );
}
