import React from "react";
import TokenService from "../../Services/TokenService";

export default function AssosiateContents({ setModalShow }) {
  const token = TokenService.getLocalAccessToken();
  const isAuth = Boolean(token);
  return (
    <>
      <div className="text4 fw-bold">Let's grow together</div>
      <div className="text1-8 pb-5">
        We provide you with a comprehensive platform through which you can
        connect your students to our partner universities and institutes.
      </div>
      <button
        className="btn rounded-pill bg-dark1 text-white px-5 py-3 text2"
        onClick={() => setModalShow(true)}
      >
        Become {isAuth ? "an agent" : "a partner"}
      </button>
    </>
  );
}
