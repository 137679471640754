import React, { useCallback, useEffect, useState } from "react";
import PageTitle from "../components/PageTitle";
import Header from "../layout/Header";
import BlogDataService from "../Services/BlogService";
import HeroSection from "./HeroSection";
import SearchBar from "../components/UI/SearchBar";
import BlogList from "./BlogList";
import Pagination from "../components/UI/Pagination";
import Section8 from "../pages/Main/Section8";
import EpLoader from "../components/UI/EpLoader";

const pageTitle = "Blogs";
const initializeData = [
  {
    title: "",
    description: "",
    image: "",
    link: "",
    createdDate: "",
    statusId: 0,
    type: "",
  },
];

export default function AppBlog() {
  const [BlogData, setBlogData] = useState(initializeData);
  const [updating, setUpdating] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [itemsLength, setItemsLength] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getBlogData = useCallback(async () => {
    setUpdating(true);
    try {
      let data;

      data = {
        search: searchQuery,
        currentPage,
        pageSize,
        statusId: 1,
      };

      const response = await BlogDataService.find(data);
      const responseData = response.data;
      setBlogData(responseData);
      setLoading(false);
      setItemsLength(responseData[0]?.totalItems);
      setUpdating(false);
    } catch (error) {
      console.error(error);
      setUpdating(false);
    }
  }, [currentPage, pageSize, searchQuery]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getBlogData();
    }, 200);
    return () => clearTimeout(timer);
  }, [getBlogData]);

  return (
    <>
      <PageTitle title={pageTitle} />
      <Header />
      <HeroSection />
      <div className="row mt-md-4 mt-5 justify-content-center ">
        <div className="d-flex col-8 mt-4 col-md-4">
          <SearchBar
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            searchTitle={"Search Blogs"}
            setCurrentPage={setCurrentPage}
            className="w-100"
          />
        </div>
      </div>
      <div className="container d-flex flex-column my-5 pt-3">
        <div className="aos-init aos-animate" data-aos="zoom-out">
          <div className="d-flex align-items-center ms-2">
            <div className="text4 d-flex">
              <div
                className="bg-dark1 col-md-6 col-sm-12 ms-2 me-3"
                style={{ height: "50px", width: "8px" }}
              ></div>
              Blogs
            </div>
          </div>
        </div>
        <div className=" mt-4 ps-5 ms-2">
          <p className="my-0 text-dark1 text1-5 w-75 w-md-50">
            We share common trends, strategies, ideas, opinions, short & long
            stories from all over the world.
          </p>
        </div>
      </div>

      <div className="me-5">
        {itemsLength > 0 && (
          <Pagination
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            dataLength={itemsLength}
            itemsPerPage={9}
          />
        )}
      </div>
      <div className="container my-5 ">
        <div className="row mt-2">
          {updating ? (
            <EpLoader />
          ) : BlogData.length === 0 ? (
            <div className="mt-5 text-center fs-1">No Data Found</div>
          ) : (
            <div className="col-12 p-4">
              <BlogList dataList={BlogData} />
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          marginTop: "70px",
        }}
      >
        <Section8 />
      </div>
    </>
  );
}
