import React from "react";
import { Modal } from "react-bootstrap";

function RemarkDialog({ open, handleClose, req }) {
  return (
    <Modal
      show={open}
      onHide={handleClose}
      centered
      aria-labelledby="contained-modal-title-vcenter"
      size="sm"
      className="custom-modal"
    >
      <Modal.Header
        className="d-flex justify-content-between align-items-center py-2 px-4 bg-dark1 text-white "
        style={{
          borderRadius: "15px 15px 0px 0px",
        }}
      >
        <Modal.Title
          id="contained-modal-title-vcenter "
          className="d-flex align-items-center text1-6"
        >
          Remarks
        </Modal.Title>
        <i
          className="bi bi-x-circle text2"
          style={{ cursor: "pointer" }}
          onClick={handleClose}
        ></i>
      </Modal.Header>

      <Modal.Body className="d-flex flex-column gap-2 align-items-center pt-4 pb-5">
        <p className=" text1-4 mb-0 ">Remarks</p>
        <textarea
          style={{
            resize: "none",
            width: "250px",
          }}
          disabled
          className="rounded-pill border text1-3 ps-4 py-4 "
          rows="3"
          value={req.remarks}
        />
      </Modal.Body>
    </Modal>
  );
}

export default RemarkDialog;
