import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import UserPanel from "../UserPanel";
import Tabs from "../../components/UI/Tabs";
import AdmisionDetails from "./AdmisionDetails";
import NotesBar from "../Notes/NotesBar";
import ApplicationDataService from "../../Services/ApplicationService";
import StudentDataService from "../../Services/StudentService";
import FormatDate from "../../components/UI/FormatDate";
import ProgramSearchService from "../../Services/ProgramSearchService";
import AddDocuments from "./AddDocuments";

import EducationDataService from "../../Services/StudentEducationService";
import CollegeDataService from "../../Services/CollegeService";
import { toast } from "react-toastify";
import companyLogo from "../../assets/logo_dark.png";
import ApplicationStatusList from "../../Services/ApplicationStatusList";
import ApplicationStatusButton from "./ApplicationStatusButton";
import PageTitle from "../../components/PageTitle";
import EpLoader from "../../components/UI/EpLoader";
import ExchangeRate from "../../Services/ExchangeRate";

export default function ApplicationView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoadeding, setIsLoadeding] = useState(true);
  const [currentApplication, setCurrentApplication] = useState([]);
  const [studentCurrency, setStudentCurrency] = useState();
  const [currentStudent, setCurrentStudent] = useState([]);
  const [allDraftApplication, setAllDraftApplication] = useState(false);
  const [collegeDetails, setCollegeDetails] = useState("");
  const [imageURL, setImageURL] = useState(null);
  const [programDetails, setProgramDetails] = useState("");
  const [currentEducation, setCurrentEducation] = useState();
  const [navigating, setNavigating] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const activeTabNext = searchParams.get("tabName") ?? "admissionDetails";

  const setActiveTabNext = useCallback(
    (newTabId) => {
      searchParams.set("tabName", newTabId);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const getApplication = useCallback(async (id) => {
    try {
      const response = await ApplicationDataService.get(id);
      setCurrentApplication(response.data);
      setIsLoaded(true);
    } catch (error) {
      const errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(errorMessage);
    }
  }, []);

  const studnetID = currentApplication.studentId;

  const getStudent = useCallback(async () => {
    try {
      const response = await StudentDataService.get(studnetID);
      setCurrentStudent(response.data);
      setIsLoadeding(false);
    } catch (error) {
      const errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(errorMessage);
    }
  }, [studnetID]);

  const checkAppStatus = useCallback(async () => {
    try {
      if (currentStudent.length === 0) {
        setAllDraftApplication(true);
      } else if (currentStudent && currentStudent?.applications?.length === 0) {
        setAllDraftApplication(true);
      } else if (
        currentStudent &&
        currentStudent.applications &&
        currentStudent.applications.length > 0
      ) {
        const allApplicationsDraft = await currentStudent.applications.every(
          (application) => application.applicationStatus === "Draft"
        );
        setAllDraftApplication(allApplicationsDraft);
      } else {
        setAllDraftApplication(false);
      }
    } catch (error) {
      const errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(errorMessage);
    }
  }, [currentStudent]);

  useEffect(() => {
    checkAppStatus();
  }, [checkAppStatus, currentStudent]);

  const getCurrentCollegeDetails = useCallback(async () => {
    try {
      if (currentApplication.collegeId) {
        const response = await CollegeDataService.get(
          currentApplication.collegeId
        );
        setCollegeDetails(response.data);
        if (response.data.logoName) {
          const logoResponse = await CollegeDataService.downloadCollegeLogo(
            currentApplication.collegeId
          );
          // const blob = new Blob([logoResponse.data], { type: "image/jpeg" });
          // const url = URL.createObjectURL(blob);
          setImageURL(logoResponse?.data?.response?.collegeLogoUrl); // Update the state with the image URL
        }
      }
    } catch (error) {
      const errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(errorMessage);
    }
  }, [currentApplication.collegeId]);

  const getCurrentProgramDetails = useCallback(async () => {
    try {
      if (currentApplication.collegeProgramId) {
        const response = await ProgramSearchService.get(
          currentApplication.collegeProgramId
        );
        setProgramDetails(response.data);
      }
    } catch (error) {
      const errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(errorMessage);
    }
  }, [currentApplication.collegeProgramId]);

  useEffect(() => {
    if (id) {
      getApplication(id);
    }
  }, [id]);
  useEffect(() => {
    if (studnetID) {
      getStudent();
    }
  }, [studnetID]);

  useEffect(() => {
    getCurrentCollegeDetails();
    getCurrentProgramDetails();
  }, [currentApplication]);

  const getEducation = useCallback(() => {
    EducationDataService.findAndGetByStudentID(studnetID)
      .then((response) => {
        setCurrentEducation(response.data.response);
      })
      .catch((e) => {
        toast.error(
          (e.response && e.response.data && e.response.data.message) ||
            e.message ||
            e.toString()
        );
      });
  }, [studnetID]);
  const getStudentCurrency = useCallback(async () => {
    try {
      const country = currentStudent?.country;
      const response = await ApplicationDataService.getCurrency(country);
      setStudentCurrency(response.data.response);
    } catch (error) {
      //toast.error("Error fetching applications");
    }
  }, [currentStudent?.country]);

  useEffect(() => {
    getStudentCurrency();
  }, [getStudentCurrency]);

  const handleApplicationNav = useCallback(
    (e, app) => {
      e.preventDefault();
      setNavigating(true);
      navigate("/user/viewApplication/" + app.studentApplicationId);
    },
    [navigate]
  );

  useEffect(() => {
    if (studnetID) {
      getEducation();
    }
    setTimeout(() => {
      setNavigating(false);
    }, 300);
  }, [id]);

  const tabs = useMemo(
    () => [
      {
        id: "admissionDetails",
        label: "Admission Details",
        content: (
          <AdmisionDetails
            studentCurrency={studentCurrency}
            currentApplication={currentApplication}
            programDetails={programDetails}
            collegeDetails={collegeDetails}
            companyLogo={companyLogo}
            imageURL={imageURL}
            allDraftApplication={allDraftApplication}
            checkAppStatus={checkAppStatus}
            setAllDraftApplication={setAllDraftApplication}
          />
        ),
      },
      {
        id: "documents",
        label: "Documents",
        content: (
          <AddDocuments
            currentEducation={currentEducation}
            currentApplication={currentApplication}
            allDraftApplication={allDraftApplication}
          />
        ),
      },
      {
        id: "notes",
        label: "Notes",
        content: (
          <NotesBar
            currentApplication={currentApplication}
            programDetails={programDetails}
            companyLogo={companyLogo}
            imageURL={imageURL}
            collegeDetails={collegeDetails}
          />
        ),
      },
    ],
    [
      allDraftApplication,
      checkAppStatus,
      collegeDetails,
      currentApplication,
      currentEducation,
      imageURL,
      programDetails,
    ]
  );
  if (isLoadeding)
    return (
      <div>
        <EpLoader />
      </div>
    );
  return (
    <>
      <PageTitle title={"View Application"} />
      <div className="p-2 mt-2">
        <div className="row align-items-center">
          <div className="col-12">
            <UserPanel title={"Application Details"} />
          </div>
          <div className="col-12">
            <div
              className="bg-dark1 text-white rounded-5  p-3"
              style={{ minHeight: "160px" }}
            >
              <div className="row text1-4 py-4 px-5">
                <div className="col-12 text2-5 mb-1 font-bold">
                  Welcome to Education Planner
                </div>
                <div className="col-12">
                  <div className="row text1-4 px-5">
                    <div className="col-4 text2">
                      <div className="text2 font-bold">
                        {currentStudent.salutation} {currentStudent.firstName}{" "}
                        {currentStudent.lastName}
                      </div>
                      <span className="font-bold">Student ID: </span>
                      <FormatDate
                        date={currentStudent.createdDate}
                        idNo={currentStudent.idNumber}
                      />
                    </div>
                    <div className="col-4">
                      <div className="col">
                        <div className="text1-6">
                          Passport No.:{" "}
                          <span className="font-bold">
                            {currentStudent.passportNumber}
                          </span>
                        </div>
                        <div className="text1-6">
                          Email:{" "}
                          <span className="font-bold">
                            {currentStudent.email}
                          </span>
                        </div>
                        <div className="text1-6">
                          Phone No:{" "}
                          <span className="font-bold">
                            {currentStudent.contactNumber}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="text1-6 d-flex align-items-center">
                        Applications:
                        <span className="font-bold ms-1">
                          <div className="d-flex justify-content-center align-items-center">
                            {currentStudent.applications.map((app, index) => {
                              return (
                                // make this drop down using hover instead of on click
                                <div key={index} className="dropstart">
                                  <div
                                    className={`border p-2 rounded-circle me-1 d-flex justify-content-center align-items-center ${
                                      id === app.studentApplicationId
                                        ? " bg-orange text-white "
                                        : "bg-white text-orange "
                                    }`}
                                    style={{
                                      width: "34px",
                                      height: "34px",
                                      cursor: "pointer",
                                    }}
                                    data-bs-toggle="dropdown"
                                    data-bs-target={`#dropdown-menu-${app.studentApplicationId}`}
                                    aria-expanded="false"
                                  >
                                    <span className="mt-1 text1-6">
                                      {" "}
                                      {index + 1}
                                    </span>
                                  </div>
                                  <div
                                    className="dropdown-menu rounded-4 p-3"
                                    id={`dropdown-menu-${app.studentApplicationId}`}
                                  >
                                    <div className="text-center text1-3 text-nowrap mb-2">
                                      <strong>{app.collegeName}</strong>
                                    </div>

                                    <div>
                                      <ApplicationStatusButton
                                        AppStatus={app.applicationStatus}
                                        ApplicationStatusList={
                                          ApplicationStatusList
                                        }
                                      />
                                    </div>
                                    <div>
                                      <button
                                        className="btn btn-outline-dark rounded-pill px-3 py-2 text1-2 mx-auto text-center d-flex align-items-center justify-content-center my-2"
                                        style={{
                                          width: "180px",
                                          height: "44px",
                                        }}
                                        onClick={(e) => {
                                          handleApplicationNav(e, app);
                                          document
                                            .getElementById(
                                              `dropdown-menu-${app.studentApplicationId}`
                                            )
                                            .classList.remove("show");
                                        }}
                                        disabled={
                                          id === app.studentApplicationId
                                        }
                                      >
                                        View Application
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {navigating ? (
          <EpLoader />
        ) : (
          <Tabs
            tabs={tabs}
            isLoaded={isLoaded}
            activeTabNext={activeTabNext}
            setActiveTabNext={setActiveTabNext}
          />
        )}
      </div>
    </>
  );
}
