import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import ProgramSearchService from "../../../Services/ProgramSearchService";
import CompareButton from "../../../components/Form/CompareButton";
import globeIcon from "../../../assets/common/globe-asia-australia.svg";
import IndiaFlag from "../../../assets/common/India.svg";
import CanadaFlag from "../../../assets/common/canada.svg";
import NewZealandFlag from "../../../assets/common/newZealand.svg";
import AustraliaFlag from "../../../assets/common/Australia.svg";
import UKFlag from "../../../assets/common/UK.svg";
import USAFlag from "../../../assets/common/USA.svg";
import Ireland from "../../../assets/common/Ireland.svg";
import ResultCardHomePage from "./ResultCardHomePage";
import Header from "../../../layout/Header";
import AdvanceFilterHomePage from "./AdvanceFilterHomePage";
import educationLogo from "../../../assets/icons/educationLogo.png";
import magnifierBG1 from "../../../assets/icons/magnifer1.png";
import { toast } from "react-toastify";
import CollegeDataService from "../../../Services/CollegeService";
import Pagination from "../../../components/UI/Pagination";
import PageTitle from "../../../components/PageTitle";
import FormatIntkeDate from "../../../components/UI/FormatIntkeDate";
import TokenService from "../../../Services/TokenService";
import { usePlaceholder } from "../../../shared/hooks/usePlaceholder";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import EpLoader from "../../../components/UI/EpLoader";
import { useComponentVisible } from "../../../shared/hooks/useComponentVisible";
import { ListGroup } from "react-bootstrap";
import { format, parse } from "date-fns";
import MobileSeach from "../../../components/UI/MobileSearch";

export default function ProgramsHomePage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const navSearch = searchParams.get("searchQuery");
  const navInput = searchParams.get("input");
  const getUser = TokenService.getUser();

  useEffect(() => {
    if (getUser) navigate("/user/program");
  }, [getUser]);

  const navCountry = searchParams.get("selectedCountry")?.split(",") ?? [
    "Canada",
  ];

  const [isInputClicked, setIsInputClicked] = useState(false);
  const { placeholder } = usePlaceholder({
    stopChangingText: isInputClicked || Boolean(navSearch),
    time: 3950,
  });
  const token = TokenService.getLocalAccessToken();

  // const navCountry = extractInnerArray(
  //   navValue.state?.selectedCountry ?? ["Canada"]
  // );

  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [itemsLength, setItemsLength] = useState(0);

  const handlePageSizeChange = (event) => {
    event.preventDefault();
    setItemsPerPage(parseInt(event.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [programsData, setProgramData] = useState([]);
  const [uniqueCIDs, setUniqueCIDs] = useState();

  const [applySelectedFilters, setApplySelectedFilters] = useState("");

  const {
    Intake,
    Campus: campusLst,
    "College Name": collegeNameLst,
    "Program Type": programType,
    "English Requirement": englishRequirement,
    "Areas of Interest": areasOfInterest,
    "Current residence": currentResidence,
    Nationality,
    Province,
  } = applySelectedFilters;

  const IELTS = englishRequirement?.includes("IELTS");
  const TOEFL = englishRequirement?.includes("TOEFL");
  const PTE = englishRequirement?.includes("PTE");
  const Duolingo = englishRequirement?.includes("Duolingo");
  const GMAT = englishRequirement?.includes("GMAT");
  const GRE = englishRequirement?.includes("GRE");
  const SAT = englishRequirement?.includes("SAT");

  const [appliedDurationAndFee, setAppliedDurationAndFee] = useState({
    durationNumber: "",
    durationPeriod: "",
    applicationFee: -1,
    tuitionFee: -1,
  });

  const [searchQuery, setSearchQuery] = useState(navSearch);

  const [searchResults, setSearchResults] = useState();
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const [collapseStatus, setCollapseStatus] = useState(
    Array(programsData.length).fill(false)
  );
  const [allData, setAllData] = useState([]);
  const [options, setOptions] = useState(allData);
  const [input, setInput] = useState(navInput || navSearch);
  const [selectedCountry, setSelectedCountry] = useState(navCountry);

  const handleCountryChange = (value) => {
    setSelectedCountry((prevSelectedOptions) => {
      const allCountries = countryList.map((country) => country.name);

      if (value === "All") {
        if (prevSelectedOptions.length < allCountries.length) {
          // If "All" is selected and not all countries are already selected, select all countries
          return allCountries;
        } else {
          // If "All" is selected and all countries are already selected, deselect all countries
          return ["Canada"];
        }
      }

      if (prevSelectedOptions.includes(value)) {
        // If the option is already selected, deselect it
        const updatedSelectedOptions = prevSelectedOptions.filter(
          (item) => item !== value
        );

        // Check if there are no selected countries left
        if (updatedSelectedOptions.length === 0) {
          toast.info("Minimum one country is required.");
          return prevSelectedOptions; // Prevent deselection
        }

        // If "All" is selected, deselect it
        const updatedOptions = updatedSelectedOptions.filter(
          (item) => item !== "All"
        );
        return updatedOptions;
      } else {
        // If the option is not selected, select it
        return [...prevSelectedOptions, value];
      }
    });
  };

  // Find the flag image based on the selectedCountry value
  const selectedCountryData = countryList.find((country) =>
    selectedCountry?.includes(country.name)
  );

  const flagImage =
    selectedCountryData && selectedCountry.length === 1
      ? selectedCountryData.flagImage
      : globeIcon;

  const handleCompareClick = (e) => {
    e.preventDefault();
    const selectedProgramsData = selectedPrograms.map((i) => searchResults[i]);
    navigate("/programs/compare", {
      state: { selectedProgramsData, selectedCountry },
    });
  };
  const handleSwitchClick = (e, index) => {
    if (selectedPrograms.length >= 3 && !e.target.checked) {
      e.preventDefault();
      alert("Can't select more than three programs");
      return;
    }

    handleProgramSelect(index);
  };
  const handleProgramSelect = (index) => {
    const isSelected = selectedPrograms.includes(index);
    const updatedPrograms = isSelected
      ? selectedPrograms.filter((programIndex) => programIndex !== index)
      : selectedPrograms.concat(index);
    setSelectedPrograms(updatedPrograms);
  };
  const handleCollapseToggle = (index) => {
    const updatedStatus = [...collapseStatus];
    updatedStatus[index] = !updatedStatus[index];
    setCollapseStatus(updatedStatus);
  };
  const [checkedOptions, setCheckedOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});

  const findProgram = async () => {
    setIsLoading(true);

    try {
      const searchData = {
        currentPage,
        pageSize: itemsPerPage,
        collegeProgramName: searchQuery,
        country: selectedCountry,
        intake: Intake || [],
        campus: campusLst || [],
        duration:
          appliedDurationAndFee.durationNumber === 0
            ? ""
            : `${appliedDurationAndFee.durationNumber} ${appliedDurationAndFee.durationPeriod}`,
        credential: programType || [],
        tuitionFee: appliedDurationAndFee.tuitionFee
          ? parseInt(appliedDurationAndFee.tuitionFee)
          : -1,
        pgWorkPermit: "",
        academicRequirement: "",
        websiteUrl: "",
        ieltsRequirement: !!IELTS,
        pteRequirement: !!PTE,
        toeflRequirement: !!TOEFL,
        duolingoRequirement: !!Duolingo,
        gmat: !!GMAT,
        gre: !!GRE,
        sat: !!SAT,
        areasofInterest: areasOfInterest || [],
        directIndirect: "",
        province: Province || [],
        logo: "",
        collegeName: collegeNameLst || [],
        applicationFee: appliedDurationAndFee.applicationFee
          ? parseInt(appliedDurationAndFee.applicationFee)
          : -1,
        currentResidence: currentResidence || [],
        nationality: Nationality || [],
      };

      const response = await ProgramSearchService.find(searchData);
      const programs = response.data.response;

      const uniqueCollegeIds = Array.from(
        new Set(programs.map((program) => program.collegeId))
      );
      const collegesWithLogos = await Promise.all(
        uniqueCollegeIds.map(async (collegeId) => {
          try {
            const logoResponse = await CollegeDataService.downloadCollegeLogo(
              collegeId
            );
            return {
              collegeId,
              imgUrl: logoResponse?.data?.response.collegeLogoUrl || null,
            };
          } catch (error) {
            console.error(
              `Error fetching logo for college ID ${collegeId}:`,
              error
            );
            return {
              collegeId,
              imgUrl: null,
            };
          }
        })
      );

      setUniqueCIDs(collegesWithLogos);
      setSearchResults(programs);
      setItemsLength(programs[0]?.totalItems);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("An error occurred while fetching data.");
      setIsLoading(false);
    }
  };
  useEffect(() => {
    findProgram();
  }, [
    searchQuery,
    itemsPerPage,
    currentPage,
    applySelectedFilters,
    appliedDurationAndFee,
    selectedCountry,
  ]);

  useEffect(() => {
    findProgram();
  }, [searchQuery]);

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Calculate the scroll position, e.g., by checking window.scrollY
      const scrollPosition = window.scrollY;

      // Determine a threshold value for when the element should become sticky
      const threshold = 200; // Adjust this value according to your needs

      // Update the isSticky state based on the scroll position
      setIsSticky(scrollPosition > threshold);
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSticky]);

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible();

  const getSearchData = useCallback(async () => {
    try {
      const resp = await ProgramSearchService.getAllCollegesAndProgramsList();
      setAllData(resp.data.response);
    } catch (err) {
      toast.err(err.message);
    }
  }, []);

  const removeSelectedOption = (tabTitle, option) => {
    setSelectedOptions((prevSelectedOptions) => {
      const updatedOptions = { ...prevSelectedOptions };

      if (updatedOptions[tabTitle]) {
        updatedOptions[tabTitle] = updatedOptions[tabTitle].filter(
          (item) => item !== option
        );

        if (updatedOptions[tabTitle].length === 0) {
          delete updatedOptions[tabTitle];
        }
      }

      return updatedOptions;
    });
    setCheckedOptions((prevCheckedOptions) => {
      const updatedOptions = { ...prevCheckedOptions };
      if (updatedOptions[tabTitle]) {
        updatedOptions[tabTitle] = updatedOptions[tabTitle].filter(
          (item) => item !== option
        );
        if (updatedOptions[tabTitle].length === 0) {
          delete updatedOptions[tabTitle];
        }
      }
      return updatedOptions;
    });
    setHoveredIndex(null);
    setCurrentPage(1);
  };

  const filteredOptions = useCallback(
    (search) => {
      if (search) {
        const options = allData?.filter((data) => {
          return data.toLowerCase().includes(search.toLowerCase());
        });
        setOptions(options);
        return;
      }
      setOptions(allData);
    },
    [allData]
  );

  const handleSearch = useCallback(
    (event) => {
      const query = event.target.value;
      setInput(query);
      filteredOptions(query);
      setCurrentPage(1);
    },
    [filteredOptions, setCurrentPage]
  );

  const handleClearAllFilters = () => {
    setCurrentPage(1);
    setCheckedOptions([]);
    setSelectedOptions({});
    setApplySelectedFilters("");
    setAppliedDurationAndFee({
      durationNumber: "",
      durationPeriod: "",
      applicationFee: -1,
      tuitionFee: -1,
    });
  };

  useEffect(() => {
    getSearchData();
  }, [getSearchData]);

  useEffect(() => {
    setOptions(allData);
    if (navSearch) {
      filteredOptions(navSearch);
    }
    if (navInput) {
      filteredOptions(navInput);
    }
  }, [allData]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const handleHover = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const formatSessionIntakeDate = (intakeDate) => {
    // Split the input into start and end dates
    const [startDateStr, endDateStr] = intakeDate.split("/");

    const startDate = parse(startDateStr, "dd-MMM-yyyy", new Date());
    const endDate = parse(endDateStr, "dd-MMM-yyyy", new Date());

    const formattedStartDate = format(startDate, "MMM yyyy");
    const formattedEndDate = format(endDate, "MMM yyyy");

    const formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;

    return formattedDateRange;
  };

  const popover = (
    <Popover id="popover-basic">
      <AdvanceFilterHomePage
        setCurrentPage={setCurrentPage}
        applySelectedFilters={applySelectedFilters}
        setApplySelectedFilters={setApplySelectedFilters}
        appliedDurationAndFee={appliedDurationAndFee}
        setAppliedDurationAndFee={setAppliedDurationAndFee}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        checkedOptions={checkedOptions}
        setCheckedOptions={setCheckedOptions}
        isSticky={isSticky}
        searchResults={searchResults}
        formatSessionIntakeDate={formatSessionIntakeDate}
      />
    </Popover>
  );

  return (
    <>
      <PageTitle title={"Search Programs"} />
      <Header />
      <div
        className="bg-dark1 p-md-3"
        style={{
          overflowY: "hidden",
        }}
      >
        <div
          className="bg-white rounded-border"
          style={{ paddingTop: "20rem", minHeight: "80vh" }}
        >
          <div className="px-3">
            <div ref={ref}>
              <div className="row d-none d-sm-block ">
                <div className="col-11 col-sm-6 mx-auto">
                  <div className="rounded-pill p-1 shadow order-1 border">
                    <div className="position-relative d-flex align-items-center  border-0">
                      <div className="dropdown w-25 text1-3">
                        <div
                          className="dropdown-toggle d-flex align-items-center"
                          type="button"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="outside"
                          aria-expanded="false"
                        >
                          <img
                            src={flagImage}
                            alt="flag"
                            className="rounded-circle me-1"
                            width={"36px"}
                          />
                          <span
                            className="text-truncate"
                            style={{ maxWidth: "150px" }}
                          >
                            {selectedCountry?.join(", ")}
                          </span>
                        </div>
                        <ul className="dropdown-menu w-100">
                          {countryList.map((country) => (
                            <div key={country.name}>
                              <li
                                className={`countrySelectedDropDownItem border-top text1-3 `}
                                onClick={() =>
                                  handleCountryChange(country.name)
                                }
                              >
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>{country.name}</div>
                                  <i
                                    className={`bi bi-check fs-3 m-0 p-0 d-flex justify-content-between align-items-center ${
                                      selectedCountry?.includes(country.name)
                                        ? "text-success"
                                        : "text-white"
                                    }`}
                                  ></i>
                                </div>
                              </li>
                            </div>
                          ))}
                        </ul>
                      </div>
                      <div
                        className="position-relative overflow-hidden w-100"
                        onClick={() => {
                          setIsComponentVisible(!isComponentVisible);
                          setIsInputClicked(true);
                        }}
                      >
                        {isInputClicked ||
                        input ||
                        Boolean(navSearch) ? null : (
                          <span
                            className="animated-placeholder position-absolute ms-3 text1-4 opacity-50 w-75 w-sm-100"
                            style={{
                              pointerEvents: "none",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {placeholder}
                          </span>
                        )}
                        <input
                          type="text"
                          className={
                            "form-control border-0 mx-2 py-3 formControl text1-4"
                          }
                          style={{ width: "calc(100% - 100px)" }}
                          value={input}
                          onChange={handleSearch}
                          onBlur={() => {
                            if (!input) setIsInputClicked(false);
                          }}
                        />
                      </div>
                      <div className="d-flex position-absolute bottom-0  w-100 justify-content-end">
                        {isComponentVisible ? (
                          <ListGroup className="dropdown-options-program bg-white shadow">
                            {options?.length ? (
                              <>
                                {options?.map((item, index) => (
                                  <ListGroup.Item
                                    className="text1-4 list-item"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    key={index}
                                    onClick={() => {
                                      if (!item) setSearchQuery("");
                                      setInput(item ? item : "");
                                      setSearchQuery(item ? item : "");
                                      setCurrentPage(1);
                                      setIsComponentVisible(
                                        !isComponentVisible
                                      );
                                    }}
                                  >
                                    {item}
                                  </ListGroup.Item>
                                ))}
                              </>
                            ) : (
                              <ListGroup.Item>No data found</ListGroup.Item>
                            )}
                          </ListGroup>
                        ) : null}
                      </div>
                      <span className="top-50 end-0 position-absolute translate-middle-y">
                        <button
                          className="btn btn-dark-blue rounded-pill px-4 py-3 d-flex align-items-center py-2 text1-3"
                          onClick={() => {
                            setSearchQuery(input);
                            setIsInputClicked(false);
                            if (setCurrentPage) {
                              setCurrentPage(1);
                            }
                          }}
                        >
                          <span className="me-sm-2">
                            <i className="bi bi-search d-flex align-items-center"></i>
                          </span>
                          <p className="my-0 text1-3 d-none d-sm-block">
                            Search
                          </p>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-block d-sm-none">
                <div className="col-11 col-sm-6 mx-auto position-relative">
                  <>
                    <div className="d-flex flex-column justify-content-center align-items-center gap-4 mx-4">
                      <div
                        style={{
                          width: "65%",
                        }}
                        className="d-flex justify-content-center px-2 py-0 dropdown rounded-pill text1-3 bg-light0 shadow border-1 border"
                      >
                        <div
                          className="dropdown-toggle d-flex align-items-center w-100"
                          type="button"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="outside"
                          aria-expanded="false"
                        >
                          <img
                            src={flagImage}
                            alt="flag"
                            className="rounded-circle me-1 py-2"
                            width={"28px"}
                          />
                          <span
                            className="text-truncate d-flex justify-content-center"
                            style={{ width: "150px" }}
                          >
                            {selectedCountry?.join(", ")}
                          </span>
                        </div>
                        <ul className="dropdown-menu w-100">
                          {countryList.map((country) => (
                            <div key={country.name}>
                              <li
                                style={{
                                  cursor: "pointer",
                                }}
                                className={`countrySelectedDropDownItem border-top text1-3`}
                                onClick={() =>
                                  handleCountryChange(country.name)
                                }
                              >
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>{country.name}</div>
                                  <i
                                    className={`bi bi-check fs-3 m-0 p-0 d-flex justify-content-between align-items-center ${
                                      selectedCountry?.includes(country.name)
                                        ? "text-success"
                                        : "text-white"
                                    }`}
                                  ></i>
                                </div>
                              </li>
                            </div>
                          ))}
                        </ul>
                      </div>
                      <div
                        className={`bg-light0 overflow-hidden position-relative p-2 dropdown w-100 rounded-pill  text1-3  shadow border-1 border`}
                        onClick={() => {
                          setIsComponentVisible(!isComponentVisible);
                          setIsInputClicked(true);
                        }}
                      >
                        {isInputClicked ||
                        input ||
                        Boolean(navSearch) ? null : (
                          <span
                            className={`bg-light0 overflow-hidden animated-placeholder position-absolute ms-3 text1-4 opacity-50 w-75`}
                            style={{
                              pointerEvents: "none",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {placeholder}
                          </span>
                        )}
                        <input
                          type="text"
                          className={` bg-light0 form-control border-0 ms-2 py-3 formControl text1-4 rounded-pill`}
                          style={{ width: "calc(100% - 50px)" }}
                          value={input}
                          onChange={handleSearch}
                          onBlur={() => {
                            if (!input) setIsInputClicked(false);
                          }}
                        />

                        <div className="top-50 end-0 position-absolute rounded-pill translate-middle-y me-3">
                          <button
                            className="btn btn-dark-blue rounded-circle"
                            style={{ width: "32px", height: "32px" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setSearchQuery(input);
                              setIsInputClicked(false);
                              if (setCurrentPage) {
                                setCurrentPage(1);
                              }
                            }}
                          >
                            <span className="d-flex justify-content-center align-items-center text1-7">
                              <i
                                className={`bi bi-search justify-content-center align-items-center`}
                              ></i>
                            </span>
                          </button>
                        </div>
                      </div>
                      <div className="d-flex w-100 position-absolute bottom-0 ps-3  justify-content-center mx-5">
                        {isComponentVisible && input ? (
                          <ListGroup
                            style={{
                              maxHeight: "200px",
                            }}
                            className="dropdown-options-program bg-white shadow"
                          >
                            {options?.length ? (
                              <>
                                {options?.map((item, index) => (
                                  <ListGroup.Item
                                    className="text1-4 list-item"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    key={index}
                                    onClick={() => {
                                      if (!item) setSearchQuery("");
                                      setInput(item ? item : "");
                                      setSearchQuery(item ? item : "");
                                      if (setCurrentPage) {
                                        setCurrentPage(1);
                                      }

                                      setIsComponentVisible(
                                        !isComponentVisible
                                      );
                                    }}
                                  >
                                    {item}
                                  </ListGroup.Item>
                                ))}
                              </>
                            ) : (
                              <ListGroup.Item>No data found</ListGroup.Item>
                            )}
                          </ListGroup>
                        ) : null}
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-3 position-relative">
                <div className="position-absolute">
                  <img
                    src={magnifierBG1}
                    alt={"magnifierBG1"}
                    width={200}
                    style={{ opacity: "0.3" }}
                  />
                </div>
              </div>
              <div className="col-3">
                <div
                  className="position-absolute d-none d-sm-block"
                  style={{ top: "100px", right: "100px" }}
                >
                  <img
                    src={educationLogo}
                    alt={"educationLogo"}
                    width={200}
                    style={{ opacity: "0.3" }}
                  />
                </div>
              </div>
              <div className="col-12" style={{ zIndex: "2" }}>
                <div className="row align-items-baseline justify-content-end mt-5 mx-2 mb-1">
                  {/* <div className="col-4 text-center te
xt1-6">
                    {searchResults?.length === 0 ? (
                      "No program found for you"
                    ) : (
                      <span>
                        Total{" "}
                        <span className="font-bold">
                          {searchResults?.length}
                        </span>{" "}
                        programs showing out of{" "}
                        <span className="font-bold">{itemsLength}</span>
                      </span>
                    )}
                  </div> */}
                  <div className="col-4">
                    <div className="d-flex align-items-center justify-content-end text-dark1 column-gap-3">
                      <div className="text1-4 text-dark1">
                        {/* <img src={sortIcon} alt="sortIcon" className="img-fluid" /> */}
                      </div>
                      <div className="text1-4 text-dark1 d-none d-md-block">
                        <select
                          className="form-select rounded-4 text1-3 py-2"
                          aria-label="Items Per Page"
                          value={itemsPerPage}
                          onChange={handlePageSizeChange}
                        >
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={75}>75</option>
                          <option value={100}>100</option>
                        </select>
                      </div>
                      {selectedPrograms.length > 0 && (
                        <CompareButton
                          onClick={handleCompareClick}
                          selectedProgramsLength={selectedPrograms.length}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={`row d-${
                    Object.keys(applySelectedFilters).length === 0
                      ? "none"
                      : isSticky
                      ? "none"
                      : "flex"
                  } `}
                >
                  <div className="col-auto d-flex align-items-center column-gap-1">
                    <button
                      className={`btn btn-sm btn-warning text1-3 rounded-pill p-2`}
                      onClick={handleClearAllFilters}
                    >
                      Clear All
                    </button>
                    <div className="text1-4 d-flex align-items-center">
                      Selected Options:
                    </div>
                  </div>
                  {/* {appliedDurationAndFee &&
                    appliedDurationAndFee.map((r, i) => (
                      <div key={i} className={`col-auto rounded-pill me-1 mb-2 text1-3 text-center border-dark1 text-dark1 `}>
                        <span className="d-flex align-items-center justify-content-center">{r}</span>
                      </div>
                    ))} */}
                  {/* {applySelectedFilters &&
                    Object.entries(applySelectedFilters).map(([tabTitle, selectedValues]) =>
                      selectedValues.map((value, option) => (
                        <div
                          key={`${tabTitle}-${option}`}
                          className={`col-auto rounded-pill me-1 mb-2 text1-3 text-center border-dark1 text-dark1 `}
                        >
                          <span className="d-flex align-items-center justify-content-center">{value.replace(/\//g, ",")}</span>
                          <button onClick={() => removeSelectedOption(tabTitle, option)}>Remove</button>
                        </div>
                      ))
                    )} */}

                  {applySelectedFilters &&
                    Object.entries(applySelectedFilters).map(
                      ([tabTitle, selectedValues]) =>
                        selectedValues.map((option, index) => (
                          <div
                            key={`${tabTitle}-${index}`}
                            className={`col-auto rounded-pill me-1 mb-2 text1-3 text-center ${
                              hoveredIndex === index + tabTitle
                                ? "bg-normal-red text-white border border-danger"
                                : "text-dark1 border-dark1"
                            } `}
                            onMouseOver={() => handleHover(index + tabTitle)}
                            onMouseLeave={handleMouseLeave}
                            onClick={() =>
                              removeSelectedOption(tabTitle, option)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <span className="d-flex align-items-center justify-content-center p-1">
                              {tabTitle === "Areas of Interest"
                                ? option?.replace(/\//g, ",")
                                : tabTitle === "Intake"
                                ? formatSessionIntakeDate(option)
                                : option}
                              {tabTitle}
                              {index}
                              {hoveredIndex === index + tabTitle && (
                                <span className="d-flex align-items-center justify-content-center ms-1">
                                  <i className="bi bi-trash"></i>
                                </span>
                              )}
                            </span>
                          </div>
                        ))
                    )}

                  {/* {applySelectedFilters &&
                    Object.entries(applySelectedFilters).map(([tabTitle, selectedValues]) => (
                      <div key={tabTitle} className="row">
                        {selectedValues.map((option, index) => (
                          <div
                            className={`col-auto rounded-pill me-1 mb-2 text1-3 text-center ${
                              hoveredIndex === tabTitle + index ? "bg-danger text-white" : "border-dark1 text-dark1"
                            } `}
                            style={hoveredIndex === tabTitle + index ? { cursor: "pointer" } : {}}
                            onMouseOver={() => handleHover(tabTitle + index)}
                            onMouseLeave={handleMouseLeave}
                          >
                            <span className="d-flex align-items-center justify-content-center" key={option}>
                              {option}
                              <span className="ms-1" onClick={() => removeSelectedOption(tabTitle, option)}>
                                <i className="bi bi-x-lg"></i>
                              </span>
                            </span>
                          </div>
                        ))}
                      </div>
                    ))} */}
                </div>
              </div>

              <div className="col-12" style={{ zIndex: "2" }}>
                <div className="mt-3 mb-4 d-flex gap-4 px-3 px-sm-0 justify-content-between justify-content-sm-end">
                  <div
                    className="text1-4 text-dark1 mt-1 d-md-none"
                    style={{ width: "54px" }}
                  >
                    <select
                      className="form-select rounded-4 text1-3 py-2"
                      aria-label="Items Per Page"
                      value={itemsPerPage}
                      onChange={handlePageSizeChange}
                    >
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={75}>75</option>
                      <option value={100}>100</option>
                    </select>
                  </div>
                  {itemsLength > 0 && (
                    <Pagination
                      currentPage={currentPage}
                      handlePageChange={handlePageChange}
                      dataLength={itemsLength}
                      itemsPerPage={itemsPerPage}
                    />
                  )}
                </div>
                <div className="row mt-2 pb-3">
                  <div
                    className={`d-none d-sm-block col-sm-2 vh-100 ${
                      isSticky ? "stickyAdvanceFilter " : ""
                    }`}
                  >
                    <div className="d-none d-sm-block">
                      <AdvanceFilterHomePage
                        setCurrentPage={setCurrentPage}
                        applySelectedFilters={applySelectedFilters}
                        setApplySelectedFilters={setApplySelectedFilters}
                        appliedDurationAndFee={appliedDurationAndFee}
                        setAppliedDurationAndFee={setAppliedDurationAndFee}
                        selectedOptions={selectedOptions}
                        setSelectedOptions={setSelectedOptions}
                        checkedOptions={checkedOptions}
                        setCheckedOptions={setCheckedOptions}
                        isSticky={isSticky}
                        searchResults={searchResults}
                        formatSessionIntakeDate={formatSessionIntakeDate}
                      />
                    </div>
                  </div>
                  <div className="d-flex d-sm-none overflow-y-auto">
                    <OverlayTrigger
                      trigger="click"
                      placement="left"
                      overlay={popover}
                      rootClose={true}
                    >
                      <div
                        style={{
                          width: "52px",
                          height: "52px",
                        }}
                        className="border bg-dark1 floating-filter-button rounded-circle d-flex justify-content-center align-items-center"
                      >
                        <i class="bi bi-funnel text-white  text2-5 mt-2"></i>
                      </div>
                    </OverlayTrigger>{" "}
                  </div>
                  {isLoading ? (
                    <div className="col-10">
                      <EpLoader />
                    </div>
                  ) : (
                    <>
                      <div className="col-12 col-sm-10 px-4 px-sm-0 pe-sm-4">
                        <ResultCardHomePage
                          handleSwitchClick={handleSwitchClick}
                          handleCollapseToggle={handleCollapseToggle}
                          selectedPrograms={selectedPrograms}
                          collapseStatus={collapseStatus}
                          searchResults={searchResults}
                          selectedProgramsLength={selectedPrograms.length}
                          findProgram={findProgram}
                          uniqueCIDs={uniqueCIDs}
                        />
                      </div>
                      <div className="col-12">
                        {itemsLength > 0 && (
                          <Pagination
                            currentPage={currentPage}
                            handlePageChange={handlePageChange}
                            dataLength={itemsLength}
                            itemsPerPage={itemsPerPage}
                          />
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const countryList = [
  {
    name: "All",
    value: "All",
    flagImage: globeIcon,
  },
  {
    name: "India",
    value: "India",
    flagImage: IndiaFlag,
  },
  {
    name: "Canada",
    value: "Canada",
    flagImage: CanadaFlag,
  },
  {
    name: "Australia",
    value: "Australia",
    flagImage: AustraliaFlag,
  },
  {
    name: "USA",
    value: "USA",
    flagImage: USAFlag,
  },
  {
    name: "UK",
    value: "UK",
    flagImage: UKFlag,
  },
  {
    name: "New Zealand",
    value: "New Zealand",
    flagImage: NewZealandFlag,
  },
  {
    name: "Ireland",
    value: "Ireland",
    flagImage: Ireland,
  },
];

// function extractInnerArray(data) {
//   if (Array.isArray(data) && Array.isArray(data[0])) {
//     return data[0];
//   }
//   return data;
// }
