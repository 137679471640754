import React, { useState } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import EditColleges from "./EditColleges";
import LogoView from "./LogoView";
import ManageIntakeModal from "./ManageIntakeModal";
export default function ListColleges({
  dataList,
  handleDelete,
  getData,
  dataService,
}) {
  const [openIntakeModal, setOpenIntakeModal] = useState(false)
  const [clgData, setClgData] = useState([])

  return (
    <>
      <div>
        <Row xs={1} md={4} className="g-3">
          {dataList.map((data, idx) => {
            const {
              logo,
              name,
              country,
              province,
              website,
              applicationFee,
              statusId,
              qsRanking,
              webometricScore,
              refundUrl,
              score,
              directIndirect,
            } = data;
            return (
              <Col key={idx} style={{ maxWidth: "400px" }}>
                <Card className="p-2">
                  <LogoView data={data} />
                  <Card.Body className="border-top">
                    <Card.Title className="fw-bold">{`${name}`}</Card.Title>
                    <Card.Title>
                      Application Fee:{" "}
                      <span className="fw-bold">{`$${applicationFee}`}</span>
                    </Card.Title>
                    <Card.Title>
                      qsRanking:{" "}
                      <span className="fw-bold">{`${qsRanking}`}</span>
                    </Card.Title>{" "}
                    <Card.Title>
                      webometricScore:{" "}
                      <span className="fw-bold">{`${webometricScore}`}</span>
                    </Card.Title>
                    <Card.Title>
                      Direct/Indirect:{" "}
                      <span className="fw-bold">{`${directIndirect}`}</span>
                    </Card.Title>
                    <Card.Title>
                      Score: <span className="fw-bold">{`${score ?? 0}`}</span>
                    </Card.Title>
                    <Card.Text>
                      {province}, {country}
                    </Card.Text>
                    <div className="d-flex">

                      {website === null ? (
                        <Card.Text>No Website Link Found</Card.Text>
                      ) : (
                        <Card.Link href={website} target="_blank">
                          Open Website
                        </Card.Link>
                      )}
                      {refundUrl === null ? (
                        <Card.Text>No Refund Link Found</Card.Text>
                      ) : (
                        <Card.Link href={refundUrl} target="_blank">
                          Open Refund URL
                        </Card.Link>
                      )}
                    </div>

                    <Card.Link
                      onClick={() => {
                        setClgData(data)
                        setOpenIntakeModal(true)
                      }}
                      style={{ cursor: 'pointer' }} >
                      Manage Intakes
                    </Card.Link>
                  </Card.Body>
                  <Row className="mx-4 ">
                    <Col>
                      {statusId ? (
                        <Button
                          variant="outline-success"
                          className="w-100 rounded-pill"
                        >
                          <i className="fs-4 d-flex justify-content-center align-items-center bi bi-check2-square"></i>
                        </Button>
                      ) : (
                        <Button
                          variant="outline-secondary"
                          className="w-100 rounded-pill"
                        >
                          <i className="fs-4 d-flex justify-content-center align-items-center bi bi-x-square"></i>
                        </Button>
                      )}
                    </Col>
                    <Col>
                      <EditColleges
                        data={data}
                        getData={getData}
                        dataService={dataService}
                      />
                    </Col>
                    <Col>
                      <Button
                        variant="outline-danger"
                        className="w-100 rounded-pill"
                        onClick={(e) => handleDelete(e, data)}
                      >
                        <i className="fs-4 d-flex justify-content-center align-items-center bi bi-trash"></i>
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
      {openIntakeModal &&
        <ManageIntakeModal
          showModal={openIntakeModal}
          handleCloseModal={() => setOpenIntakeModal(false)}
          clgData={clgData} />
      }
    </>
  );
}
