import React from "react";
import agent from "../../assets/landingPage/become_agent.png";

const OurStory = () => {
  return (
    <div className="d-flex align-items-center flex-column flex-lg-row mb-3 my-md-5">
      <div className="w-100 w-lg-50">
        <p className="text5">Our Story</p>
        <p className="lh-base text1-8">
          Education Planners enriched with the experience of over 11 years in
          the international education domain is a meticulously structured
          organization, which is playing a significant role in helping students
          study in preeminent universities in the course of their choice. Owing
          to the presence of some world-class universities and colleges,
          students often face a dilemma to make the right college choice for
          them. This lead to a lot of confusion and makes students succumbs to
          stress and anxiety. This is where Education Planner comes to their
          rescue, as we not only help them choose the right college and
          university but we also assist them with the complete admission
          process.
        </p>
      </div>
      <div className="w-100 w-lg-50 d-flex justify-content-center">
        <img src={agent} className="img-fluid" alt="our-story" />
      </div>
    </div>
  );
};

export default OurStory;
