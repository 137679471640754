import React from "react";

export default function AdvanceFilterFooter({ handleApplyFilters, handleClearAllFilters }) {
  return (
    <>
      <button type="button" className="btn btn-light-blue-outline rounded-pill py-2 me-2 px-4 text1-3" data-bs-dismiss="modal">
        Cancel
      </button>
      <button
        className="btn bg-dark1 text-white rounded-pill py-2 px-4 text1-3"
        data-bs-dismiss="modal"
        onClick={() => handleClearAllFilters()}
      >
        Clear All Filters
      </button>
      <button
        className="btn bg-dark1 text-white rounded-pill py-2 px-4 text1-3"
        data-bs-dismiss="modal"
        onClick={() => handleApplyFilters()}
      >
        Apply Filter
      </button>
    </>
  );
}
