import React, { useState, useEffect } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Tooltip from "./UI/ToolTip";
import { OverlayTrigger } from "react-bootstrap";
import { StyledTooltip } from "../shared/components/StyledTooltip";

export default function PhoneNumberInput({
  value,
  customCSS,
  onChange,
  resetPhoneNumber,
  error,
  setError,
  ...rest
}) {
  const [contact, setContact] = useState(value);

  // Notify parent component about 'contact' state changes
  useEffect(() => {
    if (onChange) {
      onChange(contact);
    }
  }, [contact, onChange]);

  // Reset the phone number when 'resetPhoneNumber' prop changes
  useEffect(() => {
    if (resetPhoneNumber) {
      setContact("");
    }
  }, [resetPhoneNumber]);

  const handleInputChange = (newValue) => {
    setContact(newValue);
    if (newValue) {
      if (setError) {
        setError("");
      }
      return;
    }
  };

  return (
    <div className="position-relative text-normal-red">
      <PhoneInput
        international
        countryCallingCodeEditable={false}
        defaultCountry="IN"
        value={contact}
        onChange={handleInputChange}
        name="contactNumber"
        placeholder="Enter Phone Number"
        className={`form-control rounded-pill border py-3 px-3 text1-4 ${customCSS} position-relative`}
        required
        error={
          contact
            ? isValidPhoneNumber(contact)
              ? undefined
              : "Invalid phone number"
            : "Phone number required"
        }
        autoComplete="off"
        {...rest}
      />

      {error ? (
        <span className="ms-3 text-danger text1-1">
          <i className="bi bi-exclamation-circle text-danger"></i> {error}
        </span>
      ) : null}
    </div>
  );
}
