import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import ApplicationDataService from "../../Services/ApplicationService";
import RefundService from "../../Services/RefundService";
import { debounce } from "../../shared/helpers";

export function useRefund() {
  const [dropdownData, setDropdownData] = useState({
    agents: [],
    applicationIds: [],
  });

  const [inputData, setInputData] = useState({
    applicationIds: "",
    agents: "",
  });
  const [selected, setSelected] = useState({
    applicationIds: "",
    agents: "",
  });

  const [loading, setLoading] = useState(false);

  const [ledger, setLedger] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const formatDate = (datetime) => {
    if (!datetime) return "";
    const dateObj = new Date(datetime);
    const day = String(dateObj.getDate()).padStart(2, "0");
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const year = String(dateObj.getFullYear()).slice(-2);
    return `${day}${month}${year}`;
  };

  const handlePageChange = useCallback(
    (page) => {
      searchParams.set("page", page);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const getAgentList = useCallback(async (searchQuery) => {
    try {
      const resp = await RefundService.getAllBranchHeadAndCounsellorList({
        currentPage: 1,
        pageSize: 10000,
        search: searchQuery,
      });
      setDropdownData((p) => ({ ...p, agents: resp.data.response }));
    } catch (err) {
      toast.err(err.message);
    }
  }, []);

  const getApplications = useCallback(async (searchQuery) => {
    try {
      const response =
        await ApplicationDataService.getAllStudentApplications_forAllCompaniesAndStudents(
          {
            currentPage: 1,
            pageSize: 10000,
            applicationIdSearch: searchQuery,
          }
        );
      setDropdownData((p) => ({
        ...p,
        applicationIds: response.data.response,
      }));
    } catch (error) {
      toast.err(error.message);
    }
  }, []);

  const handleApplicationDebouncedSearch = useCallback(
    debounce((search) => getApplications(search), 400),
    []
  );

  const handleAgentDebouncedSearch = useCallback(
    debounce((search) => getAgentList(search), 400),
    []
  );
  const handleChange = useCallback(
    async (search, type) => {
      setInputData((p) => ({
        ...p,
        [type]: search,
      }));
      if (type === "applicationIds") {
        handleApplicationDebouncedSearch(search);
      } else if (type === "agents") {
        handleAgentDebouncedSearch(search);
      }
    },
    [handleAgentDebouncedSearch, handleApplicationDebouncedSearch]
  );

  const getRefundLedger = useCallback(async () => {
    try {
      setLoading(true);
      const resp = await RefundService.fetchRefundLedger({
        currentPage: searchParams.get("page") || 1,
        pageSize: searchParams.get("limit") || 10,
        receiverId: selected.agents,
        studentApplicationId: selected.applicationIds,
      });
      setLedger(resp.data.response);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [searchParams, selected]);

  useEffect(() => {
    getAgentList();
    getApplications();
  }, []);

  useEffect(() => {
    getRefundLedger();
  }, [getRefundLedger]);

  return {
    dropdownData,
    setDropdownData,
    inputData,
    setInputData,
    handleChange,
    selected,
    setSelected,
    formatDate,
    ledger,
    loading,
    handlePageChange,
    getRefundLedger,
  };
}
