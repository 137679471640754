import React, { useState, useEffect, useRef } from "react";

const CountUp = ({ start, end, duration, prefix, suffix }) => {
  const [count, setCount] = useState(start);
  const countRef = useRef(null);

  useEffect(() => {
    let animationFrameId;
    let startTime;

    const updateCount = (timestamp) => {
      if (!startTime) {
        startTime = timestamp;
      }

      const elapsedTime = timestamp - startTime;
      if (elapsedTime < duration) {
        const progress = elapsedTime / duration;
        setCount(start + (end - start) * progress);
        animationFrameId = requestAnimationFrame(updateCount);
      } else {
        setCount(end);
      }
    };

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        startTime = null;
        animationFrameId = requestAnimationFrame(updateCount);
      }
    }, options);

    if (countRef.current) {
      observer.observe(countRef.current);
    }

    return () => {
      if (countRef.current) {
        observer.unobserve(countRef.current);
      }
      cancelAnimationFrame(animationFrameId);
    };
  }, [end, duration, start]);

  return (
    <span ref={countRef}>
      {prefix}
      {Math.round(count)}
      {suffix}
    </span>
  );
};

export default CountUp;
