import React, { useEffect, useState } from "react";
import StudentDataService from "../../Services/StudentService";
import LeadDataService from "../../Services/LeadService";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/UI/ConfirmationModal";
export default function PushLead({ lead, retrieveLeads, setUpdating }) {
  const [leadServiceInterestData, setLeadServiceInterestData] = useState({
    id: lead.id,
    firstName: lead.firstName,
    lastName: lead.lastName,
    dateofBirth: lead.dateofBirth,
    gender: lead.gender,
    citizenship: lead.citizenship,
    contactNumber: lead.contactNumber,
    email: lead.email,
    currentCountry: lead.currentCountry,
    postalCode: lead.postalCode,
    qualification: lead.qualification,
    servicesofInterest: lead.servicesofInterest,
    countryofInterest: lead.countryofInterest,
    referralSource: lead.referralSource,
    previousRejection: lead.previousRejection,
    statusId: 1,
  });

  useEffect(() => {
    setLeadServiceInterestData({
      id: lead.id,
      firstName: lead.firstName,
      lastName: lead.lastName,
      dateofBirth: lead.dateofBirth,
      gender: lead.gender,
      citizenship: lead.citizenship,
      contactNumber: lead.contactNumber,
      email: lead.email,
      currentCountry: lead.currentCountry,
      postalCode: lead.postalCode,
      qualification: lead.qualification,
      servicesofInterest: lead.servicesofInterest,
      countryofInterest: lead.countryofInterest,
      referralSource: lead.referralSource,
      previousRejection: lead.previousRejection,
      statusId: 1,
    });
  }, [lead]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLeadServiceInterestData({ ...leadServiceInterestData, [name]: value });
  };

  const [showConfirmation, setShowConfirmation] = useState(false);

  const updateLeadData = (e) => {
    e.preventDefault();
    if (leadServiceInterestData.servicesofInterest === "Sent to student list") {
      setShowConfirmation(true); // Show the Confirmation Modal
    } else {
      handleConfirmUpdate();
    }
  };

  const handleConfirmUpdate = async () => {
    setShowConfirmation(false); // Close the Confirmation Modal
    setUpdating(true);

    try {
      const statusID = 4;
      const response = await LeadDataService.update(leadServiceInterestData);

      if (
        leadServiceInterestData.servicesofInterest === "Sent to student list"
      ) {
        await pushLeadToStudent();
        await LeadDataService.changeStatus(lead.id, statusID);
      }
      toast.success(response.data.message);
      await retrieveLeads();
    } catch (error) {
      toast.error(error);
    } finally {
      setUpdating(false);
    }
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false); // Close the Confirmation Modal
  };

  const pushLeadToStudent = async () => {
    let whatissalutation = "";

    if (lead.gender === "Male") {
      whatissalutation = "Mr";
    } else if (lead.gender === "Female") {
      if (lead.maritalStatus === "Married") {
        whatissalutation = "Mrs";
      } else {
        whatissalutation = "Miss";
      }
    } else {
      whatissalutation = "";
    }

    const data = {
      firstName: lead.firstName,
      lastName: lead.lastName,
      dateofBirth: lead.dateofBirth,
      gender: lead.gender,
      nationality: lead.nationality,
      contactNumber: lead.contactNumber,
      email: lead.email,
      country: lead.country,
      postalCode: lead.postalCode,
      salutation: whatissalutation,
    };

    try {
      const response = await StudentDataService.create(data);

      if (response.data.success === true) {
        toast.success(response.data.message);
      } else if (response.data.validationErrors) {
        response.data.validationErrors.map((verr) => toast.error(verr));
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      <ConfirmationModal
        show={showConfirmation}
        onHide={handleCloseConfirmation}
        onConfirm={handleConfirmUpdate}
        message={`Are you sure you want to send ${leadServiceInterestData.firstName} ${leadServiceInterestData.lastName} to student list?`}
        title="Confirm Sending"
      />

      <div className="form-floating">
        <select
          className="form-select rounded-pill border"
          aria-label="Select"
          required
          id="servicesofInterest"
          name="servicesofInterest"
          onChange={handleInputChange}
          value={leadServiceInterestData.servicesofInterest}
        >
          <option value="">Select</option>
          <option value="IELTS">IELTS</option>
          <option value="PTE">PTE</option>
          <option value="Study visa">Study visa</option>
          <option value="Spouse open work permit">
            Spouse open work permit
          </option>
          <option value="Visitor visa">Visitor visa</option>
          <option value="Sent to student list">Sent to student list</option>
        </select>
        <label htmlFor="floatingInput" className="ms-4 text-light-gray">
          Services of Interest<span className="text-danger">*</span>
        </label>
      </div>
      <button
        className="btn btn-sm btn-success p-2 rounded-circle mx-2"
        onClick={updateLeadData}
      >
        Go
      </button>
      {/* <button className="btn rounded-4 btn-outline-success text1-2" onClick={pushLeadToStudent}>
        Send to student list
      </button> */}
    </>
  );
}
