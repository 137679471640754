import React from "react";
import Slider from "react-slick";
import CollegeLogos from "../../Services/CollegeLogos";

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 6,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 2000,
  cssEase: "linear",
  pauseOnHover: false,
  centerPadding: "60px",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export default function Section2() {
  // const {
  //   loyalistTorontoImage,
  //   capilanoUniversityImage,
  //   niagara1Image,
  //   parklandCollegeImage,
  //   uniManitobaImage,
  //   vancouverCommunityCollegeImage,
  //   westernCommunityCollegeImage,
  // } = CollegeLogos;

  // const settingsFeaturedCollege = {
  //   dots: false,
  //   infinite: true,
  //   slidesToShow: 6,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   speed: 3000,
  //   autoplaySpeed: 3000,
  //   cssEase: "linear",
  //   pauseOnHover: false,
  // };
  // const featuredcollege = [
  //   {
  //     photo: loyalistTorontoImage,
  //   },
  //   {
  //     photo: capilanoUniversityImage,
  //   },
  //   {
  //     photo: niagara1Image,
  //   },
  //   {
  //     photo: parklandCollegeImage,
  //   },
  //   {
  //     photo: uniManitobaImage,
  //   },
  //   {
  //     photo: vancouverCommunityCollegeImage,
  //   },
  //   {
  //     photo: westernCommunityCollegeImage,
  //   },
  // ];

  //const featuredCollegeNames = featuredcollege.map((clg) => Object.keys(CollegeLogos).find((key) => CollegeLogos[key] === clg.photo));

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 text-center  sections-title font-bold">
            <span className="text-dark0">
              Explore exceptional Institutions for your{" "}
            </span>
            <div className="text-dark1">Academic Journey</div>
          </div>

          <div className="mt-5">
            <Slider {...settings}>
              {Object.keys(CollegeLogos).map((collegeName, index) => {
                const collegeImage = CollegeLogos[collegeName];
                return (
                  <div className="row" key={index}>
                    <div className="mx-5 d-flex justify-content-center align-items-center">
                      <div
                        style={{
                          width: "360px",
                          height: "140px",
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                      >
                        <img
                          src={collegeImage}
                          className="img-fluid p-3 w-100 h-100"
                          alt={collegeName}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
            {/* <Slider {...settings}>
              {Object.keys(CollegeLogos).map((collegeName, index) => {
                // Check if the college name is not in the featured colleges
                if (!featuredCollegeNames.includes(collegeName)) {
                  const collegeImage = CollegeLogos[collegeName];
                  return (
                    <div className="col-12" key={index}>
                      <div className="mx-3">
                        <img
                          src={collegeImage}
                          className="img-fluid shadow-sm p-3 rounded-4"
                          alt={collegeName}
                          style={{ width: "240px", height: "120px" }}
                        />
                      </div>
                    </div>
                  );
                }
                return null; // Hide the image
              })}
            </Slider> */}
            {/* <Slider {...settingsFeaturedCollege}>
              {featuredcollege.map((clg, index) => {
                return (
                  <div className="col-12 mt-5" key={index}>
                    <div className="mx-2">
                      <img
                        src={clg.photo}
                        className="img-fluid shadow-sm p-3 rounded-4"
                        style={{ width: "240px", height: "200px" }}
                        alt={index}
                      />
                    </div>
                  </div>
                );
              })}
            </Slider> */}
          </div>
        </div>
      </div>
    </>
  );
}
