import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import UserPanel from "../UserPanel";
import ApplicationDataService from "../../Services/ApplicationService";
import StudentDataService from "../../Services/StudentService";
import FormatDate from "../../components/UI/FormatDate";
import ProgramSearchService from "../../Services/ProgramSearchService";
import NotesBar from "./NotesBar";
import ApplicationStatusList from "../../Services/ApplicationStatusList";
import CollegeDataService from "../../Services/CollegeService";
import companyLogo from "../../assets/logo_dark.png";
import { toast } from "react-toastify";
import IntakeService from "../../Services/IntakeService";
import EpLoader from "../../components/UI/EpLoader";
export default function NotesView() {
  const {
    UnderReview,
    RequireChanges,
    Applicationsubmitted,
    Applicationclosed,
    Offerreceived,
    TuitionFeePending,
    TuitionFeePaid,
    Offerexpired,
    VisaApproved,
    VisaRejected,
    PreRegistered,
    Deferalunderprocess,
    Refund,
    OfferRefused,
  } = ApplicationStatusList;

  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoaded, setIsLoaded] = useState(true);

  const [currentApplication, setCurrentApplication] = useState([
    {
      id: id,
    },
  ]);

  const getApplication = (id) => {
    ApplicationDataService.get(id)
      .then((response) => {
        setCurrentApplication(response.data);
      })
      .catch((e) => {
        alert(
          (e.response && e.response.data && e.response.data.message) ||
            e.message ||
            e.toString()
        );
      });
  };
  const studnetID = currentApplication.studentId;

  const [currentStudent, setCurrentStudent] = useState([]);
  const getStudent = () => {
    StudentDataService.get(studnetID).then((response) => {
      setCurrentStudent(response.data);
    });
  };

  const [programData, setProgramData] = useState([]);
  const [collegeData, setCollegeData] = useState([]);
  const [collegeLogoURL, setCollegeLogoURL] = useState();

  const handleCollegeAndProgram = async () => {
    try {
      const programResponse = await ProgramSearchService.get(
        currentApplication.collegeProgramId
      );
      setProgramData(programResponse.data);

      const collegeResponse = await CollegeDataService.get(
        currentApplication.collegeId
      );
      setCollegeData(collegeResponse.data);

      if (collegeResponse.data.logoName) {
        const logoResponse = await CollegeDataService.downloadCollegeLogo(
          currentApplication.collegeId
        );

        // const blob = new Blob([logoResponse.data], { type: "image/jpeg" });
        // const url = URL.createObjectURL(blob);
        setCollegeLogoURL(logoResponse?.data?.response?.collegeLogoUrl); // Update the state with the image URL
      }

      setIsLoaded(false);
    } catch (error) {
      toast.error(error);
    }
  };

  const [navigating, setNavigating] = useState(false);
  const handleApplicationNav = (e, app) => {
    e.preventDefault();
    setNavigating(true);
    navigate("/user/viewApplication/" + app.studentApplicationId);
  };

  useEffect(() => {
    if (id) {
      getApplication(id);
      handleCollegeAndProgram();
    }
    if (studnetID) {
      getStudent();
    }
    setTimeout(() => {
      setNavigating(false);
    }, 300);
  }, [id, studnetID]);

  if (isLoaded)
    return (
      <div>
        <EpLoader />
      </div>
    );
  return (
    <>
      <div className="p-2 mt-2">
        <div className="row align-items-center">
          <div className="col-12">
            <UserPanel title={"Notes"} />
          </div>
          <div className="col-12">
            <div
              className="bg-dark1 text-white rounded-5 mb-4 p-3"
              style={{ minHeight: "160px" }}
            >
              <div className="row text1-4 py-4 px-5">
                <div className="col-12 text2-5 mb-1 font-bold">
                  Welcome to Education Planner
                </div>
                <div className="col-12">
                  <div className="row text1-4 px-5">
                    <div className="col-4 text2">
                      <div className="text2 font-bold">
                        {currentStudent.salutation} {currentStudent.firstName}{" "}
                        {currentStudent.lastName}
                      </div>
                      <span className="font-bold">Student ID: </span>
                      <FormatDate
                        date={currentStudent.createdDate}
                        idNo={currentStudent.idNumber}
                      />
                    </div>
                    <div className="col-4">
                      <div className="col">
                        <div className="text1-6">
                          Passport No.:{" "}
                          <span className="font-bold">
                            {currentStudent.passportNumber}
                          </span>
                        </div>
                        <div className="text1-6">
                          Email:{" "}
                          <span className="font-bold">
                            {currentStudent.email}
                          </span>
                        </div>
                        <div className="text1-6">
                          Phone No:{" "}
                          <span className="font-bold">
                            {currentStudent.contactNumber}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="text1-6 d-flex align-items-center">
                        Applications:
                        <span className="font-bold ms-1">
                          <div className="d-flex justify-content-center align-items-center">
                            {currentStudent.applications?.map((app, index) => {
                              return (
                                <div key={index} className="dropstart">
                                  <div
                                    className={`border p-2 rounded-circle me-1 d-flex justify-content-center align-items-center ${
                                      id === app.studentApplicationId
                                        ? " bg-orange text-white "
                                        : "bg-white text-orange "
                                    }`}
                                    style={{
                                      width: "34px",
                                      height: "34px",
                                      cursor: "pointer",
                                    }}
                                    data-bs-toggle="dropdown"
                                    data-bs-target={`#dropdown-menu-${app.studentApplicationId}`}
                                    aria-expanded="false"
                                  >
                                    <span className="mt-1 text1-6">
                                      {" "}
                                      {index + 1}
                                    </span>
                                  </div>
                                  <div
                                    className="dropdown-menu rounded-4 p-3"
                                    id={`dropdown-menu-${app.studentApplicationId}`}
                                  >
                                    <div className="text-center text1-3 text-nowrap mb-2">
                                      <strong>{app.collegeName}</strong>
                                    </div>

                                    <div>
                                      <div
                                        className={`rounded-pill px-3 py-2 text1-3 font-bold mx-auto text-center d-flex align-items-center justify-content-center ${
                                          app.applicationStatus === UnderReview
                                            ? "btn-dark-underReview"
                                            : app.applicationStatus ===
                                              RequireChanges
                                            ? "btn-dark-requireChanges"
                                            : app.applicationStatus ===
                                              Applicationsubmitted
                                            ? "btn-dark-applicationSubmitted"
                                            : app.applicationStatus ===
                                              Applicationclosed
                                            ? "btn-dark-applicationClosed"
                                            : app.applicationStatus ===
                                              Offerreceived
                                            ? "btn-dark-offerReceived"
                                            : app.applicationStatus ===
                                              TuitionFeePending
                                            ? "btn-dark-tuitionFeePending"
                                            : app.applicationStatus ===
                                              TuitionFeePaid
                                            ? "btn-dark-tuitionFeePaid"
                                            : app.applicationStatus ===
                                              Offerexpired
                                            ? "btn-dark-offerExpired"
                                            : app.applicationStatus ===
                                              VisaApproved
                                            ? "btn-dark-visaApproved"
                                            : app.applicationStatus ===
                                              VisaRejected
                                            ? "btn-dark-visaRejected"
                                            : app.applicationStatus ===
                                              PreRegistered
                                            ? "btn-dark-preRegistered"
                                            : app.applicationStatus ===
                                              Deferalunderprocess
                                            ? "btn-dark-deferralUnderProcess"
                                            : app.applicationStatus === Refund
                                            ? "btn-dark-refund"
                                            : app.applicationStatus ===
                                              OfferRefused
                                            ? "btn-dark-offerRefused"
                                            : "btn-dark-draft"
                                        }`}
                                        style={{
                                          width: "180px",
                                          height: "44px",
                                        }}
                                      >
                                        {app.applicationStatus}
                                      </div>
                                    </div>
                                    <div>
                                      <button
                                        className="btn btn-outline-dark rounded-pill px-3 py-2 text1-2 mx-auto text-center d-flex align-items-center justify-content-center my-2"
                                        style={{
                                          width: "180px",
                                          height: "44px",
                                        }}
                                        onClick={(e) => {
                                          handleApplicationNav(e, app);
                                          document
                                            .getElementById(
                                              `dropdown-menu-${app.studentApplicationId}`
                                            )
                                            .classList.remove("show");
                                        }}
                                        disabled={
                                          id === app.studentApplicationId
                                        }
                                      >
                                        View Application
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {navigating ? (
          <EpLoader />
        ) : (
          <NotesBar
            currentApplication={currentApplication}
            programDetails={programData}
            imageURL={collegeLogoURL}
            companyLogo={companyLogo}
            collegeDetails={collegeData}
          />
        )}
      </div>
    </>
  );
}
