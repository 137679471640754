import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import ApplicationDataService from "../../Services/ApplicationService";
import RefundService from "../../Services/RefundService";
import { debounce } from "../../shared/helpers";

export function useRefundDialog({ modalShow, setModalShow, getRefundLedger }) {
  const [dropdownData, setDropdownData] = useState({
    agents: [],
    applications: [],
  });

  const [inputData, setInputData] = useState({
    application: "",
    agent: "",
  });

  const [refundInfo, setRefundInfo] = useState({
    agentId: "",
    agentCompanyId: "",
    applicationId: "",
    amount: "",
  });

  const [loading, setLoading] = useState(false);

  const getAgentList = useCallback(async (searchQuery) => {
    try {
      setLoading(true);
      const resp = await RefundService.getAllBranchHeadAndCounsellorList({
        currentPage: 1,
        pageSize: 10000,
        search: searchQuery,
      });
      setDropdownData((p) => ({ ...p, agents: resp.data.response }));
    } catch (err) {
      toast.err(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleApplicationDebouncedSearch = useCallback(
    debounce((search) => getApplicationIds(search), 400),
    []
  );

  const handleAgentDebouncedSearch = useCallback(
    debounce((search) => getAgentList(search), 400),
    []
  );

  const handleInputDataChange = useCallback(
    async (key, value) => {
      setInputData((p) => ({
        ...p,
        [key]: value,
      }));
      if (key === "application") {
        handleApplicationDebouncedSearch(value);
      } else if (key === "agent") {
        handleAgentDebouncedSearch(value);
      }
    },
    [handleAgentDebouncedSearch, handleApplicationDebouncedSearch]
  );
  const handleApplicationStatusChange = useCallback(async (id) => {
    try {
      const data = {
        id: id,
        applicationStatus: "Application Fee Refund",
        remarks: "",
      };

      await ApplicationDataService.updateStatus(data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleIssueRefund = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        const payload = {
          receiverId: refundInfo.agentId,
          receiverCompanyId: refundInfo.agentCompanyId,
          studentApplicationId: refundInfo.applicationId,
          amount: refundInfo.amount,
        };
        const resp = await RefundService.issueRefund(payload);
        await getRefundLedger();
        if (resp.data.success === true) {
          toast(resp.data.message);
          await handleApplicationStatusChange(refundInfo.applicationId);
        }

        setModalShow(false);
      } catch (err) {
        toast.error("There was some error issuing refund");
      }
    },
    [
      getRefundLedger,
      handleApplicationStatusChange,
      refundInfo.agentCompanyId,
      refundInfo.agentId,
      refundInfo.amount,
      refundInfo.applicationId,
      setModalShow,
    ]
  );

  const getApplicationIds = useCallback(
    async (searchQuery) => {
      try {
        const resp = await ApplicationDataService.find({
          companyId: refundInfo.agentCompanyId,
          currentPage: 1,
          pageSize: 10000,
          applicationIdSearch: searchQuery,
        });
        setDropdownData((p) => ({
          ...p,
          applications: resp.data.response,
        }));
      } catch (err) {}
    },
    [refundInfo.agentCompanyId]
  );

  useEffect(() => {
    getAgentList();
    // getApplications();
  }, []);

  useEffect(() => {
    if (!refundInfo.agentCompanyId) return;
    getApplicationIds();
  }, [refundInfo.agentCompanyId]);

  return {
    inputData,
    setInputData,
    handleInputDataChange,
    loading,
    handleIssueRefund,
    refundInfo,
    setRefundInfo,
    dropdownData,
  };
}
