import "./MarketPlace.css";
import React from "react";
import gicCorner from "../../assets/marketplace/card-corner.png";
import gicMain from "../../assets/marketplace/cibcMain.png";
import icici from "../../assets/images/icici.svg";
import cibc from "../../assets/images/cibc.svg";
import mapleLeaf from "../../assets/marketplace/maple-leaf.png";
import { useNavigate } from "react-router-dom";
import RolesService from "../../Services/RolesService";
import TokenService from "../../Services/TokenService";

export default function CIBCCard({ setShowModal }) {
  const { BranchHead } = RolesService;
  const getRole = TokenService.getUserRole();
  const navigate = useNavigate();
  return (
    <div className="gic-card blue-bgImage">
      <div className="d-flex gap-4 mt-md-4 mt-5 pt-4 align-items-center px-5">
        <img src={gicMain} alt="250 CAD" className="h-100" />
        <div className="d-flex flex-column gap-3">
          <p className="text1-7 text-dark1 my-0">
            Streamline your study permit application with a GIC from CIBC Bank
          </p>
          <div className="d-flex gap-3">
            <img src={cibc} alt="icici" />
            <img src={mapleLeaf} alt="maple leaf" />
          </div>
        </div>
      </div>
      <div className="px-5 w-100 d-flex justify-content-center gap-md-4 gap-2 position-absolute bottom-buttons">
        <button className="bg-white text1-3 rounded-pill py-2 px-3 border-0 text-dark1">
          Instruction Guide
        </button>
        <button
          onClick={() => navigate("/user/marketplace/details")}
          className="bg-white text1-3 rounded-pill py-2 px-3 border-0 text-dark1"
        >
          View Details
        </button>
        {getRole === BranchHead ? (
          <button
            onClick={() => setShowModal(true)}
            className="bg-white text1-3 rounded-pill py-2 px-3 border-0 text-dark1 "
          >
            Apply Now
          </button>
        ) : null}
        <button className=" d-flex align-items-center bg-white text1-3 rounded-pill py-2 px-3 border-0 text-dark1">
          <i class="bi bi-youtube text-danger me-1 mt-1 "></i>{" "}
          <span>YouTube</span>
        </button>
      </div>
    </div>
  );
}
