import Tooltip from "react-bootstrap/Tooltip";
import "./StyledTooltip.css";

export function StyledTooltip({ text, ...rest }) {
  return (
    <Tooltip id="tooltip" {...rest} style={{ position: "fixed" }}>
      {text}
    </Tooltip>
  );
}
