import React, { useCallback, useEffect, useMemo, useState } from "react";
import notificationIconDarkBlue from "../../assets/icons/notification_darkBlue.svg";
import editIcon from "../../assets/icons/edit.svg";
import applicationIcon from "../../assets/icons/doc.svg";
import uploadIcon from "../../assets/icons/upload.svg";
import statusIcon from "../../assets/icons/change.svg";
import { Link, useLocation } from "react-router-dom";
import ApplicationNoteService from "../../Services/ApplicationNoteService";
import FormatDate from "../../components/UI/FormatDate";
import TokenService from "../../Services/TokenService";
import RolesService from "../../Services/RolesService";
import PageTitle from "../../components/PageTitle";
import UserPanel from "../UserPanel";
import EpLoader from "../../components/UI/EpLoader";

function NotificationDetails({ data }) {
  const handleReadNotification = useCallback((data) => {
    if (!data.isRead) {
      ApplicationNoteService.markNotificationAsRead({
        notificationIds: [data.id],
      });
    }
  }, []);
  const notificationText = useMemo(() => {
    switch (data.type) {
      case "ApplicationStatusUpdate":
        return (
          <Link
            to={`/user/viewApplication/${data.referenceId}`}
            onClick={() => handleReadNotification(data)}
            className="text-decoration-none"
          >
            <div
              className={
                !data.isRead
                  ? `row  align-items-center ps-4 p-3 bg-stripped1`
                  : `row align-items-center ps-4 p-3 bg-light`
              }
              key={data.id}
            >
              <div
                className="d-grid col-4  align-items-center justify-content-center rounded-circle bg-light-blue2 "
                style={{
                  width: "38px",
                  height: "38px",
                  cursor: "pointer",
                }}
              >
                <img
                  src={statusIcon}
                  alt="notificationIcon"
                  className="img-fluid"
                />
              </div>
              <div className="d-grid col">
                <p
                  className={
                    !data.isRead
                      ? "text1-2 ms-2 mb-0 text-dark"
                      : "text1-2 ms-2  mb-0 text-secondary"
                  }
                >
                  <strong className="text-dark1">{data.sender} </strong>changed
                  application status for{" "}
                  <strong className="text-dark1">
                    {" "}
                    {data.options.studentName}{" "}
                  </strong>{" "}
                  {
                    <FormatDate
                      date={data.options.createdAt}
                      idNo={data.options.idNumber}
                    />
                  }{" "}
                  to {data.remarks}
                </p>
              </div>
            </div>
          </Link>
        );
      case "ApplicationNoteCreate":
        return (
          <Link
            to={`/user/viewApplication/${data.referenceId}?tabName=notes`}
            onClick={() => handleReadNotification(data)}
            className="text-decoration-none"
          >
            <div
              className={
                !data.isRead
                  ? `row  align-items-center ps-4 p-3 bg-stripped1`
                  : `row align-items-center ps-4 p-3 bg-light`
              }
              key={data.id}
            >
              <div
                className="d-grid col-4  align-items-center justify-content-center rounded-circle bg-light-blue2 "
                style={{
                  width: "38px",
                  height: "38px",
                  cursor: "pointer",
                }}
              >
                <img
                  src={applicationIcon}
                  alt="notificationIcon"
                  className="img-fluid"
                  style={{
                    height: "22px",
                    width: "22px",
                  }}
                />
              </div>
              <div className="d-grid col">
                <p
                  className={
                    !data.isRead
                      ? "text1-2 ms-3 mb-0 text-dark"
                      : "text1-2 ms-3 mb-0 text-secondary"
                  }
                >
                  <strong className="text-dark1">{data.sender} </strong> added a
                  note for{" "}
                  <strong className="text-dark1">
                    {" "}
                    {data.options.studentName}{" "}
                  </strong>{" "}
                  {
                    <FormatDate
                      date={data.options.createdAt}
                      idNo={data.options.idNumber}
                    />
                  }
                </p>
              </div>
            </div>
          </Link>
        );

      case "DocumentUpload":
        return (
          <Link
            to={`/user/updateStudent/${data.referenceId}`}
            onClick={() => handleReadNotification(data)}
            className="text-decoration-none"
          >
            <div
              className={
                !data.isRead
                  ? `row  align-items-center ps-4 p-3 bg-stripped1`
                  : `row align-items-center ps-4 p-3 bg-light`
              }
              key={data.id}
            >
              <div
                className="d-grid col-4  align-items-center justify-content-center rounded-circle bg-light-blue2 "
                style={{
                  width: "38px",
                  height: "38px",
                  cursor: "pointer",
                }}
              >
                <img
                  src={uploadIcon}
                  alt="notificationIcon"
                  className="img-fluid"
                  style={{
                    height: "22px",
                    width: "22px",
                  }}
                />
              </div>
              <div className="d-grid col">
                <p
                  className={
                    !data.isRead
                      ? "text1-2 ms-3 mb-0 text-dark"
                      : "text1-2 ms-3 mb-0 text-secondary"
                  }
                >
                  <strong className="text-dark1">{data.sender} </strong>{" "}
                  uploaded a new document for{" "}
                  <strong className="text-dark1">
                    {" "}
                    {data.options.studentName}{" "}
                  </strong>{" "}
                  {
                    <FormatDate
                      date={data.options.createdAt}
                      idNo={data.options.idNumber}
                    />
                  }
                </p>
              </div>
            </div>
          </Link>
        );
      case "EditRequestStatusUpdate":
        return (
          <Link
            to={`/user/updateStudent/${data.referenceId}`}
            onClick={() => handleReadNotification(data)}
            className="text-decoration-none"
          >
            <div
              className={
                !data.isRead
                  ? `row  align-items-center ps-4 p-3 bg-stripped1`
                  : `row align-items-center ps-4 p-3 bg-light`
              }
              key={data.id}
            >
              <div
                className="d-grid col-4  align-items-center justify-content-center rounded-circle bg-light-blue2 "
                style={{
                  width: "38px",
                  height: "38px",
                  cursor: "pointer",
                }}
              >
                <img
                  src={editIcon}
                  alt="notificationIcon"
                  className="img-fluid"
                  style={{
                    height: "22px",
                    width: "22px",
                  }}
                />
              </div>
              <div className="d-grid col">
                <p
                  className={
                    !data.isRead
                      ? "text1-2 ms-3 mb-0 text-dark"
                      : "text1-2 ms-3 mb-0 text-secondary"
                  }
                >
                  Student edit request{" "}
                  {data.options.statusId === 1 ? "declined" : "approved"} for{" "}
                  <strong className="text-dark1">
                    {" "}
                    {data.options.studentName}{" "}
                  </strong>{" "}
                  {
                    <FormatDate
                      date={data.options.createdAt}
                      idNo={data.options.idNumber}
                    />
                  }
                </p>
              </div>
            </div>
          </Link>
        );

      case "CreateStudentApplication":
        return (
          <Link
            to={`/user/updateStudent/${data?.options?.studentId}`}
            onClick={() => handleReadNotification(data)}
            className="text-decoration-none"
          >
            <div
              className={
                !data.isRead
                  ? `row  align-items-center ps-4 p-3 bg-stripped1`
                  : `row align-items-center ps-4 p-3 bg-light`
              }
              key={data.id}
            >
              <div
                className="d-grid col-4  align-items-center justify-content-center rounded-circle bg-light-blue2 "
                style={{
                  width: "38px",
                  height: "38px",
                  cursor: "pointer",
                }}
              >
                <img
                  src={applicationIcon}
                  alt="notificationIcon"
                  className="img-fluid"
                  style={{
                    height: "22px",
                    width: "22px",
                  }}
                />
              </div>
              <div className="d-grid col">
                <p
                  className={
                    !data.isRead
                      ? "text1-2 ms-3 mb-0 text-dark"
                      : "text1-2 ms-3 mb-0 text-secondary"
                  }
                >
                  A new application is received from {data.options.companyName}{" "}
                  for
                  <strong className="text-dark1">
                    {" "}
                    {data.options.studentName}{" "}
                  </strong>{" "}
                  {data.options.collegeProgramName} for {data.options.Intake}
                </p>
              </div>
            </div>
          </Link>
        );
      case "CreateEditRequest":
        return (
          <Link
            to={`/user/updateStudent/${data.referenceId}`}
            onClick={() => handleReadNotification(data)}
            className="text-decoration-none"
          >
            <div
              className={
                !data.isRead
                  ? `row  align-items-center ps-4 p-3 bg-stripped1`
                  : `row align-items-center ps-4 p-3 bg-light`
              }
              key={data.id}
            >
              <div
                className="d-grid col-4  align-items-center justify-content-center rounded-circle bg-light-blue2 "
                style={{
                  width: "38px",
                  height: "38px",
                  cursor: "pointer",
                }}
              >
                <img
                  src={editIcon}
                  alt="notificationIcon"
                  className="img-fluid"
                  style={{
                    height: "22px",
                    width: "22px",
                  }}
                />
              </div>
              <div className="d-grid col">
                <p
                  className={
                    !data.isRead
                      ? "text1-2 ms-3 mb-0 text-dark"
                      : "text1-2 ms-3 mb-0 text-secondary"
                  }
                >
                  An edit request has been submitted for the student
                  <strong className="text-dark1">
                    {" "}
                    {data.options.studentName}{" "}
                  </strong>{" "}
                  ({" "}
                  {
                    <FormatDate
                      date={data.options.createdAt}
                      idNo={data.options.idNumber}
                    />
                  }
                  ) with passport number {data.options.passportNumber}
                </p>
              </div>
            </div>
          </Link>
        );

      case "Refund":
        return (
          <Link
            to={`/user/viewApplication/${data.referenceId}`}
            onClick={() => handleReadNotification(data)}
            className="text-decoration-none"
          >
            <div
              className={
                !data.isRead
                  ? `row  align-items-center ps-4 p-3 bg-stripped1`
                  : `row align-items-center ps-4 p-3 bg-light`
              }
              key={data.id}
            >
              <div
                className="d-grid col-4  align-items-center justify-content-center rounded-circle bg-light-blue2 "
                style={{
                  width: "38px",
                  height: "38px",
                  cursor: "pointer",
                }}
              >
                <img
                  src={notificationIconDarkBlue}
                  alt="notificationIcon"
                  className="img-fluid"
                  style={{
                    height: "22px",
                    width: "22px",
                  }}
                />
              </div>
              <div className="d-grid col">
                <p
                  className={
                    !data.isRead
                      ? "text1-2 ms-3 mb-0 text-dark"
                      : "text1-2 ms-3 mb-0 text-secondary"
                  }
                >
                  <strong className="text-dark1"> {data.sender} </strong>{" "}
                  refunded for{" "}
                  <strong className="text-dark1">
                    {" "}
                    {data.options.studentName}{" "}
                  </strong>{" "}
                  {
                    <FormatDate
                      date={data.options.createdAt}
                      idNo={data.options.idNumber}
                    />
                  }
                </p>
              </div>
            </div>
          </Link>
        );
      case "Register":
        return (
          <Link
            to={``}
            onClick={() => handleReadNotification(data)}
            className="text-decoration-none"
          >
            <div
              className={
                !data.isRead
                  ? `row  align-items-center ps-4 p-3 bg-stripped1`
                  : `row align-items-center ps-4 p-3 bg-light`
              }
              key={data.id}
            >
              <div
                className="d-grid col-4  align-items-center justify-content-center rounded-circle bg-light-blue2 "
                style={{
                  width: "38px",
                  height: "38px",
                  cursor: "pointer",
                }}
              >
                <i class="bi bi-person-add text2 text-dark1"></i>
              </div>
              <div className="d-grid col">
                <p
                  className={
                    !data.isRead
                      ? "text1-2 ms-3 mb-0 text-dark"
                      : "text1-2 ms-3 mb-0 text-secondary"
                  }
                >
                  A new user has signed up , name
                  <strong className="text-dark1">
                    {" "}
                    {data.options.name}{" "}
                  </strong>{" "}
                  , and email id {data.options.email}
                </p>
              </div>
            </div>
          </Link>
        );

      case "AssignStudentApplication":
        return (
          <Link
            to={`/user/viewApplication/${data.referenceId}`}
            onClick={() => handleReadNotification(data)}
            className="text-decoration-none"
          >
            <div
              className={
                !data.isRead
                  ? `row  align-items-center ps-4 p-3 bg-stripped1`
                  : `row align-items-center ps-4 p-3 bg-light`
              }
              key={data.id}
            >
              <div
                className="d-grid col-4  align-items-center justify-content-center rounded-circle bg-light-blue2 "
                style={{
                  width: "38px",
                  height: "38px",
                  cursor: "pointer",
                }}
              >
                <img
                  src={notificationIconDarkBlue}
                  alt="notificationIcon"
                  className="img-fluid"
                  style={{
                    height: "22px",
                    width: "22px",
                  }}
                />
              </div>
              <div className="d-grid col">
                <p
                  className={
                    !data.isRead
                      ? "text1-2 ms-3 mb-0 text-dark"
                      : "text1-2 ms-3 mb-0 text-secondary"
                  }
                >
                  A new application has been assigned to you for review:
                  <strong className="text-dark1">
                    {" "}
                    {data.options.studentName}{" "}
                  </strong>{" "}
                  , program- {data.options.collegeProgramName}, intake:{" "}
                  {data.options.intake}
                </p>
              </div>
            </div>
          </Link>
        );
      case "CreateLead":
        return (
          <Link
            to={`/user/lead`}
            onClick={() => handleReadNotification(data)}
            className="text-decoration-none"
          >
            <div
              className={
                !data.isRead
                  ? `row  align-items-center ps-4 p-3 bg-stripped1`
                  : `row align-items-center ps-4 p-3 bg-light`
              }
              key={data.id}
            >
              <div
                className="d-grid col-4  align-items-center justify-content-center rounded-circle bg-light-blue2 "
                style={{
                  width: "38px",
                  height: "38px",
                  cursor: "pointer",
                }}
              >
                <img
                  src={notificationIconDarkBlue}
                  alt="notificationIcon"
                  className="img-fluid"
                  style={{
                    height: "22px",
                    width: "22px",
                  }}
                />
              </div>
              <div className="d-grid col">
                <p
                  className={
                    !data.isRead
                      ? "text1-2 ms-3 mb-0 text-dark"
                      : "text1-2 ms-3 mb-0 text-secondary"
                  }
                >
                  <strong className="text-dark1"> {data.sender} </strong> added
                  a new lead for{" "}
                  <strong className="text-dark1"> {data.options.name} </strong>{" "}
                  {/* {
                    <FormatDate
                      date={data.options.createdAt}
                      idNo={data.options.idNumber}
                    />
                  } */}
                </p>
              </div>
            </div>
          </Link>
        );
      default:
        return <p />;
    }
  }, [data, handleReadNotification]);

  return notificationText;
}

const pageTitle = "Notifications";

export default function Notification() {
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getRecentNotifications = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await ApplicationNoteService.fetchNotifications();

      setNotifications(response.data.response);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getRecentNotifications();
  }, [getRecentNotifications]);

  return (
    <>
      <PageTitle title={pageTitle} />
      <UserPanel title={""} />
      <div style={{ padding: "20px 80px 10px 80px" }}>
        <p className=" text-center p-3 text3  text-white bg-dark1  text-uppercase">
          {pageTitle}
        </p>

        {notifications?.length > 0 ? (
          notifications?.map((notif) => <NotificationDetails data={notif} />)
        ) : isLoading ? (
          <EpLoader />
        ) : (
          <p className="text-center text3 pt-5 mt-5">No Data Found</p>
        )}
      </div>
    </>
  );
}
