import http from "./api";

const create = (data) => {
  return http.post("EditRequest", data);
};

const get = (params) => {
  return http.get("EditRequest/search?", { params });
};

const update = (data) => {
  return http.put("EditRequest", data);
};

const RequestService = {
  create,
  get,
  update,
};

export default RequestService;
