import React, { useState } from "react";
import PhoneNumberInput from "../../components/PhoneNumberInput";
import AgentDataService from "../../Services/AgentsService";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
export default function AgentEdit({ agent, retrieveAgents, setModalShow }) {
  const currentAgent = agent.companyUsers[0];
  const [agentData, setAgentData] = useState({
    id: agent.id,
    name: agent.name,
    firstName: currentAgent?.firstName,
    lastName: currentAgent?.lastName,
    phoneNumber: currentAgent?.phoneNumber,
    email: currentAgent?.email,
    role: currentAgent?.role,
    statusId: agent.statusId,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAgentData({ ...agentData, [name]: value });
  };

  const handlePhoneNumberChange = (value) => {
    if (value) {
      agentData.contactNumber = value;
    }
  };

  const updateAgentData = (e) => {
    e.preventDefault();
    AgentDataService.update(agentData)
      .then((response) => {
        retrieveAgents();
        if (response.data === true) {
          toast.success("Updated successfully");
          setModalShow(false);
        } else {
          toast.error("Somehting went wrong");
          setModalShow(false);
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  return (
    <>
      <form className="needs-validation" onSubmit={updateAgentData}>
        <div className="row g-3 p-3">
          <div className="col-6 form-floating px-3">
            <input
              type="text"
              className="form-control rounded-pill border"
              placeholder="Name*"
              required
              id="name"
              value={agentData.name}
              onChange={handleInputChange}
              name="name"
              autoComplete="off"
            />
            <label htmlFor="floatingInput" className="ms-4 text-light-gray ">
              Name<span className="text-danger">*</span>
            </label>
          </div>
          <div className="col-6 form-floating px-3">
            <input
              type="text"
              className="form-control rounded-pill border"
              placeholder="First name*"
              required
              id="firstName"
              value={agentData.firstName}
              onChange={handleInputChange}
              name="firstName"
              autoComplete="off"
              disabled
            />
            <label htmlFor="floatingInput" className="ms-4 text-light-gray ">
              First Name<span className="text-danger">*</span>
            </label>
          </div>
          <div className="col-6 form-floating px-3">
            <input
              type="text"
              className="form-control rounded-pill border"
              placeholder="Last name"
              required
              id="lastName"
              value={agentData.lastName}
              onChange={handleInputChange}
              name="lastName"
              autoComplete="off"
              disabled
            />
            <label htmlFor="floatingInput" className="ms-4 text-light-gray">
              Last Name<span className="text-danger">*</span>
            </label>
          </div>

          <div className="col-6 form-floating px-3">
            <input
              type="text"
              className="form-control rounded-pill border"
              placeholder="Phone Number"
              required
              id="phoneNumber"
              value={agentData.phoneNumber}
              onChange={handleInputChange}
              name="phoneNumber"
              autoComplete="off"
              disabled
            />
            <label htmlFor="floatingInput" className="ms-4 text-light-gray">
              Phone Number<span className="text-danger">*</span>
            </label>

            {/* <PhoneNumberInput customCSS={""} value={agentData.phoneNumber} onChange={handlePhoneNumberChange} /> */}
          </div>
          <div className="col-6 form-floating px-3">
            <input
              type="email"
              className="form-control rounded-pill border"
              placeholder="Email"
              required
              id="email"
              value={agentData.email}
              autoComplete="off"
              disabled
              name="email"
            />
            <label htmlFor="floatingInput" className="ms-4 text-light-gray">
              Email<span className="text-danger">*</span>
            </label>
          </div>
          <div className="col-6 form-floating px-3">
            <input
              type="text"
              className="form-control rounded-pill border"
              placeholder="Role"
              required
              id="role"
              value={agentData.role}
              autoComplete="off"
              disabled
              //onChange={handleInputChange}
              name="role"
            />
            <label htmlFor="floatingInput" className="ms-4 text-light-gray">
              Role<span className="text-danger">*</span>
            </label>
          </div>
          <div className="col-6 form-floating px-3">
            <select
              className="form-select rounded-pill border"
              aria-label="Choose status"
              required
              id="statusId"
              value={agentData.statusId}
              onChange={handleInputChange}
              name="statusId"
              autoComplete="off"
            >
              <option value="">Choose status</option>
              <option value={1}>Active</option>
              <option value={2}>Inactive</option>
            </select>
            <label htmlFor="floatingInput" className="ms-4 text-light-gray">
              Choose status<span className="text-danger">*</span>
            </label>
          </div>
        </div>

        <Modal.Footer>
          <button type="button" className="btn btn-light-blue-outline rounded-pill py-3 me-2 px-5" onClick={() => setModalShow(false)}>
            Cancel
          </button>
          <button type="submit" className="btn btn-dark-blue rounded-pill py-3 px-5">
            Update
          </button>
        </Modal.Footer>
      </form>
    </>
  );
}
