import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
const placeholderTexts = [
  "Program Search (For e.g. - Business)",
  "Institute Search (For e.g. - NAIT)",
];

export function usePlaceholder({ stopChangingText, time = 4000 }) {
  const [placeholder, setPlaceholder] = useState(0);

  useEffect(() => {
    if (stopChangingText) return;

    const intervalId = setInterval(() => {
      setPlaceholder((prev) => {
        if (prev === placeholderTexts.length - 1) {
          return 0;
        }
        return prev + 1;
      });
    }, time);

    return () => clearInterval(intervalId);
  }, [stopChangingText, time]);

  return {
    placeholder: placeholderTexts[placeholder],
  };
}
