import React from "react";
import Checkbox from "../../components/Form/Checkbox";
import DatePicker from "react-datepicker";
import { isEditable } from "@testing-library/user-event/dist/utils";

export default function EnglishProficiencyTest({
  studentData,
  handleCheckboxChange,
  checkboxes,
  handleTestChange,
  handleRemoveEPT,
  currentEPTests,
  allDraftApplication,
  disabled,
  isEditable,
}) {
  return (
    <>
      <div className="d-flex align-items-center bg-stripped1 border border-top-0 rounded-4 p-3 font-bold  mb-3">
        English Proficiency Test/Other Test
        <div className="d-flex align-items-center ms-5">
          {checkboxes.map((checkbox) => (
            <Checkbox
              key={checkbox.id}
              id={checkbox.id}
              label={checkbox.type}
              value={checkbox.value}
              required={checkbox.required}
              onChange={handleCheckboxChange}
              isChecked={checkbox.isChecked}
              customCSS={true}
              allDraftApplication={allDraftApplication}
              disabled={disabled}
            />
          ))}
        </div>
        {/* <div className="text-end d-flex justify-content-end ms-5">
          <button type="button" className="btn bg-dark1 text-white rounded-pill py-3 me-2 px-5 text1-4" onClick={saveEPTest}>
            Save
          </button>
        </div> */}
      </div>
      <div className="row align-items-center">
        <div className="col-12">
          {/* Score Details */}
          {checkboxes.map((checkbox) => {
            if (checkbox.isChecked) {
              const test = currentEPTests.find((t) => t.type === checkbox.type);
              if (test) {
                switch (checkbox.id) {
                  case "checkBoxIELTS":
                    return (
                      <IELTSDetails
                        key={checkbox.id}
                        test={test}
                        handleTestChange={(e) =>
                          handleTestChange(e, checkbox.type)
                        }
                        handleRemoveEPT={handleRemoveEPT}
                        allDraftApplication={allDraftApplication}
                        disabled={disabled}
                        isEditable={isEditable}
                      />
                    );
                  case "checkBoxTOEFL":
                    return (
                      <TOEFLDetails
                        key={checkbox.id}
                        test={test}
                        handleTestChange={(e) =>
                          handleTestChange(e, checkbox.type)
                        }
                        handleRemoveEPT={handleRemoveEPT}
                        allDraftApplication={allDraftApplication}
                        disabled={disabled}
                        isEditable={isEditable}
                      />
                    );
                  case "checkBoxPTE":
                    return (
                      <PTEDetails
                        key={checkbox.id}
                        test={test}
                        handleTestChange={(e) =>
                          handleTestChange(e, checkbox.type)
                        }
                        handleRemoveEPT={handleRemoveEPT}
                        allDraftApplication={allDraftApplication}
                        disabled={disabled}
                        isEditable={isEditable}
                      />
                    );
                  case "checkBoxDuolingo":
                    return (
                      <DuolingoDetails
                        key={checkbox.id}
                        test={test}
                        handleTestChange={(e) =>
                          handleTestChange(e, checkbox.type)
                        }
                        handleRemoveEPT={handleRemoveEPT}
                        allDraftApplication={allDraftApplication}
                        disabled={disabled}
                        isEditable={isEditable}
                      />
                    );
                  case "checkBoxGMAT":
                    return (
                      <GMATDetails
                        key={checkbox.id}
                        test={test}
                        handleTestChange={(e) =>
                          handleTestChange(e, checkbox.type)
                        }
                        handleRemoveEPT={handleRemoveEPT}
                        allDraftApplication={allDraftApplication}
                        disabled={disabled}
                        isEditable={isEditable}
                      />
                    );
                  case "checkBoxGRE":
                    return (
                      <GREDetails
                        key={checkbox.id}
                        test={test}
                        handleTestChange={(e) =>
                          handleTestChange(e, checkbox.type)
                        }
                        handleRemoveEPT={handleRemoveEPT}
                        allDraftApplication={allDraftApplication}
                        disabled={disabled}
                        isEditable={isEditable}
                      />
                    );
                  case "checkBoxSAT":
                    return (
                      <SATDetails
                        key={checkbox.id}
                        test={test}
                        handleTestChange={(e) =>
                          handleTestChange(e, checkbox.type)
                        }
                        handleRemoveEPT={handleRemoveEPT}
                        allDraftApplication={allDraftApplication}
                        disabled={disabled}
                        isEditable={isEditable}
                      />
                    );
                  default:
                    return (
                      <IELTSDetails
                        key={checkbox.id}
                        test={test}
                        handleTestChange={(e) =>
                          handleTestChange(e, checkbox.type)
                        }
                        handleRemoveEPT={handleRemoveEPT}
                        allDraftApplication={allDraftApplication}
                        disabled={disabled}
                        isEditable={isEditable}
                      />
                    );
                }
              }
            }
            return null;
          })}
        </div>
      </div>
    </>
  );
}

const IELTSDetails = ({
  test,
  handleTestChange,
  handleRemoveEPT,
  allDraftApplication,
  disabled,
  isEditable,
}) => {
  function convertUTCToLocalDate(date) {
    if (!date) {
      return date;
    }

    const localOffset = new Date().getTimezoneOffset(); // Get local timezone offset in milliseconds
    const utcDate = new Date(date);
    const localDate = new Date(utcDate.getTime() - localOffset);

    return localDate;
  }
  const handleDateChange = (value) => {
    const dateString = value;
    const date = new Date(dateString);
    const utcDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000
    );
    const formattedDate = utcDate.toISOString();
    handleTestChange({ target: { name: "testDate", value: formattedDate } });
  };

  return (
    <div className="p-4 rounded-4 border border-dark mb-4 position-relative">
      <div className="row g-3">
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Overall Score*"
            required
            id="overallScore"
            name="overallScore"
            value={test.overallScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray ">
            Overall Score<span className="text-danger">*</span>
          </label>
        </div>

        <div className="col-4 px-3">
          <div className="customDatePickerWidth">
            <DatePicker
              className="form-control rounded-pill border shadow text1-4 px-4 py-2"
              selected={convertUTCToLocalDate(test.testDate)}
              onChange={(value) => handleDateChange(value)}
              dateFormat="dd-MM-yyyy"
              name="testDate"
              required
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="Exam Date"
              autoComplete="off"
              disabled={disabled}
            />
          </div>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Reading Score"
            required
            id="readingScore"
            name="readingScore"
            value={test.readingScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Reading Score<span className="text-danger">*</span>
          </label>
        </div>

        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Listening Score"
            required
            id="listeningScore"
            name="listeningScore"
            value={test.listeningScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Listening Score<span className="text-danger">*</span>
          </label>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Speaking Score"
            required
            id="speakingScore"
            name="speakingScore"
            value={test.speakingScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Speaking Score<span className="text-danger">*</span>
          </label>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Writing Score"
            required
            id="writingScore"
            name="writingScore"
            value={test.writingScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Writing Score<span className="text-danger">*</span>
          </label>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="email"
            pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
            title="Please provide valid email address"
            className="form-control rounded-pill border shadow"
            placeholder="Email"
            id="writingScore"
            name="email"
            value={test.email}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Email
          </label>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Password"
            id="password"
            name="password"
            value={test.password}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Password
          </label>
        </div>
      </div>
      <div className="position-absolute top-0 start-50 translate-middle">
        <span className="text1-4 px-3 py-1 bg-orange rounded-1 text-white">
          {test.type}
        </span>
        {test.id ? (
          allDraftApplication === true ||
          isEditable ||
          allDraftApplication === undefined ? (
            <i
              className="bi bi-x-circle text2  rounded-circle text-white bg-normal-red ms-3"
              onClick={(e) => handleRemoveEPT(e, test)}
              style={{ cursor: "pointer" }}
            ></i>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
const TOEFLDetails = ({
  test,
  handleTestChange,
  handleRemoveEPT,
  allDraftApplication,
  disabled,
  isEditable,
}) => {
  function convertUTCToLocalDate(date) {
    if (!date) {
      return date;
    }

    const localOffset = new Date().getTimezoneOffset(); // Get local timezone offset in milliseconds
    const utcDate = new Date(date);
    const localDate = new Date(utcDate.getTime() - localOffset);

    return localDate;
  }
  const handleDateChange = (value) => {
    const dateString = value;
    const date = new Date(dateString);
    const utcDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000
    );
    const formattedDate = utcDate.toISOString();
    handleTestChange({ target: { name: "testDate", value: formattedDate } });
  };
  return (
    <div className="p-4 rounded-4 border border-dark mb-4 position-relative">
      <div className="row g-3">
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Overall Score*"
            required
            id="overallScore"
            name="overallScore"
            value={test.overallScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray ">
            Overall Score<span className="text-danger">*</span>
          </label>
        </div>

        <div className="col-4 px-3">
          <div className="customDatePickerWidth">
            <DatePicker
              className="form-control rounded-pill border shadow text1-4 px-4 py-2"
              selected={convertUTCToLocalDate(test.testDate)}
              onChange={(value) => handleDateChange(value)}
              dateFormat="dd-MM-yyyy"
              name="testDate"
              required
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="Exam Date"
              autoComplete="off"
              disabled={disabled}
            />
          </div>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Reading Score"
            required
            id="readingScore"
            name="readingScore"
            value={test.readingScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Reading Score<span className="text-danger">*</span>
          </label>
        </div>

        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="listening Score"
            required
            id="listeningScore"
            name="listeningScore"
            value={test.listeningScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Listening Score<span className="text-danger">*</span>
          </label>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Speaking Score"
            required
            id="speakingScore"
            name="speakingScore"
            value={test.speakingScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Speaking Score<span className="text-danger">*</span>
          </label>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Writing Score"
            required
            id="writingScore"
            name="writingScore"
            value={test.writingScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Writing Score<span className="text-danger">*</span>
          </label>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="email"
            title="Please provide valid email address"
            pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
            className="form-control rounded-pill border shadow"
            placeholder="Email"
            id="writingScore"
            name="email"
            value={test.email}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Email
          </label>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Password"
            id="password"
            name="password"
            value={test.password}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Password
          </label>
        </div>
      </div>
      <div className="position-absolute top-0 start-50 translate-middle">
        <span className="text1-4 px-3 py-1 bg-orange rounded-1 text-white">
          {test.type}
        </span>
        {test.id ? (
          allDraftApplication === true ||
          isEditable ||
          allDraftApplication === undefined ? (
            <i
              className="bi bi-x-circle text2  rounded-circle text-white bg-normal-red ms-3"
              onClick={(e) => handleRemoveEPT(e, test)}
              style={{ cursor: "pointer" }}
            ></i>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
const PTEDetails = ({
  test,
  handleTestChange,
  handleRemoveEPT,
  allDraftApplication,
  disabled,
  isEditable,
}) => {
  function convertUTCToLocalDate(date) {
    if (!date) {
      return date;
    }

    const localOffset = new Date().getTimezoneOffset(); // Get local timezone offset in milliseconds
    const utcDate = new Date(date);
    const localDate = new Date(utcDate.getTime() - localOffset);

    return localDate;
  }
  const handleDateChange = (value) => {
    const dateString = value;
    const date = new Date(dateString);
    const utcDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000
    );
    const formattedDate = utcDate.toISOString();
    handleTestChange({ target: { name: "testDate", value: formattedDate } });
  };
  return (
    <div className="p-4 rounded-4 border border-dark mb-4 position-relative">
      <div className="row g-3">
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Overall Score*"
            required
            id="overallScore"
            name="overallScore"
            value={test.overallScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray ">
            Overall Score<span className="text-danger">*</span>
          </label>
        </div>

        <div className="col-4 px-3">
          <div className="customDatePickerWidth">
            <DatePicker
              className="form-control rounded-pill border shadow text1-4 px-4 py-2"
              selected={convertUTCToLocalDate(test.testDate)}
              onChange={(value) => handleDateChange(value)}
              dateFormat="dd-MM-yyyy"
              name="testDate"
              required
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="Exam Date"
              autoComplete="off"
              disabled={disabled}
            />
          </div>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Reading Score"
            required
            id="readingScore"
            name="readingScore"
            value={test.readingScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Reading Score<span className="text-danger">*</span>
          </label>
        </div>

        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Listening Score"
            required
            id="listeningScore"
            name="listeningScore"
            value={test.listeningScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Listening Score<span className="text-danger">*</span>
          </label>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Speaking Score"
            required
            id="speakingScore"
            name="speakingScore"
            value={test.speakingScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Speaking Score<span className="text-danger">*</span>
          </label>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Writing Score"
            required
            id="writingScore"
            name="writingScore"
            value={test.writingScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Writing Score<span className="text-danger">*</span>
          </label>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="email"
            pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
            className="form-control rounded-pill border shadow"
            placeholder="Email"
            id="writingScore"
            name="email"
            title="Please provide valid email address"
            value={test.email}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Email
          </label>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Password"
            id="password"
            name="password"
            value={test.password}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Password
          </label>
        </div>
      </div>
      <div className="position-absolute top-0 start-50 translate-middle">
        <span className="text1-4 px-3 py-1 bg-orange rounded-1 text-white">
          {test.type}
        </span>
        {test.id ? (
          allDraftApplication === true ||
          isEditable ||
          allDraftApplication === undefined ? (
            <i
              className="bi bi-x-circle text2  rounded-circle text-white bg-normal-red ms-3"
              onClick={(e) => handleRemoveEPT(e, test)}
              style={{ cursor: "pointer" }}
            ></i>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
const DuolingoDetails = ({
  test,
  handleTestChange,
  handleRemoveEPT,
  allDraftApplication,
  disabled,
  isEditable,
}) => {
  function convertUTCToLocalDate(date) {
    if (!date) {
      return date;
    }

    const localOffset = new Date().getTimezoneOffset(); // Get local timezone offset in milliseconds
    const utcDate = new Date(date);
    const localDate = new Date(utcDate.getTime() - localOffset);

    return localDate;
  }
  const handleDateChange = (value) => {
    const dateString = value;
    const date = new Date(dateString);
    const utcDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000
    );
    const formattedDate = utcDate.toISOString();
    handleTestChange({ target: { name: "testDate", value: formattedDate } });
  };
  return (
    <div className="p-4 rounded-4 border border-dark mb-4 position-relative">
      <div className="row g-3">
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Overall Score*"
            required
            id="overallScore"
            name="overallScore"
            value={test.overallScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray ">
            Overall Score<span className="text-danger">*</span>
          </label>
        </div>

        <div className="col-4 px-3">
          <div className="customDatePickerWidth">
            <DatePicker
              className="form-control rounded-pill border shadow text1-4 px-4 py-2"
              selected={convertUTCToLocalDate(test.testDate)}
              onChange={(value) => handleDateChange(value)}
              dateFormat="dd-MM-yyyy"
              name="testDate"
              required
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="Exam Date"
              autoComplete="off"
              disabled={disabled}
            />
          </div>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Reading Score"
            required
            id="readingScore"
            name="readingScore"
            value={test.readingScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Literacy Score<span className="text-danger">*</span>
          </label>
        </div>

        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Listening Score"
            required
            id="listeningScore"
            name="listeningScore"
            value={test.listeningScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Comprehension Score<span className="text-danger">*</span>
          </label>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Speaking Score"
            required
            id="speakingScore"
            name="speakingScore"
            value={test.speakingScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Conversation Score<span className="text-danger">*</span>
          </label>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Writing Score"
            required
            id="writingScore"
            name="writingScore"
            value={test.writingScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Production Score<span className="text-danger">*</span>
          </label>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="email"
            pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
            className="form-control rounded-pill border shadow"
            placeholder="Email"
            title="Please provide valid email address"
            id="email"
            name="email"
            value={test.email}
            onChange={handleTestChange}
            disabled={!Boolean(allDraftApplication)}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Email
          </label>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Password"
            id="password"
            name="password"
            value={test.password}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Password
          </label>
        </div>
      </div>
      <div className="position-absolute top-0 start-50 translate-middle">
        <span className="text1-4 px-3 py-1 bg-orange rounded-1 text-white">
          {test.type}
        </span>
        {test.id ? (
          allDraftApplication === true ||
          isEditable ||
          allDraftApplication === undefined ? (
            <i
              className="bi bi-x-circle text2  rounded-circle text-white bg-normal-red ms-3"
              onClick={(e) => handleRemoveEPT(e, test)}
              style={{ cursor: "pointer" }}
            ></i>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
const GMATDetails = ({
  test,
  handleTestChange,
  handleRemoveEPT,
  allDraftApplication,
  disabled,
  isEditable,
}) => {
  function convertUTCToLocalDate(date) {
    if (!date) {
      return date;
    }

    const localOffset = new Date().getTimezoneOffset(); // Get local timezone offset in milliseconds
    const utcDate = new Date(date);
    const localDate = new Date(utcDate.getTime() - localOffset);

    return localDate;
  }
  const handleDateChange = (value) => {
    const dateString = value;
    const date = new Date(dateString);
    const utcDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000
    );
    const formattedDate = utcDate.toISOString();
    handleTestChange({ target: { name: "testDate", value: formattedDate } });
  };
  return (
    <div className="p-4 rounded-4 border border-dark mb-4 position-relative">
      <div className="row g-3">
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Overall Score*"
            required
            id="overallScore"
            name="overallScore"
            value={test.overallScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray ">
            Overall Score<span className="text-danger">*</span>
          </label>
        </div>

        <div className="col-4 px-3">
          <div className="customDatePickerWidth">
            <DatePicker
              className="form-control rounded-pill border shadow text1-4 px-4 py-2"
              selected={convertUTCToLocalDate(test.testDate)}
              onChange={(value) => handleDateChange(value)}
              dateFormat="dd-MM-yyyy"
              name="testDate"
              required
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="Exam Date"
              autoComplete="off"
              disabled={disabled}
            />
          </div>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Quantitative"
            required
            id="readingScore"
            name="readingScore"
            value={test.readingScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Quantitative<span className="text-danger">*</span>
          </label>
        </div>

        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Verbal"
            required
            id="listeningScore"
            name="listeningScore"
            value={test.listeningScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Verbal<span className="text-danger">*</span>
          </label>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Analytical Writing"
            required
            id="speakingScore"
            name="speakingScore"
            value={test.speakingScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Analytical Writing<span className="text-danger">*</span>
          </label>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Integrated Reasoning"
            required
            id="writingScore"
            name="writingScore"
            value={test.writingScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Integrated Reasoning<span className="text-danger">*</span>
          </label>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="email"
            title="Please provide valid email address"
            pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
            className="form-control rounded-pill border shadow"
            placeholder="Email"
            id="writingScore"
            name="email"
            value={test.email}
            onChange={handleTestChange}
            disabled={!Boolean(allDraftApplication)}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Email
          </label>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Password"
            id="password"
            name="password"
            value={test.password}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Password
          </label>
        </div>
      </div>
      <div className="position-absolute top-0 start-50 translate-middle">
        <span className="text1-4 px-3 py-1 bg-orange rounded-1 text-white">
          {test.type}
        </span>
        {test.id ? (
          allDraftApplication === true ||
          isEditable ||
          allDraftApplication === undefined ? (
            <i
              className="bi bi-x-circle text2  rounded-circle text-white bg-normal-red ms-3"
              onClick={(e) => handleRemoveEPT(e, test)}
              style={{ cursor: "pointer" }}
            ></i>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
const GREDetails = ({
  test,
  handleTestChange,
  handleRemoveEPT,
  allDraftApplication,
  disabled,
  isEditable,
}) => {
  function convertUTCToLocalDate(date) {
    if (!date) {
      return date;
    }

    const localOffset = new Date().getTimezoneOffset(); // Get local timezone offset in milliseconds
    const utcDate = new Date(date);
    const localDate = new Date(utcDate.getTime() - localOffset);

    return localDate;
  }
  const handleDateChange = (value) => {
    const dateString = value;
    const date = new Date(dateString);
    const utcDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000
    );
    const formattedDate = utcDate.toISOString();
    handleTestChange({ target: { name: "testDate", value: formattedDate } });
  };
  return (
    <div className="p-4 rounded-4 border border-dark mb-4 position-relative">
      <div className="row g-3">
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Overall Score*"
            required
            id="overallScore"
            name="overallScore"
            value={test.overallScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray ">
            Overall Score<span className="text-danger">*</span>
          </label>
        </div>

        <div className="col-4 px-3">
          <div className="customDatePickerWidth">
            <DatePicker
              className="form-control rounded-pill border shadow text1-4 px-4 py-2"
              selected={convertUTCToLocalDate(test.testDate)}
              onChange={(value) => handleDateChange(value)}
              dateFormat="dd-MM-yyyy"
              name="testDate"
              required
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="Exam Date"
              autoComplete="off"
              disabled={disabled}
            />
          </div>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Quantitative"
            required
            id="readingScore"
            name="readingScore"
            value={test.readingScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Quantitative<span className="text-danger">*</span>
          </label>
        </div>

        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Verbal"
            required
            id="listeningScore"
            name="listeningScore"
            value={test.listeningScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Verbal<span className="text-danger">*</span>
          </label>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Analytical Writinge"
            required
            id="speakingScore"
            name="speakingScore"
            value={test.speakingScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Analytical Writinge<span className="text-danger">*</span>
          </label>
        </div>
        <div className="col-4 form-floating px-3 d-none">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Writing Score"
            required
            id="writingScore"
            name="writingScore"
            value={0}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Writing Score<span className="text-danger">*</span>
          </label>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="email"
            title="Please provide valid email address"
            pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
            className="form-control rounded-pill border shadow"
            placeholder="Email"
            id="writingScore"
            name="email"
            value={test.email}
            onChange={handleTestChange}
            disabled={!Boolean(allDraftApplication)}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Email
          </label>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Password"
            id="password"
            name="password"
            value={test.password}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Password
          </label>
        </div>
      </div>
      <div className="position-absolute top-0 start-50 translate-middle">
        <span className="text1-4 px-3 py-1 bg-orange rounded-1 text-white">
          {test.type}
        </span>
        {test.id ? (
          allDraftApplication === true ||
          isEditable ||
          allDraftApplication === undefined ? (
            <i
              className="bi bi-x-circle text2  rounded-circle text-white bg-normal-red ms-3"
              onClick={(e) => handleRemoveEPT(e, test)}
              style={{ cursor: "pointer" }}
            ></i>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
const SATDetails = ({
  test,
  handleTestChange,
  handleRemoveEPT,
  allDraftApplication,
  disabled,
  isEditable,
}) => {
  function convertUTCToLocalDate(date) {
    if (!date) {
      return date;
    }

    const localOffset = new Date().getTimezoneOffset(); // Get local timezone offset in milliseconds
    const utcDate = new Date(date);
    const localDate = new Date(utcDate.getTime() - localOffset);

    return localDate;
  }
  const handleDateChange = (value) => {
    const dateString = value;
    const date = new Date(dateString);
    const utcDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000
    );
    const formattedDate = utcDate.toISOString();
    handleTestChange({ target: { name: "testDate", value: formattedDate } });
  };
  return (
    <div className="p-4 rounded-4 border border-dark mb-4 position-relative">
      <div className="row g-3">
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Overall Score*"
            required
            id="overallScore"
            name="overallScore"
            value={test.overallScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray ">
            Overall Score<span className="text-danger">*</span>
          </label>
        </div>

        <div className="col-4 px-3">
          <div className="customDatePickerWidth">
            <DatePicker
              className="form-control rounded-pill border shadow text1-4 px-4 py-2"
              selected={convertUTCToLocalDate(test.testDate)}
              onChange={(value) => handleDateChange(value)}
              dateFormat="dd-MM-yyyy"
              name="testDate"
              required
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="Exam Date"
              autoComplete="off"
              disabled={disabled}
            />
          </div>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Reading & Writing"
            required
            id="readingScore"
            name="readingScore"
            value={test.readingScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Reading & Writing<span className="text-danger">*</span>
          </label>
        </div>

        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Math"
            required
            id="listeningScore"
            name="listeningScore"
            value={test.listeningScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Math<span className="text-danger">*</span>
          </label>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Essay"
            required
            id="speakingScore"
            name="speakingScore"
            value={test.speakingScore}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Essay<span className="text-danger">*</span>
          </label>
        </div>
        <div className="col-4 form-floating px-3 d-none">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Writing Score"
            required
            id="writingScore"
            name="writingScore"
            value={test.writingScore || 0}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Writing Score<span className="text-danger">*</span>
          </label>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="email"
            title="Please provide valid email address"
            pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
            className="form-control rounded-pill border shadow"
            placeholder="Email"
            id="writingScore"
            name="email"
            value={test.email}
            onChange={handleTestChange}
            disabled={!Boolean(allDraftApplication)}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Email
          </label>
        </div>
        <div className="col-4 form-floating px-3">
          <input
            type="text"
            className="form-control rounded-pill border shadow"
            placeholder="Password"
            id="password"
            name="password"
            value={test.password}
            onChange={handleTestChange}
            disabled={disabled}
          />
          <label htmlFor="floatingInput" className="ms-4 text-light-gray">
            Password
          </label>
        </div>
      </div>
      <div className="position-absolute top-0 start-50 translate-middle">
        <span className="text1-4 px-3 py-1 bg-orange rounded-1 text-white">
          {test.type}
        </span>
        {test.id ? (
          allDraftApplication === true ||
          isEditable ||
          allDraftApplication === undefined ? (
            <i
              className="bi bi-x-circle text2  rounded-circle text-white bg-normal-red ms-3"
              onClick={(e) => handleRemoveEPT(e, test)}
              style={{ cursor: "pointer" }}
            ></i>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
