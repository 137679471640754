import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import PhoneNumberInput from "../../components/PhoneNumberInput";
import UserDataService from "../../Services/UserService";
import { toast } from "react-toastify";

export default function UserAdd({ user, roles, retrieveUsers, setModalShow, RoleService, getRole }) {
  const { SuperAdmin, Reception, Counsellor, BranchHead } = RoleService;
  const [userData, setUserData] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    confirmEmail: user.confirmEmail,
    phoneNumber: user.phoneNumber,
    companyId: user.companyId,
    role: user.role,
    password: user.password,
    confirmPassword: user.confirmPassword,
    statusId: user.statusId,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlePhoneNumberChange = (value) => {
    if (value) {
      userData.phoneNumber = value;
    }
  };

  const updateUserData = (e) => {
    e.preventDefault();
    UserDataService.update(userData)
      .then((response) => {
        retrieveUsers();
        toast.success("User Updated Successfully");
        setModalShow(false);
      })
      .catch((e) => {
        toast.error(e);
      });
  };

  return (
    <>
      <form className="needs-validation" onSubmit={updateUserData}>
        <div className="row g-3 p-3">
          <div className="col-6 form-floating px-3">
            <input
              type="text"
              className="form-control rounded-pill border"
              placeholder="First name*"
              required
              id="firstName"
              value={userData.firstName}
              onChange={handleInputChange}
              name="firstName"
            />
            <label htmlFor="floatingInput" className="ms-4 text-light-gray ">
              First Name<span className="text-danger">*</span>
            </label>
          </div>
          <div className="col-6 form-floating px-3">
            <input
              type="text"
              className="form-control rounded-pill border"
              placeholder="Last name"
              required
              id="lastName"
              value={userData.lastName}
              onChange={handleInputChange}
              name="lastName"
            />
            <label htmlFor="floatingInput" className="ms-4 text-light-gray">
              Last Name<span className="text-danger">*</span>
            </label>
          </div>

          <div className="col-6 form-floating px-3">
            <input
              type="email"
              className="form-control rounded-pill border"
              placeholder="Country of Citizenship"
              required
              id="email"
              value={userData.email}
              //onChange={handleInputChange}
              name="email"
              disabled
            />
            <label htmlFor="floatingInput" className="ms-4 text-light-gray">
              Email
              <span className="text-danger">*</span>
            </label>
          </div>

          <div className="col-6 form-floating px-3">
            <PhoneNumberInput customCSS={""} value={userData.phoneNumber} onChange={handlePhoneNumberChange} />
          </div>

          <div className="col-6 form-floating px-3">
            <select
              className="form-select rounded-pill border"
              aria-label="Services of Interest"
              required
              id="role"
              value={userData.role}
              onChange={handleInputChange}
              name="role"
              disabled
            >
              <option value="">Select Role</option>
              {roles.map((role) => {
                if (getRole === SuperAdmin) {
                  return (
                    <option key={role.id} value={role.name}>
                      {role.name}
                    </option>
                  );
                } else if (getRole === BranchHead) {
                  if (role.name === Counsellor || role.name === Reception) {
                    return (
                      <option key={role.id} value={role.name}>
                        {role.name}
                      </option>
                    );
                  }
                } else {
                  return <option key={role.id}>You are not Authorized</option>;
                }
                return null;
              })}
            </select>
            <label htmlFor="floatingInput" className="ms-4 text-light-gray">
              Role <span className="text-danger">*</span>
            </label>
          </div>
          <div className="col-6 form-floating px-3">
            <select
              className="form-select rounded-pill border"
              aria-label="Services of Interest"
              required
              id="statusId"
              value={userData.statusId}
              onChange={handleInputChange}
              name="statusId"
            >
              <option value="">Select Status</option>
              <option value={1}>Active</option>
              <option value={0}>Inactive</option>
            </select>
            <label htmlFor="floatingInput" className="ms-4 text-light-gray">
              Status <span className="text-danger">*</span>
            </label>
          </div>
        </div>
        <Modal.Footer>
          <button type="button" className="btn btn-light-blue-outline rounded-pill py-3 me-2 px-5" onClick={() => setModalShow(false)}>
            Cancel
          </button>
          <button type="submit" className="btn btn-dark-blue rounded-pill py-3 px-5">
            Submit
          </button>
        </Modal.Footer>
      </form>
    </>
  );
}
