import React from "react";
import sampleFile from "../../../assets/sampleFile.csv";

export default function DownloadCSV() {
  const downloadFile = () => {
    const link = document.createElement("a");
    link.href = sampleFile; // Use the imported sampleFile
    link.download = "sampleFileCSV.csv"; // Set the desired filename for download
    link.click();
  };

  return (
    <div style={{ cursor: "pointer" }} onClick={downloadFile}>
      <i className="bi bi-file-arrow-down text2 text-orange"></i>{" "}
      <span className="text1-4 text-dark1">Download Sample File</span>
    </div>
  );
}
