import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import platformLogo from "../../assets/logo_dark.png";
import "./MarketPlace.css";
import MarketPlaceService from "../../Services/MarketPlaceService";
import { toast } from "react-toastify";
import useStudentStore from "../../stores/StudentStore";
import { useNavigate } from "react-router-dom";

export default function StudentConfirmationModal({ show, setShowModal }) {
  const [step, setStep] = useState(1);
  const [existing, setExisting] = useState(false);
  const { studentDetails, setStudentDetails } = useStudentStore();

  const [applicationIds, setApplicationIds] = useState([]);
  const [options, setOptions] = useState(applicationIds);

  const navigate = useNavigate();
  const handleCloseModal = () => {
    setStep(1);
    setStudentDetails({ name: "", studentId: "", email: "" });
    setShowModal(false);
  };
  const handleNavigate = (e) => {
    e.preventDefault();
    setStudentDetails({
      isExisting: existing,
    });
    navigate("/user/marketplace/apply", {
      state: { existing },
    });
  };
  useEffect(() => {
    if (studentDetails.name) {
      const filtered = applicationIds.filter((data) => {
        const dataName = `${data.firstName} ${data.lastName}`;
        return dataName
          .toLowerCase()
          .includes(studentDetails.name.toLowerCase());
      });
      setOptions(filtered);
    } else {
      setOptions(applicationIds);
    }
  }, [applicationIds]);

  const getStudentData = useCallback(async () => {
    try {
      const response = await MarketPlaceService.getStudentListByUserId();

      setApplicationIds(response.data.response);
    } catch (error) {
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.to()
      );
    }
  }, []);

  useEffect(() => {
    if (existing !== studentDetails.isExisting) {
      setStudentDetails({
        studentId: "",
        name: "",
        firstName: "",
        email: "",
        passport: "",
        lastName: "",
        contactNumber: "",
      });
    }
  }, []);

  useEffect(() => {
    getStudentData();
  }, [getStudentData]);

  const handleInputChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      if (existing) {
        setStudentDetails({
          studentId: "",
          name: "",
          firstName: "",
          email: "",
          passport: "",
          lastName: "",
          contactNumber: "",
        });
        if (name === "name") {
          const filtered = applicationIds.filter((data) => {
            const dataName = `${data.firstName} ${data.lastName}`;
            return dataName.toLowerCase().includes(value.toLowerCase());
          });
          setOptions(filtered);
        } else {
          setOptions(applicationIds);
        }
      }

      setStudentDetails({ [name]: value });
    },
    [applicationIds, existing, setStudentDetails]
  );

  const handleSelect = useCallback(
    (e) => {
      e.preventDefault();

      const { value } = e.target;

      const data = options.filter((item) => {
        return item.studentId === e.target.value;
      });
      setStudentDetails({
        studentId: value,
        uniqueIdNumber: data[0]?.uniqueIdNumber,
        name: `${data[0]?.firstName} ${data[0]?.lastName}`,
        firstName: data[0]?.firstName,
        email: data[0].email ?? "",
        passport: data[0]?.passport,
        lastName: data[0]?.lastName,
        contactNumber: data[0]?.contactNumber,
      });
    },
    [options, setStudentDetails]
  );

  return (
    <Modal
      show={show}
      onHide={handleCloseModal}
      centered
      aria-labelledby="contained-modal-title-vcenter"
      size="md"
      className="custom-modal"
    >
      <Modal.Body>
        <i
          className="bi bi-x-circle-fill text2 p-3 text-danger position-absolute end-0 top-0"
          style={{ cursor: "pointer" }}
          onClick={handleCloseModal}
        ></i>
        <div className="d-flex flex-column align-items-center pt-5 ">
          <img src={platformLogo} alt="platformLogo" height="80px" />
        </div>
        {step === 1 ? (
          <div className="mt-3 pt-3 d-flex flex-column gap-3">
            {" "}
            <p className="text-center text-dark1 text1-6">
              Are you an Education Planner Applicant?
            </p>{" "}
            <div className="d-flex justify-content-center gap-4 mb-5">
              <button
                onClick={() => {
                  setExisting(false);
                  setStep(2);
                }}
                className="btn btn-gic text-dark1 border-dark1 rounded-pill py-2 px-5 text1-3"
              >
                No
              </button>
              <button
                onClick={() => {
                  setExisting(true);
                  setStep(2);
                }}
                className="bg-dark1 btn btn-gic text-white  border-0 rounded-pill py-2 px-5 text1-3"
              >
                Yes
              </button>
            </div>
          </div>
        ) : (
          <form
            className="mt-3 mb-4 py-3 px-5 row gap-3 needs-validation g-3"
            onSubmit={handleNavigate}
          >
            <div>
              <p className="text-dark1 text1-4">Name</p>
              <div className="col-12 form-floating ">
                <input
                  type="text"
                  className="form-control rounded-pill pt-3 border ps-4 pb-1"
                  placeholder="Name"
                  required
                  value={studentDetails.name}
                  onChange={handleInputChange}
                  name="name"
                />
                <label htmlFor="floatingInput" className="ms-4 pt-2">
                  Enter Name<span className="text-danger">*</span>
                </label>
              </div>
            </div>
            {existing ? (
              <div>
                <p className="text-dark1 text1-4">Student Application ID</p>
                <div className="col-12 form-floating ">
                  <select
                    className="form-select id-select rounded-pill text1-3 py-2"
                    required
                    value={studentDetails.studentId}
                    onChange={handleSelect}
                    name="studentId"
                    id="floatingSelect"
                  >
                    <option hidden value={""}>
                      Select Application Id
                    </option>

                    {options.length ? (
                      options?.map((item, index) => (
                        <option key={index} value={item.studentId}>
                          {item.uniqueIdNumber}
                        </option>
                      ))
                    ) : (
                      <option>No such student found</option>
                    )}
                  </select>
                </div>
              </div>
            ) : null}

            <div>
              <p className="text-dark1 text1-4">Email</p>
              <div className="col-12 form-floating ">
                <input
                  type="email"
                  className="form-control rounded-pill border ps-4 pb-1"
                  placeholder="Email"
                  required
                  value={studentDetails.email}
                  onChange={handleInputChange}
                  name="email"
                />

                <label htmlFor="floatingInput" className="ms-4 pt-2">
                  Enter Email<span className="text-danger">*</span>
                </label>
              </div>
            </div>
            <div className="d-flex justify-content-center gap-4 mt-3">
              <button
                type="submit"
                className="btn text-white bg-dark1 rounded-pill py-3 px-5 text1-4"
              >
                Continue
              </button>
            </div>
          </form>
        )}
      </Modal.Body>
    </Modal>
  );
}
