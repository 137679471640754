import React from 'react'
import countriesData from "../../Services/CountriesList.json";
import PlaceholderPhoneInput from '../../components/PlaceholderPhone';
import TokenService from '../../Services/TokenService';
import RolesService from '../../Services/RolesService';


const BankingInfo = ({ setActiveTabNext, profileData, setProfileData, disabled,
    handleInputChange, handleSaveProfileData, isFormEdited, setIsFormEdited }) => {

    const getRole = TokenService.getUserRole()
    const { BranchHead } = RolesService

    const handleTabChange = (e) => {
        e.preventDefault();
        setActiveTabNext("documents");
    };
    return (
        <form className='need-validations'>

            <div className="border rounded-4 bg-light-blue1 text1-5 p-3 mb-4">
                <div className="d-flex align-items-center bg-stripped1 border border-top-0 rounded-4 p-3  mb-3">
                    <span className="font-bold">Bank Details</span>

                    {/* <div className={`col-4 form-floating px-3 ${disabled && "d-none"}`}>
                        <GoogleAddressAutoComplete setSelectedPlace={setSelectedPlace} />
                    </div> */}
                </div>
                <div className="row align-items-center">
                    <div className="col-12">
                        <div className="row g-3">
                            <div className="col-4 form-floating px-3">
                                <input
                                    type="number"
                                    className="form-control rounded-pill border shadow"
                                    placeholder="Beneficiary account number"
                                    required
                                    id="beneficiaryAccountNumber"
                                    value={profileData?.beneficiaryAccountNumber}
                                    onChange={handleInputChange}
                                    name="beneficiaryAccountNumber"
                                    autoComplete="off"
                                    disabled={disabled}
                                />
                                <label htmlFor="floatingInput" className="ms-4">
                                    Beneficiary account number
                                </label>
                            </div>
                            <div className="col-4 form-floating px-3">
                                <input
                                    type="text"
                                    className="form-control rounded-pill border shadow"
                                    placeholder="Account holder name"
                                    required
                                    id="accountHolderName"
                                    value={profileData?.accountHolderName}
                                    onChange={handleInputChange}
                                    name="accountHolderName"
                                    autoComplete="off"
                                    disabled={disabled}
                                />
                                <label htmlFor="floatingInput" className="ms-4">
                                    Account holder name
                                </label>
                            </div>
                            <div className="col-4 form-floating px-3">
                                <input
                                    type="text"
                                    className="form-control rounded-pill border shadow"
                                    placeholder="Beneficiary bank name"
                                    required
                                    id="beneficiaryBankName"
                                    value={profileData?.beneficiaryBankName}
                                    onChange={handleInputChange}
                                    name="beneficiaryBankName"
                                    autoComplete="off"
                                    disabled={disabled}
                                />
                                <label htmlFor="floatingInput" className="ms-4">
                                    Beneficiary bank name
                                </label>
                            </div>

                            <div className="col-4 form-floating px-3">
                                <input
                                    type="text"
                                    className="form-control rounded-pill border shadow"
                                    placeholder="Branch name"
                                    required
                                    id="branchName"
                                    value={profileData?.branchName}
                                    onChange={handleInputChange}
                                    name="branchName"
                                    autoComplete="off"
                                    disabled={disabled}
                                />
                                <label htmlFor="floatingInput" className="ms-4">
                                    Branch name
                                </label>
                            </div>
                            <div className="col-4 form-floating px-3">
                                <PlaceholderPhoneInput
                                    placeholder='Branch number - Landline'
                                    value={profileData?.branchLandlineNumber}
                                    onChange={(phoneNo) => {
                                        setIsFormEdited(false)
                                        setProfileData((prev) => ({
                                            ...prev,
                                            branchLandlineNumber: phoneNo
                                        }))
                                    }}
                                    disabled={disabled}
                                />
                            </div>
                            <div className="col-4 form-floating px-3">
                                <PlaceholderPhoneInput
                                    placeholder='Branch number - Mobile'
                                    value={profileData.branchMobileNumber}
                                    onChange={(phoneNo) => {
                                        setIsFormEdited(false)
                                        setProfileData((prev) => ({
                                            ...prev,
                                            branchMobileNumber: phoneNo
                                        }))
                                    }}
                                    disabled={disabled}
                                />
                            </div>
                            <div className="col-4 form-floating px-3">
                                <input
                                    type="text"
                                    className="form-control rounded-pill border shadow"
                                    placeholder="IFSC code"
                                    required
                                    id="ifscCode"
                                    value={profileData.ifscCode}
                                    onChange={handleInputChange}
                                    name="ifscCode"
                                    disabled={disabled}
                                    autoComplete="off"
                                />
                                <label htmlFor="floatingInput" className="ms-4">
                                    IFSC code
                                </label>
                            </div>
                            <div className="col-4 form-floating px-3">
                                <input
                                    type="text"
                                    className="form-control rounded-pill border shadow"
                                    placeholder="Swift code"
                                    required
                                    id="swiftCode"
                                    value={profileData.swiftCode}
                                    onChange={handleInputChange}
                                    name="swiftCode"
                                    disabled={disabled}
                                    autoComplete="off"
                                />
                                <label htmlFor="floatingInput" className="ms-4">
                                    Swift code(If applicable)
                                </label>
                            </div>
                            <div className="col-4 form-floating px-3">
                                <input
                                    type="text"
                                    className="form-control rounded-pill border shadow"
                                    placeholder="GST/HST number"
                                    required
                                    id="gstNumber"
                                    value={profileData.gstNumber}
                                    onChange={handleInputChange}
                                    name="gstNumber"
                                    autoComplete="off"
                                    disabled={disabled}
                                />
                                <label htmlFor="floatingInput" className="ms-4">
                                    GST/HST number
                                </label>
                            </div>
                            <div className="col-4 form-floating px-3">
                                <input
                                    type="text"
                                    className="form-control rounded-pill border shadow"
                                    placeholder="Institution number"
                                    required
                                    id="institutionNumber"
                                    value={profileData.institutionNumber}
                                    onChange={handleInputChange}
                                    name="institutionNumber"
                                    autoComplete="off"
                                    disabled={disabled}
                                />
                                <label htmlFor="floatingInput" className="ms-4">
                                    Institution number
                                </label>
                            </div>
                            <div className="col-4 form-floating px-3">
                                <input
                                    type="text"
                                    className="form-control rounded-pill border shadow"
                                    placeholder="Transit number"
                                    required
                                    id="transitNumber"
                                    value={profileData.transitNumber}
                                    onChange={handleInputChange}
                                    name="transitNumber"
                                    autoComplete="off"
                                    disabled={disabled}
                                />
                                <label htmlFor="floatingInput" className="ms-4">
                                    Transit number
                                </label>
                            </div>
                            <div className="col-4 form-floating px-3">
                                <input
                                    type="text"
                                    className="form-control rounded-pill border shadow"
                                    placeholder="PAN number"
                                    required
                                    id="panNumber"
                                    value={profileData.panNumber}
                                    onChange={handleInputChange}
                                    name="panNumber"
                                    autoComplete="off"
                                    disabled={disabled}
                                />
                                <label htmlFor="floatingInput" className="ms-4">
                                    PAN number
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="border rounded-4 bg-light-blue1 text1-5 p-3 mb-4">
                <div className="d-flex align-items-center bg-stripped1 border border-top-0 rounded-4 p-3  mb-3">
                    <span className="font-bold">Bank Address</span>

                    {/* <div className={`col-4 form-floating px-3 ${disabled && "d-none"}`}>
                        <GoogleAddressAutoComplete setSelectedPlace={setSelectedPlace} />
                    </div> */}
                </div>
                <div className="row align-items-center">
                    <div className="col-12">
                        <div className="row g-3">
                            <div className="col-4 form-floating px-3">
                                <textarea
                                    className="form-control rounded-4 border shadow h-100 text1-4"
                                    placeholder="Mailing Address"
                                    required
                                    id="bankAddress"
                                    value={profileData.bankAddress}
                                    onChange={handleInputChange}
                                    name="bankAddress"
                                    disabled={disabled}
                                />
                                <label htmlFor="floatingTextarea" className="ms-4">
                                    Address

                                </label>
                            </div>

                            <div className="col-8 mb-3">
                                <div className="row g-3">
                                    <div className="col-6 form-floating px-3">
                                        <select
                                            className={`form-select rounded-pill border shadow`}
                                            aria-label="Country"
                                            value={profileData.bankCountry}
                                            onChange={handleInputChange}
                                            name="bankCountry"
                                            disabled={disabled}
                                            required
                                        >
                                            <option value="">Country</option>
                                            {countriesData.map((country) => (
                                                <option key={country.code} value={country.name}>
                                                    {country.name}
                                                </option>
                                            ))}
                                        </select>
                                        <label htmlFor="floatingInput" className="ms-4">
                                            Country
                                        </label>
                                    </div>
                                    <div className="col-6 form-floating px-3">
                                        <input
                                            type="text"
                                            className="form-control rounded-pill border shadow"
                                            placeholder="City"
                                            required
                                            id="bankCity"
                                            value={profileData.bankCity}
                                            onChange={handleInputChange}
                                            name="bankCity"
                                            autoComplete="off"
                                            disabled={disabled}
                                        />
                                        <label htmlFor="floatingInput" className="ms-4">
                                            City
                                        </label>
                                    </div>
                                    <div className="col-6 form-floating px-3">
                                        <input
                                            type="text"
                                            className="form-control rounded-pill border shadow"
                                            placeholder="State"
                                            required
                                            id="bankState"
                                            value={profileData.bankState}
                                            onChange={handleInputChange}
                                            name="bankState"
                                            autoComplete="off"
                                            disabled={disabled}
                                        />
                                        <label htmlFor="floatingInput" className="ms-4">
                                            State
                                        </label>
                                    </div>
                                    <div className="col-6 form-floating px-3">
                                        <input
                                            type="text"
                                            className="form-control rounded-pill border shadow"
                                            placeholder="Province"
                                            required
                                            id="bankProvince"
                                            value={profileData.bankProvince}
                                            onChange={handleInputChange}
                                            name="bankProvince"
                                            autoComplete="off"
                                            disabled={disabled}
                                        />
                                        <label htmlFor="floatingInput" className="ms-4">
                                            Province
                                        </label>
                                    </div>

                                </div>
                            </div>
                            <div className="col-4 form-floating px-3">
                                <input
                                    type="number"
                                    className="form-control rounded-pill border shadow"
                                    placeholder="Postal Code"
                                    required
                                    id="bankPostalCode"
                                    value={profileData.bankPostalCode}
                                    onChange={handleInputChange}
                                    name="bankPostalCode"
                                    autoComplete="off"
                                    disabled={disabled}
                                />
                                <label htmlFor="floatingInput" className="ms-4">
                                    Postal Code
                                </label>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="d-flex justify-content-end my-2 mt-5">
                {getRole === BranchHead ?
                    <button
                        type="button"
                        className="btn btn-light-blue-outline rounded-pill py-3 me-2 px-5 text1-4"
                        onClick={handleSaveProfileData}
                        disabled={disabled || isFormEdited}
                    >
                        Save
                    </button>
                    : null}
                <button
                    type="button"
                    className="btn btn-light-blue-outline rounded-pill py-3 me-2 px-5 text1-4"
                    onClick={handleTabChange}
                >
                    Next
                </button>
            </div>

        </form>
    )
}

export default BankingInfo
