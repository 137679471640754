import React, { useRef, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { toast } from "react-toastify";
import apiURLandKEYS from "../Services/apiURLandKEYS";

let googleApiClient;

async function getGoogleMapsApiClient() {
  if (googleApiClient) {
    return googleApiClient;
  }
  const loader = new Loader({
    apiKey: apiURLandKEYS.googleAddressKey,
    version: "weekly",
    libraries: ["places"],
  });
  googleApiClient = await loader.load();
  return googleApiClient;
}

function GoogleAddressAutoComplete({ setSelectedPlace }) {
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  //const [placeDetail, setPlaceDetail] = useState();

  const sessionTokenRef = useRef();

  const timeoutRef = useRef();
  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (!newValue || newValue.trim().length <= 3) {
      setSuggestions([]);
      return;
    }

    timeoutRef.current = setTimeout(async () => {
      const google = await getGoogleMapsApiClient();

      if (!sessionTokenRef.current) {
        sessionTokenRef.current =
          new google.maps.places.AutocompleteSessionToken();
      }

      new google.maps.places.AutocompleteService().getPlacePredictions(
        {
          input: newValue,
          sessionToken: sessionTokenRef.current,
        },
        (predictions, status) => {
          if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
            setSuggestions([]);
            return;
          }
          if (
            status !== google.maps.places.PlacesServiceStatus.OK ||
            !predictions
          ) {
            toast.warn(status);
            return;
          }
          setSuggestions(predictions);
        }
      );
    }, 350);
  };

  const handleSuggestionSelected = async (suggestion) => {
    setValue(suggestion.description);
    setSuggestions([]);

    const google = await getGoogleMapsApiClient();

    const sessionToken = sessionTokenRef.current;
    sessionTokenRef.current = undefined;

    new google.maps.places.PlacesService(
      document.getElementById("googlemaps-attribution-container")
    ).getDetails(
      {
        placeId: suggestion.place_id,
        fields: ["formatted_address", "place_id", "address_components"],
        sessionToken,
      },

      (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          //setPlaceDetail(place);
          setSelectedPlace(place);
        }
      }
    );
  };

  return (
    <>
      <div className="googleAddress">
        <div
          className=" text1-3 dropdown-toggle"
          data-bs-toggle="dropdown"
          data-bs-auto-close="outside"
          aria-expanded="false"
        >
          <input
            id="location-input"
            className="form-control rounded-pill border py-2 text1-4"
            placeholder="Enter at least 4 characters to load suggestions"
            onChange={handleChange}
            value={value}
            autoComplete="off"
          />
        </div>
        <ul
          className="dropdown-menu"
          style={{ listStyleType: "none", padding: "0" }}
          role="listbox"
        >
          {suggestions.map((suggestion) => (
            <li
              className="px-3 py-2 text1-2 dropdownItem"
              key={suggestion.place_id}
              tabIndex={-1}
              role="option"
              aria-selected="false"
              onClick={() => handleSuggestionSelected(suggestion)}
              style={{ cursor: "pointer" }}
            >
              {suggestion.description}
            </li>
          ))}
          {/* {value !== null && value !== undefined && value.trim().length <= 3 && (
            <li className="m-2 text1-2" tabIndex={-1} role="option" aria-selected="false">
              Enter at least 4 characters to load suggestions
            </li>
          )} */}
        </ul>
      </div>

      {/* <div className="col-6 form-floating px-3">
          <input
            id="location-input"
            className="form-control rounded-pill border py-2"
            placeholder="Enter an address or a place name"
            onChange={handleChange}
            value={value}
          />
          <label htmlFor="floatingTextarea" className="ms-4">
            Enter a Location
          </label>
        </div> */}

      {/* {suggestions.length > 0 && (
          <div>
            <h2 style={{ fontSize: "1.5rem", textAlign: "left" }}>Suggestions:</h2>
            <ul style={{ listStyleType: "none", padding: "0" }} role="listbox">
              {suggestions.map((suggestion) => (
                <li
                  key={suggestion.place_id}
                  style={{
                    fontSize: "1rem",
                    padding: "0.25rem 0.5rem",
                    margin: "0.25rem 0",
                    backgroundColor: "rgba(255, 255, 255, 0.15)",
                    cursor: "pointer",
                  }}
                  tabIndex={-1}
                  role="option"
                  aria-selected="false"
                  onClick={() => handleSuggestionSelected(suggestion)}
                >
                  {suggestion.description}
                </li>
              ))}
            </ul>
          </div>
        )} */}

      {/* {placeDetail && (
        <div>
          <h2 style={{ fontSize: "1.5rem", textAlign: "left" }}>Place detail:</h2>
          <pre
            style={{
              fontSize: "0.85rem",
              textAlign: "left",
              whiteSpace: "pre-wrap",
            }}
          >
            {JSON.stringify(placeDetail, null, 2)}
          </pre>
        </div>
      )} */}

      <div id="googlemaps-attribution-container"></div>
    </>
  );
}

export default GoogleAddressAutoComplete;
