import { React, useEffect, useState } from "react";

import Header from "./Header";
import Section1 from "../pages/Main/Section1";
import Section2 from "../pages/Main/Section2";
import Section3 from "../pages/Main/Section3";
import Section4 from "../pages/Main/Section4";
import Section5 from "../pages/Main/Section5";
import Section6 from "../pages/Main/Section6";
import Section7 from "../pages/Main/Section7";
import Section8 from "../pages/Main/Section8";
import Footer from "./Footer";
import FloatingWhatsAppButton from "../components/UI/FloatingWhatsAppButton";
import GoDaddySSLSeal from "./GoDaddySSLSeal";
import PageTitle from "../components/PageTitle";

const pageTitle = "Education Planner";
export default function Main() {
  const [shouldScroll, setShouldScroll] = useState(false);

  return (
    <>
      <PageTitle title={pageTitle} />
      <div className="">
        <Header />
        <div className="my-1">
          <Section1
            shouldScroll={shouldScroll}
            setShouldScroll={setShouldScroll}
          />
        </div>
        {shouldScroll && (
          <>
            <div
              className="my-3 py-2 my-md-5 py-md-5"
              data-aos="zoom-in-up"
              data-aos-duration="800"
            >
              <Section6 />
            </div>
            <div
              className="my-3 py-2 my-md-5 py-md-5"
              data-aos="zoom-in-up"
              data-aos-duration="800"
            >
              <Section2 />
            </div>
            <div
              className="my-3 py-2 my-md-5 py-md-5"
              data-aos="zoom-in-up"
              data-aos-duration="800"
            >
              <Section3 />
            </div>
            <div
              className="my-3 py-2 my-md-5 py-md-5"
              data-aos="zoom-in-up"
              data-aos-duration="800"
            >
              <Section5 />
            </div>
            <div
              className="my-3 py-2 my-md-5 py-md-5"
              data-aos="zoom-in-up"
              data-aos-duration="800"
            >
              <Section4 />
            </div>
            <Section8 />
            {/* <Footer /> */}
            <GoDaddySSLSeal />
            <FloatingWhatsAppButton />
          </>
        )}
      </div>
    </>
  );
}
