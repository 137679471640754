import http from "./api";

const get = (id) => {
  return http.get(`College?CollegeId=${id}`);
};
const remove = (id) => {
  return http.delete(`College?id=${id}`);
};
const create = (data) => {
  return http.post("College", data);
};
const update = (data) => {
  return http.put(`College`, data);
};
const find = (params) => {
  return http.get("College/search?", { params });
};
const getCollegeLogoByCollegeId = (id) => {
  return http.get(`College/getCollegeLogoByCollegeId?CollegeId=${id}`);
};
const downloadCollegeLogo = (id) => {
  return http.get(`College/downloadCollegeLogo?collegeId=${id}`);
};
const updateCollegelogo = (data) => {
  return http.put(`College/updateCollegelogo`, data);
};
const CollegeDataService = {
  get,
  remove,
  create,
  update,
  find,
  getCollegeLogoByCollegeId,
  downloadCollegeLogo,
  updateCollegelogo,
};

export default CollegeDataService;
