import React, { useState } from "react";
import PropTypes from "prop-types";
import ApplicationStatusButton from "../Applications/ApplicationStatusButton";
import ApplicationStatusList from "../../Services/ApplicationStatusList";

const ApplicationButton = ({ onClick, disabled, children }) => (
  <button
    className="btn btn-dark-white border rounded-circle m-0 p-0"
    style={{ width: "36px", height: "36px" }}
    onClick={onClick}
    disabled={disabled}
  >
    <span className="d-flex justify-content-center align-items-center text2">
      {children}
    </span>
  </button>
);

ApplicationButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const ApplicationsCarousel = ({
  currentStudentData,
  showProgramList,
  RolesService,
  getRole,
}) => {
  const { SuperAdmin, Counsellor, BranchHead } = RolesService;

  const { applications } = currentStudentData;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [hoverIndex, setHoverIndex] = useState(null);
  const visibleApplications = applications?.slice(
    currentIndex,
    currentIndex + 2
  );
  const isLastVisible = currentIndex + 2 >= applications?.length;
  const isFirstVisible = currentIndex === 0;

  const handleNext = () => {
    if (currentIndex + 2 >= applications.length) {
      return;
    }
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? applications.length - 1 : prevIndex - 1
    );
  };

  const disableNext = isLastVisible || applications?.length <= 2;
  const disablePrev = isFirstVisible || applications?.length <= 2;
  if (applications?.length === 0) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ width: "160px" }}
        >
          <span className="text-normal-red text2">0</span>
        </div>
        {getRole === SuperAdmin ||
        getRole === BranchHead ||
        getRole === Counsellor ? (
          <div className="ms-1 d-flex justify-content-end align-items-center">
            <button
              className="bg-orange text-white border rounded-circle d-flex justify-content-center align-items-center text2-5 pt-2"
              style={{ width: "34px", height: "34px", cursor: "pointer" }}
              onClick={() => showProgramList(currentStudentData)}
            >
              +
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  return (
    <>
      <div className="d-flex justify-content-center align-items-center">
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ width: "160px" }}
        >
          {!disablePrev && (
            <ApplicationButton onClick={handlePrev} disabled={disablePrev}>
              &laquo;
            </ApplicationButton>
          )}

          {visibleApplications?.map((application, index) => (
            <div
              key={index}
              className="dropstart position-relative"
              onMouseEnter={() => setHoverIndex(index)}
              onMouseLeave={() => setHoverIndex(null)}
            >
              <a
                className="link-underline link-underline-opacity-0"
                href={`/#/user/viewApplication/${application.studentApplicationId}`}
              >
                <div
                  className="bg-white text-orange border p-2 rounded-circle me-1 d-flex justify-content-center align-items-center"
                  style={{ width: "34px", height: "34px", cursor: "pointer" }}
                >
                  <span className="mt-1 text1-6">
                    {currentIndex + index + 1}
                  </span>
                </div>
                <div
                  className={`${
                    hoverIndex === index ? "d-block" : "d-none"
                  } position-absolute top-0 z-3 end-100 rounded-4 p-3 bg-white`}
                >
                  <div className="text-center text-black text1-3 text-nowrap mb-2">
                    <strong>{application.collegeName}</strong>
                  </div>
                  <div>
                    <ApplicationStatusButton
                      AppStatus={application.applicationStatus}
                      ApplicationStatusList={ApplicationStatusList}
                    />
                  </div>
                </div>
              </a>
            </div>
          ))}

          {!disableNext && (
            <ApplicationButton onClick={handleNext} disabled={disableNext}>
              &raquo;
            </ApplicationButton>
          )}
        </div>
        {getRole === SuperAdmin ||
        getRole === BranchHead ||
        getRole === Counsellor ? (
          <div className="ms-1 d-flex justify-content-end align-items-center">
            <button
              className="bg-orange text-white border rounded-circle d-flex justify-content-center align-items-center text2-5 pt-2"
              style={{ width: "34px", height: "34px", cursor: "pointer" }}
              onClick={() => showProgramList(currentStudentData)}
            >
              +
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

ApplicationsCarousel.propTypes = {
  applications: PropTypes.array.isRequired,
};

export default ApplicationsCarousel;
