import React from "react";
import UserPanel from "../UserPanel";
import TokenService from "../../Services/TokenService";
import DarkTextLogo from "../../assets/logo_text_dark.png";
export default function WelcomeBoard() {
  const getUser = TokenService.getUser();
  return (
    <>
      <div>
        <UserPanel />
        <div className="container">
          <img
            src={DarkTextLogo}
            alt="DarkTextLogo"
            className="img-fluid position-absolute top-50 start-50 translate-middle"
            style={{ opacity: "0.1" }}
          />
          <div className="row">
            <div className="col-12">
              <div className="text-start text4">
                Hello, {getUser.firstName} {getUser.lastName}
              </div>
            </div>
            <div className="col text3 ms-5">
              <span className="ms-5">Welcome to Education Planner</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
