import { useState } from "react";
import { toast } from "react-hot-toast";
import { Table, Modal, Button, Form, Row, Col } from "react-bootstrap";
import IntakeService from "../../Services/IntakeService";
import FormatIntkeDate from "../../../components/UI/FormatIntkeDate";

export default function EditIntake({ getData, data, collegeData }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [IntakeData, setIntakeData] = useState({
    intakeIds: data.intakeList?.map((intake) => ({
      id: intake.id,
      statusId: intake.statusId,
      collegeCampusId: data.campus?.find(
        (campus) => campus.id === intake.collegeCampusId
      )?.id,
      campusName: data.campus?.find(
        (campus) => campus.id === intake.collegeCampusId
      )?.name,
    })),
  });

  const [validated, setValidated] = useState(false);

  const handleRadioChange = (idx, statusId) => {
    setIntakeData((prevData) => {
      const updatedIntakeIds = [...prevData.intakeIds]; // Clone the array of intakeIds
      updatedIntakeIds[idx].statusId = statusId;
      return { ...prevData, intakeIds: updatedIntakeIds }; // Update the intakeIds array
    });
  };

  const handleDeleteIntake = async ({ id }) => {
    try {
      var data = {
        IntakeIds: [id],
      };

      const response = await IntakeService.remove(data);

      if (response.data.success === true) {
        toast.success(response.data.message);
        getData();
      } else if (response.data.validationErrors.length > 0) {
        response.data.validationErrors.map((verr) => toast.error(verr));
      } else {
        toast.error("Something went wrong");
      }
    } catch (e) {
      toast.error(
        (e.response && e.response.data && e.response.data.message) ||
          e.message ||
          e.toString()
      );
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    setValidated(true);

    if (!form.checkValidity()) {
      event.stopPropagation();
      return;
    }
    const loadingMessage = toast.loading("Updating...");
    try {
      const response = await IntakeService.changeIntakeStatusId(IntakeData);

      if (response.data.success === true) {
        getData();
        toast.success(response.data.message, { id: loadingMessage });
      } else if (response.data.success === false) {
        toast.error(response.data.message, { id: loadingMessage });
      } else if (response.data.validationErrors.length > 0) {
        const errorMessage = response.data.validationErrors
          .map((verr) => verr)
          .join(", ");
        toast.error(errorMessage, { id: loadingMessage });
      } else {
        toast.error("Something Went Wrong", { id: loadingMessage });
      }
    } catch (error) {
      toast.error(error.message, { id: loadingMessage });
    } finally {
      setShow(false);
    }
  };

  return (
    <>
      <Button
        variant="outline-primary"
        onClick={handleShow}
        className="w-100 rounded-pill ms-1"
      >
        Update Intake
      </Button>

      <Modal show={show} onHide={handleClose} className="mt-5" size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Update Intake</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            className="px-3"
          >
            <Row xs={2} md={3} className="g-3">
              <Col md={12} className="fs-4 text-center fw-bold">
                <div>{data.name}</div>
                <div>
                  {
                    collegeData.find((college) => college.id === data.collegeId)
                      ?.collegeName
                  }
                </div>
              </Col>

              <Col md={12} className="fs-5">
                <Table bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Campus</th>
                      <th>Intakes</th>
                      <th>Inactive</th>
                      <th>Yet to Open</th>
                      <th>Open</th>
                      <th>Waitlist</th>
                    </tr>
                  </thead>
                  <tbody>
                    {IntakeData.intakeIds
                      .map((intake, idx) => ({
                        ...intake,
                        date: data.intakeList[idx].date,
                        idx: idx,
                      }))
                      .sort((a, b) => new Date(a.date) - new Date(b.date))
                      .map((intake, idx) => (
                        <tr
                          key={intake.idx}
                          className={`${
                            intake.statusId === 0
                              ? "bg-light-red"
                              : intake.statusId === 2
                              ? "bg-light-yellow"
                              : intake.statusId === 3
                              ? "bg-light-green"
                              : intake.statusId === 4
                              ? "bg-light-gray2"
                              : "bg-light-blue"
                          }`}
                        >
                          <td>
                            <div>{intake.campusName}</div>
                          </td>
                          <td>
                            <FormatIntkeDate date={intake.date} />
                          </td>
                          <td>
                            <Form.Check
                              name={`radio-${intake.idx}`}
                              type={"radio"}
                              id={`radio-inactive-${intake.idx}`}
                              onChange={() => handleRadioChange(intake.idx, 0)}
                              checked={intake.statusId === 0}
                            />
                          </td>
                          <td>
                            <Form.Check
                              className=""
                              name={`radio-${intake.idx}`}
                              type={"radio"}
                              id={`radio-active-close-${intake.idx}`}
                              onChange={() => handleRadioChange(intake.idx, 2)}
                              checked={intake.statusId === 2}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name={`radio-${intake.idx}`}
                              type={"radio"}
                              id={`radio-active-open-${intake.idx}`}
                              onChange={() => handleRadioChange(intake.idx, 3)}
                              checked={intake.statusId === 3}
                            />
                          </td>
                          <td>
                            <Form.Check
                              name={`radio-${intake.idx}`}
                              type={"radio"}
                              id={`radio-active-open-${intake.idx}`}
                              onChange={() => handleRadioChange(intake.idx, 4)}
                              checked={intake.statusId === 4}
                            />
                          </td>
                          <td>
                            {intake.statusId === 0 && (
                              <i
                                className="bi bi-trash3 text-danger text1-4"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleDeleteIntake({ id: intake.id })
                                }
                              ></i>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Col>

              <Col md={12}>
                <Modal.Footer className="mt-4">
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button type="submit" variant="success">
                    Update
                  </Button>
                </Modal.Footer>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
