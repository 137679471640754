import { useNavigate } from "react-router-dom";
import SectionsTitle from "../Components/SectionsTitle";

export default function Main({ setModalShow }) {
  const navigate = useNavigate();
  return (
    <div className=" m-4">
      <SectionsTitle title={"How it works"} />

      <div className="bg-dark1 rounded-4 mt-5 text-white">
        <div className="row justify-content-between text-center">
          <div className="col-md aos-init aos-animate" data-aos="zoom-out">
            <div className="step-count" style={{ fontSize: "96px" }}>
              01
            </div>
            <div style={{ fontSize: "24px" }}>Fill up a form</div>
            <div className="px-2" style={{ fontSize: "16px" }}>
              Submit required details.
            </div>
          </div>
          <div className="col-md aos-init aos-animate" data-aos="zoom-out">
            <div className="step-count" style={{ fontSize: "96px" }}>
              02
            </div>
            <div style={{ fontSize: "24px" }}>We will conntect with you</div>
            <div className="px-2" style={{ fontSize: "16px" }}>
              We will get in touch with you to discuss further.
            </div>
          </div>
          <div className="col-md aos-init aos-animate" data-aos="zoom-out">
            <div className="step-count" style={{ fontSize: "96px" }}>
              03
            </div>
            <div style={{ fontSize: "24px" }}>Welcome aboard!</div>
            <div className="px-2" style={{ fontSize: "16px" }}>
              Yay! You are an associate now.
            </div>
          </div>
        </div>

        <div className="row justify-content-center text-center py-5">
          <div className="col-12 mb-4">
            <button
              className="btn btn-light rounded-pill px-5 py-3 text2"
              onClick={() => navigate("/contact-us")}
            >
              Get started
            </button>
          </div>
          <div className="col-12 text2">
            <span className="px-2" style={{ cursor: "pointer" }}>
              What happens after associating with us?
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
