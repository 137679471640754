import React, { useEffect, useState } from "react";
import ToolTip from "../../components/UI/ToolTip";
export default function DocumentsAgent({
  currentEducation,
  documentList,
  documentData,
  handleStudentDocumentsInputChange,
  saveStudentDocumentsData,
  handleDeleteDocument,
  handleDownload,
  fileInputRef,
  getRole,
  RoleService,
  selectedFile,
  selectedTitle,
  isSaving,
  viewing,
  disabled,
  allDraftApplication,
}) {
  const { Counsellor, AdmissionOfficer, BranchHead } = RoleService;

  const getCurrentEducation = [...currentEducation].sort(
    (a, b) => currentEducation?.indexOf(b) - currentEducation?.indexOf(a)
  );
  const [newList, setNewList] = useState([]);

  const staticFields = [
    {
      level: "Passport",
    },
    {
      level: "English Proficiency Test/ Other Test",
    },
  ];

  useEffect(() => {
    setNewList([...staticFields, ...getCurrentEducation]);
  }, [documentData]);
  const filteredList = newList.filter(
    (ed) => !documentList.some((dl) => dl.title === ed.level)
  );

  return (
    <>
      <form id="upload-docs-form">
        <div className="table-responsive rounded-4 overflow-visible">
          <table className="table custom-table">
            <tbody className="text1-4">
              {getRole === BranchHead || getRole === Counsellor ? (
                <tr className="py-3 text-center">
                  <td>
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="col-7">
                        <input
                          className="form-control rounded-pill text1-3"
                          type="text"
                          id="title"
                          name="title"
                          placeholder="Add New Document"
                          value={documentData.title}
                          onChange={handleStudentDocumentsInputChange}
                          required
                          disabled={isSaving}
                        />
                      </div>
                      <div className="col-4 px-3">
                        <ToolTip tooltipText="pdf file only" position="top">
                          <input
                            className="form-control rounded-pill text1-3 me-3"
                            ref={fileInputRef}
                            type="file"
                            id="upload"
                            name="upload"
                            onChange={handleStudentDocumentsInputChange}
                            accept="application/pdf"
                            required
                            disabled={isSaving}
                          />
                        </ToolTip>
                      </div>
                      <div className="col-1">
                        <button
                          className="btn rounded-4 btn-dark-blue d-flex align-items-center justify-content-center text1-4"
                          type="submit"
                          //style={{ minWidth: "140px" }}
                          onClick={saveStudentDocumentsData}
                          disabled={
                            isSaving ||
                            documentData.title === undefined ||
                            documentData.title === ""
                          }
                        >
                          <span>
                            {documentData.title !== undefined && isSaving ? (
                              ""
                            ) : (
                              <i className="bi bi-upload text2"></i>
                            )}
                          </span>
                          {documentData.title !== undefined && isSaving && (
                            <span className="spinner-border"></span>
                          )}
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : null}

              {filteredList?.map((ed, index) => (
                <tr className="py-3 text-center" key={index}>
                  <td>
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="col-7">
                        <input
                          className="form-control rounded-pill text1-3"
                          type="text"
                          id={`title-${index}`}
                          name="title"
                          value={ed.level}
                          onChange={handleStudentDocumentsInputChange}
                          required
                          disabled
                        />
                      </div>
                      <div className="col-4 px-3">
                        <ToolTip tooltipText="pdf file only" position="top">
                          <input
                            className="form-control rounded-pill text1-3 me-3"
                            ref={fileInputRef}
                            type="file"
                            id="upload"
                            name="upload"
                            onChange={(e) =>
                              handleStudentDocumentsInputChange(e, ed.level)
                            }
                            accept="application/pdf"
                            required
                            disabled={isSaving}
                          />
                        </ToolTip>
                      </div>
                      <div className="col-1">
                        <button
                          className="btn rounded-4 btn-dark-blue d-flex align-items-center justify-content-center text1-4"
                          type="button"
                          //style={{ minWidth: "140px" }}
                          value={ed.level}
                          onClick={(e) => saveStudentDocumentsData(e, ed.level)}
                          disabled={
                            selectedFile === undefined ||
                            selectedFile === null ||
                            isSaving
                          }
                        >
                          <span>
                            {selectedTitle === ed.level && isSaving ? (
                              ""
                            ) : (
                              <i className="bi bi-upload text2"></i>
                            )}
                          </span>
                          {selectedTitle === ed.level && isSaving && (
                            <span className="spinner-border"></span>
                          )}
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}

              <>
                {documentList.length > 0
                  ? documentList.map((dl, index) => {
                      if (dl.roleName !== AdmissionOfficer) {
                        return (
                          <tr className="py-3 text-center" key={index}>
                            <td className="d-flex align-items-center justify-content-between">
                              <div className="text1-6 font-bold ms-5">
                                {dl.title}
                              </div>
                              <div className="d-flex align-items-center justify-content-center me-5">
                                <button
                                  className="btn rounded-4 btn-success d-flex align-items-center justify-content-center text1-4 me-3"
                                  type="button"
                                  //value={dl.id}
                                  onClick={(e) => handleDownload(e, dl)}
                                  //disabled={viewing}
                                >
                                  {/* {viewing ? (
                                    <>
                                      <span className="spinner-border"></span>
                                    </>
                                  ) : (
                                    <i className="bi bi-file-earmark-text text2"></i>
                                  )} */}
                                  <i className="bi bi-file-earmark-text text2"></i>
                                </button>
                                <button
                                  className={`btn rounded-4 d-flex align-items-center justify-content-center text1-4 ${
                                    disabled ? "btn-secondary" : "btn-danger"
                                  }`}
                                  type="button"
                                  // style={{ minWidth: "140px" }}
                                  value={dl.id}
                                  onClick={handleDeleteDocument}
                                  disabled={disabled}
                                >
                                  <i className="bi bi-trash3 text2"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      } else {
                        return null;
                      }
                    })
                  : ""}
              </>
            </tbody>
          </table>
        </div>
      </form>
    </>
  );
}
