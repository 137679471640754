import React from 'react'
import RolesService from '../../Services/RolesService'
import TokenService from '../../Services/TokenService'


const AgentAgreement = ({ setActiveTabNext, profileData, setProfileData, disabled,
    handleInputChange, handleSaveProfileData, isFormEdited, setIsFormEdited }) => {
    const getRole = TokenService.getUserRole()
    const { BranchHead } = RolesService
    return (
        <form className='need-validations'>
            <div className="border rounded-4 bg-light-blue1 text1-5 p-3 mb-4">
                <div className="d-flex align-items-center bg-stripped1 border border-top-0 rounded-4 p-3  mb-3">
                    <span className="font-bold">Please read the document and sign below to accept</span>
                    <span className="text-danger">*</span>
                </div>
                <div className="row align-items-center">
                    <div className="col-12">
                        <textarea
                            htmlFor="floatingInput"
                            className="form-control rounded-4  text1-4"
                            placeholder=""
                            style={{ height: "200px" }}
                            required
                            value={profileData.agreement}
                            onChange={handleInputChange}
                            name='agreement'
                            disabled={disabled}
                        />
                    </div>
                </div>
            </div>

            <div className="d-flex justify-content-end my-2 mt-5">
                {getRole === BranchHead ?
                    <button
                        type="button"
                        className="btn btn-light-blue-outline rounded-pill py-3 me-2 col-2 text1-4"
                        onClick={handleSaveProfileData}
                        disabled={disabled || isFormEdited}
                    >
                        Save
                    </button>
                    : null}

            </div>

        </form>
    )
}

export default AgentAgreement
