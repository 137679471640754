import { useCallback, useMemo, useState } from "react";
import Modal from "react-bootstrap/Modal";

import React from "react";
import { toast } from "react-toastify";
import AgentsDataService from "../../Services/AgentsService";
import ExchangeRate from "../../Services/ExchangeRate";
import PaymentService from "../../Services/PaymentService";
import TokenService from "../../Services/TokenService";
import AppStripePayment from "../../pages/Wallet/AppStripePayment";
import platformLogo from "../../assets/logo_dark.png";

export function AddMoneyToWalletModal({
  show,
  handleClose,
  findWalletData,
  retrieveDashbaord,
}) {
  const [payShow, setPayShow] = useState(false);
  const [clientSecret, setClientSecret] = useState("");

  const getUser = TokenService.getUser();

  const initialWalletData = useMemo(() => {
    return {
      amount: 0,
      remarks: "",
      companyId: getUser.companyId,
      type: "",
    };
  }, [getUser.companyId]);

  const [newWalletData, setNewWalletData] = useState(initialWalletData);

  const handleInputChange = useCallback(
    (e) => {
      e.preventDefault();
      const { name, value } = e.target;
      setNewWalletData({ ...newWalletData, [name]: value });
    },
    [newWalletData]
  );

  const exchangeData = ExchangeRate({
    baseCurrency: "INR",
    targetCurrency: "INR",
    baseAmount: newWalletData.amount,
  });

  const stripePayStart = useCallback(async () => {
    setPayShow(true);
    try {
      const agentResponse = await AgentsDataService.get(getUser.companyId);
      // Create the payData object
      const payData = {
        name: getUser.firstName + " " + getUser.lastName,
        description: agentResponse.data.name,
        line1: agentResponse.data.address,
        postalCode: agentResponse.data.postalCode,
        city: agentResponse.data.city,
        state: agentResponse.data.state,
        country: agentResponse.data.country,
        amount: exchangeData.convertedAmount,
      };
      // Check for incomplete fields
      const incompleteFields = Object.keys(payData).filter(
        (key) => !payData[key]
      );

      if (incompleteFields.length > 0) {
        // There are incomplete fields, show an error toast
        toast.error(`Please update: ${incompleteFields.join(", ")}`);
        // You can handle this error further as needed.
      } else {
        // All fields are complete, proceed with the payment
        const paymentResponse = await PaymentService.stripepay(payData);
        setClientSecret(paymentResponse.data.clientSecret);
      }
    } catch (error) {
      toast.error(error);
    }
  }, [
    exchangeData.convertedAmount,
    getUser.companyId,
    getUser.firstName,
    getUser.lastName,
  ]);

  const handleCloseModal = useCallback(() => {
    handleClose();
    setPayShow(false);
    setNewWalletData(initialWalletData);
    setClientSecret("");
  }, [handleClose, initialWalletData]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
      size="md"
      className="custom-modal"
    >
      <Modal.Body className="p-2">
        <i
          className="bi bi-x-circle-fill text2 p-3 text-danger position-absolute end-0 top-0"
          style={{ cursor: "pointer" }}
          onClick={handleCloseModal}
        ></i>
        <div className="d-flex flex-column align-items-center pt-5 gap-5">
          <img src={platformLogo} alt="platformLogo" height="80px" />
          <p className="text2 text-dark1 fw-bold">Add Money to Wallet</p>
        </div>

        {!payShow && (
          <form
            className="needs-validation px-5 pb-5 "
            onSubmit={stripePayStart}
          >
            <div className="row g-3 p-5">
              <div>
                <p className="text-dark1 text1-4">Amount</p>
                <div className="col-12 form-floating">
                  <input
                    type="number"
                    className="form-control rounded-pill border"
                    placeholder="Amount"
                    required
                    id="amount"
                    name="amount"
                    value={newWalletData.amount}
                    onChange={handleInputChange}
                    min={1}
                  />
                  <label
                    htmlFor="floatingInput"
                    className="ms-4 text-light-gray"
                  >
                    Enter Amount<span className="text-danger">*</span>
                  </label>
                </div>
                <p className="mt-1 mb-0 ms-3 text-light-gray">
                  Note: Extra charges applicable
                </p>
              </div>
              <div>
                <p className="text-dark1 text1-4">Remarks</p>
                <div className="col-12 form-floating">
                  <input
                    type="text"
                    className="form-control rounded-pill border"
                    placeholder="Remarks"
                    required
                    id="remarks"
                    name="remarks"
                    value={newWalletData.remarks}
                    onChange={handleInputChange}
                  />
                  <label
                    htmlFor="floatingInput"
                    className="ms-4 text-light-gray"
                  >
                    Enter Remarks<span className="text-danger">*</span>
                  </label>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center gap-4">
              <button
                type="button"
                className="btn btn-light-blue-outline rounded-pill py-2 me-2 px-5 text1-3"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="gradiantButton rounded-pill py-2 px-5 text1-3"
              >
                Submit
              </button>
            </div>
          </form>
        )}
        {payShow && newWalletData.amount > 0 && (
          <AppStripePayment
            clientSecret={clientSecret}
            PaymentService={PaymentService}
            exchangeData={exchangeData}
            newWalletData={newWalletData}
            setNewWalletData={setNewWalletData}
            handleClose={handleClose}
            findWalletData={findWalletData}
            retrieveDashbaord={retrieveDashbaord}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}
