import React, { useState, useEffect, useRef } from "react";
import UserPanel from "../UserPanel";
import Pagination from "../../components/UI/Pagination";
import SearchBar from "../../components/UI/SearchBar";
import SelectItemsPerPage from "../../components/UI/SelectItemsPerPage";
import CollegeDataService from "../../Services/CollegeService";
import Updating from "../../components/UI/Updating";
import { toast } from "react-toastify";
import ChangeLogo from "./ChangeLogo";
import LogoView from "./LogoView";
import PageTitle from "../../components/PageTitle";
import EpLoader from "../../components/UI/EpLoader";

const pageTitle = "Colleges";
export default function CollegeList() {
  const fileInputRefs = useRef({});
  const [loading, setLoading] = useState(true);
  const [isUpdating, setUpdating] = useState(false);

  const [collegesList, setCollegesList] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [itemsLength, setItemsLength] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const getRequestParams = (searchQuery, currentPage, itemsPerPage) => {
    let params = {};
    setLoading(true);
    if (searchQuery) {
      params["search"] = searchQuery;
    }
    if (currentPage) {
      params["currentPage"] = currentPage;
    }

    if (itemsPerPage) {
      params["pageSize"] = itemsPerPage;
    }

    return params;
  };

  const retrieveColleges = async () => {
    try {
      const params = getRequestParams(searchQuery, currentPage, itemsPerPage);
      const response = await CollegeDataService.find(params);
      const Colleges = response.data.response;
      setLoading(false);
      setCollegesList(Colleges);
      setItemsLength(response.data.response[0]?.totalItems);
    } catch (error) {
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString()
      );
    }
  };

  const handlePageSizeChange = (event) => {
    event.preventDefault();
    setItemsPerPage(parseInt(event.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    retrieveColleges();
  }, [searchQuery, currentPage, itemsPerPage]);

  if (loading) {
    return <EpLoader />;
  }

  return (
    <>
      <PageTitle title={pageTitle} />
      <div className="p-2 mt-2">
        <UserPanel title={"College"} />
        {isUpdating ? (
          <Updating />
        ) : (
          <div>
            <div className="">
              <div className="row align-items-baseline mt-3 mx-2 mb-1">
                <div className="col d-flex align-items-center column-gap-3">
                  <SearchBar
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    searchTitle={"Search Colleges"}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
                <div className="col">
                  <div className="d-flex align-items-center justify-content-end text-dark1 column-gap-3">
                    <SelectItemsPerPage
                      handlePageSizeChange={handlePageSizeChange}
                      itemsPerPage={itemsPerPage}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-3 mb-4">
                {itemsLength > 0 && (
                  <Pagination
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    dataLength={itemsLength}
                    itemsPerPage={itemsPerPage}
                  />
                )}
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive rounded-4 text-center">
                    <table className="table custom-table">
                      <thead className="bg-dark1 text-white">
                        <tr className="py-3 text1-3">
                          <th scope="col">Card Logo</th>
                          <th scope="col">Detail Page Logo</th>
                          <th scope="col">Name</th>
                          <th scope="col">Country</th>
                          <th scope="col">Province</th>
                          <th scope="col">Direct/Indirect</th>
                          {/* <th scope="col">Action</th> */}
                        </tr>
                      </thead>
                      <tbody className="text1-4">
                        {collegesList.map((college, index) => (
                          <tr className="py-3" key={index}>
                            <LogoView college={college} />

                            <th>{college.name}</th>

                            <td>{college.country}</td>
                            <td>{college.province}</td>
                            <td>{college.directIndirect}</td>
                            {/* <td>
                              <ChangeLogo
                                college={college}
                                fileInputRef={fileInputRefs}
                                retrieveColleges={retrieveColleges}
                                setUpdating={setUpdating}
                              />
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {itemsLength > 0 && (
                      <Pagination
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                        dataLength={itemsLength}
                        itemsPerPage={itemsPerPage}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
