import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import EditAnnouncement from "./EditAnnouncement";

export default function AnnouncementList({
  data,
  getData,
  handleDelete,
  pageTitle,
}) {
  return (
    <div>
      <Row xs={1} md={4} className="g-3">
        {data.map((item, index) => {
          const { bannerImageUrl, collegeName, description } = item;
          return (
            <Col key={index} style={{ maxWidth: "400px" }}>
              <Card
                style={{
                  height: "300px",
                }}
                className="p-3"
              >
                <div>
                  {bannerImageUrl ? (
                    <span
                      className="d-flex justify-content-center align-items-center py-3 px-0"
                      style={{ width: "100%", height: "14rem" }}
                    >
                      <Card.Img
                        variant="top"
                        src={bannerImageUrl}
                        alt={collegeName}
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </span>
                  ) : (
                    <span
                      className="d-flex justify-content-center align-items-center p-4"
                      style={{ height: "14rem" }}
                    >
                      Logo not found
                    </span>
                  )}
                </div>
                <Card.Body
                  className="border-top"
                  style={{
                    maxHeight: "170px",
                    overflowY: "scroll",
                  }}
                >
                  <Card.Title>
                    Name: <span className="fw-bold">{collegeName}</span>
                  </Card.Title>
                  <Card.Title>
                    Description: <span className="fw-bold">{description}</span>
                  </Card.Title>
                </Card.Body>
                <Row className="mx-4 mt-1">
                  <Col>
                    <EditAnnouncement
                      pageTitle={pageTitle}
                      data={item}
                      getData={getData}
                    />
                  </Col>
                  <Col>
                    <Button
                      variant="outline-danger"
                      className="w-100 rounded-pill"
                      onClick={(e) => handleDelete(e, item)}
                    >
                      <i className="fs-4 d-flex justify-content-center align-items-center bi bi-trash"></i>
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
