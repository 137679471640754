import http from "./api";

const get = (id) => {
  return http.get(`Lead?LeadId=${id}`);
};
const remove = (id) => {
  return http.delete(`Lead/?id=${id}`);
};
const create = (data) => {
  return http.post("Lead", data);
};
const update = (data) => {
  return http.put(`Lead`, data);
};
const find = (data) => {
  return http.post("Lead/search?", data);
};
const changeStatus = (id, data) => {
  return http.put(`Lead/changeLeadStatusId?id=${id}&statusId=${data}`);
};
const LeadDataService = {
  get,
  remove,
  create,
  update,
  find,
  changeStatus,
};

export default LeadDataService;
