import React from "react";
import Lottie from "lottie-react";
import blogButton from "../assets/common/blogButton.svg";
import blogAnimation from "./blogAnimation.json";

export default function HeroSection() {
  return (
    <div className="bg-white position-relative pt-5  pt-lg-3 blog-height">
      <div className="mt-5 pt-5 ps-lg-5 ps-0 h-100 ">
        <div className="d-flex h-100 flex-column gap-5 flex-md-row blog-bg ps-lg-5 ps-0">
          <div className="w-100 w-md-50 ps-5 h-100 ">
            <div className="d-flex flex-column gap-3 mt-2 h-100 justify-content-center">
              <p className="hero-text mt-0 fst-italic mb-0 text-dark1 fw-bold">
                Insights & <br /> Inspiration
              </p>

              <p className="sub-text w-sm-100 w-75 text-dark1 mb-0  mt-0">
                Discover Our Newest Articles, Insights, and Updates
              </p>
            </div>
          </div>
        </div>
        <svg
          className="position-absolute curve-svg "
          width="100%"
          height="40"
          viewBox="0 0 1440 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.29047e-06 40L0 33.6634C93.7171 22.4422 369.101 0 720.901 0C1072.7 0 1346.88 22.4422 1440 33.6634V40L3.29047e-06 40Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
}
