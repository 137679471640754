const apiURLandKEYS = {
  url: process.env.REACT_APP_SERVER_BASE_URL,
  captchaKey: process.env.REACT_APP_CAPTCHA_KEY,
  googleAddressKey: process.env.REACT_APP_GOOGLE_ADDRESS_KEY,
  exchangeRateKey: process.env.REACT_APP_EXCHANGE_RATE_API,
  leadSquaredAccessKey: process.env.REACT_APP_LEAD_SQUARED_ACCESS_KEY,
  leadSquaredSecretKey: process.env.REACT_APP_LEAD_SQUARED_SECRET_KEY,
  getAccessTokenForLocations: process.env.REACT_APP_GET_ACCESS_TOKEN_LOCATIONS,
};

export default apiURLandKEYS;
