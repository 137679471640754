import React, { useState } from "react";
import Agent_1 from "../../assets/agents/Agent-1.svg";
import Agent_2 from "../../assets/agents/Agent-2.svg";
import Agent_3 from "../../assets/agents/Agent-3.svg";
import Agent_4 from "../../assets/agents/Agent-4.svg";
import Agent_5 from "../../assets/institution/icon5.svg";
import Agent_6 from "../../assets/institution/icon6.svg";
import SectionsTitle from "../Components/SectionsTitle";

const servicesData = [
  {
    Image: Agent_1,
    title: "Complete guidance on application process",
  },
  {
    Image: Agent_2,
    title: "Help you choose the right program for students",
  },
  {
    Image: Agent_3,
    title: "Help you choose the right university",
  },
  {
    Image: Agent_4,
    title: "Facilitate scholarships and internships",
  },
  {
    Image: Agent_5,
    title: "Support to address your concerns",
  },
  {
    Image: Agent_6,
    title: "Provide insights",
  },
];

export default function Main() {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const imageStyles = servicesData.map((_, index) => ({
    width: hoveredIndex === index ? "140px" : "130px",
    transform: hoveredIndex === index ? "rotateY(180deg)" : "rotateY(0)",
    transition: "width 0.3s ease, transform 0.4s ease",
  }));

  return (
    <>
      <section id="" className="py-5 m-4">
        <div>
          <SectionsTitle title={"We are here for you"} />

          <div className="row mt-5">
            {servicesData.map((service, index) => (
              <div className="col-lg-4 col-md-6 text-center p-4 aos-init aos-animate" data-aos="zoom-out" key={index}>
                <div
                  className="border rounded-4 shadow"
                  style={{ height: "220px" }}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <img src={service.Image} className="img-fluid p-3" alt="icons" style={imageStyles[index]} />
                  <div className="mt-3 px-5">
                    <div className="text2 text-dark">{service.title}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
