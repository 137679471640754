import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import UserPanel from "../UserPanel";
import AddPersonalInformation from "./AddPersonalInformation";
// import AddEducation from "./AddEducation";
import personalInformationIcon from "../../assets/icons/personal.svg";
// import educationIcon from "../../assets/icons/education.svg";
// import docuemntsIcon from "../../assets/icons/documents.svg";
// import applicationIcon from "../../assets/icons/applications-1.svg";
// import ApplicationForm from "../Applications/ApplicationForm";
// import AddDocuments from "./AddDocuments";
import Tabs from "../../components/UI/Tabs";
import StudentDataService from "../../Services/StudentService";
import { toast } from "react-toastify";

const newStudent = true;

export default function StudentAdd() {
  const navigate = useNavigate();
  const [studentData, setStudentData] = useState(inilizeData);
  const [phoneError, setPhoneError] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setStudentData({ ...studentData, [name]: value });
  };

  const saveStudentData = async (e) => {
    e.preventDefault();
    if (!studentData.contactNumber) {
      setPhoneError("Please enter contact number");
      return;
    }

    setIsSaving(true);

    const data = {
      salutation: studentData.salutation,
      firstName: studentData.firstName,
      middleName: studentData.middleName,
      lastName: studentData.lastName,
      dateofBirth: studentData.dateofBirth,
      nationality: studentData.nationality,
      currentStatus: studentData.currentStatus,
      gender: studentData.gender,
      maritalStatus: studentData.maritalStatus,
      contactNumber: studentData.contactNumber,
      email: studentData.email,
      address: studentData.address,
      country: studentData.country,
      state: studentData.state,
      district: studentData.district,
      city: studentData.city,
      postalCode: studentData.postalCode,
      permanentAddress: studentData.permanentAddress,
      permanentCountry: studentData.permanentCountry,
      permanentState: studentData.permanentState,
      permanentDistrict: studentData.permanentDistrict,
      permanentCity: studentData.permanentCity,
      permanentPostalCode: studentData.permanentPostalCode,
      passportNumber: studentData.passportNumber,
      passportExpiryDate: studentData.passportExpiryDate,
      previousRefusal: studentData.previousRefusal,
      emergencyContactName: studentData.emergencyContactName,
      emergencyContactNumber: studentData.emergencyContactNumber,
      emergencyEmail: studentData.emergencyEmail,
      relation: studentData.relation,
      statusId: 1,
    };

    try {
      const response = await StudentDataService.create(data);

      if (response.data.success === true) {
        navigate("/user/updateStudent/" + response.data.response);
        toast.success(response.data.message);
      } else if (response.data.validationErrors) {
        response.data.validationErrors.map((verr) => toast.error(verr));
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      toast.error(error.message);
    }

    setIsSaving(false);
  };

  const tabs = [
    {
      id: "personalInformation",
      label: "Personal Information",
      icon: personalInformationIcon,
      content: (
        <AddPersonalInformation
          handleInputChange={handleInputChange}
          setStudentData={setStudentData}
          studentData={studentData}
          saveStudentData={saveStudentData}
          isSaving={isSaving}
          newStudent={newStudent}
          phoneError={phoneError}
          setPhoneError={setPhoneError}
        />
      ),
    },
    // {
    //   id: "education",
    //   label: "Education",
    //   icon: educationIcon,
    //   content: <AddEducation />,
    // },
    // {
    //   id: "documents",
    //   label: "Documents",
    //   icon: docuemntsIcon,
    //   content: <AddDocuments />,
    // },
    // {
    //   id: "application",
    //   label: "Application",
    //   icon: applicationIcon,
    //   content: <ApplicationForm />,
    // },
  ];

  return (
    <>
      <div className="p-2 mt-2">
        <UserPanel title={"Add Student"} />

        <Tabs tabs={tabs} isAnimatedBorder={false} />
      </div>
    </>
  );
}

const inilizeData = [
  {
    salutation: "",
    firstName: "",
    middleName: "",
    lastName: "",
    dateofBirth: "",
    nationality: "",
    currentStatus: "",
    gender: "",
    maritalStatus: "",
    contactNumber: "",
    email: "",
    address: "",
    country: "",
    state: "",
    district: "",
    city: "",
    postalCode: "",
    permanentAddress: "",
    permanentCountry: "",
    permanentState: "",
    permanentDistrict: "",
    permanentCity: "",
    permanentPostalCode: "",
    passportNumber: "",
    passportExpiryDate: "",
    previousRefusal: "",
    emergencyContactName: "",
    emergencyContactNumber: "",
    emergencyEmail: "",
    relation: "",
    statusId: 0,
  },
];
