import http from "./api";

const get = (id) => {
  return http.get(`Document?DocumentId=${id}`);
};
const download = (id) => {
  return http.get(`Document/downloadDoc/${id}`);
};
const remove = (id) => {
  return http.delete(`Document?id=${id}`);
};
const create = (data) => {
  return http.post("Document", data);
};
const update = (data) => {
  return http.put(`Document`, data);
};
const findAndGetByStudentID = (id) => {
  return http.get(`Document/searchAndGetDocByStudentId?studentId=${id}`);
};

const StudentDocumentService = {
  get,
  download,
  remove,
  create,
  update,
  findAndGetByStudentID,
};

export default StudentDocumentService;
