import React from "react";
import { Modal } from "react-bootstrap";
import platformLogo from "../../assets/logo_dark.png";

const DetailModal = ({ show, onHide, modalData, modalType }) => {
  return (
    <Modal show={show} onHide={onHide} size="md" className="custom-modal">
      <Modal.Body className="fs-4">
        <i
          className="bi bi-x-circle-fill text2 p-3 text-danger position-absolute end-0 top-0"
          style={{ cursor: "pointer" }}
          onClick={onHide}
        ></i>
        <div className="d-flex flex-column align-items-center pt-5 gap-5">
          <img src={platformLogo} alt="platformLogo" height="80px" />
          <p className="text2 text-dark1 fw-bold">Details</p>
        </div>
        {/* <p className="text-center text-light-gray fw-bold">
                    {message}
                </p> */}
        <div className="d-flex justify-content-center gap-5">
          <div style={{ fontSize: "100px" }}>
            <i class="bi bi-person-lines-fill text-dark1"></i>
          </div>
          {modalType === "admission officer" ? (
            <div className="d-flex flex-column justify-content-center">
              <p className="text1-3">
                Name: {modalData.firstName} {modalData.lastName}
              </p>
              <p className="text1-3">Email: {modalData.email} </p>
              <p className="text1-3">Role: {modalData.role} </p>
              <p className="text1-3">Phone Number: {modalData.phoneNumber} </p>
            </div>
          ) : (
            <div className="d-flex flex-column justify-content-center">
              <p className="text1-3">
                Name: {modalData.userFirstName} {modalData.userLastName}
              </p>
              <p className="text1-3">
                Email: {modalData.userEmailId ? modalData.userEmailId : "N/A"}{" "}
              </p>
              <p className="text1-3">
                Phone Number:{" "}
                {modalData.userPhoneNumber ? modalData.userPhoneNumber : "N/A"}{" "}
              </p>
              <p className="text1-3">Agency: {modalData.agentName} </p>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DetailModal;
