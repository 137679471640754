import React, { useCallback, useEffect, useState } from 'react'
import PageTitle from '../../components/PageTitle'
import UserPanel from '../UserPanel'
import SearchBar from '../../components/UI/SearchBar'
import SelectItemsPerPage from '../../components/UI/SelectItemsPerPage'
import Pagination from '../../components/UI/Pagination'
import { ResetPasswordService } from '../../Services/ResetPasswordService'
import { toast } from 'react-toastify'
import ResetModal from './ResetModal'

const ResetPass = () => {
    const [searchQuery, setSearchQuery] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [allusers, setAllUsers] = useState([])
    const [userInfo, setUserInfo] = useState()
    const [isModalOpen, setIsModalOpen] = useState(false)

    const handlePageSizeChange = (event) => {
        event.preventDefault();
        setItemsPerPage(parseInt(event.target.value));
        setCurrentPage(1);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const getRequestParams = (searchQuery, currentPage, itemsPerPage) => {
        let params = {};
        if (searchQuery) {
            params["search"] = searchQuery;
        }
        if (currentPage) {
            params["currentPage"] = currentPage;
        }

        if (itemsPerPage) {
            params["pageSize"] = itemsPerPage;
        }

        return params;
    };

    const getAllUsers = useCallback(async () => {
        try {
            const params = getRequestParams(searchQuery, currentPage, itemsPerPage);
            const response = await ResetPasswordService.getAllPasswords(params);
            const users = response.data.response;
            setAllUsers(users);
            setTotalItems(users[0]?.totalCount);
        } catch (error) {
            toast.error(
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            );
        }
    }, [searchQuery, currentPage, itemsPerPage]);

    useEffect(() => {
        getAllUsers();
    }, [getAllUsers]);

    return (
        <>
            <PageTitle title={"Reset Password"} />
            <UserPanel title={"Reset Password"} />
            <div
                className={`row align-items-baseline mx-2 mb-1 mt-3`}
            >
                <div className="col d-flex align-items-center column-gap-3">
                    <SearchBar
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        setCurrentPage={setCurrentPage}
                        searchTitle={"Search User"}
                    />
                </div>

                <div className="col">
                    <div className="d-flex align-items-center justify-content-end text-dark1 column-gap-3">
                        {/* <SelectOption handleOptionChange={handleStudnetApproachChange} options={["lead", "direct"]} /> */}
                        <SelectItemsPerPage
                            handlePageSizeChange={handlePageSizeChange}
                        />
                    </div>
                </div>
            </div>
            <div className="mt-3 mb-4">
                {totalItems > 0 && (
                    <Pagination
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                        dataLength={totalItems}
                        itemsPerPage={itemsPerPage}
                    />
                )}
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="table-responsive rounded-4 text-center">
                        <table className="table custom-table">
                            <thead className="bg-dark1 text-white">
                                <tr className="py-3 text1-3">
                                    <th scope="col">Name</th>
                                    <th scope="col">Company Name</th>
                                    <th scope="col">Role</th>
                                    <th scope="col">Email </th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody className="text1-4">
                                {allusers.map((user, index) => (
                                    <tr className="py-3" key={index}>
                                        <td>{user.firstName}{" "}{user.lastName}</td>
                                        <td>{user.companyName}</td>
                                        <td>{user.role}</td>
                                        <td>{user.email}</td>
                                        <td>
                                            <button className='rounded-pill gradiantButton px-4 py-3 text1'
                                                onClick={() => {
                                                    setIsModalOpen(true)
                                                    setUserInfo(user)
                                                }}>
                                                Reset Password
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="mt-3 mb-4">
                {totalItems > 0 && (
                    <Pagination
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                        dataLength={totalItems}
                        itemsPerPage={itemsPerPage}
                    />
                )}
            </div>

            <ResetModal
                showModal={isModalOpen}
                setShowModal={setIsModalOpen}
                userInfo={userInfo}
            />

        </>
    )
}

export default ResetPass
