import React, { useRef } from "react";
import CollegeProgramService from "../../Services/CollegeProgramService";
import TokenService from "../../Services/TokenService";
import RolesService from "../../Services/RolesService";
import { toast } from "react-toastify";

export default function ImportProram({ findProgram, setIsLoading }) {
  const fileInputRef = useRef(null);
  const getRoles = TokenService.getUserRole();
  const { SuperAdmin, ProgramEditor } = RolesService;

  const handleCSVInputChange = (event) => {
    const file = event.target.files && event.target.files[0]; // Check if files exist
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const fileContent = e.target.result;
        const base64String = btoa(fileContent);
        if (isValidCSV(fileContent)) {
          uploadFile(base64String);
        } else {
          fileInputRef.current.value = "";
        }
      };

      reader.readAsBinaryString(file);
    }
  };

  function isValidCSV(csvContent) {
    // Split the CSV content into lines
    const lines = csvContent.split("\n");

    // Check if there are at least two lines (header and data)
    if (lines.length < 3) {
      toast.error("CSV File is Empty:");
      return false;
    }

    // Define the expected headers
    const expectedHeaders = [
      "College Name",
      "Program",
      "Program Level",
      "Campus",
      "Province",
      "IELTS Requirement",
      "PTE Requirement",
      "TOEFL Requirement",
      "Duolingo Requirement",
      "GMAT",
      "GRE",
      "SAT",
      "Academic Requirement",
      "Areas of Interest",
      "Duration",
      "Application Fee",
      "Tuition Fee",
      "PG Work Permit",
      "Course URL",
      "DirectIndirect",
      "Intake",
      "Country",
      "Refund Url",
      "Website",
    ];

    // Split the header row into columns
    const headerRow = lines[0].trim().split(",");
    const missingHeaders = [];

    // Check if the header row contains all of the expected headers
    for (const expectedHeader of expectedHeaders) {
      if (!headerRow.includes(expectedHeader)) {
        missingHeaders.push(expectedHeader);
      }
    }

    if (missingHeaders.length > 0) {
      toast.error("Missing Headers: " + missingHeaders.join(", "));
      return false;
    }

    // Check if the "Application Fee" and "Tuition Fee" columns are not empty in the data rows
    for (let i = 1; i < lines.length; i++) {
      // Ensure that the row exists
      if (lines[i]) {
        // // Check if the row contains a comma (for comma-delimited CSV)
        // if (lines[i].includes(",")) {
        //   toast.error("Row " + (i + 1) + " contains comma");
        //   return false;
        // }

        const dataRow = lines[i].trim().split(",");
        const applicationFeeIndex = expectedHeaders.indexOf("Application Fee");
        const tuitionFeeIndex = expectedHeaders.indexOf("Tuition Fee");

        // Check if the dataRow has enough columns and if the applicationFeeIndex and tuitionFeeIndex are valid
        if (dataRow.length >= Math.max(applicationFeeIndex, tuitionFeeIndex) + 1) {
          // Now, check if the "Application Fee" and "Tuition Fee" columns are not empty
          if (dataRow[applicationFeeIndex].trim() === "" || dataRow[tuitionFeeIndex].trim() === "") {
            toast.error("Application Fee or Tuition Fee is empty in row " + (i + 1));
            return false;
          }
        } else {
          toast.error("Missing columns in row " + (i + 1));
          return false;
        }
      }
    }

    // If all checks pass, the CSV is considered valid
    return true;
  }

  const uploadFile = async (file) => {
    setIsLoading(true);
    var data = {
      file: file,
    };

    try {
      const response = await CollegeProgramService.upload(data);
      toast.success(response.data);
      await findProgram();
    } catch (error) {
      toast.error("Please check file format");
    } finally {
      // Reset the input field after handling the upload
      setIsLoading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  return (
    <>
      {getRoles === SuperAdmin || getRoles === ProgramEditor ? (
        <div className="text1-4 text-dark1 d-flex align-items-center justify-content-center mt-1">
          <i className="bi bi-cloud-plus text-orange text2-5 d-flex align-items-center justify-content-center me-2"></i>
          <label htmlFor="importInput" style={{ cursor: "pointer" }}>
            Upload
          </label>

          <input id="importInput" type="file" className="d-none" ref={fileInputRef} accept="text/csv" onChange={handleCSVInputChange} />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
