import React from "react";
import Slider from "react-slick";
import institution_hero1 from "../../assets/institution/institution_hero1.png";
import institution_hero2 from "../../assets/institution/institution_hero2.png";
import institution_hero3 from "../../assets/institution/institution_hero3.png";
import institution_hero4 from "../../assets/institution/institution_hero4.png";

const settings = {
  dots: false,
  fade: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 1500,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: false,
};

const cards = [
  {
    id: 1,
    photo: institution_hero1,
  },
  {
    id: 2,
    photo: institution_hero2,
  },
  {
    id: 3,
    photo: institution_hero3,
  },

  {
    id: 4,
    photo: institution_hero4,
  },
];

export default function Carousel() {
  return (
    <Slider {...settings}>
      {cards.map((card, index) => {
        return (
          <img
            key={index}
            src={card.photo}
            className={`img-fluid rounded-5 pt-5`}
            alt={card.photo}
          />
        );
      })}
    </Slider>
  );
}
