import React, { useEffect } from "react";
import Header from "../../layout/Header";
import OurStory from "./OurStory";
import WhatWeDo from "./WhatWeDo";
import WhyChooseUs from "./WhyChooseUs";
import Numbers from "./Numbers";
import OurMission from "./OurMission";
import Section8 from "../../pages/Main/Section8";
import filterIcon from "../../assets/student/featuredInstitutions/Img3.jpg";
import PageTitle from "../../components/PageTitle";

const pageTitle = "About us";
const AboutUs = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <PageTitle title={pageTitle} />
      <Header />
      <div className="about-header" style={{ paddingTop: "195px" }}>
        <img
          src={filterIcon}
          alt="filterIcon"
          style={{
            // objectFit: "cover",
            height: "400px",
            width: "100%",
          }}
        />
      </div>
      <div className="container">
        <OurStory />
        <WhatWeDo />
        <WhyChooseUs />
      </div>
      <Numbers />
      <div className="container">
        <OurMission />
      </div>
      <Section8 />
    </>
  );
};

export default AboutUs;
