import React, { useState } from "react";

import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

import Captcha from "../../components/Captcha";

import AuthService from "../../Services/AuthService";
import { useNavigate } from "react-router-dom";

import ConfirmationModal from "../../components/UI/ConfirmationModal";

const inilizeData = {
  email: "",
  currentPassword: "",
  createPassword: "",
  confirmPassword: "",
};

export default function ChangePassword({ modalShow, setModalShow, user }) {
  const { email } = user;

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [viewCurrentPassword, setViewCurrentPassword] = useState(false);
  const handleViewCurrentPassword = () => {
    setViewCurrentPassword(!viewCurrentPassword);
  };
  const [viewCreatePassword, setViewCreatePassword] = useState(false);
  const handleViewCreatePassword = () => {
    setViewCreatePassword(!viewCreatePassword);
  };

  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const handleViewConfirmPassword = () => {
    setViewConfirmPassword(!viewConfirmPassword);
  };

  const [captchaValue, setCaptchaValue] = useState("");

  const captchaToLogin = (captchadata) => {
    setCaptchaValue(captchadata);
  };

  const [iscaptcha, setIsCaptcha] = useState("");
  const onChangeCaptcha = (e) => {
    const iscaptcha = e.target.value;
    setIsCaptcha(iscaptcha);
  };

  const [changePasswordData, setChangePasswordData] = useState(inilizeData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setChangePasswordData((prevData) => ({ ...prevData, [name]: value }));
  };

  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleChangePasswordSubmit = async (e) => {
    e.preventDefault();
    if (iscaptcha === captchaValue) {
      setShowConfirmation(true);
    } else {
      toast.error("The captcha does not match");
    }
  };

  const handleConfirmChangePassword = async () => {
    try {
      setShowConfirmation(false);
      setLoading(true);
      const data = {
        emailAddress: email,
        currentPassword: changePasswordData.currentPassword,
        password: changePasswordData.createPassword,
        confirmPassword: changePasswordData.confirmPassword,
      };

      const response = await AuthService.changepassword(data);

      if (response.data.success === true && response.data.response === true) {
        setChangePasswordData(inilizeData);
        toast.success("Password Changed successfully");
        toast.info(`You will be logout in 3 seconds`);
        setTimeout(() => {
          setLoading(false);
          AuthService.logout();
          navigate("/");
        }, 3000);
      } else if (response.data.validationErrors) {
        response.data.validationErrors.forEach((verr) => toast.error(verr));
        setLoading(false);
      } else if (response.data.success === false && response.data.response === false) {
        setLoading(false);
        toast.error(response.data.message);
      } else {
        toast.error("Something Went Wrong");
        setLoading(false);
      }
      //setLoading(false);
      //AuthService.logout();
      //navigate("/");
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };
  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };
  return (
    <>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        size="md"
        className="custom-modal"
      >
        <Modal.Header className="bg-dark1 text-white d-flex justify-content-between align-items-center py-2 bg-gradient">
          <Modal.Title id="contained-modal-title-vcenter" className="d-flex align-items-center">
            Change your password
          </Modal.Title>
          <i className="bi bi-x-circle text2" style={{ cursor: "pointer" }} onClick={() => setModalShow(false)}></i>
        </Modal.Header>
        <Modal.Body className="">
          <form className="row g-3 needs-validation px-5" onSubmit={handleChangePasswordSubmit}>
            <div className="col-12 form-floating px-3">
              <input
                type="email"
                className="form-control rounded-pill border ps-4 pb-1"
                placeholder="Email*"
                required
                name="email"
                value={email}
                disabled
              />
              <label htmlFor="floatingInput" className="ms-4 pt-2">
                Email address<span className="text-danger">*</span>
              </label>
            </div>
            <div className="col-12 form-floating px-3 position-relative">
              <input
                type={`${viewCurrentPassword ? "text" : "password"}`}
                className="form-control rounded-pill border ps-4 pb-1"
                placeholder="*"
                required
                name="currentPassword"
                value={changePasswordData.currentPassword}
                onChange={handleInputChange}
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.@$!%*?&])[A-Za-z\d.@$!%*?&]{8,}$"
                autoComplete="off"
                disabled={loading}
              />
              <div className="position-absolute top-50 end-0 translate-middle-y me-5 pe-2" onClick={handleViewCurrentPassword}>
                <i className={`bi ${viewCurrentPassword ? "bi-eye-slash" : "bi-eye"} d-flex align-items-center text-dark1 text2`}></i>
              </div>
              <label htmlFor="floatingInput" className="ms-4 pt-2">
                Current Password<span className="text-danger">*</span>
              </label>
            </div>

            <div className="col-12 form-floating px-3 position-relative">
              <input
                type={`${viewCreatePassword ? "text" : "password"}`}
                className="form-control rounded-pill border ps-4 pb-1"
                placeholder="*"
                required
                name="createPassword"
                value={changePasswordData.createPassword}
                onChange={handleInputChange}
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.@$!%*?&])[A-Za-z\d.@$!%*?&]{8,}$"
                autoComplete="off"
                disabled={loading}
              />
              <div className="position-absolute top-50 end-0 translate-middle-y me-5 pe-2" onClick={handleViewCreatePassword}>
                <i className={`bi ${viewCreatePassword ? "bi-eye-slash" : "bi-eye"} d-flex align-items-center text-dark1 text2`}></i>
              </div>
              <label htmlFor="floatingInput" className="ms-4 pt-2">
                Create Password<span className="text-danger">*</span>
              </label>
            </div>

            <div className="col-12 form-floating px-3 position-relative">
              <input
                type={`${viewConfirmPassword ? "text" : "password"}`}
                className="form-control rounded-pill border ps-4 pb-1"
                placeholder="*"
                required
                name="confirmPassword"
                value={changePasswordData.confirmPassword}
                onChange={handleInputChange}
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.@$!%*?&])[A-Za-z\d.@$!%*?&]{8,}$"
                autoComplete="off"
                disabled={loading}
              />
              <div className="position-absolute top-50 end-0 translate-middle-y me-5 pe-2" onClick={handleViewConfirmPassword}>
                <i className={`bi ${viewConfirmPassword ? "bi-eye-slash" : "bi-eye"} d-flex align-items-center text-dark1 text2`}></i>
              </div>
              <label htmlFor="floatingInput" className="ms-4 pt-2">
                Confirm Password<span className="text-danger">*</span>
              </label>
            </div>
            <div className="col-12 px-3">
              <div className="d-flex align-items-center form-floating">
                <input
                  type="text"
                  className="form-control rounded-pill border ps-4 pb-1"
                  placeholder="Captcha*"
                  onChange={onChangeCaptcha}
                  minLength={6}
                  maxLength={6}
                  required
                  autoComplete="off"
                />
                <label htmlFor="floatingInput" className="ms-3 pt-2">
                  Captcha<span className="text-danger">*</span>
                </label>
                <div className="" style={{ minWidth: "120px" }}>
                  <Captcha captchaToAll={captchaToLogin} />
                </div>
              </div>
            </div>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-light-blue-outline rounded-pill py-2 me-2 px-5 text1-3"
                onClick={() => setModalShow(false)}
                disabled={loading}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-dark-blue rounded-pill py-2 px-5 text1-3">
                <span>{loading ? "Please Wait..." : "Save"}</span>
                {loading && <span className="spinner-border"></span>}
              </button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
      <ConfirmationModal
        show={showConfirmation}
        onHide={handleCloseConfirmation}
        onConfirm={handleConfirmChangePassword}
        message={`Are you sure you want to change password?`}
        title="Confirm Changing"
      />
    </>
  );
}
