import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import "./App.css";
import RoutesCMS from "./CMS/RoutesCMS";
import AppMainEP from "./pages/AppMainEP";
import apiURLandKEYS from "./Services/apiURLandKEYS";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <GoogleReCaptchaProvider reCaptchaKey={apiURLandKEYS.captchaKey}>
        <div>
          <AppMainEP />
          <RoutesCMS />
        </div>
      </GoogleReCaptchaProvider>
    </>
  );
}

export default App;
