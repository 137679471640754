import React, { useCallback, useEffect, useState } from "react";
import filterIcon from "../../../assets/icons/filter.svg";
import UserPanel from "../../UserPanel";
import "../Search.css";
import AdvanceFilter from "./AdvanceFilter";

import RolesService from "../../../Services/RolesService";
import TokenService from "../../../Services/TokenService";

import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CollegeDataService from "../../../Services/CollegeService";
import ProgramSearchService from "../../../Services/ProgramSearchService";
import sortIcon from "../../../assets/icons/sort.svg";
import CompareButton from "../../../components/Form/CompareButton";
import PageTitle from "../../../components/PageTitle";
import FormatIntkeDate from "../../../components/UI/FormatIntkeDate";
import MainSearch from "../../../components/UI/MainSearch";
import Pagination from "../../../components/UI/Pagination";
import { usePlaceholder } from "../../../shared/hooks/usePlaceholder";
import ImportProram from "../../Programs/ImportProram";
import Students from "../../Students/StudentsList";
import { ProgramCard } from "../External/ProgramCard";
import DownloadCSV from "./DownloadCSV";
import ProgramSearch from "../../../components/UI/ProgramSearch";
import EpLoader from "../../../components/UI/EpLoader";
import { format, parse } from "date-fns";

export default function SearchResult({
  selectedStudent,
  setIsStudentSelected,
  setSelectedStudent,
}) {
  const pageTitle = "Programs";
  const getRoles = TokenService.getUserRole();
  const { SuperAdmin, ProgramEditor, BranchHead, Counsellor } = RolesService;

  const navigate = useNavigate();
  const navValue = useLocation();

  const [isInputClicked, setIsInputClicked] = useState(false);
  const { placeholder } = usePlaceholder({
    stopChangingText: isInputClicked,
    time: 3950,
  });

  const navSearch = navValue.state?.searchQuery;
  function extractInnerArray(data) {
    if (Array.isArray(data) && Array.isArray(data[0])) {
      return data[0];
    }
    return data;
  }
  const navCountry = extractInnerArray(
    navValue.state?.selectedCountry ?? ["Canada"]
  );

  const currentSelectedStudent = selectedStudent ?? navValue.state?.studentData;
  //selectedStudent !== undefined ? selectedStudent : navValue.state?.studentData !== undefined ? navValue.state?.studentData : undefined;

  const [isLoading, setIsLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [itemsLength, setItemsLength] = useState(0);

  const handlePageSizeChange = (event) => {
    event.preventDefault();
    setItemsPerPage(parseInt(event.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleHover = useCallback((index) => {
    setHoveredIndex(index);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHoveredIndex(null);
  }, []);

  const [programsData, setProgramData] = useState([]);
  const [uniqueCIDs, setUniqueCIDs] = useState();

  const [collapseStatus, setCollapseStatus] = useState(
    Array(programsData.length).fill(false)
  );
  const [selectedPrograms, setSelectedPrograms] = useState([]);

  const [searchQuery, setSearchQuery] = useState(navSearch);
  const [searchResults, setSearchResults] = useState(programsData);

  const [applySelectedFilters, setApplySelectedFilters] = useState("");

  const {
    Intake,
    Campus: campusLst,
    "College Name": collegeNameLst,
    "Program Type": programType,
    "English Requirement": englishRequirement,
    "Areas of Interest": areasOfInterest,
    "Current Residence": currentResidence,
    Nationality,
    Province,
  } = applySelectedFilters;
  const IELTS = englishRequirement?.includes("IELTS");
  const TOEFL = englishRequirement?.includes("TOEFL");
  const PTE = englishRequirement?.includes("PTE");
  const Duolingo = englishRequirement?.includes("Duolingo");
  const GMAT = englishRequirement?.includes("GMAT");
  const GRE = englishRequirement?.includes("GRE");
  const SAT = englishRequirement?.includes("SAT");

  const [appliedDurationAndFee, setAppliedDurationAndFee] = useState({
    durationNumber: 0,
    durationPeriod: "",
    applicationFee: -1,
    tuitionFee: -1,
  });

  const handleSwitchClick = useCallback(
    (e, index) => {
      if (selectedPrograms.length >= 3 && e.target.checked) {
        e.preventDefault();
        alert("Can't select more than three options");
        return;
      }

      handleProgramSelect(index);
    },
    [selectedPrograms.length]
  );

  const handleCollapseToggle = useCallback(
    (index) => {
      const updatedStatus = [...collapseStatus];
      updatedStatus[index] = !updatedStatus[index];
      setCollapseStatus(updatedStatus);
    },
    [collapseStatus]
  );
  const handleProgramSelect = useCallback(
    (index) => {
      const isSelected = selectedPrograms.includes(index);
      const updatedPrograms = isSelected
        ? selectedPrograms.filter((programIndex) => programIndex !== index)
        : selectedPrograms.concat(index);
      setSelectedPrograms(updatedPrograms);
    },
    [selectedPrograms]
  );

  const handleCompareClick = (e) => {
    e.preventDefault();
    const selectedProgramsData = selectedPrograms.map((i) => searchResults[i]);
    navigate("/user/program/compare", {
      state: { selectedProgramsData, selectedCountry },
    });
  };
  const [selectedCountry, setSelectedCountry] = useState(navCountry);

  const [checkedOptions, setCheckedOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});

  const findProgram = useCallback(async () => {
    setIsLoading(true);
    

    try {
      const searchData = {
        currentPage,
        pageSize: itemsPerPage,
        collegeProgramName: searchQuery,
        country: selectedCountry,
        intake: Intake || [],
        campus: campusLst || [],
        duration:
          appliedDurationAndFee.durationNumber === 0
            ? ""
            : `${appliedDurationAndFee.durationNumber} ${appliedDurationAndFee.durationPeriod}`,
        credential: programType || [],
        tuitionFee: appliedDurationAndFee.tuitionFee
          ? parseInt(appliedDurationAndFee.tuitionFee)
          : -1,
        pgWorkPermit: "",
        academicRequirement: "",
        websiteUrl: "",
        ieltsRequirement: !!IELTS,
        pteRequirement: !!PTE,
        toeflRequirement: !!TOEFL,
        duolingoRequirement: !!Duolingo,
        gmat: !!GMAT,
        gre: !!GRE,
        sat: !!SAT,
        areasofInterest: areasOfInterest || [],
        directIndirect: "",
        province: Province || [],
        logo: "",
        collegeName: collegeNameLst || [],
        applicationFee: appliedDurationAndFee.applicationFee
          ? parseInt(appliedDurationAndFee.applicationFee)
          : -1,
        currentResidence: currentResidence || [],
        nationality: Nationality || [],
      };

      const response = await ProgramSearchService.find(searchData);
      const programs = response.data.response;

      const uniqueCollegeIds = Array.from(
        new Set(programs.map((program) => program.collegeId))
      );
      const collegesWithLogos = await Promise.all(
        uniqueCollegeIds.map(async (collegeId) => {
          try {
            const logoResponse = await CollegeDataService.downloadCollegeLogo(
              collegeId
            );
            return {
              collegeId,
              imgUrl: logoResponse?.data?.response.collegeLogoUrl || null,
            };
          } catch (error) {
            console.error(
              `Error fetching logo for college ID ${collegeId}:`,
              error
            );
            return {
              collegeId,
              imgUrl: null,
            };
          }
        })
      );

      setUniqueCIDs(collegesWithLogos);
      setSearchResults(programs);
      setItemsLength(programs[0]?.totalItems);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("An error occurred while fetching data.");
      setIsLoading(false);
    }
  }, [
    Duolingo,
    GMAT,
    GRE,
    IELTS,
    Intake,
    PTE,
    Province,
    SAT,
    TOEFL,
    appliedDurationAndFee.applicationFee,
    appliedDurationAndFee.durationNumber,
    appliedDurationAndFee.durationPeriod,
    appliedDurationAndFee.tuitionFee,
    areasOfInterest,
    campusLst,
    collegeNameLst,
    currentPage,
    itemsPerPage,
    programType,
    searchQuery,
    selectedCountry,
    Nationality,
    currentResidence,
  ]);
  useEffect(() => {
    findProgram();
  }, [findProgram]);

  const [selectedProgram, setSelectedProgram] = useState(null);
  const [isAppSelected, setIsAppSelected] = useState(false);

  const handleSelectedProgram = (storedProgram) => {
    setSelectedProgram(storedProgram);
    setIsAppSelected(true);
  };

  const removeSelectedOption = (tabTitle, option) => {
    setApplySelectedFilters((prevSelectedOptions) => {
      const updatedOptions = { ...prevSelectedOptions };

      if (updatedOptions[tabTitle]) {
        updatedOptions[tabTitle] = updatedOptions[tabTitle].filter(
          (item) => item !== option
        );

        if (updatedOptions[tabTitle].length === 0) {
          delete updatedOptions[tabTitle];
        }
      }

      return updatedOptions;
    });
    setSelectedOptions((prevSelectedOptions) => {
      const updatedOptions = { ...prevSelectedOptions };

      if (updatedOptions[tabTitle]) {
        updatedOptions[tabTitle] = updatedOptions[tabTitle].filter(
          (item) => item !== option
        );

        if (updatedOptions[tabTitle].length === 0) {
          delete updatedOptions[tabTitle];
        }
      }

      return updatedOptions;
    });
    setCheckedOptions((prevCheckedOptions) => {
      const updatedOptions = { ...prevCheckedOptions };
      if (updatedOptions[tabTitle]) {
        updatedOptions[tabTitle] = updatedOptions[tabTitle].filter(
          (item) => item !== option
        );
        if (updatedOptions[tabTitle].length === 0) {
          delete updatedOptions[tabTitle];
        }
      }
      return updatedOptions;
    });
    setHoveredIndex(null);
    setCurrentPage(1);
  };

  const handleClearAllFilters = () => {
    setCurrentPage(1);
    setCheckedOptions([]);
    setSelectedOptions({});
    setApplySelectedFilters("");
    setAppliedDurationAndFee({
      durationNumber: 0,
      durationPeriod: "",
      applicationFee: -1,
      tuitionFee: -1,
    });
  };

  const showStudentList = useCallback((program) => {
    setSelectedProgram(program);
    handleSelectedProgram(program);
    setIsAppSelected(true);
  }, []);

  const formatSessionIntakeDate = (intakeDate) => {
    // Split the input into start and end dates
    const [startDateStr, endDateStr] = intakeDate.split("/");

    const startDate = parse(startDateStr, "dd-MMM-yyyy", new Date());
    const endDate = parse(endDateStr, "dd-MMM-yyyy", new Date());

    const formattedStartDate = format(startDate, "MMM yyyy");
    const formattedEndDate = format(endDate, "MMM yyyy");

    const formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;

    return formattedDateRange;
  };

  if (isAppSelected) {
    return (
      <Students
        selectedProgram={selectedProgram}
        setIsAppSelected={setIsAppSelected}
      />
    );
  }

  return (
    <>
      <PageTitle title={pageTitle} />
      <div className="">
        <div className="row align-items-center">
          <div className="col-3 px-4">
            <p className="text2-2 font-bold text-uppercase">Search</p>
          </div>
          <div className="col-5">
            <ProgramSearch
              setSearchQuery={setSearchQuery}
              searchQuery={searchQuery}
              setCurrentPage={setCurrentPage}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              placeholder={placeholder}
              isInputClicked={isInputClicked}
              setIsInputClicked={setIsInputClicked}
            />
          </div>
          <div className="col-4">
            <UserPanel />
          </div>
        </div>
        {isLoading ? (
          <EpLoader />
        ) : (
          <>
            <div>
              <div className="row align-items-baseline mt-5 mb-1">
                <div className={`col`}>
                  <div
                    className={`text1-4 align-items-center justify-cotent-center d-${
                      Object.keys(applySelectedFilters).length === 0
                        ? "none"
                        : "flex"
                    }`}
                  >
                    <button
                      className="btn btn-sm btn-warning text1-3 rounded-pill p-2 me-1"
                      onClick={handleClearAllFilters}
                    >
                      Clear All
                    </button>
                    Selected options:
                  </div>
                </div>
                <div className="col text-center text1-5">
                  {currentSelectedStudent === undefined ? (
                    searchResults?.length === 0 ? (
                      "No program found for you"
                    ) : (
                      <span>
                        Total{" "}
                        <span className="font-bold">
                          {searchResults?.length}
                        </span>{" "}
                        programs showing out of{" "}
                        <span className="font-bold">{itemsLength}</span>
                      </span>
                    )
                  ) : (
                    <>
                      <span className="fw-bold shadow px-4 py-2 text2 rounded-4">
                        {currentSelectedStudent.salutation !== null
                          ? currentSelectedStudent.salutation +
                            " " +
                            currentSelectedStudent.firstName +
                            " " +
                            currentSelectedStudent.lastName
                          : "" +
                            " " +
                            currentSelectedStudent.firstName +
                            " " +
                            currentSelectedStudent.lastName}
                      </span>
                      <div className="d-flex align-items-center justify-content-center mt-1">
                        <div
                          className="btn-dark-red text-4 rounded-pill py-2 col-4"
                          onClick={(e) => {
                            e.preventDefault();
                            setIsStudentSelected(false);
                            setSelectedStudent(null);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Re-Select Student
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="col">
                  <div className="d-flex align-items-center justify-content-end text-dark1 column-gap-3">
                    {getRoles === SuperAdmin || getRoles === ProgramEditor ? (
                      <>
                        <DownloadCSV />
                        <ImportProram
                          findProgram={findProgram}
                          setIsLoading={setIsLoading}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    <div className="text1-4 text-dark1">
                      <img
                        src={sortIcon}
                        alt="sortIcon"
                        className="img-fluid"
                      />
                    </div>
                    <div className="text1-4 text-dark1">
                      <select
                        className="form-select rounded-4 text1-3 py-2"
                        aria-label="Items Per Page"
                        value={itemsPerPage}
                        onChange={handlePageSizeChange}
                      >
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={75}>75</option>
                        <option value={100}>100</option>
                      </select>
                    </div>
                    <div
                      className="text1-4 text-dark1 "
                      style={{ cursor: "pointer" }}
                      data-bs-toggle="modal"
                      data-bs-target="#filterModal"
                    >
                      <img
                        src={filterIcon}
                        alt="filterIcon"
                        className="img-fluid"
                      />
                      Advance Search
                    </div>
                    <CompareButton
                      onClick={handleCompareClick}
                      selectedProgramsLength={selectedPrograms.length}
                    />
                  </div>
                </div>
              </div>
            </div>
            <AdvanceFilter
              setCurrentPage={setCurrentPage}
              setApplySelectedFilters={setApplySelectedFilters}
              findProgram={findProgram}
              setAppliedDurationAndFee={setAppliedDurationAndFee}
              checkedOptions={checkedOptions}
              setCheckedOptions={setCheckedOptions}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              appliedDurationAndFee={appliedDurationAndFee}
              formatSessionIntakeDate={formatSessionIntakeDate}
            />
            {/* <div className="row">
              {applySelectedFilters &&
                Object.entries(applySelectedFilters).map(([key, values]) =>
                  values.map((value, index) => (
                    <div
                      key={`${key}-${index}`}
                      className={`col-auto rounded-pill me-1 mb-2 text1-3 text-center ${
                        hoveredIndex === index + key ? "bg-danger text-white" : "border-dark1 text-dark1"
                      } `}
                      style={hoveredIndex === index + key ? { cursor: "pointer" } : {}}
                      onMouseOver={() => handleHover(index + key)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <span className="d-flex align-items-center justify-content-center p-1">{value.replace(/\//g, ",")}</span>
                      {hoveredIndex === index + key && (
                        <span className="d-flex align-items-center justify-content-center" onClick={handleFilterRemove}>
                          Remove
                        </span>
                      )}
                    </div>
                  ))
                )}
            </div> */}
            <div className="row ms-1">
              {applySelectedFilters &&
                Object.entries(applySelectedFilters).map(
                  ([tabTitle, selectedValues]) =>
                    selectedValues.map((option, index) => (
                      <div
                        key={`${tabTitle}-${index}`}
                        className={`col-auto rounded-pill me-1 mb-2 text1-3 text-center ${
                          hoveredIndex === index + tabTitle
                            ? "bg-normal-red text-white border border-danger"
                            : "text-dark1 border-dark1"
                        } `}
                        onMouseOver={() => handleHover(index + tabTitle)}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => removeSelectedOption(tabTitle, option)}
                        style={{ cursor: "pointer" }}
                      >
                        <span className="d-flex align-items-center justify-content-center p-1">
                          {tabTitle === "Areas of Interest"
                            ? option.replace(/\//g, ",")
                            : tabTitle === "Intake"
                            ? formatSessionIntakeDate(option)
                            : option}
                          {hoveredIndex === index + tabTitle && (
                            <span className="d-flex align-items-center justify-content-center ms-1">
                              <i className="bi bi-trash"></i>
                            </span>
                          )}
                        </span>
                      </div>
                    ))
                )}
              {/* {applySelectedFilters &&
                Object.entries(applySelectedFilters).map(([key, values]) =>
                  values.map((value, index) => (
                    <div key={`${key}-${index}`} className={`col-auto rounded-pill me-1 mb-2 text1-3 text-center border-dark1 text-dark1 `}>
                      <span className="d-flex align-items-center justify-content-center">{value.replace(/\//g, ",")}</span>
                    </div>
                  ))
                )} */}
            </div>

            <div className="mt-3 mb-4">
              {itemsLength > 0 && (
                <Pagination
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                  dataLength={itemsLength}
                  itemsPerPage={itemsPerPage}
                />
              )}
            </div>

            <div className="row mt-3">
              {/* <ResultCard
                handleSwitchClick={handleSwitchClick}
                handleCollapseToggle={handleCollapseToggle}
                selectedPrograms={selectedPrograms}
                collapseStatus={collapseStatus}
                searchResults={searchResults}
                storeSelectedProgram={handleSelectedProgram}
                selectedStudent={currentSelectedStudent}
                findProgram={findProgram}
                uniqueCIDs={uniqueCIDs}
                RolesService={RolesService}
                getRoles={getRoles}
              /> */}
              <ul className="program-list-wrapper">
                {searchResults.map((program, index) => (
                  <li className="resultCard" key={index}>
                    {/* needs refactoring */}
                    <ProgramCard
                      program={program}
                      uniqueCIDs={uniqueCIDs}
                      selectedPrograms={selectedPrograms}
                      handleSwitchClick={handleSwitchClick}
                      index={index}
                      userRole={getRoles}
                      allRoles={RolesService}
                      showDeleteIcon={
                        Boolean(!selectedStudent && getRoles === SuperAdmin) ||
                        (!selectedStudent && getRoles === ProgramEditor)
                      }
                      findProgram={findProgram}
                      showEnquireButton={false}
                      showStartApplicationButton={
                        (getRoles === SuperAdmin ||
                          getRoles === BranchHead ||
                          getRoles === Counsellor) &&
                        !Boolean(selectedStudent)
                      }
                      selectedStudent={selectedStudent}
                      showGenerateApplicationButton={
                        (getRoles === SuperAdmin ||
                          getRoles === BranchHead ||
                          getRoles === Counsellor) &&
                        Boolean(selectedStudent)
                      }
                      showStudentList={showStudentList}
                    />
                  </li>
                ))}
              </ul>
            </div>
            <div>
              {itemsLength > 0 && (
                <Pagination
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                  dataLength={itemsLength}
                  itemsPerPage={itemsPerPage}
                />
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}
