import React, { useEffect } from "react";
import Sidebar from "./Sidebar";
import {
  Link,
  Outlet,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import "./AppCMS.css";
import logoTextDark from "./Assets/logo_text_dark.png";

import TokenService from "../Services/TokenService";
import Logout from "../components/LogOut";

export default function AppCMS() {
  const navigate = useNavigate();
  const location = useLocation();
  const user = TokenService.getUser();
  const getRole = TokenService.getUserRole();
  useEffect(() => {
    if (user) {
      if (getRole !== "Super Admin" && getRole !== "Program Editor") {
        navigate("/unauthorized", { replace: true });
      }
    }
    // eslint-disable-next-line
  }, [getRole, user]);
  return (user && getRole === "Super Admin") || getRole === "Program Editor" ? (
    <>
      <div className="fs-4">
        <div className="row m-0 p-0">
          <div className="col m-0 p-0 bg-body-tertiary ">
            <Sidebar />
          </div>
          <div className="col-10">
            <div className="row ">
              <nav className="navbar bg-body-tertiary border-bottom pt-4">
                <div className="ms-5 fs-3">
                  Welcome to Content Management System of Education Planner
                </div>
                <div className="d-flex justify-content-end align-items-center me-5">
                  <div className="dropdown">
                    <button
                      className="btn btn-primary text-start fs-4"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ minWidth: "200px" }}
                    >
                      <span className="text-white">
                        {`${user?.firstName} ${user?.lastName} (${user?.role})`}{" "}
                      </span>
                    </button>
                    <ul
                      className="dropdown-menu dropdown-menu-end"
                      style={{ minWidth: "190px" }}
                    >
                      <li>
                        <Link className="dropdown-item" to={getRole === "Program Editor" ? "/user" : "/user/dashboard"}>
                          Exit CMS
                        </Link>
                      </li>
                      <li>
                        <Logout />
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
            <div className="m-0 p-0">
              <div className="col-12 m-0 p-0 mt-3 postion-relative">
                <div className="position-absolute top-50 start-50 translate-middle ms-5">
                  <img
                    src={logoTextDark}
                    alt={logoTextDark}
                    className="img-fluid ms-5"
                    style={{ opacity: "0.03", width: "500px" }}
                  />
                </div>

                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
}
