import React from "react";
import { useComponentVisible } from "../../shared/hooks/useComponentVisible";
import { ListGroup } from "react-bootstrap";

export function Autocomplete({ value, onChange, list, handleItemClick }) {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible();
  return (
    <>
      <input
        ref={ref}
        value={value}
        onClick={() => setIsComponentVisible(!isComponentVisible)}
        className="align-self-end select-container text1-4 bg-dark1 text-white mt-3 "
        onChange={onChange}
      />

      {isComponentVisible ? (
        <ListGroup className="dropdown-options-container w-100 bg-white ">
          {list.length > 0 ? (
            list.map((item) => (
              <ListGroup.Item
                className="text1-4 list-item"
                key={item.id}
                onClick={(e) => handleItemClick(e, item)}
              >
                {item.name}
              </ListGroup.Item>
            ))
          ) : (
            <ListGroup.Item>No options</ListGroup.Item>
          )}
        </ListGroup>
      ) : null}
    </>
  );
}
