import React, { useCallback, useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import UserPanel from "../UserPanel";
import SearchBar from "../../components/UI/SearchBar";
import SelectItemsPerPage from "../../components/UI/SelectItemsPerPage";
import Pagination from "../../components/UI/Pagination";
import "./MarketPlace.css";
import GicServices from "../../Services/GicServices";
import { toast } from "react-toastify";
import TokenService from "../../Services/TokenService";
import RolesService from "../../Services/RolesService";
import InvoiceModal from "./InvoiceModal";
import { useNavigate } from "react-router-dom";
import EpLoader from "../../components/UI/EpLoader";
import icici from "../../assets/images/icici.svg";
import cibc from "../../assets/images/cibc.svg";
import FormatDOB from "../../components/UI/FormatDOB";
import StudentConfirmationModal from "./StudentConfirmationModal";

const pageTitle = "Detail Page";

const status = [
  "All",
  "In progress",
  "Account Opened",
  "Account Funded",
  "Commission Paid",
];

const GicDetails = () => {
  const navigate = useNavigate();

  const getRole = TokenService.getUserRole();
  const { BranchHead, Accounts } = RolesService;
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filter, setFilter] = useState("All");
  const [studentsData, setStudentsData] = useState([]);
  const [InvoiceModalOpen, setInvoiceModalOpen] = useState(false);
  const [applicantId, setApplicantId] = useState();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handlePageSizeChange = (event) => {
    event.preventDefault();
    setItemsPerPage(parseInt(event.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getRequestParams = (searchQuery, currentPage, itemsPerPage, filter) => {
    let params = {};
    if (searchQuery) {
      params["search"] = searchQuery;
    }
    if (currentPage) {
      params["currentPage"] = currentPage;
    }

    if (itemsPerPage) {
      params["pageSize"] = itemsPerPage;
    }
    if (filter) {
      params["applicationStatus"] = filter === "All" ? "" : filter;
    }

    return params;
  };

  const getGicDetails = useCallback(async () => {
    try {
      setIsLoading(true);
      const params = getRequestParams(
        searchQuery,
        currentPage,
        itemsPerPage,
        filter
      );
      const response = await GicServices.getGicDetails(params);
      const students = response.data.response;
      setStudentsData(students);
      setTotalItems(students[0]?.totalItems);
    } catch (error) {
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString()
      );
    } finally {
      setIsLoading(false);
    }
  }, [searchQuery, currentPage, itemsPerPage, filter]);

  useEffect(() => {
    getGicDetails();
  }, [getGicDetails]);

  const updateStatus = useCallback(
    async (applicantStatus, id) => {
      try {
        if (applicantStatus === "Commission Paid") {
          setApplicantId(id);
          setInvoiceModalOpen(true);
          return;
        }
        setIsUpdating(true);
        const body = {
          id,
          applicantStatus,
        };
        const response = await GicServices.updateStatus(body);
        if (response.data.success === true) {
          toast.success(response.data.message);
          await getGicDetails();
        } else if (response.data.validationErrors) {
          response.data.validationErrors.forEach((verr) => toast.error(verr));
        } else {
          toast.error("Something Went Wrong");
        }
      } catch (error) {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
        );
      } finally {
        setIsUpdating(false);
      }
    },
    [getGicDetails]
  );

  if (isLoading) return <EpLoader />;

  return (
    <>
      <PageTitle title={pageTitle} />
      <div className="p-2 mt-2">
        <div className="row align-items-center">
          <div className="col">
            <UserPanel title={pageTitle} />
          </div>
        </div>
        <div className={`row align-items-baseline mx-2 mb-1 mt-3`}>
          <div className="col d-flex align-items-center column-gap-3">
            <SearchBar
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              setCurrentPage={setCurrentPage}
              searchTitle={"Search User"}
            />
          </div>
          <StudentConfirmationModal
            show={showModal}
            setShowModal={setShowModal}
          />

          <div className="col">
            <div className="d-flex align-items-center justify-content-end text-dark1 column-gap-3">
              {getRole === BranchHead ? (
                <button
                  onClick={() => setShowModal(true)}
                  className="btn bg-dark1 text-white px-3 rounded-pill text1-4 me-5"
                >
                  Apply now
                </button>
              ) : null}

              <SelectItemsPerPage handlePageSizeChange={handlePageSizeChange} />
            </div>
          </div>
        </div>
        <div className="mt-3 mb-4">
          {totalItems > 0 && (
            <Pagination
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              dataLength={totalItems}
              itemsPerPage={itemsPerPage}
            />
          )}
        </div>

        <div className="row gap-4 justify-content-between">
          <div className="col-2">
            <div className="text1-5 mb-3">Status</div>
            <div className="border rounded-4 d-flex flex-column p-3 gap-3 text1-5 text-dark1">
              {status.map((item) => (
                <div
                  onClick={() => setFilter(item)}
                  style={{ cursor: "pointer" }}
                  className={`${
                    filter === item
                      ? " bg-dark1 rounded-4 text-white ps-3 py-1"
                      : "ps-3 py-1"
                  }`}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
          <div
            className="px-0"
            style={{
              maxWidth: "82%",
            }}
          >
            <div className="table-responsive rounded-4 text-center">
              <table className="table border rounded">
                <thead className="">
                  <tr className="py-4 text1-3 bg-light0">
                    <th scope="col"> Date </th>
                    {getRole === Accounts ? <th>Agency Name</th> : null}
                    {getRole === Accounts ? <th>Agent Name</th> : null}

                    <th scope="col">Student Name</th>
                    <th scope="col"> Bank </th>

                    <th scope="col">GIC Id</th>
                    <th scope="col">Passport number</th>
                    <th scope="col">Status </th>
                  </tr>
                </thead>
                <tbody className="">
                  {studentsData.map((stud, index) => (
                    <tr className="text1-3 border" key={index}>
                      <td
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(
                            `/user/marketplace/form/${stud.applicantId}`
                          );
                        }}
                      >
                        <FormatDOB
                          date={stud.lastApplicationStatusChangedDate}
                        />
                      </td>
                      {getRole === Accounts ? (
                        <td
                          className=""
                          style={{
                            cursor: "pointer",
                            maxWidth: "180px",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(
                              `/user/marketplace/form/${stud.applicantId}`
                            );
                          }}
                        >
                          {stud.companyName}
                        </td>
                      ) : null}
                      {getRole === Accounts ? (
                        <td
                          style={{
                            cursor: "pointer",
                            maxWidth: "50px",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(
                              `/user/marketplace/form/${stud.applicantId}`
                            );
                          }}
                        >
                          {stud.agentFirstName} {stud.agentLastName}
                        </td>
                      ) : null}

                      <td
                        style={{
                          cursor: "pointer",
                          maxWidth: "60px",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(
                            `/user/marketplace/form/${stud.applicantId}`
                          );
                        }}
                      >
                        {stud.firstName} {stud.lastName}
                      </td>
                      <td
                        className="pe-3"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(
                            `/user/marketplace/form/${stud.applicantId}`
                          );
                        }}
                      >
                        {stud.bank}
                      </td>

                      <td
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(
                            `/user/marketplace/form/${stud.applicantId}`
                          );
                        }}
                      >
                        <div className="border rounded-pill py-1 bg-light0 fw-bold">
                          {stud.formId}
                        </div>
                      </td>

                      <td
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(
                            `/user/marketplace/form/${stud.applicantId}`
                          );
                        }}
                        className="d-flex justify-content-center"
                      >
                        <div className="border rounded-pill py-1 bg-light0 fw-bold  w-75">
                          {stud.passport ? stud.passport : "N/A"}
                        </div>
                      </td>
                      <td>
                        {getRole === BranchHead ? (
                          <button className="btn bg-dark1 py-2 text-white text1-2 rounded-pill">
                            {stud.applicationStatus}
                          </button>
                        ) : (
                          <select
                            className="rounded-pill p-2 bg-dark1 text-white"
                            value={stud.applicationStatus}
                            onChange={(e) => {
                              updateStatus(e?.target?.value, stud.applicantId);
                            }}
                          >
                            {isUpdating ? (
                              <option className="bg-white text-black" value="">
                                Please Wait...
                              </option>
                            ) : (
                              <>
                                <option
                                  className="bg-white text-black"
                                  value="In Progress"
                                >
                                  In Progress
                                </option>
                                <option
                                  className="bg-white text-black"
                                  value="Account Opened"
                                >
                                  Account Opened
                                </option>
                                <option
                                  className="bg-white text-black"
                                  value="Account Funded"
                                >
                                  Account Funded
                                </option>
                                <option
                                  className="bg-white text-black"
                                  value="Commission Paid"
                                >
                                  Commission Paid
                                </option>
                              </>
                            )}
                          </select>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <InvoiceModal
            showModal={InvoiceModalOpen}
            handleCloseModal={() => setInvoiceModalOpen(false)}
            applicantId={applicantId}
            getGicDetails={getGicDetails}
          />
        </div>
        <div className="mt-3 mb-4">
          {totalItems > 0 && (
            <Pagination
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              dataLength={totalItems}
              itemsPerPage={itemsPerPage}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default GicDetails;
