import React, { useCallback, useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import UserPanel from "../UserPanel";
import Pagination from "../../components/UI/Pagination";

import sortIcon from "../../assets/icons/sort.svg";
import "./Request.css";
import RequestService from "../../Services/RequestService";
import { toast } from "react-toastify";
import FormatDate from "../../components/UI/FormatDate";
import RemarkDialog from "./RemarkDialog";
import TokenService from "../../Services/TokenService";
import RolesService from "../../Services/RolesService";
import { useNavigate } from "react-router-dom";

const StatusOptions = [
  { label: "Accepted", value: 2 },
  { label: "Declined", value: 1 },
];

export default function RequestsList() {
  const [currentPage, setCurrentPage] = useState(1);
  const [requestList, setRequestList] = useState(null);
  const [statusName, setStatusName] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [openRemark, setOpenRemark] = useState(null);

  const getRole = TokenService.getUserRole();
  const { Accounts } = RolesService;

  const navigate = useNavigate()
  const handleStatusChange = useCallback((event) => {
    setStatusName(event.target.value);
    setCurrentPage(1);
  }, []);

  const getRequestParams = useCallback((currentPage, statusName) => {
    setIsLoading(true);
    let params = {
      Type: getRole === Accounts ? "Agent" : "Student",
      PageSize: 10,
    };
    if (currentPage) {
      params["CurrentPage"] = currentPage;
    }
    if (statusName) {
      params["StatusId"] = statusName;
    }

    return params;
  }, [Accounts, getRole]);

  const getRequests = useCallback(async () => {
    try {
      const params = getRequestParams(currentPage, statusName);

      const response = await RequestService.get(params);

      if (response.data.success === true) {
        setTotalItems(response.data.response[0]?.totalItems);
        setRequestList(response.data.response);
      } else if (response.data.validationErrors.length > 0) {
        response.data.validationErrors.map((verr) => toast.error(verr));
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      );
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, getRequestParams, statusName]);

  useEffect(() => {
    getRequests();
  }, [statusName, currentPage, getRequests]);

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const updateStatus = useCallback(
    async (id, status) => {
      try {
        const data = {
          id: id,
          statusId: status,
        };

        const response = await RequestService.update(data);

        if (response.data.success === true) {
          toast.success(response.data.message);
          await getRequests();
        } else if (response.data.validationErrors) {
          response.data.validationErrors.forEach((verr) => toast.error(verr));
        } else {
          toast.error("Something Went Wrong");
        }
      } catch (error) {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        );
      }
    },
    [getRequests]
  );

  return (
    <>
      <PageTitle title="Applications" />
      <div className="p-2 mt-2">
        <div className="row align-items-center">
          <div className="col">
            <UserPanel title={"Requests"} />
          </div>
        </div>
      </div>
      <>
        <div className="d-flex justify-content-end align-items-center mt-3 px-5 mb-3">
          <div className="col-5">
            <div className="d-flex align-items-center justify-content-end text-dark1 column-gap-3">
              <div className="text1-4 text-dark1">
                <img src={sortIcon} alt="sortIcon" className="img-fluid" />
              </div>
              <div className=" text1-4 text-white">
                <select
                  className="select-form text1-4 bg-dark1 text-white py-4 rounded-pill px-5 "
                  value={statusName}
                  aria-label="request status"
                  onChange={handleStatusChange}
                >
                  <option value={0}>All Requests</option>
                  {StatusOptions?.map((option, index) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3 mb-4">
          {totalItems > 0 && (
            <Pagination
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              dataLength={totalItems}
              itemsPerPage={10}
            />
          )}
        </div>
        <div className="row">
          <div className="col-12">
            <div className="table-responsive rounded-4">
              <table className="table custom-table">
                <thead className="bg-dark1 text-white text-center">
                  <tr className="py-1 text1-3">
                    <th scope="col">Agent Name</th>
                    {getRole !== Accounts ?
                      <>
                        <th scope="col">Student Name</th>
                        <th scope="col"> Student ID</th>
                      </>
                      :
                      <th scope="col"> Company Name</th>
                    }
                    <th scope="col">Accept</th>
                    <th scope="col">Decline</th>
                    <th scope="col">Remarks</th>
                  </tr>
                </thead>
                <tbody className="text1-4 text-center">
                  {requestList &&
                    requestList.map((req, index) => (
                      <tr key={index}>
                        <td>
                          <div onClick={() => {
                            if (getRole === Accounts)
                              navigate('/user/profile', { state: req.referenceId })
                          }} style={{ cursor: 'pointer' }}>
                            {req.agentName}
                          </div>
                        </td>
                        {getRole !== Accounts ?
                          <>
                            <td>{req.studentName}</td>
                            <td>
                              <FormatDate
                                date={req.studentCreatedDate}
                                idNo={req.studentIdNumber}
                              />
                            </td>
                          </> :
                          <td>
                            {req.companyName}
                          </td>
                        }
                        <td className="text-center">
                          <i
                            className={`bi bi-check-circle-fill text-${req.statusId !== 1 ? "success" : "light-gray"
                              } text1-8`}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (req.statusId === 0) {
                                updateStatus(req.id, 2);
                              }
                            }}
                          ></i>
                        </td>
                        <td className="text-center">
                          <i
                            className={`bi bi-x-circle-fill text-${req.statusId === 0 || req.statusId === 1
                              ? "danger"
                              : "light-gray"
                              } text1-8`}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (req.statusId === 0) {
                                updateStatus(req.id, 1);
                              }
                            }}
                          ></i>
                        </td>
                        <td>
                          <div
                            className={`d-flex position-relative justify-content-center gap-3 align-items-center `}
                          >
                            <span className="remark-text">{req.remarks}</span>

                            <i
                              className={`bi ${openRemark === index
                                ? "bi-chevron-up"
                                : "bi-chevron-down"
                                } text-dark`}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                setOpenRemark(
                                  openRemark === index ? null : index
                                )
                              }
                            ></i>
                          </div>
                          {openRemark === index && (
                            <RemarkDialog
                              open={openRemark === index}
                              handleClose={() => setOpenRemark(null)}
                              req={req}
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {totalItems > 0 && (
                <Pagination
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                  dataLength={totalItems}
                  itemsPerPage={10}
                />
              )}
            </div>
          </div>
        </div>
      </>
    </>
  );
}
