import React, { useRef } from "react";

import { useCallback, useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-hot-toast";
import addIcon from "../../../assets/icons/add.svg";
import AddImage from "./AddImage";
import AnnouncementService from "../../Services/AnnouncementService";

const initializeData = {
  collegeName: "",
  description: "",
  image: {
    logo: "",
    logoName: "",
    type: "",
  },
};

export default function AddAnnouncement({ pageTitle, getData }) {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setBannerData(initializeData);
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const [validated, setValidated] = useState(false);
  const fileInputRef = useRef(null);

  const [bannerData, setBannerData] = useState(initializeData);
  const [IsSaving, setIsSaving] = useState(false);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      setValidated(true);

      if (form.checkValidity() === false) {
        event.stopPropagation();
        return;
      }
      setIsSaving(true);
      try {
        const loadingMessage = toast.loading("Creating...");

        const payload = {
          ...bannerData,
          bannerName: bannerData.image.logoName,
          bannerImageContent: bannerData.image.logo,
        };

        const response = await AnnouncementService.create(payload);

        if (response.data.success === true) {
          getData();
          toast.success(response.data.message, { id: loadingMessage });
          setBannerData(initializeData);
          setValidated(false);
          setShow(false);
          setIsSaving(false);
        } else if (
          response.data.success === false &&
          response.data.response === false
        ) {
          toast.error(response.data.message, { id: loadingMessage });
          setIsSaving(false);
        } else if (
          response.data.success === false &&
          response.data.validationErrors.length > 0
        ) {
          response.data.validationErrors.map((verr) =>
            toast.error(verr, { id: loadingMessage })
          );
          setIsSaving(false);
        } else {
          setIsSaving(false);
          toast.error("Something Went Wrong", { id: loadingMessage });
        }
      } catch (error) {
        console.log(error);
        setIsSaving(false);
        toast.error("An error occurred");
      }
    },
    [bannerData]
  );

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setBannerData((prevData) => ({ ...prevData, [name]: value }));
  }, []);

  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
        className="py-2 px-4 fs-5 rounded-pill"
      >
        Add New {pageTitle}
      </Button>

      <Modal show={show} onHide={handleClose} className="mt-5" size="md">
        <Modal.Header closeButton={!IsSaving}>
          <Modal.Title>Add {pageTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            className="px-3"
          >
            <Row xs={12} md={12} className="g-3">
              <FloatingLabel controlId="floatingInput" label="College Name">
                <Form.Control
                  type="text"
                  placeholder=" College Name"
                  name="collegeName"
                  required
                  value={bannerData.collegeName}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide Name
                </Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel controlId="floatingInput" label="Description">
                <Form.Control
                  as="textarea"
                  required
                  name="description"
                  value={bannerData.description}
                  style={{ height: "100px" }}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide description
                </Form.Control.Feedback>
              </FloatingLabel>

              <div className="d-flex flex-column gap-1 justify-content-center">
                <p className="text1-3 text-light-gray">Add Image</p>
                <AddImage
                  setBannerData={setBannerData}
                  bannerData={bannerData}
                  fileInputRef={fileInputRef}
                />
              </div>

              <Modal.Footer className="mt-4">
                <Button
                  variant="secondary"
                  onClick={handleClose}
                  disabled={IsSaving}
                >
                  Close
                </Button>
                <Button type="submit" variant="success" disabled={IsSaving}>
                  save
                </Button>
              </Modal.Footer>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
