const ApplicationStatusList = {
  Draft: "Draft",
  OfferLetterApplied: "Offer Letter Applied",
  UnderReview: "Under Review",
  RequireChanges: "Require Changes",
  OfferReceived: "Offer Received",
  OfferRefused: "Offer Refused",
  OfferExpired: "Offer Expired",
  DeferralUnderProcess: "Deferral Under Process",
  TuitionFeePaid: "Tuition Fee Paid",
  TuitionFeePending: "Tuition Fee Pending",
  VisaApproved: "Visa Approved",
  VisaRejected: "Visa Rejected",
  ApplicationFeeRefund: "Application Fee Refund",
  ApplicationClosed_StudentNotQualified:
    "Application closed - Student not qualified",
  ApplicationClosed_FeesNotPaid: "Application closed - Fees not paid",
  ApplicationClosed_ProgClosedAppInProcess:
    "Application closed - Program closed",
  StudentNotInterested: "Application closed - Student not interested",
};

export default ApplicationStatusList;
