import React from "react";
import CountUp from "../../components/CountUp";
import bg_gradient from "../../assets/landingPage/bg_gradient.svg";
import worldMap from "../../assets/landingPage/map.png";
import institutionsOrangeIcon from "../../assets/icons/institutions2.svg";
import studentsOrangeIcon from "../../assets/icons/students2.svg";
import agentsOrangeIcon from "../../assets/icons/agents2.svg";
import TokenService from "../../Services/TokenService";
export default function Section4() {
  const token = TokenService.getLocalAccessToken();
  const isAuth = Boolean(token);
  return (
    <>
      <div className="container ">
        <div className="row">
          <div className="col-12 text-center sections-title font-bold">
            <span className="text-dark0">
              Connecting {isAuth ? " agents" : "partners"} , institutions and
              students <br /> from{" "}
            </span>
            <span className="text-dark1">across the globe</span>
          </div>
          <div className="col-12 text-center">
            <img
              src={worldMap}
              alt="section4Img"
              className="img-fluid section3-globe"
            />
          </div>
        </div>
      </div>

      {/* <img
        src={bg_gradient}
        alt="leftBG"
        className="position-absolute img-fluid z-n1"
      /> */}

      <div className="">
        <div className="">
          <div className="d-flex justify-content-around">
            <div className="">
              <div className="d-flex flex-column flex-sm-row align-items-center">
                <img
                  src={agentsOrangeIcon}
                  alt="CountriesIcon"
                  className="img-fluid"
                  width={"72px"}
                />
                <div className="text-dark1 ms-3">
                  <div className="static-count-data">
                    <strong>
                      <CountUp
                        start={0}
                        end={200}
                        duration={2000}
                        suffix={"+"}
                      />
                    </strong>
                  </div>
                  <div className="static-count-title">
                    {isAuth ? "Agents" : "Partners"}
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="d-flex flex-column flex-sm-row align-items-center">
                <img
                  src={studentsOrangeIcon}
                  alt="CountriesIcon"
                  className="img-fluid"
                  width={"72px"}
                />
                <div className="text-dark1 ms-3">
                  <div className="static-count-data">
                    <strong>
                      <CountUp
                        start={0}
                        end={1000}
                        duration={2000}
                        suffix={"+"}
                      />
                    </strong>
                  </div>
                  <div className="static-count-title">Students</div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="d-flex flex-column flex-sm-row align-items-center">
                <img
                  src={institutionsOrangeIcon}
                  alt="CountriesIcon"
                  className="img-fluid"
                  width={"72px"}
                />
                <div className="text-dark1 ms-3">
                  <div className="static-count-data">
                    <strong>
                      <CountUp
                        start={0}
                        end={100}
                        duration={2000}
                        suffix={"+"}
                      />
                    </strong>
                  </div>
                  <div className="static-count-title">Instituition</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
