import http from "./api";

const get = (id) => {
  return http.get(`Blog?BlogId=${id}`);
};
const remove = (id) => {
  return http.delete(`Blog?id=${id}`);
};
const create = (data) => {
  return http.post("Blog", data);
};
const update = (data) => {
  return http.put(`Blog`, data);
};

const find = (data) => {
  return http.post("Blog/search?", data);
};
const getActiveBlogsList = () => {
  return http.get(`Blog/getActiveBlogsList`);
};

const BlogDataService = {
  get,
  remove,
  create,
  update,
  find,
  getActiveBlogsList,
};

export default BlogDataService;
