import http from "./api";

const getAllPasswords = (params) => {
    return http.get(`Auth/getAllUsers`, { params });
};

const resetPassword = (body) => {
    return http.post(`Auth/resetPasswordByAdmin`, body)
}

export const ResetPasswordService = {
    getAllPasswords,
    resetPassword
};
