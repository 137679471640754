import React from "react";
import logoLoader from '../../assets/epLoaderBlue.mp4';

export default function EpLoader() {
    return (
        <>
            <div className="d-flex flex-column justify-content-center align-items-center text-center mt-5 pt-5 text3 text-dark1"
                style={{ height: '75vh' }}>
                {/* Please wait a moment while we <br /> load the information... */}
                <video className="loader-video" autoPlay loop src={logoLoader} muted height='100px' />
            </div >
        </>
    );
}