import React, { useState, useEffect } from "react";
import UserPanel from "../UserPanel";
import Pagination from "../../components/UI/Pagination";
import SearchBar from "../../components/UI/SearchBar";
import SelectItemsPerPage from "../../components/UI/SelectItemsPerPage";
import LeadAdd from "./LeadAdd";
import LeadEdit from "./LeadEdit";
import LeadDataService from "../../Services/LeadService";
import PushLead from "./PushLead";
import { Modal, Table } from "react-bootstrap";
import SelectOption from "../../components/UI/SelectOption";
import Updating from "../../components/UI/Updating";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/UI/ConfirmationModal";
import PageTitle from "../../components/PageTitle";
import EpLoader from "../../components/UI/EpLoader";

export default function LeadList() {
  const pageTitle = "Leads";
  const [loading, setLoading] = useState(true);
  const [isUpdating, setUpdating] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);

  const [leadsList, setLeadsList] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [itemsLength, setItemsLength] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [servicesofInterest, setServicesofInterest] = useState("");

  const servicesofInterestOptions = [
    "IELTS",
    "PTE",
    "Study visa",
    "Spouse open work permit",
    "Visitor visa",
    "Sent to student list",
  ];

  const getRequestParams = (
    searchQuery,
    servicesofInterest,
    currentPage,
    itemsPerPage
  ) => {
    let params = {};
    if (servicesofInterest) {
      params["servicesofInterest"] = servicesofInterest;
    }
    if (searchQuery) {
      params["firstName"] = searchQuery;
    }
    if (currentPage) {
      params["currentPage"] = currentPage;
    }

    if (itemsPerPage) {
      params["pageSize"] = itemsPerPage;
    }

    return params;
  };

  const retrieveLeads = async () => {
    try {
      const params = getRequestParams(
        searchQuery,
        servicesofInterest,
        currentPage,
        itemsPerPage
      );
      const response = await LeadDataService.find(params);
      const Leads = response.data.response;
      setLoading(false);
      setLeadsList(Leads);
      setItemsLength(response.data.response[0]?.totalItems);
    } catch (error) {
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      );
    }
  };

  const handlePageSizeChange = (event) => {
    event.preventDefault();
    setItemsPerPage(parseInt(event.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleServicesofInterestChange = (event) => {
    setServicesofInterest(event.target.value);
    setCurrentPage(1);
  };

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [itemToConfirm, setItemToConfirm] = useState(null);

  const handleDeleteLead = async (e, data) => {
    e.preventDefault();
    setShowConfirmation(true);
    setItemToConfirm(data);
  };

  const handleConfirmation = async () => {
    setShowConfirmation(false);
    try {
      const response = await LeadDataService.remove(itemToConfirm.id);
      if (response.data === true) {
        toast.info("Delete Successfully");
        retrieveLeads();
        setUpdating(false);
      } else {
        setUpdating(false);
        toast.info("Not Deleted");
      }
    } catch (e) {
      setUpdating(false);
      toast.error(
        (e.response && e.response.data && e.response.data.message) ||
        e.message ||
        e.toString()
      );
    }
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleStatusChange = async (id, status) => {
    setUpdating(true);
    try {
      await LeadDataService.changeStatus(id, status);
      retrieveLeads();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setUpdating(false);
    }
  };

  const openModal = (lead) => {
    setSelectedLead(lead);
    setModalShow(true);
  };

  const closeModal = () => {
    setSelectedLead(null);
    setModalShow(false);
  };

  useEffect(() => {
    retrieveLeads();
  }, [searchQuery, servicesofInterest, currentPage, itemsPerPage]);

  if (loading) {
    return <EpLoader />;
  }

  return (
    <>
      <PageTitle title={pageTitle} />
      <div className="p-2 mt-2">
        <UserPanel title={"Lead"} />
        {isUpdating ? (
          <Updating />
        ) : (
          <div>
            <div className="">
              <div className="row align-items-baseline mt-3 mx-2 mb-1">
                <div className="col d-flex align-items-center column-gap-3">
                  <SearchBar
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    searchTitle={"Search Leads"}
                  />
                </div>
                <div className="col">
                  <div className="d-flex align-items-center justify-content-end text-dark1 column-gap-3">
                    <LeadAdd retrieveLeads={retrieveLeads} />
                    <SelectOption
                      handleOptionChange={handleServicesofInterestChange}
                      options={servicesofInterestOptions}
                    />
                    <SelectItemsPerPage
                      handlePageSizeChange={handlePageSizeChange}
                    />
                  </div>
                </div>
              </div>

              {itemsLength > 0 && (
                <Pagination
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                  dataLength={itemsLength}
                  itemsPerPage={itemsPerPage}
                />
              )}

              <div className="row">
                <div className="col-12">
                  <div className="table-responsive rounded-4 text-center">
                    <Table className="table custom-table">
                      <thead className="bg-dark1 text-white">
                        <tr className="py-3 text1-3">
                          <th scope="col text-danger">Name</th>
                          <th scope="col">Contact</th>
                          <th scope="col">Current Country</th>
                          <th scope="col">Country of Interest</th>
                          <th scope="col">Referral Source</th>
                          <th scope="col">Lead Status</th>
                          <th scope="col">Service of Interest</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody className="text1-4">
                        {leadsList.map((lead, index) => (
                          <tr className="py-3" key={index}>
                            <th>
                              {lead.statusId === 4 ? (
                                <div>
                                  {lead.firstName} {lead.lastName}
                                </div>
                              ) : (
                                <div
                                  className=""
                                  style={{ cursor: "pointer" }}
                                  onClick={() => openModal(lead)}
                                >
                                  {lead.firstName} {lead.lastName}
                                </div>
                              )}
                            </th>

                            <td>
                              <div>
                                <a
                                  href={`mailto:${lead.email}?subject=Education Planner&body=Hi,${lead.firstName}`}
                                  className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover text1-4"
                                  target="_blank"
                                >
                                  {lead.email}
                                </a>
                              </div>
                              <div>
                                <a
                                  href={`http://wa.me/${lead.contactNumber}`}
                                  className="link-success link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover text1-4"
                                  target="_blank"
                                >
                                  {lead.contactNumber}
                                </a>
                              </div>
                            </td>
                            <td>{lead.currentCountry}</td>
                            <td>{lead.countryofInterest}</td>
                            <td>{lead.referralSource}</td>
                            <td>
                              <div className="d-flex justify-content-center align-items-center">
                                {lead.statusId === 4 ? (
                                  "Applied"
                                ) : (
                                  <select
                                    className={`form-select py-2 rounded-pill text1-4 ps-4`}
                                    aria-label="Select service of Interest"
                                    style={{ width: "14rem" }}
                                    name="statusId"
                                    value={lead.statusId}
                                    onChange={(e) =>
                                      handleStatusChange(
                                        lead.id,
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option>Select option</option>
                                    <option value={1}>New Lead</option>
                                    <option value={2}>Follow Up</option>
                                    <option value={3}>Ready To Apply</option>
                                  </select>
                                )}
                              </div>
                            </td>
                            <td className="">
                              {lead.servicesofInterest ===
                                "Sent to student list" ? (
                                "Sent to student list"
                              ) : (
                                <div className="d-flex justify-content-center align-items-center">
                                  <PushLead
                                    lead={lead}
                                    index={index}
                                    retrieveLeads={retrieveLeads}
                                    setUpdating={setUpdating}
                                  />
                                </div>
                              )}
                            </td>
                            <td>
                              <div
                                onClick={(e) => {
                                  if (lead.statusId !== 4)
                                    handleDeleteLead(e, lead);
                                }}
                                value={lead.id}
                                style={
                                  lead.statusId === 4
                                    ? {}
                                    : { cursor: "pointer" }
                                }
                              >
                                <i
                                  className={`text2-2 h1 ${lead.statusId === 4
                                    ? "bi bi-file-lock text-light-gray"
                                    : "bi bi-trash3 text-normal-red"
                                    }`}
                                  style={{ minWidth: "24px", height: "24px" }}
                                ></i>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {itemsLength > 0 && (
                      <Pagination
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                        dataLength={itemsLength}
                        itemsPerPage={itemsPerPage}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            {selectedLead && (
              <Modal
                show={modalShow}
                onHide={closeModal}
                centered
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
              >
                <Modal.Header className="bg-dark1 text-white d-flex justify-content-between align-items-center py-2 bg-gradient">
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="d-flex align-items-center"
                  >
                    Update Lead of {selectedLead.firstName}{" "}
                    {selectedLead.lastName}
                  </Modal.Title>
                  <i
                    className="bi bi-x-circle text2 "
                    style={{ cursor: "pointer" }}
                    onClick={() => setModalShow(false)}
                  ></i>
                </Modal.Header>
                <Modal.Body>
                  <LeadEdit
                    lead={selectedLead}
                    retrieveLeads={retrieveLeads}
                    setModalShow={setModalShow}
                  />
                </Modal.Body>
              </Modal>
            )}
          </div>
        )}
      </div>
      <ConfirmationModal
        show={showConfirmation}
        onHide={handleCloseConfirmation}
        onConfirm={handleConfirmation}
        message={`Are you sure you want to delete Lead of ${itemToConfirm?.firstName} ${itemToConfirm?.lastName}?`}
        title="Confirm Deletion"
      />
    </>
  );
}
