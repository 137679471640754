import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import SideBarButton from "../components/SideBarButton";
import TokenService from "../Services/TokenService";
import RoleService from "../Services/RolesService";

export default function Sidemenu({ btnToggle, EnterCMSMode }) {
  const location = useLocation();
  const pathname = location.pathname;

  const handleSidebarToggle = btnToggle;
  const [menuItems, setMenuItems] = useState([]);
  const [activeItem, setActiveItem] = useState("");

  const getRole = TokenService.getUserRole();
  const {
    SuperAdmin,
    Reception,
    Student,
    Counsellor,
    AdmissionOfficer,
    TeamLeader,
    BranchHead,
    ProgramEditor,
    AgencyManager,
    Accounts,
  } = RoleService;

  // Retrieve the active item from localStorage on component mount
  useEffect(() => {
    const storedActiveItem = localStorage.getItem("activeItem");
    if (storedActiveItem) {
      setActiveItem(storedActiveItem);
    }
  }, []);

  // Update localStorage when activeItem changes
  useEffect(() => {
    localStorage.setItem("activeItem", activeItem);
  }, [activeItem]);

  useEffect(() => {
    // Set the active item based on the current pathname
    const matchingMenuItem = menuItems.find((item) => item.navTo === pathname);
    if (matchingMenuItem) {
      setActiveItem(matchingMenuItem.navTo);
    }
  }, [pathname, menuItems]);

  useEffect(() => {
    switch (getRole) {
      case SuperAdmin:
        setMenuItems([
          {
            text: "Dashboard",
            navTo: "/user/dashboard",
            icon: "bi bi-menu-button-wide",
          },
          { text: "Programs", navTo: "/user/program", icon: "bi bi-search" },
          {
            text: "Colleges",
            navTo: "/user/colleges",
            icon: "bi bi-buildings",
          },
          { text: "Students", navTo: "/user/students", icon: "bi bi-people" },
          {
            text: "Applications",
            navTo: "/user/applications",
            icon: "bi bi-window-stack",
          },
          { text: "Agents", navTo: "/user/agents", icon: "bi bi-person-vcard" },
          {
            text: "Agent Users",
            navTo: "/user/agent-users",
            icon: "bi bi-person-square",
          },
          { text: "Wallet", navTo: "/user/wallet", icon: "bi bi-piggy-bank" },
          {
            text: "Payments",
            navTo: "/user/payment",
            icon: "bi bi-list-check",
          },
          // { text: "Vouchers", navTo: "/user/vouchers", icon: "bi bi-award" },
          {
            text: "Users",
            navTo: "/user/users",
            icon: "bi bi-person-lines-fill",
          },
          { text: "Leads", navTo: "/user/lead", icon: "bi bi-layer-forward" },
          {
            text: "Passwords",
            navTo: "/user/reset-password",
            icon: "bi bi-gear",
          },
          {
            text: "Reports",
            navTo: "/user/reports",
            icon: "bi bi-bar-chart-fill",
          },
        ]);
        break;
      case Reception:
        setMenuItems([
          { text: "Leads", navTo: "/user/lead", icon: "bi bi-layer-forward" },
        ]);
        break;
      case Student:
        setMenuItems([
          { text: "Programs", navTo: "/user/program", icon: "bi bi-search" },
        ]);
        break;
      case Counsellor:
        setMenuItems([
          { text: "Leads", navTo: "/user/lead", icon: "bi bi-layer-forward" },
          { text: "Programs", navTo: "/user/program", icon: "bi bi-search" },
          { text: "Students", navTo: "/user/students", icon: "bi bi-people" },
          {
            text: "Applications",
            navTo: "/user/applications",
            icon: "bi bi-window-stack",
          },
        ]);
        break;
      case AdmissionOfficer:
        setMenuItems([
          {
            text: "Applications",
            navTo: "/user/applications",
            icon: "bi bi-window-stack",
          },
        ]);
        break;
      case TeamLeader:
        setMenuItems([
          { text: "Programs", navTo: "/user/program", icon: "bi bi-search" },
          { text: "Agents", navTo: "/user/agents", icon: "bi bi-person-vcard" },
          {
            text: "Applications",
            navTo: "/user/applications",
            icon: "bi bi-window-stack",
          },
        ]);
        break;
      case BranchHead:
        setMenuItems([
          {
            text: "Dashboard",
            navTo: "/user/dashboard",
            icon: "bi bi-menu-button-wide",
          },
          { text: "Programs", navTo: "/user/program", icon: "bi bi-search" },
          { text: "Students", navTo: "/user/students", icon: "bi bi-people" },
          {
            text: "Applications",
            navTo: "/user/applications",
            icon: "bi bi-window-stack",
          },
          {
            text: "Users",
            navTo: "/user/users",
            icon: "bi bi-person-lines-fill",
          },
          { text: "Wallet", navTo: "/user/wallet", icon: "bi bi-piggy-bank" },
          {
            text: "Payments",
            navTo: "/user/payment",
            icon: "bi bi-list-check",
          },
          { text: "Leads", navTo: "/user/lead", icon: "bi bi-layer-forward" },
          {
            text: "Marketplace",
            navTo: "/user/marketplace",
            icon: "bi bi-shop-window",
          },
        ]);
        break;
      case ProgramEditor:
        setMenuItems([
          { text: "Programs", navTo: "/user/program", icon: "bi bi-search" },
          {
            text: "Colleges",
            navTo: "/user/colleges",
            icon: "bi bi-buildings",
          },
        ]);
        break;
      case AgencyManager:
        setMenuItems([
          { text: "Agents", navTo: "/user/agents", icon: "bi bi-person-vcard" },
          {
            text: "Applications",
            navTo: "/user/applications",
            icon: "bi bi-window-stack",
          },
          {
            text: "Requests",
            navTo: "/user/requests",
            icon: "bi bi-card-checklist",
          },
        ]);
        break;
      case Accounts:
        setMenuItems([
          { text: "Wallet", navTo: "/user/wallet", icon: "bi bi-piggy-bank" },
          {
            text: "Payments",
            navTo: "/user/payment",
            icon: "bi bi-list-check",
          },
          { text: "Refunds", navTo: "/user/refunds", icon: "bi bi-cash-coin" },
          {
            text: "Requests",
            navTo: "/user/requests",
            icon: "bi bi-card-checklist",
          },
          { text: "Agents", navTo: "/user/agents", icon: "bi bi-person-vcard" },
          {
            text: "Marketplace",
            navTo: "/user/marketplace",
            icon: "bi bi-shop-window",
          },
        ]);
        break;
      default:
        // Handle the default case here if needed
        break;
    }

    const storedActiveItem = localStorage.getItem("activeItem");
    if (storedActiveItem) {
      setActiveItem(storedActiveItem);
    }
  }, [
    getRole,
    SuperAdmin,
    Reception,
    Student,
    Counsellor,
    AdmissionOfficer,
    TeamLeader,
    BranchHead,
    ProgramEditor,
    AgencyManager,
    Accounts,
  ]);

  return (
    <>
      {menuItems.map((item) => (
        <SideBarButton
          key={item.navTo}
          handleSidebarToggle={handleSidebarToggle}
          text={item.text}
          navTo={item.navTo}
          icon={item.icon}
          linkClass={`${handleSidebarToggle ? "justify-content-center" : "text1-6"
            } ${activeItem === item.navTo
              ? "bg-white text-dark1 font-bold"
              : "bg-dark1 text-white"
            } `}
          onClick={() => setActiveItem(item.navTo)}
        />
      ))}
      {getRole === SuperAdmin || getRole === ProgramEditor ? (
        <li
          className={`border-top ${handleSidebarToggle ? "mb-1" : "mb-2"}`}
          style={{ cursor: "pointer" }}
        >
          <NavLink
            to={"/cms"}
            className={`mt-2 py-3 d-flex align-items-center text-decoration-none ${activeItem === "CMS"
              ? "bg-white text-dark1 font-bold"
              : "bg-dark1 text-white"
              } ${handleSidebarToggle
                ? "rounded-start-pill justify-content-center"
                : "rounded-start-pill text1-6"
              }`}
            onClick={EnterCMSMode}
          >
            <div>
              <i
                className={`mx-3 text1-8 d-flex justify-content-center align-items-center text-orange bi bi-gear-wide-connected`}
              ></i>
              {handleSidebarToggle ? <span className="px-3">CMS</span> : ""}
            </div>

            {handleSidebarToggle ? "" : <span>CMS</span>}
          </NavLink>
        </li>
      ) : null}
    </>
  );
}
