import React from "react";
import sortIcon from "../../assets/icons/sort.svg";
export default function SelectItemsPerPage({ handlePageSizeChange, itemsPerPage }) {
  return (
    <>
      <div className="text1-4 text-dark1">
        <img src={sortIcon} alt="sortIcon" className="img-fluid" />
      </div>
      <div className="text1-4 text-dark1">
        <select
          className="form-select rounded-4 text1-3 py-2"
          aria-label="Items Per Page"
          defaultValue={10}
          value={itemsPerPage}
          onChange={handlePageSizeChange}
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
    </>
  );
}
