import React, { useCallback, useState } from 'react'
import { Modal } from 'react-bootstrap'
import platformLogo from "../../assets/logo_dark.png";
import { toast } from 'react-toastify';
import { ResetPasswordService } from '../../Services/ResetPasswordService';


const ResetModal = ({ showModal, setShowModal, userInfo }) => {
    const [step, setStep] = useState(1)
    const [password, setPassword] = useState({
        newPassword: '',
        confirmPassword: ''
    })
    const [viewPassword, setViewPassword] = useState({
        newPassword: false,
        confirmPassword: false
    })

    const [passwordError, setPasswordError] = useState('');

    const handleCloseModal = () => {
        setPassword({
            newPassword: '',
            confirmPassword: ''
        })
        setPasswordError('');
        setStep(1)
        setShowModal(false)
    }

    const handleSaveClick = useCallback(async (event) => {
        try {
            event.preventDefault()
            if (password.newPassword !== password.confirmPassword) {
                setPasswordError('Passwords do not match');
                event.stopPropagation()
                return;
            }

            // Password validation regex
            const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
            if (!passwordRegex.test(password.newPassword)) {
                setPasswordError('Password must contain at least one lowercase letter, one uppercase letter, one digit, one special character, and be at least 8 characters long');
                event.stopPropagation()
                return;
            }

            const body = {
                emailAddress: userInfo?.email,
                password: password.newPassword,
                confirmPassword: password.confirmPassword

            }
            const response = await ResetPasswordService.resetPassword(body);
            if (response.data.success === true) {
                toast.success(response.data.message);
                setStep(2)
            } else if (response.data.validationErrors.length > 0) {
                response.data.validationErrors.map((verr) => toast.error(verr));
            } else {
                toast.error("Something went wrong");
            }
        } catch (error) {
            toast.error(
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            );
        }
    }, [password.confirmPassword, password.newPassword, userInfo?.email])

    return (
        <Modal
            show={showModal}
            onHide={handleCloseModal}
            centered
            aria-labelledby="contained-modal-title-vcenter"
            size="md"
            className="custom-modal">
            <Modal.Body>
                <i className="bi bi-x-circle-fill text2 p-3 text-danger position-absolute end-0 top-0" style={{ cursor: "pointer" }}
                    onClick={handleCloseModal}></i>
                <div className='d-flex flex-column align-items-center pt-5 gap-5'>

                    <img src={platformLogo} alt='platformLogo' height='80px' />
                    <p className='text2 text-dark1 fw-bold'>{step === 1 ? 'Reset Password' : 'Reset Password Successful'} </p>
                </div>
                {step === 1 &&
                    <form onSubmit={handleSaveClick} className='d-flex flex-column p-5 mx-5 gap-5 justify-content-center align-items-center'>
                        <div className='w-100 d-flex flex-column justify-content-center'>
                            <div>
                                <p className='text-dark1 text1-4'>New Password</p>
                            </div>
                            <div className="col-12 form-floating">
                                <input
                                    type={viewPassword.newPassword ? "text" : "password"}
                                    className="form-control rounded-pill pt-3 border ps-4 pb-1"
                                    placeholder="Enter your new Password*"
                                    required
                                    value={password.newPassword}
                                    onChange={(e) => setPassword((prev) => ({
                                        ...prev,
                                        newPassword: e?.target?.value
                                    }))}
                                />
                                <div
                                    className="position-absolute top-50 end-0 translate-middle-y me-4 pe-2"
                                    onClick={() => setViewPassword((prev) => ({
                                        ...prev,
                                        newPassword: !viewPassword.newPassword
                                    }))}
                                >
                                    <i
                                        className={`bi ${viewPassword.newPassword ? "bi-eye" : "bi-eye-slash"
                                            } d-flex align-items-center text-dark1 text2`}
                                    ></i>
                                </div>
                                <label htmlFor="floatingInput" className="ms-4 pt-2">
                                    Enter your new Password<span className="text-danger">*</span>
                                </label>
                            </div>
                        </div>

                        <div className='w-100 d-flex flex-column justify-content-center'>
                            <div>
                                <p className='text-dark1 text1-4'>Confirm New Password</p>
                            </div>
                            <div className="col-12 form-floating">
                                <input
                                    type={viewPassword.confirmPassword ? "text" : "password"}
                                    className="form-control rounded-pill pt-3 border ps-4 pb-1"
                                    placeholder="Enter your new Password*"
                                    required
                                    value={password.confirmPassword}
                                    onChange={(e) => setPassword((prev) => ({
                                        ...prev,
                                        confirmPassword: e?.target?.value
                                    }))}
                                />
                                <div
                                    className="position-absolute top-50 end-0 translate-middle-y me-4 pe-2"
                                    onClick={() => setViewPassword((prev) => ({
                                        ...prev,
                                        confirmPassword: !viewPassword.confirmPassword
                                    }))}
                                >
                                    <i
                                        className={`bi ${viewPassword.confirmPassword ? "bi-eye" : "bi-eye-slash"
                                            } d-flex align-items-center text-dark1 text2`}
                                    ></i>
                                </div>
                                <label htmlFor="floatingInput" className="ms-4 pt-2">
                                    Enter your new Password<span className="text-danger">*</span>
                                </label>

                            </div>
                            {passwordError && <p className='text-danger mt-3'>{passwordError}</p>}
                        </div>

                        <div className=' d-flex gap-5'>
                            <button className='rounded-pill text1-4  px-5 pt-2 border-dark1'
                                onClick={handleCloseModal}>Cancel</button>
                            <button type='submit'
                                className='rounded-pill text1-4 px-5 pt-2 gradiantButton'
                            >
                                Save
                            </button>
                        </div>
                    </form>}
                {step === 2 &&
                    <p className='text-center text1-9 m-5' style={{ color: '#6C757D' }}>{userInfo?.firstName}'s password has been changed <br /> successfully.</p>
                }
            </Modal.Body>

        </Modal >
    )
}

export default ResetModal
