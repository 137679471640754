import React, { useState } from "react";
import { Col, ListGroup, Row } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import { useSearchParams } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import { ComponentLoader } from "../../components/UI/Loader";
import UserPanel from "../UserPanel";
import "./index.css";
import { useReports } from "./useReports";
import { Autocomplete } from "./Autocomplete";

export function Reports() {
  const {
    graphData,
    graphConfigOptions,
    dropdownData,
    loading,
    inputData,
    setInputData,
    handleChange,
    openDropdown,
    setOpenDropdown,
  } = useReports();
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className="reports">
      <PageTitle title={"Reports"} />
      <UserPanel title={"Reports"} />
      <Row style={{ padding: "20px" }}>
        <Col className="d-flex flex-column">
          <>
            <div className="form-floating align-self-end text-white position-relative">
              <Autocomplete
                value={inputData.colleges}
                onChange={(e) => handleChange(e.target.value, "colleges")}
                list={dropdownData.colleges}
                handleItemClick={(e, item) => {
                  setInputData((p) => ({
                    ...p,
                    colleges: item.name,
                  }));
                  searchParams.set("selectedCollege", item.id);
                  setSearchParams(searchParams);
                }}
              />
              <label className="text-white text1-2" htmlFor="floatingInput">
                College
              </label>
            </div>
            {loading.applicationsInCollege ? (
              <div
                className="d-flex justify-content-center"
                style={{
                  minHeight: "33vh",
                }}
              >
                <ComponentLoader />
              </div>
            ) : (
              graphData.applicationsInCollege?.datasets?.length && (
                <Bar
                  options={graphConfigOptions}
                  data={graphData.applicationsInCollege}
                />
              )
            )}
          </>
        </Col>
        <Col className="d-flex flex-column">
          {loading.applicationsInCountry ? (
            <div
              className="d-flex justify-content-center"
              style={{
                minHeight: "33vh",
              }}
            >
              <ComponentLoader />
            </div>
          ) : (
            <>
              <div className="form-floating align-self-end text-white">
                <select
                  aria-label="Default select example"
                  className="align-self-end select-container text1-4 bg-dark1 text-white my-3 "
                  value={searchParams.get("selectedCountry") ?? ""}
                  onChange={(e) => {
                    searchParams.set("selectedCountry", e.target.value);
                    setSearchParams(searchParams);
                  }}
                >
                  <option disabled>Select Country</option>
                  {dropdownData.countries?.map((country) => (
                    <option key={country} value={country.country_name}>
                      {country.country_name}
                    </option>
                  ))}
                </select>
                <label className="text-white text1-2" htmlFor="floatingInput">
                  Country
                </label>
              </div>
              {graphData.applicationsInCountry?.datasets?.length && (
                <Bar
                  options={graphConfigOptions}
                  data={graphData.applicationsInCountry}
                />
              )}
            </>
          )}
        </Col>
      </Row>
      <Row style={{ padding: "20px" }}>
        <Col className="d-flex flex-column justify-content-center">
          <>
            <div className="form-floating align-self-end text-white position-relative">
              <Autocomplete
                value={inputData.programs}
                onChange={(e) => handleChange(e.target.value, "programs")}
                list={dropdownData.programs}
                handleItemClick={(e, item) => {
                  setInputData((p) => ({
                    ...p,
                    programs: item.name,
                  }));
                  searchParams.set("selectedProgram", item.id);
                  setSearchParams(searchParams);
                  setOpenDropdown((p) => ({
                    ...p,
                    programs: false,
                  }));
                }}
              />
              <label className="text-white text1-2" htmlFor="floatingInput">
                Program
              </label>
            </div>

            {loading.applicationsInProgram ? (
              <div
                className="d-flex justify-content-center"
                style={{
                  minHeight: "33vh",
                }}
              >
                <ComponentLoader />
              </div>
            ) : (
              graphData.applicationsInProgram?.datasets?.length && (
                <Bar
                  options={graphConfigOptions}
                  data={graphData.applicationsInProgram}
                />
              )
            )}
          </>
        </Col>
        <Col className="d-flex flex-column">
          <>
            <div className="form-floating align-self-end text-white position-relative">
              <Autocomplete
                value={inputData.agents}
                onChange={(e) => handleChange(e.target.value, "agents")}
                list={dropdownData.agents}
                handleItemClick={(e, item) => {
                  setInputData((p) => ({
                    ...p,
                    agents: item.name,
                  }));
                  searchParams.set("selectedAgent", item.id);
                  setSearchParams(searchParams);
                  setOpenDropdown((p) => ({
                    ...p,
                    agents: false,
                  }));
                }}
              />
              <label className="text-white text1-2" htmlFor="floatingInput">
                Agent
              </label>
            </div>
            {loading.applicationsToAgents ? (
              <div
                className="d-flex justify-content-center"
                style={{
                  minHeight: "33vh",
                }}
              >
                <ComponentLoader />
              </div>
            ) : (
              graphData.applicationsToAgents?.datasets?.length && (
                <Bar
                  options={graphConfigOptions}
                  data={graphData.applicationsToAgents}
                />
              )
            )}
          </>
        </Col>
      </Row>
      <Row style={{ padding: "20px" }}>
        <Col xs={6} className="d-flex flex-column">
          {loading.applicationsToTeamLeaders ? (
            <div
              className="d-flex justify-content-center"
              style={{
                minHeight: "33vh",
              }}
            >
              <ComponentLoader />
            </div>
          ) : (
            <>
              {" "}
              <div className="form-floating align-self-end text-white">
                <select
                  aria-label="Default select example"
                  className="align-self-end select-container text1-4 bg-dark1 text-white my-3"
                  value={searchParams.get("selectedTeamLeader") ?? ""}
                  onChange={(e) => {
                    searchParams.set("selectedTeamLeader", e.target.value);
                    setSearchParams(searchParams);
                  }}
                >
                  <option disabled>Select Team Leader</option>
                  {dropdownData.teamLeaders.map((leader) => (
                    <option value={leader.id}>
                      {leader.firstName} {leader.lastName}
                    </option>
                  ))}
                </select>
                <label className="text-white text1-2" htmlFor="floatingInput">
                  Team Leader
                </label>
              </div>
              {graphData.applicationsToTeamLeaders?.datasets?.length && (
                <Bar
                  options={graphConfigOptions}
                  data={graphData.applicationsToTeamLeaders}
                />
              )}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
}
