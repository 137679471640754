import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import StudentDocumentService from "../../Services/StudentDocumentService";
import Tabs from "../../components/UI/Tabs";
import DocumentsEPAssignee from "./DocumentsEPAssignee";
import DocumentsAgent from "./DocumentsAgent";
import WelcomeBanner from "./WelcomeBanner";
import TokenService from "../../Services/TokenService";
import RoleService from "../../Services/RolesService";
import { toast } from "react-toastify";
import FileSizeService from "../../Services/FileSizeService";
import ConfirmationModal from "../../components/UI/ConfirmationModal";

export default function AddDocuments({
  studentData,
  currentEducation,
  setActiveTabNext,
  allDraftApplication,
  documentList,
  setDocumentList,
  isNextDisabled,
}) {
  const getRole = TokenService.getUserRole();
  const { Counsellor, AdmissionOfficer, BranchHead } = RoleService;
  const studentId = studentData.id;
  const fileInputRef = useRef(null);
  const { MinSize, MaxSize, MinSizeName, MaxSizeName } = FileSizeService;

  const handleTabChange = (e) => {
    e.preventDefault();
    if (isNextDisabled) {
      toast.error("Please upload the mandatory documents");
    } else {
      setActiveTabNext("application");
    }
  };

  const disabled = useMemo(() => {
    if (allDraftApplication || allDraftApplication === undefined) {
      return false;
    } else {
      if (!studentData.isEditable) {
        return true;
      }
      return false;
    }
  }, [allDraftApplication, studentData.isEditable]);

  const initializeState = [
    {
      studentId: null,
      documentTypeId: "",
      title: "",
      upload: "",
      fileName: "",
      companyId: "",
      statusId: 1,
    },
  ];

  const [isSaving, setIsSaving] = useState(false);

  const [documentData, setDocumentData] = useState(initializeState);
  const [documentFile, setDocumentFile] = useState(undefined);

  const getDocument = () => {
    StudentDocumentService.findAndGetByStudentID(studentId)
      .then((response) => {
        setDocumentList(response.data.response);
        //setIsLoaded(true);
      })
      .catch((e) => {
        toast.error(
          (e.response && e.response.data && e.response.data.message) ||
            e.message ||
            e.toString()
        );
      });
  };

  useEffect(() => {
    if (studentId) getDocument(studentId);
  }, [studentId]);

  const [viewing, setViewing] = useState(false);
  const handleOpenInNewTab = async (e, dl) => {
    e.preventDefault();
    setViewing(true);
    let id = dl.id;
    try {
      StudentDocumentService.download(id).then((e) => {
        const url = e.data?.Url;
        if (url) {
          const newTab = window.open(url, "_blank");
          setViewing(false);
          if (!newTab) {
            toast.error("Failed to open the file in a new tab.");
          }
        } else {
          toast.error("Document not found!");
        }
      });
    } catch (error) {
      //toast.error("Error opening PDF in a new tab:", error);
    } finally {
      setViewing(false);
    }
  };

  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const handleStudentDocumentsInputChange = useCallback(
    (event) => {
      const { name, value } = event.target;

      if (event.target.type === "text") {
        setDocumentData({
          ...documentData,
          [name]: value,
          fileName: `${value}.pdf`,
        });
        setSelectedTitle(value);
      } else if (event.target.type === "file") {
        const file = event.target.files && event.target.files[0];
        if (file && file.size >= MinSize && file.size <= MaxSize) {
          setSelectedFile(file);
          const reader = new FileReader();
          reader.onload = function (e) {
            const fileContent = e.target.result;
            const base64String = btoa(fileContent);
            setDocumentFile(base64String);
          };
          reader.readAsBinaryString(file);
        } else if (file && file.size < MinSize) {
          setSelectedTitle(null);
          setSelectedFile(null);
          setDocumentFile(undefined);
          toast.error(`File size should be greater than ${MinSizeName}`);
          return;
        } else if (file && file.size > MaxSize) {
          setSelectedTitle(null);
          setSelectedFile(null);
          setDocumentFile(undefined);
          toast.error(`File size should be less than ${MaxSizeName}`);
          return;
        }
      }
    },
    [MaxSize, MaxSizeName, MinSize, MinSizeName, documentData]
  );

  const saveStudentDocumentsData = async (e, ed) => {
    e.preventDefault();
    toast.dismiss();
    let getTitle = "";
    let getFileName = "";

    if (ed) {
      getTitle = ed;
      getFileName = getTitle.trim().replace(/\s/g, "") + ".pdf";
    } else {
      getTitle = documentData.title;
      getFileName = documentData.fileName;
    }

    var data = {
      studentId: studentData.id,
      title: getTitle,
      fileName: getFileName,
      upload: documentFile,
      companyId: studentData.companyId,
      statusId: 1,
    };

    if (getTitle === "" || getTitle === undefined || getTitle === null) {
      toast.error("Title is empty");
    } else if (
      documentFile === "" ||
      documentFile === null ||
      documentFile === undefined ||
      selectedFile === "" ||
      selectedFile === undefined ||
      selectedFile === null
    ) {
      toast.error("Choose a valid pdf file");
    } else {
      setIsSaving(true);
      try {
        const response = await StudentDocumentService.create(data);
        if (response?.data?.success) {
          // Reset the form state
          document.getElementById("upload-docs-form")?.reset();
          setDocumentData((prevData) => ({
            ...prevData,
            title: "",
            upload: "",
            fileName: "",
          }));
          setSelectedTitle("");
          setSelectedFile(null);
          // Clear the input fields
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
          toast.success("Uploaded successfully");
          getDocument(); // Refresh the document list
          setIsSaving(false);
        } else if (response?.data?.validationErrors) {
          response.data.validationErrors.map((verr) => toast.error(verr));
          setIsSaving(false);
        } else {
          setIsSaving(false);
          toast.error("Something Went Wrong");
        }
      } catch (error) {
        toast.error(error.response.data);
        setIsSaving(false);
      }
    }
  };
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [itemToConfirm, setItemToConfirm] = useState(null);

  const handleDeleteDocument = async (e) => {
    e.preventDefault();
    const id = e.currentTarget.value;
    setShowConfirmation(true);
    setItemToConfirm(id);
  };

  const handleConfirmation = async () => {
    setShowConfirmation(false);
    try {
      const response = await StudentDocumentService.remove(itemToConfirm);
      if (response.data === true) {
        toast.info("Delete Successfully");
        // Clear the input fields
        document.getElementById("upload-docs-form")?.reset();
        setDocumentData(initializeState);
        setSelectedTitle("");
        setSelectedFile(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        getDocument();
      } else {
        toast.info("Not Deleted");
      }
    } catch (error) {
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString()
      );
    }
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  // const tabs = [
  //   {
  //     id: "DocumentsAgent",
  //     label: "Agent Documents",
  //     content: (
  //       <DocumentsAgent
  //         documentData={documentData}
  //         handleStudentDocumentsInputChange={handleStudentDocumentsInputChange}
  //         saveStudentDocumentsData={saveStudentDocumentsData}
  //         currentEducation={currentEducation}
  //         documentList={documentList}
  //         handleDeleteDocument={handleDeleteDocument}
  //         fileInputRef={fileInputRef}
  //         handleDownload={handleOpenInNewTab}
  //         getRole={getRole}
  //         RoleService={RoleService}
  //         selectedFile={selectedFile}
  //         selectedTitle={selectedTitle}
  //         isSaving={isSaving}
  //         viewing={viewing}
  //         allDraftApplication={allDraftApplication}
  //       />
  //     ),
  //   },
  //   {
  //     id: "DocumentsAdmissionOfficer",
  //     label: "Admission Officer Documents",
  //     content: (
  //       <DocumentsAdmissionOfficer
  //         documentData={documentData}
  //         handleStudentDocumentsInputChange={handleStudentDocumentsInputChange}
  //         saveStudentDocumentsData={saveStudentDocumentsData}
  //         documentList={documentList}
  //         handleDeleteDocument={handleDeleteDocument}
  //         fileInputRef={fileInputRef}
  //         handleDownload={handleOpenInNewTab}
  //         getRole={getRole}
  //         RoleService={RoleService}
  //         isSaving={isSaving}
  //         viewing={viewing}
  //         allDraftApplication={allDraftApplication}
  //       />
  //     ),
  //   },
  // ];

  return (
    <>
      <WelcomeBanner data={studentData} isData={true} show={disabled} />
      <div className="row justify-content-between">
        <div className="col-6">
          <div
            className="border-end rounded-2 p-2"
            style={{ minHeight: "400px" }}
          >
            <div className="text-center p-4 text2 bg-dark1 rounded-pill py-2 mx-5 text-white mb-1 bg-gradient">
              Agent Documents
            </div>
            <DocumentsAgent
              documentData={documentData}
              handleStudentDocumentsInputChange={
                handleStudentDocumentsInputChange
              }
              saveStudentDocumentsData={saveStudentDocumentsData}
              currentEducation={currentEducation}
              documentList={documentList}
              handleDeleteDocument={handleDeleteDocument}
              fileInputRef={fileInputRef}
              handleDownload={handleOpenInNewTab}
              getRole={getRole}
              RoleService={RoleService}
              selectedFile={selectedFile}
              selectedTitle={selectedTitle}
              isSaving={isSaving}
              viewing={viewing}
              disabled={disabled}
              allDraftApplication={allDraftApplication}
            />
          </div>
        </div>
        <div className="col-6">
          <div
            className="border-start rounded-2 p-2"
            style={{ minHeight: "400px" }}
          >
            <div className="text-center p-4 text2 bg-dark1 rounded-pill py-2 mx-5 text-white mb-1 bg-gradient">
              Admission Officer Documents
            </div>
            <DocumentsEPAssignee
              documentData={documentData}
              handleStudentDocumentsInputChange={
                handleStudentDocumentsInputChange
              }
              saveStudentDocumentsData={saveStudentDocumentsData}
              documentList={documentList}
              handleDeleteDocument={handleDeleteDocument}
              fileInputRef={fileInputRef}
              handleDownload={handleOpenInNewTab}
              getRole={getRole}
              RoleService={RoleService}
              isSaving={isSaving}
              viewing={viewing}
              disabled={disabled}
              allDraftApplication={allDraftApplication}
            />
          </div>
        </div>
        {/* <Tabs tabs={tabs} customCss={true} activeTabNext={activeTabNext} setActiveTabNext={setActiveTabNext} /> */}
      </div>
      {getRole === BranchHead || getRole === Counsellor ? (
        <div className="d-flex justify-content-end align-items-center my-2 mt-5">
          <button
            type="button"
            className="btn btn-light-blue-outline rounded-pill py-3 me-2 col-2 text1-4"
            onClick={handleTabChange}
            // disabled={isNextDisabled}
          >
            Next
          </button>
        </div>
      ) : (
        ""
      )}
      <ConfirmationModal
        show={showConfirmation}
        onHide={handleCloseConfirmation}
        onConfirm={handleConfirmation}
        message="Are you sure you want to delete document?"
        title="Confirm Deletion"
      />
    </>
  );
}
