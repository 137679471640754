import React from "react";
import { Modal } from "react-bootstrap";
import platformLogo from "../../assets/logo_dark.png";

const ThankyouModal = ({ showModal, handleCloseModal }) => {
  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      centered
      aria-labelledby="contained-modal-title-vcenter"
      maxWidth="md"
      className="custom-modal"
    >
      <Modal.Body>
        <i
          className="bi bi-x-circle-fill text2 p-3 text-danger position-absolute end-0 top-0"
          style={{ cursor: "pointer" }}
          onClick={handleCloseModal}
        ></i>
        <div className="d-flex flex-column align-items-center pt-5 gap-5">
          <img src={platformLogo} alt="platformLogo" height="80px" />
          <p className="text2 text-dark1 fw-bold">Thanks for Applying</p>
        </div>
        <p className="text-center text1-5 m-5 mt-2 mt-sm-5">
          Your information has been submitted. A counselor will contact you
          shortly to schedule your free session. We look forward to speaking
          with you soon.
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default ThankyouModal;
