import React, { useState, useEffect } from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import DarkTextLogo from "../../assets/logo_text_dark.png";
import Header from "../../layout/Header";
import AppFooter from "../Main/Section8";

import CollegeDataService from "../../Services/CollegeService";
import ProgramSearchService from "../../Services/ProgramSearchService";
import IntakeService from "../../Services/IntakeService";
import { useLocation } from "react-router-dom";
import CopyToClipboardButton from "../../components/CopyToClipboardButton";
import FormatIntkeDate from "../../components/UI/FormatIntkeDate";

export default function SharedLinkCompareView() {
  const [selectedProgramsData, setSelectedProgramsData] = useState([]);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const id1 = queryParams.get("id1");
  const id2 = queryParams.get("id2");
  const id3 = queryParams.get("id3");

  const currentUrl = window.location.href;

  const handlePrint = () => {
    window.print();
  };
  const [loading, setLoading] = useState(true);
  const [getDataFailed, setGetDataFailed] = useState(false);

  const handleCollegeAndProgram = async (programId) => {
    try {
      const programResponse = await ProgramSearchService.get(programId);
      const collegeResponse = await CollegeDataService.get(
        programResponse.data.collegeId
      );
      const intakeResponse =
        await IntakeService.getListByCollegeIdAndCollegeProgramId({
          collegeId: programResponse.data.collegeId,
          collegeProgramId: programId,
        });

      const newData = {
        programData: programResponse.data,
        collegeData: collegeResponse.data,
        intakeData: intakeResponse.data,
      };

      setSelectedProgramsData((prevData) => {
        // Check if the data is already present to avoid duplication
        const isDataPresent = prevData.some(
          (data) => data.programData.id === newData.programData.id
        );
        return isDataPresent ? prevData : [...prevData, newData];
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setGetDataFailed(true);
      // Display an error message to the user
    }
  };

  useEffect(() => {
    if (id1) handleCollegeAndProgram(id1);
    if (id2) handleCollegeAndProgram(id2);
    if (id3) handleCollegeAndProgram(id3);
  }, [id1, id2, id3]);
  useEffect(() => {
    if (!id1 && !id2 && !id3) {
      setGetDataFailed(true);
      setLoading(false);
    }
  }, [id1, id2, id3]);
  if (loading) {
    return (
      <div className="fullscreen-backdrop">
        <div className="fullscreen-content">
          <div>
            <button
              disabled
              className="btn btn-dark-blue rounded-pill px-4 py-3"
            >
              <div className="d-flex align-items-center fs-2">
                <div
                  className="spinner-grow"
                  style={{ width: "40px", height: "40px" }}
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
                <div className="ms-3">Please Wait...</div>
              </div>
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (getDataFailed) {
    return (
      <>
        <Header />
        <div
          className="d-flex justify-content-center align-items-center d-print-none fs-1"
          style={{ paddingTop: "200px" }}
        >
          Invalid or Link expired
        </div>
      </>
    );
  }

  return (
    <>
      <Header />
      <div className="px-5" style={{ paddingTop: "10rem" }}>
        <div className="d-flex justify-content-between align-items-center d-print-none">
          <div className="d-flex justify-content-center align-items-center mx-3">
            <div className="btn-group dropstart">
              <button
                type="button"
                className="btn text-dark1 text1-4 ms-4 btn-dark-blue py-2 px-4 mb-2 rounded-pill d-flex align-items-center"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="bi bi-share-fill text-orange me-2 text1-6"></i>
                Share
              </button>
              <ul className="dropdown-menu border-0 p-2 rounded-pill">
                <div className="d-flex">
                  <CopyToClipboardButton textToCopy={currentUrl} />
                  <EmailShareButton
                    url={currentUrl}
                    subject={"Share"}
                    body="Education Planner"
                    className="me-1"
                  >
                    <EmailIcon size={36} round={true} />
                  </EmailShareButton>
                  <FacebookShareButton
                    url={currentUrl}
                    subject={"Share"}
                    body="Education Planner"
                    className="me-1"
                  >
                    <FacebookIcon size={36} round={true} />
                  </FacebookShareButton>
                  <LinkedinShareButton
                    url={currentUrl}
                    subject={"Share"}
                    body="Education Planner"
                    className="me-1"
                  >
                    <LinkedinIcon size={36} round={true} />
                  </LinkedinShareButton>
                  <TwitterShareButton
                    url={currentUrl}
                    subject={"Share"}
                    body="Education Planner"
                    className="me-1"
                  >
                    <TwitterIcon size={36} round={true} />
                  </TwitterShareButton>
                  <WhatsappShareButton
                    url={currentUrl}
                    subject={"Share"}
                    body="Education Planner"
                    className="me-1"
                  >
                    <WhatsappIcon size={36} round={true} />
                  </WhatsappShareButton>
                </div>
              </ul>
            </div>

            <div
              className="text-dark1 text1-4 ms-4 btn-dark-blue py-2 px-4 mb-2 rounded-pill"
              style={{ cursor: "pointer" }}
              onClick={handlePrint}
            >
              <i className="bi bi-printer-fill text-orange me-2 text1-6"></i>
              Print
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="table-responsive rounded-4">
              <table className="table custom-table position-relative">
                <img
                  src={DarkTextLogo}
                  alt="DarkTextLogo"
                  className="img-fluid position-absolute top-50 start-50 translate-middle"
                  style={{ opacity: "0.1" }}
                />
                <thead className="bg-dark1 text-white">
                  <tr className="py-1 text1-3">
                    <th scope="col" className="ps-4">
                      Criteria
                    </th>
                    {selectedProgramsData.map((data, index) => (
                      <th key={index} scope="col" className="text1-5">
                        {data.collegeData.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="text1-4">
                  <tr>
                    <td className="ps-4">Program</td>
                    {selectedProgramsData.map((data, index) => (
                      <td key={index}>{data.programData.name}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="ps-4">Location</td>
                    {selectedProgramsData.map((data, index) => (
                      <td key={index}>
                        {data.programData.campus
                          ?.map((camp) => camp.name)
                          ?.join(", ")}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="ps-4">Credential</td>
                    {selectedProgramsData.map((data, index) => (
                      <td key={index}>{data.programData.credential}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="ps-4">PG Work Permit</td>
                    {selectedProgramsData.map((data, index) => (
                      <td key={index}>
                        {data.programData.pgWorkPermit === "Yes" ? (
                          <i className="bi bi-check-circle-fill text1-6 text-normal-green"></i>
                        ) : (
                          <i className="bi bi-x-circle-fill text1-6 text-normal-red"></i>
                        )}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="ps-4">Application Fee</td>
                    {selectedProgramsData.map((data, index) => (
                      <td key={index}>${data.collegeData.applicationFee}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="ps-4">Tuition Fee</td>
                    {selectedProgramsData.map((data, index) => (
                      <td key={index}>${data.programData.tuitionFee}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="ps-4">IELTS</td>
                    {selectedProgramsData.map((data, index) => (
                      <td key={index}>{data.programData.ieltsRequirement}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="ps-4">PTE</td>
                    {selectedProgramsData.map((data, index) => (
                      <td key={index}>{data.programData.pteRequirement}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="ps-4">Duolingo</td>
                    {selectedProgramsData.map((data, index) => (
                      <td key={index}>
                        {data.programData.duolingoRequirement}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="ps-4">TOEFL</td>
                    {selectedProgramsData.map((data, index) => (
                      <td key={index}>{data.programData.toeflRequirement}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="ps-4">GMAT</td>
                    {selectedProgramsData.map((data, index) => (
                      <td key={index}>{data.programData.gmat}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="ps-4">SAT</td>
                    {selectedProgramsData.map((data, index) => (
                      <td key={index}>{data.programData.sat}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="ps-4">Intake</td>

                    {selectedProgramsData.map((programData, index) => (
                      <td key={index}>
                        {programData.intakeData.length > 0 &&
                          programData.intakeData.some(
                            (intake) => intake.statusId === 3
                          ) && (
                            <div className="d-flex justify-content-start align-items-center">
                              <i className="bi bi-circle-fill text-normal-green me-1"></i>
                              {programData.intakeData
                                .filter((intake) => intake.statusId === 3)
                                .sort(
                                  (a, b) => new Date(a.date) - new Date(b.date)
                                ) // Sort by date
                                .map((intake, index, array) => (
                                  <div key={intake.date}>
                                    <FormatIntkeDate date={intake.date} />
                                    {index < array.length - 1 && <>,&nbsp;</>}
                                  </div>
                                ))}
                            </div>
                          )}

                        {programData.intakeData.length > 0 &&
                          programData.intakeData.some(
                            (intake) => intake.statusId === 2
                          ) && (
                            <div className="d-flex justify-content-start align-items-center">
                              <i className="bi bi-circle-fill text-normal-yellow me-1"></i>
                              {programData.intakeData
                                .filter((intake) => intake.statusId === 2)
                                .sort(
                                  (a, b) => new Date(a.date) - new Date(b.date)
                                ) // Sort by date
                                .map((intake, index, array) => (
                                  <div key={intake.date}>
                                    <FormatIntkeDate date={intake.date} />
                                    {index < array.length - 1 && <>,&nbsp;</>}
                                  </div>
                                ))}
                            </div>
                          )}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="ps-4">Academic Requirement</td>
                    {selectedProgramsData.map((data, index) => (
                      <td key={index}>
                        {data.programData.academicRequirement}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="ps-4">Areas of Interest</td>
                    {selectedProgramsData.map((data, index) => (
                      <td key={index}>{data.programData.areasofInterest}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="ps-4">Duration</td>
                    {selectedProgramsData.map((data, index) => (
                      <td key={index}>{data.programData.duration}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="ps-4">Web (Program link)</td>
                    {selectedProgramsData.map((data, index) => (
                      <td key={index}>
                        <a
                          href={data.programData.websiteUrl}
                          style={{ cursor: "pointer" }}
                          className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Go to website
                        </a>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {!getDataFailed && <AppFooter />}
    </>
  );
}
