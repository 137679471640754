import React from "react";
import Table from "react-bootstrap/Table";
import AddIntake from "./AddIntake";
import FormatIntkeDate from "../../../components/UI/FormatIntkeDate";
import EditIntake from "./EditIntake";

export default function ListIntakes({
  dataList,
  collegeData,
  getData,
  pageTitle,
}) {
  return (
    <>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Program Name</th>
            <th>College Name</th>
            <th>Open Intakes</th>
            <th>Yet to Open Intakes</th>
            <th>Inactive Intakes</th>
            <th>Waitlist Intakes</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {dataList.map((data, idx) => (
            <tr key={idx}>
              <td>{data.name}</td>

              <td>
                {collegeData.find((college) => college.id === data.collegeId)
                  ?.collegeName || ""}
              </td>

              <td>
                {data.intakeList
                  .filter((intake) => intake.statusId === 3) // active + open
                  .sort((a, b) => new Date(a.date) - new Date(b.date)) // Sort by date
                  .map((intake, index, array) => (
                    <div key={intake.date} className="fw-bold text-success">
                      <FormatIntkeDate date={intake.date} />
                      {index < array.length - 1 && <>,&nbsp;</>}
                    </div>
                  ))}
              </td>
              <td>
                {data.intakeList
                  .filter((intake) => intake.statusId === 2) // active + close
                  .sort((a, b) => new Date(a.date) - new Date(b.date)) // Sort by date
                  .map((intake, index, array) => (
                    <div key={intake.date} className="fw-bold text-warning">
                      <FormatIntkeDate date={intake.date} />
                      {index < array.length - 1 && <>,&nbsp;</>}
                    </div>
                  ))}
              </td>
              <td>
                {data.intakeList
                  .filter((intake) => intake.statusId === 0) // Inactive
                  .sort((a, b) => new Date(a.date) - new Date(b.date)) // Sort by date
                  .map((intake, index, array) => (
                    <div key={intake.date} className="fw-bold text-danger">
                      <FormatIntkeDate date={intake.date} />
                      {index < array.length - 1 && <>,&nbsp;</>}
                    </div>
                  ))}
              </td>
              <td>
                {data.intakeList
                  .filter((intake) => intake.statusId === 4) // Waitlist
                  .sort((a, b) => new Date(a.date) - new Date(b.date)) // Sort by date
                  .map((intake, index, array) => (
                    <div key={intake.date} className="fw-bold text-info">
                      <FormatIntkeDate date={intake.date} />
                      {index < array.length - 1 && <>,&nbsp;</>}
                    </div>
                  ))}
              </td>
              <td>
                <div className="d-flex">
                  <AddIntake
                    data={data}
                    collegeData={collegeData}
                    pageTitle={pageTitle}
                    getData={getData}
                  />
                  <EditIntake
                    data={data}
                    collegeData={collegeData}
                    pageTitle={pageTitle}
                    getData={getData}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
