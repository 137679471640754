import http from "./api";

const get = (id) => {
  return http.get(`Company?CompanyId=${id}`);
};
const remove = (id) => {
  return http.delete(`Company/${id}`);
};
const create = (data) => {
  return http.post("Company", data);
};
const update = (data) => {
  return http.put(`Company`, data);
};
const find = (params) => {
  return http.get("Company/search?", { params });
};
const getAllCompaniesandBranchHead = (params) => {
  return http.get("Company/getAllCompaniesandBranchHead?", { params });
};
const updateStatus = (data) => {
  return http.post(`Company/changeCompanyStatus`, data);
};
const assignCompanyToTeamLeader = (data) => {
  return http.post(`Company/assignCompanyToTeamLeader`, data);
};
const getCompaniesandBranchHeadByTeamLeaderId = (params) => {
  return http.get(`Company/getCompaniesandBranchHeadByTeamLeaderId?`, { params });
};
const AgentsDataService = {
  get,
  remove,
  create,
  update,
  find,
  getAllCompaniesandBranchHead,
  updateStatus,
  assignCompanyToTeamLeader,
  getCompaniesandBranchHeadByTeamLeaderId,
};

export default AgentsDataService;
