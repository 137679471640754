import React from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

const ShareButton = ({ url, title, customIcon, customIconClasses = "" }) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center mx-3"
      id="share-button"
    >
      <div className="btn-group dropstart">
        <button
          type="button"
          className={
            customIcon
              ? customIconClasses
              : "btn bg-normal-green text-white rounded-circle "
          }
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style={{ width: "48px", height: "48px" }}
        >
          {customIcon ?? (
            <i className="bi bi-share-fill fs-2 d-flex justify-content-center align-items-center" />
          )}
        </button>
        <ul className="dropdown-menu border-0 p-2 rounded-pill">
          <div className=" d-flex">
            <EmailShareButton
              url={url}
              subject={title}
              body="Education Planner"
              className="me-1"
            >
              <EmailIcon size={36} round={true} />
            </EmailShareButton>
            <FacebookShareButton
              url={url}
              subject={title}
              body="Education Planner"
              className="me-1"
            >
              <FacebookIcon size={36} round={true} />
            </FacebookShareButton>
            <LinkedinShareButton
              url={url}
              subject={title}
              body="Education Planner"
              className="me-1"
            >
              <LinkedinIcon size={36} round={true} />
            </LinkedinShareButton>
            <TwitterShareButton
              url={url}
              subject={title}
              body="Education Planner"
              className="me-1"
            >
              <TwitterIcon size={36} round={true} />
            </TwitterShareButton>
            <WhatsappShareButton
              url={url}
              subject={title}
              body="Education Planner"
              className="me-1"
            >
              <WhatsappIcon size={36} round={true} />
            </WhatsappShareButton>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default ShareButton;
