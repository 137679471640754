import React from 'react'
import countriesData from "../../Services/CountriesList.json";
import PlaceholderPhoneInput from '../../components/PlaceholderPhone';
import TokenService from '../../Services/TokenService';
import RolesService from '../../Services/RolesService';


const DirectorInfo = ({ setActiveTabNext, profileData, setProfileData, disabled,
    handleInputChange, handleSaveProfileData, isFormEdited, setIsFormEdited }) => {

    const getRole = TokenService.getUserRole()
    const { BranchHead } = RolesService

    const handleTabChange = (e) => {
        e.preventDefault();
        setActiveTabNext("bankingInformation");
    };

    return (
        <form className='need-validations'>

            <div className="border rounded-4 bg-light-blue1 text1-5 p-3 mb-4">
                <div className="d-flex align-items-center bg-stripped1 border border-top-0 rounded-4 p-3  mb-3">
                    <span className="font-bold">Basic Details</span>
                </div>
                <div className="row align-items-center">
                    <div className="col-12">
                        <div className="row g-3">
                            <div className="col-4 form-floating px-3">
                                <input
                                    type="text"
                                    className="form-control rounded-pill border shadow"
                                    placeholder="Legal first name"
                                    required
                                    id="legalFirstName"
                                    value={profileData.legalFirstName}
                                    onChange={handleInputChange}
                                    name="legalFirstName"
                                    autoComplete="off"
                                    disabled={disabled}
                                />
                                <label htmlFor="floatingInput" className="ms-4">
                                    Legal first name<span className="text-danger">*</span>
                                </label>
                            </div>
                            <div className="col-4 form-floating px-3">
                                <input
                                    type="text"
                                    className="form-control rounded-pill border shadow"
                                    placeholder=" Legal last name"
                                    required
                                    id="legalLastName"
                                    value={profileData.legalLastName}
                                    onChange={handleInputChange}
                                    name="legalLastName"
                                    autoComplete="off"
                                    disabled={disabled}
                                />
                                <label htmlFor="floatingInput" className="ms-4">
                                    Legal last name
                                </label>
                            </div>
                            <div className="col-4 form-floating px-3">
                                <PlaceholderPhoneInput
                                    placeholder='Telephone'
                                    value={profileData.directorTelephone}
                                    onChange={(phoneNo) => {
                                        setIsFormEdited(false)
                                        setProfileData((prev) => ({
                                            ...prev,
                                            directorTelephone: phoneNo
                                        }))
                                    }}
                                    disabled={disabled}
                                />
                            </div>

                            <div className="col-4 form-floating px-3">
                                <PlaceholderPhoneInput
                                    placeholder='Landline number'
                                    value={profileData.directorLandlineNumber}
                                    onChange={(phoneNo) => {
                                        setIsFormEdited(false)
                                        setProfileData((prev) => ({
                                            ...prev,
                                            directorLandlineNumber: phoneNo
                                        }))
                                    }}
                                    disabled={disabled}
                                />

                            </div>
                            <div className="col-4 form-floating px-3">
                                <input
                                    type="text"
                                    className="form-control rounded-pill border shadow"
                                    placeholder="Email ID"
                                    required
                                    id="directorEmailId"
                                    value={profileData.directorEmailId}
                                    onChange={handleInputChange}
                                    name="directorEmailId"
                                    autoComplete="off"
                                    disabled={disabled}
                                />
                                <label htmlFor="floatingInput" className="ms-4">
                                    Email ID<span className="text-danger">*</span>
                                </label>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="border rounded-4 bg-light-blue1 text1-5 p-3 mb-4">
                <div className="d-flex align-items-center bg-stripped1 border border-top-0 rounded-4 p-3  mb-3">
                    <span className="font-bold">Address</span>

                    {/* <div className={`col-4 form-floating px-3 ${disabled && "d-none"}`}>
                        <GoogleAddressAutoComplete setSelectedPlace={setSelectedPlace} />
                    </div> */}
                </div>
                <div className="row align-items-center">
                    <div className="col-12">
                        <div className="row g-3">
                            <div className="col-4 form-floating px-3">
                                <textarea
                                    className="form-control rounded-4 border shadow h-100 text1-4"
                                    placeholder="Mailing Address"
                                    required
                                    id="directorAddress"
                                    value={profileData.directorAddress}
                                    onChange={handleInputChange}
                                    name="directorAddress"
                                    disabled={disabled}
                                />
                                <label htmlFor="floatingTextarea" className="ms-4">
                                    Address

                                </label>
                            </div>

                            <div className="col-8 mb-3">
                                <div className="row g-3">
                                    <div className="col-6 form-floating px-3">
                                        <select
                                            className={`form-select rounded-pill border shadow`}
                                            aria-label="Country"
                                            value={profileData.directorCountry}
                                            onChange={handleInputChange}
                                            name="directorCountry"
                                            required
                                            disabled={disabled}
                                        >
                                            <option value="">Country</option>
                                            {countriesData.map((country) => (
                                                <option key={country.code} value={country.name}>
                                                    {country.name}
                                                </option>
                                            ))}
                                        </select>
                                        <label htmlFor="floatingInput" className="ms-4">
                                            Country
                                        </label>
                                    </div>
                                    <div className="col-6 form-floating px-3">
                                        <input
                                            type="text"
                                            className="form-control rounded-pill border shadow"
                                            placeholder="City"
                                            required
                                            id="directorCity"
                                            value={profileData.directorCity}
                                            onChange={handleInputChange}
                                            name="directorCity"
                                            autoComplete="off"
                                            disabled={disabled}
                                        />
                                        <label htmlFor="floatingInput" className="ms-4">
                                            City
                                        </label>
                                    </div>
                                    <div className="col-6 form-floating px-3">
                                        <input
                                            type="text"
                                            className="form-control rounded-pill border shadow"
                                            placeholder="State"
                                            required
                                            id="directorState"
                                            value={profileData.directorState}
                                            onChange={handleInputChange}
                                            name="directorState"
                                            disabled={disabled}
                                            autoComplete="off"
                                        />
                                        <label htmlFor="floatingInput" className="ms-4">
                                            State
                                        </label>
                                    </div>
                                    <div className="col-6 form-floating px-3">
                                        <input
                                            type="text"
                                            className="form-control rounded-pill border shadow"
                                            placeholder="Province"
                                            required
                                            id="directorProvince"
                                            value={profileData.directorProvince}
                                            onChange={handleInputChange}
                                            name="directorProvince"
                                            autoComplete="off"
                                            disabled={disabled}
                                        />
                                        <label htmlFor="floatingInput" className="ms-4">
                                            Province
                                        </label>
                                    </div>

                                </div>
                            </div>
                            <div className="col-4 form-floating px-3">
                                <input
                                    type="text"
                                    className="form-control rounded-pill border shadow"
                                    placeholder="Postal Code"
                                    required
                                    id="directorPostalCode"
                                    value={profileData.directorPostalCode}
                                    onChange={handleInputChange}
                                    name="directorPostalCode"
                                    autoComplete="off"
                                    disabled={disabled}
                                />
                                <label htmlFor="floatingInput" className="ms-4">
                                    Postal Code
                                </label>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="border rounded-4 bg-light-blue1 text1-5 p-3 mb-4">
                <div className="d-flex align-items-center bg-stripped1 border border-top-0 rounded-4 p-3  mb-3">
                    <span className="font-bold">Social presence</span>

                    {/* <div className={`col-4 form-floating px-3 ${disabled && "d-none"}`}>
                        <GoogleAddressAutoComplete setSelectedPlace={setSelectedPlace} />
                    </div> */}
                </div>
                <div className="row align-items-center">
                    <div className="col-12">
                        <div className="row g-3">
                            <div className="col-4 form-floating px-3">
                                <input
                                    type="text"
                                    className="form-control rounded-pill border shadow"
                                    placeholder="Skype ID"
                                    required
                                    id="directorSkypeId"
                                    value={profileData.directorSkypeId}
                                    onChange={handleInputChange}
                                    name="directorSkypeId"
                                    autoComplete="off"
                                    disabled={disabled}
                                />
                                <label htmlFor="floatingInput" className="ms-4">
                                    Skype ID
                                </label>
                            </div>
                            <div className="col-4 form-floating px-3">
                                <input
                                    type="text"
                                    className="form-control rounded-pill border shadow"
                                    placeholder="Twitter ID"
                                    required
                                    id="directorTwitterId"
                                    value={profileData.directorTwitterId}
                                    onChange={handleInputChange}
                                    name="directorTwitterId"
                                    autoComplete="off"
                                    disabled={disabled}
                                />
                                <label htmlFor="floatingInput" className="ms-4">
                                    Twitter ID
                                </label>
                            </div>
                            <div className="col-4 form-floating px-3">
                                <input
                                    type="text"
                                    className="form-control rounded-pill border shadow"
                                    placeholder="Facebook ID"
                                    required
                                    id="directorFacebookId"
                                    value={profileData.directorFacebookId}
                                    onChange={handleInputChange}
                                    name="directorFacebookId"
                                    autoComplete="off"
                                    disabled={disabled}
                                />
                                <label htmlFor="floatingInput" className="ms-4">
                                    Facebook ID
                                </label>
                            </div>
                            <div className="col-4 form-floating px-3">
                                <input
                                    type="text"
                                    className="form-control rounded-pill border shadow"
                                    placeholder="Instagram ID"
                                    required
                                    id="directorInstagramId"
                                    value={profileData.directorInstagramId}
                                    onChange={handleInputChange}
                                    name="directorInstagramId"
                                    autoComplete="off"
                                    disabled={disabled}
                                />
                                <label htmlFor="floatingInput" className="ms-4">
                                    Instagram ID
                                </label>
                            </div>
                            <div className="col-4 form-floating px-3">
                                <PlaceholderPhoneInput
                                    placeholder='Whatsapp Number'
                                    value={profileData.directorWhatsappNumber}
                                    onChange={(phoneNo) => {
                                        setIsFormEdited(false)
                                        setProfileData((prev) => ({
                                            ...prev,
                                            directorWhatsappNumber: phoneNo
                                        }))
                                    }}
                                    disabled={disabled}
                                />


                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="border rounded-4 bg-light-blue1 text1-5 p-3 mb-4">
                <div className="d-flex align-items-center bg-stripped1 border border-top-0 rounded-4 p-3  mb-3">
                    <span className="font-bold">Secondary contact information</span>

                    {/* <div className={`col-4 form-floating px-3 ${disabled && "d-none"}`}>
                        <GoogleAddressAutoComplete setSelectedPlace={setSelectedPlace} />
                    </div> */}
                </div>
                <div className="row align-items-center">
                    <div className="col-12">
                        <div className="row g-3">
                            <div className="col-4 form-floating px-3">
                                <input
                                    type="text"
                                    className="form-control rounded-pill border shadow"
                                    placeholder="Name"
                                    required
                                    id="secondaryContactName"
                                    value={profileData.secondaryContactName}
                                    onChange={handleInputChange}
                                    name="secondaryContactName"
                                    autoComplete="off"
                                    disabled={disabled}
                                />
                                <label htmlFor="floatingInput" className="ms-4">
                                    Name
                                </label>
                            </div>
                            <div className="col-4 form-floating px-3">
                                <input
                                    type="text"
                                    className="form-control rounded-pill border shadow"
                                    placeholder="Designation"
                                    required
                                    id="secondaryContactDesignation"
                                    value={profileData.secondaryContactDesignation}
                                    onChange={handleInputChange}
                                    name="secondaryContactDesignation"
                                    autoComplete="off"
                                    disabled={disabled}
                                />
                                <label htmlFor="floatingInput" className="ms-4">
                                    Designation
                                </label>
                            </div>
                            <div className="col-4 form-floating px-3">
                                <PlaceholderPhoneInput
                                    placeholder='Telephone'
                                    value={profileData.secondaryContactTelephone}
                                    onChange={(phoneNo) => {
                                        setIsFormEdited(false)
                                        setProfileData((prev) => ({
                                            ...prev,
                                            secondaryContactTelephone: phoneNo
                                        }))
                                    }}
                                    disabled={disabled}
                                />

                            </div>
                            <div className="col-4 form-floating px-3">
                                <PlaceholderPhoneInput
                                    placeholder='Landline Number'
                                    value={profileData.secondaryContactLandline}
                                    onChange={(phoneNo) => {
                                        setIsFormEdited(false)
                                        setProfileData((prev) => ({
                                            ...prev,
                                            secondaryContactLandline: phoneNo
                                        }))
                                    }}
                                    disabled={disabled}
                                />

                            </div>
                            <div className="col-4 form-floating px-3">
                                <input
                                    type="email"
                                    className="form-control rounded-pill border shadow"
                                    placeholder="Email ID"
                                    required
                                    id="secondaryContactEmailId"
                                    value={profileData.secondaryContactEmailId}
                                    onChange={handleInputChange}
                                    name="secondaryContactEmailId"
                                    autoComplete="off"
                                    disabled={disabled}
                                />
                                <label htmlFor="floatingInput" className="ms-4">
                                    Email ID
                                </label>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="d-flex justify-content-end my-2 mt-5">
                {getRole === BranchHead ?
                    <button
                        type="button"
                        className="btn btn-light-blue-outline rounded-pill py-3 me-2 px-5 text1-4"
                        onClick={handleSaveProfileData}
                        disabled={disabled || isFormEdited}
                    >
                        Save
                    </button>
                    : null}
                <button
                    type="button"
                    className="btn btn-light-blue-outline rounded-pill py-3 me-2 px-5 text1-4"
                    onClick={handleTabChange}
                >
                    Next
                </button>
            </div>

        </form>
    )
}

export default DirectorInfo
