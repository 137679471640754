import http from "./api";

const get = (id) => {
  return http.get(`Education?EducationId=${id}`);
};
const remove = (id) => {
  return http.delete(`Education?id=${id}`);
};
const create = (data) => {
  return http.post("Education", data);
};
const update = (data) => {
  return http.put(`Education`, data);
};
const findAndGetByStudentID = (id) => {
  return http.get(`Education/searchAndGetByStudentId?studentId=${id}`);
};

const EducationDataService = {
  get,
  remove,
  create,
  update,
  findAndGetByStudentID,
};

export default EducationDataService;
