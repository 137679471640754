import React, { useState, useEffect, useCallback, useMemo } from "react";
import WelcomeBanner from "./WelcomeBanner";
import EnglishProficiencyTest from "./EnglishProficiencyTest";
import EducationDataService from "../../Services/StudentEducationService";
import DatePicker from "react-datepicker";
import EnglishProficiencyTestDataService from "../../Services/StudentEPTestService";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/UI/ConfirmationModal";

const gradingOptions = [
  {
    label: "Select",
    value: "",
  },
  {
    label: "Out of 100",
    value: "Out of 100",
  },
  {
    label: "Out of 45",
    value: "Out of 45",
  },
  {
    label: "Out of 10",
    value: "Out of 10",
  },
  {
    label: "Out of 7",
    value: "Out of 7",
  },
  {
    label: "Out of 5",
    value: "Out of 5",
  },
  {
    label: "Out of 4",
    value: "Out of 4",
  },
];

export default function AddEducation({
  EducationData,
  currentEducation,
  studentData,
  saveStudentData,
  saveEducationData,
  handleInputChange,
  updateState,
  updateStateAfterDelete,
  setActiveTabNext,
  allDraftApplication,
  getEducation,
}) {
  const [showConfirmationEPT, setShowConfirmationEPT] = useState(false);
  const [itemToConfirmEPT, setItemToConfirmEPT] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [itemToConfirm, setItemToConfirm] = useState(null);

  const handleTabChange = (e) => {
    e.preventDefault();
    setActiveTabNext("documents");
  };

  const [addedEducationFields, setAddedEducationFields] = useState([]);

  const currentOtherEducations = currentEducation?.filter((cd) => {
    const firstTwoWords = cd.level.startsWith("Other Education");

    return firstTwoWords;
  });

  const uniqueCds = currentOtherEducations?.filter(
    (cd) => !EducationData.includes(cd)
  );

  if (uniqueCds !== undefined) {
    EducationData.push(...uniqueCds);
  }

  const [otherEducationCount, setOtherEducationCount] = useState(
    currentOtherEducations?.length
  );

  const disabled = useMemo(() => {
    if (allDraftApplication || allDraftApplication === undefined) {
      return false;
    } else {
      if (!studentData.isEditable) {
        return true;
      }
      return false;
    }
  }, [allDraftApplication, studentData.isEditable]);

  const handleAddOtherEducation = (e) => {
    e.preventDefault();
    let newCount = otherEducationCount + 1;
    // Check if the newCount already exists in currentEducation levels
    while (
      currentEducation.some(
        (edu) => edu.level === `Other Education ${newCount}`
      )
    ) {
      newCount++;
    }
    setOtherEducationCount(newCount);

    const newEducation = {
      level: `Other Education ${newCount}`,
      institute: "",
      field: "",
      boardUniv: "",
      passingYear: "",
      startDate: "",
      endDate: "",
      address: "",
      percentage: "",
      gradingSystem: "",
    };

    EducationData.push(newEducation);
    // Update addedEducationFields state
    setAddedEducationFields([...addedEducationFields, newEducation]);
  };

  const handleRemoveOtherEducation = (e, education) => {
    e.preventDefault();
    const isOtherEducation = education.level.startsWith("Other Education");
    const isFieldsEmpty =
      education.passingYear === "" &&
      education.percentage === "" &&
      education.gradingSystem === "" &&
      education.startDate === "" &&
      education.endDate === "" &&
      education.institute === "" &&
      education.field === "" &&
      education.boardUniv === "" &&
      education.address === "";

    if (isOtherEducation && isFieldsEmpty) {
      const updatedEducationData = EducationData.filter(
        (item) => item !== education
      );
      setOtherEducationCount((prevCount) => prevCount - 1);
      EducationData.pop(updatedEducationData);
    }
    setAddedEducationFields([]);
  };
  const [resetEducationIndex, setResetEducationIndex] = useState(null);
  const handleRemoveEducation = async (e, data, index) => {
    e.preventDefault();
    setShowConfirmation(true);
    setItemToConfirm(data);
    setResetEducationIndex(index);
  };

  const handleRemoveEducationConfirm = async () => {
    setShowConfirmation(false);
    try {
      const response = await EducationDataService.remove(itemToConfirm.id);

      if (response.data === true) {
        if (itemToConfirm.level.startsWith("Other Education")) {
          toast.success(`${itemToConfirm.level} is deleted successfully`);
        } else {
          toast.success(`${itemToConfirm.level} is deleted successfully`);
          updateStateAfterDelete(resetEducationIndex);
        }
      }
    } catch (error) {
      toast.error(error);
    } finally {
      await getEducation();
      setItemToConfirm(null);
      const updatedEducationData = EducationData.filter(
        (item) => item !== itemToConfirm
      );
      setOtherEducationCount((prevCount) => prevCount - 1);
      EducationData.pop(updatedEducationData);
      getEducation();
    }
  };

  function convertUTCToLocalDate(date) {
    if (!date) {
      return date;
    }

    const localOffset = new Date().getTimezoneOffset(); // Get local timezone offset in milliseconds
    const utcDate = new Date(date);
    const localDate = new Date(utcDate.getTime() - localOffset);

    return localDate;
  }

  const handleDateChange = (value, index, fieldName) => {
    const dateString = value;
    const date = new Date(dateString);
    const utcDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000
    );
    const formattedDate = utcDate.toISOString();
    updateState(index)({ target: { name: fieldName, value: formattedDate } });
  };

  const renderEducationFields = () => {
    return EducationData?.map((education, index) => (
      <div
        key={index}
        className="border rounded-4 bg-light-blue1 text1-5 p-3 mb-4"
      >
        <div className="d-flex align-items-center bg-stripped1 border border-top-0 rounded-4 p-3 font-bold mb-3">
          {education.level}
          {education.level === "10th" ||
          education.level === "12th" ||
          education.level === "Graduation" ? (
            ""
          ) : education.level.startsWith("Other Education") && !education.id ? (
            <i
              className="bi bi-x-circle text2 text-normal-red ms-3"
              onClick={(e) => handleRemoveOtherEducation(e, education)}
              style={{ cursor: "pointer" }}
            ></i>
          ) : education.id ? (
            <i
              className="bi bi-x-circle text2 text-normal-red ms-3"
              onClick={(e) => handleRemoveEducation(e, education, index)}
              style={{ cursor: "pointer" }}
            ></i>
          ) : (
            ""
          )}

          {education.level === "10th" ? (
            <span className="text-danger">*</span>
          ) : (
            ""
          )}
        </div>
        <div className="row align-items-center">
          <div className="col-12">
            <div className="row g-3">
              {renderFormField("Institute Name", "institute", index)}
              {renderFormField("Field", "field", index)}
              {renderFormField("Board / University", "boardUniv", index)}
              {renderFormField("Passing Year", "passingYear", index)}
              {renderFormField("startDate", "startDate", index)}
              {renderFormField("endDate", "endDate", index)}
              {renderFormField("City / Country", "address", index, "textarea")}
              {renderFormField(
                "Grading System",
                "gradingSystem",
                index,
                "select",
                gradingOptions
              )}
              {renderFormField(
                "Enter Percentage (Please convert your CGPA to Percentage)",
                "percentage",
                index
              )}
            </div>
          </div>
        </div>
      </div>
    ));
  };

  const renderFormField = (label, name, index, type = "text", options = []) => {
    const educationLevel = EducationData[index].level;
    const isRequired = educationLevel.includes("10th");
    const isDisabled = disabled;

    const isStartDate = name === "startDate";
    const endDate = name === "endDate";
    const isDateField = isStartDate || endDate; // Check if it's startDate or endDate field

    const maxFourDigitsRegex = /^\d{0,4}$/;
    const maxTwoDigitsRegex = /^\d{0,2}$/;
    const textOnlyRegex = /^[A-Za-z\s]+$/;

    // Define regex and title for specific fields
    const regexAndTitleMap = {
      passingYear: { regex: maxFourDigitsRegex, title: "Enter up to 4 digits" },
      percentage: { regex: maxTwoDigitsRegex, title: "Enter up to 2 digits" },
      institute: { regex: textOnlyRegex, title: "Enter text only" },
      field: { regex: textOnlyRegex, title: "Enter text only" },
      boardUniv: { regex: textOnlyRegex, title: "Enter text only" },
    };

    const inputPattern = regexAndTitleMap[name]?.regex;
    const inputTitle = regexAndTitleMap[name]?.title;

    return (
      <div className="col-4 form-floating px-3">
        {type === "textarea" ? (
          <textarea
            className="form-control rounded-pill border shadow text1-4 px-4"
            placeholder={label}
            required={isRequired}
            id={name + index}
            value={EducationData[index][name]}
            onChange={updateState(index)}
            name={name}
            disabled={isDisabled}
          />
        ) : isDateField ? (
          <div className="customDatePickerWidth">
            <DatePicker
              className="form-control rounded-pill border shadow text1-4 px-4
            py-2"
              selected={convertUTCToLocalDate(EducationData[index][name])}
              onChange={(value) => handleDateChange(value, index, name)}
              dateFormat="dd-MM-yyyy"
              name={name}
              required={isRequired}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText={isStartDate ? "Start Date" : "End Date"}
              maxDate={new Date()}
              minDate={new Date(1950, 0, 1)}
              autoComplete="off"
              disabled={isDisabled}
            />
          </div>
        ) : type === "select" ? (
          <select
            className="form-control rounded-pill border shadow "
            required={isRequired}
            id={name + index}
            placeholder={label}
            value={EducationData[index][name]}
            onChange={updateState(index)}
            name={name}
            title={inputTitle}
            autoComplete="off"
            disabled={isDisabled}
          >
            {options?.map((item) => (
              <option value={item.value}>{item.label}</option>
            ))}
          </select>
        ) : (
          <input
            type={type}
            className="form-control rounded-pill border shadow text1-4"
            placeholder={label}
            required={isRequired}
            id={name + index}
            value={EducationData[index][name]}
            onChange={updateState(index)}
            name={name}
            //pattern={inputPattern} // Using the regex pattern here
            title={inputTitle} // Tooltip text for invalid input
            disabled={isDisabled}
          />
        )}
        <label
          htmlFor="floatingInput"
          className="ms-4 text-light-gray overflow-hidden"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: "85%",
            display: "block",
          }}
        >
          {label === "startDate" || label === "endDate" ? "" : label}
          {label === "startDate" || label === "endDate"
            ? ""
            : isRequired && <span className="text-danger">*</span>}
        </label>
      </div>
    );
  };

  // EP TEST FUNCTIONS
  const testTypes = [
    { id: "checkBoxIELTS", type: "IELTS" },
    { id: "checkBoxTOEFL", type: "TOEFL" },
    { id: "checkBoxPTE", type: "PTE" },
    { id: "checkBoxDuolingo", type: "Duolingo" },
    { id: "checkBoxGMAT", type: "GMAT" },
    { id: "checkBoxGRE", type: "GRE" },
    { id: "checkBoxSAT", type: "SAT" },
  ];

  const StudentId = studentData.id;
  const [checkboxes, setCheckboxes] = useState(
    testTypes.map((test) => ({
      ...test,
      value: "",
      required: false,
      isChecked: false,
    }))
  );

  const handleCheckboxChange = (checkboxId, checkboxValue) => {
    const updatedCheckboxes = checkboxes.map((checkbox) => {
      if (checkbox.id === checkboxId) {
        return { ...checkbox, isChecked: checkboxValue };
      }
      return checkbox;
    });
    setCheckboxes(updatedCheckboxes);
  };

  const [currentEPTests, setCurrentEPTests] = useState(
    testTypes.map((test) => ({
      id: undefined,
      studentId: StudentId,
      overallScore: "",
      testDate: "",
      readingScore: "",
      listeningScore: "",
      speakingScore: "",
      writingScore: "",
      type: test.type,
      email: "",
      password: "",
    }))
  );

  const getEPTest = useCallback(() => {
    var data = {
      overallScore: "",
      type: "",
      studentId: StudentId,
      currentPage: 1,
      pageSize: 12,
    };

    EnglishProficiencyTestDataService.find(data)
      .then((response) => {
        const testData = response.data.response; // Array of objects
        // Create a copy of the currentEPTests state and update it with the fetched data
        const updatedEPTests = testTypes.map((test) => {
          const foundTest = testData.find(
            (testDataItem) => testDataItem.type === test.type
          );
          return {
            id: foundTest ? foundTest.id : undefined,
            studentId: StudentId,
            overallScore: foundTest ? foundTest.overallScore.toString() : "",
            testDate: foundTest ? foundTest.testDate : "",
            readingScore: foundTest ? foundTest.readingScore.toString() : "",
            listeningScore: foundTest
              ? foundTest.listeningScore.toString()
              : "",
            speakingScore: foundTest ? foundTest.speakingScore.toString() : "",
            writingScore: foundTest ? foundTest.writingScore.toString() : "",
            type: test.type,
            email: foundTest?.email || "",
            password: foundTest?.password || "",
          };
        });

        // Update the checkboxes with the isChecked property based on fetched data
        const updatedCheckboxes = checkboxes.map((checkbox) => {
          const foundTest = testData.find(
            (testDataItem) => testDataItem.type === checkbox.type
          );
          return { ...checkbox, isChecked: !!foundTest };
        });

        setCheckboxes(updatedCheckboxes);
        setCurrentEPTests(updatedEPTests);
      })
      .catch((e) => {
        toast.error(
          (e.response && e.response.data && e.response.data.message) ||
            e.message ||
            e.toString()
        );
      });
  }, [StudentId, checkboxes]);

  useEffect(() => {
    getEPTest();
  }, []);

  const handleTestChange = (e, type) => {
    const { name, value } = e.target;

    let updatedEPTests;

    // get current test type
    const currentTest = currentEPTests.find(
      (testItem) => testItem.type === type
    );

    if (
      currentTest &&
      (currentTest.type === "GRE" || currentTest.type === "SAT")
    ) {
      updatedEPTests = currentEPTests.map((testItem) => {
        if (testItem.type === type) {
          // If test type is "GRE" or "SAT", set writingScore to 0, else use user input for writingScore
          const writingScore =
            currentTest.type === "GRE" || currentTest.type === "SAT"
              ? 0
              : value;
          return { ...testItem, [name]: value, writingScore };
        }
        return testItem;
      });
    } else {
      updatedEPTests = currentEPTests.map((testItem) => {
        if (testItem.type === type) {
          return { ...testItem, [name]: value };
        }
        return testItem;
      });
    }

    setCurrentEPTests(updatedEPTests);
  };

  const saveEPTest = async (e) => {
    e.preventDefault();
    let toSave = [];
    let messages = [];

    for (const record of currentEPTests) {
      let data = {
        id: record.id,
        studentId: StudentId,
        overallScore: record.overallScore,
        testDate: record.testDate,
        readingScore: record.readingScore,
        listeningScore: record.listeningScore,
        speakingScore: record.speakingScore,
        writingScore: record.writingScore,
        type: record.type,
        email: record.email,
        password: record.password,
        statusId: 1,
      };

      toSave.push(data);
    }

    for (const record of toSave) {
      try {
        if (record.id === undefined) {
          await EnglishProficiencyTestDataService.create(record);
          messages.push(`Success: ${record.type} record created successfully.`);
        } else {
          await EnglishProficiencyTestDataService.update(record);
          messages.push(`Success: ${record.type} record updated successfully.`);
        }
      } catch (e) {
        messages.push(
          `Failed: ${record.type} - ${
            e.response.status === 400 ? "record was empty" : e.message
          }`
        );
      }
    }

    if (messages.length > 0) {
      getEPTest();
      //alert(messages.join("\n"));
      toast.success("saved successfully");
    }
  };

  const handleRemoveEPT = async (e, data) => {
    e.preventDefault();
    setItemToConfirmEPT(data);
    setShowConfirmationEPT(true);
  };

  const handleConfirmation = async () => {
    setShowConfirmationEPT(false);
    try {
      const response = await EnglishProficiencyTestDataService.remove(
        itemToConfirmEPT.id
      );

      if (response.data === true) {
        toast.info("Delete Successfully");
        getEPTest();
      } else {
        toast.info("Not Deleted");
      }
    } catch (e) {
      toast.error(
        (e.response && e.response.data && e.response.data.message) ||
          e.message ||
          e.toString()
      );
    } finally {
      setItemToConfirmEPT(null);
    }
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
    setShowConfirmationEPT(false);
  };

  const [isSaving, setIsSaving] = useState(false);
  const runFunctions = async (e) => {
    e.preventDefault();
    const hasAnyScoreValue = currentEPTests.some((item) => {
      const scores = [
        item.listeningScore,
        item.overallScore,
        item.readingScore,
        item.speakingScore,
      ];

      return scores.some((score) => score !== "");
    });

    if (hasAnyScoreValue) {
      setIsSaving(true);
      await saveEducationData(e);
      await saveEPTest(e);
      await saveStudentData(e);
      setIsSaving(false);
    } else {
      toast.warning("Please fill atleast one English Proficiency Test");
    }
  };

  const [workingFrom, setWorkingFrom] = useState(
    studentData.workingFrom || null
  );
  const [workingUpto, setWorkingUpto] = useState(
    studentData.workingUpto || null
  );

  const handleWorkingFromDate = (value) => {
    const dateString = value;
    const date = new Date(dateString);
    const utcDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000
    );
    const formattedDate = utcDate.toISOString();
    setWorkingFrom(formattedDate);
    studentData.workingFrom = formattedDate;
  };

  const handleWorkingUptoDate = (value) => {
    const dateString = value;
    const date = new Date(dateString);
    const utcDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000
    );
    const formattedDate = utcDate.toISOString();
    setWorkingUpto(formattedDate);
    studentData.workingUpto = formattedDate;
  };

  return (
    <>
      <WelcomeBanner data={studentData} isData={true} show={disabled} />
      <ConfirmationModal
        show={showConfirmationEPT}
        onHide={handleCloseConfirmation}
        onConfirm={handleConfirmation}
        message={`Are you sure you want to delete ${itemToConfirmEPT?.type} ?`}
        title="Confirm Deletion"
      />
      <ConfirmationModal
        show={showConfirmation}
        onHide={handleCloseConfirmation}
        onConfirm={handleRemoveEducationConfirm}
        message={`Are you sure you want to delete ${itemToConfirm?.level} ?`}
        title="Confirm Deletion"
      />
      <form className="needs-validation" onSubmit={runFunctions}>
        <div className="border rounded-4 bg-light-blue1 text1-5 p-3 mb-4">
          <EnglishProficiencyTest
            studentData={studentData}
            handleCheckboxChange={handleCheckboxChange}
            checkboxes={checkboxes}
            currentEPTests={currentEPTests}
            handleTestChange={handleTestChange}
            handleRemoveEPT={handleRemoveEPT}
            allDraftApplication={allDraftApplication}
            disabled={disabled}
            isEditable={studentData.isEditable}
          />
        </div>

        {renderEducationFields()}

        <div className="border rounded-4 bg-light-blue1 text1-5 p-3 mb-4">
          <div className="d-flex align-items-center bg-stripped1 border border-top-0 rounded-4 p-3  mb-3">
            <span className="font-bold">Gap Details</span>
          </div>
          <div className="row align-items-center">
            <div className="col-12">
              <textarea
                htmlFor="floatingInput"
                className="form-control rounded-4  text1-4"
                placeholder=""
                style={{ height: "100px" }}
                value={studentData.gapJustification}
                onChange={handleInputChange}
                name="gapJustification"
                disabled={disabled}
              />
            </div>
          </div>
        </div>
        <div className="border rounded-4 bg-light-blue1 text1-5 p-3 mb-4">
          <div className="d-flex align-items-center bg-stripped1 border border-top-0 rounded-4 p-3  mb-3">
            <span className="font-bold">Work Experience</span>
          </div>
          <div className="row align-items-center">
            <div className="col-12">
              <div className="row g-3">
                <div className="col-4 form-floating px-3">
                  <input
                    type="text"
                    className="form-control rounded-pill border shadow"
                    placeholder="Organization Name and Address"
                    id="organizationNameAndAddress"
                    value={studentData?.organizationNameAndAddress}
                    onChange={handleInputChange}
                    name="organizationNameAndAddress"
                    autoComplete="off"
                    disabled={disabled}
                  />
                  <label htmlFor="floatingInput" className="ms-4">
                    Organization Name and Address
                  </label>
                </div>
                <div className="col-4 form-floating px-3">
                  <input
                    type="text"
                    className="form-control rounded-pill border shadow"
                    placeholder="Position"
                    id="position"
                    value={studentData?.position}
                    onChange={handleInputChange}
                    name="position"
                    autoComplete="off"
                    disabled={disabled}
                  />
                  <label htmlFor="floatingInput" className="ms-4">
                    Position
                  </label>
                </div>
                <div className="col-4 form-floating px-3">
                  <input
                    type="text"
                    className="form-control rounded-pill border shadow"
                    placeholder="Job Profile"
                    id="jobProfile"
                    value={studentData?.jobProfile}
                    onChange={handleInputChange}
                    name="jobProfile"
                    autoComplete="off"
                    disabled={disabled}
                  />
                  <label htmlFor="floatingInput" className="ms-4">
                    Job Profile
                  </label>
                </div>

                <div className="col-4 form-floating px-3 customDatePickerWidth z-3">
                  <DatePicker
                    className="form-control rounded-pill border shadow text1-4 px-4 py-2"
                    selected={convertUTCToLocalDate(workingFrom)}
                    onChange={(date) => handleWorkingFromDate(date)}
                    dateFormat="dd-MM-yyyy"
                    minDate={new Date(1950, 0, 1)}
                    name="workingFrom"
                    placeholderText="Working From"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    autoComplete="off"
                    disabled={disabled}
                  />
                  {/* <label htmlFor="floatingInput" className="ms-4">
                    Working From
                  </label> */}
                </div>
                <div className="col-4 form-floating px-3 customDatePickerWidth z-3">
                  <DatePicker
                    className="form-control rounded-pill border shadow text1-4 px-4 py-2"
                    selected={convertUTCToLocalDate(workingUpto)}
                    onChange={(date) => handleWorkingUptoDate(date)}
                    dateFormat="dd-MM-yyyy"
                    minDate={new Date(1950, 0, 1)}
                    name="workingUpto"
                    placeholderText="Working Upto"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    autoComplete="off"
                    disabled={disabled}
                  />
                  {/* <label htmlFor="floatingInput" className="ms-4">
                    Working Upto
                  </label> */}
                </div>
                <div className="col-4 form-floating px-3">
                  <select
                    className="form-select rounded-pill border shadow"
                    aria-label="Mode of salary"
                    id="modeOfSalary"
                    value={studentData.modeOfSalary}
                    onChange={handleInputChange}
                    name="modeOfSalary"
                    autoComplete="off"
                    disabled={disabled}
                  >
                    <option value="Cash">Cash</option>
                    <option value="Online">Online</option>
                  </select>
                  <label htmlFor="floatingInput" className="ms-4">
                    Mode of salary
                  </label>
                </div>
                <div className="col-4 form-floating px-3">
                  <select
                    className="form-select rounded-pill border shadow"
                    aria-label="Currently Working"
                    id="currentlyWorking"
                    value={studentData.currentlyWorking}
                    onChange={handleInputChange}
                    name="currentlyWorking"
                    autoComplete="off"
                    disabled={disabled}
                  >
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                  <label htmlFor="floatingInput" className="ms-4">
                    I am Currently Working here
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="row justify-content-between mb-2 mt-5">
            <div className="col-6">
              <div className="d-flex justify-content-start align-items-center my-2 mt-5">
                {allDraftApplication === true ||
                allDraftApplication === undefined ? (
                  <button
                    className="btn btn-light-blue-outline d-flex justify-content-center align-items-center rounded-pill text1-4 py-3 px-4"
                    onClick={handleAddOtherEducation}
                    disabled={isSaving}
                  >
                    Add other Education Field
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="col-6">
              <div className="d-flex justify-content-end align-items-center my-2 mt-5">
                {allDraftApplication === true ||
                studentData.isEditable ||
                allDraftApplication === undefined ? (
                  <button
                    type="submit"
                    className="btn btn-light-blue-outline rounded-pill py-3 me-2 col-4 text1-4"
                    disabled={isSaving}
                  >
                    <span>{isSaving ? "Please Wait..." : "Save"}</span>
                    {isSaving && <span className="spinner-border"></span>}
                  </button>
                ) : (
                  ""
                )}

                <button
                  type="button"
                  className="btn btn-light-blue-outline rounded-pill py-3 me-2 col-4 text1-4"
                  onClick={handleTabChange}
                  disabled={isSaving}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
