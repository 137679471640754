import http from "./api";

const get = (id) => {
  return http.get(`StudentApplication?StudentApplicationId=${id}`);
};
const create = (data) => {
  return http.post("StudentApplication", data);
};
const update = (data) => {
  return http.put(`StudentApplication`, data);
};
const updateStatus = (data) => {
  return http.post(`StudentApplication/changeApplicationStatus`, data);
};
const find = (params) => {
  return http.get("StudentApplication/search?", { params });
};
const getStudentApplicationByStudentId = (id) => {
  return http.get(
    `StudentApplication/getStudentApplicationByStudentId?studentId=${id}`
  );
};
const getAllStudentApplications_forAllCompaniesAndStudents = (params) => {
  return http.get(
    "StudentApplication/getAllStudentApplications_forAllCompaniesAndStudents?",
    { params }
  );
};
const assignStudentApplicationToEPAssignee = (data) => {
  return http.post(
    "StudentApplication/assignStudentApplicationToEPAssignee",
    data
  );
};
const getStudentApplicationByEPAssigneeUserId = (params) => {
  return http.get(
    `StudentApplication/getStudentApplicationByEPAssigneeUserId`,
    { params }
  );
};
const getStudentApplicationByTeamLeaderUserId = (params) => {
  return http.get(
    `StudentApplication/getStudentApplicationByTeamLeaderUserId`,
    { params }
  );
};
const deleteDraftApplication = (id) => {
  return http.delete(`StudentApplication?id=${id}`);
};
const getCurrency = (id) => {
  return http.get(`Currency/getByCountry?country=${id}`);
};
const ApplicationDataService = {
  get,
  create,
  update,
  updateStatus,
  find,
  getStudentApplicationByStudentId,
  getAllStudentApplications_forAllCompaniesAndStudents,
  assignStudentApplicationToEPAssignee,
  getStudentApplicationByEPAssigneeUserId,
  getStudentApplicationByTeamLeaderUserId,
  deleteDraftApplication,
  getCurrency,
};

export default ApplicationDataService;
