import React from "react";
import { Modal } from "react-bootstrap";
import platformLogo from "../../assets/logo_dark.png";
import newLoader from "../../assets/new-loader.svg"

export default function PaymentProcessing({ paymentProcessing, setPaymentProcessing }) {
  const paymentDone = paymentProcessing.paydone;
  const paymentStart = paymentProcessing.paystart;

  const handlePayClose = (e) => {
    e.preventDefault();
    // Close the payment processing modal
    setPaymentProcessing((prevData) => ({
      ...prevData,
      paystart: false,
      paydone: false,
    }));
  };

  return (
    <>
      <Modal show={paymentStart} backdrop="static" keyboard={false} centered size="md"
        className="custom-modal" >
        <Modal.Body >
          <i className="bi bi-x-circle-fill text2 p-3 text-danger position-absolute end-0 top-0" style={{ cursor: "pointer" }}
            onClick={handlePayClose}></i>
          <div className='d-flex flex-column align-items-center pt-5 gap-5'>

            <img src={platformLogo} alt='platformLogo' height='80px' />
            <p className='text2 text-dark1 fw-bold'>
              {paymentDone ? "Payment Received" : "Receiving Payment"}            </p>
          </div>

          {!paymentDone && (
            <div className="container p-5 bg-white bg-gradient">
              <div className="row align-items-center justify-content-center text-center">
                <div className="col-12">
                  <div className="text1-8 text-light-gray">Please wait while we updating your payment details.</div>
                </div>
                <div className="col-12 my-4">
                  <img src={newLoader} alt="newLoader" height='60px' style={{ animation: 'spin 2s linear infinite' }} />
                </div>

                <div className="col-12">
                  <div className="text1-6 text-danger">
                    Please refrain from refreshing, reloading, or closing this page during the payment process.
                  </div>
                </div>
                <div className="col-12 mt-5">
                  <div className="fs-4 text-light-gray">System will redirect automatically</div>
                </div>
              </div>
            </div>
          )}
          {paymentDone ? (
            // Display a success message after the application is submitted
            <div className="my-4">
              <div className="row align-items-center justify-content-center text-center">
                <div className="text-light-gray text1-4 col-12 mb-2">Application submitted successfully</div>
                <div className="col-3">
                  <button type="button" className="rounded-pill text1-4 w-100 px-5 pt-2 my-4 gradiantButton" onClick={handlePayClose}>
                    Ok
                  </button>
                </div>
              </div>
            </div>
          ) : (
            // Placeholder
            ""
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
