import React, { useCallback, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import platformLogo from "../../assets/logo_dark.png";
import { toast } from "react-toastify";
import ApplicationNoteService from "../../Services/ApplicationNoteService";
import { format } from "date-fns";
import ApplicationDataService from "../../Services/ApplicationService";

const AttachProffModal = ({
  show,
  onHide,
  proofData,
  retrieveApplications,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [proofDocument, setProffDocument] = useState({});

  const handleInputChange = (event) => {
    const { type, files } = event.target;
    if (type === "file") {
      const file = files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setProffDocument({
            fileName: file.name,
            content: reader.result.split(",")[1],
            mimeType: file.type,
          });
        };
        reader.readAsDataURL(file);
      }
    } else {
      setProffDocument({});
    }
  };

  const handleChangeApplicationStatus = useCallback(async () => {
    try {
      const data = {
        id: proofData?.id,
        applicationStatus: "Offer Letter Applied",
        remarks: "",
      };

      const response = await ApplicationDataService.updateStatus(data);

      if (response.data.success === true) {
        toast.success(response.data.message);
      } else if (response.data.validationErrors) {
        response.data.validationErrors.forEach((verr) => toast.error(verr));
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString()
      );
    }
  }, [proofData?.id]);

  const handleSubmitProff = useCallback(async () => {
    try {
      setIsLoading(true);
      const currentDate = new Date();
      const formattedDate = format(currentDate, "dd/MM/yy HH:mm");

      const data = {
        studentApplicationId: proofData?.id,
        note: `Dear ${proofData?.agentName}, This is to inform you that the application you submitted 
                on behalf of ${proofData?.studentFirstName} ${proofData?.studentLastName} has been successfully 
                submitted to ${proofData?.collegeName} on ${formattedDate}`,
        noteType: 0,
        statusId: 0,
        files: {
          [proofDocument?.fileName]: proofDocument?.content,
        },
      };
      const response = await ApplicationNoteService.create(data);
      if (response.data.success === true) {
        await handleChangeApplicationStatus();
      } else if (response.data.validationErrors) {
        response.data.validationErrors.forEach((verr) => toast.error(verr));
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString()
      );
    } finally {
      setIsLoading(false);
      onHide();
      retrieveApplications();
    }
  }, [
    onHide,
    proofData,
    proofDocument,
    handleChangeApplicationStatus,
    retrieveApplications,
  ]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      aria-labelledby="contained-modal-title-vcenter"
      size="md"
      className="custom-modal"
    >
      <Modal.Body>
        <i
          className="bi bi-x-circle-fill text2 p-3 text-danger position-absolute end-0 top-0"
          style={{ cursor: "pointer" }}
          onClick={onHide}
        ></i>
        <div className="d-flex flex-column align-items-center pt-5 gap-5">
          <img src={platformLogo} alt="platformLogo" height="80px" />
          <p className="text2 text-dark1 fw-bold">
            Attach Proof to Application
          </p>
        </div>
        {proofDocument?.fileName ? (
          <div className="d-flex justify-content-center ms-4 mt-2 position-relative">
            {proofDocument?.mimeType?.startsWith("image/") ? (
              <>
                <div className="d-flex flex-column align-items-center gap-3">
                  <img
                    src={`data:${proofDocument?.mimeType};base64,${proofDocument?.content}`}
                    alt="Uploaded"
                    className=""
                    style={{ maxWidth: "100%", maxHeight: "180px" }}
                  />
                  <div className="d-flex align-items-center">
                    <p className="mb-1">{proofDocument?.fileName}</p>
                    <button
                      className="btn btn-link btn-cross"
                      onClick={() => {
                        setProffDocument({});
                      }}
                    >
                      <i class="bi bi-trash-fill text-danger text2"></i>
                    </button>
                  </div>
                </div>
              </>
            ) : (
              proofDocument?.mimeType?.startsWith("application/") && (
                <>
                  <div className="d-flex flex-column justify-content-center align-items-center position-relative gap-3">
                    <button
                      className="btn rounded-4 btn-success d-flex align-items-center justify-content-center text1-4 me-3"
                      type="button"
                      style={{
                        width: "56px",
                        height: "58px",
                        cursor: "default",
                      }}
                    >
                      <i className="bi bi-file-earmark-text text3"></i>
                    </button>

                    <div className="d-flex align-items-center">
                      <p className="mb-1">{proofDocument?.fileName}</p>
                      <button
                        className="btn btn-link btn-cross"
                        onClick={() => {
                          setProffDocument({});
                        }}
                      >
                        <i class="bi bi-trash-fill text-danger text2"></i>
                      </button>
                    </div>
                  </div>
                </>
              )
            )}
          </div>
        ) : (
          <div className="d-flex justify-content-center m-3 mb-5 align-items-center ">
            <div
              className="rounded-4 border p-2 d-flex justify-content-center align-items-center"
              tabIndex="0"
              contentEditable="false"
            >
              <label
                className="text1-5 text-dark1"
                htmlFor="importInput"
                style={{ cursor: "pointer" }}
              >
                Attach Image/Pdf
              </label>
              <label
                className="text1-5"
                htmlFor="importInput"
                style={{ cursor: "pointer" }}
              >
                <i className="bi bi-paperclip text2-5 text-blue"></i>
              </label>

              <input
                id="importInput"
                type="file"
                required
                accept="image/jpeg, image/jpg, image/png, application/pdf"
                className="d-none"
                onChange={handleInputChange}
              />
            </div>
          </div>
        )}
        {proofDocument?.fileName && (
          <div className="d-flex justify-content-center m-4">
            {isLoading ? (
              <Spinner />
            ) : (
              <button
                onClick={handleSubmitProff}
                className="rounded-pill text1-4 px-5 pt-2 gradiantButton"
              >
                Save
              </button>
            )}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AttachProffModal;
