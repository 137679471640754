import React, { useState, useEffect, useRef } from "react";
import Select from "react-select"; // Im

import ProgramSearchService from "../../Services/ProgramSearchService";

import FormatIntkeDate from "../../components/UI/FormatIntkeDate";

export default function SearchAndSelectProgramManually({
  setSelectedResults,
  saveApplicationData,
  selectedIntake,
  setSelectedIntake,
  isNextDisabled,
  setSelectedCampusInApplication,
  setCountry,
  clicked,
}) {
  const [isLoading, setIsLoading] = useState(false);
  // Separate isLoading states for each select component
  const [isLoadingCountry, setIsLoadingCountry] = useState(false);
  const [isLoadingCollege, setIsLoadingCollege] = useState(false);
  const [isLoadingCampus, setIsLoadingCampus] = useState(false);
  const [isLoadingIntake, setIsLoadingIntake] = useState(false);
  const [isLoadingProgram, setIsLoadingProgram] = useState(false);
  const [isClearable, setIsClearable] = useState(true);

  const [advanceDataList, setAdvanceDataList] = useState([]);
  const getAdvanceSearchFilterData = async () => {
    setIsLoadingCountry(true);
    try {
      setAdvanceDataList((await ProgramSearchService.advanceFilterList()).data);
    } catch (e) {
      //console.log(e);
    }
    setIsLoadingCountry(false);
  };
  useEffect(() => {
    getAdvanceSearchFilterData();
  }, []);
  const { countryLst } = advanceDataList;

  const defaultCountry = { value: "", label: "All Countries" };
  const [selectedCountry, setSelectedCountry] = useState("");

  const [countryCollegesList, setCountryCollegesList] = useState();
  const [selectedCollege, setSelectedCollege] = useState("");
  const [collegeCampusList, setCollegeCampusList] = useState([]);
  const [selectedCampus, setSelectedCampus] = useState("");
  const [intakeList, setIntakeList] = useState();

  const [collegeProgramList, setCollegeProgramList] = useState();

  // Create a ref for the country select
  const collegeSelectRef = useRef(null);

  // Function to clear the selected country value
  const clearCollegeValue = () => {
    // Use the ref to clear the country select value
    if (collegeSelectRef.current) {
      collegeSelectRef.current.clearValue();
    }
  };

  const handleCountrySelect = (selectedCountryName) => {
    setCountry(selectedCountryName?.value ?? "");
    if (!selectedCountryName) {
      setSelectedCountry("");
    } else {
      setSelectedCountry(selectedCountryName?.value);
    }
  };

  const getCountryColleges = async () => {
    setIsLoadingCollege(true);
    try {
      setCountryCollegesList(
        (await ProgramSearchService.getCountryColleges(selectedCountry)).data
      );
      setIsLoadingCollege(false);
    } catch (error) {
      // console.log("getCountryColleges ~ error:", error);
    }
  };
  useEffect(() => {
    if (selectedCountry) {
      getCountryColleges();
    }
    setSelectedCollege("");
    setSelectedCampus("");
    setSelectedIntake("");
    setSelectedProgram("");
  }, [selectedCountry]);

  const handleCollegeSelect = (selectedCollegeName) => {
    if (!selectedCollegeName) {
      setSelectedCollege("");
    } else {
      setSelectedCollege(selectedCollegeName.value);
    }
  };

  const getCollegeCampus = async () => {
    setIsLoadingCampus(true);
    try {
      setCollegeCampusList(
        (await ProgramSearchService.getCollegeCampus(selectedCollege)).data
      );
      setIsLoadingCampus(false);
    } catch (error) {
      // console.log("getCollegeCampus ~ error:", error);
    }
  };
  useEffect(() => {
    if (selectedCollege) {
      getCollegeCampus();
    }
    setSelectedCampus("");
    setSelectedIntake("");
    setSelectedProgram("");
  }, [selectedCollege]);

  const handleCampusSelect = (selectedCampus) => {
    setSelectedCampusInApplication(selectedCampus?.value ?? "");
    if (!selectedCampus) {
      setSelectedCampus("");
    } else {
      setSelectedCampus(selectedCampus?.value);
    }
  };

  const campusId = collegeCampusList?.find(
    (campus) => campus.campus === selectedCampus
  )?.id;

  const getIntakeList = async () => {
    setIsLoadingIntake(true);
    try {
      setIntakeList(
        (await ProgramSearchService.getIntakeList(selectedCollege, campusId))
          .data
      );
      setIsLoadingIntake(false);
    } catch (error) {
      // console.log("getIntakeList ~ error:", error);
    }
  };
  useEffect(() => {
    if (selectedCampus && selectedCollege) {
      getIntakeList();
    }
    setSelectedIntake("");
    setSelectedProgram("");
  }, [selectedCampus, selectedCollege]);

  const handleIntakeSelect = (selectedIntake) => {
    if (!selectedIntake) {
      setSelectedIntake([]); // Pass an empty array for undefined, "", or any other falsy value
    } else {
      setSelectedIntake(selectedIntake.value); // Wrap the selected value in an array
    }
  };

  const getCollegeProgramsList = async () => {
    setIsLoadingProgram(true);
    var data = {
      collegeId: selectedCollege,
      collegeCampusId: campusId,
      intake: selectedIntake,
    };
    try {
      setCollegeProgramList(
        (await ProgramSearchService.getCollegeProgramsList(data)).data
      );
      setIsLoadingProgram(false);
    } catch (error) {
      // console.log("getCollegeProgramsList ~ error:", error);
    }
  };
  useEffect(() => {
    if (selectedIntake) {
      getCollegeProgramsList();
    }
    setSelectedProgram("");
  }, [selectedIntake]);

  const [selectedProgram, setSelectedProgram] = useState();

  const handleSearchSelectChange = (program) => {
    setIsClearable(false);
    if (!program) {
      setSelectedProgram("");
    } else {
      setSelectedProgram(program.value);
    }
  };

  const ProgramOptionsForCountry = countryLst?.map((countryName) => ({
    value: countryName,
    label: countryName,
  }));

  const ProgramOptionsForCollegeName = countryCollegesList?.map((data) => ({
    value: data.id,
    label: data.collegeName,
  }));

  const ProgramOptionsForCampus = collegeCampusList?.map((data) => ({
    value: data.campus,
    label: data.campus,
  }));

  const uniqueIntakeDates = intakeList?.reduce((acc, data) => {
    if (data.statusId !== 3) {
      return acc; // Skip items with statusId !== 3
    }

    const date = new Date(data.date);
    const year = date.getFullYear();
    const month = date.getMonth();

    const existing = acc.find((item) => {
      const existingDate = new Date(item.value);
      return (
        existingDate.getFullYear() === year && existingDate.getMonth() === month
      );
    });

    if (!existing) {
      acc.push({
        value: data.date,
        label: <FormatIntkeDate date={data.date} />,
      });
    }

    return acc;
  }, []);

  const ProgramOptionsForCollegeProgramName = collegeProgramList?.map(
    (result) => ({
      value: result.collegeProgramId,
      label: result.collegeProgramName,
      collegeProgramName: result.collegeProgramName,
      collegeId: selectedCollege,
      id: result.collegeProgramId,
      country: selectedCountry,
      campus: selectedCampus,
      intake: selectedIntake,
      statusId: result.statusId,
    })
  );

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "2rem",
      boxShadow: "2px 6px 10px rgba(0, 0, 0, 0.1)",
    }),
  };
  const getProgram = async () => {
    setIsLoading(true);
    try {
      const result = (await ProgramSearchService.get(selectedProgram)).data;

      console.log(
        "🚀 ~ file: SearchAndSelectProgramManually.js:232 ~ getProgram ~ result:",
        result
      );

      setSelectedResults(result);
      setIsLoading(false);
    } catch (error) {
      // console.log("getProgram ~ error:", error);
    }
  };
  useEffect(() => {
    if (selectedProgram) {
      getProgram();
    }
  }, [selectedProgram]);

  return (
    <div>
      <div className="border rounded-4 bg-light-blue1 text1-5 p-3 mb-4">
        <div className="d-flex align-items-center bg-stripped1 border border-top-0 rounded-4 p-3 font-bold  mb-3">
          Add application details manually
        </div>
        <div className="row align-items-center">
          <div className="col-12 position-relative">
            <div className="row g-3 mb-4">
              <div className="col-4 px-3">
                <label htmlFor="Select Country">Select Country</label>
                <Select
                  ref={collegeSelectRef}
                  options={ProgramOptionsForCountry}
                  onChange={(selectedCountryName) =>
                    handleCountrySelect(selectedCountryName)
                  }
                  placeholder="Select Country"
                  styles={customStyles}
                  defaultValue={defaultCountry}
                  isLoading={isLoadingCountry}
                  isClearable={isClearable}
                />
              </div>
              <div className="col-4 px-3">
                <label htmlFor="Select College">Select College</label>
                <Select
                  options={ProgramOptionsForCollegeName}
                  onChange={(selectedCollegeName) =>
                    handleCollegeSelect(selectedCollegeName)
                  }
                  placeholder="Select College"
                  styles={customStyles}
                  isLoading={isLoadingCollege}
                  isClearable={isClearable}
                  isDisabled={selectedCountry === ""}
                />
              </div>
              <div className="col-4 px-3">
                <label htmlFor="Select Campus">Select Campus</label>
                <Select
                  options={ProgramOptionsForCampus}
                  onChange={(selectedCampus) =>
                    handleCampusSelect(selectedCampus)
                  }
                  placeholder="Select Campus"
                  styles={customStyles}
                  isLoading={isLoadingCampus}
                  isClearable={isClearable}
                  isDisabled={selectedCollege === ""}
                />
              </div>
              <div className="col-4 px-3">
                <label htmlFor="Select Intake">Select Intake</label>
                <Select
                  options={uniqueIntakeDates}
                  onChange={(Intake) => handleIntakeSelect(Intake)}
                  placeholder="Select Intake"
                  styles={customStyles}
                  isLoading={isLoadingIntake}
                  isClearable={isClearable}
                  isDisabled={selectedCampus === ""}
                />
              </div>
              <div className="col-4 px-3">
                <label htmlFor="Select Program">Select Program</label>
                <Select
                  options={ProgramOptionsForCollegeProgramName}
                  onChange={(program) => handleSearchSelectChange(program)}
                  placeholder="Search by Program name"
                  styles={customStyles}
                  isLoading={isLoadingProgram}
                  isClearable={isClearable}
                  isDisabled={selectedIntake === ""}
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="btn btn-dark-blue rounded-pill py-3 text1-4"
                disabled={!selectedProgram || isNextDisabled || clicked}
                onClick={saveApplicationData}
              >
                <span>
                  {isLoading ? "Please Wait..." : "Start Selected Program"}
                </span>
                {isLoading && <span className="spinner-border"></span>}
              </button>
              {/* {isNextDisabled ? (
                <p className="ms-3 mt-3 text-danger">
                  Please upload Documents before proceeding
                </p>
              ) : null} */}
            </div>
            <div className="position-absolute top-100 start-50 translate-middle mt-4">
              <div className="d-flex align-items-center justify-content-center">
                <div
                  className="text-center text1-5 bg-orange rounded-circle d-flex align-items-center justify-content-center text-white"
                  style={{ width: "40px", height: "40px" }}
                >
                  OR
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
