import React, { useRef } from "react";

import { useCallback, useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-hot-toast";
import addIcon from "../../../assets/icons/add.svg";

import AnnouncementService from "../../Services/AnnouncementService";
import EditImage from "./EditImage";

export default function EditAnnouncement({ getData, data, pageTitle }) {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const [isImageUpdated, setIsImageUpdated] = useState(false);
  const handleShow = () => setShow(true);
  const [validated, setValidated] = useState(false);
  const fileInputRef = useRef(null);

  const [bannerData, setBannerData] = useState({
    ...data,
  });
  const [IsSaving, setIsSaving] = useState(false);
  const [imageError, setImageError] = useState("");

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      setValidated(true);

      if (form.checkValidity() === false) {
        event.stopPropagation();
        return;
      }

      if (!bannerData.bannerImageContent && !bannerData?.bannerImageUrl) {
        setImageError("Please upload image");
        event.stopPropagation();
        return;
      }

      setIsSaving(true);
      try {
        const loadingMessage = toast.loading("Updating...");
        var payload;
        if (isImageUpdated) {
          payload = {
            collegeName: bannerData.collegeName,
            description: bannerData.description,
            Id: bannerData.id,
            bannerImageContent: bannerData.bannerImageContent,
            bannerName: bannerData.bannerName,
            isBannerImageUpdated: isImageUpdated,
          };
        } else {
          payload = {
            collegeName: bannerData.collegeName,
            description: bannerData.description,
            Id: bannerData.id,
            bannerImageContent: null,
            bannerName: bannerData.bannerName,
            isBannerImageUpdated: isImageUpdated,
          };
        }

        const response = await AnnouncementService.update(payload);

        if (response.data.success === true) {
          getData();
          toast.success(response.data.message, { id: loadingMessage });
          setShow(false);
          setIsSaving(false);
        } else if (
          response.data.success === false &&
          response.data.response === false
        ) {
          toast.error(response.data.message, { id: loadingMessage });
          setIsSaving(false);
        } else if (
          response.data.success === false &&
          response.data.validationErrors.length > 0
        ) {
          response.data.validationErrors.map((verr) =>
            toast.error(verr, { id: loadingMessage })
          );
          setIsSaving(false);
        } else {
          setIsSaving(false);
          toast.error("Something Went Wrong", { id: loadingMessage });
        }
      } catch (error) {
        console.log(error);
        setIsSaving(false);
        toast.error("An error occurred");
      }
    },
    [bannerData, getData, isImageUpdated]
  );

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setBannerData((prevData) => ({ ...prevData, [name]: value }));
  }, []);

  return (
    <>
      <Button
        variant="outline-warning"
        onClick={handleShow}
        className="w-100 rounded-pill"
      >
        <i className="fs-4 d-flex justify-content-center align-items-center bi bi-pencil-square"></i>
      </Button>

      <Modal show={show} onHide={handleClose} className="mt-5" size="md">
        <Modal.Header closeButton={!IsSaving}>
          <Modal.Title>Edit {pageTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            className="px-3"
          >
            <Row xs={12} md={12} className="g-3">
              <FloatingLabel controlId="floatingInput" label="College Name">
                <Form.Control
                  type="text"
                  placeholder=" College Name"
                  name="collegeName"
                  required
                  value={bannerData.collegeName}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide Name
                </Form.Control.Feedback>
              </FloatingLabel>

              <div className="d-flex gap-0.5 flex-column  justify-content-center w-100">
                <p className="text1-3 text-light-gray d-flex align-items-end mb-0">
                  Description
                </p>
                <FloatingLabel
                  className="w-100"
                  controlId="floatingInput"
                  label=""
                >
                  <Form.Control
                    as="textarea"
                    className="text1-3 pt-2 w-100 "
                    required
                    name="description"
                    value={bannerData.description}
                    style={{ height: "100px" }}
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide description
                  </Form.Control.Feedback>
                </FloatingLabel>
              </div>

              <div className="d-flex flex-column gap-1 justify-content-center">
                <p className="text1-3 text-light-gray">Edit Image</p>
                <EditImage
                  setBannerData={setBannerData}
                  bannerData={bannerData}
                  fileInputRef={fileInputRef}
                  setUpdated={setIsImageUpdated}
                  updated={isImageUpdated}
                  setImageError={setImageError}
                />
                {imageError ? (
                  <div className="d-flex gap-1 align-items-center">
                    <i class="bi bi-exclamation-circle text-danger"></i>
                    <span className="text1-3 text-danger">{imageError}</span>
                  </div>
                ) : null}
              </div>

              <Modal.Footer className="mt-4">
                <Button
                  variant="secondary"
                  onClick={handleClose}
                  disabled={IsSaving}
                >
                  Close
                </Button>
                <Button type="submit" variant="success" disabled={IsSaving}>
                  Update
                </Button>
              </Modal.Footer>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
