import React, { useState, useEffect, useCallback } from "react";
import CollegeDataService from "../../Services/CollegeService";
import companyLogo from "../../assets/logo_dark.png";
import { toast } from "react-toastify";

export default function LogoView({ college }) {
  const collegeId = college.id;
  const cLogo = college.logoName;
  const dLogo = college.detailPageLogoName;
  const [imageURL, setImageURL] = useState({
    collegeLogoUrl: "",
    detailPageCollegeLogoUrl: "",
  });
  const getCollegeLogo = useCallback(async () => {
    try {
      if (cLogo || dLogo) {
        const response = await CollegeDataService.downloadCollegeLogo(
          collegeId
        );

        setImageURL(response?.data?.response);
      }
    } catch (error) {
      toast.error(error);
    }
  }, [cLogo, collegeId, dLogo]);

  useEffect(() => {
    getCollegeLogo();
  }, [getCollegeLogo]);

  return (
    <>
      <td>
        {imageURL.collegeLogoUrl ? (
          <div
            style={{ width: "20rem", height: "14rem" }}
            className="d-flex justify-content-center align-items-center rounded-4 "
          >
            <img
              src={imageURL.collegeLogoUrl}
              alt={cLogo}
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
            />
          </div>
        ) : (
          "Logo not available"
        )}
      </td>
      <td>
        {imageURL.detailPageCollegeLogoUrl ? (
          <div
            style={{ width: "20rem", height: "14rem" }}
            className="d-flex justify-content-center align-items-center rounded-4 "
          >
            <img
              src={imageURL.detailPageCollegeLogoUrl}
              alt={dLogo}
              className="rounded-4"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
            />
          </div>
        ) : (
          "Logo not available"
        )}
      </td>
    </>
  );
}
