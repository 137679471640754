import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import Header from "../../layout/Header";
import AppFooter from "../Main/Section8";
import BlogDataService from "../../Services/BlogService";
import CopyToClipboardButton from "../../components/CopyToClipboardButton";
import FormatDOB from "../../components/UI/FormatDOB";
import EpLoader from "../../components/UI/EpLoader";
import BlogViewMobile from "./BlogViewMobile";

const pageSize = 5;
const pageTitle = "View Blog";

export default function BlogView() {
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [id]);

  const currentUrl = window.location.href;

  const [loading, setLoading] = useState(true);
  const [getDataFailed, setgetDataFailed] = useState(false);
  const [blogData, setBlogData] = useState({});
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, settotalPages] = useState(0);

  const [Blogs, setBlogs] = useState([]);

  const handleGetBlogs = async () => {
    const data = {
      search: "",
      pageSize: pageSize,
      currentPage: currentPage,
      statusId: 1,
    };
    try {
      const response = (await BlogDataService.find(data)).data;
      settotalPages(Math.ceil(response[0].totalItems / pageSize));
      setBlogs(response);
    } catch (error) {
      setgetDataFailed(true);
    }
  };

  useEffect(() => {
    handleGetBlogs();
  }, [currentPage]);

  const handleGetBlogById = async () => {
    try {
      const response = await BlogDataService.get(id);
      if (response.status !== 200) {
        setgetDataFailed(true);
        document.title = pageTitle;
      } else {
        setBlogData(response.data);
        document.title = response.data.title;
      }
    } catch (error) {
      setgetDataFailed(true);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (id) {
      handleGetBlogById();
    }
  }, [id]);

  if (loading) {
    return <EpLoader />;
  }

  if (getDataFailed) {
    return (
      <>
        <Header />
        <div
          className="d-flex justify-content-center align-items-center d-print-none fs-1"
          style={{ paddingTop: "200px" }}
        >
          Invalid or Link expired
        </div>
      </>
    );
  }

  return (
    <div>
      <Header />
      <div
        className="container d-none d-sm-block "
        style={{ paddingTop: "120px" }}
      >
        <div className="row justify-content-center">
          <div className="col-10">
            <div className="position-relative w-100">
              <img
                src={blogData.image}
                className="img-fluid w-100 rounded-top-4"
                alt="blog pic"
                style={{ height: "400px" }}
              />
              <div className="black-gradient-overlay"></div>
              <div className="position-relative mx-5 text-white">
                <div className="position-absolute bottom-0 start-0 mb-3">
                  <div className="text2-5 mx-4">{blogData.title}</div>
                  <div className="text1-3">
                    <i className="bi bi-calendar3 me-1"></i>Posted on{" "}
                    <FormatDOB date={blogData.createdDate} />
                  </div>
                </div>
                <div className="position-absolute bottom-0 end-0 mb-3">
                  <div className="text1-4">
                    <div className="d-flex justify-content-center align-items-center mx-3">
                      <div className="btn-group dropstart">
                        <div
                          className="text-white pt-3"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{
                            width: "48px",
                            height: "48px",
                            cursor: "pointer",
                          }}
                        >
                          <i className="bi bi-share-fill fs-2 d-flex justify-content-center align-items-center" />
                        </div>
                        <ul className="dropdown-menu border-0 p-2 rounded-pill bg-light bg-opacity-75 shadow">
                          <div className="d-flex">
                            <CopyToClipboardButton textToCopy={currentUrl} />
                            <EmailShareButton
                              url={currentUrl}
                              subject={blogData.title}
                              body={`Created on ${blogData.date} by Education Planner`}
                              className="me-1"
                            >
                              <EmailIcon size={36} round={true} />
                            </EmailShareButton>
                            <FacebookShareButton
                              url={currentUrl}
                              subject={blogData.title}
                              body={`Created on ${blogData.date} by Education Planner`}
                              className="me-1"
                            >
                              <FacebookIcon size={36} round={true} />
                            </FacebookShareButton>
                            <LinkedinShareButton
                              url={currentUrl}
                              subject={blogData.title}
                              body={`Created on ${blogData.date} by Education Planner`}
                              className="me-1"
                            >
                              <LinkedinIcon size={36} round={true} />
                            </LinkedinShareButton>
                            <TwitterShareButton
                              url={currentUrl}
                              subject={blogData.title}
                              body={`Created on ${blogData.date} by Education Planner`}
                              className="me-1"
                            >
                              <TwitterIcon size={36} round={true} />
                            </TwitterShareButton>
                            <WhatsappShareButton
                              url={currentUrl}
                              subject={blogData.title}
                              body={`Created on ${blogData.date} by Education Planner`}
                              className="me-1"
                            >
                              <WhatsappIcon size={36} round={true} />
                            </WhatsappShareButton>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="p-5 fs-3 mt-3 text-break">
              {blogData.description}
            </div>
          </div>
          <div className="col-4">
            <div className="mt-4">
              <div className="col-12 mb-1">
                <div className="d-flex justify-content-center">
                  <button
                    className="btn bg-light0 rounded-circle d-flex justify-content-center align-items-center"
                    onClick={() => setcurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                    style={{ width: "16px", height: "16px" }}
                  >
                    <i className="bi bi-caret-left-fill text-dark1 d-flex justify-content-center align-items-center"></i>
                  </button>
                  <button
                    className="btn bg-light0 rounded-circle ms-2 d-flex justify-content-center align-items-center"
                    onClick={() => setcurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    style={{ width: "16px", height: "16px" }}
                  >
                    <i className="bi bi-caret-right-fill text-dark1 d-flex justify-content-center align-items-center"></i>
                  </button>
                </div>
              </div>
              {Blogs.map((blog, index) => (
                <div className="card mb-3" key={index}>
                  <div className="row g-0 align-items-center border-bottom border-3 border-primary rounded-2">
                    <div className="col-md-4 p-2">
                      <img
                        src={blog.image}
                        className="img-fluid rounded shadow w-100"
                        alt="blog pics"
                        style={{ height: "100px" }}
                      />
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <div className="card-title text1-3 fw-bold m-0">
                          {blog.title}
                        </div>

                        <div className="card-text text1-2 d-flex justify-content-between mt-2">
                          <div className="text-body-secondary me-1">
                            Posted On: <FormatDOB date={blog.createdDate} />
                          </div>
                          <div>
                            <a
                              className="text-dark link-offset-2 link-offset-3-hover link-underline text1-2"
                              href={`/#/blogView/${blog.id}`}
                            >
                              Read More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <BlogViewMobile
        currentPage={currentPage}
        setcurrentPage={setcurrentPage}
        totalPages={totalPages}
        Blogs={Blogs}
        blogData={blogData}
      />

      <AppFooter />
    </div>
  );
}
