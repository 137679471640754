import React from "react";

export default function FloatingWhatsAppButton() {
  return (
    <>
      <a href="https://api.whatsapp.com/send?phone=919914100042&text=Hi" target="_blank" rel="noreferrer" className="d-print-none">
        <i
          className={`floating-button rounded-circle d-flex justify-content-center align-items-center bi bi-whatsapp text2-5`}
          style={{ width: "52px", height: "52px", backgroundColor: "#25D366" }}
        ></i>
      </a>
    </>
  );
}
