import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import sortIcon from "../../assets/icons/sort.svg";
import UserPanel from "../UserPanel";
import Pagination from "../../components/UI/Pagination";
import SearchBar from "../../components/UI/SearchBar";
import SelectItemsPerPage from "../../components/UI/SelectItemsPerPage";
import Updating from "../../components/UI/Updating";
import AgentsDataService from "../../Services/AgentsService";
import AgentEdit from "./AgentEdit";
import TokenService from "../../Services/TokenService";
import RolesService from "../../Services/RolesService";
import Tooltip from "../../components/UI/ToolTip";
import UserDataService from "../../Services/UserService";
import { toast } from "react-toastify";
import PageTitle from "../../components/PageTitle";
import EpLoader from "../../components/UI/EpLoader";
import { useNavigate } from "react-router-dom";

const pageTitle = "Agents List";

export default function AgentsList() {
  const getRole = TokenService.getUserRole();
  const getUser = TokenService.getUser();
  const { AgencyManager, SuperAdmin, TeamLeader, Accounts } = RolesService;
  const [TeamLeaderUsers, setTeamLeaderUsers] = useState();
  const retrieveTeamLeaderUsers = () => {
    UserDataService.getTeamLeader().then((response) => {
      setTeamLeaderUsers(response.data);
    });
  };
  useEffect(() => {
    retrieveTeamLeaderUsers();
  }, []);

  const navigate = useNavigate()

  const [agentsList, setAgentsList] = useState([]);
  console.log(agentsList);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setUpdating] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [teamLeaderUserId, setTeamLeaderUserId] = useState();
  const [totalItems, setTotalItems] = useState(0);

  const getRequestParams = (
    searchQuery,
    teamLeaderUserId,
    currentPage,
    itemsPerPage
  ) => {
    let params = {};

    if (searchQuery) {
      params["search"] = searchQuery;
    }
    if (teamLeaderUserId) {
      params["teamLeaderUserId"] = teamLeaderUserId;
    }
    if (currentPage) {
      params["currentPage"] = currentPage;
    }
    if (itemsPerPage) {
      params["pageSize"] = itemsPerPage;
    }

    return params;
  };

  const handleTeamLeaderUserChange = (aID, tlID) => {
    setUpdating(true);
    var data = {
      companyId: aID,
      teamLeaderUserId: tlID,
    };
    AgentsDataService.assignCompanyToTeamLeader(data)
      .then((response) => {
        if (response.data.success === true) {
          toast.success(response.data.message);
          retrieveAgents();
          setUpdating(false);
        } else if (response?.data?.validationErrors) {
          response.data.validationErrors.map((verr) => toast.error(verr));
          setUpdating(false);
        } else {
          setUpdating(false);
          toast.error("Something Went Wrong");
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const retrieveAgents = async () => {
    const params = getRequestParams(
      searchQuery,
      teamLeaderUserId,
      currentPage,
      itemsPerPage
    );

    let response;
    try {
      if (getRole === TeamLeader) {
        response =
          await AgentsDataService.getCompaniesandBranchHeadByTeamLeaderId(
            params
          );
        setAgentsList(response.data);
        setTotalItems(response.data[0]?.totalItems);
      } else {
        response = await AgentsDataService.getAllCompaniesandBranchHead(params);
        setAgentsList(response.data.response);
        setTotalItems(response.data.response[0]?.totalItems);
      }

      setIsLoading(false);
    } catch (error) {
      toast.error("Error retrieving agents:", error);
    }
  };

  const handlePageSizeChange = (event) => {
    event.preventDefault();
    setItemsPerPage(parseInt(event.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    retrieveAgents();
  }, [searchQuery, teamLeaderUserId, currentPage, itemsPerPage]);

  const handleStatusChange = async (id, status) => {
    try {
      setUpdating(true);
      const data = {
        id: id,
        statusId: parseInt(status),
      };

      const response = await AgentsDataService.updateStatus(data);

      if (response.data.success === true) {
        toast.success(response.data.message);
        retrieveAgents();
      } else if (response.data.validationErrors) {
        response.data.validationErrors.forEach((verr) => toast.error(verr));
      } else {
        toast.error("Something Went Wrong");
      }

      setUpdating(false);
    } catch (error) {
      toast.error(error);
      setUpdating(false);
    }
  };

  const handleTeamLeaderView = (event) => {
    event.preventDefault();
    if (event.target.value === "All") {
      setTeamLeaderUserId();
    } else {
      setTeamLeaderUserId(event.target.value);
    }
    setCurrentPage(1);
  };

  const openModal = (agent) => {
    setSelectedAgent(agent);
    setModalShow(true);
  };
  const closeModal = () => {
    setSelectedAgent(null);
    setModalShow(false);
  };
  // const handleDeleteAgent = (event) => {
  //   event.preventDefault();
  //   setUpdating(true);
  //   const id = event.currentTarget.value;
  //   if (window.confirm("Are you sure you want to delete?")) {
  //     AgentsDataService.remove(id)
  //       .then((response) => {
  //         alert(response.data ? "Delete Successfully" : response.data);
  //         retrieveAgents();
  //         setUpdating(false);
  //       })
  //       .catch((e) => {
  //         setUpdating(false);
  //         alert((e.response && e.response.data && e.response.data.message) || e.message || e.toString());
  //       });
  //   } else {
  //     setUpdating(false);
  //   }
  // };

  if (isLoading) {
    return <EpLoader />;
  }
  return (
    <>
      <PageTitle title={pageTitle} />
      <div className="p-2 mt-2">
        <div className="row align-items-center">
          <div className="col">
            <UserPanel title={pageTitle} />
          </div>
        </div>

        {isUpdating ? (
          <Updating />
        ) : (
          <>
            <div className="row align-items-baseline mt-3 mx-2 mb-1">
              <div className="col d-flex align-items-center column-gap-3">
                <SearchBar
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  searchTitle={"Search Agent"}
                />
              </div>
              <div className="col">
                <div className="d-flex align-items-center justify-content-end text-dark1 column-gap-3">
                  {getRole !== TeamLeader && (
                    <>
                      <div className="text1-4 text-dark1">
                        <img
                          src={sortIcon}
                          alt="sortIcon"
                          className="img-fluid"
                        />
                      </div>
                      <div className="text1-4 text-dark1">
                        <select
                          className="form-select rounded-4 text1-3 py-2"
                          aria-label="team leader"
                          value={teamLeaderUserId}
                          onChange={handleTeamLeaderView}
                        >
                          <option value={"All"}>All</option>
                          {TeamLeaderUsers?.map((user, index) => (
                            <option value={user.id} key={index}>
                              {user.firstName} {user.lastName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </>
                  )}

                  <SelectItemsPerPage
                    handlePageSizeChange={handlePageSizeChange}
                  />
                </div>
              </div>
            </div>
            <div className="mt-3 mb-4">
              {totalItems > 0 && (
                <Pagination
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                  dataLength={totalItems}
                  itemsPerPage={itemsPerPage}
                />
              )}
            </div>
            <div className="row">
              <div className="col-12">
                <div className="table-responsive rounded-4">
                  <table className="table custom-table">
                    <thead className="bg-dark1 text-white text-center">
                      <tr className="py-1 text1-3">
                        <th scope="col">Company Name</th>
                        <th scope="col">Head Name</th>
                        {getRole === Accounts ?
                          <>
                            <th scope="col">Email </th>
                            <th scope="col">Phone Number </th>

                          </> :
                          <>
                            <th scope="col">Contact Details </th>
                            {getRole !== TeamLeader && (
                              <th scope="col">Team Leader</th>
                            )}
                            <th scope="col">Status</th>
                            {/* <th scope="col">Action</th> */}
                            {getRole === SuperAdmin ? (
                              <th scope="col">Balance</th>
                            ) : null}
                          </>}
                      </tr>
                    </thead>
                    {getRole === Accounts ?
                      <tbody className="text1-4">
                        {agentsList.map((agent, index) => (
                          <tr className="text-center" key={index}
                            onClick={() => navigate('/user/profile', {
                              state: agent.companyUsers[0].id,
                            })} style={{ cursor: 'pointer' }}
                          >
                            <td>{agent.name}</td>
                            <td>
                              <div>
                                {agent.companyUsers.map((branchHead) => (
                                  <div key={branchHead.id}>
                                    <div >
                                      {branchHead.firstName}{" "}
                                      {branchHead.lastName}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </td>
                            <td>
                              <div>
                                {agent.companyUsers.map((branchHead) => (
                                  <div key={branchHead.id}>
                                    {branchHead.email}
                                  </div>
                                ))}
                              </div>
                            </td>

                            <td>
                              <div>
                                {agent.companyUsers.map((branchHead) => (
                                  <div key={branchHead.id}>
                                    {branchHead.phoneNumber}
                                  </div>
                                ))}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      :
                      <tbody className="text1-4">
                        {agentsList.map((agent, index) => {
                          if (
                            agent.name === "Master Agent" &&
                            getRole !== SuperAdmin
                          ) {
                            return null;
                          }
                          if (
                            getRole === TeamLeader
                              ? agent.createdBy !== getUser.id
                              : getRole === AgencyManager ||
                              getRole === SuperAdmin
                          ) {
                            return (
                              <tr className="text-center" key={index}>
                                <td>
                                  <div>
                                    {getRole === AgencyManager ||
                                      getRole === SuperAdmin ? (
                                      <div
                                        className="text1-4 fw-bold"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => openModal(agent)}
                                      >
                                        {agent.name}
                                      </div>
                                    ) : (
                                      <div className="text1-4 fw-bold">
                                        {agent.name}
                                      </div>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div
                                    onClick={() => navigate('/user/profile', {
                                      state: agent.companyUsers[0].id,
                                    })} style={{ cursor: 'pointer' }}>
                                    {agent.companyUsers.map((branchHead) => (
                                      <div key={branchHead.id}>
                                        <div className="fw-bold">
                                          {branchHead.firstName}{" "}
                                          {branchHead.lastName}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    {agent.companyUsers.map((branchHead) => (
                                      <div key={branchHead.id}>
                                        <div>
                                          <a
                                            href={`mailto:${branchHead.email}?subject=Education Planner&body=Hi,${branchHead.firstName}`}
                                            className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover text1-4"
                                            target="_blank"
                                          >
                                            {branchHead.email}
                                          </a>
                                        </div>
                                        <div className="">
                                          <a
                                            href={`http://wa.me/${branchHead.phoneNumber}`}
                                            className="link-success link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover text1-4"
                                            target="_blank"
                                          >
                                            {branchHead.phoneNumber}
                                          </a>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </td>
                                {getRole !== TeamLeader && (
                                  <td>
                                    <div className="d-flex justify-content-center align-items-center">
                                      {getRole === AgencyManager ||
                                        getRole === SuperAdmin ? (
                                        <select
                                          className="form-select py-2 rounded-pill text1-4 ps-3 w-50"
                                          aria-label="Team Leader select"
                                          style={{ width: "14rem" }}
                                          value={agent.TeamLeaderUserId}
                                          onChange={(e) =>
                                            handleTeamLeaderUserChange(
                                              agent.id,
                                              e.target.value
                                            )
                                          }
                                          disabled={isUpdating}
                                          defaultValue={agent.TeamLeaderUserId}
                                        >
                                          <option
                                            disabled={
                                              agent.teamLeaderUserId !==
                                              "00000000-0000-0000-0000-000000000000"
                                            }
                                          >
                                            Select Team Leader
                                          </option>

                                          {TeamLeaderUsers?.map((user) => (
                                            <option
                                              value={user.id}
                                              key={user.id}
                                              selected={
                                                user.id === agent.teamLeaderUserId
                                              }
                                            >
                                              {user.firstName} {user.lastName}
                                            </option>
                                          ))}
                                        </select>
                                      ) : (
                                        TeamLeaderUsers?.map((user, index) => {
                                          if (
                                            user.id === agent.teamLeaderUserId
                                          ) {
                                            return (
                                              <Tooltip
                                                tooltipText={user.email}
                                                position="top"
                                                key={index}
                                              >
                                                <span value={user.id}>
                                                  <div>
                                                    {user.firstName}{" "}
                                                    {user.lastName}
                                                  </div>
                                                </span>
                                              </Tooltip>
                                            );
                                          } else {
                                            <span>NO Team Leader selected</span>;
                                          }
                                        })
                                      )}
                                    </div>
                                  </td>
                                )}
                                <td>
                                  {getRole === AgencyManager ||
                                    getRole === SuperAdmin ? (
                                    agent.teamLeaderUserId ===
                                      "00000000-0000-0000-0000-000000000000" ? (
                                      "Select Team Leader First"
                                    ) : agent.id ===
                                      "d5300590-0aad-4c64-bec3-f800c3b9dd4a" ? (
                                      <div>
                                        {agent.statusId ? "Active" : "Inactive"}
                                      </div>
                                    ) : (
                                      <div>
                                        {agent.teamLeaderUserId ? (
                                          <select
                                            className="form-select rounded-pill border text1-4"
                                            aria-label="status"
                                            id="statusId"
                                            value={agent.statusId}
                                            onChange={(e) => {
                                              handleStatusChange(
                                                agent.id,
                                                e.target.value
                                              );
                                            }}
                                            name="statusId"
                                            disabled={
                                              agent.name === "Master Agent"
                                            }
                                          //disabled={getRole !== AgencyManager || getRole !== SuperAdmin}
                                          >
                                            <option disabled>
                                              Select Status
                                            </option>
                                            <option value={1}>Active</option>
                                            <option value={2}>Inactive</option>
                                          </select>
                                        ) : (
                                          "First select Team Leader"
                                        )}
                                      </div>
                                    )
                                  ) : (
                                    <div>
                                      {agent.statusId ? "Active" : "Inactive"}
                                    </div>
                                  )}
                                </td>
                                {getRole === SuperAdmin ? (
                                  <td>{agent.walletBalance.toFixed(2)}</td>
                                ) : null}
                                {/* <td>
                              <div className="text-center">
                                <button className="btn p-0 m-0" onClick={(event) => handleDeleteAgent(event)} value={agent.id}>
                                  <img
                                    src={deleteIcon}
                                    alt="deleteIcon"
                                    className="img-fluid me-1"
                                    style={{ minWidth: "24px", height: "24px" }}
                                  />
                                </button>
                              </div>
                            </td> */}
                              </tr>
                            );
                          }
                        })}
                      </tbody>}
                  </table>
                  {totalItems > 0 && (
                    <Pagination
                      currentPage={currentPage}
                      handlePageChange={handlePageChange}
                      dataLength={totalItems}
                      itemsPerPage={itemsPerPage}
                    />
                  )}
                </div>
              </div>
            </div>
            {selectedAgent && (
              <Modal
                show={modalShow}
                onHide={closeModal}
                centered
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
              >
                <Modal.Header className="bg-dark1 text-white d-flex justify-content-between align-items-center py-2 bg-gradient">
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="d-flex align-items-center"
                  >
                    Agent Details
                  </Modal.Title>
                  <i
                    className="bi bi-x-circle text2 "
                    style={{ cursor: "pointer" }}
                    onClick={() => setModalShow(false)}
                  ></i>
                </Modal.Header>
                <Modal.Body>
                  <AgentEdit
                    agent={selectedAgent}
                    retrieveAgents={retrieveAgents}
                    setModalShow={setModalShow}
                  />
                </Modal.Body>
              </Modal>
            )}
          </>
        )}
      </div>
    </>
  );
}
