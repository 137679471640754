import React from "react";
export default function ApplicationStatusButton({ AppStatus, ApplicationStatusList }) {
  const {
    OfferLetterApplied,
    UnderReview,
    RequireChanges,
    OfferReceived,
    OfferRefused,
    OfferExpired,
    DeferralUnderProcess,
    TuitionFeePaid,
    TuitionFeePending,
    VisaApproved,
    VisaRejected,
    Refund,
    ApplicationClosed_StudentNotQualified,
    ApplicationClosed_FeesNotPaid,
    ApplicationClosed_ProgClosedAppInProcess,
  } = ApplicationStatusList;
  return (
    <>
      <div
        className={`rounded-pill px-3 py-2 text1-2 font-bold mx-auto text-center d-flex align-items-center justify-content-center btn-${
          AppStatus === OfferLetterApplied
            ? "offerLetterApplied"
            : AppStatus === UnderReview
            ? "underReview"
            : AppStatus === RequireChanges
            ? "requireChanges"
            : AppStatus === OfferReceived
            ? "offerReceived"
            : AppStatus === OfferRefused
            ? "offerRefused"
            : AppStatus === OfferExpired
            ? "offerExpired"
            : AppStatus === DeferralUnderProcess
            ? "deferralUnderProcess"
            : AppStatus === TuitionFeePaid
            ? "tuitionFeePaid"
            : AppStatus === TuitionFeePending
            ? "tuitionFeePending"
            : AppStatus === VisaApproved
            ? "visaApproved"
            : AppStatus === VisaRejected
            ? "visaRejected"
            : AppStatus === Refund
            ? "refund"
            : AppStatus === ApplicationClosed_StudentNotQualified
            ? "applicationClosed-StudentNotQualified"
            : AppStatus === ApplicationClosed_FeesNotPaid
            ? "applicationClosed-FeesNotPaid"
            : AppStatus === ApplicationClosed_ProgClosedAppInProcess
            ? "applicationClosed-ProgClosedAppInProcess"
            : "draft"
        }`}
        style={{ width: "180px", minHeight: "44px" }}
      >
        {AppStatus}
      </div>
    </>
  );
}
