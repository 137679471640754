import acadiaImage from "../assets/collegeLogos/acadia.jpg";
import asendaImage from "../assets/collegeLogos/acsenda.jpg";
import adlerImage from "../assets/collegeLogos/adler.jpg";
import alexanderImage from "../assets/collegeLogos/alexander.jpg";
import cambrianHansonImage from "../assets/collegeLogos/cambrian-at-hanson.jpg";
import canadoreImage from "../assets/collegeLogos/canadore.jpg";
import capeBretonImage from "../assets/collegeLogos/cape-breton.jpg";
import capilanoImage from "../assets/collegeLogos/capilano.jpg";
import cityUniImage from "../assets/collegeLogos/city-uni.jpg";
import coastMountainImage from "../assets/collegeLogos/coast-mountain.jpg";
import collegeRockiesImage from "../assets/collegeLogos/college-of-rockies.jpg";
import coquitlamImage from "../assets/collegeLogos/coquitlam.jpg";
import crandalImage from "../assets/collegeLogos/crandal.jpg";
import cumberlandImage from "../assets/collegeLogos/cumberland.jpg";
import greatPlainsImage from "../assets/collegeLogos/great-plains.jpg";
import justiceInstituteImage from "../assets/collegeLogos/justice-institute.jpg";
import keyanoImage from "../assets/collegeLogos/keyano.jpg";
import lakelandImage from "../assets/collegeLogos/lakeland.jpg";
import LasalleImage from "../assets/collegeLogos/Lasalle.jpg";
import laurierImage from "../assets/collegeLogos/laurier.jpg";
import loyalistImage from "../assets/collegeLogos/loyalist.jpg";
import medicineHatImage from "../assets/collegeLogos/medicine-hat.jpg";
import mittImage from "../assets/collegeLogos/mitt.jpg";
import mountAllisonImage from "../assets/collegeLogos/mount-allison.jpg";
import msvuImage from "../assets/collegeLogos/msvu.jpg";
import niagraCollegeImage from "../assets/collegeLogos/niagra-college.jpg";
import nipissiongImage from "../assets/collegeLogos/nipissiong.jpg";
import norquestImage from "../assets/collegeLogos/norquest.jpg";
import northernLightsImage from "../assets/collegeLogos/northern-lights.jpg";
import oldsCollegeImage from "../assets/collegeLogos/olds-college.jpg";
import parklandImage from "../assets/collegeLogos/parkland.jpg";
import portageImage from "../assets/collegeLogos/portage.jpg";
import stThomasImage from "../assets/collegeLogos/St.-Thomas.jpg";
import trinityUniImage from "../assets/collegeLogos/Trinity-Uni.jpg";
import unbcImage from "../assets/collegeLogos/unbc.jpg";
import uniCanadaWestImage from "../assets/collegeLogos/uni-canada-west.jpg";
import uniManitobaImage from "../assets/collegeLogos/uni-of-manitoba.jpg";
import vancouverCommunityCollegeImage from "../assets/collegeLogos/vancouver-community-college.jpg";
import westernCommunityCollegeImage from "../assets/collegeLogos/western-community-college.jpg";

const CollegeLogos = {
  acadiaImage,
  asendaImage,
  adlerImage,
  alexanderImage,
  cambrianHansonImage,
  canadoreImage,
  capeBretonImage,
  capilanoImage,
  cityUniImage,
  coastMountainImage,
  collegeRockiesImage,
  coquitlamImage,
  crandalImage,
  cumberlandImage,
  greatPlainsImage,
  justiceInstituteImage,
  keyanoImage,
  lakelandImage,
  LasalleImage,
  laurierImage,
  loyalistImage,
  medicineHatImage,
  mittImage,
  mountAllisonImage,
  msvuImage,
  niagraCollegeImage,
  nipissiongImage,
  norquestImage,
  northernLightsImage,
  oldsCollegeImage,
  parklandImage,
  portageImage,
  stThomasImage,
  trinityUniImage,
  unbcImage,
  uniCanadaWestImage,
  uniManitobaImage,
  vancouverCommunityCollegeImage,
  westernCommunityCollegeImage,
};

export default CollegeLogos;
