import React from "react";
import { toast } from "react-toastify";
import AustraliaFlag from "../../assets/common/Australia.svg";
import IndiaFlag from "../../assets/common/India.svg";
import Ireland from "../../assets/common/Ireland.svg";
import UKFlag from "../../assets/common/UK.svg";
import USAFlag from "../../assets/common/USA.svg";
import CanadaFlag from "../../assets/common/canada.svg";
import globeIcon from "../../assets/common/globe-asia-australia.svg";
import NewZealandFlag from "../../assets/common/newZealand.svg";

export default function MainSearch({
  searchQuery,
  setSearchQuery,
  setCurrentPage,
  handleHomeProgramSearch,
  pageTitle,
  selectedCountry,
  setSelectedCountry,
  placeholder = "Program/Institute Search",
  isInputClicked,
  setIsInputClicked,
}) {
  const countryList = [
    {
      name: "All",
      value: "All",
      flagImage: globeIcon,
    },
    {
      name: "India",
      value: "India",
      flagImage: IndiaFlag,
    },
    {
      name: "Canada",
      value: "Canada",
      flagImage: CanadaFlag,
    },
    {
      name: "Australia",
      value: "Australia",
      flagImage: AustraliaFlag,
    },
    {
      name: "USA",
      value: "USA",
      flagImage: USAFlag,
    },
    {
      name: "UK",
      value: "UK",
      flagImage: UKFlag,
    },
    {
      name: "New Zealand",
      value: "New Zealand",
      flagImage: NewZealandFlag,
    },
    {
      name: "Ireland",
      value: "Ireland",
      flagImage: Ireland,
    },
  ];

  const handleCountryChange = (value) => {
    setSelectedCountry((prevSelectedOptions) => {
      const allCountries = countryList.map((country) => country.name);

      if (value === "All") {
        if (prevSelectedOptions.length < allCountries.length) {
          // If "All" is selected and not all countries are already selected, select all countries
          return allCountries;
        } else {
          // If "All" is selected and all countries are already selected, deselect all countries
          return ["Canada"];
        }
      }

      if (prevSelectedOptions.includes(value)) {
        // If the option is already selected, deselect it
        const updatedSelectedOptions = prevSelectedOptions.filter(
          (item) => item !== value
        );

        // Check if there are no selected countries left
        if (updatedSelectedOptions.length === 0) {
          toast.info("Minimum one country is required.");
          return prevSelectedOptions; // Prevent deselection
        }

        // If "All" is selected, deselect it
        const updatedOptions = updatedSelectedOptions.filter(
          (item) => item !== "All"
        );
        return updatedOptions;
      } else {
        // If the option is not selected, select it
        return [...prevSelectedOptions, value];
      }
    });
  };

  // Find the flag image based on the selectedCountry value
  const selectedCountryData = countryList?.find((country) =>
    selectedCountry?.includes(country.name)
  );

  const flagImage =
    selectedCountryData && selectedCountry.length === 1
      ? selectedCountryData.flagImage
      : globeIcon;

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    if (setCurrentPage) {
      setCurrentPage(1)
    }
  };
  return (
    <div className="p-1 rounded-pill bg-white shadow order-1 border">
      <div className="">
        <div className="rounded-pill w-100 position-relative d-flex align-items-center">
          <div className="dropdown  w-25 text1-3 border-end">
            <div
              className="dropdown-toggle d-flex align-items-center"
              type="button"
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
              aria-expanded="false"
            >
              <img
                src={flagImage}
                alt="flag"
                className="rounded-circle me-1"
                width={"36px"}
              />
              <span className="text-truncate" style={{ maxWidth: "150px" }}>
                {selectedCountry?.join(", ")}
              </span>
            </div>
            <ul className="dropdown-menu w-100">
              {countryList.map((country) => (
                <div key={country.name}>
                  <li
                    className={`countrySelectedDropDownItem border-top text1-3`}
                    onClick={() => handleCountryChange(country.name)}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div>{country.name}</div>
                      <i
                        className={`bi bi-check fs-3 m-0 p-0 d-flex justify-content-between align-items-center ${selectedCountry?.includes(country.name)
                          ? "text-success"
                          : "text-white"
                          }`}
                      ></i>
                    </div>
                  </li>
                </div>
              ))}
            </ul>
          </div>
          <div
            className="position-relative overflow-hidden w-100 rounded-pill"
            onClick={() => setIsInputClicked(true)}
          >
            {isInputClicked || searchQuery ? null : (
              <span
                className="animated-placeholder position-absolute ms-3 text1-4 opacity-50 w-50 w-sm-100"
                style={{
                  pointerEvents: "none",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {placeholder}
              </span>
            )}
            <input
              type="text"
              className={
                "form-control border-0 ms-2 py-3 formControl text1-4"
              }
              style={{ width: 'calc(100% - 125px)' }}
              value={searchQuery}
              onChange={handleSearch}
              onBlur={() => {
                if (!searchQuery) setIsInputClicked(false);
              }}
            />
          </div>
          <span className="top-50 end-0 position-absolute rounded-pill translate-middle-y">
            <button
              className={`btn ${pageTitle === "Student" ? "btn-student-red" : "btn-dark-blue"
                } rounded-pill px-5 py-3 d-flex align-items-center py-2 text1-3`}
              onClick={handleHomeProgramSearch}
              disabled={searchQuery === ""}
            >
              <span className="me-2">
                <i className="bi bi-search d-flex align-items-center"></i>
              </span>
              Search
            </button>
          </span>
        </div>
      </div>
    </div>
  );
}
