import http from "./api";

const get = (id) => {
  return http.get(`Blog?BlogId=${id}`);
};

const find = (data) => {
  return http.post("Blog/search?", data);
};
const BlogDataService = {
  get,
  find,
};

export default BlogDataService;
