import React from 'react'
import countriesData from "../../Services/CountriesList.json";
import PlaceholderPhoneInput from '../../components/PlaceholderPhone';
import TokenService from '../../Services/TokenService';
import RolesService from '../../Services/RolesService';


const CompanyInfo = ({ setActiveTabNext, profileData, setProfileData, disabled,
    handleInputChange, handleSaveProfileData, isFormEdited, setIsFormEdited }) => {
    const getRole = TokenService.getUserRole()
    const { BranchHead } = RolesService
    const handleTabChange = (e) => {
        e.preventDefault();
        setActiveTabNext("directorInformation");
    };

    return (
        <form className='need-validations'>
            <div className="border rounded-4 bg-light-blue1 text1-5 p-3  mb-4">
                <div className="d-flex align-items-center bg-stripped1 border border-top-0 rounded-4 p-3  mb-3">
                    <span className="font-bold">Trading Details</span>
                </div>
                <div className="col-4 form-floating px-3">
                    <input
                        type="text"
                        className="form-control rounded-pill border shadow"
                        placeholder="Trading Name"
                        required
                        id="tradingName"
                        value={profileData?.tradingName}
                        onChange={handleInputChange}
                        name="tradingName"
                        disabled={disabled}
                    />
                    <label htmlFor="floatingInput" className="ms-4">
                        Trading Name<span className="text-danger">*</span>
                    </label>
                </div>
            </div>

            <div className="border rounded-4 bg-light-blue1 text1-5 p-3 mb-4">
                <div className="d-flex align-items-center bg-stripped1 border border-top-0 rounded-4 p-3  mb-3">
                    <span className="font-bold">Address</span>
                </div>
                <div className="row align-items-center">
                    <div className="col-12">
                        <div className="row g-3">
                            <div className="col-4 form-floating px-3">
                                <textarea
                                    className="form-control rounded-4 border shadow h-100 text1-4"
                                    placeholder="Mailing Address"
                                    required
                                    id="address"
                                    value={profileData?.address}
                                    onChange={handleInputChange}
                                    name="address"
                                    disabled={disabled}
                                />
                                <label htmlFor="floatingTextarea" className="ms-4">
                                    Address
                                    <span className="text-danger">*</span>
                                </label>
                            </div>

                            <div className="col-8 mb-3">
                                <div className="row g-3">
                                    <div className="col-6 form-floating px-3">
                                        <select
                                            className={`form-select rounded-pill border shadow`}
                                            aria-label="Country"
                                            value={profileData?.country}
                                            onChange={handleInputChange}
                                            name="country"
                                            required
                                            disabled={disabled}
                                        >
                                            <option value="">Country</option>
                                            {countriesData.map((country) => (
                                                <option key={country.code} value={country.name}>
                                                    {country.name}
                                                </option>
                                            ))}
                                        </select>
                                        <label htmlFor="floatingInput" className="ms-4">
                                            Country
                                        </label>
                                    </div>
                                    <div className="col-6 form-floating px-3">
                                        <input
                                            type="text"
                                            className="form-control rounded-pill border shadow"
                                            placeholder="City"
                                            required
                                            id="city"
                                            value={profileData?.city}
                                            onChange={handleInputChange}
                                            name="city"
                                            autoComplete="off"
                                            disabled={disabled}
                                        />
                                        <label htmlFor="floatingInput" className="ms-4">
                                            City
                                        </label>
                                    </div>
                                    <div className="col-6 form-floating px-3">
                                        <input
                                            type="text"
                                            className="form-control rounded-pill border shadow"
                                            placeholder="State"
                                            required
                                            id="state"
                                            value={profileData?.state}
                                            onChange={handleInputChange}
                                            name="state"
                                            autoComplete="off"
                                            disabled={disabled}
                                        />
                                        <label htmlFor="floatingInput" className="ms-4">
                                            State
                                        </label>
                                    </div>
                                    <div className="col-6 form-floating px-3">
                                        <input
                                            type="text"
                                            className="form-control rounded-pill border shadow"
                                            placeholder="Province"
                                            required
                                            id="province"
                                            value={profileData?.province}
                                            onChange={handleInputChange}
                                            name="province"
                                            autoComplete="off"
                                            disabled={disabled}
                                        />
                                        <label htmlFor="floatingInput" className="ms-4">
                                            Province
                                        </label>
                                    </div>

                                </div>
                            </div>
                            <div className="col-4 form-floating px-3">
                                <input
                                    type="number"
                                    className="form-control rounded-pill border shadow"
                                    placeholder="Postal Code"
                                    required
                                    id="postalCode"
                                    value={profileData?.postalCode}
                                    onChange={handleInputChange}
                                    name="postalCode"
                                    autoComplete="off"
                                    disabled={disabled}
                                />
                                <label htmlFor="floatingInput" className="ms-4">
                                    Postal Code
                                </label>
                            </div>
                            <div className="col-4 form-floating px-3">
                                <PlaceholderPhoneInput
                                    placeholder='Telephone'
                                    value={profileData?.telephone}
                                    disabled={disabled}
                                    onChange={(phoneNo) => {
                                        setIsFormEdited(false)
                                        setProfileData((prev) => ({
                                            ...prev,
                                            telephone: phoneNo
                                        }));
                                    }}
                                />
                            </div>
                            <div className="col-4 form-floating px-3">
                                <input
                                    type="text"
                                    className="form-control rounded-pill border shadow"
                                    placeholder="Company type"
                                    required
                                    id="companyType"
                                    value={profileData?.companyType}
                                    onChange={handleInputChange}
                                    name="companyType"
                                    autoComplete="off"
                                    disabled={disabled}
                                />
                                <label htmlFor="floatingInput" className="ms-4">
                                    Company type
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="border rounded-4 bg-light-blue1 text1-5 p-3 mb-4">
                <div className="d-flex align-items-center bg-stripped1 border border-top-0 rounded-4 p-3  mb-3">
                    <span className="font-bold">Social presence</span>

                </div>
                <div className="row align-items-center">
                    <div className="col-12">
                        <div className="row g-3">
                            <div className="col-4 form-floating px-3">
                                <input
                                    type="text"
                                    className="form-control rounded-pill border shadow"
                                    placeholder="Skype ID"
                                    required
                                    id="skypeId"
                                    value={profileData?.skypeId}
                                    onChange={handleInputChange}
                                    name="skypeId"
                                    autoComplete="off"
                                    disabled={disabled}
                                />
                                <label htmlFor="floatingInput" className="ms-4">
                                    Skype ID
                                </label>
                            </div>
                            <div className="col-4 form-floating px-3">
                                <input
                                    type="text"
                                    className="form-control rounded-pill border shadow"
                                    placeholder="Twitter ID"
                                    required
                                    id="twitterId"
                                    value={profileData?.twitterId}
                                    onChange={handleInputChange}
                                    name="twitterId"
                                    autoComplete="off"
                                    disabled={disabled}
                                />
                                <label htmlFor="floatingInput" className="ms-4">
                                    Twitter ID
                                </label>
                            </div>
                            <div className="col-4 form-floating px-3">
                                <input
                                    type="text"
                                    className="form-control rounded-pill border shadow"
                                    placeholder="Facebook ID"
                                    required
                                    id="facebookId"
                                    value={profileData?.facebookId}
                                    onChange={handleInputChange}
                                    name="facebookId"
                                    autoComplete="off"
                                    disabled={disabled}
                                />
                                <label htmlFor="floatingInput" className="ms-4">
                                    Facebook ID
                                </label>
                            </div>
                            <div className="col-4 form-floating px-3">
                                <input
                                    type="text"
                                    className="form-control rounded-pill border shadow"
                                    placeholder="Instagram ID"
                                    required
                                    id="instagramId"
                                    value={profileData?.instagramId}
                                    onChange={handleInputChange}
                                    name="instagramId"
                                    autoComplete="off"
                                    disabled={disabled}
                                />
                                <label htmlFor="floatingInput" className="ms-4">
                                    Instagram ID
                                </label>
                            </div>
                            <div className="col-4 form-floating px-3">
                                <PlaceholderPhoneInput
                                    placeholder='Whatsapp Number'
                                    value={profileData?.whatsappNumber}
                                    disabled={disabled}
                                    onChange={(phoneNo) => {
                                        setIsFormEdited(false);
                                        setProfileData((prev) => ({
                                            ...prev,
                                            whatsappNumber: phoneNo
                                        }))
                                    }}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-end my-2 mt-5">
                {getRole === BranchHead ?
                    <button
                        type="button"
                        className="btn btn-light-blue-outline rounded-pill py-3 me-2 px-5 text1-4"
                        onClick={handleSaveProfileData}
                        disabled={disabled || isFormEdited}
                    >
                        Save
                    </button>
                    : null}
                <button
                    type="button"
                    className="btn btn-light-blue-outline rounded-pill py-3  me-2 px-5 text1-4"
                    onClick={handleTabChange}
                >
                    Next
                </button>

            </div>

        </form>
    )
}

export default CompanyInfo
