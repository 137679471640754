import "./SideMenuButton.css";

const SideMenuButton = ({ handleSidebarToggle, sidebarActive }) => {
  return (
    <div
      className={`sidemenubutton bg-orange rounded-circle d-flex justify-content-center align-items-center position-absolute top-50 start-0 translate-middle ${
        sidebarActive ? "" : "is-active"
      }`}
      onClick={handleSidebarToggle}
      style={{ width: "38px", height: "38px" }}
    >
      <div className="sidemenubutton__container">
        <div className="sidemenubutton__inner"></div>
        <div className="sidemenubutton__hidden"></div>
      </div>
    </div>
  );
};

export default SideMenuButton;
