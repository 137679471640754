import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import addIcon from "../../assets/icons/add_role.svg";
import PhoneNumberInput from "../../components/PhoneNumberInput";
import LeadDataService from "../../Services/LeadService";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
export default function LeadAdd({ retrieveLeads }) {
  const [modalShow, setModalShow] = useState(false);
  const inilizeLeadData = {
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    gender: "",
    citizenship: "",
    contactNumber: "",
    email: "",
    currentCountry: "",
    postalCode: "",
    qualification: "",
    servicesofInterest: "",
    countryofInterest: "",
    referralSource: "",
    previousRejection: false,
    statusId: "",
  };
  const [leadData, setLeadData] = useState(inilizeLeadData);

  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    setLeadData((prevData) => ({
      ...prevData,
      [name]: name === "previousRejection" ? checked : value,
    }));
  };
  const [resetPhoneNumber, setResetPhoneNumber] = useState(false);
  const handlePhoneNumberChange = (value) => {
    if (value) {
      leadData.contactNumber = value;
    }
  };

  const saveLeadData = async (e) => {
    e.preventDefault();
    if (!leadData.referralSource) {
      return toast.warn("please enter referral source");
    }
    const data = { ...leadData, statusId: 1 };

    try {
      const response = await LeadDataService.create(data);
      retrieveLeads();
      setModalShow(false);
      toast.success(response.data.message);
      setLeadData(inilizeLeadData);
      setResetPhoneNumber(true);
    } catch (error) {
      toast.error(error);
    }
  };

  const convertUTCToLocalDate = (date) => {
    if (!date) {
      return date;
    }
    const localOffset = new Date().getTimezoneOffset();
    const utcDate = new Date(date);
    const localDate = new Date(utcDate.getTime() - localOffset);
    return localDate;
  };
  const handleDateChange = (value) => {
    const utcDate = new Date(
      value.getTime() - value.getTimezoneOffset() * 60 * 1000
    );
    handleInputChange({
      target: { name: "dateOfBirth", value: utcDate.toISOString() },
    });
  };

  return (
    <>
      <button
        className="btn bg-dark1 d-flex justify-content-center align-items-center rounded-pill text-white text1-4 py-2 px-4"
        onClick={() => setModalShow(true)}
      >
        <img
          src={addIcon}
          alt="addLead"
          className="img-fluid me-1 mb-1"
          style={{ width: "16px", height: "16px" }}
        />{" "}
        Add New Lead
      </button>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        centered
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header className="bg-dark1 text-white d-flex justify-content-between align-items-center py-2 bg-gradient">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="d-flex align-items-center"
          >
            Add New Lead
          </Modal.Title>
          <i
            className="bi bi-x-circle text2"
            style={{ cursor: "pointer" }}
            onClick={() => setModalShow(false)}
          ></i>
        </Modal.Header>
        <Modal.Body>
          <form className="needs-validation" onSubmit={saveLeadData}>
            <div className="row g-3 p-3">
              <div className="col-6 form-floating px-3">
                <input
                  type="text"
                  className="form-control rounded-pill border"
                  placeholder="First name*"
                  required
                  id="firstName"
                  value={leadData.firstName}
                  onChange={handleInputChange}
                  name="firstName"
                  autoComplete="off"
                />
                <label
                  htmlFor="floatingInput"
                  className="ms-4 text-light-gray "
                >
                  First Name<span className="text-danger">*</span>
                </label>
              </div>
              <div className="col-6 form-floating px-3">
                <input
                  type="text"
                  className="form-control rounded-pill border"
                  placeholder="Last name"
                  required
                  id="lastName"
                  value={leadData.lastName}
                  onChange={handleInputChange}
                  name="lastName"
                  autoComplete="off"
                />
                <label htmlFor="floatingInput" className="ms-4 text-light-gray">
                  Last Name<span className="text-danger">*</span>
                </label>
              </div>
              <div className="col-6 form-floating px-3">
                <div className="customDatePickerWidth">
                  <DatePicker
                    className="form-control rounded-pill border text1-4 px-4 py-2"
                    selected={convertUTCToLocalDate(leadData.dateOfBirth)}
                    onChange={(value) => handleDateChange(value)}
                    dateFormat="dd-MM-yyyy"
                    name="dateOfBirth"
                    required
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="DOB"
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="col-6 form-floating px-3">
                <select
                  className="form-select rounded-pill border"
                  aria-label="Gender"
                  required
                  id="gender"
                  value={leadData.gender}
                  onChange={handleInputChange}
                  name="gender"
                >
                  <option value="">Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
                <label htmlFor="floatingInput" className="ms-4 text-light-gray">
                  Gender<span className="text-danger">*</span>
                </label>
              </div>

              <div className="col-6 form-floating px-3">
                <input
                  type="text"
                  className="form-control rounded-pill border"
                  placeholder="Country of Citizenship"
                  required
                  id="citizenship"
                  value={leadData.citizenship}
                  onChange={handleInputChange}
                  name="citizenship"
                  autoComplete="off"
                />
                <label htmlFor="floatingInput" className="ms-4 text-light-gray">
                  Country of Citizenship
                  <span className="text-danger">*</span>
                </label>
              </div>

              <div className="col-6 form-floating px-3">
                <PhoneNumberInput
                  customCSS={""}
                  value={leadData.contactNumber}
                  onChange={handlePhoneNumberChange}
                  resetPhoneNumber={resetPhoneNumber}
                />
              </div>
              <div className="col-6 form-floating px-3">
                <input
                  type="email"
                  className="form-control rounded-pill border"
                  placeholder="Email"
                  required
                  id="email"
                  value={leadData.email}
                  onChange={handleInputChange}
                  name="email"
                  autoComplete="off"
                  pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                />
                <label htmlFor="floatingInput" className="ms-4 text-light-gray">
                  Email<span className="text-danger">*</span>
                </label>
              </div>
              <div className="col-6 form-floating px-3">
                <input
                  type="text"
                  className="form-control rounded-pill border"
                  placeholder="Current Country"
                  required
                  id="currentCountry"
                  value={leadData.currentCountry}
                  onChange={handleInputChange}
                  name="currentCountry"
                  autoComplete="off"
                />
                <label htmlFor="floatingInput" className="ms-4 text-light-gray">
                  Current Country<span className="text-danger">*</span>
                </label>
              </div>

              <div className="col-6 form-floating px-3">
                <input
                  type="text"
                  className="form-control rounded-pill border"
                  placeholder="Postal Code"
                  required
                  id="postalCode"
                  value={leadData.postalCode}
                  onChange={handleInputChange}
                  name="postalCode"
                  autoComplete="off"
                />
                <label htmlFor="floatingInput" className="ms-4 text-light-gray">
                  Postal Code<span className="text-danger">*</span>
                </label>
              </div>

              <div className="col-6 form-floating px-3">
                <input
                  type="text"
                  className="form-control rounded-pill border"
                  placeholder="Highest qualification"
                  required
                  id="qualification"
                  value={leadData.qualification}
                  onChange={handleInputChange}
                  name="qualification"
                  autoComplete="off"
                />
                <label htmlFor="floatingInput" className="ms-4 text-light-gray">
                  Highest qualification
                  <span className="text-danger">*</span>
                </label>
              </div>

              <div className="col-6 form-floating px-3">
                <select
                  className="form-select rounded-pill border"
                  aria-label="Services of Interest"
                  required
                  id="servicesofInterest"
                  value={leadData.servicesofInterest}
                  onChange={handleInputChange}
                  name="servicesofInterest"
                  autoComplete="off"
                >
                  <option value="" disabled>
                    Services of Interest
                  </option>
                  <option value="IELTS">IELTS</option>
                  <option value="PTE">PTE</option>
                  <option value="Study visa">Study visa</option>
                  <option value="Spouse open work permit">
                    Spouse open work permit
                  </option>
                  <option value="Visitor visa">Visitor visa</option>
                </select>
                <label htmlFor="floatingInput" className="ms-4 text-light-gray">
                  Services of Interest<span className="text-danger">*</span>
                </label>
              </div>

              <div className="col-6 form-floating px-3">
                <select
                  className="form-select rounded-pill border"
                  aria-label="Country of Interest"
                  required
                  id="countryofInterest"
                  value={leadData.countryofInterest}
                  onChange={handleInputChange}
                  name="countryofInterest"
                  autoComplete="off"
                >
                  <option value="" disabled>
                    Country of Interest
                  </option>
                  <option value="Australia">Australia</option>
                  <option value="Canada">Canada</option>
                  <option value="USA">USA</option>
                  <option value="UK">UK</option>
                </select>
                <label htmlFor="floatingInput" className="ms-4 text-light-gray">
                  Country of Interest<span className="text-danger">*</span>
                </label>
              </div>

              <div className="col-12 d-flex justify-content-center text1-4">
                <span className="font-bold">
                  Have you ever been rejected by any country?
                </span>
                <span className="ms-3 me-1">
                  {leadData.previousRejection ? "Yes" : "No"}
                </span>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="previousRejection"
                    checked={leadData.previousRejection}
                    onChange={handleInputChange}
                    name="previousRejection"
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="col-6 form-floating px-3">
                <select
                  className="form-select rounded-pill border text-capitalize"
                  aria-label="Referral source"
                  required
                  id="referralSource"
                  name="referralSource"
                  value={leadData.referralSource}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Referral source
                  </option>
                  <option value="Walk in">Walk in</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Instagram">Instagram</option>
                  <option value="YouTube">YouTube</option>
                  <option value="Google">Google</option>
                  <option value="Website">Website</option>
                  <option value="Constant Contact">Constant Contact</option>
                  <option value="Inbound Call">Inbound Call</option>
                  <option value="Staff">Staff</option>
                  <option value="In Person Event">In person event</option>
                  <option value="Virtual Event">Virtual event</option>
                  <option value="Other">Other</option>
                </select>
                <label htmlFor="floatingInput" className="ms-4 text-light-gray">
                  Referral source<span className="text-danger">*</span>
                </label>
              </div>

              <div className="col-6 form-floating px-3">
                <select
                  className="form-select rounded-pill border"
                  aria-label="Choose status"
                  required
                  id="statusId"
                  value={leadData.statusId}
                  onChange={handleInputChange}
                  name="statusId"
                >
                  <option value="" disabled>
                    Choose status
                  </option>
                  <option value={1}>New Lead</option>
                  <option value={2}>Follow up</option>
                  <option value={3}>Ready to apply</option>
                </select>
                <label htmlFor="floatingInput" className="ms-4 text-light-gray">
                  Choose status<span className="text-danger">*</span>
                </label>
              </div>
            </div>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-light-blue-outline rounded-pill py-2 me-2 px-5 text1-4"
                onClick={() => setModalShow(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-dark-blue rounded-pill py-2 px-5 text1-4"
              >
                Submit
              </button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
