import React from "react";
import { Card, Row, Col } from "react-bootstrap";

export default function BlogList({ dataList }) {
  const formatDate = (datetime) => {
    const dateObj = new Date(datetime);
    const day = String(dateObj.getDate()).padStart(2, "0");
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const year = String(dateObj.getFullYear());
    return `${day}/${month}/${year}`;
  };

  return (
    <>
      <div className="mt-2">
        <Row xs={1} md={4} className="g-5">
          {dataList?.map((data, idx) => {
            const { id, image, title, createdDate, description } = data;
            return (
              <Col md={3.5} xs={12} key={idx}>
                <Card
                  as="a"
                  className="text-decoration-none"
                  href={`/#/blogView/${id}`}
                  style={{
                    height: "380px",
                    borderRadius: "16px",
                  }}
                >
                  {image ? (
                    <Card.Img
                      variant="top"
                      src={data.image}
                      style={{
                        height: "200px",
                        borderRadius: "16px 16px 0px 0px",
                      }}
                    />
                  ) : (
                    <span style={{ height: "200px" }}>Logo not found</span>
                  )}
                  <Card.Body
                    className="d-flex flex-column gap-3  py-4"
                    style={{
                      maxHeight: "180px",
                      overflowY: "scroll",
                    }}
                  >
                    <Card.Title className="fw-bold text1-4 mb-0 text-dark-gray">
                      {title}
                    </Card.Title>
                    <Card.Text className="text1-3 text-dark1 mb-0">
                      {formatDate(createdDate)}
                    </Card.Text>
                    <Card.Text className="text-dark2">{description}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
    </>
  );
}
