import React from "react";
import SectionsTitle from "../../pagesAgent/Components/SectionsTitle";
import globeMap from "../../assets/images/globeMap.png";

const OurMission = () => {
  return (
    <div className="my-5 py-5">
      <SectionsTitle title={"Our Mission"} />
      <div
        style={{
          maxWidth: "80%",
        }}
        className="d-flex flex-column gap-5 flex-sm-row align-items-center justify-content-evenly "
        data-aos="zoom-out"
      >
        <ul className="d-flex flex-column gap-5 text1-5">
          <li>Make quality education accessible for all</li>
          <li>Provide academics consultants</li>
          <li>Find your true potential and fulfill your dream of studying</li>
        </ul>
        <div className="">
          <img className="img-fluid" src={globeMap} alt="img" />
        </div>
      </div>
    </div>
  );
};

export default OurMission;
