import React, { useCallback, useEffect, useMemo, useState } from "react";
import CollegeDataService from "../../../Services/CollegeService";
import { toast } from "react-toastify";
import SpinnerBorder from "../../Components/SpinnerBorder";
import SpinnerGrow from "../../Components/SpinnerGrow";

export default function UpdateLogo({
  fileInputRef,
  data,
  setData,
  label,
  imageURL,
  setImageURL,
}) {
  const [updating, setUpdating] = useState(false);

  const getCollegeLogo = useCallback(
    async (cID) => {
      try {
        setUpdating(true);
        const response = await CollegeDataService.downloadCollegeLogo(cID);
        if (label === "detailPageLogo") {
          setImageURL(response?.data?.response?.detailPageCollegeLogoUrl);
        } else {
          setImageURL(response?.data?.response?.collegeLogoUrl);
        }
      } catch (error) {
        toast.error(error);
      }
      setUpdating(false);
    },
    [label, setImageURL]
  );

  const handleCollegeLogoInput = useCallback(
    async (event) => {
      setUpdating(true);

      const { name, type, files, value } = event.target;

      const file = files[0];

      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setData((p) => ({
            ...p,
            [label]: reader.result.split(",")[1],
            [`${label}Name`]: file.name,
            [`${label}type`]: file.type,
          }));
        };
        reader.readAsDataURL(file);
        setUpdating(false);
      }
    },
    [label, setData]
  );

  useEffect(() => {
    getCollegeLogo(data.id);
  }, []);

  const editImageContent = useMemo(() => {
    switch (true) {
      case !!imageURL:
        return (
          <div className=" d-flex justify-content-center mt-2 position-relative">
            <img
              src={imageURL}
              alt="updated"
              className=""
              style={{ maxWidth: "100%", maxHeight: "180px" }}
            />
          </div>
        );
      case !!data[label]:
        return (
          <div className=" d-flex mt-2 position-relative">
            <img
              src={`data:${data[`${label}Name`]};base64,${data[label]}`}
              alt="Uploaded"
              className=""
              style={{ maxWidth: "100%", maxHeight: "180px" }}
            />
          </div>
        );
      default:
        return (
          <>
            <div className="text1-4 text-dark1 d-flex align-items-center justify-content-center mt-1">
              <label
                htmlFor={`importInput_${label}`}
                style={{ cursor: "pointer" }}
              >
                <div className="d-flex gap-1 align-items-center">
                  <i className="bi bi-cloud-plus text-dark1 text2-5 d-flex align-items-center justify-content-center me-2"></i>

                  <span>Upload Image</span>
                </div>
              </label>

              <input
                id={`importInput_${label}`}
                type="file"
                className="d-none"
                ref={fileInputRef}
                accept="image/jpeg,image/jpg,image/png"
                onChange={handleCollegeLogoInput}
              />
            </div>
          </>
        );
    }
  }, [data, fileInputRef, handleCollegeLogoInput, imageURL, label]);
  if (updating) {
    return (
      <div
        style={{
          height: "100px",
        }}
        className="d-flex justify-content-center align-items-center w-100"
      >
        <span className="spinner-border "></span>
      </div>
    );
  } else {
    return editImageContent;
  }
}
