import React from "react";
import { Button, Table } from "react-bootstrap";
import EditPrograms from "./EditPrograms";

export default function ListPrograms({
  dataList,
  handleDelete,
  collegeData,
  getData,
  pageTitle,
}) {
  return (
    <>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>College Name</th>
            <th>Program Name</th>
            <th>Campus</th>
            <th>Tuition Fee</th>
            <th>Credential</th>
            <th>Duration</th>
            <th>Direct/Indirect</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {dataList.map((data, idx) => (
            <tr key={idx}>
              <td>
                {
                  collegeData.find((college) => college.id === data.collegeId)
                    ?.collegeName
                }
              </td>
              <td>{data.name}</td>
              <td>{data.campus?.map((camp) => camp.name)?.join(", ") ?? ""}</td>
              <td>{data.tuitionFee}</td>
              <td>{data.credential}</td>
              <td>{data.duration}</td>
              <td>{data.directIndirect}</td>
              <td>
                <div className="d-flex">
                  <EditPrograms
                    data={data}
                    collegeData={collegeData}
                    pageTitle={pageTitle}
                    getData={getData}
                  />
                  <Button
                    variant="outline-danger"
                    className="w-100 rounded-pill ms-1"
                    onClick={(e) => handleDelete(e, data)}
                  >
                    Delete
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
