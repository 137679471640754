import React, { useCallback, useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import UserPanel from "../UserPanel";
import CompanyInfo from "./CompanyInfo";
import DirectorInfo from "./DirectorInfo";
import BankingInfo from "./BankingInfo";
import AgentDocuments from "./AgentDocuments";
import AgentAgreement from "./AgentAgreement";
import AgentTabs from "./AgentTabs";
import AgentProfileService from "../../Services/AgentProfileService";
import TokenService from "../../Services/TokenService";
import { toast } from "react-toastify";
import { OverlayTrigger } from "react-bootstrap";
import requestIcon from "../../assets/icons/requestIcon.svg";
import { StyledTooltip } from "../../shared/components/StyledTooltip";
import RequestEdit from "../Students/RequestEdit";
import { useLocation } from "react-router-dom";
import RolesService from "../../Services/RolesService";

const pageTitle = "Profile";

const initialValues = {
  id: "",
  userId: "",
  agreement: "",
  address: "",
  city: "",
  state: "",
  country: "",
  province: "",
  postalCode: null,
  companyType: "",
  tradingName: "",
  skypeId: "",
  twitterId: "",
  instagramId: "",
  facebookId: "",
  whatsappNumber: "",
  telephone: "",
  statusId: 1,
  legalFirstName: "",
  legalLastName: "",
  directorAddress: "",
  directorCountry: "",
  directorState: "",
  directorCity: "",
  directorPostalCode: "",
  directorEmailId: "",
  directorLandlineNumber: "",
  directorProvince: "",
  DirectorTelephone: "",
  DirectorSkypeId: "",
  DirectorTwitterId: "",
  DirectorFacebookId: "",
  DirectorInstagramId: "",
  DirectorWhatsappNumber: "",
  SecondaryContactName: "",
  SecondaryContactCountry: "",
  SecondaryContactDesignation: "",
  SecondaryContactTelephone: "",
  SecondaryContactLandline: "",
  SecondaryContactEmailId: "",
  SecondaryContactAddress: "",
  SecondaryContactCity: "",
  SecondaryContactState: "",
  SecondaryContactProvince: "",
  SecondaryContactPostalCode: "",
  SecondaryContactSkypeId: "",
  SecondaryContactTwitterId: "",
  SecondaryContactFacebookId: "",
  SecondaryContactInstagramId: "",
  SecondaryContactWhatsappNumber: "",
  beneficiaryAccountNumber: "",
  beneficiaryBankName: "",
  branchName: "",
  accountHolderName: "",
  branchLandlineNumber: "",
  branchMobileNumber: "",
  ifscCode: "",
  institutionNumber: "",
  transitNumber: "",
  panNumber: "",
  swiftCode: "",
  gstNumber: "",
  bankAddress: "",
  bankCountry: "",
  bankCity: "",
  bankState: "",
  bankProvince: "",
  bankPostalCode: "",
  isUserProfileCompleted: false,
};

const AgentProfile = () => {
  const userDetails = TokenService.getUser();
  const getRole = TokenService.getUserRole();
  const { BranchHead } = RolesService;
  const [activeTabNext, setActiveTabNext] = useState("companyInformation");
  const [profileData, setProfileData] = useState(initialValues);

  const [showProfileData, setShowProfileData] = useState(true);

  const [gstCertificate, setGstCertificate] = useState({});
  const [businessLicense, setBusinessLicense] = useState({});
  const [regulationLicense, setRegulationLicense] = useState({});
  const [msmeCertificate, setMsmeCertificate] = useState({});

  const [openEditRequest, setOpenEditRequest] = useState();
  const [disabled, setDisabled] = useState(false);
  const [isFormEdited, setIsFormEdited] = useState(true);

  const navValue = useLocation();
  const agentId = navValue.state;

  const getAgentProfileData = useCallback(async () => {
    try {
      const response = await AgentProfileService.getAgentProfileData(
        agentId ? agentId : userDetails.id
      );
      if (response.data.response) {
        setProfileData(response?.data?.response);
        setDisabled(
          response?.data?.response.isUserProfileCompleted ||
            getRole !== BranchHead
        );
        if (response?.data?.response?.isUserProfileCompleted) {
          localStorage.setItem("isUserProfileCompleted", true);
        }
      } else if (response?.data?.response === null) {
        if (getRole !== BranchHead) {
          setShowProfileData(false);
        }
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString()
      );
    }
  }, [BranchHead, agentId, getRole, userDetails.id]);

  const isValid = useCallback((values) => {
    if (
      values.tradingName &&
      values.address &&
      values?.legalFirstName &&
      values?.directorEmailId
    ) {
      return true;
    } else return false;
  }, []);

  const handleSaveProfileData = useCallback(async () => {
    try {
      const { userId, id, ...postData } = profileData;
      const shouldSaveData = isValid(profileData);
      if (!shouldSaveData) {
        toast.error("Fill all the mandatory fields first");
        return;
      }
      const response = profileData?.id
        ? await AgentProfileService.updateAgentProfileData(profileData)
        : await AgentProfileService.postAgentProfileData(postData);
      if (response.data.success === true) {
        toast.success(response.data.message);
        getAgentProfileData();
      } else if (response.data.validationErrors) {
        response.data.validationErrors.map((verr) => toast.error(verr));
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString()
      );
    } finally {
      setIsFormEdited(true);
    }
  }, [getAgentProfileData, isValid, profileData]);

  useEffect(() => {
    getAgentProfileData();
  }, [getAgentProfileData]);

  const getAllDocuments = useCallback(async () => {
    try {
      const response = await AgentProfileService.getDocuments(
        agentId ? agentId : userDetails.id
      );
      if (response.data.response) {
        setGstCertificate((prev) => ({
          ...prev,
          url: response?.data.response.gstCertificateUrl,
        }));
        setBusinessLicense((prev) => ({
          ...prev,
          url: response.data.response.businessLicenseUrl,
        }));
        setRegulationLicense((prev) => ({
          ...prev,
          url: response.data.response
            .punjabTravelProfessionalRegulationLicenseUrl,
        }));
        setMsmeCertificate((prev) => ({
          ...prev,
          url: response.data.response.msmeCertificate,
        }));
      }
    } catch (error) {
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString()
      );
    }
  }, [agentId, userDetails.id]);

  useEffect(() => {
    getAllDocuments();
  }, [getAllDocuments]);

  const handleInputChange = (event) => {
    setIsFormEdited(false);
    const { name, value } = event.target;
    if (name === "agreement" && value) {
      setProfileData((prev) => ({
        ...prev,
        [name]: value,
        isUserProfileCompleted: true,
      }));
    } else {
      setProfileData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const tabs = [
    {
      id: "companyInformation",
      label: "Company Information",
      content: (
        <CompanyInfo
          setActiveTabNext={setActiveTabNext}
          profileData={profileData}
          setProfileData={setProfileData}
          disabled={disabled}
          handleInputChange={handleInputChange}
          handleSaveProfileData={handleSaveProfileData}
          isFormEdited={isFormEdited}
          setIsFormEdited={setIsFormEdited}
        />
      ),
    },
    {
      id: "directorInformation",
      label: "Director Information",
      content: (
        <DirectorInfo
          setActiveTabNext={setActiveTabNext}
          profileData={profileData}
          setProfileData={setProfileData}
          disabled={disabled}
          handleInputChange={handleInputChange}
          handleSaveProfileData={handleSaveProfileData}
          isFormEdited={isFormEdited}
          setIsFormEdited={setIsFormEdited}
        />
      ),
    },
    {
      id: "bankingInformation",
      label: "Banking Information",
      content: (
        <BankingInfo
          setActiveTabNext={setActiveTabNext}
          profileData={profileData}
          setProfileData={setProfileData}
          disabled={disabled}
          handleInputChange={handleInputChange}
          handleSaveProfileData={handleSaveProfileData}
          isFormEdited={isFormEdited}
          setIsFormEdited={setIsFormEdited}
        />
      ),
    },
    {
      id: "documents",
      label: "Documents",
      content: (
        <AgentDocuments
          setActiveTabNext={setActiveTabNext}
          gstCertificate={gstCertificate}
          businessLicense={businessLicense}
          regulationLicense={regulationLicense}
          msmeCertificate={msmeCertificate}
          setGstCertificate={setGstCertificate}
          setBusinessLicense={setBusinessLicense}
          setRegulationLicense={setRegulationLicense}
          setMsmeCertificate={setMsmeCertificate}
          getAllDocuments={getAllDocuments}
          disabled={disabled}
        />
      ),
    },
    {
      id: "agreement",
      label: "Agreement",
      content: (
        <AgentAgreement
          setActiveTabNext={setActiveTabNext}
          profileData={profileData}
          setProfileData={setProfileData}
          handleInputChange={handleInputChange}
          handleSaveProfileData={handleSaveProfileData}
          disabled={disabled}
          isFormEdited={isFormEdited}
          setIsFormEdited={setIsFormEdited}
        />
      ),
    },
  ];
  return (
    <>
      <PageTitle title={pageTitle} />
      <div className="p-2">
        <UserPanel title={pageTitle} />
        {profileData?.isUserProfileCompleted && getRole === BranchHead ? (
          <div className="d-flex justify-content-end p-3">
            <button
              className="col edit-button btn btn-sm d-flex justify-content-end rounded-circle border-0"
              onClick={() => setOpenEditRequest(true)}
            >
              <OverlayTrigger
                placement="top"
                overlay={StyledTooltip({
                  text: "Request Edit",
                })}
              >
                <img
                  src={requestIcon}
                  alt="request"
                  className="img-fluid "
                  style={{ width: "40px", height: "40px" }}
                />
              </OverlayTrigger>
            </button>
          </div>
        ) : null}
        <RequestEdit
          modalShow={openEditRequest}
          id={userDetails.id}
          setModalShow={setOpenEditRequest}
          type="Agent"
        />
        {showProfileData ? (
          <AgentTabs
            tabs={tabs}
            isAnimatedBorder={false}
            activeTabNext={activeTabNext}
            setActiveTabNext={setActiveTabNext}
            // isTabJumpDisabled={true}
          />
        ) : (
          <p className="d-flex justify-content-center mt-5 text2 fw-bold">
            Details not available
          </p>
        )}
      </div>
    </>
  );
};

export default AgentProfile;
