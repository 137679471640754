import React, { useMemo } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import "./AppWalletPay.css";
import StripeKeys from "../../Services/StripeKeys";
import EpLoader from "../../components/UI/EpLoader";
import { Spinner } from "react-bootstrap";
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
// const stripePromise = loadStripe(
//   "pk_live_51N2rmqSEeXJSCHtnENFf26ZBTGddA5RdCCit1cIzk3Shzl6jbr7k7Dq89fhh2rFq4c0dJkv0ekRgxp2Bos4MYDth00fL5JGTxe"
//   //"pk_test_51NNiyMSAwxQB49PC83FWjlk863AbgJxNdHADA70gAUUI18YlghmYEe4YZA7exxYSCBmtLmMfwxABjJRI5wxp4oGi00OLn4PX1Y"
// );
const stripePromise = loadStripe(StripeKeys.PublishableStripeKey);

const appearance = {
  theme: "stripe",
  variables: {
    colorPrimary: "#3f59ff",
    colorBackground: "#ffffff",
    colorText: "#161616",
    colorDanger: "#ff3f6d",
    spacingUnit: "3px",
    borderRadius: "1.6rem",
    marginLeft: "2rem",
  },
};

export default function AppStripePayment({
  clientSecret,
  PaymentService,
  newWalletData,
  setNewWalletData,
  handleClose,
  findWalletData,
  retrieveDashbaord,
}) {
  const options = useMemo(
    () => ({
      clientSecret,
      appearance,
    }),
    [clientSecret]
  );
  return (
    <div>
      {clientSecret ? (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            PaymentService={PaymentService}
            newWalletData={newWalletData}
            setNewWalletData={setNewWalletData}
            handleClose={handleClose}
            findWalletData={findWalletData}
            retrieveDashbaord={retrieveDashbaord}
          />
        </Elements>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "320px" }}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
}
