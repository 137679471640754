import React, { useCallback, useEffect, useState } from 'react'
import AgentProfileService from '../../Services/AgentProfileService'
import { toast } from 'react-toastify'
import RolesService from '../../Services/RolesService'
import TokenService from '../../Services/TokenService'

const AgentDocuments = ({ setActiveTabNext, gstCertificate, msmeCertificate, setMsmeCertificate,
    setGstCertificate, businessLicense, disabled,
    setBusinessLicense, regulationLicense, setRegulationLicense, getAllDocuments }) => {

    const getRole = TokenService.getUserRole()
    const { BranchHead } = RolesService


    const hasAnyKey = (obj) => obj && Object.keys(obj).length > 0;

    const isValid = useCallback(() => {
        return (
            hasAnyKey(gstCertificate) &&
            hasAnyKey(businessLicense) &&
            hasAnyKey(regulationLicense) &&
            hasAnyKey(msmeCertificate)
        );
    }, [gstCertificate, businessLicense, regulationLicense, msmeCertificate]);

    const handleUploadDocuments = useCallback(async () => {
        try {
            if (!isValid()) {
                toast.error('Upload all the documents first')
                return
            }
            const body = {
                documentList: [
                    gstCertificate?.fileName &&
                    {
                        file: gstCertificate?.content,
                        fileName: gstCertificate?.fileName,
                        title: 'GstCertificate'
                    },

                    businessLicense?.fileName &&
                    {
                        file: businessLicense?.content,
                        fileName: businessLicense?.fileName,
                        title: 'BusinessLicense'
                    },

                    regulationLicense?.fileName &&
                    {
                        file: regulationLicense?.content,
                        fileName: regulationLicense?.fileName,
                        title: 'PunjabTravelProfessionalRegulationLicense'
                    },

                    msmeCertificate?.fileName &&
                    {
                        file: msmeCertificate?.content,
                        fileName: msmeCertificate?.fileName,
                        title: 'MsmeCertificate'
                    }
                ].filter(Boolean)

            }
            const response = await AgentProfileService.uploadDocuments(body)
            if (response.data.success) {
                toast.success("Uploaded Successfully")
                getAllDocuments()
            }
        } catch (error) {
            toast.error(
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            );
        }
    }, [businessLicense, getAllDocuments, gstCertificate, msmeCertificate, regulationLicense, isValid])


    const handleInputChange = (event, stateToUpdate) => {
        const { type, files, } = event.target;
        if (type === "file") {
            const file = files[0];
            if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    stateToUpdate({
                        fileName: file.name,
                        content: reader.result.split(",")[1],
                        mimeType: file.type,
                    });
                };
                reader.readAsDataURL(file);
            }
        } else {
            stateToUpdate({});
        }
    };


    const handleTabChange = (e) => {
        e.preventDefault();
        setActiveTabNext("agreement");
    };

    return (
        <form className='need-validations'>

            <div className="border rounded-4 bg-light-blue1 text1-5 p-3 mb-4">
                <div className="d-flex align-items-center bg-stripped1 border border-top-0 rounded-4 p-3  mb-3">
                    <span className="font-bold">Documents</span>
                    <span className="text-danger">*</span>
                </div>


                <div className="table-responsive rounded-4 overflow-visible">
                    <table className="table custom-table">
                        <tbody className="text1-4">
                            <tr>
                                <td>
                                    GST/HST Certificate
                                </td>
                                {!gstCertificate?.fileName && !gstCertificate.url ?

                                    <td className='d-flex align-items-center justify-content-evenly'>
                                        <label
                                            className="text1-5 text-dark1"
                                            htmlFor="gstCertificate"
                                            style={{ cursor: "pointer" }}
                                        >
                                            Upload
                                            <i className="bi bi-paperclip text1-5 text-blue"></i>
                                        </label>

                                        <input
                                            id="gstCertificate"
                                            type="file"
                                            required
                                            accept="image/jpeg, image/jpg, image/png, application/pdf"
                                            className="d-none"
                                            disabled={disabled}
                                            onChange={(e) => handleInputChange(e, setGstCertificate)}
                                        />
                                    </td>
                                    :
                                    <>
                                        <td className='d-flex align-items-center justify-content-center gap-5'>
                                            <div className='text-center'>
                                                {gstCertificate?.mimeType?.startsWith(
                                                    "image/"
                                                ) ?
                                                    <a href={gstCertificate.url} target='_blank' rel="noreferrer">
                                                        <i class="bi bi-file-image text2 btn rounded-4 btn-success"
                                                        ></i>
                                                    </a>

                                                    :
                                                    <a href={gstCertificate.url} target='_blank' rel="noreferrer">
                                                        <i class="bi bi-file-pdf-fill text2 btn rounded-4 btn-success"></i>
                                                    </a>
                                                }

                                                {/* <p className='text1-5 mb-0'>
                                                    {gstCertificate?.fileName}
                                                </p> */}
                                            </div>

                                            <button
                                                className="btn rounded-circle btn-dark-red"
                                                style={{ width: "38px", height: "38px" }}
                                                onClick={() => {
                                                    setGstCertificate({})
                                                }}
                                                disabled={disabled}
                                            >
                                                <i
                                                    className="bi bi-trash3 text2 d-flex justify-content-center align-items-center"
                                                ></i>
                                            </button>
                                        </td>
                                    </>
                                }
                            </tr>
                            <tr>
                                <td>
                                    Business License/ Registration
                                </td>
                                {!businessLicense?.fileName && !businessLicense.url ?
                                    <td className='d-flex align-items-center justify-content-evenly'>
                                        <label
                                            className="text1-5 text-dark1"
                                            htmlFor="businessLicense"
                                            style={{ cursor: "pointer" }}
                                        >
                                            Upload
                                            <i className="bi bi-paperclip text1-5 text-blue"></i>
                                        </label>
                                        <input
                                            id="businessLicense"
                                            type="file"
                                            required
                                            accept="image/jpeg, image/jpg, image/png, application/pdf"
                                            className="d-none"
                                            disabled={disabled}
                                            onChange={(e) => handleInputChange(e, setBusinessLicense)}
                                        />
                                    </td>
                                    :
                                    <>
                                        <td className='d-flex align-items-center justify-content-center gap-5'>
                                            {businessLicense?.mimeType?.startsWith(
                                                "image/"
                                            ) ?
                                                <a href={businessLicense.url} target='_blank' rel="noreferrer">
                                                    <i class="bi bi-file-image text2 btn rounded-4 btn-success"
                                                    ></i>
                                                </a>

                                                :
                                                <a href={businessLicense.url} target='_blank' rel="noreferrer">
                                                    <i class="bi bi-file-pdf-fill text2 btn rounded-4 btn-success"></i>
                                                </a>}
                                            {/* 
                                            <p className='text1-5'>
                                                {businessLicense?.fileName}
                                            </p> */}

                                            <button
                                                className="btn rounded-circle btn-dark-red"
                                                style={{ width: "38px", height: "38px" }}
                                                onClick={() => {
                                                    setBusinessLicense({})
                                                }}
                                                disabled={disabled}
                                            >
                                                <i
                                                    className="bi bi-trash3 text2 d-flex justify-content-center align-items-center"
                                                ></i>
                                            </button>
                                        </td>
                                    </>
                                }
                            </tr>
                            <tr>
                                <td>
                                    Punjab Travel Professional Regulation License
                                </td>
                                {!regulationLicense?.fileName && !regulationLicense.url ?
                                    <td className='d-flex align-items-center justify-content-evenly'>
                                        <label
                                            className="text1-5 text-dark1"
                                            htmlFor="regulationLicense"
                                            style={{ cursor: "pointer" }}
                                        >
                                            Upload
                                            <i className="bi bi-paperclip text1-5 text-blue"></i>
                                        </label>
                                        <input
                                            id="regulationLicense"
                                            type="file"
                                            required
                                            accept="image/jpeg, image/jpg, image/png, application/pdf"
                                            className="d-none"
                                            disabled={disabled}
                                            onChange={(e) => handleInputChange(e, setRegulationLicense)}
                                        />
                                    </td>
                                    :
                                    <>
                                        <td className='d-flex align-items-center justify-content-center gap-5'>
                                            {regulationLicense?.mimeType?.startsWith(
                                                "image/"
                                            ) ?
                                                <a href={regulationLicense.url} target='_blank' rel="noreferrer">
                                                    <i class="bi bi-file-image text2 btn rounded-4 btn-success"
                                                    ></i>
                                                </a>

                                                :
                                                <a href={regulationLicense.url} target='_blank' rel="noreferrer">
                                                    <i class="bi bi-file-pdf-fill text2 btn rounded-4 btn-success"></i>
                                                </a>}
                                            {/* <p className='text1-5'>
                                                {regulationLicense?.fileName}
                                            </p> */}

                                            <button
                                                className="btn rounded-circle btn-dark-red"
                                                style={{ width: "38px", height: "38px" }}
                                                onClick={() => {
                                                    setRegulationLicense({})
                                                }}
                                                disabled={disabled}
                                            >
                                                <i
                                                    className="bi bi-trash3 text2 d-flex justify-content-center align-items-center"
                                                ></i>
                                            </button>
                                        </td>
                                    </>
                                }
                            </tr>
                            <tr>
                                <td>
                                    MSME Certificate
                                </td>
                                {!msmeCertificate?.fileName && !msmeCertificate.url ?
                                    <td className='d-flex align-items-center justify-content-evenly'>
                                        <label
                                            className="text1-5 text-dark1"
                                            htmlFor="msmeCertificate"
                                            style={{ cursor: "pointer" }}
                                        >
                                            Upload
                                            <i className="bi bi-paperclip text1-5 text-blue"></i>
                                        </label>
                                        <input
                                            id="msmeCertificate"
                                            type="file"
                                            required
                                            accept="image/jpeg, image/jpg, image/png, application/pdf"
                                            className="d-none"
                                            disabled={disabled}
                                            onChange={(e) => handleInputChange(e, setMsmeCertificate)}
                                        />
                                    </td>
                                    :
                                    <>
                                        <td className='d-flex align-items-center justify-content-center gap-5'>
                                            {msmeCertificate?.mimeType?.startsWith(
                                                "image/"
                                            ) ?
                                                <a href={msmeCertificate.url} target='_blank' rel="noreferrer">
                                                    <i class="bi bi-file-image text2 btn rounded-4 btn-success"
                                                    ></i>
                                                </a>

                                                :
                                                <a href={msmeCertificate.url} target='_blank' rel="noreferrer">
                                                    <i class="bi bi-file-pdf-fill text2 btn rounded-4 btn-success"></i>
                                                </a>}
                                            {/* <p className='text1-5'>
                                                {msmeCertificate?.fileName}
                                            </p> */}

                                            <button
                                                className="btn rounded-circle btn-dark-red"
                                                style={{ width: "38px", height: "38px" }}
                                                onClick={() => {
                                                    setMsmeCertificate({})
                                                }}
                                                disabled={disabled}
                                            >
                                                <i
                                                    className="bi bi-trash3 text2 d-flex justify-content-center align-items-center"
                                                ></i>
                                            </button>
                                        </td>
                                    </>
                                }
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="d-flex justify-content-end my-2 mt-5">
                {getRole === BranchHead ?
                    <button
                        type="button"
                        className="btn btn-light-blue-outline rounded-pill py-3 me-2 px-5 text1-4"
                        onClick={handleUploadDocuments}
                        disabled={disabled}
                    >
                        Save
                    </button>
                    : null}
                <button
                    type="button"
                    className="btn btn-light-blue-outline rounded-pill py-3 me-2 px-5 text1-4"
                    onClick={handleTabChange}
                >
                    Next
                </button>
            </div>

        </form >
    )
}

export default AgentDocuments
