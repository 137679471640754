import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ApplicationNoteService from "../Services/ApplicationNoteService";
import RoleService from "../Services/RolesService";
import TokenService from "../Services/TokenService";
import addIcon from "../assets/icons/add.svg";
import notificationIconDarkBlue from "../assets/icons/notification_darkBlue.svg";
import notificationIconWhite from "../assets/icons/notification_white.svg";
import feedbackIcon from "../assets/icons/feedbackIcon.svg";
import Logout from "../components/LogOut";
import ChangePassword from "./Profile/ChangePassword";
import "./UserPanel.css";
import { OverlayTrigger } from "react-bootstrap";
import { StyledTooltip } from "../shared/components/StyledTooltip";
import Feedback from "./Dashboard/Feedback";
import Notification from "./Notifications/Notification";

export default function UserPanel({ title }) {
  const location = useLocation();
  const navigate = useNavigate()
  const pathname = location.pathname;
  const [notifications, setNotifications] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);

  const user = TokenService.getUser();
  const getRole = TokenService.getUserRole();
  const { SuperAdmin, Counsellor, AdmissionOfficer, TeamLeader, BranchHead, AgencyManager } =
    RoleService;

  const [modalShow, setModalShow] = useState(false);
  const [feedbackModal, setFeedbackModal] = useState(false);

  const getRecentNotifications = useCallback(async () => {
    try {
      const response = await ApplicationNoteService.fetchNotifications();

      setNotifications(response.data.response);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (getRole === BranchHead || getRole === Counsellor ||
      getRole === AdmissionOfficer ||
      getRole === AgencyManager) {
      if (location.pathname.includes("user/viewApplication")) {
        setTimeout(() => {
          getRecentNotifications();
        }, 1.5 * 1000);
      } else {
        getRecentNotifications();
      }
    }
  }, [AdmissionOfficer, AgencyManager, BranchHead, Counsellor, getRecentNotifications, getRole, location.pathname]);

  const unreadNotifications = useMemo(() => {
    return notifications?.filter((notif) => !notif.isRead);
  }, [notifications]);

  const handleClickNotification = useCallback(() => {
    navigate('/user/notifications')
  }, [navigate])

  return (
    <>
      <div className="row align-items-center px-4 mb-2">
        <div className="col d-flex align-items-center">
          <p className="text2-2 font-bold text-uppercase">{title}</p>
        </div>
        <div className="col d-print-none">
          <div className="d-flex justify-content-end">
            {getRole === SuperAdmin ||
              getRole === BranchHead ||
              getRole === Counsellor ? (
              <OverlayTrigger
                placement="bottom"
                overlay={StyledTooltip({
                  text: "Add New Student",
                })}
              >
                <div
                  className={`d-${pathname === "/user/addStudent" ? "none" : "flex"
                    } justify-content-center align-items-center dropstart`}
                >
                  <button
                    className="btn btn-sm d-flex justify-content-center align-items-center rounded-circle bg-dark1 me-2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ width: "46px", height: "46px" }}
                  >
                    <img src={addIcon} alt="addIcon" className="img-fluid" />
                  </button>

                  <div className="dropdown-menu rounded-4 m-0 p-2">
                    <li>
                      <Link
                        to={"/user/addStudent"}
                        className="btn btn-dark-white rounded-2 py-2 text1-2 dropdown-item"
                      >
                        Add New Student
                      </Link>
                    </li>
                  </div>
                </div>
              </OverlayTrigger>
            ) : (
              ""
            )}

            {/* Notification starts */}
            {getRole === BranchHead ||
              getRole === Counsellor ||
              getRole === AdmissionOfficer ||
              getRole === AgencyManager ? (
              <>
                <OverlayTrigger
                  placement="bottom"
                  overlay={StyledTooltip({
                    text: "Notifications",
                  })}
                  show={showTooltip}
                >
                  <div className="d-flex justify-content-center align-items-center dropstart">
                    <button
                      className="position-relative btn btn-sm rounded-circle bg-dark1 me-2"
                      // data-bs-toggle="dropdown"
                      onClick={handleClickNotification}
                      aria-expanded="false"
                      onMouseEnter={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                      style={{ width: "46px", height: "46px" }}
                    >
                      <img
                        src={notificationIconWhite}
                        alt="notificationIcon"
                        className={
                          unreadNotifications?.length
                            ? `img-fluid`
                            : `bell-icon-image`
                        }
                      />
                      {unreadNotifications?.length && (
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger text1-1 glowing-badge">
                          {unreadNotifications?.length || 0}
                          <span className="visually-hidden">
                            unread messages
                          </span>
                        </span>
                      )}
                    </button>

                    {/* <div
                      className="dropdown-menu notification-content-container pb-0 rounded-4 "
                      style={{ minWidth: "350px" }}
                    >
                      <Notification notifications={notifications} />
                    </div> */}
                  </div>
                </OverlayTrigger>
              </>
            ) : (
              ""
            )}

            {getRole === BranchHead ? (
              <OverlayTrigger
                placement="bottom"
                overlay={StyledTooltip({
                  text: "Feedback",
                })}
              >
                <div className="d-flex justify-content-center align-items-center dropstart">
                  <button
                    className="btn btn-sm d-flex justify-content-center align-items-center rounded-circle bg-dark1 me-2"
                    style={{ width: "46px", height: "46px" }}
                    onClick={() => setFeedbackModal(true)}
                  >
                    <img
                      src={feedbackIcon}
                      alt="feedback-icon"
                      className="img-fluid"
                      style={{ width: 26, height: 26 }}
                    />
                  </button>
                </div>
              </OverlayTrigger>
            ) : (
              ""
            )}
            <div className="dropdown ms-1" style={{ minWidth: "160px" }}>
              <button
                className="btn dropdown-toggle d-flex justify-content-between align-items-center link-body-emphasis text-decoration-none border border-start-0 rounded-pill bg-dark1 text-white text1-4 py-3"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="bi bi-person-circle text-white text2-5 ms-1 d-flex align-items-center justify-content-between"></i>
                <span className="text-white text1-4 ms-1">
                  {user.firstName + " " + user.lastName}
                </span>
              </button>
              <ul className="dropdown-menu shadow w-100 rounded-4">
                {getRole === BranchHead ?
                  <li>
                    <Link to={"/user/profile"} className="btn btn-dark-white rounded-2 py-2 text1-4 dropdown-item">
                      Profile
                    </Link>
                    <hr className="dropdown-divider" />
                  </li>
                  : ''}
                <li>
                  <div
                    className="btn btn-dark-white rounded-2 py-2 text1-4 dropdown-item"
                    onClick={() => setModalShow(true)}
                  >
                    Change Password
                  </div>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Logout />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {modalShow && (
        <ChangePassword
          setModalShow={setModalShow}
          modalShow={modalShow}
          user={user}
        />
      )}
      {feedbackModal && (
        <Feedback modalShow={feedbackModal} setModalShow={setFeedbackModal} />
      )}
    </>
  );
}
