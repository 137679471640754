import React, { useCallback, useEffect, useState } from "react";
import PageTitle from "../../../components/PageTitle";
import AddAnnouncement from "./AddAnnouncement";
import AnnouncementService from "../../Services/AnnouncementService";
import { toast } from "react-toastify";
import SearchBar from "../../Components/SearchBar";
import SelectItemsPerPage from "../../Components/SelectItemsPerPage";
import SpinnerBorder from "../../Components/SpinnerBorder";
import AdvancePagination from "../../Components/Pagination";
import AnnouncementList from "./AnnouncementList";
import ConfirmationModal from "../../Components/ConfirmationModal";

const initializeData = {
  id: "",
  collegeName: "",
  description: "",
  bannerName: "",
  bannerImageUrl: "",
  statusId: 0,
  createdBy: "",
  created: "",
};
const pageTitle = "Announcements";

export default function Announcements() {
  const [updating, setUpdating] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [itemsLength, setItemsLength] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [bannerListData, setBannerListData] = useState([initializeData]);
  const getBannerData = useCallback(async () => {
    setUpdating(true);
    try {
      const data = {
        search: searchQuery,
        currentPage,
        pageSize,
      };
      const response = await AnnouncementService.find(data);
      const responseData = response.data.response;

      setBannerListData(responseData);
      setUpdating(false);
      setItemsLength(responseData[0]?.totalItems);
    } catch (error) {
      toast.error(error);
    } finally {
      setUpdating(false);
    }
  }, [currentPage, pageSize, searchQuery]);

  const handleSearchInput = (e) => {
    e.preventDefault();

    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const clearSearchQuery = () => {
    setSearchQuery("");
  };

  const handlePageSizeChange = (event) => {
    event.preventDefault();
    setPageSize(parseInt(event.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [itemToConfirm, setItemToConfirm] = useState(null);

  const handleDelete = (e, data) => {
    e.preventDefault();
    setItemToConfirm(data);
    setShowConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    setShowConfirmation(false);
    if (itemToConfirm) {
      try {
        await toast.promise(AnnouncementService.remove(itemToConfirm.id), {
          loading: "Deleting...",
          success: (
            <b> {itemToConfirm.collegeName} banner deleted successfully!</b>
          ),
          error: <b>Could not delete {itemToConfirm.collegeName} banner</b>,
        });
        setItemToConfirm(null);
        getBannerData();
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
    setItemToConfirm(null);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getBannerData();
    }, 300);
    return () => clearTimeout(timer);
  }, [getBannerData]);

  return (
    <>
      <PageTitle title={pageTitle} />
      <div className="row px-1 px-md-5">
        <div className="text-center fs-1 text-uppercase col-12">
          {pageTitle}
        </div>
        <div className="col-6">
          <AddAnnouncement pageTitle={pageTitle} getData={getBannerData} />
        </div>
        <div className="col-6 d-flex justify-content-end">
          <SearchBar
            searchQuery={searchQuery}
            handleSearchInput={handleSearchInput}
            clearSearchQuery={clearSearchQuery}
            searchTitle={`Search ${pageTitle}`}
          />
          <SelectItemsPerPage handlePageSizeChange={handlePageSizeChange} />
        </div>
        {updating ? (
          <SpinnerBorder />
        ) : bannerListData?.length === 0 ? (
          <div className="mt-5 text-center fs-1">No Data Found</div>
        ) : (
          <div className="col-12 p-4">
            <AnnouncementList
              data={bannerListData}
              getData={getBannerData}
              handleDelete={handleDelete}
              pageTitle={pageTitle}
            />
            <div className="mt-3">
              <AdvancePagination
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                totalItems={itemsLength}
                pageSize={pageSize}
              />
            </div>
            <ConfirmationModal
              show={showConfirmation}
              onHide={handleCloseConfirmation}
              onConfirm={handleConfirmDelete}
              message={
                itemToConfirm
                  ? `Are you sure want to delete "${itemToConfirm.collegeName}" banner?`
                  : ""
              }
              title={itemToConfirm?.name}
            />
          </div>
        )}
      </div>
    </>
  );
}
