import React, { useCallback, useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import UserPanel from "../UserPanel";
import EpLoader from "../../components/UI/EpLoader";
import Updating from "../../components/UI/Updating";
import SearchBar from "../../components/UI/SearchBar";
import sortIcon from "../../assets/icons/sort.svg";
import Pagination from "../../components/UI/Pagination";
import AgentUserService from "../../Services/AgentUserService";
import { toast } from "react-toastify";

const pageTitle = "Agent Users";
export default function AgentUserList() {
  const [userList, setUserList] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setUpdating] = useState(false);
  const [role, setRole] = useState("");
  const [company, setCompany] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const getRequestParams = useCallback(
    ({ searchQuery, role, currentPage, itemsPerPage, company }) => {
      let params = {};

      if (searchQuery) {
        params["search"] = searchQuery;
      }
      if (role) {
        params["role"] = role;
      }
      if (company) {
        params["companyName"] = company;
      }
      if (currentPage) {
        params["currentPage"] = currentPage;
      }
      if (itemsPerPage) {
        params["pageSize"] = itemsPerPage;
      }

      return params;
    },
    []
  );

  const getCompanyNames = useCallback(async () => {
    try {
      const response = await AgentUserService.getAllCompany();

      setCompanyOptions(response.data.response);
    } catch (error) {
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.to()
      );
    }
  }, []);

  const retrieveUsers = useCallback(async () => {
    const params = getRequestParams({
      searchQuery,
      company,
      role,
      currentPage,
      itemsPerPage,
    });

    try {
      const response = await AgentUserService.find(params);
      if (response.data.success === true) {
        setUserList(response.data.response.users);
        setTotalItems(response.data.response.totalCount);
      } else if (response.data.validationErrors.length > 0) {
        response.data.validationErrors.map((verr) => toast.error(verr));
      } else {
        toast.error("Something went wrong");
      }

      setIsLoading(false);
    } catch (error) {
      toast.error("Error retrieving users");
    }
  }, [company, currentPage, getRequestParams, itemsPerPage, role, searchQuery]);

  const handleRoleChange = (event) => {
    event.preventDefault();
    if (event.target.value === "All") {
      setRole("");
    } else {
      setRole(event.target.value);
    }
    setCurrentPage(1);
  };
  const handleCompanySelect = (event) => {
    event.preventDefault();
    if (event.target.value === "All") {
      setCompany("");
    } else {
      setCompany(event.target.value);
    }
    setCurrentPage(1);
  };

  useEffect(() => {
    retrieveUsers();
  }, [retrieveUsers]);
  useEffect(() => {
    getCompanyNames();
  }, [getCompanyNames]);

  if (isLoading) {
    return <EpLoader />;
  }

  return (
    <>
      <PageTitle title={pageTitle} />
      <div className="p-2 mt-2">
        {" "}
        <div className="row align-items-center">
          <div className="col">
            <UserPanel title={pageTitle} />
          </div>
        </div>
        {isUpdating ? (
          <Updating />
        ) : (
          <>
            <div className="row align-items-baseline mt-3 mx-2 mb-1">
              <div className="col d-flex align-items-center column-gap-3">
                <SearchBar
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  searchTitle={"Search by Agent Name"}
                  setCurrentPage={setCurrentPage}
                />
              </div>
              <div className="col-7">
                <div className="d-flex align-items-center justify-content-end text-dark1 column-gap-2 w-100">
                  <div className="text1-4 text-dark1 form-floating w-25 ">
                    <select
                      className="form-select rounded-4 text1-3 py-2"
                      aria-label="Select Role"
                      value={role}
                      onChange={handleRoleChange}
                    >
                      <option value={"All"}>All</option>
                      <option value={"Branch Head"}>Branch Head</option>
                      <option value={"Reception"}>Reception</option>
                      <option value={"Counsellor"}>Counsellor</option>
                    </select>
                    <label for="floatingSelect">Role</label>
                  </div>

                  <div className="text1-4 text-dark1 form-floating">
                    <select
                      className="form-select rounded-4 text1-3 py-2"
                      aria-label="Select company"
                      value={company}
                      onChange={handleCompanySelect}
                    >
                      <option value={"All"}>All</option>

                      {companyOptions?.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <label for="floatingSelect">Company</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3 mb-4">
              {totalItems > 0 && (
                <Pagination
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                  dataLength={totalItems}
                  itemsPerPage={itemsPerPage}
                />
              )}
            </div>
            <div className="row">
              <div className="col-12">
                <div className="table-responsive rounded-4">
                  <table className="table custom-table">
                    <thead className="bg-dark1 text-white text-center">
                      <tr className="py-1 text1-3">
                        <th scope="col">Agency Name</th>
                        <th scope="col">Agent Name</th>
                        <th scope="col">Role </th>
                        <th scope="col">Email </th>
                        <th scope="col">Phone Number </th>
                      </tr>
                    </thead>
                    <tbody className="text1-4">
                      {userList.map((user, index) => (
                        <tr className="text-center" key={index}>
                          <td>{user?.companyName}</td>
                          <td>
                            {user?.firstName} {user?.lastName}
                          </td>
                          <td>{user?.role}</td>

                          <td>{user.email}</td>

                          <td>{user.phoneNumber}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {totalItems > 0 && (
                    <Pagination
                      currentPage={currentPage}
                      handlePageChange={handlePageChange}
                      dataLength={totalItems}
                      itemsPerPage={itemsPerPage}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
