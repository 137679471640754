import http from "./api";

const getAllBranchHeadAndCounsellorList = (params) => {
  return http.get("/Auth/getAllBranchHeadAndCounsellorList?", { params });
};

const fetchRefundLedger = (params) => {
  return http.get("/RefundLedger", { params });
};

const issueRefund = (payload) => {
  return http.post("/Wallet/sendMoney", payload);
};

const RefundService = {
  getAllBranchHeadAndCounsellorList,
  fetchRefundLedger,
  issueRefund,
};

export default RefundService;
