import React from "react";
import CountUp from "../../components/CountUp";
import WorldMap from "../../assets/institution/WorldMap.png";
import SectionsTitle from "../Components/SectionsTitle";
import TokenService from "../../Services/TokenService";

const AuthInfoData = [
  {
    title: "Agents",
    count: 100,
    delay: 100,
    duration: 2000,
  },
  {
    title: "Students",
    count: 5000,
    delay: 200,
    duration: 2000,
  },
  {
    title: "Institutions",
    count: 100,
    delay: 400,
    duration: 2000,
  },
  {
    title: "Countries",
    count: 5,
    delay: 500,
    duration: 3000,
  },
];

const InfoData = [
  {
    title: "Partners",
    count: 100,
    delay: 100,
    duration: 2000,
  },
  {
    title: "Students",
    count: 5000,
    delay: 200,
    duration: 2000,
  },
  {
    title: "Institutions",
    count: 100,
    delay: 400,
    duration: 2000,
  },
  {
    title: "Countries",
    count: 5,
    delay: 500,
    duration: 3000,
  },
];

export default function Main() {
  const token = TokenService.getLocalAccessToken();
  const isAuth = Boolean(token);
  const infoData = isAuth ? AuthInfoData : InfoData;
  return (
    <div className="m-4">
      <SectionsTitle title={"Connecting global partners and institutions"} />
      <div className="row justify-content-center align-items-center mt-5">
        <div className="col-2">
          <div>
            {infoData.map((item, index) => (
              <div
                className="aos-init aos-animate my-auto"
                data-aos="zoom-in-left"
                data-aos-delay={item.delay}
                key={index}
              >
                <div className="py-4">
                  <div className="text3 fw-bold ">
                    <CountUp
                      start={0}
                      end={item.count}
                      duration={item.duration}
                      suffix={"+"}
                    />
                  </div>
                  <div className="text2">{item.title}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-10">
          <img src={WorldMap} alt={"WorldMap"} className="img-fluid" />
        </div>
      </div>
    </div>
  );
}
