import React from "react";
export default function FormatDate({ date, idNo, customCSS }) {
  const formatDate = (datetime) => {
    const dateObj = new Date(datetime);
    const day = String(dateObj.getDate()).padStart(2, "0");
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const year = String(dateObj.getFullYear()).slice(-2);
    return `${day}${month}${year}`;
  };

  return (
    <>
      <span
        className={`${
          customCSS
            ? "fw-bold shadow px-4 my-1 rounded-pill animate-charcter"
            : ""
        }`}
      >
        {formatDate(date)}-{idNo}
      </span>
    </>
  );
}
