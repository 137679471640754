import "./ToolTip.css";

export default function Tooltip({
  children,
  tooltipText = "Tooltip Text",
  position = "right",
  className,
}) {
  return (
    <div className="tooltip-trigger">
      {children}
      <div
        style={{
          zIndex: 50,
        }}
        className={`tooltip tooltip-${position} text1-2 ${className}`}
      >
        {tooltipText}
      </div>
    </div>
  );
}
