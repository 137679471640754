import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import CopyToClipboardButton from "../../../components/CopyToClipboardButton";
import DarkTextLogo from "../../../assets/logo_text_dark.png";
import Header from "../../../layout/Header";
import { useNavigate, useLocation } from "react-router-dom";
import FormatIntkeDate from "../../../components/UI/FormatIntkeDate";
import html2canvas from "html2canvas";
import { toast } from "react-toastify";
import PdfService from "../../../Services/PdfService";
import jsPDF from "jspdf";
import { Spinner } from "react-bootstrap";

export default function CompareHomePage() {
  const navigate = useNavigate();
  const compareNavValue = useLocation();
  const [loading, setLoading] = useState(false);

  const [fileUrl, setFileUrl] = useState("");

  const getShareLink = useCallback(async ({ file, fileName }) => {
    try {
      const response = await PdfService.create({
        file: file,
        fileName: fileName,
      });

      setFileUrl(response?.data?.response?.fileLink);
      if (response.data.success === false && response.data.response === false) {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  }, []);
  const generatePDF = useCallback(async () => {
    const input = document.getElementById("pdf-content");
    setLoading(true);
    try {
      const canvas = await html2canvas(input);

      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgProps = pdf.getImageProperties(imgData);
      const imgWidth = pdfWidth - 20;
      const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

      const padding = 10;
      const position = padding;

      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);

      const pdfOutput = pdf.output("datauristring");
      const file = pdfOutput.split(",")[1];
      const fileName = "generated.pdf";

      await getShareLink({ file, fileName });
    } catch (error) {
      console.error("PDF generation error:", error);
      toast.error("Something went wrong during PDF generation");
    }
    setLoading(false);
  }, [getShareLink]);
  const selectedProgramsData = JSON.parse(
    localStorage.getItem("selectedProgramsData")
  );

  const selectedCountry = JSON.parse(
    localStorage.getItem("selectedCountry")
  ) ?? ["Canada"];

  const tableRef = useRef(null);

  const handlePrint = () => {
    window.print();
  };

  const handleGoBack = () => {
    localStorage.setItem(
      "selectedProgramsData",
      JSON.stringify(selectedProgramsData)
    );
    localStorage.setItem("selectedCountry", JSON.stringify(selectedCountry));
    window.location.href = `/programs`;
  };

  useEffect(() => {
    if (!selectedProgramsData) {
      window.location.href = `/programs`;
    }
  }, [navigate, selectedProgramsData]);

  return (
    <>
      <Header />
      <div className="px-md-5 px-2" style={{ paddingTop: "15rem" }}>
        <div className="d-flex justify-content-between align-items-center d-print-none">
          <div className="d-flex align-items-center">
            <div className="btn-group dropstart ms-4 mb-2">
              <button
                type="button"
                className="btn text-dark1 text1-4 btn-dark-blue py-2 px-4 rounded-pill d-flex align-items-center"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                onClick={generatePDF}
              >
                <i className="bi bi-share-fill text-orange me-2 text1-6"></i>
                Share
              </button>
              <ul className="dropdown-menu border p-2 rounded-pill">
                {loading ? (
                  <div className="d-flex w-100 justify-content-center">
                    <Spinner />
                  </div>
                ) : (
                  <div className="d-flex">
                    <CopyToClipboardButton textToCopy={fileUrl} />
                    <EmailShareButton
                      url={fileUrl}
                      subject={"Share"}
                      body="Education Planner"
                      className="me-1"
                    >
                      <EmailIcon size={36} round={true} />
                    </EmailShareButton>
                    <FacebookShareButton
                      url={fileUrl}
                      subject={"Share"}
                      body="Education Planner"
                      className="me-1"
                    >
                      <FacebookIcon size={36} round={true} />
                    </FacebookShareButton>
                    <LinkedinShareButton
                      url={fileUrl}
                      subject={"Share"}
                      body="Education Planner"
                      className="me-1"
                    >
                      <LinkedinIcon size={36} round={true} />
                    </LinkedinShareButton>
                    <TwitterShareButton
                      url={fileUrl}
                      subject={"Share"}
                      body="Education Planner"
                      className="me-1"
                    >
                      <TwitterIcon size={36} round={true} />
                    </TwitterShareButton>
                    <WhatsappShareButton
                      url={fileUrl}
                      subject={"Share"}
                      body="Education Planner"
                      className="me-1"
                    >
                      <WhatsappIcon size={36} round={true} />
                    </WhatsappShareButton>
                  </div>
                )}
              </ul>
            </div>

            <div
              className="d-flex align-items-center text-dark1 text1-4 ms-4 btn-dark-blue py-2 px-4 mb-2 rounded-pill"
              style={{ cursor: "pointer" }}
              onClick={handlePrint}
            >
              <i className="bi bi-printer-fill text-orange me-2 text1-6"></i>
              Print
            </div>
          </div>

          <div
            className="d-none d-sm-block text-dark1 text1-4 ms-4 btn-dark-blue py-2 px-4 mb-2 rounded-pill me-5"
            style={{ cursor: "pointer" }}
            onClick={handleGoBack}
          >
            <i className="bi bi-box-arrow-left text-orange me-2 text1-6"></i>Go
            Back to Programs
          </div>
          <div
            className="d-flex align-items-center d-sm-none text-dark1 text1-4 ms-4 btn-dark-blue py-2 px-4 mb-2 rounded-pill me-4"
            style={{ cursor: "pointer" }}
            onClick={handleGoBack}
          >
            <i className="bi bi-box-arrow-left text-orange me-2 text1-6 "></i>
            Programs
          </div>
        </div>

        <div
          className="row position-relative w-100"
          id="pdf-content"
          ref={tableRef}
        >
          <div className="col-12 ps-3 pe-0">
            <div className="table-responsive rounded-4">
              <table className="table custom-table position-relative">
                <img
                  src={DarkTextLogo}
                  alt="DarkTextLogo"
                  className="img-fluid position-absolute "
                  style={{
                    opacity: "0.1",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "80%",
                    height: "auto",
                  }}
                />
                <thead className="bg-dark1 text-white">
                  <tr className="py-1 text1-3">
                    <th scope="col" className="ps-4">
                      Criteria
                    </th>
                    {selectedProgramsData?.map((programData, index) => (
                      <th key={index} scope="col" className="text1-5">
                        {programData.collegeName}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="text1-4">
                  <tr>
                    <td className="ps-4">Program</td>
                    {selectedProgramsData?.map((programData, index) => (
                      <td key={index}>{programData.collegeProgramName}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="ps-4">Location</td>
                    {selectedProgramsData?.map((programData, index) => (
                      <td key={index}>
                        {programData.campus?.join(", ")}
                        {/* {programData.province} */}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="ps-4">Credential</td>
                    {selectedProgramsData?.map((programData, index) => (
                      <td key={index}>{programData.credential}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="ps-4">PG Work Permit</td>
                    {selectedProgramsData?.map((programData, index) => (
                      <td key={index}>
                        {programData.pgWorkPermit === "Yes" ? (
                          <i className="bi bi-check-circle-fill text1-6 text-normal-green"></i>
                        ) : (
                          <i className="bi bi-x-circle-fill text1-6 text-normal-red"></i>
                        )}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="ps-4">Application Fee</td>
                    {selectedProgramsData?.map((programData, index) => (
                      <td key={index}>
                        {programData.currency} {programData.applicationFee}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="ps-4">Tuition Fee</td>
                    {selectedProgramsData?.map((programData, index) => (
                      <td key={index}>
                        {programData.currency} {programData.tuitionFee}
                      </td>
                    ))}
                  </tr>
                  {selectedProgramsData.some(
                    (item) => !!item.ieltsRequirement
                  ) && (
                    <tr>
                      <td className="ps-4">IELTS</td>
                      {selectedProgramsData.map((programData, index) => (
                        <td key={index}>{programData.ieltsRequirement}</td>
                      ))}
                    </tr>
                  )}
                  {selectedProgramsData.some(
                    (item) => !!item.pteRequirement
                  ) && (
                    <tr>
                      <td className="ps-4">PTE</td>
                      {selectedProgramsData.map((programData, index) => (
                        <td key={index}>{programData.pteRequirement}</td>
                      ))}
                    </tr>
                  )}
                  {selectedProgramsData.some(
                    (item) => !!item.duolingoRequirement
                  ) && (
                    <tr>
                      <td className="ps-4">Duolingo</td>
                      {selectedProgramsData.map((programData, index) => (
                        <td key={index}>{programData.duolingoRequirement}</td>
                      ))}
                    </tr>
                  )}
                  {selectedProgramsData.some(
                    (item) => !!item.toeflRequirement
                  ) && (
                    <tr>
                      <td className="ps-4">TOEFL</td>
                      {selectedProgramsData.map((programData, index) => (
                        <td key={index}>{programData.toeflRequirement}</td>
                      ))}
                    </tr>
                  )}
                  {selectedProgramsData.some((item) => !!item.gmat) && (
                    <tr>
                      <td className="ps-4">GMAT</td>
                      {selectedProgramsData.map((programData, index) => (
                        <td key={index}>{programData.gmat}</td>
                      ))}
                    </tr>
                  )}
                  {selectedProgramsData.some((item) => !!item.sat) && (
                    <tr>
                      <td className="ps-4">SAT</td>
                      {selectedProgramsData.map((programData, index) => (
                        <td key={index}>{programData.sat}</td>
                      ))}
                    </tr>
                  )}
                  <tr>
                    <td className="ps-4">Intake</td>
                    {selectedProgramsData?.map((programData, index) => (
                      <td key={index}>
                        {programData.intakeList.length > 0 &&
                          programData.intakeList.some(
                            (intake) => intake.statusId === 3
                          ) && (
                            <div className="d-flex justify-content-start align-items-center">
                              <i className="bi bi-circle-fill text-normal-green me-1"></i>
                              {programData.intakeList
                                .filter((intake) => intake.statusId === 3)
                                .sort(
                                  (a, b) => new Date(a.date) - new Date(b.date)
                                ) // Sort by date
                                .map((intake, index, array) => (
                                  <div key={intake.date} className="fw-bold">
                                    <FormatIntkeDate date={intake.date} />
                                    {index < array.length - 1 && <>,&nbsp;</>}
                                  </div>
                                ))}
                            </div>
                          )}

                        {programData.intakeList.length > 0 &&
                          programData.intakeList.some(
                            (intake) => intake.statusId === 2
                          ) && (
                            <div className="d-flex justify-content-start align-items-center">
                              <i className="bi bi-circle-fill text-normal-yellow me-1"></i>
                              {programData.intakeList
                                .filter((intake) => intake.statusId === 2)
                                .sort(
                                  (a, b) => new Date(a.date) - new Date(b.date)
                                ) // Sort by date
                                .map((intake, index, array) => (
                                  <div key={intake.date} className="fw-bold">
                                    <FormatIntkeDate date={intake.date} />
                                    {index < array.length - 1 && <>,&nbsp;</>}
                                  </div>
                                ))}
                            </div>
                          )}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td className="ps-4">Academic Requirement</td>
                    {selectedProgramsData?.map((programData, index) => (
                      <td key={index}>{programData.academicRequirement}</td>
                    ))}
                  </tr>

                  <tr>
                    <td className="ps-4">Duration</td>
                    {selectedProgramsData?.map((programData, index) => (
                      <td key={index}>{programData.duration}</td>
                    ))}
                  </tr>
                  <tr>
                    <td className="ps-4">Web (Program link)</td>
                    {selectedProgramsData?.map((programData, index) => (
                      <td key={index}>
                        <a
                          href={programData.courseUrl}
                          style={{ cursor: "pointer" }}
                          className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Go to website
                        </a>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
