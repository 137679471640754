// import React from "react";
// import logoLoaderRed from '../../assets/loader-logo-red.mp4';

// export default function Loader() {
//   return (
//     <>
//       <div className="d-flex justify-content-center align-items-center mt-5 pt-5 text3 text-dark1">
//         Please Wait Loading...
//         <video className="loader-video" autoPlay loop>
//           <source src={logoLoaderRed} type="video/mp4" />
//         </video>
//       </div>
//     </>
//   );
// }

import React from "react";
export default function Loader() {
  return (
    <>
      <div className="d-flex justify-content-center align-items-center mt-5 pt-5 text3 text-dark1">
        Please Wait Loading...
        <div
          className="spinner-grow"
          style={{ width: "40px", height: "40px" }}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </>
  );
}

export const ComponentLoader = () => {
  return (
    <div
      className={`d-flex justify-content-center align-items-center text2 text-dark1`}
    >
      Loading...
      <div
        className="spinner-grow"
        style={{ width: "20px", height: "20px" }}
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};
