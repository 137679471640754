import React, { useState, useEffect } from "react";

import Main from "../layout/Main";
import RequireAuth from "../routes/RequireAuth";

import { Routes, Route } from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard";
import Wallet from "./Wallet/Wallet";
import SearchResult from "./Search/Internal/SearchResult";

import Students from "./Students/StudentsList";
import Applications from "./Applications/ApplicationsList";
// import Vouchers from "../pages/Vouchers/Vouchers";
import SharedLinkCompareView from "./Search/SharedLinkCompareView";
import Compare from "./Search/Internal/Compare";
import StudentAdd from "./Students/StudentAdd";

import ApplicationView from "./Applications/ApplicationView";
import LeadList from "./Lead/LeadList";

import ErrorPage from "./ErrorPage";
import StudentUpdate from "./Students/StudentUpdate";

import UsersList from "./UserManagement/UsersList";

import PaymentDone from "./ApplicationPayment/PaymentDone";
import NotesView from "./Notes/NotesView";
import AgentsList from "./Agents/AgentsList";
import ProgramsHomePage from "./Search/External/ProgramsHomePage";
import CompareHomePage from "./Search/External/CompareHomePage";
import WelcomeBoard from "./Dashboard/WelcomeBoard";
import CollegeList from "./Programs/CollegeList";

import AppAgent from "../pagesAgent/AppAgent";

import AppInstitution from "../pagesInstitution/AppInstitution";
import MainStudent from "../pagesStudent/MainStudent";
import ForgotPassword from "./Profile/ForgotPassword";
import DetailedProgram from "./Search/DetailedProgram";
import PaymentList from "./Payments/PaymentList";

import RoleService from "../Services/RolesService";
import BlogView from "./Blog/BlogView";
import { Reports } from "./Reports";
import RequestsList from "./Requests/RequestsList";
import RefundList from "./Refunds/RefundList";
import Privacy from "./privacyPolicy/privacy";
import AboutUs from "../FooterPages/About-us/AboutUs";
import Notification from "./Notifications/Notification";
import Contact from "../FooterPages/Contact-us/Contact";
import ResetPass from "./ResetPassword/ResetPass";

import AppBlog from "../blogPages/AppBlog";
import AgentUserList from "./AgentUser/AgentUserList";
import AgentProfile from "./AagentProfile";
import GicList from "./MarketPlace/GicList";
import GicDetails from "./MarketPlace/GicDetails";
import ApplyForm from "./MarketPlace/ApplyForm";
import ViewForm from "./MarketPlace/ViewForm";
function AppMainEP() {
  const {
    SuperAdmin,
    Reception,
    Counsellor,
    AdmissionOfficer,
    TeamLeader,
    BranchHead,
    ProgramEditor,
    AgencyManager,
    Accounts,
  } = RoleService;

  return (
    <>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/agent" element={<AppAgent />} />
        <Route path="/institutions" element={<AppInstitution />} />
        <Route path="/blogs" element={<AppBlog />}></Route>
        {/* <Route path="/student" element={<MainStudent />} /> */}
        <Route path="/unauthorized" element={<ErrorPage />} />
        <Route path="/resetpassword" element={<ForgotPassword />} />
        <Route path="/programs" element={<ProgramsHomePage />}></Route>
        <Route path="/compareView" element={<SharedLinkCompareView />}></Route>
        <Route path="/blogView/:id" element={<BlogView />}></Route>
        <Route path="/program/:id" element={<DetailedProgram />}></Route>
        <Route path="/programs/compare" element={<CompareHomePage />}></Route>
        <Route path="/privacy-policy" element={<Privacy />}></Route>
        <Route path="/about-us" element={<AboutUs />}></Route>
        <Route path="/contact-us" element={<Contact />}></Route>

        <Route path="/blogs" element={<AppBlog />}></Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                SuperAdmin,
                Reception,
                Counsellor,
                AdmissionOfficer,
                TeamLeader,
                BranchHead,
                ProgramEditor,
                AgencyManager,
                Accounts,
              ]}
            />
          }
        >
          <Route path="/user" element={<WelcomeBoard />}></Route>
        </Route>
        {/* <Route path="profile" element={<UserProfile />}></Route> */}
        <Route
          element={<RequireAuth allowedRoles={[SuperAdmin, BranchHead]} />}
        >
          <Route path="/user/dashboard" element={<Dashboard />}></Route>
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                BranchHead,
                AgencyManager,
                AdmissionOfficer,
                Counsellor,
              ]}
            />
          }
        >
          <Route path="/user/notifications" element={<Notification />}></Route>
        </Route>

        <Route element={<RequireAuth allowedRoles={[SuperAdmin]} />}>
          <Route path="/user/agent-users" element={<AgentUserList />} />
          <Route path="/user/reports" element={<Reports />}></Route>
          <Route path="/user/reset-password" element={<ResetPass />}></Route>
        </Route>

        <Route
          element={
            <RequireAuth
              allowedRoles={[SuperAdmin, AgencyManager, TeamLeader, Accounts]}
            />
          }
        >
          <Route path="/user/agents" element={<AgentsList />}></Route>
          <Route path="/user/addAgent" element={<StudentAdd />}></Route>
          <Route
            path="/user/updateAgent/:id"
            element={<StudentUpdate />}
          ></Route>
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                SuperAdmin,
                Counsellor,
                AdmissionOfficer,
                BranchHead,
                AgencyManager,
                TeamLeader,
              ]}
            />
          }
        >
          <Route path="/user/students" element={<Students />}></Route>
          <Route path="/user/addStudent" element={<StudentAdd />}></Route>
          <Route
            path="/user/updateStudent/:id"
            element={<StudentUpdate />}
          ></Route>
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                SuperAdmin,
                Counsellor,
                AdmissionOfficer,
                TeamLeader,
                BranchHead,
                AgencyManager,
                AdmissionOfficer,
              ]}
            />
          }
        >
          <Route path="/user/applications" element={<Applications />}></Route>

          <Route
            path="/user/viewApplication/:id"
            element={<ApplicationView />}
          ></Route>
          <Route path="/user/viewNotes/:id" element={<NotesView />}></Route>
        </Route>
        {/* <Route path="vouchers" element={<Vouchers />}></Route> */}
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                SuperAdmin,
                Counsellor,
                TeamLeader,
                BranchHead,
                ProgramEditor,
                AdmissionOfficer,
                AgencyManager,
                Accounts,
                Reception,
              ]}
            />
          }
        >
          <Route path="/user/program" element={<SearchResult />}></Route>
          <Route path="/user/program/compare" element={<Compare />}></Route>
          <Route
            path="/user/program/studentList/:id"
            element={<Students />}
          ></Route>
        </Route>
        <Route
          element={<RequireAuth allowedRoles={[AgencyManager, Accounts]} />}
        >
          <Route path="/user/requests" element={<RequestsList />}></Route>
        </Route>
        <Route
          element={
            <RequireAuth allowedRoles={[SuperAdmin, BranchHead, Accounts]} />
          }
        >
          <Route path="/user/wallet" element={<Wallet />}></Route>
          <Route path="/user/payment" element={<PaymentList />}></Route>
        </Route>
        <Route element={<RequireAuth allowedRoles={[Accounts]} />}>
          <Route path="/user/refunds" element={<RefundList />}></Route>
        </Route>
        <Route
          element={<RequireAuth allowedRoles={[SuperAdmin, BranchHead]} />}
        >
          <Route path="/user/users" element={<UsersList />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[BranchHead, AgencyManager, Accounts, SuperAdmin]} />}>
          <Route path="/user/profile" element={<AgentProfile />}></Route>
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[BranchHead, AgencyManager, Accounts, SuperAdmin]}
            />
          }
        >
          <Route path="/user/profile" element={<AgentProfile />}></Route>
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[SuperAdmin, Reception, Counsellor, BranchHead]}
            />
          }
        >
          <Route path="/user/lead" element={<LeadList />} />
        </Route>
        <Route
          element={<RequireAuth allowedRoles={[SuperAdmin, ProgramEditor]} />}
        >
          <Route path="/user/colleges" element={<CollegeList />} />
        </Route>
        <Route
          element={
            <RequireAuth allowedRoles={[SuperAdmin, Counsellor, BranchHead]} />
          }
        >
          <Route
            path="/user/application/paymentdone/:id"
            element={<PaymentDone />}
          />
        </Route>
        <Route element={<RequireAuth allowedRoles={[Accounts, BranchHead]} />}>
          <Route path="/user/marketplace" element={<GicList />} />
          <Route path="/user/marketplace/details" element={<GicDetails />} />
          <Route path="/user/marketplace/form/:id" element={<ViewForm />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[BranchHead]} />}>
          <Route path="/user/marketplace/apply" element={<ApplyForm />} />
        </Route>
      </Routes>
    </>
  );
}

export default AppMainEP;
