import { useState } from "react";
import Modal from "react-bootstrap/Modal";

import ConfirmationModal from "../../components/UI/ConfirmationModal";

import TokenService from "../../Services/TokenService";
import AppStripePayment from "./AppStripePayment";
import AgentsDataService from "../../Services/AgentsService";
import { toast } from "react-toastify";
import ExchangeRate from "../../Services/ExchangeRate";
import platformLogo from "../../assets/logo_dark.png";

function PaymentForm({
  studentCurrency,
  collegeCurrency,
  exchangeData,
  currentApplication,
  totalAmount,
  PaymentService,
  getPaymentDetails,
  selectedPromoCode,
  allDraftApplication,
  collegeDetailsApplicationFee,
  setPaymentDoneShow,
}) {
  const [show, setShow] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isPayButtonClicked, setIsPayButtonClicked] = useState(false);
  const getUser = TokenService.getUser();

  const [clientSecret, setClientSecret] = useState("");
  const handleClose = () => {
    setShow(false);
    setClientSecret("");
  };

  const stripePayStart = () => {
    if (allDraftApplication === false) {
      setShowConfirmation(false);
      handleShow();
    } else if (allDraftApplication === true) {
      setShowConfirmation(true);
    }
  };

  const handleShow = async () => {
    try {
      setShow(true);
      setIsPayButtonClicked(true);
      const agentResponse = await AgentsDataService.get(getUser.companyId);

      // Create the payData object
      const payData = {
        name: getUser.firstName + " " + getUser.lastName,
        description: agentResponse.data.name,
        line1: agentResponse.data.address,
        postalCode: agentResponse.data.postalCode,
        city: agentResponse.data.city,
        state: agentResponse.data.state,
        country: agentResponse.data.country,
        amount: exchangeData.convertedAmount,
        applicationId: currentApplication.id,
        mode: "Card",
      };
      //console.log(payData);
      // Check for incomplete fields
      const incompleteFields = Object.keys(payData).filter(
        (key) => !payData[key]
      );

      if (incompleteFields.length > 0) {
        // There are incomplete fields, show an error toast
        toast.error(`Please update: ${incompleteFields.join(", ")}`);
        // You can handle this error further as needed.
      } else {
        // All fields are complete, proceed with the payment
        const paymentResponse = await PaymentService.stripepay(payData);
        setClientSecret(paymentResponse.data.clientSecret);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsPayButtonClicked(false);
    }
  };

  const handleConfirmLock = () => {
    setShowConfirmation(false); // Close the custom confirmation modal
    handleShow();
  };
  return (
    <>
      <button
        className="btn btn-light-blue-outline text1-8 rounded-pill py-3 px-5"
        onClick={stripePayStart}
        disabled={isPayButtonClicked}
      >
        Pay to Submit
      </button>

      <ConfirmationModal
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        onConfirm={handleConfirmLock}
        message="Upon payment, the student's details will be locked, and you will no longer be able to modify the particulars of the student."
        title="Confirm Lock"
      />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="md"
        className="custom-modal"
      >
        <Modal.Body className="p-2">
          <i
            className="bi bi-x-circle-fill text2 p-3 text-danger position-absolute end-0 top-0"
            style={{ cursor: "pointer" }}
            onClick={() => setShow(false)}
          ></i>
          <div className="d-flex flex-column align-items-center pt-5 gap-5">
            <img src={platformLogo} alt="platformLogo" height="80px" />
            <p className="text2 text-dark1 fw-bold">Application Payment</p>
          </div>

          <AppStripePayment
            studentCurrency={studentCurrency}
            collegeCurrency={collegeCurrency}
            clientSecret={clientSecret}
            exchangeData={exchangeData}
            currentApplication={currentApplication}
            PaymentService={PaymentService}
            setShow={setShow}
            getPaymentDetails={getPaymentDetails}
            selectedPromoCode={selectedPromoCode}
            collegeDetailsApplicationFee={collegeDetailsApplicationFee}
            setPaymentDoneShow={setPaymentDoneShow}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PaymentForm;
