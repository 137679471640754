import React from "react";
import Pagination from "react-bootstrap/Pagination";

const AdvancePagination = ({
  currentPage,
  handlePageChange,
  totalItems,
  pageSize,
}) => {
  const totalPages = Math.ceil(totalItems / pageSize);

  const handleFirstPage = () => {
    handlePageChange(1);
  };

  const handlePrevPage = () => {
    handlePageChange(currentPage - 1);
  };

  const handleNextPage = () => {
    handlePageChange(currentPage + 1);
  };

  const handleLastPage = () => {
    handlePageChange(totalItems);
  };

  const paginationItems = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationItems.push(
      <Pagination.Item
        key={i}
        active={i === currentPage}
        onClick={() => handlePageChange(i)}
      >
        {i}
      </Pagination.Item>
    );
  }

  return totalItems / pageSize > 1 ? (
    <Pagination>
      <Pagination.First
        onClick={handleFirstPage}
        disabled={currentPage === 1}
      />
      <Pagination.Prev onClick={handlePrevPage} disabled={currentPage === 1} />
      {paginationItems}
      <Pagination.Next
        onClick={handleNextPage}
        disabled={currentPage === totalPages}
      />
      <Pagination.Last
        onClick={handleLastPage}
        disabled={currentPage === totalPages}
      />
    </Pagination>
  ) : null;
};

export default AdvancePagination;
