import http from "./api";

const get = (id) => {
  return http.get(
    `ApplicationNote/getByStudentApplicationId?studentApplicationId=${id}`
  );
};
const create = (data) => {
  return http.post("ApplicationNote", data);
};
const download = (params) => {
  return http.get(`ApplicationNote/downloadAppNoteFile?`, { params });
};
const find = (params) => {
  return http.get("ApplicationNote/search?", { params });
};

const fetchRecentNotifications = () => {
  return http.get("ApplicationNote/getRecentAppNotes");
};

const fetchNotifications = () => {
  return http.get("Notification");
};

const markNotificationAsRead = (payload) => {
  return http.post("Notification/mark-as-read", payload);
};
const markAsReadByReferenceId = (payload) => {
  return http.post("Notification/markAsReadByReferenceId", payload);
};

const markNotesAsRead = (payload) => {
  return http.post("ApplicationNote/markAppNotesAsRead", payload);
};

const ApplicationNoteService = {
  get,
  create,
  download,
  find,
  fetchRecentNotifications,
  markNotesAsRead,
  fetchNotifications,
  markNotificationAsRead,
  markAsReadByReferenceId,
};

export default ApplicationNoteService;
